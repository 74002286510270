import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { INewTag } from './casesService';
import { ITemplate } from 'dto/ITemplate';
import { AxiosResponse } from 'axios';
import { ItemKind } from 'dto/IKindItems';

const queryKeys = {
  getTemplates: (name: string) => ['editorService.getTemplates', name],
  getSingleTemplate: (id: string, kind: string) => [
    'editorService.getSingleTemplate',
    id,
    kind,
  ],
  getVersions: (id: string) => ['editorService.getVersions', id],
};

export interface IEditDocument {
  Id: string;
  Title: string;
}

const editDocument = async (payload: IEditDocument) => {
  return await axiosInstance.put('/editor/document', payload);
};

const useEditDocument = () => {
  return useMutation(editDocument);
};
export interface ICreateDocument {
  CaseId: string | null;
  NewTags: INewTag[];
  PrivateKey: string;
  PublicKey: string;
  TagIds: string[];
  TemplateId: string | null;
  Title: string;
  Format: string;
  FolderId?: string;
}

const createDocument = async (payload: ICreateDocument) => {
  return await axiosInstance.post('editor/document', payload);
};

const useCreateDocument = () => {
  return useMutation(createDocument);
};

const getTemplates = async (
  name: string,
  allowEmpty: boolean = false
): Promise<ITemplate[]> => {
  const query = new URLSearchParams(window.location.search);

  if (!name.length && !allowEmpty) {
    return [];
  }

  if (!!name.length) {
    query.append('Text', name);
  }

  const r = await axiosInstance.get(`editor/templates?${query}`);
  return r.data ?? [];
};

const useGetTemplate = (
  name: string,
  allowEmpty?: boolean,
  enabled: boolean = true,
  customName?: string
) => {
  return useQuery(
    customName ?? queryKeys.getTemplates(name),
    () => getTemplates(name, allowEmpty),
    {
      enabled: enabled,
    }
  );
};

export interface ISign {
  Id: string;
  Uri: string;
  Report?: string;
  Countersign?: boolean;
}

const sign = (payload: ISign) => {
  return axiosInstance.post('/editor/sign', payload);
};

const useSign = () => {
  return useMutation(sign);
};

const getSingleTemplate = async (
  id: string,
  kind: string
): Promise<ITemplate[]> => {
  const query = new URLSearchParams(window.location.search);

  query.append('Id', id);
  query.append('Kind', kind.toString());

  const r = await axiosInstance.get(`editor/templates?${query}`);
  return r.data ?? [];
};

const useGetSingleTemplate = (id: string, kind: string) => {
  return useQuery(queryKeys.getSingleTemplate(id, kind), () =>
    getSingleTemplate(id, kind)
  );
};

const getVersions = async (id: string, kind: ItemKind) => {
  let url = '/editor/versions';

  if (kind === ItemKind.CaseFile) {
    url = '/dossier/file/versions';
  }
  const res = await axiosInstance.get(`${url}?Id=${id}`);
  return res.data ?? [];
};

const useGetVersions = (id: string, kind: ItemKind, enabled: boolean) => {
  return useQuery(queryKeys.getVersions(id), () => getVersions(id, kind), {
    enabled: enabled,
  });
};

export interface IForceVersion {
  Id: string;
  Name: string;
}

const forceVersion = (payload: IForceVersion) => {
  return axiosInstance.post('/editor/version', payload);
};

const useForceVersion = () => {
  return useMutation(forceVersion);
};

export interface IUnlockEditor {
  Id: string;
  Name: string;
}

const unlockDocument = (payload: IUnlockEditor) => {
  return axiosInstance.post('/editor/unlock', payload);
};

const useUnlockDocument = () => {
  return useMutation(unlockDocument);
};

export default {
  useCreateDocument,
  useGetTemplate,
  useSign,
  useEditDocument,
  useGetSingleTemplate,
  useGetVersions,
  useForceVersion,
  useUnlockDocument,
};
