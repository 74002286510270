import { useQuery } from "react-query"
import { axiosInstance } from "./axios"

const queryKeys = {
    getSubAccounts: (workspaceId: string) => ['subAccountsService.getSubAccounts', workspaceId],
}

const getSubAccounts = async (workspaceId: string) => {
    const { data } = await axiosInstance.get(`/subaccounts?WorkspaceId=${workspaceId}`)
    return data ?? []
}

const useGetSubAccounts = (workspaceId: string) => {
    return useQuery(queryKeys.getSubAccounts(workspaceId), () => getSubAccounts(workspaceId));
}

export default {
    useGetSubAccounts

}