import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import ReactDOM from 'react-dom';
import { calculatePosition } from 'utils/calculatePosition';
import ModalsManagerContext from 'contexts/ModalsManager';
import { useOnClickOutside } from 'usehooks-ts';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';

export enum DockPosition {
  TopLeft = 0,
  TopRight = 1,
  BottomLeft = 2,
  BottomRight = 3,
  DISABLED = -1,
  CENTER = 4,
  CENTER_2 = 5,
}

const ModalLayout = ({
  refWrapper,
  children,
  customX = 250,
  customY = 10,
  dock = DockPosition.BottomRight,
  className = '',
  off,
  ignore = false,
  isAnimation = false,
  onEnd,
  toggle,
  disableMove = false,
  fullHeight = false,
  widthAsLeftArea = false,
}: {
  refWrapper?: RefObject<HTMLDivElement>;
  children: any;
  customX?: number;
  customY?: number;
  dock?: DockPosition;
  className?: string;
  off?: boolean;
  ignore?: boolean;
  isAnimation?: boolean;
  onEnd?: () => void;
  toggle?: () => void;
  disableMove?: boolean;
  fullHeight?: boolean;
  widthAsLeftArea?: boolean;
}) => {
  const refs = useRef(null);
  const [position, setPosition] = useState<{
    x: number | null;
    y: number | null;
    width: number | null;
    height: number | null;
    actualPositionX: number | null;
    actualPositionY: number | null;
  }>({
    x: null,
    y: null,
    width: null,
    height: null,
    actualPositionX: 0,
    actualPositionY: 0,
  });
  const [moveWindow, setMoveWindow] = useState(false);

  const { modals, setModals } = useContext(ModalsManagerContext);

  const [isStartAnimation, setIsStartAnimation] = useState(false);
  const [isEndAnimation, setIsEndAnimation] = useState(false);

  useEffect(() => {
    if (!modals.hide || !isAnimation) return;

    // if (-position.width! < position.actualPositionX!) {
    //   setPosition((prevState) => ({
    //     ...prevState,
    //     actualPositionX: prevState.actualPositionX! - 0.1,
    //   }));
    //   return;
    // }
    setIsStartAnimation(false);
    setIsEndAnimation(true);

    setTimeout(() => {
      if (onEnd) {
        onEnd();
      }

      setModals({
        createAction: false,
        createCase: false,
        createDossier: false,
        createEntity: false,
        createLetter: false,
        createPost: false,
        createPostage: false,
        createCompany: false,
        createTask: false,
        hide: false,
        settings: false,
      });
    }, 1000);
  }, [modals, position]);

  useEffect(() => {
    if (modals.hide) return;
    if (!isAnimation) return;
    if (position.actualPositionX === null || position.actualPositionY === null)
      return;

    setIsStartAnimation(true);
    setIsEndAnimation(false);
  }, []);

  const handlePosition = () => {
    if (ignore && refs?.current) {
      // @ts-ignore
      const modalSize = refs.current!.getBoundingClientRect();

      if (ignore) {
        const x = customX;
        const y = customY;

        setPosition({
          x,
          y,
          width: modalSize.width,
          height: modalSize.height,
          actualPositionX: isAnimation ? -modalSize.width : x,
          actualPositionY: position.actualPositionY,
        });
        return;
      }
    }

    if (!refWrapper?.current || !refs.current) {
      // @ts-ignore
      const modalSize = refs.current!.getBoundingClientRect();
      setPosition({
        x: 20,
        y: 60,
        width: modalSize.width,
        height: modalSize.height,
        actualPositionX: isAnimation ? -modalSize.width : 0,
        actualPositionY: position.actualPositionY,
      });

      return;
    }

    const position_2 = refWrapper.current!.getBoundingClientRect();
    // @ts-ignore
    const modalSize = refs.current!.getBoundingClientRect();
    const screenWidth = window.outerWidth;
    const screenHeight = window.screen.height - 150;

    let x = position_2.x ?? 0;
    let y = position_2.y ?? 0;

    switch (dock) {
      case DockPosition.TopLeft:
        y = y - modalSize.height;

        if (x + modalSize.width > screenWidth) {
          x = x + position_2.width - modalSize.width;
        }

        break;
      case DockPosition.TopRight:
        y = y - modalSize.height;
        x = x + position_2.width - modalSize.width;

        break;
      case DockPosition.BottomLeft:
        y = y + position_2.height;

        if (x + modalSize.width > screenWidth) {
          x = x + position_2.width - modalSize.width;
        }

        break;
      case DockPosition.BottomRight:
        y = y + position_2.height;
        x = x + position_2.width - modalSize.width / 2;
        break;
      case DockPosition.CENTER:
        y = y + position_2.height / 2 - 50;
        x = x + position_2.width / 2 - (modalSize.width + 20);
        break;
      case DockPosition.CENTER_2:
        y = y + position_2.height / 2 - 50;
        x = x + position_2.width / 2 - modalSize.width;
        break;
      case DockPosition.DISABLED:
        x = x + customX;
        y = y - customY;

        if (!off && y + modalSize.height > screenHeight) {
          y = screenHeight - modalSize.height;
        }
    }

    const { x: rx, y: ry } = calculatePosition(
      x,
      y,
      modalSize.width,
      modalSize.height
    );

    setPosition({
      x: rx,
      y: ry,
      width: modalSize.width,
      height: modalSize.height,
      actualPositionX: isAnimation ? -modalSize.width : x,
      actualPositionY: position.actualPositionY,
    });
  };

  useEffect(() => {
    handlePosition();
  }, []);

  const handleMouseMove = (e: any) => {
    if (!moveWindow) return;
    // @ts-ignore
    const position = refs.current!.getBoundingClientRect();

    let cx = 0;
    let cy = 0;

    if (!off) {
      const { x, y } = calculatePosition(
        e.clientX,
        e.clientY,
        position.width,
        position.height
      );
      cx = x;
      cy = y;
    } else {
      cx = e.clientX - position.width / 2;
      cy = e.clientY - 30;
    }

    setPosition({
      x: cx,
      y: cy,
      width: position.width,
      height: position.height,
      actualPositionX: position.actualPositionX,
      actualPositionY: position.actualPositionY,
    });
  };

  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const settings = localStorage.getItem(`${userId}.layout`);
  const mainSettings = localStorage.getItem(`${userId}.main`);

  const mainSizes = JSON.parse(mainSettings ?? '[]');
  const sizes = JSON.parse(settings ?? '[]');

  let posX = position.x;
  let posY =
    isAnimation && position.actualPositionY
      ? position.actualPositionY
      : position.y;

  let opacity = position?.x ? 1 : 0;

  if (isAnimation && posY) {
    posY += 20;
  }

  if (!posX) {
    posX = 0;
  }

  if (!posY) {
    posY = 0;
  }

  const handleKeyDown = (e: any) => {
    if (e.code === 'Escape') {
      toggle?.();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [moveWindow]);

  useOnClickOutside(refs, () => {
    toggle?.();
  });

  return ReactDOM.createPortal(
    <div
      ref={refs}
      className={`${styles.window} ${
        moveWindow && styles.notSelectable
      } ${className} ${isStartAnimation && styles.show} ${
        isEndAnimation && styles.hide
      }`}
      style={{
        position: 'absolute',
        left: `${posX}px`,
        top: `${posY}px`,
        bottom: `${fullHeight ? '0px' : 'auto'}`,
        zIndex: 1000,
        opacity: opacity,
        width: widthAsLeftArea
          ? `${
              mainSizes.leftPanel +
              mainSizes.rightPanel * ((sizes.leftPanel ?? 50) / 100)
            }%`
          : 'auto',
      }}
    >
      {!disableMove && (
        <div
          onMouseDown={() => {
            setMoveWindow(true);
          }}
          onMouseUp={() => setMoveWindow(false)}
          className={styles.scrollarea}
        ></div>
      )}
      <div className={styles.childrens}>{children}</div>
    </div>,
    document.body
  );
};

export default ModalLayout;
