import { Route, Routes } from 'react-router-dom';
import MessagesModule from './';

const MessagesRoute = () => {
  return (
    <Routes>
      <Route
        path="fullscreen/:packageId/:kind/:PDFId?"
        element={<MessagesModule hideMenu={true} />}
      />
      <Route
        path="/:filterId/:status?/:kind/:packageId"
        element={<MessagesModule />}
      />
      <Route
        path="thread/:filterId/:status?/:kind/:threadId/:threadPackageId"
        element={<MessagesModule />}
      />
      <Route
        path="thread/:filterId/:status?/:kind/:threadId/:threadPackageId/preview-pdf/:PDFId"
        element={<MessagesModule />}
      />
      <Route
        path="thread/:filterId/:status?/:kind/:threadId"
        element={<MessagesModule />}
      />
      <Route
        path="/:filterId/:status?/:kind/:packageId/preview-pdf/:PDFId"
        element={<MessagesModule />}
      />
      <Route
        path="/:filterId/:status?/:kind/:packageId/preview-letter/:letterId/:versionId?/:signStatus?"
        element={<MessagesModule />}
      />
      <Route
        path="thread/:filterId/:status?/:kind/:threadId/preview-pdf/:PDFId"
        element={<MessagesModule />}
      />
      <Route path="/:filterId/:status?" element={<MessagesModule />} />
    </Routes>
  );
};

export default MessagesRoute;
