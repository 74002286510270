import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Button from 'components/Button';
import casesService from 'api/casesService';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { useMemo } from 'react';
import CaseCard from 'components/CaseCard';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const CasesClientModal: FC<Props> = ({ client, refWrapper }) => {
  const navigate = useNavigate();
  const { data: casesData } = casesService.useGetCases({
    page: 0,
    ParticipantId: client.Id ?? '',
  });

  const Cases: ICaseSimple[] = useMemo(
    () => casesData?.data.Items ?? [],
    [casesData]
  );

  const { data: tagsData } = tagsService.useGetTags();
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: statusesData } = statusService.useGetStatuses();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  if (!refWrapper?.current) return <></>;

  const position = refWrapper.current.getBoundingClientRect();

  return ReactDOM.createPortal(
    <div
      style={{
        left: `${position.left}px`,
        top: `${position.top + 32}px`,
      }}
      className={styles.wrapper}
    >
      <div className={styles.title}>
        Teczki
        <CloseIcon className={styles.icon} />
      </div>
      <div className={styles.list}>
        {Cases.map((c) => (
          <CaseCard
            className={styles.card}
            c={c}
            tags={tags}
            showS={false}
            statuses={statuses}
            setSelectedEdit={() => {}}
            onClick={() => {
              navigate(`/cases/${c.Id}`);
            }}
          />
        ))}
      </div>
    </div>,
    document.body
  );
};

export default CasesClientModal;
