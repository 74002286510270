import workspaceService from 'api/workspaceService';
import Avatar from 'components/Avatar';
import { IWorkspace } from 'dto/IWorkspace';
import { useMemo, useState } from 'react';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

const AddPermission: FC<Props> = ({ setNewAdd, setAddNew }) => {
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');
  const { data: workspaceData } = workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);
  const workspaceRoles = useMemo(() => workspace?.Roles ?? [], [workspace]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Upoważnienia do edycji</div>

      <div className={styles.list}>
        {workspaceMembers
          .filter((work) => !!work.UserId)
          .map((work) => {
            return (
              <div
                key={work.Id}
                onClick={() => {
                  console.log(work);
                  setNewAdd({
                    id: work.Id,
                    label: work.Label,
                    userId: work.UserId,
                    filters: [],
                  });
                  setAddNew(false);
                }}
                className={styles.box}
              >
                <div className={styles.data}>
                  <Avatar name={work.Label} />
                  <div className={styles.name}>
                    <div className={styles.label}>{work.Label}</div>
                    <div className={styles.role}>
                      {workspaceRoles.find((r) => r.Id === work.RoleId)?.Name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AddPermission;
