import casesService from 'api/casesService';
import dossierService from 'api/dossierService';
import fileService from 'api/fileService';
import keysService from 'api/keysService';
import { ReactComponent as NoFileIcon } from 'assets/icons/nofiles.svg';
import AddButton from 'components/AddButton';
import Button from 'components/Button';
import CreateCaseModal from 'components/CreateCaseModal';
import Filedossier from 'components/Filedossier/Filedossier';
import Input from 'components/Input';
import SearchCases from 'components/SearchCases';
import TextArea from 'components/TextArea';
import ModalsManagerContext from 'contexts/ModalsManager';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IKey } from 'dto/IKey';
import useFiles from 'hooks/useFiles';
import { useContext, useMemo, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import { isValidHttpUrl } from 'utils/isValidHttpUrl';
import { handleCreate } from 'utils/uploadFile';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import LayoutContext from 'contexts/LayoutContext';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import SearchCasesNew from 'components/SearchCasesNew';
import DossierAnimation from 'components/DossierAnimation';
import DossierFilesContext from 'contexts/DossierFilesContext';
import KeysContext from 'contexts/KeysContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const CreateDossierModal: FC<Props> = ({}) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);

  const LS = localStorage.getItem(`${userId ?? ''}.layout`);
  const sizes = JSON.parse(LS ?? '[]');

  const link = window.location.href;
  const caseId = link.split('cases/')?.[1]?.split('/')?.[0] ?? undefined;
  const folderId = link.split('dossier/f/')?.[1]?.split('/')?.[0] ?? undefined;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { modals, setModals } = useContext(ModalsManagerContext);
  const { files, addNewFile, removeFile, setFiles } = useFiles();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [searchedCase, setSearchedCase] = useState<ICaseSimple | null>(null);
  const { data: caseData } = casesService.useGetKeys({
    id: searchedCase?.Id ?? null,
  });

  const [externalLink, setExternalLink] = useState('');
  const [title, setTitle] = useState('');

  const [addLinke, setAddLink] = useState(false);

  const { mutate: createObject, isLoading: loadingObject } =
    keysService.useCreateObject();
  const { mutate: initUploadFile, isLoading: loadingInit } =
    fileService.useInitUpload();
  const { mutate: getS3, isLoading: loadingS3 } = fileService.useGetS3();
  const { mutate: confirmUpload, isLoading: loadingConfirm } =
    fileService.useConfirmUpload();
  const { mutate: addFileToDossier, isLoading: loadingAdd } =
    dossierService.useAddFile();
  const { mutate: addLink, isLoading: loadingLink } =
    dossierService.useAddLink();

  const key: IKey = useMemo(() => caseData?.data?.[0] ?? [], [caseData]);

  const handleClickCloseModal = () => setModals({ ...modals, hide: true });

  const handleClickToUpload = () => {
    if (!inputFile.current) return;
    inputFile.current.click();
  };

  const handleChooseFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = (event.target as any).files[0];
    if (!file) return;

    addNewFile(file);
  };

  const handleCreateLink = () => {
    addLink(
      {
        CaseId: searchedCase!.Id,
        ParentId: folderId,
        Label: title,
        Url: externalLink,
      },
      {
        onSuccess: (resp) => {
          setTitle('');
          setExternalLink('');
          if (folderId) {
            navigate(`/cases/${searchedCase!.Id}/dossier/f/${folderId}`);
          } else {
            navigate(`/cases/${searchedCase!.Id}/dossier`);
          }
          resp.data.tagIds = [];
          console.log(resp.data);
          setDossierFiles([...dossierFiles, resp.data]);
          handleClickCloseModal();
        },
      }
    );
  };

  // Starting process of upload after click on button

  const [actualFile, setActualFile] = useState(0);
  const [actualProgress, setActualProgress] = useState<number | null>(null);
  const { dossierFiles, setDossierFiles } = useContext(DossierFilesContext);
  const { keys, setKeys } = useContext(KeysContext);

  const handleCreatee = async () => {
    if (!searchedCase?.Id) return;
    setIsLoading(true);

    if (!!externalLink.length && isValidHttpUrl(externalLink)) {
      handleCreateLink();
      return;
    }

    for (const file of files) {
      const r = await handleCreate(
        [file],
        searchedCase?.Id,
        key!.PublicKey ?? '',
        true,
        true,
        undefined,
        undefined,
        (progress: number) => {
          setActualProgress(progress);
        }
      );

      if (!r) {
        return;
      }

      addFileToDossier(
        {
          CaseId: searchedCase!.Id!,
          ParentId: folderId,
          Files: [
            {
              KeyId: r[0].keyId,
              Uri: r[0].uri,
            },
          ],
        },
        {
          onSuccess: (resp) => {
            setActualFile((p) => p + 1);
            setActualProgress(0);
            setIsLoading(false);

            const k: any = resp.data.Keys.flat();
            const ok: any = resp.data.ObjectKeys.flat();

            setKeys([...keys, k, ok].flat());
            setDossierFiles([...dossierFiles, ...resp.data.Items]);
          },
          onError: () => setIsLoading(false),
        }
      );
    }
    if (folderId) {
      navigate(`/cases/${searchedCase!.Id}/dossier/f/${folderId}`);
    } else {
      navigate(`/cases/${searchedCase!.Id}/dossier`);
    }
    handleClickCloseModal();
  };

  const handleRemove = (index: number) => removeFile(index);

  const Attachments = () => {
    if (!!externalLink.length) {
      return <></>;
    }

    return (
      <>
        {!files.length ? (
          <FileUploader
            multiple={true}
            handleChange={async (f: any) => {
              const newFiles = Array.from(f).map((f) => f) as File[];
              setFiles([...files, newFiles].flat());
            }}
            classes={styles.drop}
            name="file"
            children={
              <div className={styles.emptyAttachments}>
                {/* <NoFileIcon className={styles.noFiles} /> */}
                <DossierAnimation className={styles.noFiles} />
                <div className={styles.emptyButtons}>
                  Przeciągnij i upuść plik lub
                  <div className={styles.buttonCTA}>Wybierz z dysku</div>
                </div>
              </div>
            }
          />
        ) : (
          <div className={styles.attachmentsWrapper}>
            <div className={styles.attachments}>
              {files.map((file: File, index: number) => (
                <Filedossier
                  key={index}
                  index={index}
                  handleRemove={handleRemove}
                  file={file}
                  actualFile={actualFile}
                  actualProgress={actualProgress}
                />
              ))}
            </div>
            <AddButton
              className={styles.add}
              onClick={handleClickToUpload}
              text="Dodaj kolejny załącznik"
            />
          </div>
        )}
      </>
    );
  };

  const [createCase, setCreateCase] = useState(false);
  const [addNewEntity, setAddNewEntity] = useState(false);

  const [showErrors, setShowErrors] = useState({ case: false, files: false });

  const isButtonActive = () => {
    const isSendingFilesOrLink =
      loadingObject ||
      loadingInit ||
      loadingS3 ||
      loadingConfirm ||
      loadingAdd ||
      loadingLink;

    if (isSendingFilesOrLink) {
      return true;
    }

    if (!searchedCase?.Id) {
      return true;
    }

    if (!!externalLink.length) {
      if (isValidHttpUrl(externalLink)) {
        return false;
      }
      return true;
    }

    if (!externalLink.length && !!files.length) {
      return false;
    }

    return true;
  };

  const handleError = () => {
    let payload = {
      case: false,
      files: false,
    };

    if (!searchedCase) {
      payload = { ...payload, case: true };
    }

    if (!externalLink.length || !!files.length) {
      payload = { ...payload, files: true };
    }

    setShowErrors(payload);
  };

  if (createCase) {
    return <CreateCaseModal />;
  }

  const refWrapper = useRef(null);

  return (
    <ModalLayout
      dock={DockPosition.DISABLED}
      off={true}
      customX={1}
      isAnimation
      customY={0}
      ignore
      refWrapper={refWrapper}
      fullHeight={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Nowy element
          <CloseIcon
            onClick={handleClickCloseModal}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.searchCase}>
            <Button
              onClick={() => {
                setAddLink(!addLinke);
              }}
              text={addLinke ? '+ Dodaj plik' : '+ Dodaj link'}
              className={styles.btn}
            />
          </div>

          <div className={styles.case}>
            <SearchCasesNew
              isError={showErrors.case ? 'Wybierz teczkę' : ''}
              setAddNewEntity={setCreateCase}
              defaultValue={caseId ? caseId : undefined}
              onChoose={(simpleCase) => setSearchedCase(simpleCase)}
            />
          </div>
          {addLinke && (
            <div className={styles.link}>
              <Input
                label="Tytuł"
                onChange={(t) => setTitle(t)}
                value={title}
              />
              <Input
                label="Link zewnętrzny"
                onChange={(t) => setExternalLink(t)}
                value={externalLink}
              />
            </div>
          )}
          {!addLinke && <Attachments />}
          {showErrors.files && (
            <div className={styles.error}>
              Wybierz pliki lub wpisz link zewnętrzny
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.button}
            onClick={handleCreatee}
            text="Dodaj element"
            loading={isLoading}
            handleError={handleError}
            disabled={isButtonActive()}
          />
        </div>
      </div>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={handleChooseFile}
      />
    </ModalLayout>
  );
};

export default CreateDossierModal;
