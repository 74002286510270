import { useContext, useEffect, useState } from 'react';
import { FC, Props } from './typings';
import pluginService from 'api/pluginService';
import { getKeys } from 'utils/getKeys';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { getUserIsActivated } from 'utils/getUserIsActivated';
import PluginUpdate from 'components/PluginUpdate';
import CPluginContext from 'contexts/PluginContext';
import { useNavigate } from 'react-router-dom';

const whitelisted = [
  'recovery',
  'choose-workspace',
  'activation',
  'invitation',
];

export const PluginContext: FC<Props> = ({}) => {
  const navigate = useNavigate();
  const userAgent = navigator.userAgent.toLowerCase();
  const url = window.location.href.split('/');
  const {
    data: pluginInformation,
    isFetching,
    isError,
  } = pluginService.useGetVersion();

  const { data: actualVersion } = pluginService.useActualVersion();
  const { mutate: createConnection } = pluginService.useCreateConnection();
  const { mutate: setConnection } = pluginService.useSetConnection();
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  var isActivated = getUserIsActivated(auth.user!);

  const { plugin, setPlugin } = useContext(CPluginContext);

  const [showPlugin, setShowPlugin] = useState(false);

  const connectPlugin = async () => {
    const access_token = auth.user?.access_token;

    if (plugin.version !== '0') return;
    if (!auth.isAuthenticated || !isActivated) return;
    const keys = await getKeys(userId);

    if (!pluginInformation?.Version) return;
    if (!actualVersion) return;

    //@ts-ignore
    // pluginInformation.Version = '1';

    const isWindows = userAgent.search('windows') !== -1;
    const isOS = userAgent.search('mac') !== -1;
    const isLinux = userAgent.search('linux') !== -1;

    let actual = true;

    if (whitelisted.some((keyword) => url.includes(keyword))) {
      return;
    }

    if (
      isWindows &&
      pluginInformation?.Version !== actualVersion.Windows.Version
    ) {
      actual = false;
      setShowPlugin(true);
    }
    if (isOS && pluginInformation?.Version !== actualVersion.MacOS.Version) {
      setShowPlugin(true);
      actual = false;
    }
    if (isLinux && pluginInformation?.Version !== actualVersion.Linux.Version) {
      setShowPlugin(true);
      actual = false;
    }
    setPlugin({
      actual: actual,
      isMac: isOS,
      isWindows: isWindows,
      linkMac: actualVersion.MacOS.DownloadUrl,
      linkWindows: actualVersion.Windows.DownloadUrl,
      version: pluginInformation.Version ?? '0',
    });

    // check plugin version here

    createConnection(
      {
        AppId: 'todo',
        Version: pluginInformation!.Version,
      },
      {
        onSuccess: (response) => {
          setConnection(
            {
              PrivateKey: keys!.intermidatePrivateKey!.data,
              PublicKey: keys!.publicBase64Key!,
              //Token: response.data.Token,
              Token: access_token!,
              Url: response.data.Url,
            },
            {
              onSuccess: (response) => {},
            }
          );
        },
      }
    );
  };

  useEffect(() => {
    connectPlugin();
  }, [isFetching, isError, pluginInformation, auth, actualVersion, navigate]);

  return (
    <>
      {showPlugin && (
        <PluginUpdate
          linkMac={actualVersion.MacOS.DownloadUrl ?? ''}
          linkWindows={actualVersion.Windows.DownloadUrl ?? ''}
          isWindows={userAgent.search('windows') !== -1}
          isMac={userAgent.search('mac') !== -1}
          handleClose={() => setShowPlugin(false)}
        />
      )}
    </>
  );
};

export default PluginContext;
