import { IClient } from 'dto/IClient';

export type { FC } from 'react';

export enum ETabs {
    PERSON,
    COMPANY,
    TEAM
}

export interface Props {
    onClose: () => void;
    label?: string;
    initialValue?: IClient
    onCreate?: (client: IClient) => void; 
    isCompany?: boolean;
}