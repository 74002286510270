import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_l.svg';
import settingService from 'api/settingsService';
import Input from 'components/Input';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import { getErrorMessage } from 'utils/getErrorMessage';
import Dropdown from 'components/Dropdown';
import { IDropdownObject } from 'components/Dropdown/typings';
import { ICalendarIntegrationSettings } from 'dto/Settings/ICalendarIntegrationSettings';
import classNames from 'classnames';
import { TwoFactorMethod } from 'dto/Settings/TwoFactorMethod';
import Spinner from 'components/Spinner';
import { IInitTotpResponse } from 'dto/Settings/IInitTotpResponse';
import { ENotificationType, notification } from 'utils/notification';
import ReactDOM from 'react-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { EdeliveryAddressContext } from 'dto/EDelivery/EdeliveryAddressContext';
import { EDeliveryPersonalRole } from 'dto/EDelivery/EDeliveryPersonalRole';
import { EDeliveryAddressStep } from 'dto/EDelivery/EDeliveryAddressStep';
import activationService, {
  ICreateEDeliveryAddresRequest,
} from 'api/activationService';
import { getUserClaim } from 'utils/getUserClaim';
import { NipStatus } from 'dto/EDelivery/NipStatus';
import { Claims } from 'dto/User/claims';
import SecondCheckbox from 'components/SecondCheckbox';
import { getTrustedRoles } from 'utils/edelivery/getTrustedRoles';
import { getLegalForms } from 'utils/edelivery/getLegalForms';
import SearchCode from 'components/SearchCode';
import SearchAddress from 'components/SearchAddress';
import SearchPlace from 'components/SearchPlace';

const CreateRequest: FC<Props> = ({ onCanceled, onSaved }) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);
  const [addressContext, setAddressContext] =
    useState<EdeliveryAddressContext | null>(null);
  const [personalRole, setPersonalRole] =
    useState<EDeliveryPersonalRole | null>(null);
  const [step, setStep] = useState<EDeliveryAddressStep | null>(
    EDeliveryAddressStep.CHOOSE_FOR
  );

  const { mutate: doCreateEdeliveryAddress, isLoading: isCreatingAddress } =
    activationService.useCreateEDeliveryAddress();

  const { mutate: doTransferAddress, isLoading: isTransferingAddress } =
    activationService.useTransferEDeliveryAddress();

  const [skipButtonState, setSkipButtonState] = useState({
    show: false,
    label: 'Pomiń',
    disabled: false,
  });
  const [prevButtonState, setPrevButtonState] = useState({
    show: false,
    label: 'Wstecz',
    disabled: false,
  });
  const [nextButtonState, setNextButtonState] = useState({
    show: false,
    label: '',
    disabled: false,
  });

  const [buisnessAddress, setBuisnessAddres] = useState(false);

  const [transferAddress, setTransferAddress] = useState(false);
  const [acceptRules, setAcceptRules] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [createdRequestSignature, setCreatedRequestSignature] =
    useState<string>('');

  const [addressRequest, setAddressRequest] = useState<{
    firstName: string;
    lastName: Nullable<string>;
    pesel: Nullable<string>;
    regon: Nullable<string>;
    krs: Nullable<string>;
    nip: Nullable<string>;
    nipAssigned: Nullable<NipStatus>;
    companyName: Nullable<string>;
    courtEnforcemantOfficerTitle: Nullable<string>;
    trustedRole: Nullable<number>;
    legalForm: Nullable<number>;
  }>({
    firstName: getUserClaim(auth.user!, Claims.Eidas_FirstName),
    lastName: getUserClaim(auth.user!, Claims.Eidas_FamilyName),
    pesel: getUserClaim(auth.user!, Claims.Eidas_PersonIdentifier),
    regon: '',
    krs: '',
    nip: '',
    nipAssigned: null,
    companyName: null,
    courtEnforcemantOfficerTitle: null,
    trustedRole: null,
    legalForm: null,
  });

  const [correspondenceAddress, setCorrespondenceAddress] = useState({
    zipCode: '',
    city: '',
    cityId: '',
    street: '',
    streetId: '',
    houseNumber: '',
    localNumber: '',
  });

  const prevStepInternal = () => {
    switch (step) {
      case EDeliveryAddressStep.CHOOSE_CONTEXT:
        setStep(EDeliveryAddressStep.CHOOSE_FOR);
        break;
      case EDeliveryAddressStep.ContextStep:
        break;
      case EDeliveryAddressStep.IdentityStep:
        setStep(EDeliveryAddressStep.CHOOSE_CONTEXT);
        break;
      case EDeliveryAddressStep.CompanyStep:
        setStep(EDeliveryAddressStep.IdentityStep);
        break;
      case EDeliveryAddressStep.AddressStep:
        setStep(
          addressContext === EdeliveryAddressContext.Personal
            ? EDeliveryAddressStep.IdentityStep
            : EDeliveryAddressStep.CompanyStep
        );
        break;
      case EDeliveryAddressStep.SummaryStep:
        setStep(EDeliveryAddressStep.AddressStep);
        break;
    }
  };

  const nextStepInternal = () => {
    switch (step) {
      case EDeliveryAddressStep.ContextStep:
        break;
      case EDeliveryAddressStep.IdentityStep:
        setStep(
          addressContext === EdeliveryAddressContext.Personal
            ? EDeliveryAddressStep.AddressStep
            : EDeliveryAddressStep.CompanyStep
        );
        break;
      case EDeliveryAddressStep.CompanyStep:
        setStep(EDeliveryAddressStep.AddressStep);
        break;
      case EDeliveryAddressStep.AddressStep:
        setStep(EDeliveryAddressStep.SummaryStep);
        break;
      case EDeliveryAddressStep.SummaryStep:
        setNextButtonState({ ...nextButtonState, disabled: true });
        send();
        break;
      case EDeliveryAddressStep.DoneStep:
        onSaved();
        break;
    }
  };

  useEffect(() => {
    if (auth.user) {
      setAddressRequest((prevState) => ({
        ...prevState,
        firstName: getUserClaim(auth.user!, Claims.Eidas_FirstName),
        lastName: getUserClaim(auth.user!, Claims.Eidas_FamilyName),
        pesel: getUserClaim(auth.user!, Claims.Eidas_PersonIdentifier),
      }));
    }
  }, [auth.user]);

  useEffect(() => {
    setAddressRequest({ ...addressRequest, trustedRole: null });
    setPersonalRole(null);
  }, [addressContext]);

  useEffect(() => {
    switch (step) {
      case EDeliveryAddressStep.CHOOSE_FOR:
        setPrevButtonState({ ...prevButtonState, show: false });
        setNextButtonState({ ...nextButtonState, show: false });
        break;
      case EDeliveryAddressStep.CHOOSE_CONTEXT:
        setPrevButtonState({ ...prevButtonState, show: true });
        setNextButtonState({ ...nextButtonState, show: false });
        break;
      case EDeliveryAddressStep.ContextStep:
        setSkipButtonState({ ...skipButtonState, show: true });
        setPrevButtonState({ ...prevButtonState, show: false });
        setNextButtonState({ ...nextButtonState, show: false });
        break;
      case EDeliveryAddressStep.IdentityStep:
      case EDeliveryAddressStep.CompanyStep:
      case EDeliveryAddressStep.AddressStep:
        setSkipButtonState({ ...skipButtonState, show: false });
        setPrevButtonState({ ...prevButtonState, show: true, label: 'Wstecz' });
        setNextButtonState({ ...nextButtonState, show: true, label: 'Dalej' });
        break;
      case EDeliveryAddressStep.SummaryStep:
        setSkipButtonState({ ...skipButtonState, show: false });
        setPrevButtonState({ ...prevButtonState, show: true, label: 'Wstecz' });
        setNextButtonState({
          ...nextButtonState,
          show: true,
          label: 'Złóż wniosek',
        });
        break;
      case EDeliveryAddressStep.DoneStep:
        setSkipButtonState({ ...skipButtonState, show: false });
        setPrevButtonState({
          ...prevButtonState,
          show: false,
          label: 'Wstecz',
        });
        setNextButtonState({
          ...nextButtonState,
          show: true,
          label: 'Zamknij',
          disabled: false,
        });
        break;
    }
  }, [step]);

  const send = () => {
    if (!transferAddress) {
      const req: ICreateEDeliveryAddresRequest = {
        Context:
          addressContext === EdeliveryAddressContext.Personal
            ? addressRequest.trustedRole ?? 8
            : 9,
        ProfessionalTitle: addressRequest.courtEnforcemantOfficerTitle,
        CompanyName: addressRequest.companyName,
        REGON: addressRequest.regon,
        KRS: addressRequest.krs,
        NIP: addressRequest.nip,
        NipStatus: addressRequest.nipAssigned,
        LegalForm: addressRequest.legalForm,
        CorrespondenceAddress: {
          BuildingNumber: correspondenceAddress.houseNumber,
          City: correspondenceAddress.city,
          FlatNumber: correspondenceAddress.localNumber,
          PostalCode: correspondenceAddress.zipCode,
          Street: correspondenceAddress.street,
        },
      };

      doCreateEdeliveryAddress(req, {
        onSuccess(data, variables, context) {
          if (data) {
            setCreatedRequestSignature(data.data.Signature);
            setStep(EDeliveryAddressStep.DoneStep);
          }
        },
        onError(e: any) {
          setNextButtonState({
            ...nextButtonState,
            disabled: false,
          });
          setErrorMessage(getErrorMessage(e.response.data));
        },
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.pathBar}>
        <div className={styles.backButton}>
          {/* <BackIcon onClick={onCanceled}></BackIcon> */}
        </div>
        {/* E-doręczenia <ArrowLeftIcon></ArrowLeftIcon> Złóż nowy wniosek */}
      </div>

      <div className={styles.wrapperCenter}>
        <div className={styles.content}>
          {step !== EDeliveryAddressStep.CHOOSE_FOR &&
            step !== EDeliveryAddressStep.CHOOSE_CONTEXT && (
              <div className={styles.steps}>
                <ol>
                  <li
                    className={classNames(
                      styles.step,
                      styles.stepFirst,
                      styles.step1,
                      step === EDeliveryAddressStep.IdentityStep
                        ? styles.activeStep
                        : ''
                    )}
                  >
                    <span>Twoje dane</span>
                  </li>
                  {addressContext === EdeliveryAddressContext.Company && (
                    <li
                      className={classNames(
                        styles.step,
                        styles.step2,
                        step === EDeliveryAddressStep.CompanyStep
                          ? styles.activeStep
                          : ''
                      )}
                    >
                      <span>Dane podmiotu</span>
                    </li>
                  )}
                  <li
                    className={classNames(
                      styles.step,
                      addressContext === EdeliveryAddressContext.Personal ||
                        !addressContext
                        ? styles.step2
                        : styles.step3,
                      step === EDeliveryAddressStep.AddressStep
                        ? styles.activeStep
                        : ''
                    )}
                  >
                    <span>Dane adresowe</span>
                  </li>
                  <li
                    className={classNames(
                      styles.step,
                      styles.stepLast,
                      addressContext === EdeliveryAddressContext.Personal ||
                        !addressContext
                        ? styles.step3
                        : styles.step4,
                      step === EDeliveryAddressStep.SummaryStep
                        ? styles.activeStep
                        : ''
                    )}
                  >
                    <span>Podsumowanie</span>
                  </li>
                </ol>
              </div>
            )}

          {step === EDeliveryAddressStep.CHOOSE_FOR && (
            <div className={styles.methods}>
              <div className={styles.hint}>
                {!transferAddress && (
                  <b>Wskaż w czyim imieniu składasz wniosek</b>
                )}
                {transferAddress && (
                  <b>
                    Aby przenieść adres do e-doręczeń wskaż dla kogo ma być
                    złożony wniosek o przeniesienie adresu.
                  </b>
                )}
                <br></br>
              </div>
              <div
                className={styles.method}
                onClick={() => {
                  setAddressContext(EdeliveryAddressContext.Personal);
                  setStep(EDeliveryAddressStep.CHOOSE_CONTEXT);
                }}
              >
                W imieniu własnym
              </div>
              <div
                className={styles.method}
                onClick={() => {
                  setAddressContext(EdeliveryAddressContext.Company);
                  setStep(EDeliveryAddressStep.CHOOSE_CONTEXT);
                }}
              >
                W imieniu osoby prawnej
              </div>
            </div>
          )}
          {step === EDeliveryAddressStep.CHOOSE_CONTEXT && (
            <div className={styles.methods}>
              <div className={styles.hint}>
                <b>Wybierz typ wniosku który chcesz złożyć</b>

                <br></br>
              </div>
              <div
                className={styles.method}
                onClick={() => {
                  setTransferAddress(false);

                  setStep(EDeliveryAddressStep.CHOOSE_ADDRESS);
                }}
              >
                Wniosek o utworzenie adresu
              </div>
              <div
                className={styles.method}
                onClick={() => {
                  setTransferAddress(true);

                  setStep(EDeliveryAddressStep.CHOOSE_ADDRESS);
                }}
              >
                Wniosek o przeniesienie adresu
              </div>
            </div>
          )}
          {step === EDeliveryAddressStep.CHOOSE_ADDRESS && (
            <div className={styles.methods}>
              <div className={styles.hint}>
                <b> Wskaż rodzaj adresu</b>

                <br></br>
              </div>
              <div
                className={styles.method}
                onClick={() => {
                  setBuisnessAddres(false);
                  setStep(EDeliveryAddressStep.IdentityStep);
                }}
              >
                Adres osobisty
              </div>
              <div
                className={styles.method}
                onClick={() => {
                  setBuisnessAddres(true);
                  setStep(EDeliveryAddressStep.IdentityStep);
                }}
              >
                Adres biznesowy
              </div>
            </div>
          )}
          {step === EDeliveryAddressStep.ContextStep && (
            <>
              <div className={classNames(styles.checkboxes, styles.horizontal)}>
                <SecondCheckbox
                  value={transferAddress === false ? true : false}
                  text="Utwórz adres do e-doręczeń"
                  onChange={(t) => {
                    setTransferAddress(false);
                  }}
                />
                <SecondCheckbox
                  value={transferAddress === true ? true : false}
                  text="Mam już adres do e-doręczeń"
                  onChange={(t) => {
                    setTransferAddress(true);
                  }}
                />
              </div>

              <div className={styles.hint}>
                {!transferAddress && (
                  <b>
                    Aby utworzyć adres do e-doręczeń wskaż dla kogo ma być
                    złożony wniosek o utworzenie adresu.
                  </b>
                )}
                {transferAddress && (
                  <b>
                    Aby przenieść adres do e-doręczeń wskaż dla kogo ma być
                    złożony wniosek o przeniesienie adresu.
                  </b>
                )}
                <br></br>
                Jeżeli będziesz potrzebował złożyć więcej wniosków z różnymi
                kontekstami, możesz to później zrobić w ustawieniach.
              </div>
            </>
          )}
          {step === EDeliveryAddressStep.IdentityStep && (
            <>
              <div className={styles.form}>
                {EdeliveryAddressContext.Company && (
                  <div className={styles.question}>
                    Kto występuje o wydanie adresu do doręczeń dla firmy /
                    instytucji
                  </div>
                )}

                <div className={styles.group}>
                  <Input
                    type="text"
                    label="Imię"
                    value={addressRequest.firstName}
                    onChange={() => {}}
                    disabled={true}
                  ></Input>
                  <Input
                    type="text"
                    label="Nazwisko"
                    value={addressRequest.lastName}
                    onChange={() => {}}
                    disabled={true}
                  ></Input>
                </div>
                <div className={styles.group}>
                  <Input
                    type="text"
                    label="PESEL"
                    value={addressRequest.pesel}
                    onChange={() => {}}
                    disabled={true}
                  ></Input>
                  {addressRequest.trustedRole === 14 && (
                    <>
                      <Input
                        type="text"
                        label="REGON"
                        value={addressRequest.regon}
                        onChange={(v) => {
                          setAddressRequest({ ...addressRequest, regon: v });
                        }}
                      ></Input>
                    </>
                  )}
                </div>
                {buisnessAddress && (
                  <Input
                    type="text"
                    label="Podaj nazwę pod jaką działasz"
                    value={''}
                    onChange={() => {}}
                    disabled={true}
                  ></Input>
                )}
                {addressContext === EdeliveryAddressContext.Personal && (
                  <div className={styles.checkboxes}>
                    {/* <SecondCheckbox
                      value={personalRole === EDeliveryPersonalRole.Citizen}
                      text="Dla obywatela (osoby fizycznej)"
                      onChange={(t) =>
                        setPersonalRole(EDeliveryPersonalRole.Citizen)
                      }
                    /> */}
                    {buisnessAddress && (
                      <>
                        <SecondCheckbox
                          value={false}
                          text="Prowadzę działalność gospodarczą"
                          onChange={(t) => {}}
                        />
                        <SecondCheckbox
                          value={
                            personalRole === EDeliveryPersonalRole.TrustedRole
                          }
                          text="Wykonuję zawód zaufania"
                          onChange={(t) =>
                            setPersonalRole(
                              personalRole === EDeliveryPersonalRole.TrustedRole
                                ? EDeliveryPersonalRole.Citizen
                                : EDeliveryPersonalRole.TrustedRole
                            )
                          }
                        />
                      </>
                    )}
                  </div>
                )}
                {personalRole === EDeliveryPersonalRole.TrustedRole && (
                  <div className={styles.trustedRole}>
                    <Dropdown
                      className={styles.trustedRoleDropdown}
                      onChange={(r) =>
                        setAddressRequest({
                          ...addressRequest,
                          trustedRole: r ? (r.value as number) : null,
                        })
                      }
                      list={getTrustedRoles()}
                      label="Zawód"
                      placeholder="Wybierz wykonywany zawód"
                      allowNull={true}
                      defaultValue={null}
                    />
                  </div>
                )}
                {addressRequest.trustedRole === 14 && (
                  <>
                    <Input
                      type="text"
                      label="Tytuł komornika"
                      value={addressRequest.courtEnforcemantOfficerTitle}
                      onChange={(v) => {
                        setAddressRequest({
                          ...addressRequest,
                          courtEnforcemantOfficerTitle: v,
                        });
                      }}
                    ></Input>
                  </>
                )}
              </div>
            </>
          )}
          {step === EDeliveryAddressStep.CompanyStep && (
            <>
              <div className={styles.form}>
                <div className={styles.group}>
                  <Input
                    type="text"
                    label="Nazwa podmiotu"
                    value={''}
                    onChange={(v) => {
                      setAddressRequest({ ...addressRequest, companyName: v });
                    }}
                  ></Input>
                  <Dropdown
                    list={getLegalForms()}
                    label="Forma prawna"
                    placeholder="Wybierz formę prawną"
                    allowNull={true}
                    defaultValue={null}
                    onChange={(r) =>
                      setAddressRequest({
                        ...addressRequest,
                        legalForm: r ? (r.value as number) : null,
                      })
                    }
                  ></Dropdown>
                </div>
                <div className={styles.group}>
                  <Input
                    type="text"
                    label="Numer KRS"
                    value={''}
                    onChange={(v) => {
                      setAddressRequest({ ...addressRequest, krs: v });
                    }}
                  ></Input>
                  <Input
                    type="text"
                    label="Numer REGON"
                    value={''}
                    onChange={(v) => {
                      setAddressRequest({ ...addressRequest, regon: v });
                    }}
                  ></Input>
                </div>
                <div className={styles.subHeader}>NIP podmiotu</div>
                <div className={styles.checkboxes}>
                  <SecondCheckbox
                    value={addressRequest.nipAssigned === NipStatus.Assigned}
                    text="Podmiot ma NIP"
                    onChange={(r) =>
                      setAddressRequest({
                        ...addressRequest,
                        nipAssigned: NipStatus.Assigned,
                      })
                    }
                  />
                  <SecondCheckbox
                    value={addressRequest.nipAssigned === NipStatus.Blank}
                    text="NIP nie został nadany"
                    onChange={(r) =>
                      setAddressRequest({
                        ...addressRequest,
                        nipAssigned: NipStatus.Blank,
                        nip: null,
                      })
                    }
                  />
                  <SecondCheckbox
                    value={addressRequest.nipAssigned === NipStatus.Canceled}
                    text="NIP został unieważniony"
                    onChange={(r) =>
                      setAddressRequest({
                        ...addressRequest,
                        nipAssigned: NipStatus.Canceled,
                        nip: null,
                      })
                    }
                  />
                  <SecondCheckbox
                    value={addressRequest.nipAssigned === NipStatus.Revoked}
                    text="NIP został uchylony"
                    onChange={(r) =>
                      setAddressRequest({
                        ...addressRequest,
                        nipAssigned: NipStatus.Revoked,
                        nip: null,
                      })
                    }
                  />
                </div>
                {addressRequest.nipAssigned === NipStatus.Assigned && (
                  <Input
                    type="text"
                    label="Numer NIP"
                    value={''}
                    onChange={(v) => {
                      setAddressRequest({ ...addressRequest, nip: v });
                    }}
                  ></Input>
                )}
              </div>
            </>
          )}
          {step === EDeliveryAddressStep.AddressStep && (
            <>
              <div className={styles.form}>
                <div className={styles.subHeader}>Adres korespondencyjny</div>
                <div className={styles.group}>
                  <SearchCode
                    // isError={
                    //   touched.ZipCode ? (errors.ZipCode as string) : ''
                    // }
                    defaultValue={correspondenceAddress.zipCode}
                    onChange={(t) => {
                      setCorrespondenceAddress({
                        ...correspondenceAddress,
                        zipCode: t,
                      });
                    }}
                  />
                  <SearchPlace
                    // isError={touched.City ? (errors.City as string) : ''}
                    defaultValue={correspondenceAddress.city}
                    onChange={(t, c) => {
                      setCorrespondenceAddress({
                        ...correspondenceAddress,
                        city: t,
                        cityId: c,
                      });
                    }}
                  />
                </div>
                <SearchAddress
                  // isError={touched.Address ? (errors.Address as string) : ''}
                  defaultValue={correspondenceAddress.street}
                  cityId={correspondenceAddress.cityId}
                  onChange={(t, c) => {
                    setCorrespondenceAddress({
                      ...correspondenceAddress,
                      street: t,
                      streetId: c,
                    });
                  }}
                />
                <div className={styles.group}>
                  <Input
                    type="text"
                    label="Numer domu"
                    defaultValue={correspondenceAddress.houseNumber}
                    onChange={(v) => {
                      setCorrespondenceAddress({
                        ...correspondenceAddress,
                        houseNumber: v,
                      });
                    }}
                  ></Input>
                  <Input
                    type="text"
                    label="Numer lokalu"
                    defaultValue={correspondenceAddress.localNumber}
                    onChange={(v) => {
                      setCorrespondenceAddress({
                        ...correspondenceAddress,
                        localNumber: v,
                      });
                    }}
                  ></Input>
                </div>
              </div>
            </>
          )}
          {step === EDeliveryAddressStep.SummaryStep && (
            <>
              <div className={styles.hint}>
                <b>Twój wniosek jest gotów do wysłania</b>
              </div>
              <SecondCheckbox
                value={acceptRules}
                text={
                  transferAddress
                    ? 'Zgadzam się na zmianę operatora mojego adresu do doręczeń elektronicznych. Nowym operatorem będzie kwalifikowany dostawca usług zaufania KFJ Inwestycje.'
                    : 'Zgadzam się na utworzenie adresu do doręczeń elektronicznych, którego operatorem będzie kwalifikowany dostawca usług zaufania KFJ Inwestycje.'
                }
                onChange={(t) => {
                  setAcceptRules(t);
                }}
              />
              {errorMessage && (
                <div className={styles.warn}>
                  <b>{errorMessage}</b>
                </div>
              )}
              {(isCreatingAddress || isTransferingAddress) && (
                <div className={styles.verificaionInProgress}>
                  <Spinner className={styles.spinner} />
                  <p>Trwa składanie wniosku</p>
                </div>
              )}
            </>
          )}
          {step === EDeliveryAddressStep.DoneStep && (
            <>
              <div className={styles.hint}>
                <b>Twój wniosek został złożony</b>
              </div>
              <div className={styles.subHeader}>Identyfikator wniosku:</div>
              <div className={styles.subHeader}>{createdRequestSignature}</div>
            </>
          )}
        </div>
      </div>
      <div className={styles.buttons}>
        {skipButtonState.show && (
          <HollowButton
            className={styles.button}
            text="Anuluj"
            onClick={onCanceled}
          ></HollowButton>
        )}
        {prevButtonState.show && (
          <HollowButton
            className={styles.button}
            text={prevButtonState.label}
            onClick={prevStepInternal}
          ></HollowButton>
        )}
        {nextButtonState.show && (
          <Button
            className={styles.button}
            text={nextButtonState.label}
            onClick={nextStepInternal}
          ></Button>
        )}
      </div>
      <div className={classNames(styles.hint, styles.disconnect)}></div>
    </div>
  );
};

export default CreateRequest;
