import casesService from 'api/casesService';
import deskService from 'api/deskService';
import fileService from 'api/fileService';
import listService from 'api/listService';
import pluginService from 'api/pluginService';
import tagsService from 'api/tagsService';
import ActionsCalendar from 'components/ActionsCalendar';
import ActionsList from 'components/ActionsList';
import AddTagModal from 'components/AddTagModal';
import CaseChat from 'components/CaseChat';
import CaseDossier from 'components/CaseDossier';
import CaseInfo from 'components/CaseInfo';
import CaseLetters from 'components/CaseLetters';
import CaseMenu from 'components/CaseMenu';
import CaseOptionsCard from 'components/CaseOptions';
import CasePerson from 'components/CasePerson';
import CaseThreadsModal from 'components/CaseThreadsModal';
import ConfirmModal from 'components/ConfirmModal';
import CreateFolder from 'components/CreateFolder';
import EmptyPanel from 'components/EmptyPanel';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import PDFViewer from 'components/PDFViever';
import PreviewLetter from 'components/PreviewLetter';
import { RedirectModal } from 'components/RedirectModal';
import SidebarAddingOptions from 'components/SidebarAddingOptions';
import StatusBadge from 'components/StatusBadge';
import StatusModal from 'components/StatusModal';
import TagBadge from 'components/TagBadge';
import { config } from 'config';
import CasesContext from 'contexts/CasesContext';
import KeysContext from 'contexts/KeysContext';
import CPluginContext from 'contexts/PluginContext';
import settingsContext, { IOptionType } from 'contexts/SettingsContext';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IAttachment } from 'dto/IAttachment';
import { ItemKind } from 'dto/IKindItems';
import { IPackage } from 'dto/IPackage';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { blobToBase64 } from 'utils/blobToBase64';
import { decryptFile } from 'utils/decryptFile';
import { fetchEmailAttachment } from 'utils/fetchEmailAttachment';
import { getBlob } from 'utils/getBlob';
import { getUserId } from 'utils/getUserId';
import { isAllowedAttachment } from 'utils/isAllowedAttachment';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { ECaseViews } from './dto/ECaseViews';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import Tasks from 'components/Tasks';
import { ref } from 'yup';
import { IDossierItem } from 'dto/Dossier/IDossierItem';

const CaseView: FC<Props> = ({
  setSelectedEdit,
  tags,
  statuses,
  hideMenu = false,
  selectedThread,
  setSelectedThread,
  createNewThread,
  setCreateNewThread,
  showCase = false,
  setPack,
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { caseId, view, kind, messageId, attachmentId, encryptionKey } =
    useParams();
  const [showRedirect, setShowRedirect] = useState(false);
  const [currentDossierFolder, setCurrentDossierFolder] =
    useState<IDossierItem | null>(null);
  const option: any = localStorage.getItem(config.cases_actions);
  const [actionsView, setActionsView] = useState<
    | 'dayGridWeek'
    | 'dayGridMonth'
    | 'dayGridDay'
    | 'listWeek'
    | 'list'
    | 'tasks'
  >(option ?? 'dayGridMonth');

  const { cases: casesContext, setCases: setCasesContext } =
    useContext(CasesContext);
  const { data: caseData, isSuccess: caseDataSuccess } =
    casesService.useGetCase(caseId ?? '');

  const optionsRef = useRef(null);
  const infoRef = useRef(null);
  const threadsRef = useRef(null);
  const Case = useMemo(() => (caseDataSuccess ? caseData : null), [caseData]);

  const { mutate: removeTag } = tagsService.useRemoveTagFromPackage();
  const { mutate: redirect } = casesService.useRedirect();
  const [showPDF, setShowPDF] = useState<Blob | string | null>(null);
  const [originalFile, setOriginalFile] = useState<Blob | string | null>(null);

  const { data: previewPackageData, isLoading: isPreviewPackageLoading } =
    listService.useGetPackage({
      FilterId: messageId ?? '',
      Kind: kind ?? '',
      enabled: !hideMenu && !!messageId,
    });

  useEffect(() => {
    setShowPDF(null);
    setOriginalFile(null);
  }, [messageId, attachmentId]);

  const previewPackage: IPackage = useMemo(
    () => previewPackageData?.data?.Items?.[0] ?? [],
    [previewPackageData]
  );

  const { mutate: getFile } = fileService.useGetFiltersForCases();
  const parentKeys = useMemo(
    () => previewPackageData?.data.Keys ?? null,
    [previewPackageData]
  );

  const { keys, setKeys } = useContext(KeysContext);

  // useEffect(() => {
  //   setKeys([...keys, parentKeys].flat());
  // }, [parentKeys]);

  useEffect(() => {
    if (!caseData) return;
    //@ts-ignore
    const k = caseData as any;

    let uploadKeys: any = [];

    if (k) {
      uploadKeys = [...uploadKeys, keys].flat();
    }

    if (parentKeys) {
      uploadKeys = [...uploadKeys, parentKeys].flat();
    }

    if (isFullScreen) {
      setCasesContext([Case!, ...casesContext]);
    }

    //@ts-ignore
    setKeys((prev) => [...prev, uploadKeys].flat());
  }, [caseData, parentKeys]);

  const { mutate: tryPreview } = pluginService.usePreview();

  const { plugin } = useContext(CPluginContext);
  const [showVersion, setShowVersion] = useState(false);

  const { mutate: deleteCase } = casesService.useDeleteCase();
  const { cases, setCases } = useContext(CasesContext);

  const previewFromPlugin = async (blob: Blob, attachment: IAttachment) => {
    const b: string = (await blobToBase64(blob)) as string;

    if (!plugin.actual) {
      setShowVersion(true);
      return;
    }

    tryPreview(
      {
        AttachmentId: attachment.Id,
        Content: b.split(',')[1],
        ContentType: attachment.ContentType,
        DocumentId: attachment.Id,
        FileName: attachment.FileName,
        Kind: attachment.Kind,
      },
      {
        onSuccess: async (response: any) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setShowPDF(new Blob([buffer]));
        },
      }
    );
  };

  const getFileAndDecrypt = (uri: string) => {
    getFile(
      {
        id: uri,
        ownerKind: previewPackage.Kind,
      },
      {
        onSuccess: async (data) => {
          let parentKey = null;

          if (previewPackageData?.data.ObjectKeys[0]) {
            parentKey = parentKeys.find(
              (pkey: any) =>
                pkey.Id === previewPackageData?.data.ObjectKeys[0].ParentKeyId
            );
          }

          const extension = data.data.FileName.split('.').at(-1);
          const isAllowed = isAllowedAttachment(extension ?? 'no');
          const isPDF = extension === 'pdf';

          const file = await getBlob(data.data.Url);
          try {
            const decryptedBlob = await decryptFile(
              userId,
              new Blob([file.data]),
              previewPackageData?.data.ObjectKeys[0],
              parentKey
            );

            if (!isPDF) {
              previewFromPlugin(decryptedBlob, data.data as any);
              setOriginalFile(decryptedBlob);
              return;
            }
            setShowPDF(decryptedBlob);
            setOriginalFile(decryptedBlob);
          } catch (e) {
            if (!isPDF) {
              previewFromPlugin(new Blob([file.data]), data.data as any);
              return;
            }
            setShowPDF(new Blob([file.data]));
            setOriginalFile(new Blob([file.data]));
          }
        },
      }
    );
  };

  const onPreview = async (attachment: IAttachment) => {
    getFileAndDecrypt(attachment.Uri);
  };

  useEffect(() => {
    if (!!previewPackage && messageId) {
      if (
        previewPackage.Kind == ItemKind.CaseFile ||
        previewPackage.Kind == ItemKind.EditorDocument
      ) {
        getFileAndDecrypt(previewPackage.S4 ?? previewPackage.S3 ?? '');
        return;
      }

      const attach = previewPackage?.Attachments?.find(
        (attachment) => attachment.Id === attachmentId
      );
      if (!attach) return;
      onPreview(attach);
    }
  }, [previewPackage]);

  const [showInfo, setShowInfo] = useState(false);
  const [showPerson, setShowPerson] = useState(false);
  const [showThreads, setShowThreads] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showFlags, setShowFlags] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (!showInfo) return;
    setShowPerson(false);
    setShowThreads(false);
    setCreateNewThread(false);
    setShowCreateFolder(false);
    setCurrentDossierFolder(null);
    setShowTags(false);
    setShowFlags(false);
    setShowPerson(false);
  }, [showInfo]);

  useEffect(() => {
    if (!showPerson) return;
    setShowInfo(false);
    setShowThreads(false);
    setCreateNewThread(false);
    setShowCreateFolder(false);
    setShowTags(false);
    setShowFlags(false);
  }, [showPerson]);

  useEffect(() => {
    if (!showThreads) return;
    setShowInfo(false);
    setShowPerson(false);
    setCreateNewThread(false);
    setShowCreateFolder(false);
    setShowTags(false);
    setShowFlags(false);
    setShowPerson(false);
  }, [showThreads]);
  useEffect(() => {
    if (!createNewThread) return;
    setShowInfo(false);
    setShowPerson(false);
    setShowThreads(false);
    setShowCreateFolder(false);
    setShowTags(false);
    setShowFlags(false);
    setShowPerson(false);
  }, [createNewThread]);
  useEffect(() => {
    if (!showTags) return;
    setShowInfo(false);
    setShowPerson(false);
    setShowThreads(false);
    setCreateNewThread(false);
    setShowFlags(false);
    setShowPerson(false);
  }, [showTags]);
  useEffect(() => {
    if (!showFlags) return;
    setShowInfo(false);
    setShowPerson(false);
    setShowThreads(false);
    setCreateNewThread(false);
    setShowTags(false);
    setShowPerson(false);
  }, [showFlags]);

  const navigate = useNavigate();

  useEffect(() => {
    setCreateNewThread(false);
    setSelectedThread(null);
  }, [Case]);

  const handleClose = () => {
    if (showPDF) navigate(-1);
    else {
      navigate(`/cases`);
    }
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [sort, setSort] = useState(
    localStorage.getItem('chatSort') ?? 'Ascending'
  );

  const contentPanel = () => {
    if (showVersion) {
      <div className={styles.PDFWrapper}>
        <PDFViewer
          sourceFile={originalFile}
          file={new Blob([])}
          showHeader={true}
          showVersion={true}
        />
      </div>;
    }

    if (showPDF) {
      return (
        <div className={styles.PDFWrapper}>
          <PDFViewer
            showTitle
            file={showPDF}
            sourceFile={originalFile}
            showHeader={true}
          />
        </div>
      );
    }

    if (!caseId?.length) {
      return <EmptyPanel />;
    }

    if (view === ECaseViews.PARTIAL) {
      return (
        <div className={styles.access}>
          <div className={styles.titlee}>Udostępniona teczka</div>
          <div className={styles.body}>
            Oczekujesz na udostępnienie teczki przez właściciela teczki
          </div>
        </div>
      );
    }

    if (encryptionKey && !showCase) {
      return <PreviewLetter />;
    }

    if (view === ECaseViews.LETTER && !!showCase && !isFullScreen) {
      return <PreviewLetter />;
    }

    if (view === ECaseViews.TASKS) {
      return <Tasks />;
    }

    if (view === ECaseViews.CHAT || view === ECaseViews.PACKAGES) {
      return (
        <CaseChat
          tags={tags}
          statuses={statuses}
          createNewThread={createNewThread}
          setCreateNewThread={setCreateNewThread}
          setSelectedThread={setSelectedThread}
          thread={selectedThread}
          hideMenu={hideMenu}
          setPack={setPack}
          sort={sort}
        />
      );
    }
    if (view === ECaseViews.DOSSIER) {
      return (
        <CaseDossier
          setShowCreateFolder={setShowCreateFolder}
          setCurrentFolderToCaseView={setCurrentDossierFolder}
          Case={Case}
        />
      );
    }
    if (view === ECaseViews.ACTIONS) {
      if (actionsView === 'tasks') {
        return <Tasks />;
      }
      if (actionsView === 'list') {
        return <ActionsList date={new Date()} caseId={caseId} />;
      }
      return (
        <ActionsCalendar
          initialValues={{
            case: caseData,
          }}
          showCase={false}
          type={actionsView}
          date={selectedDate}
          caseId={caseId}
        />
      );
    }
  };
  const [status, setStatus] = useState<IStatus | null>(null);
  const statusRef = useRef(null);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);

  useEffect(() => {
    if (Case) {
      const newStatus = statuses.find((s: IStatus) => s.Id === Case.StatusId);
      setStatus(newStatus ?? null);

      const t = tags?.filter((tag: ITag) =>
        Case?.TagIds?.find((iTag) => tag.Id === iTag)
      );
      setFindedTags(t);
    }
  }, [casesContext, Case, Case?.TagIds]);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        OwnerId: Case?.Id ?? '',
        OwnerKind: ItemKind.Cases,
        TagId: tag.Id,
      },
      {
        onSuccess: () => {},
      }
    );
    setFindedTags(findedTags.filter((ftag) => ftag.Id !== tag.Id));
  };

  const handleUpdateTags = (tags: ITag[]) => {
    let newCases = [...casesContext];

    let caseItem = newCases.find((c) => c.Id === c.Id);

    if (caseItem) {
      caseItem.TagIds = tags.map((t) => t.Id);
    }

    Case!.TagIds = tags.map((t) => t.Id);

    setCasesContext(newCases);
  };

  const handleNewTab = () => {
    if (showPDF) {
      window.open(
        `${
          process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'
        }/pdf/${previewPackage.Kind}/${previewPackage.Id}/${previewPackage.Id}`
      );
      return;
    }

    window.open(
      `${
        process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'
      }/fullscreen/cases/${Case?.Id}/chat`
    );
  };

  const handleChangeAction = (
    v:
      | 'dayGridWeek'
      | 'dayGridMonth'
      | 'dayGridDay'
      | 'listWeek'
      | 'list'
      | 'tasks'
  ) => {
    localStorage.setItem(config.cases_actions, v);
    setActionsView(v);
  };
  const { mutate: setDesk } = deskService.useSetDesk();

  let caseContext = casesContext.find((c) => c.Id === Case?.Id);

  if (!caseContext && Case) {
    caseContext = Case;
  }

  const isFullScreen = window.location.href.search('fullscreen') !== -1;

  const { refetch } = casesService.useGetDossier(
    caseId ?? Case?.Id ?? '',
    currentDossierFolder?.Id,
    false
  );

  const addButtonRef = useRef(null);
  const [options, setOptions] = useState(false);

  const { options: opt } = useContext(settingsContext);
  const h = opt.find((f) => f.Type === IOptionType.hoverMenuPreview)?.Value;

  const useMenu = h === 'true';

  return (
    <>
      <div className={styles.layout}>
        {showFlags && Case && (
          <ModalLayout dock={DockPosition.BottomLeft} refWrapper={statusRef}>
            <StatusModal
              toggle={setShowFlags}
              item={caseContext}
              selectedStatus={status}
              afterUpdate={(s) => setStatus(s ?? null)}
            />
          </ModalLayout>
        )}
        {showThreads && (
          <ModalLayout dock={DockPosition.BottomRight} refWrapper={optionsRef}>
            <CaseThreadsModal
              setCreateNewThread={setCreateNewThread}
              toggle={setShowThreads}
              onSelectThread={setSelectedThread}
            />
          </ModalLayout>
        )}

        {((!!caseId?.length && !showPDF && view !== ECaseViews.LETTER) ||
          showCase) && (
          <div
            style={{ display: showPDF ? 'none' : 'flex' }}
            className={styles.header}
          >
            <div className={styles.left}>
              {isFullScreen && (
                <AddIcon
                  ref={addButtonRef}
                  onClick={() => setOptions(!options)}
                  className={styles.addIcon}
                />
              )}
              <div>
                {!!Case?.Id && !showPDF && (
                  <div className={styles.title}>
                    {caseContext?.Title &&
                      caseContext?.Title?.replaceAll?.('<em>', '').replaceAll?.(
                        '</em>',
                        ''
                      )}
                  </div>
                )}
                <div className={styles.subTitle}>
                  {status && (
                    <div className={styles.badgesWrapper}>
                      {status && (
                        <div
                          onClick={() => {
                            setShowFlags(!showFlags);
                          }}
                          ref={statusRef}
                          className={styles.badge}
                        >
                          <StatusBadge
                            showName={true}
                            onRemove={() => {}}
                            status={status}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {!!findedTags.length && (
                    <div className={styles.tags}>
                      {findedTags.map((tag) => (
                        <TagBadge
                          onRemove={(tag) => handleRemoveTag(tag)}
                          tag={tag}
                        />
                      ))}
                    </div>
                  )}

                  <div className={styles.client}>
                    {caseContext?.Signature
                      ? caseContext?.Signature?.replaceAll(
                          '<em>',
                          ''
                        ).replaceAll('</em>', '') ?? ''
                      : ''}
                  </div>
                  <div className={styles.client}>
                    {Case?.Participants?.length === 1 ? (
                      <div
                        data-tooltip-id="bar"
                        data-tooltip-content={Case?.Participants?.[0]?.Label}
                      >
                        {Case?.Client}
                      </div>
                    ) : (
                      <div
                        data-tooltip-id="bar"
                        data-tooltip-content={
                          Case?.Participants?.map((c) => `${c?.Label}`).join(
                            ', '
                          ) ?? ''
                        }
                      >
                        {Case?.Client}
                      </div>
                    )}
                    {/* {caseContext?.Client ??
                      Case?.Client?.replaceAll('<em>', '').replaceAll(
                        '</em>',
                        ''
                      ) ??
                      ''} */}
                  </div>
                </div>
              </div>
            </div>
            <Tooltip
              style={{
                zIndex: 99999,
              }}
              place="bottom"
              id={`bar`}
            />
            <div className={styles.right}>
              {isFullScreen && (
                <div className={styles.add}>
                  {options && (
                    <SidebarAddingOptions
                      addButtonRef={addButtonRef}
                      toggle={setOptions}
                    />
                  )}
                </div>
              )}
              <div ref={optionsRef} className={styles.options}>
                {optionsRef.current && useMenu && !showPDF && (
                  <CaseOptionsCard
                    showDropdown={false}
                    showOptions={true}
                    showSomething={true}
                    pack={Case as ICaseSimple}
                    refetch={() => {}}
                    refWrapper={optionsRef}
                    onClickEdit={() => setSelectedEdit?.(Case!)}
                    onClickAttachIcon={() => {}}
                    toggle={() => {}}
                    posY={20}
                    posX={1}
                    threadsRef={threadsRef}
                    setShowThreads={setShowThreads}
                    showThreads={showThreads}
                    showCreateFolder={showCreateFolder}
                    setShowCreateFolder={setShowCreateFolder}
                    view={view as ECaseViews}
                  />
                )}
                {/* <DeskIcon
                  className={caseContext?.IsDeskActive ? styles.active : ''}
                  title={caseContext?.IsDeskActive ? 'Odepnij' : 'Przypnij'}
                  onClick={() =>
                    handleAddToDesk(
                      setDesk,
                      Case!,
                      casesContext,
                      setCasesContext
                    )
                  }
                />
                <HashIcon
                  title="Taguj"
                  onClick={() => {
                    setShowFlags(false);
                    setShowTags(true);
                  }}
                />
                {!isFullScreen && (
                  <NewTabIcon title="Nowa zakładka" onClick={handleNewTab} />
                )}
                {view !== ECaseViews.DOSSIER ? (
                  <ThreadsIcon
                    title="Wątki"
                    onClick={() => setShowThreads(!showThreads)}
                    ref={threadsRef}
                  />
                ) : (
                  <ThreadsIcon
                    title="Foldery"
                    onClick={() => setShowCreateFolder(!showCreateFolder)}
                    ref={threadsRef}
                  />
                )}
                <ClientIcon
                  title="Podmioty"
                  onClick={() => setShowPerson(!showPerson)}
                />
                <InfoIcon
                  title="Metryka teczki"
                  onClick={() => setShowInfo(!showInfo)}
                  ref={infoRef}
                />
                <RedirectIcon
                  title="Przekaż"
                  className={styles.reverse}
                  onClick={() => setShowRedirect(!showInfo)}
                  ref={infoRef}
                />
                <BinIcon
                  title="Usuń"
                  className={styles.reverse}
                  onClick={() => setShowDelete(true)}
                  ref={infoRef}
                /> */}
              </div>
            </div>

            {showCreateFolder && (
              <ModalLayout
                dock={DockPosition.DISABLED}
                off={true}
                customX={1}
                isAnimation={true}
                customY={0}
                ignore
                fullHeight={true}
              >
                <CreateFolder
                  caseId={caseId ?? ''}
                  parentId={currentDossierFolder?.DossierId}
                  refetch={refetch}
                  toggle={setShowCreateFolder}
                />
              </ModalLayout>
            )}

            {showInfo && Case && (
              <ModalLayout
                customY={-46}
                toggle={() => setShowInfo(false)}
                refWrapper={optionsRef}
              >
                <CaseInfo
                  Case={Case}
                  toggle={() => setShowInfo(false)}
                  statuses={statuses}
                  tags={tags}
                />
              </ModalLayout>
            )}
            {showPerson && Case && (
              <ModalLayout className={styles.disable} refWrapper={optionsRef}>
                <CasePerson toggle={() => setShowPerson(false)} Case={Case} />
              </ModalLayout>
            )}
            {showTags && Case && (
              <ModalLayout customX={-90} customY={-46} refWrapper={optionsRef}>
                <AddTagModal
                  toggle={setShowTags}
                  updateTags={handleUpdateTags}
                  toTag={Case}
                />
              </ModalLayout>
            )}
          </div>
        )}
        {showDelete && (
          <ConfirmModal
            title="Potwierdź usunięcie"
            text="Wykonanie akcji usunięcia przesyłki nieodwracanie usunie teczke z listy"
            acceptLabel="Usuń"
            cancelLabel="Anuluj"
            onAccept={() => {
              setShowDelete(false);
              let newCases = [...cases];
              setCases(newCases.filter((p) => p.Id !== Case!.Id));
              deleteCase(Case!, {
                onSuccess: () => {
                  if (isFullScreen) {
                    window.close();
                  }
                  navigate(`/cases/${newCases[1].Id}/chat`);
                },
                onError: () => {},
              });
            }}
            onCancel={() => setShowDelete(false)}
          />
        )}
        {!!caseId?.length && (
          <>
            {!showPDF &&
              view !== ECaseViews.LETTER &&
              showCase &&
              view !== ECaseViews.PARTIAL && (
                <div className={styles.menuWrapper}>
                  <CaseMenu
                    Case={Case}
                    setSort={setSort}
                    sort={sort}
                    action={actionsView}
                    onChangeAction={handleChangeAction}
                    hideMenu={hideMenu}
                  />
                </div>
              )}
            {showCase && view === ECaseViews.LETTER && !showPDF && (
              <div className={styles.menuWrapper}>
                <CaseMenu
                  Case={Case}
                  setSort={setSort}
                  sort={sort}
                  action={actionsView}
                  onChangeAction={handleChangeAction}
                  hideMenu={hideMenu}
                />
              </div>
            )}
            <div
              className={`${styles.rightY} ${
                (showPDF || view === ECaseViews.LETTER) && styles.showPDF
              } ${
                view === ECaseViews.ACTIONS &&
                actionsView === 'tasks' &&
                styles.remove
              }`}
            >
              <div className={styles.content}>{contentPanel()}</div>
            </div>
          </>
        )}
      </div>
      {showRedirect && (
        <RedirectModal
          onCancel={() => setShowRedirect(false)}
          // onSelect={handleRedirect}
          Case={Case!}
          toggle={() => {
            setShowRedirect(false);
          }}
        />
      )}
    </>
  );
};

export default CaseView;
