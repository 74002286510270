import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';

const queryKeys = {
  getStatement: 'activationService.getStatement',
};

const uploadStatement = (file: File) => {
  var formData = new FormData();
  formData.append('file', file);

  return axiosInstance.post(`/activate/statement`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const useUploadStatement = () => {
  return useMutation(uploadStatement);
};

const getStatement = ({}) => {
  return axiosInstance.get(`/activate/statement`, {
    responseType: 'blob',
  });
};

const useGetStatement = () => {
  return useMutation(getStatement);
};

const getMObywatelQRcode = ({}) => {
  return axiosInstance.get(`/mobywatel/qrcode`);
};

const useGetMObywatelQRcode = () => {
  return useMutation(getMObywatelQRcode);
};

export interface ICreateEDeliveryAddresRequest {
  Context: number;

  ProfessionalTitle?: string | null;
  CompanyName?: string | null;
  NIP?: string | null;
  REGON?: string | null;
  KRS?: string | null;

  LegalForm?: number | null;
  NipStatus?: number | null;

  CorrespondenceAddress?: IAddress;
  HeadquartersAddress?: IAddress;
}

export interface ITransferEDeliveryAddresRequest
  extends ICreateEDeliveryAddresRequest {
  EDeliveryAddress: string;
}

export interface IAddress {
  Street?: string;
  PostalCode?: string;
  City?: string;
  BuildingNumber?: string;
  FlatNumber?: string;
  PostalOffice?: string;

  PostOfficeBox?: string;
  Voivodeship?: string;
  District?: string;
  Community?: string;
}

export interface ICreateEDeliveryAddressResponse {
  Id: string;
  Signature: string;
}

const createEDeliveryAddress = (payload: ICreateEDeliveryAddresRequest) => {
  return axiosInstance.post('/edelivery/address/create', payload);
};

const useCreateEDeliveryAddress = () => {
  return useMutation(createEDeliveryAddress);
};

const transferEDeliveryAddress = (payload: ITransferEDeliveryAddresRequest) => {
  return axiosInstance.post('/edelivery/address/transfer', payload);
};

const useTransferEDeliveryAddress = () => {
  return useMutation(transferEDeliveryAddress);
};

export default {
  useGetStatement,
  useUploadStatement,
  useCreateEDeliveryAddress,
  useTransferEDeliveryAddress,
  useGetMObywatelQRcode,
};
