import { FC, Props } from './typings';
import styles from './styles.module.scss';
import classNames from 'classnames';

const HollowButton: FC<Props> = ({ className = '', text, onClick }) => {
  return (
    <div onClick={onClick} className={classNames(styles.button, className)}>
      {text}
    </div>
  );
};

export default HollowButton;
