import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as HashIcon } from 'assets/icons/hash2.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';
import NewInput from 'components/NewInput';
import { useOnClickOutside } from 'usehooks-ts';
import { getWorkspaceId } from 'utils/getWorkspaceId';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const ChooseTagNew: FC<Props> = ({
  onChoose,
  label,
  type = -1,
  initOpen = false,
  onClose,
  className = '',
}) => {
  const currentWorkspaceId = getWorkspaceId();
  const [active, setActive] = useState(false);
  const { mutate: createTag } = tagsService.useCreateTag();
  const { data: tagsData } = tagsService.useGetTags(
    false,
    type,
    undefined,
    currentWorkspaceId ?? ''
  );

  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const [search, setSearch] = useState<ITag[]>([]);
  const [value, setValue] = useState('');

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }

    if (onClose && !label) {
      onClose();
    }
  });

  useEffect(() => {
    if (!value.length) {
      setSearch(tags);
      return;
    }

    setSearch(
      tags.filter(
        (tag: ITag) =>
          tag.Name.toLocaleLowerCase().search(value.toLocaleLowerCase()) !== -1
      )
    );
  }, [tags, value]);

  const addTag = (tag: ITag) => {
    createTag(
      {
        Name: tag.Name,
        Type: 0,
      },
      {
        onSuccess: (response) => {
          setActive(false);
        },
      }
    );
  };

  useEffect(() => {
    if (!initOpen) return;
    setActive(true);
  }, [initOpen]);

  return (
    <div ref={outsideRef} className={`${styles.wrapper}`}>
      <div
        onClick={() => setActive(!active)}
        className={`${styles.card} ${className}`}
      >
        <div className={styles.left}>
          <HashIcon className={styles.icon} /> {label ? label : 'Tagi'}{' '}
        </div>

        <div className={styles.right}>
          {!onClose && (
            <FontAwesomeIcon icon={active ? faCaretDown : faCaretRight} />
          )}
        </div>
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.search}>
            <NewInput
              // onChooseTag={addTag}
              label="Szukaj"
              onClick={() => setActive(true)}
              value={value}
              onChange={(text) => setValue(text)}
            />
          </div>
          <div className={styles.list}>
            {search.map((tag: ITag) => (
              <div
                onClick={() => {
                  onChoose(tag);
                  setActive(false);
                }}
                key={tag.Id}
                className={styles.box}
              >
                #{tag.Name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseTagNew;
