import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { IStatus } from 'dto/IStatus';
import { ListType } from 'dto/ListType';

const queryKeys = {
  getStatuses: (currentWorkspaceId?: string, type?: ListType) => [
    'statusService.getStatuses',
    currentWorkspaceId,
    type,
  ],
};

const getStatuses = (currentWorkspaceId?: string, type?: ListType) => {
  const query = new URLSearchParams(window.location.search);

  if (currentWorkspaceId) {
    query.append('WorkspaceId', currentWorkspaceId);
  }

  if (type) {
    query.append('Type', type.toString());
  }

  return axiosInstance.get(`/statuses?${query}`);
};

const useGetStatuses = (currentWorkspaceId?: string, type?: ListType) => {
  return useQuery(queryKeys.getStatuses(currentWorkspaceId, type), () =>
    getStatuses(currentWorkspaceId, type)
  );
};

export interface IDeleteStatus {
  Id: number;
}

const deleteStatus = (payload: IDeleteStatus) => {
  return axiosInstance.delete('/status', {
    data: payload,
  });
};

const useDeleteStatus = () => {
  return useMutation(deleteStatus);
};

const updateStatus = (payload: IStatus) => {
  return axiosInstance.put('/status', payload);
};

const useUpdateStatus = () => {
  return useMutation(updateStatus);
};

export interface ICreateStatus {
  Color: number;
  Type: ListType;
  Name: string;
  WorkspaceId?: string;
}

const createStatus = (payload: ICreateStatus) => {
  return axiosInstance.post('/status', payload);
};

const useCreateStatus = () => {
  return useMutation(createStatus);
};

export interface ISetStatus {
  OwnerId: string;
  OwnerKind: number;
  StatusId: number | null;
}

const setStatus = (payload: ISetStatus) => {
  return axiosInstance.post('/status/set', payload);
};

export interface ISetStatusMany {
  Items: ISetStatus[];
  StatusId: number;
}

const setManyStatus = (payload: ISetStatusMany) => {
  return axiosInstance.post('/status/setMany', payload);
};

const useSetManyStatus = () => {
  return useMutation(setManyStatus);
};

const useSetStatus = () => {
  return useMutation(setStatus);
};

export default {
  useGetStatuses,
  useDeleteStatus,
  useUpdateStatus,
  useCreateStatus,
  useSetStatus,
  useSetManyStatus,
};
