import { FC, Props } from './typings';
import styles from './styles.module.scss';
import BigModal from 'components/BigModal';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';

const DeleteFolder: FC<Props> = ({ toggle, onAccept, onCancel }) => {
  return (
    <BigModal toggle={() => toggle()}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Usunąć?<div className={styles.line}></div>
        </div>
        <div className={styles.body}>
          Na pewno chcesz usunać wybrany podzbiór? Usunięcie spowoduje
          usunięcie/odpięcie z dossier elementów wewnątrz podzbioru
        </div>

        <div className={styles.buttons}>
          <HollowButton text="Anuluj" onClick={onCancel} />
          <Button text="Usuń" onClick={onAccept} />
        </div>
      </div>
    </BigModal>
  );
};

export default DeleteFolder;
