import { useEffect } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { axiosInstance } from 'api/axios';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import { getKeys } from 'utils/getKeys';
import * as openpgp from 'openpgp';
import { readAsBase64 } from 'utils/readAsBase64';
import { readFileFromBase64 } from 'utils/readFileFromBase64';

const packageSize = 20;

interface IKey {
  PublicKey: string;
  PrivateKey: string;
  cleanPublicKey: openpgp.key.KeyResult;
  cleanPrivateKey: openpgp.key.KeyResult;
}

const generateKey = async (myKeys: any): Promise<IKey> => {
  let options: openpgp.KeyOptions = {
    userIds: [
      {
        name: '1',
      },
    ],
    curve: 'secp256k1',
    numBits: 2048,
  };

  const key = await openpgp.generateKey(options);
  const pubDecoded = await openpgp.key.readArmored(key.publicKeyArmored);
  const privDecoded = await openpgp.key.readArmored(key.privateKeyArmored);
  const publicKeyResultString = new Uint8Array(
    pubDecoded.keys[0].toPacketlist().write()
  );
  const keyResultString = new Uint8Array(
    privDecoded.keys[0].toPacketlist().write()
  );

  const masterPublicKey = await readFileFromBase64(
    myKeys!.publicBase64Key,
    'application/other'
  );
  const armored = new TextDecoder().decode(masterPublicKey);

  const t = (await openpgp.key.readArmored(armored)).keys[0];

  const { message } = await openpgp.encrypt({
    message: await openpgp.message.fromBinary(keyResultString),
    publicKeys: [t],
    armor: false,
  });

  return {
    PublicKey: await readAsBase64(publicKeyResultString),
    PrivateKey: await readAsBase64(new Uint8Array(message.packets.write())),
    cleanPublicKey: pubDecoded,
    cleanPrivateKey: privDecoded,
  };
};

const generateKeysPackage = async (myKeys: any): Promise<IKey[]> => {
  return new Promise(async (resolve) => {
    let packs: IKey[] = [];
    let interval: any = null;
    interval = setInterval(async () => {
      packs.push(await generateKey(myKeys));

      if (packs.length >= packageSize) {
        clearInterval(interval);
        resolve(packs);
      }
    }, 5000);
  });
};

const KeysManager: FC<Props> = ({}) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);

  const manageKeys = async () => {
    const myKeys = await getKeys(userId!);
    console.log('wchodze');
    if (!myKeys) return;

    const missingKeys = await axiosInstance.get('/account/missingKeys');

    let counter = missingKeys.data.Count;
    console.log(counter);
    if (counter <= 0) return;

    let genTokens = true;
    console.log('rozpoczynam generowanie');
    while (genTokens) {
      if (counter > 0) {
        const packs = await generateKeysPackage(myKeys);

        const keys = packs.map((p) => {
          return {
            Id: userId,
            PrivateKey: p.PrivateKey,
            PublicKey: p.PublicKey,
            Initial: true,
            Armored: false,
          };
        });

        await axiosInstance.post('/account/userKeys', {
          Keys: keys,
        });
      }

      counter = counter - packageSize;

      if (counter <= 0) {
        genTokens = false;
      }
    }
  };

  useEffect(() => {
    manageKeys();
  }, []);

  return <></>;
};

export default KeysManager;
