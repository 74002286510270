import axios from 'axios';
import { IImages } from './decodeAndFetchImages';
import { getBlob } from './getBlob';
import { getUser } from 'api/axios';
import { blobToBase64 } from './blobToBase64';
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import React from 'react';

// TODO DOdać sprawdzanie czy istnieją obrazki jeżeli tak pobrać je i pewnie deszyfrować

const patterns = [
  /-----Original Message-----/i,
  /----- Forwarded Message -----/i,
  /From:/i,
  /To:/i,
  /Subject:/i,
  /Od:/i,
];

export const fetchImage = async (url: string) => {
  const user = getUser();
  const token = user?.access_token;

  const blob = await axios.get(url, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return blob.data;
};

export const hasImages = async (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = doc.getElementsByTagName('img');

  return !!images.length;
};

export const addToUrl = async (content: string, allowImages = false) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = doc.getElementsByTagName('img');

  // Usuń style z elementu body
  // const allElements = doc.querySelectorAll('body *');
  // allElements.forEach((element) => {
  //   element.removeAttribute('style');
  // });

  // const headElement = doc.querySelector('head');
  // if (headElement) {
  //   const htmlElement = doc.querySelector('html');
  //   htmlElement?.removeChild?.(headElement);
  // }

  for (let i = 0; i < images.length; i++) {
    // Pobierz aktualny obiekt img
    const img = images[i];
    if (img.hasAttribute('src')) {
      if (!allowImages) {
        //@ts-ignore
        img.setAttribute('src', '');
        continue;
      }
      const url = img.getAttribute('src');
      const link = `https://beta.pocztaprawnicza.pl${url}`;

      const image = await fetchImage(link);
      const base64 = await blobToBase64(image);
      //@ts-ignore
      img.setAttribute('src', base64);
    }
  }

  // Pobierz zmodyfikowany HTML
  const modifiedHTML = doc.documentElement.outerHTML;

  return modifiedHTML;
};

export const parseHTML = async (content: string, ig: IImages[]) => {
  const parser = new DOMParser();

  const doc = parser.parseFromString(content, 'text/html');

  const images = doc.getElementsByTagName('img');

  for (var i = 0; i < images.length; i++) {
    // Pobierz aktualny obiekt img
    var img = images[i];

    // Sprawdź, czy atrybut src istnieje
    if (img.hasAttribute('src')) {
      // Podmień wartość atrybutu src na nową wartość
      // W poniższym przykładzie zamieniamy każdy src na 'nowa_sciezka.jpg'

      img.getAttribute('src');

      try {
        const url = img
          .getAttribute('src')
          ?.split('/')[3]
          .split('&')[0]
          .replaceAll('image?id=', '');
        const replaceImage = ig.find((i) => i.id === url);
        //

        if (replaceImage) {
          const ie = replaceImage?.content.data;
          const binaryString = Array.from(new Uint8Array(ie), (v) =>
            String.fromCharCode(v)
          ).join('');
          const theImage = btoa(binaryString);

          //@ts-ignore
          img.setAttribute('src', 'data:image/png;base64, ' + theImage);
        }
      } catch (e) {}
      //   img.setAttribute('alt', 'nowa_sciezka.jpg');
    }
  }

  // Pobierz zmieniony kod HTML
  const modifiedHTML = doc.documentElement.outerHTML;

  return modifiedHTML;
};

export const hideOriginalOrForwardedMessage = (htmlContent: string) => {
  const options: HTMLReactParserOptions = {
    replace: (node: DOMNode, index: number) => {
      // Define patterns to identify the start of original or forwarded message
      const patterns = [
        /-----Original Message-----/i,
        /----- Forwarded Message -----/i,
        /From:/i,
        /To:/i,
        /Subject:/i,
      ];

      if (node instanceof Element && node.name === 'body') {
        let hide = false;

        const modifiedChildren = node.children.map((child) => {
          if (typeof child === 'string') {
            if (patterns.some((pattern) => pattern.test(child))) {
              hide = true;
            }
            return hide ? null : child;
          }

          if (child.type === 'tag' && child instanceof Element) {
            const childContent = domToReact(
              child.childNodes.map((c) => c as Element),
              options
            );
            if (hide) {
              return null;
            }
            return { ...child, children: childContent };
          }

          return child;
        });

        return `<div>` + { modifiedChildren } + `</div>`;
      }
    },
  };

  return parse(htmlContent, options);
};
