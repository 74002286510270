import * as openpgp from 'openpgp'
import { decryptPrivateKey } from './decryptPrivateKey';
import { readFileFromBase64 } from './readFileFromBase64';

export const generateNewKey = async (encryptionKeyId: string, myKeys: any, keys: any[]) => {
    const encryptionKey = keys.find((k) => k?.Id === encryptionKeyId);

    let parentKey = null;
    if (encryptionKey?.ParentKeyId) {
      parentKey = keys.find((k) => k?.Id === encryptionKey.ParentKeyId);
      const decryptedParentKey = await decryptPrivateKey(
        myKeys!,
        parentKey!.PrivateKey
      );

      const text = await openpgp.key.read(decryptedParentKey.data);


      const PK = await readFileFromBase64(
        encryptionKey!.PrivateKey!,
        'application/other'
      );
      const msg = await openpgp.message.read(new Uint8Array(PK));

      var options: openpgp.DecryptOptions = {
        message: msg,
        privateKeys: text.keys[0],
        format: 'binary',
      };

        return await openpgp.decrypt(options);
      // 
    } else {
      return await decryptPrivateKey(myKeys!, encryptionKey?.PrivateKey);
    }

}