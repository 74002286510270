import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Input from 'components/Input';
import { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ESearchTypes, Props } from './typings';
import { ReactComponent as ComputerIcon } from 'assets/icons/order/computer.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/order/filter.svg';
import WideSearch from 'components/WideSearch';
import { useOnClickOutside } from 'usehooks-ts';
import SearchDropdown from 'components/SearchDropdown';
import { IStatus } from 'dto/IStatus';
import SelectFlagNew from 'components/SelectFlagNew';
import { ITag } from 'dto/ITag';
import ChooseTagNew from 'components/ChooseTagNew';
import { useParams } from 'react-router-dom';
import { IEntity } from 'dto/IEntity';
import { IWorkspaceMember } from 'dto/IWorkspace';
import SearchMemberNew from 'components/SearchMemberNew';
import SearchEntitiesNew from 'components/SearchEntitiesNew';
import SelectChannel from 'components/SelectChannel';

const SearchBar: FC<Props> = ({ onChange }) => {
  const { filterId } = useParams();
  const [value, setValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [showFocused, setShowFocused] = useState(false);

  const inputWrapper = useRef(null);

  const [statuses, setStatuses] = useState<null | any[]>(null);
  const [tags, setTags] = useState<null | ITag | undefined>(undefined);
  const [entity, setEntity] = useState<null | IEntity | undefined>(undefined);
  const [kind, setKind] = useState<null | number>(null);

  const isCases = window.location.href.search('cases') !== -1;
  const isClient = window.location.href.search('clients') !== -1;
  const isOutcoming = window.location.href.search('outcoming') !== -1;

  let type = 1;
  let view = 6;

  if (isOutcoming) {
    type = 2;
    view = 7;
  }

  if (isCases) {
    view = 1;
    type = 3;
  }

  if (isClient) {
    view = 3;
    type = 6;
  }

  useEffect(() => {
    onChange(
      value,
      statuses?.[0]?.Id ?? null,
      tags?.Id ?? null,
      entity?.Id ?? null,
      kind ?? null
    );
  }, [value, statuses, tags, entity, kind]);

  useEffect(() => {
    setStatuses(null);
    setTags(undefined);
    onChange('', null, null, null, null);
  }, [filterId]);

  const handleMouse = (state: boolean) => setShowInput(state);
  const handleChangeValue = (e: any) => {
    setValue(e.target.value);
    // onChange(e.target.value);
  };

  const ref = useRef(null);
  const refka = useRef(null);

  const outsideRef = useRef(null);

  const [showWide, setShowWide] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  // useOnClickOutside(outsideRef, () => {
  //   if (showInput && !showWide) {
  //     setShowInput(false);
  //     setEntity(undefined);
  //     setTags(undefined);
  //     setStatuses(null);
  //   }
  // });

  const handleChoose = (type: ESearchTypes) => {
    setShowMenu(false);
    switch (type) {
      case ESearchTypes.STATUS:
        if (!statuses?.length) {
          setStatuses([null]);
          return;
        }
        setStatuses([null]);
        return;
      case ESearchTypes.TAG:
        setTags(null);
        return;
      case ESearchTypes.SHARED:
        setEntity(null);
        return;
      case ESearchTypes.CHANNEL:
        setKind(-1);
        return;
    }
  };

  const showFilters =
    showInput &&
    (statuses?.[0] !== undefined ||
      tags !== undefined ||
      entity !== undefined ||
      kind !== null);

  const [timer, setTimer] = useState<null | NodeJS.Timeout>(null);

  return (
    <div ref={outsideRef} className={styles.ext}>
      <div
        ref={ref}
        className={classNames(
          styles.wrapper,
          !showInput && !value.length && !showFocused && styles.hide
        )}
      >
        <div className={styles.icon}>
          {showInput && (
            <div className={styles.icons}>
              {/* <ComputerIcon
                onClick={() => setShowWide(true)}
                className={styles.i}
              /> */}
              <FilterIcon
                ref={refka}
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
                className={styles.i}
              />
            </div>
          )}
          {!showInput && (
            <FontAwesomeIcon
              onClick={() => {
                setShowInput(true);
                //@ts-ignore
                inputWrapper?.current?.focus?.();
              }}
              icon={faSearch}
            />
          )}
        </div>
        <div className={styles.inputWrapper}>
          <input
            ref={inputWrapper}
            onFocus={(e) => {
              setShowFocused(true);
            }}
            onBlur={() => setShowFocused(false)}
            className={styles.input}
            value={value}
            onChange={handleChangeValue}
          />
        </div>
        {showInput && (
          <div className={styles.cleanIcon}>
            <CloseIcon
              onClick={() => {
                setValue('');
                onChange('', null, null, null, null);
                setShowInput(false);
              }}
            />
          </div>
        )}
      </div>
      {ref?.current && showWide && (
        <WideSearch toggle={() => setShowWide(false)} refWrapper={ref} />
      )}
      {showMenu && (
        <SearchDropdown
          toggle={() => setShowMenu(false)}
          onChoose={handleChoose}
          refWrapper={refka}
        />
      )}

      {showFilters && (
        <div className={styles.list}>
          {statuses?.map((s, index) => {
            return (
              <div className={styles.listBox}>
                <SelectFlagNew
                  type={type}
                  initOpen
                  selectedStatus={s}
                  onChoose={(s) => {
                    const newStatuses = [...statuses];
                    newStatuses[index] = s;
                    setStatuses(newStatuses);
                  }}
                  onClose={() => {
                    setStatuses(null);
                  }}
                />
              </div>
            );
          })}

          <div className={styles.listBox}>
            {tags !== undefined && (
              <ChooseTagNew
                label={tags?.Name ?? undefined}
                type={view}
                onChoose={(tag) => {
                  setTags(tag);
                }}
                // onClose={() => {
                //   setTags(undefined);
                // }}
              />
            )}
          </div>
          <div className={styles.listBox}>
            {entity !== undefined && (
              <SearchEntitiesNew
                initOpen
                onClose={() => {
                  setEntity(undefined);
                }}
                label={entity ? entity.Label ?? entity.Name : 'Udostępniono'}
                onChoose={(entity) => {
                  setEntity(entity);
                }}
              />
            )}
          </div>
          <div className={styles.listBox}>
            {kind && (
              <SelectChannel
                onClose={() => {
                  setKind(null);
                }}
                selectedStatus={kind}
                onChoose={(kind) => {
                  setKind(kind);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
