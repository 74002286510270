import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';

import settingService from 'api/settingsService';
import { ReactComponent as TotpIcon } from 'assets/icons/add_to_drive.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/bin.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/encrypted.svg';
import classNames from 'classnames';
import ConfirmModal from 'components/ConfirmModal';
import Spinner from 'components/Spinner';
import { ITrustedBrowser } from 'dto/Settings/ITrustedBrowser';
import { ITwoFactorSettings } from 'dto/Settings/ITwoFactorSettings';
import { TwoFactorMethod } from 'dto/Settings/TwoFactorMethod';
import moment from 'moment';
import { getErrorMessage } from 'utils/getErrorMessage';
import { ENotificationType, notification } from 'utils/notification';
import OtpMethod from './Methods/OtpMethod';
import WebauthnMethod from './Methods/WebauthnMethod';

const SecurityTab: FC<Props> = ({ showTabBar }) => {
  const [actualView, setActualView] = useState(ETabs.FA);
  const { data: current2faSettings, isSuccess: isCurrent2faSettingsLoaded } =
    settingService.useGet2FaSettings();
  const { data: trustedBrowsersData, isSuccess: isTrustedBrowsersLoaded } =
    settingService.useGetTrustedBrowsers();
  const { mutate: removeTrustedBrowser, isLoading: isRemovingTrustedBrowser } =
    settingService.useRemoveTrustedBrowser();

  const { mutate: remove2faMethod, isLoading: isRemoving2faMethod } =
    settingService.useRemove2faMethod();

  const { mutate: getFidoMakeCredentialOptions, isLoading: isFidoLoading } =
    settingService.useFidoMakeCredentialOptions();
  const { mutate: makeFidoCredential, isLoading: isMakingFidoCredential } =
    settingService.useFidoMakeCredential();

  const [browserToDelete, setBrowserToDelete] = useState({
    id: 0,
    show: false,
  });

  const [methodToDelete, setMethodToDelete] = useState({
    method: TwoFactorMethod.None,
    show: false,
  });

  const [method, setMethod] = useState<TwoFactorMethod | null>(null);
  const [enabledMethods, setEnabledMethods] = useState({
    email: false,
    fido: false,
    totp: false,
  });

  const [trustedBrowsers, setTrustedBrowsers] = useState<ITrustedBrowser[]>([]);

  const configure = (method: TwoFactorMethod) => {
    switch (method) {
      case TwoFactorMethod.Email:
        notification({
          title: 'Skonfigurowano',
          text: 'Podwójna autoryzacja przy pomocy adresu e-mail nie podlega konfiguracji.',
          type: ENotificationType.INFO,
        });
        break;
      default:
        setMethod(method);
        break;
    }
  };

  const handleRemoveBrowser = (browser: ITrustedBrowser) => {
    setBrowserToDelete({ id: browser.id, show: true });
  };

  const handleBrowserDeleteConfirmation = () => {
    removeTrustedBrowser(
      { Id: browserToDelete.id },
      {
        onSuccess(data, variables, context) {
          var browsers = trustedBrowsers;
          var newValue = browsers.filter((x) => x.id != browserToDelete.id);
          setTrustedBrowsers(newValue);
          setBrowserToDelete({ id: 0, show: false });
        },
        onError(e: any) {
          setBrowserToDelete({ id: 0, show: false });
          notification({
            title: 'Nie udało się usunąć zaufanej przeglądarki',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  const onInnerViewCanceled = () => {
    setMethod(null);
  };

  const onInnerViewSaved = () => {
    switch (method) {
      case TwoFactorMethod.WebAuthn:
        setEnabledMethods({ ...enabledMethods, fido: true });
        break;
      case TwoFactorMethod.Totp:
        setEnabledMethods({ ...enabledMethods, totp: true });
        break;
    }
    setMethod(null);
  };

  const handleRemove2fa = (method: TwoFactorMethod) => {
    setMethodToDelete({ method, show: true });
  };

  const handleRemove2faConfirmed = () => {
    remove2faMethod(
      { method: methodToDelete.method },
      {
        onSuccess(data, variables, context) {
          switch (methodToDelete.method) {
            case TwoFactorMethod.WebAuthn:
              setEnabledMethods({ ...enabledMethods, fido: false });
              break;
            case TwoFactorMethod.Totp:
              setEnabledMethods({ ...enabledMethods, totp: false });
              break;
          }
          setMethodToDelete({ method: TwoFactorMethod.None, show: false });
          setMethod(null);
        },
        onError(e: any) {
          notification({
            title: 'Nie udało się usunąć metody',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (isCurrent2faSettingsLoaded) {
      var s = current2faSettings.data as ITwoFactorSettings;

      setEnabledMethods({
        email: !!s.configurations?.find(
          (x) => x.method === TwoFactorMethod.Email
        ),
        fido: !!s.configurations?.find(
          (x) => x.method === TwoFactorMethod.WebAuthn
        ),
        totp: !!s.configurations?.find(
          (x) => x.method === TwoFactorMethod.Totp
        ),
      });
    }
  }, [current2faSettings]);

  useEffect(() => {
    if (isTrustedBrowsersLoaded) {
      var s = trustedBrowsersData.data as ITrustedBrowser[];
      setTrustedBrowsers(s);
    }
  }, [trustedBrowsersData]);

  if (!current2faSettings) {
    return <Spinner></Spinner>;
  }

  const Menu = () => {
    return (
      <div className={styles.menu}>
        <div
          onClick={() => setActualView(ETabs.FA)}
          className={`${styles.box}  ${
            actualView === ETabs.FA && styles.activeText
          }`}
        >
          Metody 2FA
        </div>
        <div
          onClick={() => setActualView(ETabs.TRUST)}
          className={`${styles.box}  ${
            actualView === ETabs.TRUST && styles.activeText
          }`}
        >
          Zaufane urządzenia
        </div>
      </div>
    );
  };

  const Security = () => {
    return (
      <div className={styles.wrapper}>
        <div className={classNames(styles.group, styles.grow)}>
          <div className={styles.header}>Zaufane urządzenia</div>
          <div className={styles.hint}>
            Poniżej prezentujemy listę dodanych przez Ciebie zaufanych
            przeglądarek, które przy logowaniu nie wymagają podwójnej
            autoryzacji. W każdej chwili możesz unieważnić wybrane zezwolenia.
          </div>
          <div className={styles.table}>
            <div className={styles.hdr}>
              <div className={styles.date}>Data</div>
              <div className={styles.userAgent}>Przeglądarka</div>
              <div className={styles.ip}>Adres IP</div>
            </div>
            <div className={styles.tableContent}>
              {trustedBrowsers.map((t) => (
                <div className={styles.browser}>
                  <div className={styles.date}>
                    {moment(t.creationDate).format('DD.MM.YYYY').toString()}
                  </div>
                  <div className={styles.userAgent}>{t.userAgent}</div>
                  <div className={styles.ip}>{t.ipAddress}</div>

                  <div className={styles.menu}>
                    <RemoveIcon
                      title="Usuń"
                      onClick={() => handleRemoveBrowser(t)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {browserToDelete.show && (
          <ConfirmModal
            title="Potwierdź usunięcie zaufanej przeglądarki"
            text="Czy na pewno chcesz usunać wybraną zaufaną przeglądarkę? Na urządzeniu konieczna będzie podwójna autoryzacja w celu zalogowania"
            acceptLabel="Usuń"
            cancelLabel="Anuluj"
            onAccept={() => {
              handleBrowserDeleteConfirmation();
            }}
            onCancel={() => setBrowserToDelete({ id: 0, show: false })}
          />
        )}
      </div>
    );
  };

  if (actualView === ETabs.TRUST) {
    return (
      <>
        <Menu />
        <Security />
      </>
    );
  }

  return (
    <>
      <Menu />
      <div className={styles.wrapper}>
        {method === null && (
          <>
            <div className={styles.group}>
              <div className={styles.header}>
                Metody podwójnego uwierzytelniania
              </div>
              <div className={styles.hint}>
                Skonfiguruj metody podwójnego uwierzytelniania. <br></br>Twoj
                adres e-mail jest domyślną formą podwójnego uwierzytelniania i
                nie może zostać usunięty. Na nowych lub niezaufanych
                przeglądarkach poprosimy Cię o weryfikację dwuskładniokową.
              </div>
              <div className={styles.methods}>
                <div
                  className={styles.method}
                  onClick={() => configure(TwoFactorMethod.Email)}
                >
                  <div className={styles.icon}>
                    <EmailIcon></EmailIcon>
                  </div>
                  Adres e-mail
                  {enabledMethods.email && (
                    <FontAwesomeIcon
                      className={styles.enabled}
                      icon={faCheckCircle}
                      title="Aktywna"
                    />
                  )}
                </div>
                <div
                  className={styles.method}
                  onClick={() => configure(TwoFactorMethod.WebAuthn)}
                >
                  <div className={styles.icon}>
                    <KeyIcon></KeyIcon>
                  </div>
                  Klucz prywatny / Webauthn
                  {enabledMethods.fido && (
                    <FontAwesomeIcon
                      className={styles.enabled}
                      icon={faCheckCircle}
                      title="Aktywna"
                    />
                  )}
                </div>
                <div
                  className={styles.method}
                  onClick={() => configure(TwoFactorMethod.Totp)}
                >
                  <div className={styles.icon}>
                    <TotpIcon></TotpIcon>
                  </div>
                  Authenticator / OTP
                  {enabledMethods.totp && (
                    <FontAwesomeIcon
                      className={styles.enabled}
                      icon={faCheckCircle}
                      title="Aktywna"
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {method == TwoFactorMethod.WebAuthn && (
          <WebauthnMethod
            isActive={enabledMethods.fido}
            onCanceled={onInnerViewCanceled}
            onSaved={onInnerViewSaved}
            handleRemove={handleRemove2fa}
          ></WebauthnMethod>
        )}

        {method == TwoFactorMethod.Totp && (
          <OtpMethod
            isActive={enabledMethods.totp}
            onCanceled={onInnerViewCanceled}
            onSaved={onInnerViewSaved}
            handleRemove={handleRemove2fa}
          ></OtpMethod>
        )}

        {methodToDelete.show && (
          <ConfirmModal
            title="Potwierdź usunięcie metody podwójnej autoryzacji"
            text="Czy na pewno chcesz usunąć wybraną formę autoryzacji?"
            acceptLabel="Usuń"
            cancelLabel="Anuluj"
            onAccept={() => {
              handleRemove2faConfirmed();
            }}
            onCancel={() =>
              setMethodToDelete({ method: TwoFactorMethod.None, show: false })
            }
          />
        )}
      </div>
    </>
  );
};

export default SecurityTab;
