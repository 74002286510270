import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Input from 'components/Input';
import { useState } from 'react';
import Button from 'components/Button';
import SecondCheckbox from 'components/SecondCheckbox';
import dossierService from 'api/dossierService';
import { ENotificationType, notification } from 'utils/notification';

const EditDossier: FC<Props> = ({ toggle, file, refetch }) => {
  const [value, setValue] = useState(file.Label);
  const [check, setCheck] = useState(false);

  const { mutate: updateLabel } = dossierService.useUpdateLabel();
  const { mutate: setFlags } = dossierService.useSetFlags();

  const handleSave = () => {
    updateLabel(
      {
        Id: file.DossierId,
        Label: value ?? '',
      },
      {
        onSuccess: () => {
          refetch();
          toggle(false);
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Zmień nazwę
        <CloseIcon onClick={() => toggle(false)} />
      </div>

      <div className={styles.content}>
        <Input
          label="Nazwa pliku"
          onChange={(e) => setValue(e)}
          value={value}
        />
      </div>

      <div className={styles.footer}>
        <Button onClick={handleSave} text="Zapisz" />
      </div>
    </div>
  );
};

export default EditDossier;
