import { useMutation, useQuery } from "react-query";
import { axiosInstance } from "./axios"
import { INewTag } from "./casesService";
import { IExternalEditor } from "dto/IExternalEditor";
import { ITemplate } from "dto/ITemplate";


const queryKeys = {
    getTemplates: (name: string) => ['externalService.getTemplates', name],
  };
  

export interface IGetExternalEditor {
    TemplateId: string | null;
    CaseId: string | null;
    NewTags: INewTag[];
    TagIds: string[];
    Title: string;
}

const getExternalEditor = async (payload: IGetExternalEditor): Promise<IExternalEditor> => {
    const r = await axiosInstance.post('/externalEditor/document', payload);
    return r.data ?? [];    
}

const useGetExternalEditor = () => {
    return useMutation(getExternalEditor);
}

const getTemplates = async (name: string): Promise<ITemplate[]> => {

    if(!name.length) {
        return []
      }

    const r = await axiosInstance.get(`/externalEditor/templates?Text=${name}`)
    return r.data ?? []
}

const useGetTemplate = (name: string) => {
    return useQuery(queryKeys.getTemplates(name), () => getTemplates(name));
}

export default {
    useGetExternalEditor,
    useGetTemplate
}