const allowedTypes = [
    'doc',
    'docx',
    'ppt',
    'pptx',
    'xls',
    'xlsx',
    'rtf',
    'txt',
    'jpg',
    'png'
  ];


export const isAllowedAttachment = (extension: string) => {
    return allowedTypes.includes(extension ?? 'no');
}