import * as openpgp from 'openpgp';
import { IDecryptedKeys } from './decryptKeys';

export const decryptIntermidatePrivateKey = async (keys: IDecryptedKeys) => {
  const x = await openpgp.message.readArmored(keys?.privateKeyArmored);
  const key = await openpgp.decrypt({
    message: x,
    privateKeys: keys!.masterKey!.keys[0],
  });

  return key;
};
