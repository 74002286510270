import { IUserOffice } from 'dto/IUserOffice';

export type { FC } from 'react';

export interface Props {
    worker: IUserOffice
    refetch: () => void;
    isAdmin: boolean; 
}

export enum ETabs {
    ALL = 'ALL',
    ROLES = 'ROLES',
    STRUCTURE = 'STRUCTURE'
}