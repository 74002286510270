import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { Chip as ChipMUI } from '@mui/material';

const Chip: FC<Props> = ({
  className = {},
  element,
  avatar,
  onDelete,
  small = false,
}) => {
  return (
    <ChipMUI
      avatar={avatar}
      size={small ? 'small' : 'medium'}
      style={className}
      className={`${styles.tag}`}
      label={element}
      onDelete={onDelete}
    />
  );
};

export default Chip;
