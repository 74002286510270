import GridWindow from 'components/GridWindow';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import { useMemo } from 'react';
import CaseList from 'components/CaseList';
import { ReactComponent as CaseIcon } from 'assets/icons/case_sidebar.svg';

const CasesDeskWindow: FC<Props> = ({ handleRemove, id, isEditMode }) => {
  const { data: tagsData } = tagsService.useGetTags();
  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  return (
    <GridWindow
      handleRemove={handleRemove}
      id={id}
      isEditMode={isEditMode}
      title={
        <>
          <CaseIcon />
          Teczki
        </>
      }
    >
      <div className={styles.wrapper}>
        <CaseList
          selectedThread={null}
          setSelectedThread={() => {}}
          searchText=""
          tags={tags}
          statuses={statuses}
          setSelectedEdit={() => {}}
        />
      </div>
    </GridWindow>
  );
};

export default CasesDeskWindow;
