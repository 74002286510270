import { useMutation, useQuery } from "react-query"
import { axiosInstance } from "./axios"

const queryKeys = {
    getMe: (token: string) => ['userService.getMe', token]
}

const getMe = () => {
    return axiosInstance.get('/account')
}

const useGetMe = (token: string) => {
    return useQuery(queryKeys.getMe(token), () => getMe(), {
        enabled: false,
        cacheTime: 0,
        staleTime: 0
    })
}

export interface IUpdateCompany {
    Name: string;
    Address: string;
    City: string;
    ZipCode: string;
    Phone: string;
    Email: string;
    NIP: string;
    REGON: string;
}

const getCompany = () => {
    return axiosInstance.get("/account/company")
}

const useGetCompany = () => {
    return useQuery('account.getCompany', () => getCompany())
}

const updateCompany = (payload: IUpdateCompany) => {
    return axiosInstance.post("/account/company", payload)
}

const useUpdateCompany = () => {
    return useMutation(updateCompany);
}

export default {
    useGetMe,
    useUpdateCompany,
    useGetCompany
}