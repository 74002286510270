import { ISingleBinaryKeys } from "./GenerateSingleBinary";
import * as openpgp from 'openpgp';
import { readFileFromBase64 } from "./readFileFromBase64";

const encryptContent = async (content: string, keys: any, isBase64?: boolean) => {
  

    let decryptKeys = keys;

    if(isBase64) {
      const masterPublicKey = await readFileFromBase64(
            keys,
            'application/other'
      );
    const armored = new TextDecoder().decode(masterPublicKey);

    decryptKeys = await openpgp.key.readArmored(armored)
      
    }

    const { message } = await openpgp.encrypt({
        message: openpgp.message.fromText(content),
        publicKeys: [decryptKeys?.keys[0]],
        armor: false
        
      });

      return await new Uint8Array(message.packets.write());
}

export default encryptContent;