import { useState } from "react"

function useFiles() {
    const [files, setFiles] = useState<File[]>([]);


    const addNewFile = (file: File) => {
            setFiles(prev => [...prev, file])
    }
    const removeFile = (index: number) => setFiles( files.filter((file, fileIndex) => index !== fileIndex) );
     

    return {
        files,
        setFiles,
        addNewFile,
        removeFile
    }

 }

export default useFiles