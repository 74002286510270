import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useState } from 'react';
import Button from 'components/Button';
import workspaceService from 'api/workspaceService';
import { setWorkspaceId } from 'utils/setWorkspaceId';

const CreateWorkspace: FC<Props> = ({ onCreate }) => {
  const [value, setValue] = useState('');

  const { mutate: createWorkspace } = workspaceService.useCreateWorkspace();

  const handleClick = () => {
    createWorkspace(
      {
        Name: value,
      },
      {
        onSuccess: (response) => {
          setWorkspaceId(response.data.Id);
          onCreate(response.data.Id);
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      Podaj nazwę Twojej firmy / Instytucji
      <Input
        value={value}
        label="Nazwa Firmy / Instytucji"
        onChange={(t) => setValue(t)}
      />
      <Button onClick={handleClick} text="Zapisz" />
    </div>
  );
};

export default CreateWorkspace;
