import { AxiosResponse } from 'axios';
import { ICase } from 'dto/Cases/ICase';
import { ICaseParticipant } from 'dto/Cases/ICaseParticipant';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { IGetListOfMessages } from './listService';
import { IPackageListResponse } from 'dto/IPackage';

const queryKeys = {
  getFiltersForCases: 'casesService.getFiltersForCases',
  getSimpleCases: (payload: { text?: string }) => [
    'casesService.getSimpleCases',
    payload,
  ],
  getCases: (payload: IGetCases) => ['caseService.getCases', payload],
  getCase: (id: string) => ['caseService.getCase', id],
  getDossier: (id: string) => ['caseService.getDossier', id],
  getKeys: (payload: ICaseGetKeys) => ['caseService.getKeys', payload],
  getAddressBook: (name?: string, eDeliveryAddress?: string) => [
    'caseService.getAddressBook',
    name,
    eDeliveryAddress,
  ],
};

const getFiltersForCases = ({}) => {
  return axiosInstance.get(`/case/filters`);
};

const useGetFiltersForCases = () => {
  return useMutation(getFiltersForCases);
};

const createFilterForCases = (payload: any) => {
  return axiosInstance.post('/case/filter', payload);
};

const useCreateFilterForCases = () => {
  return useMutation(createFilterForCases);
};

const getSimpleCases = async (payload: { text?: string; id?: string }) => {
  const query = new URLSearchParams(window.location.search);

  if (!!payload.text?.length) {
    query.append('searchString', payload.text);
  }

  if (!!payload.id?.length) {
    query.append('id', payload.id);
  }

  return axiosInstance.get(`/cases/simple?${query}`);
};

const useGetSimpleCases = (payload: { text?: string; id?: string }) => {
  return useQuery(queryKeys.getSimpleCases(payload), () =>
    getSimpleCases(payload)
  );
};

interface IGetCases {
  page: number;
  ParticipantId?: string | number;
  filterId?: string;
  Text?: string;
}

const getCases = (payload: IGetCases) => {
  let url = `/cases?Page=${payload.page}&SortProp=UpdateDate&SortOrder=Descending`;

  if (payload.ParticipantId) {
    url += `&ParticipantId=${payload.ParticipantId}`;
  }
  if (payload.filterId) {
    url += `&FilterId=${payload.filterId}`;
  }
  if (payload.Text) {
    url += `&Text=${payload.Text}`;
  }

  return axiosInstance.get(url);
};

const useGetCases = (payload: IGetCases) => {
  return useQuery(queryKeys.getCases(payload), () => getCases(payload));
};

const getCase = (id: string, enabled: boolean): Promise<ICase> => {
  if (!id.length || !enabled) {
    return {
      data: [],
    } as any;
  }

  return axiosInstance.get(`/case?id=${id}`).then((r) => r.data);
};

const useGetCase = (id: string, enabled: boolean = true) => {
  return useQuery(queryKeys.getCase(id), () => getCase(id, enabled));
};

const getDossier = (id: string, folderId?: string | null) => {
  let url = `/dossier?CaseId=${id}`;

  if (folderId) {
    url += `&folderId=${folderId}`;
  }
  return axiosInstance.get(url);
};

const useGetDossier = (
  id: string,
  folderId?: string | null,
  enabled: boolean = true
) => {
  return useQuery(queryKeys.getDossier(id), () => getDossier(id, folderId), {
    enabled,
    staleTime: 0,
    cacheTime: 0,
  });
};

const getDossierMutation = ({
  id,
  folderId,
}: {
  id: string;
  folderId?: string | null;
}): Promise<{ data: IPackageListResponse }> => {
  let url = `/dossier?CaseId=${id}`;

  if (folderId) {
    url += `&folderId=${folderId}`;
  }

  return axiosInstance.get(url);
};

const useGetDossierMutation = () => {
  return useMutation(getDossierMutation);
};

export interface ICaseGetKeys {
  id: string | null;
}

const getKeys = (payload: ICaseGetKeys) => {
  if (!payload.id) {
    return { data: [] } as AxiosResponse;
  }

  return axiosInstance.get(`/case/keys?id=${payload.id}`);
};

const useGetKeys = (payload: ICaseGetKeys) => {
  return useQuery(queryKeys.getKeys(payload), () => getKeys(payload));
};

export interface IAttachToCase {
  CaseId: string;
  ToAttachId: string;
  ToAttachKind: number;
  ObjectKey?: string | null;
  PublicKey?: string | null;
  ShowInDossier?: boolean;
}

const attachToCase = (payload: IAttachToCase) => {
  return axiosInstance.post('/case/attach', payload);
};

const useAttachToCase = () => {
  return useMutation(attachToCase);
};

export interface IAttachManyToCase {
  CaseId: string;
  ThreadId: string;
  Items: {
    Id: string;
    Kind: string;
    ObjectKey: string;
    PublicKey: string;
  }[];
  ShowInDossier?: boolean;
}

const attachManyToCase = (payload: IAttachManyToCase) => {
  return axiosInstance.post('case/attachMany', payload);
};

const useAttachManyToCase = () => {
  return useMutation(attachManyToCase);
};

const getAddressBook = (name?: string, eDeliveryAddress?: string) => {
  const query = new URLSearchParams(window.location.search);

  if (!!name?.length) {
    query.append('Name', name);
  } else {
    query.append('LastUsed', 'true');
  }

  if (!!eDeliveryAddress?.length) {
    query.append('EDeliveryAddress', eDeliveryAddress);
  }

  return axiosInstance.get(`/AddressBook/Search?${query}`);
};

const useGetAddressBook = (name?: string, eDeliveryAddress?: string) => {
  return useQuery(queryKeys.getAddressBook(name, eDeliveryAddress), () =>
    getAddressBook(name, eDeliveryAddress)
  );
};

const searchEDeliveryAddress = (eDeliveryAddress?: string) => {
  return axiosInstance.get(
    `/AddressBook/Search?EDeliveryAddress=${eDeliveryAddress}`
  );
};

const useSearchEDeliveryAddress = () => {
  return useMutation(searchEDeliveryAddress);
};

export interface INewTag {
  Id: null;
  Name: string;
  Type: number;
}

export interface ICreateCase {
  Id?: string | null;
  NewTags?: INewTag[];
  Participants?: ICaseParticipant[];
  Signature?: string;
  TagIds?: string[];
  Title: string;
  StatusId?: number;
}

const createCase = (payload: ICreateCase) => {
  return axiosInstance.post('/case', payload);
};

const useCreateCase = () => {
  return useMutation(createCase);
};

const deleteCase = (c: ICaseSimple) => {
  return axiosInstance.delete(`/case?id=${c.Id}`);
};

const useDeleteCase = () => {
  return useMutation(deleteCase);
};

const archiveCase = (c: ICaseSimple) => {
  return axiosInstance.post(`/case/archive?id=${c.Id}`);
};

const useArchiveCase = () => {
  return useMutation(archiveCase);
};

export interface IRedirect {
  CaseId: string;
  ReceiverId: string;
  Comment?: string;
  Keys: {
    Id: string;
    Key: string;
  }[];
}

const redirect = (payload: IRedirect) => {
  return axiosInstance.post('/case/pass', payload);
};

const useRedirect = () => {
  return useMutation(redirect);
};

export default {
  useGetFiltersForCases,
  useCreateFilterForCases,
  useGetSimpleCases,
  useGetCases,
  useGetCase,
  useGetDossier,
  useAttachToCase,
  useGetKeys,
  useGetAddressBook,
  useCreateCase,
  getKeys,
  useDeleteCase,
  useArchiveCase,
  useRedirect,
  useSearchEDeliveryAddress,
  useAttachManyToCase,
  useGetDossierMutation,
};
