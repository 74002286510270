import { IClient } from 'dto/IClient';

export type { FC } from 'react';

export enum ETabs {
    PERSONAL, 
    PERSONS,
    CASES,
    NOTE
}

export interface Props {
  clientId: string;
}