import { axiosInstance } from 'api/axios';
import { decryptPrivateKey } from './decryptPrivateKey';
import { readFileFromBase64 } from './readFileFromBase64';
import * as openpgp from 'openpgp';
import { getKeys } from './getKeys';

export const reEncrypt = async (
  userId: string,
  workspaceMemberId: string,
  encryptionKey?: any,
  decryptedKey?: openpgp.DecryptResult
) => {
  console.log('Reencrypting key for userId:', workspaceMemberId);
  const myKeys = await getKeys(userId!);

  const privateKey =
    decryptedKey ?? (await decryptPrivateKey(myKeys!, encryptionKey));

  const interKey = await axiosInstance.post('/account/intermediateKeys', {
    UserIds: [workspaceMemberId],
  });

  const publicUserKey = interKey.data.Keys[0].PublicKey;

  const armored = await readFileFromBase64(publicUserKey, 'application/other');
  const interKeyDecoded = new TextDecoder().decode(armored);
  const { message } = await openpgp.encrypt({
    message: await openpgp.message.fromBinary(privateKey.data),
    publicKeys: [(await openpgp.key.readArmored(interKeyDecoded)).keys[0]],
    armor: false,
  });

  return await new Uint8Array(message.packets.write());
};
