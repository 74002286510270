// ConversationContextimport 
import { RefObject, createContext } from "react";

import { IPackage } from "dto/IPackage";
import { IWorkspace } from "dto/IWorkspace";

type ICurrentWorkspace = {
    workspace: IWorkspace | null;
    filterId: string;
    setFilterId: (filterId: string) => void;
    setWorkspace: (workspace: IWorkspace) => void;
}

const WorkspaceContext = createContext<ICurrentWorkspace>({
    workspace: null,
    setWorkspace: () => {},
    filterId: '',
    setFilterId: () => {}
})

export default WorkspaceContext;