import { User } from 'oidc-client-ts';

export const getUserId = (user?: User) => {
  if (user) {
    const sub = user?.profile.sub;
    const prefix = 'KFJ-U-';
    if (user) {
      var id = sub.replace(prefix, '');

      return id.replace(
        /([0-z]{8})([0-z]{4})([0-z]{4})([0-z]{4})([0-z]{12})/,
        '$1-$2-$3-$4-$5'
      );
    }
  }
  return '';
};
