import SearchByNip from 'components/SearchByNip';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Input from 'components/Input';
import { ETabs as TAB } from 'components/EditClientModalNew/typings';

const NumbersClientModal: FC<Props> = ({ data, setData, view }) => {
  return (
    <div className={styles.person}>
      <div className={styles.personWrapper}>
        {data.personRegon !== null && (
          <div className={styles.input}>
            <Input
              isRegon
              // isError={''}
              label="Regon"
              defaultValue={
                view === TAB.COMPANY ? data.personRegon ?? '' : data.regon ?? ''
              }
              onChange={(t) => {
                setData({ ...data, personRegon: t });
              }}
            />
          </div>
        )}

        {data.bank.map((e, index) => {
          return (
            <div className={styles.input}>
              <Input
                value={e}
                onChange={(t) => {
                  const newPhones = [...data.bank];

                  newPhones[index] = t;

                  setData({ ...data, bank: newPhones });
                }}
                label="Numer konta bankowego"
              />
            </div>
          );
        })}

        {data.emails.map((e, index) => {
          if (index === 0) return;
          return (
            <div className={styles.input}>
              <Input
                value={e}
                onChange={(t) => {
                  const newEmails = [...data.emails];

                  newEmails[index] = t;

                  setData({ ...data, emails: newEmails });
                }}
                label="Dodatkowy email"
              />
            </div>
          );
        })}
        {data.phones.map((e, index) => {
          if (index === 0) return;
          return (
            <div className={styles.input}>
              <Input
                isPhoneNumber
                value={e}
                onChange={(t) => {
                  const newPhones = [...data.phones];

                  newPhones[index] = t;

                  setData({ ...data, phones: newPhones });
                }}
                label="Dodatkowy numer telefonu"
              />
            </div>
          );
        })}
        {data.EAddresses.map((e, index) => {
          if (index === 0) return;
          return (
            <div className={styles.input}>
              <Input
                value={e}
                onChange={(t) => {
                  const newPhones = [...data.EAddresses];

                  newPhones[index] = t;

                  setData({ ...data, EAddresses: newPhones });
                }}
                label="Dodatkowy adres E-doręczeń"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NumbersClientModal;
