import { axiosInstance } from "api/axios"
import { fetchImage } from "./parseHTML";
import { blobToBase64 } from "./blobToBase64";

export const getAvatar = async (id: any) => {
    const data = await fetchImage(`https://beta.notaup.pl/api/client/avatar?id=${id}`);
    const base64 = await blobToBase64(data);

    return base64;

    // return data.data;
}