import { Claims } from 'dto/User/claims';
import { User } from 'oidc-client-ts';

export const getUserIdentityVerified = (user: User) => {
  const verified = user?.profile[Claims.Eidas_IsVerified];
  if (verified) {
    return (<string>verified).toLowerCase() === 'true';
  } else {
    return false;
  }
};
