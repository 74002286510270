import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { threadId } from 'worker_threads';
import { AxiosResponse } from 'axios';
import { IOrderObject } from 'components/SortOrder/typings';

export interface IGetListOfMessages {
  FilterId?: string;
  CaseId?: string;
  Kind?: number;
  Page: number;
  Group: boolean;
  IsIncoming: boolean;
  SortProp: 'Date' | 'ReceiveDate' | 'Deadline' | 'SendDate';
  SortOrder: 'Ascending' | 'Descending';
  Text?: string | null;
  ThreadId?: string;
  enabled?: boolean;
  Desk?: boolean;
}

const queryKeys = {
  getPackage: (payload: IGetPackage, filters?: IOrderObject) => ['listService.getPackage', payload, filters],
  getListOfMessages: (payload: IGetListOfMessages) => [
    'listService.getListOfMessages',
    payload,
  ],
  getListOfPackages: (payload: IGetListOfMessages) => [
    'listService.getListOfPackages',
    payload,
  ],
  getMetaData: (filterId: string) => ['listService.getMetaData', filterId],
};

export interface IGetPackage {
  CaseId?: string;
  FilterId?: string;
  ThreadId?: string;
  Kind?: string;
  GroupThreads?: boolean;
  enabled?: boolean;
}

const getPackage = ({
  CaseId,
  FilterId,
  Kind,
  ThreadId,
  GroupThreads,
}: IGetPackage, filters?: IOrderObject) => {
  const query = new URLSearchParams(window.location.search);
  if (!!FilterId?.length) {
    query.append('Id', FilterId);
  }

  if (!!CaseId?.length) {
    query.append('CaseId', CaseId);
  }

  if (!!Kind?.length) {
    query.append('Kind', Kind);
  }

  if (GroupThreads != undefined) {
    query.append('GroupThreads', GroupThreads.toString());
  }

  if (!!ThreadId?.length) {
    query.append('ThreadId', ThreadId);
  }

  if(filters) {
    query.append('SortProp', filters.type);
    query.append('SortOrder', filters.order);
  }

  // if(!CaseId && !FilterId && !threadId) {
  //   return {
  //     data: [],

  //   } as AxiosResponse
  // }

  return axiosInstance.get(`/list?${query}`);
};

const useGetPackage = (payload: IGetPackage, filters?: IOrderObject) => {
  return useQuery(queryKeys.getPackage(payload, filters), () => getPackage(payload, filters), {
    keepPreviousData: false,
    enabled: payload.enabled,
  });
};

const getListOfMessages = async ({
  CaseId,
  FilterId,
  Page,
  Group,
  IsIncoming,
  SortProp,
  SortOrder,
  Text,
  ThreadId,
  Kind,
  Desk,
}: IGetListOfMessages) => {
  const query = new URLSearchParams(window.location.search);

  if (Text) {
    query.append('Text', Text);
  }
  if (FilterId) {
    query.append('FilterId', FilterId);
  }

  if (Desk) {
    query.append('Desk', Desk.toString());
  }

  if (Kind) {
    query.append('Kind', Kind.toString());
  }
  query.append('Page', Page.toString());
  query.append('Group', Group.toString());
  query.append('IsIncoming', IsIncoming.toString());
  query.append('SortProp', SortProp);
  query.append('SortOrder', SortOrder);

  if (!!CaseId?.length) {
    query.append('CaseId', CaseId);
  }

  if (ThreadId) {
    query.append('ThreadId', ThreadId);
  }

  const { data } = await axiosInstance.get(`/list?${query}`);

  return data;
};

const useGetListOfMessages = (payload: IGetListOfMessages) => {
  return useQuery(
    queryKeys.getListOfMessages(payload),
    () => getListOfMessages(payload),
    {
      keepPreviousData: false,
      enabled: payload.enabled,
    }
  );
};

const getListOfPackage = async (
  {
    CaseId,
    FilterId,
    Page,
    Group,
    IsIncoming,
    SortProp,
    SortOrder,
    Text,
    Kind,
    Desk,
  }: IGetListOfMessages,
  pageParam: number
) => {
  const query = new URLSearchParams(window.location.search);

  if (Text) {
    query.append('Text', Text);
  }
  if (FilterId) {
    query.append('FilterId', FilterId);
  }

  if (Kind) {
    query.append('Kind', Kind.toString());
  }

  if (Desk) {
    query.append('Desk', Desk.toString());
  }

  query.append('Page', pageParam.toString());
  query.append('Group', Group.toString());
  query.append('IsIncoming', IsIncoming.toString());
  query.append('SortProp', SortProp);
  query.append('SortOrder', SortOrder);

  if (!!CaseId?.length) {
    query.append('CaseId', CaseId);
  }

  const { data } = await axiosInstance.get(`/list?${query}`);

  return data;
};

const useGetListOfPackages = (payload: IGetListOfMessages) => {
  return useInfiniteQuery(
    ['projects'],
    async ({ pageParam = 0 }) => getListOfPackage(payload, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        // 
        const nextPage = 1;
        return nextPage;
      },
      enabled: payload.enabled,
    }
  );
};

interface IItem {
  Id: string;
  Kind: number;
}
export interface IDelete {
  Items: IItem[];
}

const deleteList = (payload: IDelete) => {
  return axiosInstance.post('List/trash', payload);
};

const useDeleteList = () => {
  return useMutation(deleteList);
};

export interface IManageDossier {
  AttachmentIds: string[];
  OwnerId: string;
  OwnerKind: number;
  Show: boolean;
}

const manageDossier = (payload: IManageDossier) => {
  return axiosInstance.post('list/dossier', payload);
};

const useManageDossier = () => {
  return useMutation(manageDossier);
};

const getMetaData = async (filterId: string) => {
  
  if (!filterId.length || filterId === 'sending' || filterId === 'all') {
    return [];
  }

  const r = await axiosInstance.get(`/list/filter?id=${filterId}`);

  return r?.data ?? [];
};

const useGetMetaData = (filterId: string) => {
  return useQuery(queryKeys.getMetaData(filterId), () => getMetaData(filterId));
};

export interface IRestorePackage {
  Items: {
    Id: string;
    Kind: number;
  }[];
}

const restorePackage = (payload: IRestorePackage) => {
  return axiosInstance.post('/list/restoreFromTrash', payload);
};

const useRestorePackage = () => {
  return useMutation(restorePackage);
};

export default {
  useGetPackage,
  useGetListOfMessages,
  useDeleteList,
  useGetListOfPackages,
  useManageDossier,
  useGetMetaData,
  useRestorePackage,
};
