import { Route, Routes } from 'react-router-dom';
import CasesModule from '.';

const CasesFullScreenRoute = () => {
  return (
    <Routes>
      <Route path="/:caseId/:view" element={<CasesModule hideMenu={true} />} />
      <Route
        path="/:caseId/:view/:kind/:messageId/:attachmentId"
        element={<CasesModule hideMenu={true} />}
      />
      <Route
        path="/:caseId/:view/:kind/:messageId/:attachmentId/:encryptionKey"
        element={<CasesModule hideMenu={true} />}
      />
    </Routes>
  );
};

export default CasesFullScreenRoute;
