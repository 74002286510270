import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import ReactDOM from 'react-dom';
import Input from 'components/Input';
import { useState } from 'react';
import Button from 'components/Button';
import editorService from 'api/editorService';
import pluginService from 'api/pluginService';

const CreateHistory: FC<Props> = ({ pack, history, toggle }) => {
  const [name, setName] = useState('');

  const { mutate: unlock } = editorService.useUnlockDocument();
  const { mutate: openEditor } = pluginService.useOpenEditor();

  const handleClick = () => {
    unlock(
      {
        Id: pack.Id,
        Name: name,
      },
      {
        onSuccess: (response: any) => {
          openEditor({
            Id: pack.Id,
            Kind: pack.Kind,
            VersionId: response.data.Id,
          });
        },
      }
    );
  };

  return ReactDOM.createPortal(
    <div className={styles.shadow}>
      <div className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            Nazwa wersji
            <CloseIcon onClick={toggle} />
          </div>

          <div className={styles.inputWrapper}>
            <Input
              placeholder="Nazwa pliku"
              label=""
              value={name}
              onChange={(text) => setName(text)}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button onClick={handleClick} text="Utwórz" />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default CreateHistory;
