import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as WindowsIcon } from 'assets/icons/windows.svg';
import { ReactComponent as MacIcon } from 'assets/icons/mac.svg';

export const BodyPluginUpdate = ({
  isWindows,
  isMac,
  linkWindows,
  linkMac,
  className,
}: {
  isWindows: boolean;
  isMac: boolean;
  linkWindows: string;
  linkMac: string;
  className?: string;
}) => {
  return (
    <div className={`${styles.window} `}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Zaaktualizuj wtyczkę
          <div className={styles.bar}></div>
        </div>
        <div className={styles.body}>
          Aby otwierać załączniki musisz posiadać najnowszą wersję wtyczki
        </div>

        <div className={styles.system}>
          {!isMac && (
            <a rel="noreferrer" target="_blank" href={linkWindows}>
              <div className={styles.box}>
                <WindowsIcon />
                Windows
              </div>
            </a>
          )}
          {!isMac && !isWindows && <div className={styles.line}></div>}
          {!isWindows && (
            <a rel="noreferrer" target="_blank" href={linkMac}>
              <div className={styles.box}>
                <MacIcon />
                MacOS
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
