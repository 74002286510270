import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useContext, useEffect, useMemo, useState } from 'react';
import listService from 'api/listService';
import { IPackage } from 'dto/IPackage';
import { IAttachment } from 'dto/IAttachment';
import { getBlob } from 'utils/getBlob';
import { decryptFile } from 'utils/decryptFile';
import fileService from 'api/fileService';
import PDFViewer from 'components/PDFViever';
import Comments from 'components/Comments';
import Annotations from 'components/Annotations';
import useGetPDFViewer from 'hooks/useGetPDFViewer';
import { fetchEmailAttachment } from 'utils/fetchEmailAttachment';
import { getTypeOfParcelFromKind } from 'utils/getTypeOfParcel';
import { IParcelTypes } from 'dto/IParcelTypes';
import PDFContext from 'contexts/PDFContext';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { ItemKind } from 'dto/IKindItems';
import pluginService from 'api/pluginService';
import { blobToBase64 } from 'utils/blobToBase64';
import { isAllowedAttachment } from 'utils/isAllowedAttachment';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import CPluginContext from 'contexts/PluginContext';
import { key } from 'openpgp';

const PDFModule = () => {
  const {
    filterId,
    status,
    kind,
    packageId,
    threadId,
    PDFId,
    threadPackageId,
  } = useParams();
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const [showPDF, setShowPDF] = useState<Blob | string | null>(null);

  const { data: packageData, isLoading } = listService.useGetPackage({
    FilterId: packageId ?? '',
    Kind: kind ?? '',
    enabled: true,
  });

  const { mutate: getFile } = fileService.useGetFiltersForCases();

  const { page, zoom } = useGetPDFViewer();

  const documentID = `${
    threadPackageId ?? packageId ?? threadId
  }:${kind}:${PDFId}`;

  const previewPackage: IPackage = useMemo(
    () => packageData?.data?.Items?.[0] ?? [],
    [packageData]
  );

  const parentKeys = useMemo(() => packageData?.data.Keys ?? [], [packageData]);
  const keys = useMemo(() => packageData?.data.ObjectKeys ?? [], [packageData]);

  const { mutate: tryPreview } = pluginService.usePreview();

  const previewFromPlugin = async (blob: Blob, attachment: IAttachment) => {
    const b: string = (await blobToBase64(blob)) as string;

    tryPreview(
      {
        AttachmentId: attachment.Id,
        Content: b.split(',')[1],
        ContentType: attachment.ContentType,
        DocumentId: attachment.Id,
        FileName: attachment.FileName,
        Kind: attachment.Kind,
      },
      {
        onSuccess: async (response: any) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setShowPDF(new Blob([buffer]));
        },
      }
    );
  };

  const { plugin } = useContext(CPluginContext);
  const [showVersion, setShowVersion] = useState(false);

  const getFileAndDecrypt = (uri: string, kind: ItemKind) => {
    if (!plugin.actual) {
      setShowVersion(true);
      return;
    }

    getFile(
      {
        id: uri,
        ownerKind: kind,
      },
      {
        onSuccess: async (data) => {
          let parentKey = null;

          if (packageData?.data.ObjectKeys[0]) {
            parentKey = parentKeys.find(
              (pkey: any) =>
                pkey.Id === packageData?.data.ObjectKeys[0].ParentKeyId
            );
          }

          const extension = data.data.FileName.split('.').at(-1);
          const isAllowed = isAllowedAttachment(extension ?? 'no');
          const isPDF = extension === 'pdf';

          const file = await getBlob(data.data.Url);
          try {
            const decryptedBlob = await decryptFile(
              userId,
              new Blob([file.data]),
              packageData?.data.ObjectKeys[0],
              parentKey
            );

            if (!isPDF) {
              previewFromPlugin(decryptedBlob, data.data as any);
            }
            setShowPDF(decryptedBlob);
          } catch (e) {
            if (!isPDF) {
              previewFromPlugin(new Blob([file.data]), data.data as any);
              return;
            }
            setShowPDF(new Blob([file.data]));
          }
        },
      }
    );
  };

  const decryptSecuredEmail = async (
    attachment: IAttachment
  ): Promise<Blob> => {
    return new Promise((resolve) => {
      getFile(
        {
          id: attachment.Uri,
          ownerKind: previewPackage.Kind,
        },
        {
          onSuccess: async (data) => {
            const file = await getBlob(data.data.Url);
            const blob = new Blob([file.data]);

            try {
              const encryptionKeyId = attachment.EncryptionKeyId;

              const key = keys.find((k: any) => k?.Id === encryptionKeyId);
              const key2 = parentKeys.find(
                (k: any) => k?.Id === encryptionKeyId
              );

              return resolve(blob);

              // const decryptedBlob = await decryptFile(
              //   userId,
              //   new Blob([file.data]),
              //   key?.data?.ObjectKeys?.[0],
              //   parentKey
              // );
              // resolve(decryptedBlob);
            } catch (e) {}
          },
        }
      );
    });
  };

  const onPreview = async (attachment: IAttachment) => {
    if (previewPackage.Kind === ItemKind.Email) {
      if (previewPackage.EncryptionKeyId) {
        const blob = await decryptSecuredEmail(attachment);
        setShowPDF(blob);
        return;
      }
    }
    getFileAndDecrypt(attachment.Uri, attachment.Kind);
  };

  useEffect(() => {
    // if (!!previewPackage) {
    //   if (previewPackage.Kind === ItemKind.CaseFile) {
    //     console.log('1');
    //     getFileAndDecrypt(previewPackage.S3 ?? '', previewPackage.Kind);
    //     return;
    //   }
    //   const attach = previewPackage?.Attachments?.find(
    //     (attachment) => attachment.Id === packageId || attachment.Id === PDFId
    //   );

    //   if (!attach) return;
    //   console.log('2');
    //   onPreview(attach);
    // }

    const pdf = sessionStorage.getItem('pdf');

    if (!pdf) return;

    var retrievedUint8Array = new Uint8Array(JSON.parse(pdf!));
    const blob = new Blob([retrievedUint8Array], {
      type: 'application/octet-stream',
    });
    setShowPDF(blob);
  }, [previewPackage]);

  const { PDF } = useContext(PDFContext);

  return (
    <div className={styles.wrapper}>
      {showVersion && (
        <PDFViewer file={new Blob([])} showVersion={showVersion} />
      )}
      {showPDF && <PDFViewer isRightSide file={showPDF} />}

      <div className={styles.commentsWrapper}>
        <Comments documentID={documentID} />
      </div>
    </div>
  );
};

export default PDFModule;
