import listService from "api/listService";
import { useEffect, useState } from "react";

function useFilterMetaData({filterId}: {filterId: string}) {
    const [listId, setListId] = useState<number | null>(null);
    const [listName, setListName] = useState('');
    const { data: metaData } = listService.useGetMetaData(filterId);

    useEffect(() => {
        if(!metaData?.Id) {
            setListId(10);
            setListName('Do opracowania')
            return;
        }
        setListId(metaData?.List)
        setListName(metaData?.Name)
    }, [metaData])


    return {
        listId, listName
    }
}
export default useFilterMetaData