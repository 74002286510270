import { FC, Props } from './typings';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useRef, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import filterService from 'api/filterService';
import { ReactComponent as SettingsAIcon } from 'assets/icons/settings_active.svg';
import { IFilter } from 'dto/IFilter';
import { useAuth } from 'react-oidc-context';
import { CreateFilterIncomingModal } from 'components/CreateFilterIncomingModal';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import { ReactComponent as PiImage } from 'assets/icons/filters/pi.svg';
import { ReactComponent as EmailImage } from 'assets/icons/filters/email.svg';
import { ReactComponent as PaperImage } from 'assets/icons/filters/paper.svg';
import { ReactComponent as CryptedImage } from 'assets/icons/filters/crypted.svg';
import { useNavigate } from 'react-router';
import WorkspaceContext from 'contexts/CurrentWorkspace';

const SidebarFilter: FC<Props> = ({
  id,
  getFilters,
  Icon,
  title,
  isDesk,
  fullId,
  name,
  isLetter: isLet,
  filters,
  handleChangeFilterState,
  canAddNewFilter,
  hideExpander,
}) => {
  const navigate = useNavigate();
  const [showEditDesk, setShowEditDesk] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [createFilter, setCreateFilter] = useState(false);
  const [edit, setEdit] = useState<IFilter | null>(null);
  const url = window.location.href;
  const params = url.split('/');
  const refWrapper = useRef(null);
  const ref = useRef(null);
  const auth = useAuth();
  const isGuest = auth?.user?.profile?.role === 'guest';
  const isTemplates = params.includes('templates');
  const isLetter = params.includes('pisma');

  const isCase = params.includes('cases');
  const isAction = params.includes('actions');
  const isCaseWithAction = isCase && isAction && name === 'actions';
  const isDeskEdit = params.includes('desk');

  const isPackages =
    fullId === '1B8AE8D3-3209-497D-9526-27608FFFA84D' &&
    params.includes('packages') &&
    params.includes('1B8AE8D3-3209-497D-9526-27608FFFA84D');

  const showActive =
    params.includes(fullId ?? name) && !isLet && !isLetter && !isCaseWithAction;
  const showActive2 = isLet && isLetter;

  const isHomePage = location.pathname === '/' || isDeskEdit;

  const { filterId, setFilterId } = useContext(WorkspaceContext);

  const handleNavigate = (
    fId: string | null | undefined,
    isIncoming: boolean = false
  ) => {
    if (fId === 'szablony') {
      setFilterId('');
      navigate('/templates');
      return;
    }

    if (id === 'templates') {
      setFilterId('');
      navigate('/templates');
      return;
    }

    if (id === 3) {
      if (fId) {
        setFilterId(fId);
        navigate('/cases');
        return;
      }

      setFilterId('');
      const CID = localStorage.getItem('last_case');
      const view = localStorage.getItem('last_view_case');

      if (CID) {
        let link = `/cases/${CID}`;
        if (view) {
          link = `${link}/${view}`;
        }

        if (!view) {
          link = `${link}/chat`;
        }

        navigate(link);
        return;
      }
      navigate('/cases');
      return;
    }

    if (id === 4) {
      setFilterId('');
      navigate('/actions');
      return;
    }
    if (id === 6) {
      setFilterId('');
      if (fId && fId !== 'clients') {
        navigate(`/clients/${fId}`);
        return;
      }
      navigate('/clients/all');
      return;
    }

    if (id === 7) {
      setFilterId('');
      navigate('/drive');
      return;
    }

    if (id === 8) {
      setFilterId('');
      navigate('/shares');
      return;
    }

    if (id === 'd76ad393-712b-4984-968a-f104579d6fa9') {
      setFilterId('');
      navigate(`/`);
      return;
    }

    setFilterId('');
    let url = `/packages/${fId}`;

    if (title !== 'Pisma') {
      if (id === 1) {
        url += '/incoming';
      } else {
        url += '/outcoming';
      }
    } else {
      url += '/pisma';
    }

    navigate(url);
  };

  const handleClick = (e: any) => {
    const el = e.target;

    if (el?.className?.search?.('expanderWrapper') !== -1) {
      return;
    }

    handleNavigate(fullId, id === 1 ? true : false);
  };

  return (
    <div className={styles.filtersWrapper}>
      <div
        onMouseEnter={() => {
          if (isDesk) {
            setShowEditDesk(true);
          }
        }}
        onMouseLeave={() => {
          setShowEditDesk(false);
        }}
        onClick={handleClick}
        ref={refWrapper}
        className={`${styles.title} ${showActive && styles.active} ${
          showActive2 && styles.active
        } ${isDesk && isHomePage && styles.active}`}
      >
        <div className={styles.name}>
          <Icon className={styles.headerIcon} />
          <span className={styles.textName}>{title}</span>
        </div>
        <div
          onClick={() => {
            if (!hideExpander && !isDesk) {
              setIsOpen(!isOpen);
              handleChangeFilterState(title);
            }
          }}
          className={styles.expanderWrapper}
        >
          {!hideExpander && !isDesk && (
            <FontAwesomeIcon
              className={classNames(
                styles.expander,
                isOpen && styles.expanderActive
              )}
              icon={faChevronDown}
            />
          )}
          {isDesk && showEditDesk && !isGuest && (
            <SettingsAIcon
              className={styles.icone}
              onClick={() => {
                if (isDesk) {
                  const isEdit = window.location.href.search('edit') !== -1;

                  if (isEdit) {
                    navigate('/');
                    return;
                  }
                  navigate('/desk/edit');
                  return;
                }
              }}
            />
          )}
        </div>
      </div>

      <div
        style={{
          maxHeight: isOpen ? `${36 * (filters.length + 1)}px` : '0px',
        }}
        ref={ref}
        className={`${styles.list}`}
      >
        {filters
          .filter((filter) => !!filter.Name.length)
          .map((filter: IFilter) => (
            <div
              onClick={(e: any) => {
                const ele = e.target.tagName.toLowerCase();

                if (ele !== 'div') {
                  return;
                }
                handleNavigate(filter.Id, filter?.isIncoming ?? true);
              }}
              className={`${styles.box}
              ${params.includes(filter.Id) && styles.active} ${
                fullId === 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99:1' &&
                isTemplates &&
                styles.active
              } ${edit === filter && styles.active} ${
                filterId === filter.Id && styles.active
              }
              `}
            >
              <span className={styles.boxText}>{filter.Name}</span>
              <SettingsAIcon
                onClick={() => {
                  setEdit(filter);
                  setCreateFilter(true);
                }}
                className={styles.icon}
              />
            </div>
          ))}
        {canAddNewFilter && !isGuest && (
          <div
            onClick={() => {
              setCreateFilter(true);
              setEdit(null);
            }}
            className={styles.addWrapper}
          >
            <div className={styles.addButton}>+</div>
            <div className={styles.addText}>Nowa lista</div>
          </div>
        )}
      </div>

      {createFilter && (
        <>
          {id === 1 && (
            <ModalLayout
              className={styles.overflow}
              refWrapper={refWrapper}
              dock={DockPosition.DISABLED}
            >
              <CreateFilterIncomingModal
                getFilters={getFilters}
                toggle={setCreateFilter}
                typed={[
                  {
                    id: 'CourtPortalDeliverance',
                    name: 'Doręczenie PI',
                    active: false,
                    image: PiImage,
                  },
                  {
                    id: 'CourtPortalDocument',
                    name: 'Pozostałe dokumenty PI',
                    active: false,
                    image: PiImage,
                  },
                  {
                    id: 'Parcel',
                    name: 'Przesyłki papierowe',
                    active: false,
                    image: PaperImage,
                  },
                  {
                    id: 'ContactMessage',
                    name: 'Przesyłki poufne',
                    active: false,
                    image: CryptedImage,
                  },
                  {
                    id: 'EDeliveryMessage',
                    name: 'Przesyłki Nota',
                    active: false,
                    image: CryptedImage,
                  },
                  {
                    id: 'Email',
                    name: 'E-mail',
                    active: false,
                    image: EmailImage,
                  },
                ]}
                id={id}
                listRef={ref}
                initialValues={edit}
              />
            </ModalLayout>
          )}
          {id === 2 && (
            <ModalLayout
              className={styles.overflow}
              refWrapper={refWrapper}
              dock={DockPosition.TopRight}
            >
              <CreateFilterIncomingModal
                getFilters={getFilters}
                toggle={setCreateFilter}
                typed={[
                  {
                    id: 'CourtPortalApplication',
                    name: 'Wnioski do PI',
                    active: false,
                    image: PiImage,
                  },
                  {
                    id: 'EDeliveryMessage',
                    name: 'Przesyłki Nota',
                    active: false,
                    image: CryptedImage,
                  },
                  {
                    id: 'Email',
                    name: 'E-mail',
                    active: false,
                    image: EmailImage,
                  },
                ]}
                id={id}
                listRef={ref}
                initialValues={edit}
              />
            </ModalLayout>
          )}
          {id === 3 && (
            <ModalLayout
              className={styles.overflow}
              refWrapper={refWrapper}
              dock={DockPosition.TopRight}
            >
              <CreateFilterIncomingModal
                showStatus={true}
                getFilters={getFilters}
                toggle={setCreateFilter}
                typed={[]}
                id={id}
                listRef={ref}
                initialValues={edit}
              />
            </ModalLayout>
          )}
          {id === 4 && (
            <ModalLayout
              className={styles.overflow}
              refWrapper={refWrapper}
              dock={DockPosition.TopRight}
            >
              <CreateFilterIncomingModal
                showStatus={true}
                getFilters={getFilters}
                toggle={setCreateFilter}
                typed={[]}
                id={id}
                listRef={ref}
                initialValues={edit}
              />
            </ModalLayout>
          )}
          {id === 6 && (
            <ModalLayout
              className={styles.overflow}
              refWrapper={refWrapper}
              dock={DockPosition.TopRight}
            >
              <CreateFilterIncomingModal
                showStatus={false}
                getFilters={getFilters}
                toggle={setCreateFilter}
                typed={[]}
                id={id}
                listRef={ref}
                initialValues={edit}
              />
            </ModalLayout>
          )}
        </>
      )}
    </div>
  );
};

export default SidebarFilter;
