import { EClientData } from 'dto/IClient';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Avatar from 'components/Avatar';

const PersonsClient: FC<Props> = ({ client }) => {
  const contactPersons =
    client?.Data?.filter?.((d) => d.Type === EClientData.CONTACT_PERSON) ?? [];
  return (
    <div className={styles.wrapper}>
      {contactPersons.map((m) => {
        const json: any = JSON.parse(m.Value);

        return (
          <div className={styles.box}>
            <Avatar
              name={`${json.FirstName ?? json.firstName} ${
                json.LastName ?? json.lastName
              }`}
            />
            <div className={styles.name}>
              {json.FirstName ?? json.firstName}{' '}
              {json.LastName ?? json.lastName}
              <br />
              {(json.Phone || json.phone) && (
                <>Numer telefonu: {json.Phone ?? json.phone}</>
              )}
              <br />
              {(json.Email || json.email) && (
                <>Email: {json.Email ?? json.email}</>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PersonsClient;
