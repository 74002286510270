import { IKey } from "dto/IKey";
import { createContext } from "react";

type IKeysContext = {
    keys: IKey[];
    setKeys: (keys: IKey[]) => void;
}

const KeysContext = createContext<IKeysContext>({
    keys: [],
    setKeys: () => {}
})

export default KeysContext;