import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';

const queryKeys = {
  get: (documentId: string) => ['annotationService.get', documentId],
};

const get = async (documentId: string) => {
  const response = await axiosInstance.get(
    `/Annotations?documentId=${documentId}`
  );
  return response.data ?? [];
};

const useGet = (documentId: string) => {
  return useQuery(queryKeys.get(documentId), () => get(documentId));
};

const create = (payload: {
  PageNumber: number;
  Type: string;
  Json: string;
  DocumentId: string;
}) => {
  return axiosInstance.post('/annotation', payload);
};

const useCreate = () => {
  return useMutation(create);
};

const createComment = (payload: { AnnotationId: string; Text: string }) => {
  return axiosInstance.post('/comment', payload);
};

const useCreateComment = () => {
  return useMutation(createComment);
};

const updateComment = (payload: { CommentId: string; Text: string }) => {
  return axiosInstance.put('/comment', payload);
};

const useUpdateComment = () => {
  return useMutation(updateComment);
};

const deleteAnnotation = (payload: { AnnotationId: string }) => {
  return axiosInstance.delete('/annotation', {
    data: payload,
  });
};

const useDeleteAnnotation = () => {
  return useMutation(deleteAnnotation);
};

export default {
  useGet,
  useCreate,
  useCreateComment,
  useUpdateComment,
  useDeleteAnnotation,
};
