import settingsService from 'api/settingsService';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useContext, useEffect } from 'react';
import settingsContext from 'contexts/SettingsContext';

const SettingsManager: FC<Props> = ({}) => {
  const { setOptions } = useContext(settingsContext);
  const { data: settingsData } = settingsService.useGetSettings();

  useEffect(() => {
    if (!settingsData?.data) return;
    setOptions(settingsData.data);
  }, [settingsData]);

  return <></>;
};

export default SettingsManager;
