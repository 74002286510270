import LayoutContext, { ILayoutSizes } from 'contexts/LayoutContext';
import styles from './styles.module.scss';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Props } from './typings';
import { useParams } from 'react-router-dom';
import { getListName } from 'utils/getListName';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightIcon } from 'assets/icons/arrow_right.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import SearchBar from 'components/SearchBar';
import useFilterMetaData from 'hooks/useFilterMetaData';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';

const PanelsLayout: FC<Props> = ({
  layoutName = 'layout',
  defaultLeft = 50,
  disableHeader = false,
  disableSearch = false,
  collapseRight = false,
  disablePadding = false,
  disableIcons = false,
  disableListStyle = false,
  disablePaddingScroll = false,
  onChangeSearch,
  leftPanel,
  rightPanel,
  leftClassName = '',
  resize = false,
  wrapperClassName = '',
  title,
  subTitle,
}) => {
  const { packageId, caseId } = useParams();
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const ref = useRef(null);

  const [panelsInitialized, setPanelsInitialized] = useState(false);
  const { filterId } = useParams();
  const [sizes, setSizes] = useState<ILayoutSizes>({
    width: 0,
    height: 0,
    isDraging: false,
  });

  const { listId, listName } = useFilterMetaData({
    filterId: filterId ?? '',
  });

  const onLayout = (size: number[]) => {
    if (!panelsInitialized) {
      const settings = localStorage.getItem(`${userId}.${layoutName}`);
      if (!settings?.length) {
        setPanelsInitialized(true);
        return;
      }
      const parsedSettings = JSON.parse(settings);
      setSizes({
        ...sizes,
        width: Number(parsedSettings.leftPanel),
        height: Number(parsedSettings.rightPanel),
      });
      //@ts-ignore
      ref?.current?.setLayout([
        parsedSettings.leftPanel,
        parsedSettings.rightPanel,
      ]);
      setPanelsInitialized(true);
      return;
    }

    setSizes({
      ...sizes,
      width: Number(size[0]),
      height: Number(size[1]),
    });
    localStorage.setItem(
      `${userId}.${layoutName}`,
      JSON.stringify({ leftPanel: size[0], rightPanel: size[1] })
    );
  };

  const handleCollapse = (newSizes: number[]) => {
    if (!ref?.current) return;

    if (sizes.width === 100 || sizes.height === 100) {
      //@ts-ignore
      ref.current.setLayout([50, 50]);
      setSizes({
        ...sizes,
        width: 50,
        height: 50,
      });
      return;
    }

    setSizes({
      ...sizes,
      width: newSizes[0],
      height: newSizes[1],
    });
    //@ts-ignore
    ref.current.setLayout(newSizes);
  };

  useEffect(() => {
    if (!!ref?.current) return;
    // @ts-ignore
    ref?.current?.setLayout([100, 0]);
  }, [ref, collapseRight]);

  const defaultSizeRight = 100 - defaultLeft;

  const isWork =
    filterId === 'C2192A34-C79B-4856-8D0B-02DA1C49C5F9' ||
    filterId === '027bdcf6-232b-4355-a396-2558c2c30f23' ||
    filterId === '675d78ff-983d-4b0e-a334-67699448572a' ||
    filterId === 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99';

  const isClients = window.location.pathname.search('clients') !== -1;
  const isTemplates = window.location.pathname.search('templates') !== -1;

  const t = window.location.pathname;
  const show = !isClients && !isTemplates;
  const [lastPage, setLastPage] = useState('');

  useEffect(() => {
    const path = t.split('/');
    if (!ref?.current) return;
    if (sizes.height === 100) {
      setLastPage(t);
      setSizes({
        ...sizes,
        width: 50,
        height: 50,
      });
      // @ts-ignore
      ref?.current?.setLayout([50, 50]);
    }
  }, [t]);

  useEffect(() => {
    if (!resize) return;
    if (packageId && sizes.width === 100) {
      setSizes({
        ...sizes,
        width: 50,
        height: 50,
      });
      //@ts-ignore
      ref?.current?.setLayout([50, 50]);
    }

    if (caseId && sizes.width === 100) {
      setSizes({
        ...sizes,
        width: 50,
        height: 50,
      });
      //@ts-ignore
      ref?.current?.setLayout([50, 50]);
    }
  }, [packageId, caseId]);

  return (
    <div className={`${styles.wrap} ${wrapperClassName}`}>
      <LayoutContext.Provider
        value={{
          sizes,
          setSizes,
        }}
      >
        {!disableIcons && (
          <div style={{ left: `${sizes.width}%` }} className={styles.elements}>
            <div
              onClick={() => handleCollapse([0, 100])}
              className={styles.element}
            >
              <LeftIcon className={styles.icon} />
            </div>
            <div
              onClick={() => handleCollapse([100, 0])}
              className={styles.element}
            >
              <RightIcon className={styles.icon} />
            </div>
          </div>
        )}
        <PanelGroup ref={ref} direction="horizontal" onLayout={onLayout}>
          <Panel defaultSize={defaultLeft}>
            {!disableHeader && (
              <div className={styles.header}>
                <div className={styles.left}>
                  <div className={styles.title}>
                    {title ?? getListName(listId ?? 0, false, isWork)}
                  </div>
                  <div className={styles.subTitle}>
                    {subTitle ? subTitle : <>{show && listName}</>}
                  </div>
                </div>
                <div className={styles.right}>
                  {!disableSearch && (
                    <SearchBar
                      onChange={(t, statusId, tagId, entityId, kind) =>
                        onChangeSearch?.(t, statusId, tagId, entityId, kind)
                      }
                    />
                  )}
                </div>
              </div>
            )}
            <div
              className={`${!disableListStyle && styles.list} ${
                disablePadding && styles.disablePadding
              } ${leftClassName} ${disablePaddingScroll && styles.disableList}`}
            >
              {leftPanel}
            </div>
          </Panel>
          <PanelResizeHandle
            onDragging={(isDraging) => {
              setSizes({ ...sizes, isDraging });
            }}
            className={`${styles.test} ${
              disablePaddingScroll && styles.disablePaddingScroll
            }`}
            id={layoutName}
          ></PanelResizeHandle>

          <Panel defaultSize={defaultSizeRight}>{rightPanel}</Panel>
        </PanelGroup>
      </LayoutContext.Provider>
    </div>
  );
};

export default PanelsLayout;
