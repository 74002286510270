import { EPrivileges } from "dto/EPrivileges";
import { IWorkspaceRole } from "dto/IWorkspace";


export interface IPrivilages {
    CanEditWorkspace: boolean,
    CanEditWorkspaceStructure: boolean,
    CanViewWorkspaceStructure: boolean,
    CanInviteMembersToWorkspace: boolean,
    CanEditMembersInWorkspace: boolean
    CanEditRole: boolean,
    CanViewRoles: boolean,
    canTaskAssign: boolean;
}

export const getPrivilages = (role: IWorkspaceRole | undefined): IPrivilages => {


    return {
        CanEditMembersInWorkspace: !!role?.Privileges.find(p => p === EPrivileges.CanEditMembersInWorkspace)?.length ?? false,
        CanEditRole: !!role?.Privileges.find(p => p === EPrivileges.CanEditRole)?.length ?? false,
        CanEditWorkspace: !!role?.Privileges.find(p => p === EPrivileges.CanEditWorkspace)?.length ?? false,
        CanEditWorkspaceStructure: !!role?.Privileges.find(p => p === EPrivileges.CanEditWorkspaceStructure)?.length ?? false,
        CanInviteMembersToWorkspace: !!role?.Privileges.find(p => p === EPrivileges.CanInviteMembersToWorkspace)?.length ?? false,
        CanViewRoles: !!role?.Privileges.find(p => p === EPrivileges.CanViewRoles)?.length ?? false,
        CanViewWorkspaceStructure: !!role?.Privileges.find(p => p === EPrivileges.CanViewWorkspaceStructure)?.length ?? false,
        canTaskAssign: !!role?.Privileges.find(p => p === EPrivileges.canTaskAssign)?.length ?? false,
    }


}