import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as CaseIcon } from 'assets/icons/clientCase.svg';
import { ReactComponent as PlaceIcon } from 'assets/icons/Place.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/clientEmail.svg';
import EmailClientModal from 'components/EmailClientModal';
import { useRef, useState } from 'react';
import PhoneClientModal from 'components/PhoneClientModal';
import AddressClientModal from 'components/AddressClientModal';
import { useOnClickOutside } from 'usehooks-ts';
import CasesClientModal from 'components/CasesClientModal';
import { ClientFlags } from 'dto/IClient';
import { checkFlags } from 'utils/checkFlags';

const defaultState = {
  case: false,
  email: false,
  phone: false,
  place: false,
};

const ClientIconState: FC<Props> = ({ client }) => {
  const refWrapper = useRef(null);

  const [state, setState] = useState(defaultState);

  useOnClickOutside(refWrapper, () => {
    if (state.case || state.email || state.phone || state.place) {
      setState(defaultState);
    }
  });

  const handleChangeView = (type: string) => {
    switch (type) {
      case 'case':
        setState({ ...defaultState, case: true });
        return;
      case 'email':
        setState({ ...defaultState, email: true });
        return;
      case 'phone':
        setState({ ...defaultState, phone: true });
        return;
      case 'place':
        setState({ ...defaultState, place: true });
        return;
      default:
        setState(defaultState);
        return;
    }
  };

  const hasEmail = checkFlags(client.Flags, ClientFlags.HasEmail);
  const hasPhone = checkFlags(client.Flags, ClientFlags.HasPhone);
  const hasAddress = checkFlags(client.Flags, ClientFlags.HasAddress);

  return (
    <div ref={refWrapper} className={styles.wrapper}>
      {client.CasesCount > 0 && (
        <CaseIcon
          onClick={() => handleChangeView('case')}
          className={styles.box}
        />
      )}
      {hasEmail && (
        <EmailIcon
          onClick={() => handleChangeView('email')}
          className={styles.box}
        />
      )}
      {hasPhone && (
        <PhoneIcon
          onClick={() => handleChangeView('phone')}
          className={styles.box}
        />
      )}
      {hasAddress && (
        <PlaceIcon
          onClick={() => handleChangeView('place')}
          className={styles.box}
        />
      )}
      {state.case && (
        <CasesClientModal client={client} refWrapper={refWrapper} />
      )}
      {state.email && (
        <EmailClientModal client={client} refWrapper={refWrapper} />
      )}
      {state.phone && (
        <PhoneClientModal client={client} refWrapper={refWrapper} />
      )}
      {state.place && (
        <AddressClientModal client={client} refWrapper={refWrapper} />
      )}
    </div>
  );
};

export default ClientIconState;
