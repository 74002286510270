import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ITag } from 'dto/ITag';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Input from 'components/Input';
import Button from 'components/Button';
import ReactDOM from 'react-dom';
import Chip from 'components/Chip';

const EditTagModal = ({
  tag,
  onChange,
}: {
  tag: ITag;
  onChange: (tag: ITag) => void;
}) => {
  const [value, setValue] = useState(tag.Name ?? '');

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Edytuj etykiete</div>
          <Input
            disabledTags
            label="Etykieta"
            value={value}
            onChange={(t) => setValue(t)}
          />
          <div className={styles.button}>
            <Button
              text="Zapisz"
              onClick={() => onChange({ ...tag, Name: value })}
            />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

const TagBadge: FC<Props> = ({
  tag,
  onEdit,
  onRemove,
  className,
  removeClassName,
  allwaysShowRemove,
}) => {
  const [showRemove, setShowRemove] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [editTag, setEditTag] = useState(false);

  return (
    <>
      <Chip
        className={{
          background: 'red !important',
        }}
        small={true}
        element={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            {tag?.Name}
          </div>
        }
        onDelete={
          onRemove
            ? () => {
                onRemove(tag);
              }
            : undefined
        }
      />

      {/* <div
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
        className={`${className ? className : styles.tag}`}
      >
        {!!tag?.Name?.length && <>#{tag?.Name?.toUpperCase()}</>}
        {onEdit && (showRemove || showMenu) && (
          <FontAwesomeIcon
            onClick={() => setShowMenu(!showMenu)}
            className={styles.remove}
            icon={faEllipsisVertical}
          />
        )}
        {onRemove && !onEdit && (showRemove || allwaysShowRemove) && (
          <CloseIcon
            onClick={(e) => {
              onRemove?.(tag);
              e.stopPropagation();
            }}
            className={`${removeClassName ? removeClassName : styles.remove}`}
          />
        )}
        {showMenu && (
          <div className={styles.dropdown}>
            <div
              onClick={() => {
                setEditTag(true);
                setShowMenu(false);
              }}
              className={styles.box}
            >
              Edytuj
            </div>
            {onRemove && (
              <div
                onClick={() => {
                  onRemove(tag);
                  setShowMenu(false);
                }}
                className={styles.box}
              >
                Usuń
              </div>
            )}
          </div>
        )}
      </div> */}
      {editTag && onEdit && (
        <EditTagModal
          tag={tag}
          onChange={(tag) => {
            onEdit(tag);
            setEditTag(false);
          }}
        />
      )}
    </>
  );
};

export default TagBadge;
