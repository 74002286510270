import { ITask } from 'dto/ITask';

export enum HubCallbacksNames {
  CalendarSynchronized = 'calendarSynchronized',
  UserIdentified = 'userIdentified',
  Online = 'online',
  Offline = 'offline',
}

export interface ICalendarSynchronized {
  new: ITask[];
  modified: ITask[];
  syncDate: string;
}

export interface IUserIdentified {
  success: boolean;
  isPESELInUse: boolean;
  message: string;
}
