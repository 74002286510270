import { axiosInstance } from "api/axios";
import moment from "moment";

export const logError = async (text: string, sendToBackend: boolean = false) => {
    if(process.env.NODE_ENV === 'development') {
        console.error(text);
    }

    if(sendToBackend) {
        const time = moment().toJSON();
        await axiosInstance.post('/logger', {
            message: text,
            level: 'error',
            url: window.location.href,
            timestamp: time,
        });
    }
}