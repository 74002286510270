import ActionModal from 'components/ActionModal';
import AddNewPackageModal from 'components/AddNewPackageModal';
import ApplicationLayout from 'components/ApplicationLayout';
import CreateCaseModal from 'components/CreateCaseModal';
import CreateDossierModal from 'components/CreateDossierModal';
import CreateLetterModal from 'components/CreateLetterModal';
import CreateOutgoingParcelModal from 'components/CreateOutgoingParcelModal';
import EditClientModal from 'components/EditClientModal';
import ProtectedRoute from 'components/ProtectedRoute';
import CaseChatContext from 'contexts/CaseChatContext';
import CasesContext from 'contexts/CasesContext';
import DecryptedMsgs, { IDecryptedMsg } from 'contexts/DecryptedMsgs';
import DossierFilesContext from 'contexts/DossierFilesContext';
import ModalsManagerContext, {
  IModalOptions,
  IModalsManager,
} from 'contexts/ModalsManager';
import PDFContext, { IPDF } from 'contexts/PDFContext';
import PackagesContext from 'contexts/PackagesContext';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { IPackage } from 'dto/IPackage';
import { IUser } from 'dto/IUser';
import PDFModule from 'modules/PDFModule';
import ActionsRoute from 'modules/actionsModule/Actions.route';
import CasesRoute from 'modules/casesModule/Cases.route';
import ClientsRoute from 'modules/clientsModule/Clients.route';
import DeskRoute from 'modules/deskModule/Desk.route';
import MessagesRoute from 'modules/packageModule/Messages.route';
import Recovery from 'modules/recovery';
import { useEffect, useState } from 'react';
//@ts-ignore
import { NotificationContainer } from 'react-notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { plPL } from '@mui/x-date-pickers/locales';
import PluginContext from 'components/PluginContext';
import SettingsModal from 'components/Settings/SettingsModal';
import Unauthorized from 'components/Unauthorized';
import Login from 'components/Login';
import ActionsContext from 'contexts/ActionsContext';
import CertContext, { ICert } from 'contexts/CertContext';
import ConverstationContext from 'contexts/ConversationContext';
import KeysContext from 'contexts/KeysContext';
import LettersContext from 'contexts/LettersContext';
import MenuContext from 'contexts/MenuContext';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IFeed } from 'dto/IFeed';
import { IKey } from 'dto/IKey';
import ActivationRoute from 'modules/activationModule/Activation.route';
import CasesFullScreenRoute from 'modules/casesModule/Cases.FullScreen.route';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { createSignalRContext } from 'react-signalr/signalr';
import './styles/notification.css';

import InvitationToCaseManager from 'components/InvitationToCaseManager';
import KeysManager from 'components/KeysManager';
import Spinner from 'components/Spinner';
import ClientsContext from 'contexts/ClientsContext';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import CPluginContext, { IPlugin } from 'contexts/PluginContext';
import { IClient } from 'dto/IClient';
import { IWorkspace } from 'dto/IWorkspace';
import ChooseWorkspace from 'modules/ChooseWorkspace';
import InvitationModule from 'modules/InvitationModule';
import { FolderModule } from 'modules/folderModule';
import TemplatesRoutes from 'modules/templatesModule/Templates.route';
import 'moment/locale/pl';
import { setWorkspaceId } from 'utils/setWorkspaceId';
import { INotification } from 'dto/INotification';
import { ENotificationType, notification } from 'utils/notification';
import NotificationManager from 'components/NotificationManager';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ExportModule } from 'modules/ExportModule';
import SettingsManager from 'components/SettingsManager';
import SettingsContext, { IOptions } from 'contexts/SettingsContext';
import RegisterModule from 'modules/registerModule';
import EditClientModalNew from 'components/EditClientModalNew';
import { HubCallbacksNames } from 'api/hub';
import NotificationContext from 'contexts/NotificationsContext';
import LetterModule from 'modules/letterModule';
import { ThreadModule } from 'modules/ThreadModule';
import { parseHTML } from 'utils/parseHTML';
import LoadingContext from 'contexts/LoadingContext';
import Loading from 'components/Loading';

import { LicenseInfo } from '@mui/x-license';
import CreateTaskModal from 'components/CreateTaskModal';
import DriveRoute from 'modules/driveModule/Drive.route';
import SharesRoute from 'modules/sharesModule/Shares.route';

LicenseInfo.setLicenseKey(
  '1e01c4024067a0bbb5f63948bc629466Tz04ODAwNSxFPTE3NDQyMDMzNjEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);
// import 'dayjs/locale/pl';

// const navigate = useNavigate();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const SignalRContext = createSignalRContext({
  shareConnectionBetweenTab: true,
});

export function App() {
  const auth = useAuth();
  const [user, setUser] = useState<IUser | null>(null);
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [modals, setModals] = useState<IModalsManager>({
    createDossier: false,
    createCase: false,
    createAction: false,
    createTask: false,
    createEntity: false,
    createCompany: false,
    createLetter: false,
    createPostage: false,
    createPost: false,
    settings: false,
    hide: false,
  });

  const [modalOptions, setModalOptions] = useState<IModalOptions>({});

  const [pdf, setPDF] = useState<IPDF>({
    page: 1,
    zoom: 1,
    annotation: null,
    showAnnotation: null,
    canvas: null,
    editComment: '',
  });

  const [workspace, setWorkspace] = useState<IWorkspace | null>(null);
  const [notis, setNotification] = useState<INotification[]>([]);
  const [settings, setSettings] = useState<IOptions[]>([]);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [keys, setKeys] = useState<IKey[]>([]);
  const [cases, setCases] = useState<ICaseSimple[]>([]);
  const [tasks, setTasks] = useState<IFeed[]>([]);
  const [casePackages, setCasePackages] = useState<IPackage[]>([]);
  const [decryptedMsgs, setDecryptedMsgs] = useState<IDecryptedMsg[]>([]);
  const [dossierFiles, setDossierFiles] = useState<IDossierItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [letters, setLetters] = useState<IPackage[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [plugin, setPlugin] = useState<IPlugin>({
    actual: true,
    version: '0',
    linkWindows: '',
    linkMac: '',
    isMac: false,
    isWindows: false,
  });

  const [filterId, setFilterId] = useState('');

  const [converstation, setConverstation] = useState<IPackage[]>([]);
  const [id, setId] = useState('');

  const [cert, setCert] = useState<ICert>({
    id: '',
    name: '',
    versionId: '',
  });

  const isGuest = auth?.user?.profile?.role === 'guest';

  SignalRContext.useSignalREffect(
    HubCallbacksNames.Online,
    (message) => {
      notification({
        title: `Użytkownik online`,
        text: `Inny użytkownik online: ${message.userId}`,
        type: ENotificationType.INFO,
      });
    },
    []
  );

  SignalRContext.useSignalREffect(
    HubCallbacksNames.Offline,
    (message) => {
      notification({
        title: `Użytkownik OFFLINE`,
        text: `Inny użytkownik OFFLINE: ${message.userId}`,
        type: ENotificationType.ERROR,
      });
    },
    []
  );

  const removeDuplicates = (keys: IKey[]) => {
    return keys.filter(
      (item, index, array) =>
        array.findIndex((i) => i?.Id === item?.Id) === index
    );
  };

  useEffect(() => {
    setInterval(() => {
      //@ts-ignore
      setKeys((prev) => {
        const hasDuplicates = prev.some(
          (item, index) => prev.findIndex((i) => i?.Id === item?.Id) !== index
        );

        if (!hasDuplicates) {
          return prev;
        }
        return removeDuplicates(prev).filter((f) => f !== undefined);
      });
    }, 5000);
  }, []);

  useEffect(() => {}, [keys]);

  useEffect(() => {
    if (auth.user) {
    }
  }, [auth.user]);

  useEffect(() => {
    if (!workspace) return;
    setWorkspaceId(workspace.Id);
  }, [workspace]);

  // todo - na później, jeżeli ostatnie ruchy myszką były w przeciągu 10 minut
  // to odświeżamy automatycznie, jeżeli nie to pokazujemy komunikat dla usera,
  // że zostanie niedługo wylogowany.

  React.useEffect(() => {
    return auth.events.addSilentRenewError(() => {});
  }, [auth.events, auth.signinSilent]);
  switch (auth.activeNavigator) {
    case 'signinSilent':
      if (!auth.user || !auth.isAuthenticated) {
        return <div>Trwa logowanie...</div>;
      }
      break;
    case 'signoutRedirect':
      return <div>Przekierowanie do wylogowania...</div>;
    default:
    //
  }

  // if (auth.) {
  //   return <div>Trwa ładowanie...</div>;
  // }

  if (auth.error && !auth.isAuthenticated) {
    return <div>Oops... {auth.error.message}</div>;
  }

  // useEffect(() => {
  //   NotificationManager.error('Info message', 'tes', 99999999999);
  // }, []);

  const h = window.location.href;
  const isInvitation = h.search('invitation') !== -1;
  const isLogin = h.search('login') !== -1;
  const isRegister = h.search('register') !== -1;

  if (isInvitation) {
    return (
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path="/invitation/:id/:type?"
              element={
                <ApplicationLayout>
                  <>
                    <InvitationModule notLogged={true} />
                    <NotificationContainer />
                  </>
                </ApplicationLayout>
              }
            />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    );
  }

  if (isRegister) {
    return (
      <SignalRContext.Provider url={'https://beta.notaup.pl/wsRegister'}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route
                path="/register"
                element={
                  <ApplicationLayout>
                    <RegisterModule SignalRContext={SignalRContext} />
                  </ApplicationLayout>
                }
              />
              <Route
                path="/register/confirm/:linkToken/:linkCode"
                element={
                  <ApplicationLayout>
                    <RegisterModule SignalRContext={SignalRContext} />
                  </ApplicationLayout>
                }
              />
            </Routes>
          </QueryClientProvider>
        </BrowserRouter>
      </SignalRContext.Provider>
    );
  }

  if (isLogin && !(auth.user && auth.isAuthenticated)) {
    return <Login></Login>;
  }

  if (auth.user && auth.isAuthenticated) {
    return (
      <ErrorBoundary>
        <LoadingContext.Provider
          value={{
            isLoading: loading,
            setIsLoading: setLoading,
          }}
        >
          <LocalizationProvider
            adapterLocale={'plPL'}
            localeText={
              plPL.components.MuiLocalizationProvider.defaultProps.localeText
            }
            dateAdapter={AdapterMoment}
          >
            <SignalRContext.Provider
              connectEnabled={!!auth.user.access_token}
              accessTokenFactory={() => auth.user!.access_token}
              dependencies={[auth.user.access_token]}
              url={
                process.env.REACT_APP_API_WS ??
                'https://beta.pocztaprawnicza.pl/hub'
              }
            >
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <ModalsManagerContext.Provider
                    value={{
                      modals,
                      options: modalOptions,
                      setModals,
                      setOptions: setModalOptions,
                    }}
                  >
                    <CPluginContext.Provider value={{ plugin, setPlugin }}>
                      <WorkspaceContext.Provider
                        value={{
                          workspace,
                          setWorkspace,
                          filterId,
                          setFilterId,
                        }}
                      >
                        <MenuContext.Provider value={{ id, setId }}>
                          <CertContext.Provider value={{ cert, setCert }}>
                            <KeysContext.Provider value={{ keys, setKeys }}>
                              <ActionsContext.Provider
                                value={{ tasks, setTasks }}
                              >
                                <NotificationContext.Provider
                                  value={{
                                    notifications: notis,
                                    setNotifications: setNotification,
                                  }}
                                >
                                  <PackagesContext.Provider
                                    value={{ packages, setPackages }}
                                  >
                                    <ClientsContext.Provider
                                      value={{ clients, setClients }}
                                    >
                                      <LettersContext.Provider
                                        value={{ letters, setLetters }}
                                      >
                                        <CasesContext.Provider
                                          value={{ cases, setCases }}
                                        >
                                          <ConverstationContext.Provider
                                            value={{
                                              converstation,
                                              setConverstation,
                                            }}
                                          >
                                            <CaseChatContext.Provider
                                              value={{
                                                casePackages,
                                                setCasePackages,
                                              }}
                                            >
                                              <DossierFilesContext.Provider
                                                value={{
                                                  dossierFiles,
                                                  setDossierFiles,
                                                }}
                                              >
                                                <DecryptedMsgs.Provider
                                                  value={{
                                                    decryptedMsgs,
                                                    setDecryptedMsgs,
                                                  }}
                                                >
                                                  <PDFContext.Provider
                                                    value={{
                                                      PDF: pdf,
                                                      setPDF: setPDF,
                                                    }}
                                                  >
                                                    <SettingsContext.Provider
                                                      value={{
                                                        options: settings,
                                                        setOptions: setSettings,
                                                      }}
                                                    >
                                                      <PluginContext />
                                                      <KeysManager />
                                                      <NotificationManager />
                                                      <SettingsManager />
                                                      <InvitationToCaseManager
                                                        SignalRContext={
                                                          SignalRContext
                                                        }
                                                      />
                                                      <ProtectedRoute
                                                        SignalRContext={
                                                          SignalRContext
                                                        }
                                                      >
                                                        <Routes>
                                                          <Route
                                                            path="/packages/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <MessagesRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/fullscreen/cases/*"
                                                            element={
                                                              <CasesFullScreenRoute />
                                                            }
                                                          />
                                                          <Route
                                                            path="/folder/:caseId/:folderId"
                                                            element={
                                                              <FolderModule />
                                                            }
                                                          />
                                                          <Route
                                                            path="/thread/:caseId/:threadId"
                                                            element={
                                                              <ThreadModule />
                                                            }
                                                          />
                                                          <Route
                                                            path="/cases/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <CasesRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/clients/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <ClientsRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/templates/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <TemplatesRoutes />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/desk/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <DeskRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/drive"
                                                            element={
                                                              <ApplicationLayout>
                                                                <DriveRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/shares"
                                                            element={
                                                              <ApplicationLayout>
                                                                <SharesRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/pdf/:kind/:packageId/:PDFId"
                                                            element={
                                                              <PDFModule />
                                                            }
                                                          />
                                                          <Route
                                                            path="/letter-preview/:caseId/:kind/:id/:letterId/:encryptionKey"
                                                            element={
                                                              <LetterModule />
                                                            }
                                                          />
                                                          <Route
                                                            path="/recovery"
                                                            element={
                                                              <Recovery />
                                                            }
                                                          />
                                                          <Route
                                                            path="/actions/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <ActionsRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/choose-workspace"
                                                            element={
                                                              <ChooseWorkspace />
                                                            }
                                                          />
                                                          <Route
                                                            path="/activation/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <ActivationRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/invitation/:id/:type"
                                                            element={
                                                              <ApplicationLayout>
                                                                <InvitationModule />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                          <Route
                                                            path="/export"
                                                            element={
                                                              <ExportModule />
                                                            }
                                                          />

                                                          <Route
                                                            path="/*"
                                                            element={
                                                              <ApplicationLayout>
                                                                <DeskRoute />
                                                              </ApplicationLayout>
                                                            }
                                                          />
                                                        </Routes>
                                                      </ProtectedRoute>
                                                      {modals.createDossier && (
                                                        <CreateDossierModal />
                                                      )}
                                                      {modals.createCase && (
                                                        <CreateCaseModal />
                                                      )}
                                                      {modals.createAction && (
                                                        <ActionModal />
                                                      )}
                                                      {modals.createEntity && (
                                                        <EditClientModalNew
                                                          label="Nowy podmiot"
                                                          onClose={() =>
                                                            setModals({
                                                              ...modals,
                                                              hide: true,
                                                            })
                                                          }
                                                          // newClient={true}
                                                        />
                                                      )}
                                                      {modals.createCompany && (
                                                        <EditClientModalNew
                                                          label="Nowy podmiot"
                                                          onClose={() =>
                                                            setModals({
                                                              ...modals,
                                                              hide: true,
                                                            })
                                                          }
                                                          isCompany
                                                          // newClient={true}
                                                        />
                                                      )}
                                                      {modals.createLetter && (
                                                        <CreateLetterModal />
                                                      )}
                                                      {modals.createPostage && (
                                                        <AddNewPackageModal />
                                                      )}
                                                      {modals.createPost && (
                                                        <CreateOutgoingParcelModal />
                                                      )}
                                                      {modals.createTask && (
                                                        <CreateTaskModal />
                                                      )}
                                                      {modals.settings && (
                                                        <SettingsModal
                                                          label={
                                                            isGuest
                                                              ? 'E-Doręczenia'
                                                              : undefined
                                                          }
                                                        />
                                                      )}
                                                      {/* <ReactQueryDevtools /> */}
                                                      {loading && (
                                                        <div
                                                          id="shado222"
                                                          style={{
                                                            width: '100vw',
                                                            height: '100vh',
                                                            position:
                                                              'absolute',
                                                            zIndex: 1002,
                                                            left: 0,
                                                            top: 0,
                                                            background:
                                                              'rgba(0, 0, 0, 0.1)',
                                                          }}
                                                        >
                                                          <Loading />
                                                        </div>
                                                      )}
                                                    </SettingsContext.Provider>
                                                  </PDFContext.Provider>
                                                </DecryptedMsgs.Provider>
                                              </DossierFilesContext.Provider>
                                            </CaseChatContext.Provider>
                                          </ConverstationContext.Provider>
                                        </CasesContext.Provider>
                                      </LettersContext.Provider>
                                    </ClientsContext.Provider>
                                  </PackagesContext.Provider>
                                </NotificationContext.Provider>
                              </ActionsContext.Provider>
                            </KeysContext.Provider>
                          </CertContext.Provider>
                        </MenuContext.Provider>
                      </WorkspaceContext.Provider>
                    </CPluginContext.Provider>
                  </ModalsManagerContext.Provider>
                </QueryClientProvider>
                <NotificationContainer />
              </BrowserRouter>
            </SignalRContext.Provider>
          </LocalizationProvider>
        </LoadingContext.Provider>
      </ErrorBoundary>
    );
  }

  return (
    <>
      {auth.isLoading && <Spinner></Spinner>}
      {/* tymczasowe - jak b�dzie landing page, to nie b�dzie potrzebne */}
      {!auth.isLoading && <Unauthorized />}
    </>
  );
}
