import { decryptPrivateKey } from "./decryptPrivateKey";
import { getKeys } from "./getKeys";
import * as openpgp from 'openpgp';
import { readFileFromBase64 } from "./readFileFromBase64";
import { logError } from "./logger";


interface IDecryptedKeys {
  id: string;
  key: any
}

let DECRYPTED_KEYS: IDecryptedKeys[] = [];



const decodeNotArmored = async (pack: any, key: any) => {
  console.log("not armored launch", key)
  const tresc = await readFileFromBase64(pack, 'application/other');
  console.log(tresc);
  const uint8 = new Uint8Array(tresc);
  console.log(uint8);
  const xpack = await openpgp.message.read(uint8);
  console.log(xpack);
  
  const decryptedPackage = await openpgp.decrypt({
    message: xpack,
    privateKeys: [key.keys[0]],
    // privateKeys: [],
    format: 'binary',
    
  });
  
  console.log('Gotowa');
  console.log(decryptedPackage);
  return new TextDecoder().decode(decryptedPackage.data)
}

const decodeArmored = async (pack: any, key: any) => {
  const xpack = await openpgp.message.readArmored(pack);
  const decryptedPackage = await openpgp.decrypt({
    message: xpack,
    privateKeys: key.keys[0],
    format: 'binary',
  });
  
  
  return new TextDecoder().decode(decryptedPackage.data)
}

const decodeString = async (pack: any, key: any) => {
  const tresc = await readFileFromBase64(pack, 'application/other');
  const xpack = await openpgp.message.read(new Uint8Array(tresc));
  const decryptedPackage = await openpgp.decrypt({
    message: xpack,
    privateKeys: key.keys[0],
    // privateKeys: [],
    format: 'binary',
    
  });
  
  
  return new TextDecoder().decode(decryptedPackage.data)
}

const decodeKey = async(
  userId: string,
  keys?: any,
  parentKeys?: any

) => {
  const myKeys = await getKeys(userId);
  let privateKey = null;
    
  if(parentKeys) {
    console.log('1', keys, parentKeys);
    const decryptParentKey = await decryptPrivateKey(myKeys!, parentKeys.PrivateKey);
    
    console.log('1 udane');
    const text = await openpgp.key.read(decryptParentKey.data);
    console.log('2');
    
    
    const PK = await readFileFromBase64(keys.PrivateKey, 'application/other');
    console.log('3');
    
    const msg = await openpgp.message.read(new Uint8Array(PK));
    console.log('4');
    
    
    var options: openpgp.DecryptOptions = {
      message: msg,
      privateKeys: text.keys[0],
      format: 'binary',
    };
    
    privateKey = await openpgp.decrypt(options);
    
  } else {
    if(keys?.PrivateKey) {
      privateKey = await decryptPrivateKey(myKeys!, keys.PrivateKey);
    }
  }
  
  console.log('3');
  
  let key = null;
  
  if(privateKey) {
    key = await openpgp.key.read(privateKey.data);
  } else {
    key = await openpgp.key.readArmored(atob(myKeys!.intermidatePrivateKey!.data))
  }
  console.log('4');
  
  if (keys?.EmptyPassphrase) {
    key.keys[0].decrypt('');
  }
  
  if(keys && !DECRYPTED_KEYS.find(k => k.id === keys?.Id)) {
    DECRYPTED_KEYS.push({id: keys.Id, key: key});
  }
  console.log('5');


  return key;
}


export const decryptPackage = async (
    userId: string,
    pack: any,
    keys?: any,
    parentKeys?: any,
) => {


    let key = null;
    if(!!DECRYPTED_KEYS.find(k => k?.id === keys?.Id)) {
      key = DECRYPTED_KEYS.find(k => k?.id === keys?.Id)?.key;
    } else {
      key = await decodeKey(
        userId, keys, parentKeys
        );
    }

  

    console.log("asdf");


    try {
      console.log("not armored");
      return await decodeNotArmored(pack, key);
    } catch(e) {
      try {
        console.log("armored");
        return await decodeArmored(pack, key);
      } catch(e) {
        logError(`[DecryptPackage] ${e}`);
        return await decodeString(pack, key);
      }

    }
}