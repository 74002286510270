import GridWindow from 'components/GridWindow';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as DeskIcon } from 'assets/icons/notifications.svg';
import notificationService from 'api/notificationService';
import { INotification } from 'dto/INotification';
import moment from 'moment';
import { ReactComponent as CaseIcon } from 'assets/icons/case_sidebar.svg';

import { ReactComponent as LettersIcon } from 'assets/icons/letters.svg';
import { ReactComponent as MailOutline } from 'assets/icons/mail_outline.svg';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import NotificationContext from 'contexts/NotificationsContext';
import { axiosInstance } from 'api/axios';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';

const NotiBox = ({
  item,
  refetch,
}: {
  item: INotification;
  refetch: () => void;
}) => {
  const { mutate: deleteNoti } = notificationService.useDeleteNotification();

  const navigate = useNavigate();
  const SwitchIcon = () => {
    switch (item.AssociatedObjectKind) {
      case 1024:
        return <CaseIcon />;
      case 2048:
        return <LettersIcon />;
      case 1:
        return <MailOutline />;
      case 2:
        return <MailOutline />;
      case 8:
        return <MailOutline />;
      case 16:
        return <MailOutline />;
      case 32:
        return <CaseIcon />;
      case 64:
        return <MailOutline />;
      case 128:
        return <MailOutline />;
      case 256:
        return <LettersIcon />;
      default:
        return <DeskIcon />;
    }
  };

  const handleClick = (e: any) => {
    const ele = e.target.tagName.toLowerCase();
    const button = e.target.outerHTML.search('button') !== -1;

    if (ele !== 'div' || button) {
      return;
    }
    if (!item.AssociatedObjectKind) return;

    switch (item.Reason) {
      case 0:
        navigate(
          `/packages/1B8AE8D3-3209-497D-9526-27608FFFA84D/incoming/${item.AssociatedObjectKind}/${item.AssociatedObjectId}`
        );
        return;
      case 1:
        navigate(`/cases/${item.AssociatedObjectId}/chat`);
        return;
      case 2:
        navigate(`/cases/${item.AssociatedObjectId}/chat`);
        return;
      case 8:
        navigate(
          `/packages/1B8AE8D3-3209-497D-9526-27608FFFA84D/incoming/${item.AssociatedObjectKind}/${item.AssociatedObjectId}`
        );
        return;
      case 9:
        navigate(
          `/packages/sending/outcoming/${item.AssociatedObjectKind}/${item.AssociatedObjectId}`
        );
        return;
      case 13:
        navigate(`/cases/${item.AssociatedObjectId}/chat`);
        return;
      case 14:
        navigate(
          `/packages/1B8AE8D3-3209-497D-9526-27608FFFA84D/incoming/${item.AssociatedObjectKind}/${item.AssociatedObjectId}`
        );
        return;
      case 15:
        navigate(
          `/packages/1B8AE8D3-3209-497D-9526-27608FFFA84D/incoming/${item.AssociatedObjectKind}/${item.AssociatedObjectId}`
        );
        return;
    }
  };

  const [hover, setHover] = useState(false);

  const handleDelete = () => {
    deleteNoti(
      {
        Id: item.Id,
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  return (
    <div
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.box}
    >
      <div className={styles.wrapBox}>
        <div className={styles.icons}>
          {item.Unread && <div className={styles.dot}></div>}
          <div className={styles.icon}>{SwitchIcon()}</div>
        </div>
        <div className={styles.data}>
          <div className={styles.title}>
            <div className={styles.left}>{item.Label}</div>

            <div className={styles.right}>
              {hover && (
                <BinIcon
                  onClick={() => handleDelete()}
                  className={styles.remove}
                />
              )}
              {moment(item.CreationDate).format('DD.MM.YY')}
            </div>
          </div>
          {item.Priority === 2 && <div className={styles.i}>PILNE</div>}
          <div className={styles.description}>{item.Message}</div>
        </div>
      </div>
    </div>
  );
};

const NotificationDesk: FC<Props> = ({ id, handleRemove, isEditMode }) => {
  const { data, refetch } = notificationService.useGetNotifications();

  const { notifications, setNotifications } = useContext(NotificationContext);

  useEffect(() => {
    if (!data?.Items?.length) return;

    if (!!notifications.length) {
      for (const notification of notifications) {
        axiosInstance.post('notification/read', {
          Id: notification.Id,
        });
        notification.Unread = false;
      }

      setNotifications(
        data.Items.filter(
          (a: INotification) => !notifications.filter((b) => a.Id !== b.Id)
        )
      );
      return;
    }

    setNotifications(data?.Items?.filter((f: INotification) => f.Unread));
  }, [data]);

  return (
    <GridWindow
      handleRemove={handleRemove}
      id={id}
      isEditMode={isEditMode}
      title={
        <>
          {' '}
          <DeskIcon /> Powiadomienia
        </>
      }
    >
      <div className={styles.wrapper}>
        {data?.Items?.map((item: INotification) => (
          <NotiBox key={item.Id} refetch={refetch} item={item} />
        ))}
        {/* <NotiBox />
        <NotiBox />
        <NotiBox /> */}
      </div>
    </GridWindow>
  );
};

export default NotificationDesk;
