export type { FC } from 'react';

export interface Props {
  showTabBar: (v: boolean) => void;
}

export enum EIntegrationsViews {
  COURT = 'COURT',
  EMAIL = 'EMAIL',
  CALENDAR = 'CALENDAR',
  EDITORS = 'EDITORS',
  ACCOUNTANCY = 'ACCOUNTANCY',
}
