// ConversationContextimport 
import { RefObject, createContext } from "react";

import { IPackage } from "dto/IPackage";

type IConverstationContext = {
    converstation: IPackage[];
    setConverstation: (msgs: IPackage[]) => void;
}

const ConverstationContext = createContext<IConverstationContext>({
    converstation: [],
    setConverstation: () => {}
})

export default ConverstationContext;