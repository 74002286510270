import prettyBytes from 'pretty-bytes';
import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as NewIcon } from 'assets/icons/new_tab.svg';
import { ReactComponent as DowloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as AttachIcon } from 'assets/icons/attachToCase2.svg';
import { useState } from 'react';
import AttachmentIconComponent from 'components/AttachmentIconComponent';
import sanitizeHtml from 'sanitize-html';

const Attachment: FC<Props> = ({
  date,
  attachment,
  onPreview,
  onDownload,
  onNewWindow,
  onAddDossier,
  isInDossier = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClickOnFile = (e: React.MouseEvent<HTMLDivElement>) => {
    const target: any = e.target;

    if (target.dataset.is) {
      onPreview(attachment);
      return;
    }
  };

  return (
    <div
      data-is={true}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
      onClick={handleClickOnFile}
      className={styles.wrapper}
    >
      <div className={styles.content}>
        <AttachmentIconComponent
          className={styles.iconPDF}
          file={attachment?.FileName ?? ''}
        />
        <div className={styles.metaData} data-is={true}>
          <div
            data-is={true}
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                !!attachment?.Label?.length
                  ? attachment.Label
                  : attachment?.FileName ?? ''
              ),
            }}
          ></div>
          <div data-is={true} className={styles.size}>
            {prettyBytes(attachment?.Size ?? 0)} {date && <>{date}</>}
          </div>
        </div>
        {showMenu && (
          <div className={styles.menu}>
            <NewIcon onClick={() => onNewWindow(attachment)} />

            <DowloadIcon onClick={() => onDownload(attachment)} />

            {onAddDossier && (
              <AttachIcon onClick={() => onAddDossier(attachment)} />
            )}
          </div>
        )}
        {isInDossier && !showMenu && onAddDossier && (
          <AttachIcon onClick={() => onAddDossier(attachment)} />
        )}
      </div>
    </div>
  );
};

export default Attachment;
