import casesService from 'api/casesService';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useParams } from 'react-router';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { useMemo } from 'react';
import CaseCard from 'components/CaseCard';
import statusService from 'api/statusService';
import tagsService from 'api/tagsService';

const CasesClient: FC<Props> = ({}) => {
  const { clientId } = useParams();
  const { data: casesData } = casesService.useGetCases({
    page: 0,
    ParticipantId: clientId ?? '',
  });
  const Cases: ICaseSimple[] = useMemo(
    () => casesData?.data.Items ?? [],
    [casesData]
  );

  const { data: tagsData } = tagsService.useGetTags();
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: statusesData } = statusService.useGetStatuses();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  return (
    <div className={styles.wrapper}>
      {Cases.map((c: ICaseSimple) => (
        <CaseCard
          key={c.Id}
          className={styles.card}
          c={c}
          showS={false}
          tags={tags}
          statuses={statuses}
          setSelectedEdit={(c) => {}}
        />
      ))}
    </div>
  );
};

export default CasesClient;
