import { IUserOfficeRole } from "dto/IUserOfficeRole"
import { IUserOfficeStatus } from "dto/IUserOfficeStatus"

export const convertOfficeUserStatus = (type: number) => {
    switch(type) {
        case 0:
            return IUserOfficeStatus.WAITING_FOR_APPROVAL
        case 1:
            return IUserOfficeStatus.INVITATION
        case 2:
            return IUserOfficeStatus.REJECTED
        case 3:
            return IUserOfficeStatus.REMOVED
        case 4:
            return IUserOfficeStatus.ACTIVE
    }
}

export const convertOfficeUserRole = (type: number) => {
    switch(type) {
        case 0:
            return IUserOfficeRole.NONE
        case 1:
            
        return IUserOfficeRole.ADMIN
        case 2:
            
        return IUserOfficeRole.LAWYER
        case 3:
            
        return IUserOfficeRole.REGISTRY_OFFICE
    }
}