import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const SecondCheckbox: FC<Props> = ({
  value,
  defaultValue = false,
  onChange,
  text,
  className = '',
}) => {
  const [checked, setChecked] = useState(defaultValue ?? false);

  let isChecked = checked;

  if (value !== null && value !== undefined) {
    isChecked = value;
  }
  return (
    <div
      onClick={() => {
        setChecked(!value ?? !checked);
        onChange(!value ?? !checked);
      }}
      className={`${styles.wrapper}`}
    >
      <div className={styles.m}></div>
      <div
        className={`${styles.check} ${className} ${
          isChecked && styles.checkedMarker
        }`}
      >
        {isChecked && (
          <FontAwesomeIcon className={styles.icon} icon={faCheck} />
        )}
      </div>
      {text}
    </div>
  );
};

export default SecondCheckbox;
