import { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import SelectFlagNew from 'components/SelectFlagNew';
import Input from 'components/Input';
import ChooseTagNew from 'components/ChooseTagNew';
import TagBadge from 'components/TagBadge';
import Button from 'components/Button';
import { IEntity } from 'dto/IEntity';
import { ICaseParticipant } from 'dto/Cases/ICaseParticipant';
import SearchEntities from 'components/SearchEntities';
import EntityCard from 'components/EntityCard';
import casesService, { INewTag } from 'api/casesService';
import { ENotificationType, notification } from 'utils/notification';

const AdditionalCaseCreate: FC<Props> = ({ onCreate }) => {
  // const { data: tagsData } = tagsService.useGetTags();
  // const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);

  // const { data: statusesData } = statusService.useGetStatuses();
  // const statuses: IStatus[] = useMemo(
  //   () => statusesData?.data ?? [],
  //   [statusesData]
  // );

  const { mutate: createCase } = casesService.useCreateCase();

  const [selectedStatus, setSelectedStatus] = useState<IStatus | null>(null);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);

  const [values, setValues] = useState({
    title: '',
    signature: '',
  });

  const [entities, setEntities] = useState<ICaseParticipant[]>([]);

  const addNewTag = (tag: ITag | null) => {
    const isTag = selectedTags.find((etag) => etag.Name === tag!.Name);
    if (isTag) return;
    setSelectedTags([...selectedTags, tag!]);
  };

  const handleRemoveTag = (tag: ITag) =>
    setSelectedTags(selectedTags.filter((etag) => tag.Name !== etag.Name));

  const removeEntity = (entityId: number) =>
    setEntities(entities.filter((entity) => entity.Id !== entityId));

  const updateIsMain = (entityId: string | number) => {
    setEntities(
      entities.map((entity) => {
        if (entityId === entity.Id) {
          entity.IsMain = !entity.IsMain;
        }
        return entity;
      })
    );
  };

  const handleClickSave = () => {
    const newTags: INewTag[] = selectedTags
      .filter((tag) => tag.Id === '-1')
      .map((tag) => ({
        Id: null,
        Name: tag.Name,
        Type: tag.Type,
      }));

    const tagIds = selectedTags
      .filter((tag) => tag.Id !== '-1')
      .map((tag) => tag.Id);

    const participants: ICaseParticipant[] = entities.map((entity) => {
      return {
        //@ts-ignore
        UserId: entity.Type === 1 || entity.Type === 4 ? entity.Id : undefined,
        //@ts-ignore
        ParticipantId: entity.Type === 3 ? entity.Id : undefined,
        Email: entity.Email,
        EDeliveryAddress: entity.EDeliveryAddress,
        Address: entity.Address,
        Label: entity.Label,
        RoleId: entity.RoleId ?? null,
        ParcelTypes: entity.ParcelTypes,
        DefaultParcelType: null,
        IsMain: entity.IsMain ?? false,
      };
    });

    createCase(
      {
        NewTags: newTags,
        Participants: participants,
        Signature: values.signature,
        TagIds: tagIds,
        Title: values.title,
        StatusId: selectedStatus?.Id,
      },
      {
        onSuccess: (resp) => {
          onCreate(resp.data);
        },
        onError: () => {
          notification({
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.padding}>
        <div className={styles.status}>
          <SelectFlagNew
            selectedStatus={selectedStatus}
            onChoose={(status) => setSelectedStatus(status)}
          />
        </div>
        <div className={styles.inputs}>
          <Input
            focus
            name="title"
            tags={false}
            placeholder="Tytuł teczki, wpisując # wyszukaj tag lub dodaj nowy"
            onChange={(t) => setValues({ ...values, title: t })}
            label="Tytuł"
            value={values.title}
          />
          <Input
            label="Sygnatura"
            name="signature"
            onChange={(t) => setValues({ ...values, signature: t })}
            value={values.signature}
          />

          <SearchEntities
            removeFocus
            onChoose={(entity) =>
              setEntities([...entities, entity as ICaseParticipant])
            }
          />
        </div>

        {!!entities.length && (
          <>
            {entities.map((entity) => (
              <EntityCard
                withoutRole
                //@ts-ignore
                removeEntity={removeEntity}
                //@ts-ignore
                setEntityRole={() => {}}
                updateIsMain={updateIsMain}
                key={entity.Id}
                entity={entity}
              />
            ))}
          </>
        )}

        <div className={styles.tags}>
          <ChooseTagNew onChoose={addNewTag} />

          {!!selectedTags.length && (
            <div className={styles.list}>
              {selectedTags.map((tag) => (
                <TagBadge
                  className={styles.tag}
                  removeClassName={styles.remove}
                  key={tag.Id}
                  tag={tag}
                  onRemove={handleRemoveTag}
                />
              ))}
            </div>
          )}

          <div className={styles.buttons}>
            <Button
              className={styles.button}
              onClick={handleClickSave}
              text="+ Dodaj"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalCaseCreate;
