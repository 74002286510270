import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_l.svg';
import settingService from 'api/settingsService';
import Input from 'components/Input';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import { getErrorMessage } from 'utils/getErrorMessage';
import Dropdown from 'components/Dropdown';
import { IDropdownObject } from 'components/Dropdown/typings';
import { ICalendarIntegrationSettings } from 'dto/Settings/ICalendarIntegrationSettings';
import classNames from 'classnames';
import { TwoFactorMethod } from 'dto/Settings/TwoFactorMethod';

const WebauthnMethod: FC<Props> = ({
  isActive,
  onCanceled,
  onSaved,
  handleRemove,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.pathBar}>
        <div className={styles.backButton}>
          <BackIcon onClick={onCanceled}></BackIcon>
        </div>
        Metody podwójnego uwierzytelniania <ArrowLeftIcon></ArrowLeftIcon>{' '}
        Webauthn
      </div>

      {isActive && (
        <>
          <div className={styles.hint}>
            Autoryzacja przy użyciu Webauthn/FIDO została skonfigurowana i jest
            aktywna
            <br></br>
            Aby skonfigurować nowe urządzenie/klucz konieczne jest usunięcie
            obecnego
          </div>
        </>
      )}

      {!isActive && (
        <>
          <div className={styles.hint}>
            Postępuj zgodnie z instrukcjami Twojej przeglądarki internetowej.
          </div>
        </>
      )}

      <div className={styles.buttons}>
        {isActive && (
          <div className={styles.disconnect}>
            <a onClick={() => handleRemove(TwoFactorMethod.WebAuthn)}>
              Usuń metodę
            </a>
          </div>
        )}
        <HollowButton text="Anuluj" onClick={onCanceled}></HollowButton>
      </div>
      <div className={classNames(styles.hint, styles.disconnect)}></div>
    </div>
  );
};

export default WebauthnMethod;
