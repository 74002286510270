import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CompleteTask } from 'assets/icons/check.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag_border.svg';
import { ReactComponent as HashIcon } from 'assets/icons/hash2.svg';
import { ReactComponent as UnCompleteIcon } from 'assets/icons/unhandled.svg';
import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';

import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import AddTagModal from 'components/AddTagModal';
import CreateActionModal from 'components/CreateActionModal';
import ModalLayout from 'components/ModalLayout';
import StatusModal from 'components/StatusModal';
import { RefObject, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import taskService from 'api/taskService';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { ITask } from 'dto/ITask';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { ENotificationType, notification } from 'utils/notification';
import deskService from 'api/deskService';
import ActionModal from 'components/ActionModal';

const ActionsOptionsCard = ({
  refWrapper,
  task,
  showOptions,
  className = '',
  handleChangeStatus,
  handleClickDelete,
  handleChangeFlag,
  handleUpdateTask,
  refetch,
  selectedStatus,
  handleAddTag,
  handleRemoveTag,
}: {
  className?: string;
  refWrapper: RefObject<HTMLDivElement>;
  task: ITask;
  showOptions: boolean;
  refetch: () => void;
  handleChangeStatus: (id: string) => void;
  handleChangeFlag: (id: string, statusId: number) => void;
  handleClickDelete: (id: string) => void;
  handleUpdateTask: (c: any) => void;
  selectedStatus: IStatus | null;

  handleAddTag: (c: string, tagId: string) => void;
  handleRemoveTag: (c: string, tagId: string) => void;
}) => {
  const navigate = useNavigate();
  const { mutate: complete } = taskService.useComplete();
  const { mutate: uncomplete } = taskService.useUncomplete();
  const { mutate: deleteTask } = taskService.useDeleteTask();

  const { mutate: setDesk } = deskService.useSetDesk();

  const [showActions, setShowActions] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showFlags, setShowFlags] = useState(false);
  const [edit, setEdit] = useState(false);

  const tRef = useRef(null);
  if (!refWrapper?.current) return <></>;
  const position = refWrapper?.current?.getBoundingClientRect?.();
  const x = position.x + position.width / 2;
  const y = position.y - 14;

  // const showAttachIcon = !pack.Case && pack.IsIncoming;
  // const showSomething = true;

  const getPosition = (refWrapper: RefObject<HTMLDivElement>) => {
    if (!refWrapper?.current) return { x: 0, y: 0, width: 0, height: 0 };
    const position = refWrapper?.current?.getBoundingClientRect?.();
    return {
      x: position.x ?? 0,
      y: position.y ?? 0,
      width: position.width ?? 0,
      height: position.height ?? 0,
    };
  };

  const handleClickComplete = () => {
    if (task.CompleteDate) {
      uncomplete({
        Id: task.Id,
      });
      handleChangeStatus(task.Id);
      return;
    }

    handleChangeStatus(task.Id);
    complete({
      Id: task.Id,
    });
  };

  const handleDeletePackage = () => {
    deleteTask({
      Id: task.Id,
      MoveToTrash: true,
    });
    handleClickDelete(task.Id);
  };

  const handleUpdateTags = (tags: ITag[]) => {
    // let newPacks = [...packages];
    // let el = newPacks.find((c) => c.Id === pack.Id && c.Kind === pack.Kind);
    // if (el) {
    //   el.TagIds = tags.map((t) => t.Id);
    // }
  };

  const handleAddToDesk = () => {
    setDesk(
      {
        Items: [{ Id: task.Id, Kind: task.Kind }],
        Show: !task.IsDeskActive,
      },
      {
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  const handleChangeDate = (task: ITask | null) => {
    refetch();
    setEdit(false);
  };

  const buildIcons = () => {
    return (
      <>
        <EditIcon title="Edytuj" onClick={() => setEdit(true)} />
        {task.CompleteDate ? (
          <UnCompleteIcon
            title="Oznacz jako niegotowe"
            onClick={handleClickComplete}
          />
        ) : (
          <CompleteTask
            title="Oznacz jako gotowe"
            onClick={handleClickComplete}
          />
        )}
        {!task.IsDeskActive && (
          <DeskIcon title="Przypnij" onClick={handleAddToDesk} />
        )}
        {/* <CalendarIcon
          title="Czynności"
          onClick={() => {
            setShowCreateAction(!showCreateAction);
            setShowTags(false);
            setShowFlags(false);
          }}
        /> */}
        <HashIcon
          title="Taguj"
          onClick={() => {
            setShowTags(!showTags);
            setShowActions(false);
            setShowFlags(false);
          }}
        />
        <FlagIcon
          onClick={() => {
            setShowFlags(true);
            setShowTags(false);
            setShowActions(false);
          }}
          title="Ustaw status"
        />
        <BinIcon title="Usuń" onClick={handleDeletePackage} />
      </>
    );
  };

  task.Kind = 131072;

  return ReactDOM.createPortal(
    <>
      <div
        style={{
          left: `${x}px`,
          top: `${y - 3}px`,
        }}
        ref={tRef}
        className={`${styles.options} ${
          !showOptions && styles.hide
        } ${className}`}
      >
        {buildIcons()}
      </div>
      {showTags && (
        <ModalLayout refWrapper={tRef}>
          <AddTagModal
            toggle={setShowTags}
            updateTags={handleUpdateTags}
            toTag={task}
            isTask
            afterAddTag={(tagId) => handleAddTag(task.Id, tagId)}
            afterRemoveTag={(tagId) => handleRemoveTag(task.Id, tagId)}
          />
        </ModalLayout>
      )}

      {showCreateAction && (
        <ModalLayout refWrapper={tRef}>
          {
            <CreateActionModal
              kind={0}
              showCase={false}
              refetch={refetch}
              toggle={() => setShowCreateAction(false)}
              startDate={moment(task.FromDate ?? new Date()).toDate()}
              x={200}
              y={200}
              afterUpdate={handleUpdateTask}
              endDate={moment(task.ToDate ?? task.FromDate).toDate()}
              initialValue={{
                id: task.Id,
                note: task.Note,
                fullday: task.AllDay,
                case: task.Case,
              }}
            />
          }
        </ModalLayout>
      )}
      {showFlags && (
        <ModalLayout customX={250} customY={-30} refWrapper={tRef}>
          <StatusModal
            item={{
              Id: task.Id,
              Kind: 131072,
              StatusId: task.StatusId,
            }}
            toggle={setShowFlags}
            afterUpdate={(flag) => handleChangeFlag(task.Id, flag!.Id)}
            selectedStatus={selectedStatus}
          />
        </ModalLayout>
      )}

      {edit && (
        <ActionModal
          onChange={handleChangeDate}
          initialState={{
            task: task,
            id: task.Id,
            Id: task.Id,
            case: task.Case,
            endDate: moment(task.ToDate).toDate(),
            startDate: moment(task.FromDate).toDate(),
            text: task.Note,
            title: task.Title,
            allDay: task.AllDay,
          }}
        />
      )}
    </>,
    document.body
  );
};

export default ActionsOptionsCard;
