import { Route, Routes } from 'react-router-dom';
import SharesModule from '.';

const SharesRoute = () => {
  return (
    <Routes>
      <Route path="/:folderId?" element={<SharesModule />} />
      <Route path="*" element={<SharesModule />} />
    </Routes>
  );
};

export default SharesRoute;
