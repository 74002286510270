import statusService from 'api/statusService';
import taskService, { IUpdateTaskPayload } from 'api/taskService';
import Placeholder from 'assets/placeholder.png';
import AdditionalClientModal from 'components/AdditionalClientModal';
import Button from 'components/Button';
import ChooseTagNew from 'components/ChooseTagNew';
import DatePickerNew, { DateSidePick } from 'components/DatePickerNew';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import SearchCasesNew from 'components/SearchCasesNew';
import SearchMemberNew from 'components/SearchMemberNew';
import SelectFlagNew from 'components/SelectFlagNew';
import TagBadge from 'components/TagBadge';
import TextArea from 'components/TextArea';
import ActionsContext from 'contexts/ActionsContext';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import ModalsManagerContext from 'contexts/ModalsManager';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IFeed } from 'dto/IFeed';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { IWorkspaceMember } from 'dto/IWorkspace';
import { Formik, useFormikContext } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { getPrivilages } from 'utils/getPrivilages';
import { getUserId } from 'utils/getUserId';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import styles from './styles.module.scss';
import { FC, InitialValues, Props } from './typings';
import { validationSchema } from './validation.schema';

import tagsService from 'api/tagsService';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Chip from 'components/Chip';
import { flexibleCompare } from '@fullcalendar/core/internal';
import { Avatar } from '@mui/material';
import { convertToLetters } from 'utils/convertToLetters';

const ActionModal: FC<Props> = ({ initialState, onChange, pack }) => {
  const auth = useAuth();
  const { data: tagsData } = tagsService.useGetTags();
  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const { data: statusesData } = statusService.useGetStatuses();

  const statusList = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const userId = getUserId(auth.user!);
  const LS = localStorage.getItem(`${userId ?? ''}.layout`);
  const sizes = JSON.parse(LS ?? '[]');
  const [Case, setCase] = useState<ICaseSimple | null>(null);
  const { modals, setModals } = useContext(ModalsManagerContext);
  const { mutate: createTask, isLoading: isCreateTask } =
    taskService.useUpdateTask();
  const { mutate: updateTask, isLoading: isUpdateTask } =
    taskService.useNewUpdateTask();
  const { mutate: setStatus } = statusService.useSetStatus();
  const [selectedStatus, setSelectedStatus] = useState<IStatus | null>(null);

  const { workspace } = useContext(WorkspaceContext);

  const isLoading = isCreateTask || isUpdateTask;

  const myPrivilages = getPrivilages(workspace?.Roles[0]);

  const [fullDay, setFullDay] = useState(false);
  const { tasks, setTasks } = useContext(ActionsContext);

  const [tags, setTags] = useState<ITag[]>([]);
  const [members, setMembers] = useState<IWorkspaceMember[]>([]);

  const [isPackage, setIsPackage] = useState<string | null>(null);
  const [initialStartTime, setInitialStartTime] = useState<null | Date>(null);
  const [initalEndTime, setInitialEndTime] = useState<null | Date>(null);

  useEffect(() => {
    const pack = sessionStorage.getItem('action');
    const date = sessionStorage.getItem('action-date');
    if (!!pack?.length) {
      setIsPackage(pack);
      sessionStorage.removeItem('action');
    }

    if (!!date?.length) {
      setInitialStartTime(moment(date).toDate());
      setInitialEndTime(moment(date).add(30, 'minutes').toDate());
      sessionStorage.removeItem('action-date');
    }
  }, []);

  const handleClick = (values: InitialValues) => {
    let packId: string | null = null;
    let packKind: string | null = null;

    if (isPackage) {
      const p = isPackage.split(':');
      packId = p[1];
      packKind = p[2];
    }

    if (pack) {
      packId = pack.Id;
      packKind = String(pack.Kind);
    }

    const isAllDay = moment(values.startDate).format('HH:mm') === '00:00';

    let payload: IUpdateTaskPayload = {
      OwnerId: packId ? packId : values?.case ? values.case!.Id : '',
      OwnerKind: packKind
        ? Number(packKind)
        : values?.case
        ? values.case!.Kind
        : 0,
      Allday: isAllDay,
      FromDate: moment(values.startDate).toISOString(true),
      Id: initialState ? initialState.id : null,
      IsFavourite: true,
      Title: values.title,
      Note: values.text,
      TagIds: tags.map((tag) => tag.Id),
      ToDate: values.endDate ? moment(values.endDate).toISOString(true) : null,
    };

    if (!!members.length) {
      payload.WorkspaceId = getWorkspaceId() ?? '';
      payload.ForUserId = members[0].UserId;
    }
    // if (values.member) {
    //   payload.WorkspaceId = getWorkspaceId() ?? '';
    //   payload.ForUserId = values.member.UserId;
    // }

    if (initialState?.id) {
      updateTask(payload, {
        onSuccess: (response: any) => {
          if (onChange) {
            closeModal();
            onChange(response.data);
            return;
          }
        },
      });

      return;
    }

    createTask(payload, {
      onError: (e: any) => {
        // notification({
        //   type: ENotificationType.ERROR,
        //   title: 'Wystąpił błąd',
        //   text:
        //     e?.response?.data?.errorCodes?.[0]?.message ??
        //     'Coś poszło nie tak... spróbuj ponownie później',
        // });
      },
      onSuccess: (response: any) => {
        if (onChange) {
          closeModal();
          onChange(response.data);
          return;
        }

        const task: IFeed = {
          IsFavourite: false,
          note: response.data.Note ?? '',
          allDay: response.data.AllDay,
          AllDay: response.data.AllDay,
          id: response.data.Id,
          Id: response.data.Id,
          title: response.data.Title ?? '',
          description: response.data.Note ?? '',
          Note: response.data.Note ?? '',
          caseId: values?.case?.Id ?? undefined,
          Case: response.data.Case,
          FromDate: moment(response.data.FromDate).toISOString(true),
          ToDate: response.data.ToDate
            ? moment(response.data.ToDate).toISOString(true)
            : moment(response.data.FromDate)
                .endOf('day')
                .add(1, 'm')
                .toISOString(true),
          start: moment(response.data.FromDate).toDate(),
          end: response.data.ToDate
            ? moment(response.data.ToDate).toDate()
            : moment(response.data.FromDate).endOf('day').add(1, 'm').toDate(),
          TagIds: response.data.TagIds,
          StatusId: selectedStatus?.Id,
          statusId: selectedStatus?.Id,
        };
        setTasks([task, ...tasks]);
        if (selectedStatus) {
          setStatus(
            {
              OwnerId: response.data.Id,
              OwnerKind: 131072,
              StatusId: selectedStatus?.Id,
            },
            {
              onSuccess: () => {
                closeModal();
              },
              onError: (r: any) => {
                // notification({
                //   type: ENotificationType.ERROR,
                //   title: 'Wystąpił błąd',
                //   text: 'Coś poszło nie tak... spróbuj ponownie później',
                // });
              },
            }
          );
        } else {
          closeModal();
        }
      },
    });
  };

  const closeModal = () => {
    setModals({ ...modals, hide: true });
    onChange?.(null);
  };

  const [init, setInit] = useState(false);

  const initialValues: any = {
    case: null,
    title: '',
    text: '',
    startDate: null,
    endDate: null,
    member: null,
  };

  const Observer = () => {
    const { validateForm, values, setFieldValue } = useFormikContext();
    const data: InitialValues = values as InitialValues;

    useEffect(() => {
      setTimeout(() => {
        validateForm();
      }, 100);
    }, [values]);

    useEffect(() => {
      if (moment(data.startDate).isAfter(data.endDate)) {
        setFieldValue(
          'endDate',
          moment(data.startDate).add(1, 'hour').toDate()
        );
        return;
      }
    }, [data.startDate]);

    useEffect(() => {
      if (moment(data.endDate).isBefore(data.startDate)) {
        setFieldValue(
          'startDate',
          moment(data.endDate).subtract(1, 'h').toDate()
        );

        return;
      }
    }, [data.endDate]);

    return <></>;
  };

  const CaseObserver = () => {
    const { values, setFieldValue, validateForm, setFieldTouched } =
      useFormikContext();
    const data: any = values as any;

    useEffect(() => {
      if (!isPackage) return;
      setFieldValue('case', '1234');
      setFieldTouched('case', true);
      setTimeout(() => {
        validateForm();
      }, 100);
    }, [isPackage]);

    useEffect(() => {
      if (!Case) return;
      setFieldValue('case', Case);
      setFieldTouched('case', true);
    }, [Case]);

    useEffect(() => {
      if (initialStartTime) {
        setFieldValue('startDate', initialStartTime);
      }
      if (initalEndTime) {
        setFieldValue('endDate', initalEndTime);
      }
    }, [initialStartTime, initalEndTime]);

    useEffect(() => {
      if (!initialState || init) return;

      setInit(true);

      // if (!initialState?.case) return;
      if (initialState?.case) {
        setFieldValue('case', initialState?.case);
      }
      setFieldValue('title', initialState?.title);
      setFieldValue('text', initialState?.text);
      setFieldValue('startDate', initialState?.startDate);

      setTags(
        tagsList.filter(
          (t: ITag) => !!initialState?.task?.TagIds.find((tt) => tt === t.Id)
        )
      );

      const s = statusList.find(
        (s: IStatus) => s.Id === initialState?.task?.StatusId
      );

      if (s) {
        setSelectedStatus(s);
      }

      if (!initialState?.allDay) {
        setFieldValue('endDate', initialState?.endDate);
      }
    }, [initialState]);

    return <></>;
  };

  const [selected, setSelected] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showCase, setShowCase] = useState(false);

  const refWrapper = useRef(null);

  const showRight = showCalendar || showCase;

  return (
    // Jeżeli będzie potrzeba to tą zawartość wynieśc do funkcji build i w ten sposób obsłużyć okna modalne
    <Formik
      validationSchema={validationSchema}
      onSubmit={() => {}}
      initialValues={initialValues}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        setFieldTouched,
        isValid,
        validateForm,
      }) => {
        return (
          <ModalLayout
            dock={DockPosition.DISABLED}
            off={true}
            customX={1}
            customY={0}
            ignore
            refWrapper={refWrapper}
            isAnimation
            fullHeight={true}
          >
            <>
              <CaseObserver />
              <div className={styles.wrap}>
                <div
                  className={`${styles.window} ${
                    showCalendar && styles.disableBorder
                  }`}
                >
                  <div className={styles.wrapper}>
                    <div className={styles.title}>
                      Nowe wydarzenie
                      <CloseIcon
                        onClick={closeModal}
                        className={styles.closeIcon}
                      />
                    </div>

                    <div className={styles.content}>
                      <div className={styles.topBar}>
                        <div className={styles.left}>
                          <SelectFlagNew
                            selectedStatus={selectedStatus}
                            onChoose={(s) => setSelectedStatus(s)}
                          />

                          {!showCalendar && (
                            <DatePickerNew
                              showTime
                              startOf={values.startDate}
                              endOf={values.endDate}
                              setDate={(type, date) => {
                                if (type === 1) {
                                  setFieldValue('startDate', date);

                                  if (values.startDate === null) {
                                    setFieldValue(
                                      'endDate',
                                      moment(date).add(30, 'minutes').toDate()
                                    );
                                  }

                                  if (moment(date).isAfter(values.endDate)) {
                                    setFieldValue(
                                      'endDate',
                                      moment(date).add(30, 'minutes').toDate()
                                    );
                                  }
                                }
                                if (type === 2) {
                                  setFieldValue('endDate', date);

                                  if (moment(date).isBefore(values.startDate)) {
                                    setFieldValue(
                                      'startDate',
                                      moment(date).toDate()
                                    );
                                  }
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className={styles.right}>
                          {/* <div
                            onClick={() => {
                              setShowCalendar(!showCalendar);
                            }}
                            className={styles.icon}
                          >
                            {showCalendar ? <HideIcon /> : <ShowIcon />}
                          </div> */}
                        </div>
                      </div>
                      <div className={styles.input}>
                        {!isPackage && (
                          <SearchCasesNew
                            settedCase={values.case}
                            onBlur={() => setFieldTouched('case', true)}
                            setAddNewEntity={() => {
                              setShowCase(true);
                            }}
                            onChoose={(cas) => {
                              setFieldValue('case', cas);
                              setFieldTouched('case', true);
                            }}
                          />
                        )}
                      </div>
                      <TextArea
                        focus
                        className={styles.input}
                        onBlur={() => setFieldTouched('title', true)}
                        value={values.title}
                        onChange={(t) => {
                          setFieldValue('title', t);
                        }}
                        label="Tytuł"
                      />
                      <TextArea
                        onBlur={() => setFieldTouched('text', true)}
                        className={styles.input}
                        value={values.text}
                        label={'Opis wydarzenia'}
                        onChange={(text) => {
                          setFieldValue('text', text);
                        }}
                        minRows={2}
                      />

                      <div className={styles.controls}>
                        <ChooseTagNew
                          type={0}
                          onChoose={(tag) => {
                            const isExist = tags.find(
                              (t) => t.Name === tag?.Name
                            );

                            if (isExist) return;

                            setTags([...tags, tag!]);
                          }}
                        />

                        <SearchMemberNew
                          onChoose={(member) =>
                            setMembers([...members, member as IWorkspaceMember])
                          }
                        />
                      </div>

                      <div className={styles.list}>
                        {!!tags.length && (
                          <div className={styles.tags}>
                            {tags.map((tag) => (
                              <Chip
                                element={<>{tag.Name}</>}
                                onDelete={() => {
                                  setTags(tags.filter((t) => t.Id !== tag.Id));
                                }}
                              />
                            ))}
                          </div>
                        )}
                        <div className={styles.members}>
                          {members.map((member) => (
                            <Chip
                              avatar={
                                <Avatar className={styles.avatar}>
                                  {convertToLetters(member.Label)}
                                </Avatar>
                              }
                              element={
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  {member.Label}
                                </div>
                              }
                              onDelete={() => {
                                setMembers(
                                  members.filter((f) => f.Id !== member.Id)
                                );
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className={styles.footer}>
                      <div className={styles.buttonWrapper}>
                        <Button
                          loading={isLoading}
                          disabled={!values.title.length}
                          // disabled={
                          //   isPackage
                          //     ? !values.title.length
                          //     : !values.case || !values.title.length
                          // }
                          className={styles.button}
                          //  handleError={handleError}
                          onClick={() => handleClick(values)}
                          text={
                            initialState?.Id ? 'Zapisz' : 'Utwórz wydarzenie'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showRight && (
                  <div className={styles.datePicker}>
                    <AdditionalClientModal
                      showActionModal
                      component={
                        <div className={styles.card}>
                          <DateSidePick
                            selected={selected}
                            setDate={(type, date) => {
                              if (type === 1) {
                                setFieldValue('startDate', date);
                              }
                              if (type === 2) {
                                setFieldValue('endDate', date);
                              }
                            }}
                            setSelected={setSelected}
                            startOf={values.startDate}
                            endOf={values.endDate}
                          />
                        </div>
                      }
                      showCase={showCase}
                      onCreateCase={(c) => {
                        setCase(c);
                        setShowCase(false);
                      }}
                    />
                  </div>
                  // <div className={styles.datePicker}>
                  //   <DateSidePick
                  //     selected={selected}
                  //     setDate={(type, date) => {
                  //       if (type === 1) {
                  //         setFieldValue('startDate', date);
                  //       }
                  //       if (type === 2) {
                  //         setFieldValue('endDate', date);
                  //       }
                  //     }}
                  //     setSelected={setSelected}
                  //     startOf={values.startDate}
                  //     endOf={values.endDate}
                  //   />
                  // </div>
                )}
              </div>
            </>
          </ModalLayout>
        );
      }}
    </Formik>
  );
};

export default ActionModal;
