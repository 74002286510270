export const createChunks = (file: Blob) => {
    const chunkSize = 10000000; 
  
    const chunks = [];
    for (let start = 0; start < file.size; start += chunkSize) {
      const chunk = file.slice(start, start + chunkSize)
      chunks.push(chunk);
    }
  
    return chunks;
  }