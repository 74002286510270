import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useState } from 'react';
import { IPrivilages, getPrivilages } from 'utils/getPrivilages';
import SecondCheckbox from 'components/SecondCheckbox';
import Checkbox from 'components/Checkbox';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import workspaceService from 'api/workspaceService';
import { EPrivileges } from 'dto/EPrivileges';

export const WorkspaceCreateRole: FC<Props> = ({
  workspaceId,
  initialValue,
  onCancel,
  onCreate,
}) => {
  const [name, setName] = useState(initialValue?.Name ?? '');

  const p = getPrivilages(initialValue ?? undefined);

  const { mutate: createRole } = workspaceService.useCreateWorkspaceRole();
  const { mutate: editRole } = workspaceService.useEditWorkspaceRole();

  const [permissions, setPermissions] = useState<IPrivilages>({
    CanEditMembersInWorkspace: p.CanEditMembersInWorkspace,
    CanInviteMembersToWorkspace: p.CanInviteMembersToWorkspace,
    CanEditWorkspace: p.CanEditWorkspace,
    CanViewRoles: p.CanViewRoles,
    CanEditRole: p.CanEditRole,
    CanViewWorkspaceStructure: p.CanViewWorkspaceStructure,
    CanEditWorkspaceStructure: p.CanEditWorkspaceStructure,
    canTaskAssign: p.canTaskAssign,
  });

  const handleCreateRole = () => {
    const keys = Object.keys(permissions);
    const entries = Object.values(permissions);

    const privileges = entries
      .map((e, index) => {
        if (e === true) {
          const el = keys[index];
          //@ts-ignore
          return EPrivileges[el];
        }
      })
      .filter((m) => m);

    if (initialValue?.Id) {
      editRole(
        {
          Name: name,
          Privileges: privileges,
          Id: initialValue.Id,
        },
        {
          onSuccess: () => onCreate(),
        }
      );

      return;
    }

    createRole(
      {
        Name: name,
        Privileges: privileges,
        WorkspaceId: workspaceId,
      },
      {
        onSuccess: () => onCreate(),
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Utwórz rolę</div>
      <Input label="Nazwa roli" value={name} onChange={(t) => setName(t)} />
      <div className={styles.title}>Zakres uprawnień</div>
      <div className={styles.permissions}>
        <SecondCheckbox
          text="Edytuj nazwę zespołu"
          value={permissions.CanEditWorkspace}
          onChange={() => {
            setPermissions({
              ...permissions,
              CanEditWorkspace: !permissions.CanEditWorkspace,
            });
          }}
        />
        <SecondCheckbox
          text="Zaprasza do zespołu"
          value={permissions.CanInviteMembersToWorkspace}
          onChange={() => {
            setPermissions({
              ...permissions,
              CanInviteMembersToWorkspace:
                !permissions.CanInviteMembersToWorkspace,
            });
          }}
        />
        <SecondCheckbox
          text="Edytuje osoby z listy 'zespół'"
          value={permissions.CanEditMembersInWorkspace}
          onChange={() => {
            setPermissions({
              ...permissions,
              CanEditMembersInWorkspace: !permissions.CanEditMembersInWorkspace,
            });
          }}
        />
        <SecondCheckbox
          text="Przegląda role"
          value={permissions.CanViewRoles}
          onChange={() => {
            setPermissions({
              ...permissions,
              CanViewRoles: !permissions.CanViewRoles,
            });
          }}
        />
        <SecondCheckbox
          text="Edytuje role"
          value={permissions.CanEditRole}
          onChange={() => {
            setPermissions({
              ...permissions,
              CanEditRole: !permissions.CanEditRole,
            });
          }}
        />
        <SecondCheckbox
          text="Przeglada strukturę"
          value={permissions.CanViewWorkspaceStructure}
          onChange={() => {
            setPermissions({
              ...permissions,
              CanViewWorkspaceStructure: !permissions.CanViewWorkspaceStructure,
            });
          }}
        />
        <SecondCheckbox
          text="Edytuje strukturę"
          value={permissions.CanEditWorkspaceStructure}
          onChange={() => {
            setPermissions({
              ...permissions,
              CanEditWorkspaceStructure: !permissions.CanEditWorkspaceStructure,
            });
          }}
        />
        <SecondCheckbox
          text="Zleca czynności"
          value={permissions.canTaskAssign}
          onChange={() => {
            setPermissions({
              ...permissions,
              canTaskAssign: !permissions.canTaskAssign,
            });
          }}
        />
      </div>

      <div className={styles.buttons}>
        <HollowButton onClick={onCancel} text="Anuluj" />
        <Button onClick={handleCreateRole} text="Utwórz" />
      </div>
    </div>
  );
};
