import { useMutation, useQuery } from 'react-query';
import { axiosInstance, axiosPluginInstance } from './axios';
import { ItemKind } from 'dto/IKindItems';

const queryKeys = {
  getVersion: 'pluginService.getVersion',
  actualVersion: 'pluginService.actualVersion',
};

const actualVersion = async () => {
  const r = await axiosInstance.get('/plugin/version');

  return r.data ?? [];
};

const useActualVersion = () => {
  return useQuery(queryKeys.actualVersion, () => actualVersion());
};

export interface IVersion {
  Platform: string;
  Version: string;
}

const getVersion = async (): Promise<IVersion> => {
  const response = await axiosPluginInstance.get('/api/version');
  return response.data ?? [];
};

const useGetVersion = () => {
  return useQuery(queryKeys.getVersion, () => getVersion());
};

export interface IOpenEditor {
  Id: string;
  Kind: ItemKind;
  VersionId?: string;
}

const openEditor = async (payload: IOpenEditor) => {
  return await axiosPluginInstance.post('/api/openEditor', payload);
};

const useOpenEditor = () => {
  return useMutation(openEditor);
};

export interface IPluginCreateConnection {
  AppId: string;
  Version: string;
}

const createConnection = async (payload: IPluginCreateConnection) => {
  return await axiosInstance.post('/Plugin/Connect', payload);
};

const useCreateConnection = () => {
  return useMutation(createConnection);
};

export interface IPluginSetConnection {
  PrivateKey: string;
  PublicKey: string;
  Token: string;
  Url: string;
}

const setConnection = async (payload: IPluginSetConnection) => {
  return await axiosPluginInstance.post('/api/connect', payload);
};

const useSetConnection = () => {
  return useMutation(setConnection);
};

export interface IPluginCheckPreview {
  AttachmentId: string;
  DocumentId: string;
  FileName: string;
  Kind: number;
}

const checkPreview = async (payload: IPluginCheckPreview) => {
  return await axiosPluginInstance.post('/api/checkPreview', payload);
};

const useCheckPreview = () => {
  return useMutation(checkPreview);
};

export interface IPluginPreview {
  AttachmentId?: string;
  Content: string;
  ContentType?: string | null;
  DocumentId?: string;
  FileName: string;
  Kind?: number;
}

const preview = async (payload: IPluginPreview) => {
  return await axiosPluginInstance.post('/api/preview', payload);
};

const usePreview = () => {
  return useMutation(preview);
};

export interface IVerifySignature {
  FileName: string;
  Content: string;
  ContentType: string;
  Xades?: string;
}

const verifySignature = (payload: IVerifySignature) => {
  return axiosPluginInstance.post('/api/verify', payload);
};

const useVerifySignature = () => {
  return useMutation(verifySignature);
};

const getCertificates = () => {
  return axiosPluginInstance.get('/api/certificates');
};

const useGetCerificates = () => {
  return useQuery('certs', () => getCertificates());
};

export interface ISign {
  CertificateSerialNumber: string;
  Content: string;
  ContentType: string;
  FileName: string;
  GenerateReport: boolean;
}

const signDocument = (payload: ISign) => {
  return axiosPluginInstance.post('/api/sign', payload);
};

const useSignDocument = () => {
  return useMutation(signDocument);
};

export default {
  useGetVersion,
  useOpenEditor,
  useCreateConnection,
  useSetConnection,
  useCheckPreview,
  usePreview,
  useVerifySignature,
  useGetCerificates,
  useSignDocument,
  useActualVersion,
};
