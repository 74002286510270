import { ICaseSimple } from "dto/Cases/ICaseSimple";
import { IClient } from "dto/IClient";
import { createContext } from "react";

type IClientsContext = {
    clients: IClient[];
    setClients: (clients: IClient[]) => void;
}

const ClientsContext = createContext<IClientsContext>({
    clients: [],
    setClients: () => {}
})

export default ClientsContext;