export const getListName = (id: string | number, isDesk: boolean, isWork: boolean) => {
  
  const isActions = window.location.pathname.search('actions') !== -1
  const isCases = window.location.pathname.search('cases') !== -1
  const isDesk_url = window.location.pathname.search('desk') !== -1
  const isClients = window.location.pathname.search('clients') !== -1;
  const isTemplates = window.location.pathname.search('templates') !== -1;
  const isPisma = window.location.pathname.search('pisma') !== -1;
  
  if(isPisma) {
    return 'Pisma'
  }

  if(isCases) {
    return 'Teczki'
  }
  
  if(isActions) {
    return 'Czynności'
  }

  if (isDesk || isDesk_url) {
    return 'Biurko';
  }

  if(isWork) {
    return 'Robocze'
  }

  if(isClients) {
    return 'Podmioty'
  }

  if(isTemplates) {
    return 'Szablony'
  }

  

  switch (id) {
    case 0:
      return '';
    case 1:
      return 'Przychodzące';
    case 2:
      return 'Wychodzace';
    case 10:
      return 'Wychodzące';
    default:
      return 'Przychodzące';
  }
};
