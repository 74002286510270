import PanelsLayout from 'components/PanelsLayout';
import styles from './styles.module.scss';
import editorService from 'api/editorService';
import TemplateCard from 'components/TemplateCard';
import { ITemplate } from 'dto/ITemplate';
import { useNavigate } from 'react-router-dom';
import Template from 'components/Template';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

const TemplatesModule = () => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');

  const [debouncedText] = useDebounce(searchText, 300);

  const { data: templatesData } = editorService.useGetTemplate(
    debouncedText,
    true,
    true,
    `temp.${debouncedText}`
  );

  const handleChoose = (template: ITemplate) => {
    navigate(`/templates/${template.Id}/${template.Kind}`);
  };

  const handleChangeSearchBar = (text: string) => setSearchText(text);

  const leftPanel = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.packageList}>
          {templatesData?.map((template) => (
            <TemplateCard onClick={handleChoose} template={template} />
          ))}
        </div>
      </div>
    );
  };

  const rightPanel = () => {
    return <Template />;
  };

  return (
    <PanelsLayout
      leftClassName={styles.changeSize}
      onChangeSearch={handleChangeSearchBar}
      leftPanel={leftPanel()}
      rightPanel={rightPanel()}
      resize
    />
  );
};

export default TemplatesModule;
