import workspaceService from 'api/workspaceService';
import CreateWorkspace from 'components/CreateWorkspace';
import WorkspaceMembers from 'components/WorkspaceMembers';
import WorkspaceRoles from 'components/WorkspaceRoles';
import WorkspaceStructure from 'components/WorkspaceStructure';
import { IWorkspace } from 'dto/IWorkspace';
import { useMemo, useState } from 'react';
import { getPrivilages } from 'utils/getPrivilages';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import { ETabs } from './components/WorkerCard/typings';
import styles from './styles.module.scss';

const WorkersTab = () => {
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');
  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);
  const workspaceRoles = useMemo(() => workspace?.Roles ?? [], [workspace]);
  const workspaceStructure = useMemo(
    () => workspace?.Structure ?? [],
    [workspace]
  );
  const [searchValue, setSearchValue] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const me = workspaceMembers.find((membership) => membership.IsMe === true);
  const meRole = workspaceRoles.find((role) => role.Id === me?.RoleId);
  const myPrivilages = getPrivilages(meRole);

  const [actualView, setActualView] = useState(ETabs.ALL);

  const handleCreateWorkspace = (workspaceId: string) => {
    setWorkspaceId(workspaceId);
    // refetch();
  };

  if (!workspaceId) {
    return <CreateWorkspace onCreate={handleCreateWorkspace} />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        {/* <div className={styles.menu}>
          <div
            onClick={() => setActualView(ETabs.ALL)}
            className={`${styles.box}  ${
              actualView === ETabs.ALL && styles.activeText
            }`}
          >
            Osoby
          </div>
          <div
            onClick={() => setActualView(ETabs.ROLES)}
            className={`${styles.box}  ${
              actualView === ETabs.ROLES && styles.activeText
            }`}
          >
            Role
          </div>
          <div
            onClick={() => setActualView(ETabs.STRUCTURE)}
            className={`${styles.box}  ${
              actualView === ETabs.STRUCTURE && styles.activeText
            }`}
          >
            Struktura
          </div>
        </div> */}
        {actualView === ETabs.ALL && (
          <WorkspaceMembers
            workspace={workspace}
            showAdd={showAdd}
            setShowAdd={setShowAdd}
            refetch={refetch}
            myPrivilages={myPrivilages}
            roles={workspaceRoles}
            members={workspaceMembers}
          />
        )}
        {actualView === ETabs.ROLES && (
          <WorkspaceRoles
            workspace={workspace}
            roles={workspaceRoles}
            refetch={refetch}
          />
        )}
        {actualView === ETabs.STRUCTURE && (
          <WorkspaceStructure
            myPrivilages={myPrivilages}
            workspaceMembers={workspaceMembers}
            workspaceStructures={workspaceStructure}
            refetch={refetch}
          />
        )}
        {/* {actualView === ETabs.ALL && (
          <>
            <div className={styles.search}>
              <Input
                label="Wyszukaj"
                value={searchValue}
                onChange={(t) => setSearchValue(t)}
              />
            </div>
            <div className={styles.addButton}>
              <AddButton
                className={styles.add}
                text="Dodaj pracownika"
                onClick={() => setShowAdd(true)}
              />
            </div>
            <div className={styles.list}>
              {workers
                ?.sort(
                  (a: IUserOffice, b: IUserOffice) =>
                    Number(b.IsMe) - Number(a.IsMe)
                )
                .map((worker: IUserOffice) => (
                  <WorkerCard
                    isAdmin={officesData?.data.Offices?.[0].Role === 1 ?? false}
                    refetch={refetch}
                    worker={worker}
                  />
                ))}
            </div>
          </>
        )} */}
      </div>
      {/* {showAdd && (
        <AddWorker
          toggle={() => setShowAdd(false)}
          refetch={refetch}
          officeId={officesData?.data.Offices[0].Id ?? ''}
        />
      )} */}
    </>
  );
};

export default WorkersTab;
