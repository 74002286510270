import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { ItemKind } from 'dto/IKindItems';

const queryKeys = {
  initParcel: () => 'parcelService.initParcel',
};

export interface Attachment {
  KeyId: string;
  KeyPk: string;
  Order: 0;
  Uri: string;
}

export interface ICreateParcel {
  Attachments: Attachment[];
  CaseId: string | null;
  ReceiveDate: string;
  ReceiverId: string | null;
  SenderId: string;
  Title: string | null;
  TagIds?: string[];
}

const createParcel = (payload: ICreateParcel) => {
  return axiosInstance.post('/incomingParcel', payload);
};

const useCreateParcel = () => {
  return useMutation(createParcel);
};

const initParcel = () => {
  return axiosInstance.get('incomingParcel/init');
};

const useInitParcel = () => {
  return useQuery(queryKeys.initParcel(), () => initParcel());
};

export interface IOutgoingParcelRelation {
  Id: string;
  Kind: ItemKind;
}

export interface IRequestCreateOutGoingParcel {
  PublicKey: string;
  PrivateKey: string;
  DocumentId: any;
  CaseId: string | null;
  Title: string;
  Content: string;
  RawContent: string | null;
  Attachments: any;
  Receivers: Receiver[];
  Cc: any;
  Reply?: {
    Id: string;
    Kind: string;
  },
  Forward?: {
    Id: string;
    Kind: string;
  }
}

export interface Receiver {
  Parcel: number;
  Id?: string | null;
  Type?: number;
  Email: any;
  EDeliveryAddress?: string;

}

const createOutGoingParcel = (payload: IRequestCreateOutGoingParcel) => {
  return axiosInstance.post('/outgoingParcel', payload);
};

const useCreateOutGoingParcel = () => {
  return useMutation(createOutGoingParcel);
};

const getOutcomingInit = () => {
  return axiosInstance.get('outgoingParcel/init');
};

const useGetOutcomingInit = () => {
  return useQuery('outcommingParcel', () => getOutcomingInit());
};

export default {
  useCreateParcel,
  useInitParcel,
  useCreateOutGoingParcel,
  useGetOutcomingInit,
};
