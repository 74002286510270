import styles from './styles.module.scss';
import { ESendStatus, FC, Props } from './typings';

import statusService from 'api/statusService';
import { ReactComponent as Attach3Icon } from 'assets/icons/attachments.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/case.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat_bubble_outline.svg';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';
import { ReactComponent as SharedIcon } from 'assets/icons/share.svg';
import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';
import ChatActionsList from 'components/ChatActionList';
import CommentsModal from 'components/CommentsModal';
import CreateActionModal from 'components/CreateActionModal';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import PackageAttachmentsModal from 'components/PackageAttachmentsModal';
import PackageCaseModal from 'components/PackageCaseModal';
import StatusBadge from 'components/StatusBadge';
import StatusModal from 'components/StatusModal';
import { IStatus } from 'dto/IStatus';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import MetricModal from 'components/MetricModal';
import PackageSharesModal from 'components/PackageSharesModal';
import PackagesContext from 'contexts/PackagesContext';
import deskService from 'api/deskService';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import HistoryModal from 'components/HistoryModal';
import SelectFlagNew from 'components/SelectFlagNew';

const IconsState: FC<Props> = ({
  msg,
  keys,
  customY,
  onPreview,
  onDownload,
  showCaseIcon = true,
  withoutCase = false,
  previewData,
}) => {
  const { status: linkStatus } = useParams();

  const isOutcoming = linkStatus === 'outcoming';

  const [showCase, setShowCase] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);
  const [showFlags, setShowFlags] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const [showMetric, setShowMetric] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showVersion, setShowVersion] = useState(false);
  const [showTask, setShowTask] = useState(false);

  useEffect(() => {
    if (!showCase) return;
    setShowAttachments(false);
    setShowActions(false);
    setShowCreateAction(false);
    setShowFlags(false);
    setShowAttach(false);
    setShowMetric(false);
    setShowShare(false);
    setShowVersion(false);

    setShowComments(false);
  }, [showCase]);

  useEffect(() => {
    if (!showAttachments) return;
    setShowCase(false);
    setShowActions(false);
    setShowCreateAction(false);
    setShowVersion(false);
    setShowFlags(false);
    setShowAttach(false);
    setShowMetric(false);
    setShowShare(false);

    setShowComments(false);
  });

  useEffect(() => {
    if (!showActions) return;
    setShowCase(false);
    setShowAttachments(false);
    setShowCreateAction(false);
    setShowFlags(false);
    setShowAttach(false);
    setShowVersion(false);
    setShowMetric(false);
    setShowShare(false);

    setShowComments(false);
  }, [showActions]);

  useEffect(() => {
    if (!showCreateAction) return;
    setShowCase(false);
    setShowAttachments(false);
    setShowActions(false);
    setShowFlags(false);
    setShowAttach(false);
    setShowMetric(false);
    setShowShare(false);
    setShowVersion(false);

    setShowComments(false);
  }, [showCreateAction]);

  useEffect(() => {
    if (!showFlags) return;
    setShowCase(false);
    setShowCreateAction(false);
    setShowAttachments(false);
    setShowActions(false);
    setShowVersion(false);
    setShowAttach(false);
    setShowMetric(false);
    setShowShare(false);

    setShowComments(false);
  }, [showFlags]);

  useEffect(() => {
    if (!showComments) return;
    setShowCase(false);
    setShowCreateAction(false);
    setShowAttachments(false);
    setShowActions(false);
    setShowVersion(false);
    setShowFlags(false);
    setShowAttach(false);
    setShowMetric(false);
    setShowShare(false);
  }, [showComments]);

  useEffect(() => {
    if (!showMetric) return;
    setShowCase(false);
    setShowCreateAction(false);
    setShowAttachments(false);
    setShowActions(false);
    setShowFlags(false);
    setShowAttach(false);
    setShowShare(false);
    setShowComments(false);
    setShowVersion(false);
  }, [showMetric]);

  useEffect(() => {
    if (!showShare) return;
    setShowCase(false);
    setShowCreateAction(false);
    setShowAttachments(false);
    setShowActions(false);
    setShowFlags(false);
    setShowAttach(false);
    setShowMetric(false);
    setShowVersion(false);
    setShowComments(false);
  }, [showShare]);
  const { packages, setPackages } = useContext(PackagesContext);
  const { mutate: setDesk } = deskService.useSetDesk();

  const removeFromDesk = () => {
    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === msg.Id);

    if (el) {
      el.IsDeskActive = !el.IsDeskActive;
      setPackages(newPacks);
    }

    setDesk(
      {
        Items: [{ Id: msg.Id, Kind: msg.Kind }],
        Show: false,
      },
      {
        onError: () => {},
      }
    );
  };

  const [status, setStatus] = useState<IStatus | null>(null);
  const { mutate: setPackageStatus } = statusService.useSetStatus();

  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const refe = useRef(null);

  useEffect(() => {
    const newStatus = statuses.find((s: IStatus) => s.Id === msg.StatusId);
    setStatus(newStatus ?? null);
  }, [msg.StatusId, statuses]);

  const [sendStatus, setSendStatus] = useState<ESendStatus | null>(null);

  useEffect(() => {
    if (msg.state) {
      const state = msg.state;

      if (
        state === ESendStatus.WAITING ||
        state === ESendStatus.PROCESSING ||
        state === ESendStatus.ERROR
      ) {
        setSendStatus(ESendStatus.PROCESSING);
        return;
      }

      if (state === ESendStatus.ERROR_NOT_RETRAYABLE) {
        setSendStatus(ESendStatus.ERROR_NOT_RETRAYABLE);
        return;
      }

      setSendStatus(ESendStatus.SENT);
      return;
    }

    const receivers = msg.Receivers;

    if (!receivers) return;

    const waiting = receivers.filter(
      (r) =>
        r.SendStatus === ESendStatus.WAITING ||
        r.SendStatus === ESendStatus.PROCESSING ||
        r.SendStatus === ESendStatus.ERROR
    );
    const error = receivers.filter(
      (r) => r.SendStatus === ESendStatus.ERROR_NOT_RETRAYABLE
    );

    if (!!error.length) {
      setSendStatus(ESendStatus.ERROR_NOT_RETRAYABLE);
      return;
    }

    if (!!waiting.length) {
      setSendStatus(ESendStatus.PROCESSING);
      return;
    }

    setSendStatus(ESendStatus.SENT);
  }, [msg, msg.state]);

  const getStatus = () => {
    if (sendStatus === ESendStatus.ERROR_NOT_RETRAYABLE) {
      return 'Błąd wysyłki';
    }
    if (sendStatus === ESendStatus.SENT) {
      return 'Wysłana';
    }
    if (sendStatus === ESendStatus.PROCESSING) {
      return 'Oczekująca';
    }
  };

  const getColor = () => {
    if (sendStatus === ESendStatus.ERROR_NOT_RETRAYABLE) {
      return 3;
    }
    if (sendStatus === ESendStatus.SENT) {
      return 6;
    }
    if (sendStatus === ESendStatus.PROCESSING) {
      return 8;
    }
  };

  const handleChangeFlag = (status: IStatus) => {
    setPackageStatus({
      OwnerId: msg.Id,
      OwnerKind: msg.Kind,
      StatusId: status.Id,
    });

    let newPacks = [...packages];

    let el = newPacks.find((c) => c.Id === msg.Id && c.Kind === msg.Kind);

    if (el) {
      el.StatusId = status.Id;
    }

    setPackages(newPacks);
  };

  const isPisma =
    window.location.href.search('pisma') !== -1 ||
    window.location.href.search('letters') !== -1;

  return (
    <div ref={refe} className={styles.wrapper}>
      {isPisma && (
        <HistoryIcon
          onClick={() => setShowVersion(true)}
          className={styles.icon}
        />
      )}
      <InfoIcon
        onClick={() => setShowMetric(!showMetric)}
        className={styles.icon}
      />
      {msg.HasTasks && (
        <CalendarIcon
          onClick={() => {
            setShowTask(!showTask);
          }}
          className={styles.icon}
        />
      )}
      {msg?.CommentsCount > 0 && (
        <ChatIcon
          className={styles.icon}
          onClick={(e) => {
            setShowFlags(false);
            setShowActions(false);
            setShowCase(false);
            setShowAttachments(false);
            setShowShare(false);
            setShowComments(!showComments);
            e.stopPropagation();
          }}
          title="Komentarze"
        />
      )}

      {msg.IsDeskActive && (
        <DeskIcon onClick={removeFromDesk} className={styles.icon} />
      )}
      {msg?.Case?.Id && showCaseIcon && !withoutCase && (
        <CaseIcon
          title="Teczka"
          onClick={(e) => {
            setShowActions(false);
            setShowAttachments(false);
            setShowComments(false);
            setShowCase(!showCase);
            setShowFlags(false);
            setShowShare(false);
            e.stopPropagation();
          }}
          className={classNames(
            styles.icon,
            msg?.SearchHit?.InCase ? styles.searchHit : ''
          )}
        />
      )}
      {!!msg?.Attachments?.length && (
        <Attach3Icon
          title="Pokaż załączniki"
          onClick={(e) => {
            setShowActions(false);
            setShowCase(false);
            setShowFlags(false);
            setShowComments(false);
            setShowShare(false);
            setShowAttachments(!showAttachments);
            e.stopPropagation();
            return true;
          }}
          className={classNames(
            styles.icon,
            msg?.SearchHit?.InAttachments ? styles.searchHit : ''
          )}
        />
      )}
      {status && !isOutcoming && (
        <div
          onClick={() => {
            setShowFlags(true);
            setShowActions(false);
            setShowCase(false);
            setShowComments(false);
            setShowAttachments(false);
            setShowShare(false);
          }}
          className={styles.icon}
        >
          <StatusBadge onRemove={() => {}} status={status} />
        </div>
      )}

      {sendStatus && (isOutcoming || msg.Kind === 4096) && (
        <div className={styles.icon}>
          <StatusBadge
            onRemove={() => {}}
            status={{
              Id: -1,
              Name: getStatus() ?? '',
              Color: getColor() ?? 8,
              Type: 1,
            }}
          />
        </div>
      )}
      {msg.IsShared && (
        <SharedIcon
          onClick={() => {
            setShowFlags(false);
            setShowActions(false);
            setShowCase(false);
            setShowComments(false);
            setShowAttachments(false);
            setShowShare(true);
          }}
          className={styles.icon}
        />
      )}

      {showTask && (
        <ModalLayout customY={customY ?? 0} refWrapper={refe}>
          <ChatActionsList
            changeView={() => {
              setShowActions(false);
              setShowCreateAction(true);
            }}
            toggle={() => {
              setShowTask(false);
            }}
            x={0}
            y={0}
            pack={msg}
          />
        </ModalLayout>
      )}

      {!!msg?.Case?.Id && showCase && (
        <ModalLayout
          toggle={() => setShowCase(false)}
          customY={customY ?? 0}
          refWrapper={refe}
        >
          <PackageCaseModal
            toggle={setShowCase}
            CaseId={msg.Case.Id}
            Package={msg}
          />
        </ModalLayout>
      )}
      {showAttachments && (
        <ModalLayout
          toggle={() => setShowAttachments(false)}
          customY={customY ?? 0}
          refWrapper={refe}
        >
          <PackageAttachmentsModal
            keys={keys}
            toggle={setShowAttachments}
            pack={msg}
            previewData={previewData}
            onDownload={onDownload}
            onPreview={onPreview}
          />
        </ModalLayout>
      )}

      {refe.current && showComments && (
        <ModalLayout
          className={styles.overflow}
          customY={customY ?? 0}
          refWrapper={refe}
        >
          <CommentsModal toggle={() => setShowComments(false)} pack={msg} />
        </ModalLayout>
      )}
      {showFlags && (
        <ModalLayout
          toggle={() => setShowFlags(false)}
          customX={0}
          customY={-30}
          refWrapper={refe}
        >
          {/* <StatusModal
            toggle={setShowFlags}
            item={msg}
            selectedStatus={status}
          /> */}
          <SelectFlagNew
            onClose={() => {
              setShowFlags(false);
            }}
            initOpen
            disableControl
            onChoose={(flag) => {
              handleChangeFlag(flag!);
            }}
            selectedStatus={null}
          />
        </ModalLayout>
      )}
      {showMetric && (
        <ModalLayout
          toggle={() => setShowMetric(false)}
          customX={0}
          refWrapper={refe}
          dock={DockPosition.BottomRight}
        >
          <MetricModal toggle={setShowMetric} pack={msg} />
        </ModalLayout>
      )}
      {showVersion && (
        <ModalLayout
          // toggle={() => setShowVersion(false)}
          customX={0}
          customY={-30}
          refWrapper={refe}
        >
          <HistoryModal pack={msg} toggle={() => setShowVersion(false)} />
        </ModalLayout>
      )}
      {showShare && (
        <ModalLayout
          toggle={() => setShowShare(false)}
          customX={0}
          customY={-30}
          refWrapper={refe}
        >
          <PackageSharesModal toggle={() => setShowShare(false)} item={msg} />
        </ModalLayout>
      )}
    </div>
  );
};

export default IconsState;
