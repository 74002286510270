import { ItemKind } from 'dto/IKindItems';
import { TagType } from 'dto/ITag';
import { ListType } from 'dto/ListType';

export const getTagType = (kind: ItemKind) => {
  switch (kind) {
    case ItemKind.Task:
      return TagType.Tasks;
    case ItemKind.Participant:
      return TagType.Participants;
    case ItemKind.Cases:
      return TagType.Cases;
    default:
      return TagType.Incoming;
  }
};

export const getListType = (kind: ItemKind) => {
  switch (kind) {
    case ItemKind.Task:
      return ListType.Tasks;
    case ItemKind.Participant:
      return ListType.Participants;
    case ItemKind.Cases:
      return ListType.Cases;
    case ItemKind.EditorDocument:
      return ListType.EditorDocuments;
    default:
      return ListType.Incoming;
  }
};
