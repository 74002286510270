import casesService from 'api/casesService';
import fileService, { ICaseGetFile } from 'api/fileService';
import pluginService from 'api/pluginService';
import PDFViewer from 'components/PDFViever';
import KeysContext from 'contexts/KeysContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { blobToBase64 } from 'utils/blobToBase64';
import { getBlob } from 'utils/getBlob';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { axiosInstance } from 'api/axios';
import Loading from 'components/Loading';
import ErrorScreen from 'components/ErrorScreen';
import { decryptFile } from 'utils/decryptFile';

const PreviewLetter: FC<Props> = ({}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const navigate = useNavigate();

  const { caseId, kind, packageId, attachmentId, fileName, encryptionKey } =
    useParams();

  const { mutate: isPreview } = pluginService.useCheckPreview();
  const {
    mutate: tryPreview,
    isLoading: previewLoading,
    isError: previewError,
  } = pluginService.usePreview();

  const {
    mutate: getFile,
    isLoading: isFileLoading,
    isError: fileError,
  } = fileService.useGetFiltersForCases();

  const {
    data: caseData,
    isSuccess: caseDataSuccess,
    isError: caseError,
    isLoading: caseLoading,
  } = casesService.useGetCase(caseId!);

  const Case = useMemo(() => caseData ?? [], [caseData]);

  const [blob, setBlob] = useState<Blob | null>(null);

  const isError = caseError || previewError || fileError;

  const isLoading = caseLoading || isFileLoading || previewLoading;

  const checkPreview = async () => {
    return new Promise((resolve) => {
      isPreview(
        {
          AttachmentId: packageId!,
          DocumentId: packageId!,
          FileName: fileName!,
          Kind: Number(kind!),
        },
        {
          onSuccess: () => resolve(true),
          onError: () => resolve(false),
        }
      );
    });
  };

  const get = async (): Promise<ICaseGetFile | null> => {
    return new Promise((resolve) => {
      getFile(
        {
          id: attachmentId!,
          ownerKind: Number(kind!),
        },
        {
          onSuccess: (response) => resolve(response.data),
          onError: () => resolve(null),
        }
      );
    });
  };

  const { keys, setKeys } = useContext(KeysContext);

  const initializePreview = async () => {
    //@ts-ignore
    if (!Case?.Keys?.[0]) return;

    const file: ICaseGetFile | null = await get();
    if (!file) return;

    // const key = keys.find((key) => key?.Id === encryptionKey);
    const keyR = await axiosInstance.get(`/keys/object?id=${encryptionKey}`);
    const key = keyR.data;

    if (!key) {
      return;
    }

    const myKeys = await getKeys(userId);
    // const privateKey = await decryptPackageKeyByPrivateCaseKey(
    //   myKeys!,
    //   //@ts-ignore
    //   Case.Keys[0], // To fix for now can stay we need to find correct key
    //   key.PrivateKey
    // );

    const blob = await getBlob(file.Url);
    let b: string | null = null;
    try {
      const decryptedBlob = await decryptFile(
        userId,
        blob.data,
        key,
        //@ts-ignore
        Case.Keys[0]
      );
      b = (await blobToBase64(decryptedBlob)) as string;
    } catch (e) {
      setBlob(blob.data);
      return;
    }

    if (!b) {
      return;
    }

    tryPreview(
      {
        AttachmentId: packageId ?? attachmentId,
        Content: b.split(',')[1],
        ContentType: 'application/other',
        DocumentId: packageId ?? attachmentId,
        FileName: fileName!,
        Kind: Number(kind!),
      },
      {
        onSuccess: async (response) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setBlob(new Blob([buffer]));
        },
      }
    );
  };

  useEffect(() => {
    if (!Case) return;
    //@ts-ignore
    setKeys([...keys, Case.ObjectKeys, Case.Keys].flat());
  }, [Case]);

  useEffect(() => {
    if (!Case) return;

    initializePreview();
  }, [caseId, attachmentId, Case, keys]);

  return (
    <>
      {isError || isLoading ? (
        <div className={styles.count}>
          {isLoading && <Loading />}
          {isError && <ErrorScreen />}
        </div>
      ) : (
        <div className={styles.wrapper}>
          {blob && (
            <PDFViewer
              showSignButton
              onCloseButton={() => {
                navigate(`/cases/${caseId}/dossier`);
              }}
              file={blob}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PreviewLetter;
