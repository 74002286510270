// Rodzaj listy
// (filtry i statusy są przypisane do listy danego typu)
export enum ListType {
  None = 0,

  /// <summary>
  /// Przychodzące
  /// </summary>
  Incoming = 1,
  /// <summary>
  /// Wychodzące
  /// </summary>
  Outgoing = 2,
  /// <summary>
  /// Sprawy
  /// </summary>
  Cases = 3,
  /// <summary>
  /// Czynności
  /// </summary>
  Tasks = 4,

  /// <summary>
  /// Robocze
  /// </summary>
  Drafts = 5,

  /// <summary>
  /// Podmioty
  /// </summary>
  Participants = 6,

  /// <summary>
  /// Pisma
  /// </summary>
  EditorDocuments = 7,

  /// <summary>
  /// Szablony
  /// </summary>
  Templates = 8,

  /// Dysk
  Drive = 9,

  /// Udostępnienia
  Shares = 10,
}
