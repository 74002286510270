import messagesService from 'api/messagesService';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useContext } from 'react';
import PackagesContext from 'contexts/PackagesContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';

import moment from 'moment';

const PackageSharesModal: FC<Props> = ({ toggle, item }) => {
  const { data: sharesData, refetch } = messagesService.useGetShare(
    item.Id,
    item.Kind
  );
  const { mutate: remove } = messagesService.useRemoveShare();
  const { packages, setPackages } = useContext(PackagesContext);

  const handleDelete = (id: string) => {
    remove(
      {
        Id: id,
        Kind: item.Kind,
      },
      {
        onSuccess: () => {
          let newPacks = [...packages];
          let newPack = newPacks.find((p) => p.Id === item.Id);
          if (!newPack) {
            return;
          }

          newPack.IsShared = false;
          setPackages(newPacks);
          // toggle();
          refetch();
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          Udostępnienia
          <CloseIcon onClick={toggle} style={{ cursor: 'pointer' }} />
        </div>

        <div className={styles.list}>
          {sharesData?.data.map((m: any) => (
            <div className={styles.case}>
              <div className={styles.metaData}>
                <div className={styles.signature}>{m.To}</div>
                <div className={styles.client}>
                  {moment(m.Date).format('DD.MM.YY')}
                </div>
              </div>
              <div onClick={() => handleDelete(m.Id)} className={styles.delete}>
                <BinIcon />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackageSharesModal;
