import { ReactComponent as GoalImg } from 'assets/icons/goal.svg';
import { ReactComponent as NotaIcon } from 'assets/icons/nota.svg';
import EDelivery from 'components/Activation/EDelivery';
import Identity from 'components/Activation/Identity';
import RecoveryPackage from 'components/Activation/RecoveryPackage';
import TwoFactorAuthentication from 'components/Activation/TwoFactorAuthentication';
import HollowButton from 'components/HollowButton';
import styles from './styles.module.scss';
import Button from 'components/Button';
import workspaceService from 'api/workspaceService';
import { useNavigate, useParams } from 'react-router-dom';
import { setWorkspaceId } from 'utils/setWorkspaceId';
import { useEffect, useState } from 'react';
import Input from 'components/Input';
import { useAuth } from 'react-oidc-context';
import { IUserCreated } from 'dto/IUserCreated';
import { ReactComponent as InfoIcon } from 'assets/icons/error.svg';
import { ReactComponent as GmailIcon } from 'assets/icons/gmail.svg';
import { ReactComponent as OutlookIcon } from 'assets/icons/outlook.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/apple.svg';
import { ReactComponent as AEmail } from 'assets/icons/aemail.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowBack.svg';
import {
  User,
  WebStorageStateStore,
  UserManager,
  IdTokenClaims,
} from 'oidc-client-ts';
import Loading from 'components/Loading';
import { Tooltip } from 'react-tooltip';
import AuthLayout from 'components/AuthLayout';
import { ESteps } from 'components/Stepper';
import { ENotificationType, notification } from 'utils/notification';

const steps = [
  {
    type: ESteps.INFORMATION,
    title: 'Twoje dane',
    text: 'Wpisz email i hasło bądź wybierz metodę logowania',
  },
  {
    type: ESteps.KEY_GENERATION,
    title: 'Klucz przywracania',
    text: 'Generuj Swój klucz przywracania',
  },
];

const InvitationModule = ({ notLogged }: { notLogged?: boolean }) => {
  const [step, setStep] = useState(0);
  const { id, type } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const userManager = new UserManager(auth.settings);
  const {
    data: invitationData,
    isError,
    isLoading,
    error,
  } = workspaceService.useGetInvitation(id!);

  const { mutate: approve } = workspaceService.useApproveInvitation();
  const { mutate: reject } = workspaceService.useRejectInvitation();
  const { mutate: switchWorkspace } = workspaceService.useWorkspaceSwitch();
  const { mutate: register } = workspaceService.useRegisterInWorkspace();

  const setUser = (accessToken: string, refreshToken: string) => {
    userManager.storeUser(
      new User({
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: 'Bearer',
        profile: { sub: '', iss: '', aud: '', exp: 0, iat: 0 },
      })
    );
  };

  const handleAccept = () => {
    console.log('Type ', type);
    if (notLogged) {
      if (!type) {
        auth.signinRedirect({
          state: { invitation: id },
        });
      }
      setStep(1);
      return;
    }

    approve(
      {
        Key: id,
      },
      {
        onSuccess: () => {
          if (invitationData?.data?.Case) {
            navigate('/');
            return;
          }

          switchWorkspace(
            {
              Id: invitationData?.data?.Workspace?.Id,
            },
            {
              onSuccess: () => {
                navigate('/');
                setWorkspaceId(invitationData?.data?.Workspace?.Id);
              },
            }
          );
        },
      }
    );
  };

  useEffect(() => {
    if (invitationData?.data?.Id) {
      handleAccept();
    }
  }, [invitationData]);

  const handleCreate = () => {
    register(
      {
        AcceptNotaRules: true,
        AcceptRules: true,
        Email: invitationData?.data?.Email,
        FirstName: invitationData?.data?.Name.split(' ')[0],
        InvitationId: id!,
        LastName: invitationData?.data?.Name.split(' ')[1] ?? '',
        Password: password,
        RepeatPassword: rePassword,
      },
      {
        onSuccess: (resp) => {
          var r = resp.data as IUserCreated;

          setUser(r.AccessToken, r.RefreshToken);
          auth.signinSilent();
          navigate('/');
        },
        onError: (e: any) => {
          const errors = e.response.data.errors;
          let mergedErrors = '';
          for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
              mergedErrors += `${errors[key].join(',')} \n`;
            }
          }

          notification({
            type: ENotificationType.ERROR,
            title: 'Bład',
            text: mergedErrors,
          });
        },
      }
    );
  };

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [input, setInput] = useState(false);

  const [typ, setTyp] = useState(0);

  useEffect(() => {
    if (!type) {
      auth.signinRedirect({
        state: { invitation: id },
      });
      return;
    }
  }, [type]);

  useEffect(() => {
    if (!rePassword.length) {
      setInput(false);
      return;
    }

    if (password === rePassword) {
      setInput(false);
      return;
    }

    setInput(true);
  }, [password, rePassword]);

  const checkCan = () => {
    if (!password.length || !rePassword.length) {
      return true;
    }

    if (password !== rePassword) {
      return true;
    }

    return false;
  };

  if (isLoading) {
    return (
      <AuthLayout steps={steps} actualStep={0}>
        <>
          <Loading />
        </>
      </AuthLayout>
    );
  }

  if (isError) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.leftBar}>
          <div className={styles.logo}>
            <NotaIcon />
          </div>
          <div className={styles.text}>
            Poznaj nowy wymiar komunikacji:
            <br />
            pełną kontrolę, bezpieczeństwo i wygodę
          </div>
          <div className={styles.img}>
            <GoalImg></GoalImg>
          </div>
          <div className={styles.logout}>
            <HollowButton text="Wyloguj" onClick={() => {}}></HollowButton>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            {/* @ts-ignore */}
            {error?.response?.data?.errorCodes?.[0]?.message ??
              'Zaproszenie nie odnalezione'}
          </div>
        </div>
      </div>
    );
  }

  const ChooseMethod = () => {
    return (
      <>
        <div className={styles.externalLogin}>
          <div
            onClick={() => {
              setTyp(1);
            }}
            className={styles.external}
          >
            <AEmail></AEmail> Login i hasło
          </div>
          <a
            className={styles.external}
            onClick={() =>
              auth.signinRedirect({
                acr_values: 'idp:Google',
                state: { invitation: id },
              })
            }
          >
            <GmailIcon></GmailIcon> Zaloguj z Google
          </a>
          <a
            className={styles.external}
            onClick={() =>
              auth.signinRedirect({
                acr_values: 'idp:Microsoft',
                state: { invitation: id },
              })
            }
          >
            <OutlookIcon></OutlookIcon> Zaloguj z Microsoft
          </a>
          <a
            className={styles.external}
            onClick={() =>
              auth.signinRedirect({
                acr_values: 'idp:Apple',
                state: { invitation: id },
              })
            }
          >
            <AppleIcon></AppleIcon> Zaloguj z Apple
          </a>
        </div>
      </>
    );
  };

  if (notLogged && step === 1) {
    return (
      <div className={styles.wrapper}>
        <AuthLayout steps={steps} actualStep={0}>
          <>
            <div className={styles.title}>Utwórz konto</div>
            <div className={styles.side}>I zacznij korzystać z Notaup</div>
            {typ === 0 && <ChooseMethod />}
            {typ === 1 && (
              <>
                <div className={styles.inputs}>
                  <div
                    onClick={() => {
                      setTyp(0);
                    }}
                    className={styles.back}
                  >
                    <ArrowIcon /> Wróc
                  </div>
                  <Input
                    className={styles.input}
                    disabledTags
                    disabled
                    label="Imię i nazwisko"
                    value={invitationData?.data?.Name}
                    onChange={(v) => setName(v)}
                  />
                  <Input
                    className={styles.input}
                    label="E-Mail"
                    disabled
                    disabledTags
                    value={invitationData?.data?.Email ?? ''}
                    onChange={() => {}}
                  />
                  <div className={styles.box}>
                    <Input
                      className={styles.input}
                      label="Hasło"
                      type="password"
                      disabledTags
                      value={password}
                      onChange={(v) => setPassword(v)}
                    />
                    {input && (
                      <InfoIcon
                        data-tooltip-id={`bar-1`}
                        data-tooltip-content={'Podane hasła nie są takie same'}
                        className={styles.error}
                      />
                    )}
                  </div>
                  <div className={styles.box}>
                    <Input
                      className={styles.input}
                      label="Powtórz hasło"
                      disabledTags
                      type="password"
                      value={rePassword}
                      onChange={(v) => setRePassword(v)}
                    />
                    {input && (
                      <InfoIcon
                        data-tooltip-id={`bar-1`}
                        data-tooltip-content={'Podane hasła nie są takie same'}
                        className={styles.error}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.button}>
                  <Button
                    className={styles.b}
                    text="Dalej"
                    disabled={checkCan()}
                    onClick={() => handleCreate()}
                  />
                </div>
              </>
            )}
          </>
        </AuthLayout>
        {/* <div className={styles.leftBar}>
          <NewLogo className={styles.logo} />
        </div>
       
        </div> */}
        <Tooltip
          place="bottom"
          // style={{
          //   background: isOut ? '#BA1A1A' : '#5d49c7',
          // }}
          id={`bar-1`}
        />
      </div>
    );
  }

  return <></>;
};

export default InvitationModule;
