import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as HashIcon } from 'assets/icons/down.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';
import NewInput from 'components/NewInput';
import { useOnClickOutside } from 'usehooks-ts';
import { getWorkspaceId } from 'utils/getWorkspaceId';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const ChooseMethods: FC<Props> = ({
  onChoose,
  label,
  type = -1,
  initOpen = false,
  onClose,
  className = '',
  types: typed,
}) => {
  const currentWorkspaceId = getWorkspaceId();
  const [active, setActive] = useState(false);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }

    if (onClose && !label) {
      onClose();
    }
  });

  useEffect(() => {
    if (!initOpen) return;
    setActive(true);
  }, [initOpen]);

  return (
    <div ref={outsideRef} className={`${styles.wrapper}`}>
      <div
        onClick={() => setActive(!active)}
        className={`${styles.card} ${className}`}
      >
        <div className={styles.left}>
          <HashIcon className={styles.icon} />{' '}
          {label ? label : 'Rodzaj przesyłki'}{' '}
        </div>

        <div className={styles.right}>
          {!onClose && (
            <FontAwesomeIcon icon={active ? faCaretDown : faCaretRight} />
          )}
        </div>
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.list}>
            {typed.map((type) => (
              <div
                onClick={() => {
                  onChoose(type);
                  setActive(false);
                }}
                className={styles.box}
                key={type.id}
              >
                {<type.image />}
                {type.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseMethods;
