import { useGoogleLogin } from '@react-oauth/google';
import { HubCallbacksNames, ICalendarSynchronized } from 'api/hub';
import settingService from 'api/settingsService';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/filters/email.svg';
import { ReactComponent as IMAPIcon } from 'assets/icons/filters/imap.svg';
import { ReactComponent as PIIcon } from 'assets/icons/filters/pi.svg';
import { ReactComponent as PolandIcon } from 'assets/icons/filters/poland.svg';
import OauthPopup from 'components/OauthPopup';
import Spinner from 'components/Spinner';
import { ICourtPortalSettings } from 'dto/Settings/ICourtPortalSettings';
import { IExternalIntegration } from 'dto/Settings/IIntegrationsResponse';
import { IntegrationProvider } from 'dto/Settings/IntegrationProvider';
import { IntegrationType } from 'dto/Settings/IntegrationType';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { getErrorMessage } from 'utils/getErrorMessage';
import { ENotificationType, notification } from 'utils/notification';
import IntegrationMenu from '../IntegrationMenu';
import { SignalRContext } from './../../../App';
import styles from './styles.module.scss';
import { EIntegrationsViews, FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import ConfirmModal from 'components/ConfirmModal';
import Button from 'components/Button';
import { ReactComponent as MicrosoftIcon } from 'assets/icons/filters/Microsoft.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/filters/Google.svg';
import moment from 'moment';
const IntegrationsTab: FC<Props> = ({ showTabBar }) => {
  const [wsMessages, setWsMessage] = useState([]);

  const auth = useAuth();
  const isGuest = auth?.user?.profile?.role === 'guest';
  SignalRContext.useSignalREffect(
    HubCallbacksNames.CalendarSynchronized,
    (message: ICalendarSynchronized) => {
      setCalendarState({ ...calendarState, lastSync: message.syncDate });
    },
    []
  );

  const { mutate: updateSettings } =
    settingService.useUpdateCourtPortalSettings();

  const {
    data: integrationsData,
    isSuccess: isLoaded,
    refetch: refetchI,
  } = settingService.useGetIntegrationSettings();

  const { mutate: configureIntegration } =
    settingService.useConfigureIntegration();

  const { mutate: removeIntegration } = settingService.useRemoveIntegration();

  const { mutate: getMsRedirect } = settingService.useGetMsredirect();

  const [activeIntegrations, setActiveIntegrations] =
    useState<Array<IExternalIntegration> | null>(null);

  const [showMenu, setShowMenu] = useState(false);

  const [scopes, setScopes] = useState<Array<string> | null>(null);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationType | null>(null);
  const [toDelete, setToDelete] = useState<{
    id: number;
    show: boolean;
    isCourt?: boolean;
    isMain?: boolean;
    callback?: () => void | undefined;
  }>({
    id: 0,
    show: false,
    isCourt: false,
    callback: () => {},
  });

  const { refetch } = settingService.useGetCourtPortalSettings();

  const [confIMAP, setConfIMAP] = useState(false);

  const [courtPortalState, setCourtPortalState] = useState({
    id: 0,
    isActive: false,
    lastSync: '',
  });

  const { data: settingsData, refetch: refetchportals } =
    settingService.useGetCourtPortalSettings();

  const syncPortals = useMemo(
    () =>
      (settingsData?.data as ICourtPortalSettings)?.Portals.filter(
        (p) => p.IsActive
      ) ?? [],
    [settingsData]
  );

  const [emailState, setEmailState] = useState({
    id: 0,
    microsoftActive: false,
    microsoftLastSync: '',
    gmailActive: false,
    gmailLastSync: '',
    name: '',
    imapActive: false,
    imapLastSync: '',
  });

  const [calendarState, setCalendarState] = useState({
    id: 0,
    lastSync: '',
    name: '',
    microsoftActive: false,
    gmailActive: false,
    appleActive: false,
  });

  const [editorState, setEditorState] = useState({
    id: 0,
    microsoftActive: false,
    microsoftLastSync: '',
    gmailActive: false,
    gmailLastSync: '',
  });

  const [fkState, setFkState] = useState({
    id: 0,
    name: '',
    wfirmaActive: false,
  });

  const [msRedirecUrl, setMsRedirecUrl] = useState('');

  const selectIntegration = (type: IntegrationType | null) => {
    setSelectedIntegration(type);
    showTabBar(!type);
  };

  const onInnerViewCanceled = () => {
    selectIntegration(null);
  };

  const onInnerViewSaved = () => {
    selectIntegration(null);
  };

  const handleDisconnect = (
    id: number,
    callback?: () => void,
    isCourt?: boolean,
    isMain?: boolean
  ) => {
    //@ts-ignore
    setToDelete({
      id: id,
      show: true,
      callback: callback,
      isCourt: isCourt ?? false,
      isMain: isMain ?? false,
    });
  };

  const handleDisconnectConfirmed = () => {
    removeIntegration(
      { Id: toDelete.id },
      {
        onSuccess(data, variables, context) {
          switch (selectedIntegration) {
            case IntegrationType.Email:
              setEmailState({
                ...emailState,
                id: 0,
                gmailActive: false,
                microsoftActive: false,
                imapActive: false,
              });
              break;
            case IntegrationType.Calendar:
              setCalendarState({
                ...calendarState,
                id: 0,
                gmailActive: false,
                microsoftActive: false,
              });
              break;
            case IntegrationType.Editor:
              setEditorState({
                ...editorState,
                id: 0,
                gmailActive: false,
                microsoftActive: false,
              });
              break;
            case IntegrationType.PublicPortal:
              setCourtPortalState({
                ...courtPortalState,
                id: 0,
                isActive: false,
              });
              break;
          }

          setCourtPortalState({
            ...courtPortalState,
            id: 0,
            isActive: false,
          });
          refetch();
          setSelectedIntegration(null);
        },
        onError(e: any) {
          notification({
            title: 'Nie udało się usunąć integracji',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  const [activeConfigType, setActiveConfigType] =
    useState<IntegrationType | null>(null);

  useEffect(() => {
    if (isLoaded) {
      let active: Array<IExternalIntegration> = integrationsData?.data.Active;
      setActiveIntegrations(active);

      var activeEmail = active?.find((a) => a.Type === IntegrationType.Email);

      if (activeEmail) {
        setEmailState({
          ...emailState,
          id: activeEmail.Id,
          microsoftActive:
            activeEmail.Provider === IntegrationProvider.Microsoft,
          microsoftLastSync:
            activeEmail.Provider === IntegrationProvider.Microsoft
              ? activeEmail.LastSync ?? ''
              : '',
          gmailActive: activeEmail.Provider === IntegrationProvider.Google,
          gmailLastSync:
            activeEmail.Provider === IntegrationProvider.Google
              ? activeEmail.LastSync ?? ''
              : '',
          name: activeEmail.Name,
          imapActive: activeEmail.Provider === IntegrationProvider.Imap,
          imapLastSync:
            activeEmail.Provider === IntegrationProvider.Imap
              ? activeEmail.LastSync ?? ''
              : '',
        });
      }

      var activeCalendar = active?.find(
        (a) => a.Type === IntegrationType.Calendar
      );

      if (activeCalendar) {
        setCalendarState({
          ...calendarState,
          id: activeCalendar.Id,
          name: activeCalendar.Name,
          lastSync: activeCalendar.LastSync ?? '',
          microsoftActive:
            activeCalendar.Provider === IntegrationProvider.Microsoft,
          gmailActive: activeCalendar.Provider === IntegrationProvider.Google,
          appleActive: activeCalendar.Provider === IntegrationProvider.Apple,
        });
      }

      var activeEditor = active?.find((a) => a.Type === IntegrationType.Editor);
      if (activeEditor) {
        setEditorState({
          ...editorState,
          id: activeEditor.Id,
          microsoftActive:
            activeEditor.Provider === IntegrationProvider.Microsoft,
          microsoftLastSync:
            activeEditor.Provider === IntegrationProvider.Microsoft
              ? activeEditor.LastSync ?? ''
              : '',
          gmailActive: activeEditor.Provider === IntegrationProvider.Google,
          gmailLastSync:
            activeEditor.Provider === IntegrationProvider.Google
              ? activeEditor.LastSync ?? ''
              : '',
        });
      }

      var activeCourtPortal = active?.find(
        (a) => a.Type === IntegrationType.PublicPortal
      );

      if (activeCourtPortal) {
        setCourtPortalState({
          id: activeCourtPortal.Id,
          isActive: true,
          lastSync: activeCourtPortal.LastSync ?? '',
        });
      } else {
        setCourtPortalState({ id: 0, isActive: false, lastSync: '' });
      }

      var activeFk = active?.find(
        (a) => a.Type === IntegrationType.InvoiceSystem
      );

      if (activeFk) {
        setFkState({
          id: activeFk.Id,
          wfirmaActive: true,
          name: activeFk.Name,
        });
      } else {
        setFkState({ id: 0, wfirmaActive: false, name: '' });
      }
    }
  }, [integrationsData]);

  const handleClick = (
    type: IntegrationType,
    provider: IntegrationProvider,
    isActive: boolean
  ) => {
    if (!isActive) {
      switch (provider) {
        case IntegrationProvider.Google: {
          initGoogle(type);
          break;
        }
        case IntegrationProvider.Microsoft: {
          initMicrosoft(type);
          break;
        }
        case IntegrationProvider.CourtPortal: {
          setSelectedIntegration(IntegrationType.PublicPortal);
          break;
        }
        case IntegrationProvider.Imap: {
          setConfIMAP(true);
          break;
        }
      }
    } else {
      switch (type) {
        case IntegrationType.Email:
          setToDelete({
            id: emailState.id,
            show: true,
            callback: () =>
              setEmailState({
                ...emailState,
                microsoftActive: false,
                gmailActive: false,
                imapActive: false,
              }),
          });
          return;
        case IntegrationType.Calendar:
          setToDelete({
            id: calendarState.id,
            show: true,
            callback: () =>
              setCalendarState({
                ...calendarState,
                appleActive: false,
                gmailActive: false,
              }),
          });
          return;
        case IntegrationType.Editor:
          setToDelete({
            id: editorState.id,
            show: true,
            callback: () => {
              setEditorState({
                ...editorState,
                gmailActive: false,
                microsoftActive: false,
              });
            },
          });
          return;
        default:
          setSelectedIntegration(type);
          return;
      }
    }
  };

  const loginGoogle = useGoogleLogin({
    scope: scopes?.join(' '),
    flow: 'auth-code',
    onSuccess: (codeResponse) => {
      if (codeResponse) {
        let missing = false;
        scopes?.forEach((s) => {
          if (codeResponse.scope.indexOf(s) < 0) {
            missing = true;
          }
        });

        if (missing) {
          notification({
            title: 'Niewystarczające uprawnienia',
            text: 'Nie udało się ustanowić integracji, spróbuj ponownie i zweryfikuj czy w kreatorze logowania nadałeś wymagane uprawnienia dla aplikacji zaznaczając uprawnienia o które wnioskuje aplikacja.',
            type: ENotificationType.ERROR,
          });
          return;
        }

        configureIntegration(
          {
            Provider: IntegrationProvider.Google,
            Type: activeConfigType!,
            Code: codeResponse.code,
            Scope: codeResponse.scope,
          },
          {
            onSuccess(data, variables, context) {
              var resp: IExternalIntegration = data.data;

              switch (activeConfigType) {
                case IntegrationType.Email:
                  setEmailState({
                    ...emailState,
                    id: resp.Id,
                    microsoftActive: false,
                    gmailActive: true,
                    gmailLastSync: '',
                  });
                  break;
                case IntegrationType.Calendar:
                  setCalendarState({
                    ...calendarState,
                    id: resp.Id,
                    microsoftActive: false,
                    appleActive: false,
                    gmailActive: true,
                    lastSync: '',
                  });
                  break;
                case IntegrationType.Editor:
                  setEditorState({
                    ...editorState,
                    id: resp.Id,
                    microsoftActive: false,
                    gmailActive: true,
                    gmailLastSync: '',
                  });
                  break;
              }
            },
            onError(e: any) {
              notification({
                title: 'Nie udało się skonfigurować',
                text: getErrorMessage(e.response.data),
                type: ENotificationType.ERROR,
              });
            },
          }
        );
      }
    },
  });

  const initGoogle = (type: IntegrationType) => {
    switch (type) {
      case IntegrationType.Email:
        setScopes([
          'https://mail.google.com/',
          'https://www.googleapis.com/auth/contacts.readonly',
        ]);
        break;
      case IntegrationType.Calendar:
        setScopes([
          'https://www.googleapis.com/auth/calendar',
          'https://www.googleapis.com/auth/tasks',
        ]);
        break;
      case IntegrationType.Editor:
        setScopes([
          'https://www.googleapis.com/auth/documents',
          'https://www.googleapis.com/auth/drive',
        ]);
        break;
    }

    setActiveConfigType(type);
    loginGoogle();
  };

  const initMicrosoft = (type: IntegrationType) => {
    setActiveConfigType(type);

    let newScope = ['user.read', 'calendars.readwrite'];

    if (type === IntegrationType.Editor) {
      newScope = ['user.read', 'files.readwrite.all'];
    } else if (type === IntegrationType.Email) {
      newScope = [
        'user.read',
        'mail.readwrite',
        'mail.send',
        'contacts.readwrite',
      ];
    }

    setScopes(newScope);

    getMsRedirect(
      { scopes: newScope!.join(', ') },
      {
        onSuccess(data, variables, context) {
          var url = data.data.Url;
          setMsRedirecUrl(url);
        },
      }
    );
  };

  const onCode = (code: any, params: any) => {
    configureIntegration(
      {
        Provider: IntegrationProvider.Microsoft,
        Type: activeConfigType!,
        Code: code,
        Scope: scopes!.join(', '),
      },
      {
        onSuccess(data, variables, context) {
          var resp: IExternalIntegration = data.data;

          switch (activeConfigType) {
            case IntegrationType.Email:
              setEmailState({
                ...emailState,
                id: resp.Id,
                microsoftActive: true,
                gmailActive: false,
                gmailLastSync: '',
              });
              break;
            case IntegrationType.Calendar:
              setCalendarState({
                ...calendarState,
                id: resp.Id,
                microsoftActive: true,
                appleActive: false,
                gmailActive: false,
                lastSync: '',
              });
              break;
            case IntegrationType.Editor:
              setEditorState({
                ...editorState,
                id: resp.Id,
                microsoftActive: true,
                gmailActive: false,
                gmailLastSync: '',
              });
              break;
          }
        },
        onError(e: any) {
          notification({
            title: 'Nie udało się skonfigurować',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };
  const [actualView, setActualView] = useState(EIntegrationsViews.COURT);
  const onClose = () => {};

  if (!integrationsData) {
    return <Spinner></Spinner>;
  }

  if (showMenu) {
    return (
      <IntegrationMenu
        refetchportals={refetchportals}
        toggle={() => setShowMenu(false)}
        integrationId={courtPortalState.id}
        onInnerViewCanceled={onInnerViewCanceled}
        onInnerViewSaved={onInnerViewSaved}
        handleDisconnect={handleDisconnect}
        emailState={emailState}
        courtPortalState={courtPortalState}
        setEmailState={setEmailState}
        refetchI={refetchI}
        handleClick={handleClick}
      />
    );
  }

  console.log(syncPortals);

  return (
    <>
      <OauthPopup
        width={400}
        height={600}
        title="Zaloguj się"
        url={msRedirecUrl}
        onClose={onClose}
        onCode={onCode}
      >
        <div></div>
      </OauthPopup>

      <div className={styles.wrapper}>
        <div className={styles.title}>Aktywne integracje</div>
        <div className={styles.hint}></div>

        <div className={styles.list}>
          {!!syncPortals.length && (
            <div className={styles.box}>
              <div className={styles.boxTitle}>
                <PIIcon /> PI Sądowe
              </div>
              <div className={styles.boxList}>
                {syncPortals.map((s) => (
                  <div key={s.Id} className={styles.integration}>
                    <div className={styles.s}>
                      <PolandIcon className={styles.icon} /> {s.Name}{' '}
                      <CloseIcon
                        onClick={() => {
                          handleDisconnect(s.Id, undefined, true, s.IsMain);
                        }}
                        className={styles.icon}
                      />
                    </div>
                    <div>
                      Synch:{' '}
                      {s.LastSync
                        ? moment(s.LastSync)
                            .format('DD.MM.YYYY HH:mm')
                            .toString()
                        : 'Oczekuje'}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {!!emailState.id && (
            <div className={styles.box}>
              <div className={styles.boxTitle}>
                <EmailIcon /> Email
              </div>
              <div className={styles.boxList}>
                <div className={styles.integration}>
                  <div className={styles.s}>
                    <IMAPIcon /> {emailState.name}{' '}
                    <CloseIcon
                      onClick={() => {
                        handleDisconnect(emailState.id);
                      }}
                      className={styles.icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {!!calendarState.id && (
            <div className={styles.box}>
              <div className={styles.boxTitle}>
                <CalendarIcon /> Kalendarz
              </div>
              <div className={styles.boxList}>
                <div className={styles.integration}>
                  <div className={styles.s}>
                    {calendarState.gmailActive && (
                      <GoogleIcon className={styles.icon} />
                    )}{' '}
                    {calendarState.microsoftActive && (
                      <MicrosoftIcon className={styles.icon} />
                    )}
                    {calendarState.name}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!!editorState.id && (
            <div className={styles.box}>
              <div className={styles.boxTitle}>
                <CalendarIcon /> Edytor
              </div>
              <div className={styles.boxList}>
                {/* <div className={styles.integration}>
                <IMAPIcon /> {emailState.name}
              </div> */}
              </div>
            </div>
          )}
        </div>
      </div>

      <Button
        className={styles.btn}
        onClick={() => setShowMenu(true)}
        text="+ Dodaj integracje"
      />
      {toDelete.show && (
        <ConfirmModal
          title="Potwierdź usunięcie integracji"
          text="Czy na pewno chcesz usunać wybraną integrację?"
          acceptLabel="Usuń"
          cancelLabel="Anuluj"
          onAccept={() => {
            if (!toDelete?.isCourt) {
              handleDisconnectConfirmed();
              setToDelete({ id: 0, show: false });
              toDelete.callback?.();
            } else {
              if (toDelete.isMain) {
                removeIntegration(
                  {
                    Id: courtPortalState.id,
                  },
                  {
                    onSuccess: () => {
                      refetchportals();
                      setCourtPortalState({
                        ...courtPortalState,
                        id: 0,
                        isActive: false,
                      });
                      refetchI();
                      setToDelete({ id: 0, show: false });
                    },
                  }
                );
                return;
              }

              updateSettings(
                {
                  PortalIds: syncPortals
                    .filter((x) => x.Id !== toDelete.id)
                    .map((x) => x.Id),
                  DocumentIds: [],
                },
                {
                  onSuccess: () => {
                    refetchportals();
                    setToDelete({ id: 0, show: false });
                  },
                }
              );
            }
          }}
          onCancel={() => setToDelete({ id: 0, show: false })}
        />
      )}
    </>
  );

  // return (
  //   <>
  //     <OauthPopup
  //       width={400}
  //       height={600}
  //       title="Zaloguj się"
  //       url={msRedirecUrl}
  //       onClose={onClose}
  //       onCode={onCode}
  //     >
  //       <div></div>
  //     </OauthPopup>
  //     <div className={styles.wrapper}>
  //       <div className={styles.menu}>
  //         <div
  //           onClick={() => setActualView(EIntegrationsViews.COURT)}
  //           className={`${styles.box}  ${
  //             actualView === EIntegrationsViews.COURT && styles.activeText
  //           }`}
  //         >
  //           PI Sądów
  //         </div>
  //         <div
  //           onClick={() => setActualView(EIntegrationsViews.EMAIL)}
  //           className={`${styles.box} ${
  //             actualView === EIntegrationsViews.EMAIL && styles.activeText
  //           }`}
  //         >
  //           Email
  //         </div>
  //         {!isGuest && (
  //           <div
  //             onClick={() => setActualView(EIntegrationsViews.CALENDAR)}
  //             className={`${styles.box} ${
  //               actualView === EIntegrationsViews.CALENDAR && styles.activeText
  //             }`}
  //           >
  //             Kalendarze
  //           </div>
  //         )}
  //         {!isGuest && (
  //           <div
  //             onClick={() => setActualView(EIntegrationsViews.EDITORS)}
  //             className={`${styles.box} ${
  //               actualView === EIntegrationsViews.EDITORS && styles.activeText
  //             }`}
  //           >
  //             Edytor dokumentów
  //           </div>
  //         )}
  //         {!isGuest && (
  //           <div
  //             onClick={() => setActualView(EIntegrationsViews.ACCOUNTANCY)}
  //             className={`${styles.box} ${
  //               actualView === EIntegrationsViews.ACCOUNTANCY &&
  //               styles.activeText
  //             }`}
  //           >
  //             System FK
  //           </div>
  //         )}
  //       </div>
  //       {selectedIntegration === null && (
  //         <>
  //           {actualView === EIntegrationsViews.COURT && (
  //             <div className={styles.groupWrapper}>
  //               <div className={styles.groupCourt}>
  //                 <div className={styles.subheader}>Integracja z PI Sądów</div>
  //                 {/* <div className={styles.hint}>
  //               Synchronizuj dokumenty z pozostałych miejsc, aby mieć
  //               wszystkie dokumenty zorganizowane w jednym miejscu.
  //             </div> */}
  //                 <div className={styles.integrationsCourt}>
  //                   <IntegrationCourtCard
  //                     type={IntegrationType.PublicPortal}
  //                     provider={IntegrationProvider.CourtPortal}
  //                     lastSync={courtPortalState.lastSync}
  //                     isActive={courtPortalState.isActive}
  //                     integrationId={courtPortalState.id}
  //                     handleDisconnect={handleDisconnect}
  //                     handleClick={handleClick}
  //                   ></IntegrationCourtCard>
  //                 </div>
  //               </div>
  //               <div className={styles.groupCourt}>
  //                 <div className={styles.subheader}>Subkonta</div>
  //               </div>
  //             </div>
  //           )}
  //           {actualView === EIntegrationsViews.EMAIL && (
  //             <div className={styles.group}>
  //               <div className={styles.subheader}>Email</div>
  //               <div className={styles.hint}>
  //                 Odbieraj swoje maile, wieszaj do spraw i obsługuj
  //                 korespondencję e-mail bezpośrednio w Nocie.
  //               </div>
  //               <div className={styles.integrations}>
  //                 <div className={styles.integrationGroup}>
  //                   <IntegrationCard
  //                     name={emailState.microsoftActive ? emailState.name : ''}
  //                     type={IntegrationType.Email}
  //                     provider={IntegrationProvider.Microsoft}
  //                     lastSync={emailState.microsoftLastSync}
  //                     isActive={emailState.microsoftActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                   <IntegrationCard
  //                     name={emailState.gmailActive ? emailState.name : ''}
  //                     type={IntegrationType.Email}
  //                     provider={IntegrationProvider.Google}
  //                     lastSync={emailState.gmailLastSync}
  //                     isActive={emailState.gmailActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                   <IntegrationCard
  //                     name={emailState.imapActive ? emailState.name : ''}
  //                     type={IntegrationType.Email}
  //                     provider={IntegrationProvider.Imap}
  //                     lastSync={emailState.imapLastSync}
  //                     isActive={emailState.imapActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                 </div>
  //                 <div className={styles.integrationGroup}></div>
  //               </div>
  //             </div>
  //           )}
  //           {actualView === EIntegrationsViews.CALENDAR && (
  //             <div className={styles.group}>
  //               <div className={styles.subheader}>Kalendarze</div>
  //               <div className={styles.hint}>
  //                 Synchronizuj swoje zaplanowane zadania, spotkania oraz inne
  //                 czynności z zewnętrznym kalendarzem.
  //               </div>
  //               <div className={styles.integrations}>
  //                 <div className={styles.integrationGroup}>
  //                   <IntegrationCard
  //                     type={IntegrationType.Calendar}
  //                     provider={IntegrationProvider.Microsoft}
  //                     lastSync={calendarState.lastSync}
  //                     isActive={calendarState.microsoftActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                   <IntegrationCard
  //                     type={IntegrationType.Calendar}
  //                     provider={IntegrationProvider.Google}
  //                     lastSync={calendarState.lastSync}
  //                     isActive={calendarState.gmailActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                   <IntegrationCard
  //                     type={IntegrationType.Calendar}
  //                     provider={IntegrationProvider.Apple}
  //                     lastSync={calendarState.lastSync}
  //                     isActive={calendarState.appleActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                 </div>

  //                 <div className={styles.integrationGroup}></div>
  //               </div>
  //             </div>
  //           )}
  //           {actualView === EIntegrationsViews.EDITORS && (
  //             <div className={styles.group}>
  //               <div className={styles.subheader}>Edytor dokumnetów</div>
  //               <div className={styles.hint}>
  //                 Edycję dokumnetów możesz prowadzić w lokalnym edytorze lub
  //                 używanym przez Ciebie edytorze online.
  //               </div>
  //               <div className={styles.integrations}>
  //                 <div className={styles.integrationGroup}>
  //                   <IntegrationCard
  //                     type={IntegrationType.Editor}
  //                     provider={IntegrationProvider.Microsoft}
  //                     lastSync={editorState.microsoftLastSync}
  //                     isActive={editorState.microsoftActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                   <IntegrationCard
  //                     type={IntegrationType.Editor}
  //                     provider={IntegrationProvider.Google}
  //                     lastSync={editorState.gmailLastSync}
  //                     isActive={editorState.gmailActive}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                 </div>
  //                 <div className={styles.integrationGroup}></div>
  //               </div>
  //             </div>
  //           )}
  //           {actualView === EIntegrationsViews.ACCOUNTANCY && (
  //             <div className={styles.group}>
  //               <div className={styles.subheader}>System FK</div>
  //               <div className={styles.hint}>
  //                 Eksportuj wystawiane przez Ciebie faktury bezpośrednio do
  //                 Twojego systemu FK
  //               </div>
  //               <div className={styles.integrations}>
  //                 <div className={styles.integrationGroup}>
  //                   <IntegrationCard
  //                     type={IntegrationType.InvoiceSystem}
  //                     provider={IntegrationProvider.WFirma}
  //                     lastSync={''}
  //                     isActive={fkState.wfirmaActive}
  //                     name={fkState.name}
  //                     handleClick={handleClick}
  //                   ></IntegrationCard>
  //                 </div>
  //                 <div className={styles.integrationGroup}></div>
  //               </div>
  //             </div>
  //           )}
  //         </>
  //       )}
  //       {selectedIntegration == IntegrationType.Email && (
  //         <EmailSettings
  //           integrationId={emailState.id}
  //           onCanceled={onInnerViewCanceled}
  //           onSaved={onInnerViewSaved}
  //           handleDisconnect={handleDisconnect}
  //         ></EmailSettings>
  //       )}
  //       {selectedIntegration == IntegrationType.Calendar && (
  //         <CalendarSettings
  //           integrationId={calendarState.id}
  //           onCanceled={onInnerViewCanceled}
  //           onSaved={onInnerViewSaved}
  //           handleDisconnect={handleDisconnect}
  //         ></CalendarSettings>
  //       )}
  //       {selectedIntegration == IntegrationType.Editor && (
  //         <EditorSettings
  //           integrationId={editorState.id}
  //           onCanceled={onInnerViewCanceled}
  //           onSaved={onInnerViewSaved}
  //           handleDisconnect={handleDisconnect}
  //         ></EditorSettings>
  //       )}
  //       {selectedIntegration == IntegrationType.PublicPortal && (
  //         <CourtPortalSettings
  //           integrationId={courtPortalState.id}
  //           onCanceled={onInnerViewCanceled}
  //           onSaved={onInnerViewSaved}
  //           handleDisconnect={handleDisconnect}
  //           refetchI={refetchI}
  //         ></CourtPortalSettings>
  //       )}
  //       {toDelete.show && (
  //         <ConfirmModal
  //           title="Potwierdź usunięcie integracji"
  //           text="Czy na pewno chcesz usunać wybraną integrację?"
  //           acceptLabel="Usuń"
  //           cancelLabel="Anuluj"
  //           onAccept={() => {
  //             setToDelete({ id: 0, show: false });
  //             handleDisconnectConfirmed();
  //             toDelete.callback?.();
  //           }}
  //           onCancel={() => setToDelete({ id: 0, show: false })}
  //         />
  //       )}
  //     </div>
  //   </>
  // );
};

export default IntegrationsTab;
