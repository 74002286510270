import { useQuery } from "react-query";
import { axiosInstance } from "./axios";
import { AxiosResponse } from "axios";

const queryKeys = {
    getZipCode: (zipCode: string | number) => ['TerytService.getZipCode', zipCode],
    getCity: (cityName: string) => ['TerytService.getCity', cityName],
    getStreet: (cityId: string | null, name: string) => ['TerytService.getStreet', cityId, name],
  };

const getZipCode = async (zip: string) => {

    if(!zip?.length) {
        return {
          data: [],
    
        } as AxiosResponse
      }
    return await axiosInstance.get(`/Teryt/ZipCode?query=${zip}`)
}

const useGetZipCode = (zip: string) => {
    return useQuery(queryKeys.getZipCode(zip), () => getZipCode(zip))
}

const getCity = async (cityName: string) => {
    if(!cityName?.length) {
        return {
          data: [],
    
        } as AxiosResponse
      }
    return await axiosInstance.get(`/Teryt/City?query=${cityName}`)
}

const useGetCity = (cityName: string) => {
    return useQuery(queryKeys.getCity(cityName), () => getCity(cityName))
}

const getStreet = async (cityId: string | null, name: string) => {
    // 
    if(!cityId?.length) {
        return {
          data: [],
    
        } as AxiosResponse
      }

    return await axiosInstance.get(`/Teryt/Street?cityId=${cityId}&query=${name}`)
}

const useGetStreet = (cityId: string | null, name: string) => {
    return useQuery(queryKeys.getStreet(cityId, name), () => getStreet(cityId, name))
}

export default {
    useGetZipCode,
    useGetCity,
    useGetStreet
}