import { IPackage } from "dto/IPackage";
import { createContext } from "react";

type IMenuContext = {
    id: string;
    setId: (id: string) => void;
}

const MenuContext = createContext<IMenuContext>({
    id: '',
    setId: () => {}
})

export default MenuContext;