import GridWindow from 'components/GridWindow';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as LettersIcon } from 'assets/icons/letters.svg';
import listService from 'api/listService';
import { useContext, useEffect, useMemo } from 'react';
import { IPackage } from 'dto/IPackage';
import LettersContext from 'contexts/LettersContext';
import KeysContext from 'contexts/KeysContext';
import LetterCard from 'components/LetterCard';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import LetterCardDesk from 'components/LetterCardDesk';
import AddButton from 'components/AddButton';
import ModalsManagerContext from 'contexts/ModalsManager';

const LettersDeskWindow: FC<Props> = ({ id, isEditMode, handleRemove }) => {
  const { data: lettersData, refetch } = listService.useGetListOfMessages({
    Group: true,
    IsIncoming: false,
    Page: 0,
    SortOrder: 'Ascending',
    SortProp: 'SendDate',
    Kind: 256,
    Desk: true,
  });

  const l: IPackage[] = useMemo(() => lettersData?.Items ?? [], [lettersData]);
  const objectKeys = useMemo(
    () => lettersData?.ObjectKeys ?? [],
    [lettersData]
  );
  const Keys = useMemo(() => lettersData?.Keys ?? [], [lettersData]);

  const { data: tagsData } = tagsService.useGetTags();
  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const { letters, setLetters } = useContext(LettersContext);
  const { keys, setKeys } = useContext(KeysContext);
  const { modals, setModals } = useContext(ModalsManagerContext);

  useEffect(() => {
    setLetters(l);
    setKeys([...keys, objectKeys, Keys].flat());
  }, [l]);

  return (
    <GridWindow
      handleRemove={handleRemove}
      id={id}
      isEditMode={isEditMode}
      title={
        <div className={styles.lw}>
          <div className={styles.left}>
            {' '}
            <LettersIcon /> Pisma
          </div>
          <div className={styles.right}>
            <div className={styles.boxT}>Tagi</div>
            <div className={styles.box}>Rozmiar</div>
            <div className={styles.box}>Data</div>
          </div>
        </div>
      }
    >
      <div className={styles.modal}>
        <div className={styles.wrapper}>
          {letters.map((letter) => (
            <LetterCardDesk
              selectedItems={[]}
              setSelectedItems={() => {}}
              isOutcoming={false}
              statuses={statuses}
              tags={tags}
              msg={letter}
              refetch={refetch}
            />
          ))}
        </div>
        {/* <div className={styles.add}>Nowe pismo</div> */}
        <AddButton
          className={styles.add}
          onClick={() => {
            setModals({ ...modals, createLetter: true });
          }}
          text="Nowe pismo"
        />
      </div>
    </GridWindow>
  );
};

export default LettersDeskWindow;
