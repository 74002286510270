import * as openpgp from 'openpgp';
import { getKeys } from './getKeys';
import { decryptPrivateKey } from './decryptPrivateKey';
import { readFile } from './readFile';
import { readFileFromBase64 } from './readFileFromBase64';
import { readAsBase64 } from './readAsBase64';
import { IDecryptedKeys } from './decryptKeys';

export const readParentKey = async (
  myKeys: IDecryptedKeys,
  keys: any,
  parentKeys: any
) => {
  const decryptParentKey = await decryptPrivateKey(
    myKeys!,
    parentKeys.PrivateKey
  );
  const text = await openpgp.key.read(decryptParentKey.data);
  const PK = await readFileFromBase64(keys.PrivateKey, 'application/other');
  const msg = await openpgp.message.read(new Uint8Array(PK));

  var options: openpgp.DecryptOptions = {
    message: msg,
    privateKeys: text.keys[0],
    format: 'binary',
  };

  return await openpgp.decrypt(options);
};

const readArmoredParentKey = async (
  myKeys: IDecryptedKeys,
  keys: any,
  parentKeys: any
) => {
  const decryptParentKey = await decryptPrivateKey(
    myKeys!,
    parentKeys.PrivateKey
  );

  var string = new TextDecoder().decode(decryptParentKey.data);
  const armored = atob(string);
  const text = await openpgp.key.readArmored(armored);
  //@ts-ignore
  // 
  const PK = await readFileFromBase64(keys.PrivateKey, 'application/other');

  const msg = await openpgp.message.read(new Uint8Array(PK));

  var options: openpgp.DecryptOptions = {
    message: msg,
    privateKeys: text.keys[0],
    format: 'binary',
  };

  return await openpgp.decrypt(options);
};

export const decryptFile = async (
  userId: string,
  file: Blob,
  keys: any,
  parentKeys?: any
): Promise<Blob> => {
  try {

    const myKeys = await getKeys(userId);
    let privateKey = null;

    if (parentKeys) {
      try {
        privateKey = await readParentKey(myKeys!, keys, parentKeys);
      } catch (e) {
        privateKey = await readArmoredParentKey(myKeys!, keys, parentKeys);
      }
    } else {
      privateKey = await decryptPrivateKey(myKeys!, keys.PrivateKey);
    }


    const xfile = await openpgp.message.read(
      new Uint8Array(await readFile(file))
    );

    let decryptedFile = null;

    try {
      const key = await openpgp.key.read(privateKey.data);

      if (keys.EmptyPassphrase) {
        key.keys[0].decrypt('');
      }

      decryptedFile = await openpgp.decrypt({
        message: xfile,
        privateKeys: key.keys[0],
        format: 'binary',
      });

      return new Blob([decryptedFile.data]);
    } catch (e) {
      
      let string = new TextDecoder().decode(privateKey.data);
      const armored = atob(string);
      const key = await openpgp.key.readArmored(armored);

      decryptedFile = await openpgp.decrypt({
        message: xfile,
        privateKeys: key.keys[0],
        format: 'binary',
      });
    }

    return new Blob([decryptedFile.data]);
  } catch (e: any) {
    throw new Error(e.message);
  }
};
