import { Route, Routes } from 'react-router-dom';
import MessagesModule from './';

const TemplatesRoutes = () => {
  return (
    <Routes>
      <Route path="/:templateId/:templateKind" element={<MessagesModule />} />
      <Route path="/*" element={<MessagesModule />} />
    </Routes>
  );
};

export default TemplatesRoutes;
