import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';

const NewInput: FC<Props> = ({
  type = 'input',
  tags = false,
  onChooseTag = () => {},
  label,
  onChange,
  defaultValue,
  value,
  placeholder,
  disabled = false,
  onFocus,
  onBlur,
  onClick = () => {},
  labelClassName = '',
  className = '',
  labelNew,
  focus = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState(defaultValue ?? '');

  const ref = useRef(null);

  const { data: tagsData } = tagsService.useGetTags();

  const [showTags, setShowTags] = useState(false);

  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const handleChooseTag = (tag: ITag) => {
    onChooseTag(tag);
    const formatedText = value ?? text;

    setText(formatedText.replaceAll('#', ''));
    onChange(formatedText.replaceAll('#', ''));
  };

  useEffect(() => {
    const formatedText = value ?? text;

    const isHashtag = formatedText.search('#') !== -1;

    if (isHashtag && !showTags) {
      setShowTags(true);
      return;
    }

    if (!isHashtag && showTags) {
      setShowTags(false);
      return;
    }
  }, [value, text]);

  const handleClickButton = (e: any) => {
    if (e.key === 'Enter' && isFocused) {
      const formatedText = value ?? text;

      const tag = formatedText.split('#')[1];

      setShowTags(false);

      onChooseTag({
        Id: '-1',
        Name: tag,
        Type: 2,
        Flags: 0,
        Global: false,
      });

      setText(formatedText.split('#')[0]);
      onChange(formatedText.split('#')[0]);
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleClickButton);

    return () => document.removeEventListener('keypress', handleClickButton);
  }, [value, text, isFocused]);

  const isText = !!value?.length || !!text.length;
  const isFocusedStyle = isFocused && !isText ? styles.active : '';
  const isFocusedStyle2 = !isFocused && isText ? styles.active : '';
  const isFocusedStyle3 = isFocused && isText ? styles.active : '';

  useEffect(() => {
    if (!ref?.current || !focus) return;
    // @ts-ignore
    ref?.current?.focus();
    setIsFocused(true);
  }, [focus, ref]);

  return (
    <div
      className={`${
        styles.inputWrapper
      } ${isFocusedStyle3} ${isFocusedStyle} ${isFocusedStyle2} ${
        labelNew && styles.labelNew222222
      } `}
    >
      {labelNew && (
        <div
          className={`${styles.labelNew} ${labelClassName} ${
            isFocused && styles.ACTIVELABEL
          }`}
        >
          {labelNew}
        </div>
      )}
      <div
        onClick={() => {
          // @ts-ignore
          ref?.current?.focus();
          setIsFocused(true);
        }}
        className={`${styles.label} ${labelClassName}`}
      >
        {label}
      </div>
      <input
        ref={ref}
        spellCheck={true}
        onFocus={() => {
          setIsFocused(true);
          onFocus?.();
        }}
        onBlur={() => {
          setIsFocused(false);
          onBlur?.();
        }}
        disabled={disabled}
        type={type}
        onClick={() => {
          onClick?.();
        }}
        onChange={(e) => handleChange(e)}
        className={`${styles.input} ${className}`}
        value={value ?? text}
        placeholder={placeholder}
      />
      {/* {showTags && (
        <div className={styles.dropdown}>
          <div className={styles.title}>Lista tagów</div>
          {tagsList
            .filter((tag: ITag) => tag.Type === 2)
            .map((tag: ITag) => (
              <div onClick={() => handleChooseTag(tag)} className={styles.box}>
                {tag.Name}
              </div>
            ))}
        </div>
      )} */}
    </div>
  );
};

export default NewInput;
