import Spinner from 'components/Spinner';
import styles from './styles.module.scss';

const LoadingScreen = () => {
    return <div className={styles.wrapper}>
        <div className={styles.content}>
            <Spinner className={styles.spinner} />
            <div className={styles.text}>Trwa ładowanie</div>
        </div>
    </div>
}

export default LoadingScreen;