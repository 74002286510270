import { Route, Routes } from 'react-router-dom';
import CasesModule from '.';

const CasesRoute = () => {
  return (
    <Routes>
      <Route path="/:caseId/:view" element={<CasesModule />} />
      <Route
        path="/fullscreen/:caseId/:view"
        element={<CasesModule hideMenu={true} />}
      />
      <Route
        path="/:caseId/:view/:kind/:messageId/:attachmentId"
        element={<CasesModule />}
      />

      <Route path="/:caseId/:view/f/:folderId" element={<CasesModule />} />

      <Route
        path="/:caseId/:view/:kind/:messageId/:attachmentId/:encryptionKey"
        element={<CasesModule />}
      />
      {/* <Route
        path="/:filterId/:status?/:caseId/:kind/:msgId/preview"
        element={<CasesModule />}
      /> */}
      <Route path="/*" element={<CasesModule />} />
    </Routes>
  );
};

export default CasesRoute;
