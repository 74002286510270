import { useMutation, useQuery } from "react-query"
import { axiosInstance } from "./axios";

const queryKeys = {
    getNotifications: () => [
      'notificationService.getNotifications',
    ],
}

const getNotifications = async () => {
    const r = await axiosInstance.get('/notifications');
    return r.data ?? [];
}

const useGetNotifications = () => {
    return useQuery(queryKeys.getNotifications(), () => getNotifications())
}

export interface IReadNotificationPayload {
    Id: string | number;
}

const readNotification = (payload: IReadNotificationPayload) => {
    return axiosInstance.post('notification/read', payload)
}

const useReadNotification = () => {
    return useMutation(readNotification);
}

const deleteNotification = (payload: IReadNotificationPayload) => {
    return axiosInstance.delete('/notification', {
        data: payload
    });
}

const useDeleteNotification = () => {
    return useMutation(deleteNotification);
}



export default {
    useGetNotifications,
    useReadNotification,
    useDeleteNotification
}