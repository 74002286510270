import { FC, Props } from './typings';
import styles from './styles.module.scss';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import casesService from 'api/casesService';
import { useMemo } from 'react';
import { ReactComponent as CaseIcon } from 'assets/icons/case.svg';

const CaseRelationsModal: FC<Props> = ({ toggle, CaseId }) => {
  const { data: CaseData } = casesService.useGetCase(CaseId ?? '');

  const Case = useMemo(() => CaseData, [CaseData]);

  const openCase = (id: string) => {
    navigate(`/cases/${id}/chat`);
  };

  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          Relacje
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => toggle(false)}
          />
        </div>
        <div className={styles.list}>
          {CaseData?.Relations.map((r) => (
            <div key={r.Id} className={styles.case}>
              <div className={styles.metaData}>
                <div className={styles.signature}>{r.Signature}</div>
                <div className={styles.client}>{r.Principal}</div>
              </div>

              <div className={styles.menu}>
                <CaseIcon onClick={() => openCase(r.CaseId)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseRelationsModal;
