import { ItemKind } from 'dto/IKindItems';

export const calculateKindItems = ({
  CourtPortalDeliverance,
  CourtPortalDocument,
  Parcel,
  EDeliveryMessage,
  CourtPortalApplication,
  Email,
  ContactMessage,
  OutgoingParcel,
}: {
  CourtPortalDeliverance: boolean;
  CourtPortalDocument: boolean;
  Parcel: boolean;
  EDeliveryMessage: boolean;
  CourtPortalApplication: boolean;
  Email: boolean;
  ContactMessage: boolean;
  OutgoingParcel: boolean;
}) => {
  let toRet = 0;

  if (CourtPortalDeliverance) {
    toRet += ItemKind.CourtPortalDeliverance;
  }

  if (CourtPortalDocument) {
    toRet += ItemKind.CourtPortalDocument;
  }

  if (Parcel) {
    toRet += ItemKind.Parcel;
  }

  if (EDeliveryMessage) {
    toRet += ItemKind.EDeliveryMessage;
  }

  if (CourtPortalApplication) {
    toRet += ItemKind.CourtPortalApplication;
  }
  if (Email) {
    toRet += ItemKind.Email;
  }
  if (ContactMessage) {
    toRet += ItemKind.ContactMessage;
  }
  if (OutgoingParcel) {
    toRet += ItemKind.OutgoingParcel;
  }

  return toRet;
};
