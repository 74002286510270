import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { IDossierFolder } from 'dto/Dossier/IDossierFolder';
import { IGetItemsResponse } from 'dto/Dossier/IGetItemsResponse';
import { IError } from 'dto/IError';
import { ListType } from 'dto/ListType';
import { DossierItemCategory } from 'dto/Dossier/DossierItemCategory';

const queryKeys = {
  getFolder: (folderId: string) => ['dossierService.getFolder', folderId],
  getFolders: (caseId: string) => ['dossierService.getFolders', caseId],
  getItems: (payload: IGetItemsRequest) => ['dossierService.getItems', payload],
};

export interface IMoveDosier {
  Id: string;
  Order: number;
  ParentId: string | null;
}

export interface IGetItemsRequest {
  FilterId?: string;
  CaseId?: string;
  FolderId?: string;
  Page: number;
  SortProp: 'Date' | 'LastModification' | 'Name' | 'Status';
  SortOrder: 'Ascending' | 'Descending';
  Text?: string | null;
  IncludeFolders?: boolean;
  IncludeShared?: boolean;
  Shared?: boolean;
  SharedFromUserId?: string;
  SharedToUserId?: string;
  enabled?: boolean;
}

const moveDossier = (payload: IMoveDosier) => {
  return axiosInstance.post('/dossier/move', payload);
};

const useMoveDossier = () => {
  return useMutation(moveDossier);
};

export interface ICreateFolder {
  CaseId: string;
  ParentId?: string;
  Name: string;
}

const createFolder = (
  payload: ICreateFolder
): Promise<{ data: IDossierFolder }> => {
  return axiosInstance.post('/dossier/folder', payload);
};

const useCreateFolder = () => {
  return useMutation(createFolder);
};

export interface IUpdateLabel {
  Id: string;
  Label: string;
}

const updateLabel = async (payload: IUpdateLabel) => {
  return await axiosInstance.put('/dossier/label', payload);
};

const useUpdateLabel = () => {
  return useMutation(updateLabel);
};

export interface ICreateVersion {
  Id: string;
  Name: string;
}

const createVersion = async (payload: ICreateVersion) => {
  return await axiosInstance.post('/dossier/version', payload);
};

const useCreateVersion = () => {
  return useMutation(createVersion);
};

export interface IRemoveVersion {
  Id: string;
  VersionId: string;
}

const removeVersion = async (payload: IRemoveVersion) => {
  return await axiosInstance.delete('/dossier/version', { data: payload });
};

const useRemoveVersion = () => {
  return useMutation(removeVersion);
};

export interface IDeleteDossier {
  Id: string;
}

const deleteDossier = (payload: IDeleteDossier) => {
  return axiosInstance.delete('/dossier', {
    data: payload,
  });
};

const useDeleteDossier = () => {
  return useMutation(deleteDossier);
};

export interface IDossierFile {
  KeyId: string;
  Uri: string;
}
export interface IDossierAddFile {
  CaseId: string;
  Files: IDossierFile[];
  ParentId?: string;
  SourceId?: string;
}

const addFile = (payload: IDossierAddFile) => {
  return axiosInstance.post('/dossier/file', payload);
};

const useAddFile = () => {
  return useMutation(addFile);
};

export interface IDossierAddLink {
  CaseId: string;
  ParentId?: string;
  Label: string;
  Url: string;
}

const addLink = (payload: IDossierAddLink) => {
  return axiosInstance.post('/dossier/link', payload);
};

const useAddLink = () => {
  return useMutation(addLink);
};

export interface IUpdateDossierFlag {
  Add: number | null;
  Id: string;
  Remove: number | null;
}

const setFlags = (payload: IUpdateDossierFlag) => {
  return axiosInstance.put('/dossier/flags', payload);
};

const useSetFlags = () => {
  return useMutation(setFlags);
};

const getFolder = async (folderId: string) => {
  const r = await axiosInstance.get(`/dossier/folder?id=${folderId}`, {});
  return r.data ?? [];
};

const useGetFolder = (folderId: string, enabled: boolean) => {
  return useQuery(queryKeys.getFolder(folderId), () => getFolder(folderId), {
    enabled: enabled,
  });
  ('');
};

const getFolders = async (caseId: string) => {
  const r = await axiosInstance.get(`/dossier/folders?id=${caseId}`, {});
  return r.data ?? [];
};

const useGetFolders = (caseId: string, enabled: boolean) => {
  return useQuery(queryKeys.getFolders(caseId), () => getFolders(caseId), {
    enabled: enabled,
  });
  ('');
};

export interface ISign {
  Id: string;
  Uri?: string;
  XadesUri?: string;
  Report?: string;
  Countersign?: boolean;
}

const sign = (payload: ISign) => {
  return axiosInstance.post('/dossier/sign', payload);
};

const useSign = () => {
  return useMutation(sign);
};

const getItems = async ({
  CaseId,
  FilterId,
  FolderId,
  Page,
  SortProp,
  SortOrder,
  Text,
  IncludeFolders,
  IncludeShared,
  Shared,
  SharedFromUserId,
  SharedToUserId,
}: IGetItemsRequest): Promise<{ data: IGetItemsResponse }> => {
  const query = new URLSearchParams(window.location.search);

  if (Text) {
    query.append('Text', Text);
  }
  if (FilterId) {
    query.append('FilterId', FilterId);
  }

  if (FolderId) {
    query.append('FolderId', FolderId);
  }

  if (IncludeFolders !== undefined) {
    query.append('IncludeFolders', IncludeFolders.toString());
  }

  if (IncludeShared !== undefined) {
    query.append('IncludeShared', IncludeShared.toString());
  }

  if (Shared !== undefined) {
    query.append('Shared', Shared.toString());
  }

  if (SharedFromUserId) {
    query.append('SharedFromUserId', SharedFromUserId);
  }

  if (SharedToUserId) {
    query.append('SharedToUserId', SharedToUserId);
  }

  query.append('Page', Page.toString());
  query.append('SortProp', SortProp);
  query.append('SortOrder', SortOrder);

  if (!!CaseId?.length) {
    query.append('CaseId', CaseId);
  }

  return axiosInstance.get(`/dossier?${query}`);
};

const useGetItems = (payload: IGetItemsRequest) => {
  return useQuery(queryKeys.getItems(payload), () => getItems(payload), {
    keepPreviousData: false,
    enabled: payload.enabled,
  });
};

const useMutationGetItems = () => {
  return useMutation(getItems);
};

export interface IGetFiltersRequest {
  ListType: ListType;
}

const getFilters = (request: IGetFiltersRequest) => {
  const query = new URLSearchParams(window.location.search);

  query.append('ListType', request.ListType.valueOf().toString());
  return axiosInstance.get(`/dossier/filters?${query}`);
};

const useGetFilters = () => {
  return useMutation(getFilters);
};

export interface ICreateFilterRequest {
  Id?: string;
  ListType: ListType;

  Name: string;
  Category: DossierItemCategory;
  SharedToUserId?: string;
  SharedByUserId?: string;
  Shared?: boolean;
  TagIds?: string[];
  StatusIds?: number[];
}

const createFilter = (payload: ICreateFilterRequest) => {
  return axiosInstance.post('/dossier/filter', payload);
};

const useCreateFilter = () => {
  return useMutation(createFilter);
};

export default {
  useMoveDossier,
  useCreateFolder,
  useUpdateLabel,
  useDeleteDossier,
  useAddFile,
  useAddLink,
  useSetFlags,
  useGetFolder,
  useGetFolders,
  useSign,
  useCreateVersion,
  useRemoveVersion,
  useGetItems,
  useMutationGetItems,
  useGetFilters,
  useCreateFilter,
};
