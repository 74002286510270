export enum EDeliveryAddressStatus {
  /// <summary>
  /// Zarezerwowany
  /// </summary>
  RESERVED = 'RESERVED',

  /// <summary>
  /// Aktywny
  /// </summary>
  ACTIVE = 'ACTIVE',

  /// <summary>
  /// Zamknięty, do odzyskania
  /// </summary>
  CLOSEDRECOVERABLE = 'CLOSEDRECOVERABLE',

  /// <summary>
  /// Zamknięty na stałę
  /// </summary>
  CLOSEDUNRECOVERABLE = 'CLOSEDUNRECOVERABLE',

  /// <summary>
  /// Skreślony
  /// </summary>
  STRUCKOFF = 'STRUCKOFF',

  /// <summary>
  /// Oczekujący
  /// </summary>
  PENDING = 'PENDING',

  /// <summary>
  /// Do weryfikacji
  /// </summary>
  TOVERIFY = 99,

  /// <summary>
  /// Błędny adres - nie istnieje w BAE
  /// </summary>
  NOTEXISTING = 100,
}
