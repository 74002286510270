import moment from 'moment';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

const TemplateCard: FC<Props> = ({ onClick, template }) => {
  return (
    <div onClick={() => onClick(template)} className={styles.wrapper}>
      <div className={styles.card}>
        <div className={styles.name}>{template.S1}</div>
        <div className={styles.name}>
          {moment(template.CreationDate).format('DD.MM.YY')}
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
