import { Autocomplete, Box, TextField } from '@mui/material';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

const AutoComplete: FC<Props> = ({
  open = false,
  onChange,
  onChoose,
  options,
  value,
  label = 'Teczki',
}) => {
  return (
    <div className={styles.wrapper}>
      <Autocomplete
        // open={open}
        openOnFocus={true}
        options={options}
        onChange={(a, b) => {
          console.log(b);
          if (b) {
            onChoose(b.id);
          }
        }}
        renderInput={(params) => (
          <TextField
            className={styles.b}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            variant={'filled'}
            // onChange={(e) => setValue(e.target.value)}
            {...params}
            label={label}
            style={{
              width: '100%',
              // height: height,
            }}
            /* styles the label component */
          />
        )}
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Box
              sx={{
                flexGrow: 1,
                '& span': {},
              }}
            >
              {option.label}
            </Box>
          </li>
        )}
      />
    </div>
  );
};

export default AutoComplete;
