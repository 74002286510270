import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BackIcon } from 'assets/icons/tasks/back.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/tasks/flag.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tasks/tag.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/tasks/person.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/tasks/calendar.svg';
import { ReactComponent as EndIcon } from 'assets/icons/tasks/end.svg';
import { useEffect, useState } from 'react';
import ChooseTagNew from 'components/ChooseTagNew';
import SearchMemberNew from 'components/SearchMemberNew';
import { IWorkspaceMember } from 'dto/IWorkspace';
import { ITag } from 'dto/ITag';
import SelectFlagNew from 'components/SelectFlagNew';
import { IStatus } from 'dto/IStatus';
import Avatar from 'components/Avatar';
import Chip from 'components/Chip';
import { axiosInstance } from 'api/axios';
import { ITask } from 'dto/ITask';
import taskService, { IUpdateTaskPayload } from 'api/taskService';

const TaskFilter: FC<Props> = ({
  setFilters,
  task,
  setTask,
  isOpen = false,
}) => {
  const [show, setShow] = useState(false);
  const { mutate: updateTask } = taskService.useUpdateEvent();
  const [tags, setTags] = useState<ITag[]>([]);
  const [members, setMembers] = useState<IWorkspaceMember[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<IStatus | null>(null);

  const onchange = () => {
    setFilters?.({
      tagId: tags.at(0)?.Id ?? null,
      memberId: members.at(0)?.UserId ?? null,
      statusId: selectedStatus?.Id.toString() ?? null,
    });
  };

  useEffect(() => {
    if (!task) {
      onchange();
    }
  }, [tags, members, selectedStatus]);

  const move = async () => {
    const response = await axiosInstance.post('/task/pass', {
      Id: task!.Id,
      UserId: members.at(0)?.UserId,
    });

    const nTask: ITask = JSON.parse(JSON.stringify(task));

    nTask.UserLabel = members.at(0)?.Label;
    setTask?.(nTask);
  };

  const handleUpdate = (tag: ITag) => {
    if (!task) return;
    const payload: IUpdateTaskPayload = {
      Allday: task.AllDay,
      FromDate: task.FromDate,
      Id: task.Id,
      IsFavourite: task.IsFavourite,
      Note: task.Note,
      OwnerId: task.OwnerId,
      OwnerKind: task.Kind,
      TagIds: [...task.TagIds, tag.Id].flat(),
      ToDate: task.ToDate,
      Title: task.Title,
    };

    updateTask(payload, {
      onSuccess: (response) => {
        setTask?.(response.data);
      },
    });
  };

  useEffect(() => {
    if (task && members.at(0)) {
      move();
    }
  }, [members]);

  return (
    <div
      className={`${styles.wrapper} ${(show || isOpen) && styles.expand} ${
        isOpen && styles.big
      }`}
    >
      <div className={styles.wrap}>
        <div className={styles.header}>
          {show && (
            <div className={styles.title}>
              {!task ? 'Filtruj' : 'Zarządzaj'}
            </div>
          )}
          {!isOpen && (
            <BackIcon className={styles.icon} onClick={() => setShow(!show)} />
          )}
        </div>
        {!show && !isOpen && (
          <div className={styles.icons}>
            {!task && (
              <>
                <FlagIcon />
              </>
            )}
            <TagIcon />
            <PersonIcon />
            {!task && (
              <>
                <CalendarIcon />
              </>
            )}
          </div>
        )}

        {(show || isOpen) && (
          <div className={styles.controls}>
            {!task && (
              <>
                <SelectFlagNew
                  selectedStatus={selectedStatus}
                  onChoose={(s) => {
                    setSelectedStatus(s);
                  }}
                />
              </>
            )}
            <ChooseTagNew
              type={0}
              onChoose={(tag) => {
                const isExist = tags.find((t) => t.Name === tag?.Name);

                if (isExist) return;

                if (task && tag) {
                  handleUpdate(tag);
                  return;
                }

                setTags([...tags, tag!]);
              }}
            />
            <SearchMemberNew
              isDisabled={task && !!task?.CompleteDate}
              label={
                task
                  ? !!members.length
                    ? members.at(0)?.Label
                    : task?.UserLabel ?? ''
                  : undefined
              }
              onChoose={(member) => {
                setMembers([member as IWorkspaceMember]);
              }}
            />
          </div>
        )}
        {show && !task && (
          <div className={styles.list}>
            {!!tags.length && !task && (
              <div className={styles.tags}>
                {tags.map((tag) => (
                  <Chip
                    element={<>{tag.Name}</>}
                    onDelete={() => {
                      setTags(tags.filter((t) => t.Id !== tag.Id));
                    }}
                  />
                ))}
              </div>
            )}
            <div className={styles.members}>
              {members.map((member) => (
                <Chip
                  element={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <Avatar name={member.Label} className={styles.avatar} />{' '}
                      {member.Label}
                    </div>
                  }
                  onDelete={() => {
                    setMembers(members.filter((f) => f.Id !== member.Id));
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {task && !task?.CompleteDate && (
        <div className={styles.end}>
          <EndIcon
            onClick={async () => {
              await axiosInstance.post('/task/done', {
                Id: task.Id,
                Done: true,
              });
              const nTask = JSON.parse(JSON.stringify(task));
              nTask.CompleteDate = new Date();
              setTask?.(nTask);
            }}
            className={styles.icone}
          />
        </div>
      )}
    </div>
  );
};

export default TaskFilter;
