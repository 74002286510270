import Button from 'components/Button';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { EClientData, IClient, IClientData } from 'dto/IClient';
import { useContext, useEffect, useState } from 'react';
import ClientsContext from 'contexts/ClientsContext';
import clientService from 'api/clientService';

import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as StarBorderIcon } from 'assets/icons/starBorder.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edite.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copye.svg';
import Input from 'components/Input';
import EditAddressModal from 'components/EditAddressModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Empty = ({
  data,
  setData,
  type,
  setEdit,
  setOrginalIndex,
  orginalIndex,
  actualEditType,
  setType,
  updateData,
}: {
  data: IClient;
  setData: (d: IClient) => void;
  type: string | null;
  setEdit: (s: number) => void;
  orginalIndex: number;
  setOrginalIndex: (s: number) => void;
  actualEditType: string | null;
  setType: (s: string | null) => void;
  updateData: () => void;
}) => {
  const [value, setValue] = useState('');

  return (
    <div className={styles.data}>
      {actualEditType === type ? (
        <div className={styles.ticket}>
          <Input
            label=""
            onChange={(t) => setValue(t)}
            value={value}
            onAccept={() => {
              let t = EClientData.EMAIL;

              if (type === 'phone') {
                t = EClientData.PHONE;
              }

              if (type === 'eaddress') {
                t = EClientData.eaddress;
              }

              const n = { Type: t, IsMain: true, Value: value };

              data.Data.push(n);

              setData(data);
              setOrginalIndex(0);
              setType(null);
              setTimeout(() => {
                updateData();
              }, 500);
            }}
          />
          <FontAwesomeIcon
            onClick={() => {
              let t = EClientData.EMAIL;

              if (type === 'phone') {
                t = EClientData.PHONE;
              }

              if (type === 'eaddress') {
                t = EClientData.eaddress;
              }

              const n = { Type: t, IsMain: true, Value: value };

              data.Data.push(n);

              setData(data);
              setOrginalIndex(0);
              setType(null);
              setTimeout(() => {
                updateData();
              }, 500);
            }}
            icon={faCheck}
          />
        </div>
      ) : (
        <>...</>
      )}
      {actualEditType !== type && (
        <div className={styles.icons}>
          <EditIcon
            title="Edytuj"
            onClick={() => {
              setEdit(0);
              setType(type);
            }}
          />
        </div>
      )}
    </div>
  );
};

const PersonalDataClient: FC<Props> = ({ client, refetch }) => {
  if (!client?.Data) return <></>;

  const [initalized, setIntialized] = useState(false);
  const [data, setData] = useState<IClient>(JSON.parse(JSON.stringify(client)));

  const note = data.Data.find((d) => d.Type === EClientData.NOTE)?.Value ?? '';

  const addresses = data.Data.filter((d) => d.Type === EClientData.ADDRESS);
  const emails = data.Data.filter(
    (d) => d.Type === EClientData.EMAIL && !!d.Value.length
  );
  const phones = data.Data.filter(
    (d) => d.Type === EClientData.PHONE && !!d.Value.length
  );
  const eaddresses = data.Data.filter(
    (d) => d.Type === EClientData.eaddress && !!d.Value.length
  );

  function formatPhone(str: string) {
    str = str.replaceAll(' ', '');
    if (str.length == 0) return str;

    let startFrom = 0;

    var phone = '';

    if (str.startsWith('+')) {
      startFrom = 3;
    }

    if (str.length < 3) {
      phone += str;
    } else if (str.length < 6) {
      phone += str.substring(0, 3) + '';
      if (str.length > 3) phone += str.substring(3, str.length);
    } else {
      if (str.startsWith('+')) {
        phone += str.substring(0, 3) + ' ';
      }

      phone +=
        str.substring(startFrom, startFrom + 3) +
        ' ' +
        str.substring(startFrom + 3, startFrom + 6) +
        ' ' +
        str.substring(startFrom + 6, startFrom + 12);
    }

    return phone;
  }

  const { clients, setClients } = useContext(ClientsContext);
  const { mutate: updateClient } = clientService.useNewUpdateClient();

  const updateData = () => {
    updateClient(data, {
      onSuccess: (resp) => {
        const newClients = [...clients];
        let searchedClient = newClients.find((c) => c.Id === client.Id);

        if (searchedClient) {
          searchedClient = resp.data;
          // setClients(newClients);
          refetch();
        }
      },
    });
  };

  const updateMain = (newMain: IClientData) => {
    const ndata = data.Data.map((c) => {
      if (newMain.Type === c.Type) {
        if (newMain.Value === c.Value) {
          c.IsMain = true;
          return c;
        }
        c.IsMain = false;
      }

      return c;
    });

    updateClient(data);

    const newClients = [...clients];
    const searchedClient = newClients.find((c) => c.Id === client.Id);

    if (searchedClient) {
      searchedClient.Data = ndata;
      // setClients(newClients);
    }
  };

  useEffect(() => {
    setData(JSON.parse(JSON.stringify(client)));
  }, [client]);

  const [selected, setSelected] = useState<null | IClientData>(null);
  const [editNip, setEditNip] = useState(false);

  const [type, setType] = useState<string | null>(null);
  const [orginalIndex, setOrginalIndex] = useState<number>(0);
  const [edit, setEdit] = useState<null | number>(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.group}>
        <div className={styles.label}>Nip</div>
        <div className={styles.data}>
          {editNip ? (
            <div className={styles.ticket}>
              <Input
                label="NIP"
                value={data.NIP}
                onChange={(e) => {
                  setData({ ...data, NIP: e });
                }}
                onEnter={() => {
                  updateData();
                  setEditNip(false);
                }}
              />
              <FontAwesomeIcon
                onClick={() => {
                  updateData();
                  setEditNip(false);
                }}
                icon={faCheck}
              />
            </div>
          ) : (
            <>
              <div className={styles.g}>
                {!!data.NIP?.length ? (
                  <div onClick={() => setEditNip(true)}>{data.NIP}</div>
                ) : (
                  <div onClick={() => setEditNip(true)}>...</div>
                )}
                {!editNip && (
                  <div className={styles.icons}>
                    <EditIcon onClick={() => setEditNip(true)} title="Edytuj" />
                    <CopyIcon
                      title="Kopiuj"
                      onClick={() => {
                        navigator.clipboard.writeText(data?.NIP ?? '');
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.label}>Adres</div>
        <div className={styles.list}>
          {!addresses.length && (
            <div className={styles.data}>
              ...
              <div className={styles.icons}>
                <EditIcon
                  onClick={() => {
                    const n = JSON.stringify({
                      address: '',
                      zipCode: '',
                      city: '',
                    });

                    const newData = data;
                    newData.Data = [
                      ...newData.Data,
                      { Type: EClientData.ADDRESS, IsMain: true, Value: n },
                    ];

                    setData(newData);
                    setEdit(0);
                    setOrginalIndex(newData.Data.length - 1);
                    setType('address');
                  }}
                />
                <CopyIcon
                  onClick={() => {
                    navigator.clipboard.writeText(data?.NIP ?? '');
                  }}
                />
              </div>
            </div>
          )}
          {addresses.map((a, index) => {
            const json = JSON.parse(a.Value);

            const isSame =
              selected?.Type !== a.Type || selected?.Value !== a.Value;
            return (
              <>
                <div
                  onMouseEnter={() => {
                    setSelected(a);
                  }}
                  onMouseLeave={() => {
                    setSelected(null);
                  }}
                  className={styles.data}
                >
                  <div className={styles.address}>
                    <div className={styles.line1}>
                      {json.Address ?? json.address}{' '}
                      {json.ZipCode ?? json.zipCode}, {json.City ?? json.city}
                    </div>
                  </div>
                  <div className={styles.icons}>
                    <EditIcon
                      onClick={() => {
                        setEdit(index);
                        setType('address');
                        const indexOf = data.Data.findIndex((b) => a === b);
                        setOrginalIndex(indexOf);
                      }}
                    />
                    <CopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${json.Address} ${json.ZipCode}, ${json.City}` ?? ''
                        );
                      }}
                    />
                    {a.IsMain ? (
                      <StarIcon className={styles.icon} />
                    ) : (
                      <StarBorderIcon
                        onClick={() => {
                          updateMain(a);
                        }}
                        className={`${styles.icon} ${
                          selected !== a && styles.active
                        }`}
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.label}>Email</div>
        <div className={styles.list}>
          {!emails.length && (
            <Empty
              updateData={updateData}
              actualEditType={type}
              data={data}
              setData={setData}
              type="email"
              orginalIndex={orginalIndex}
              setEdit={setEdit}
              setOrginalIndex={setOrginalIndex}
              setType={setType}
            />
          )}
          {emails.map((e, index) => {
            const isSame =
              selected?.Type !== e.Type || selected?.Value !== e.Value;

            return (
              <div
                onMouseEnter={() => {
                  setSelected(e);
                }}
                onMouseLeave={() => {
                  setSelected(null);
                }}
                className={styles.data}
              >
                {type === 'email' && edit === index ? (
                  <div className={styles.ticket}>
                    <Input
                      label="Email"
                      value={e.Value}
                      onChange={(text) => {
                        const copy = JSON.parse(JSON.stringify(data));
                        copy.Data[orginalIndex].Value = text;
                        setData(copy);
                      }}
                      onAccept={() => {
                        updateData();
                        setOrginalIndex(0);
                        setEdit(null);
                        setType(null);
                      }}
                    />
                    <FontAwesomeIcon
                      onClick={() => {
                        updateData();
                        setOrginalIndex(0);
                        setEdit(null);
                        setType(null);
                      }}
                      icon={faCheck}
                    />
                  </div>
                ) : (
                  e.Value
                )}
                {type !== 'email' && (
                  <div className={styles.icons}>
                    <EditIcon
                      title="Edytuj"
                      onClick={() => {
                        setEdit(index);
                        setType('email');
                        const indexOf = data.Data.findIndex((b) => e === b);
                        setOrginalIndex(indexOf);
                      }}
                    />
                    <CopyIcon
                      title="Kopiuj"
                      onClick={() => {
                        navigator.clipboard.writeText(e.Value ?? '');
                      }}
                    />
                    {e.IsMain ? (
                      <StarIcon className={styles.icon} />
                    ) : (
                      <StarBorderIcon
                        onClick={() => {
                          updateMain(e);
                        }}
                        className={`${styles.icon} ${
                          selected !== e && styles.active
                        }`}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.label}>Telefon</div>

        {!phones.length && (
          <Empty
            updateData={updateData}
            actualEditType={type}
            data={data}
            setData={setData}
            type="phone"
            orginalIndex={orginalIndex}
            setEdit={setEdit}
            setOrginalIndex={setOrginalIndex}
            setType={setType}
          />
        )}
        {phones.map((p, index) => {
          const isSame =
            selected?.Type !== p.Type || selected?.Value !== p.Value;
          let number = formatPhone(p.Value);

          return (
            <div className={styles.data}>
              {type === 'phone' && edit === index ? (
                <div className={styles.ticket}>
                  <Input
                    isPhoneNumber
                    label="Numer telefonu"
                    value={p.Value}
                    onChange={(text) => {
                      const copy = JSON.parse(JSON.stringify(data));
                      copy.Data[orginalIndex].Value = text;
                      setData(copy);
                    }}
                    onAccept={() => {
                      updateData();
                      setOrginalIndex(0);
                      setEdit(null);
                      setType(null);
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      updateData();
                      setOrginalIndex(0);
                      setEdit(null);
                      setType(null);
                    }}
                    icon={faCheck}
                  />
                </div>
              ) : (
                number
              )}
              {type !== 'phone' && (
                <div className={styles.icons}>
                  <EditIcon
                    title="Edytuj"
                    onClick={() => {
                      setEdit(index);
                      setType('phone');
                      const indexOf = data.Data.findIndex((b) => p === b);
                      setOrginalIndex(indexOf);
                    }}
                  />
                  <CopyIcon
                    title="Kopiuj"
                    onClick={() => {
                      navigator.clipboard.writeText(p.Value ?? '');
                    }}
                  />
                  {p.IsMain ? (
                    <StarIcon className={styles.icon} />
                  ) : (
                    <StarBorderIcon
                      onClick={() => {
                        updateMain(p);
                      }}
                      className={`${styles.icon} ${
                        selected !== p && styles.active
                      }`}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className={styles.group}>
        <div className={styles.label}>Adres E-Doręczeń</div>
        {!eaddresses.length && (
          <Empty
            updateData={updateData}
            actualEditType={type}
            data={data}
            setData={setData}
            type="eaddress"
            orginalIndex={orginalIndex}
            setEdit={setEdit}
            setOrginalIndex={setOrginalIndex}
            setType={setType}
          />
        )}
        {eaddresses.map((p, index) => {
          const isSame =
            selected?.Type !== p.Type || selected?.Value !== p.Value;

          return (
            <div className={styles.data}>
              {type === 'eaddress' && edit === index ? (
                <div className={styles.ticket}>
                  <Input
                    label="Adres E-Doręczeń"
                    value={p.Value}
                    onChange={(text) => {
                      const copy = JSON.parse(JSON.stringify(data));
                      copy.Data[orginalIndex].Value = text;
                      setData(copy);
                    }}
                    onAccept={() => {
                      updateData();
                      setOrginalIndex(0);
                      setEdit(null);
                      setType(null);
                    }}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      updateData();
                      setOrginalIndex(0);
                      setEdit(null);
                      setType(null);
                    }}
                    icon={faCheck}
                  />
                </div>
              ) : (
                p.Value
              )}
              {type !== 'eaddress' && (
                <div className={styles.icons}>
                  <EditIcon
                    title="Edytuj"
                    onClick={() => {
                      setEdit(index);
                      setType('eaddress');
                      const indexOf = data.Data.findIndex((b) => p === b);
                      setOrginalIndex(indexOf);
                    }}
                  />
                  <CopyIcon
                    title="Kopiuj"
                    onClick={() => {
                      navigator.clipboard.writeText(p.Value ?? '');
                    }}
                  />
                  {p.IsMain ? (
                    <StarIcon className={styles.icon} />
                  ) : (
                    <StarBorderIcon
                      onClick={() => {
                        updateMain(p);
                      }}
                      className={`${styles.icon} ${
                        selected !== p && styles.active
                      }`}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {type === 'address' && (
        <EditAddressModal
          data={data}
          orginalIndex={orginalIndex}
          setData={setData}
          toggle={() => {
            setOrginalIndex(0);
            setEdit(null);
            setType(null);
          }}
          onAccept={() => {
            updateData();
            setOrginalIndex(0);
            setEdit(null);
            setType(null);
          }}
        />
      )}
    </div>
  );
};

export default PersonalDataClient;
