import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import Chip from 'components/Chip';

const StatusBadge: FC<Props> = ({
  status,
  onRemove,
  showName = false,
  allowRemove = true,
}) => {
  const getColor = () => {
    switch (status.Color) {
      case 0:
        return '#E89F39';
      case 1:
        return '#9A48D0';
      case 2:
        return '#50A7DE';
      case 3:
        return '#F05D5E';
      case 4:
        return '#F0E37D';
      case 5:
        return '#D9017E';
      case 6:
        return '#49C6AB';
      case 7:
        return '#BAB26D';
      default:
        return '#E89F39';
    }
  };

  if (showName) {
    return (
      <Chip
        className={{
          background: 'red !important',
        }}
        small={true}
        element={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <svg
              className={styles.bad}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_54775_65726"
                // style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="white" />
              </mask>
              <g mask="url(#mask0_54775_65726)">
                <path
                  d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5Z"
                  fill={getColor()}
                />
              </g>
            </svg>

            {status.Name}
          </div>
        }
        onDelete={
          onRemove
            ? () => {
                onRemove(status);
              }
            : undefined
        }
      />
    );
  }

  return (
    <div
      title={status.Name}
      style={{
        background: 'transparent',
        border: 'solid 1px transparent',
      }}
      className={styles.tag}
    >
      <svg
        className={styles.bad}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_54775_65726"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="white" />
        </mask>
        <g mask="url(#mask0_54775_65726)">
          <path
            d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5Z"
            fill={getColor()}
          />
        </g>
      </svg>
    </div>
  );
};

export default StatusBadge;
