import SecondCheckbox from 'components/SecondCheckbox';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Avatar from 'components/Avatar';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { ITag } from 'dto/ITag';
import Chip from 'components/Chip';
import tagsService from 'api/tagsService';
import { ReactComponent as ChatIcon } from 'assets/icons/chat_bubble_outline.svg';
import sanitize from 'sanitize-html';
import taskService, { IUpdateTaskPayload } from 'api/taskService';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TaskCard: FC<Props> = ({ setTask, task, onClick }) => {
  const { data: tagsData } = tagsService.useGetTags();
  const { mutate: updateTask } = taskService.useUpdateEvent();
  const [expanded, setExpanded] = useState(false);
  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const tags: ITag[] = useMemo(
    () =>
      tagsList.filter((t: ITag) => task.TagIds.find((tt) => tt === t.Id)) ?? [],
    [task, tagsList]
  );

  const handleUpdate = (tag: ITag) => {
    const payload: IUpdateTaskPayload = {
      Allday: task.AllDay,
      FromDate: task.FromDate,
      Id: task.Id,
      IsFavourite: task.IsFavourite,
      Note: task.Note,
      OwnerId: task.OwnerId,
      OwnerKind: task.Kind,
      TagIds: tags.filter((t) => t.Id !== tag.Id).map((t) => t.Id),
      ToDate: task.ToDate,
      Title: task.Title,
    };

    updateTask(payload, {
      onSuccess: (response) => {
        setTask(response.data);
      },
    });
  };

  return (
    <div
      onClick={onClick}
      className={`${styles.card} ${!!task.CompleteDate && styles.end}`}
    >
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>
            <SecondCheckbox
              value={!!task.CompleteDate}
              className={styles.checkbox}
              onChange={() => {}}
            />
            {task.Title}
          </div>
          <div className={styles.time}>
            <Avatar className={styles.avatar} name={task.UserLabel ?? ' '} />{' '}
            {task.UserLabel} | Utworzone - {''}
            {moment(task.FromDate).format('YYYY.MM.DD HH:mm')}
          </div>
        </div>
        <div className={styles.body}>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(task.Note) }}
              className={styles.content}
            ></div>
          </div>
          <div className={styles.icons}>
            <ChatIcon className={styles.icon} />
          </div>
        </div>
        <div className={styles.tags}>
          {tags.map((tag) => (
            <Chip
              element={<>{tag.Name}</>}
              onDelete={() => {
                handleUpdate(tag);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
