import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_l.svg';
import settingService from 'api/settingsService';
import Input from 'components/Input';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import { getErrorMessage } from 'utils/getErrorMessage';

const ChangePassword: FC<Props> = ({ onCanceled, onSaved }) => {
  const [passwords, setPasswords] = useState({
    currentPass: '',
    newPass: '',
    newPassRepeat: '',
  });
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutate: updatePassword } = settingService.useSetPassword();

  const save = () => {
    updatePassword(
      {
        OldPassword: passwords.currentPass,
        NewPassword: passwords.newPass,
        RepeatPassword: passwords.newPassRepeat,
      },
      {
        onSuccess(data, variables, context) {
          onSaved();
        },
        onError(e: any) {
          setError(getErrorMessage(e.response.data));
        },
      }
    );
  };

  useEffect(() => {
    if (
      passwords.currentPass.length === 0 ||
      passwords.newPass.length < 8 ||
      passwords.newPassRepeat.length < 8
    ) {
      setIsValid(false);
      setError(null);
      return;
    }

    if (passwords.newPass !== passwords.newPassRepeat) {
      setIsValid(false);
      setError('Hasła nie są takie same');
      return;
    }

    setIsValid(true);
    setError(null);
  }, [passwords.currentPass, passwords.newPass, passwords.newPassRepeat]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.pathBar}>
        <div className={styles.backButton}>
          <BackIcon onClick={onCanceled}></BackIcon>
        </div>
        Dane podstawowe <ArrowLeftIcon></ArrowLeftIcon> Zmiana hasła
      </div>

      <div className={styles.hint}>
        Aby zmienić swoje hasło wprowadź obecne hasło oraz podwójnie nowe hasło.
        Nowe hasło musi się składać z co najmniej ośmiu znaków, w tym
        jednocześnie liter, cyfr i symboli.
      </div>
      <Input
        type="password"
        label="Aktualne hasło"
        onChange={(v) => setPasswords({ ...passwords, currentPass: v })}
        value={passwords.currentPass}
        name="currentPassword"
      ></Input>
      <div className={styles.group}>
        <Input
          type="password"
          label="Nowe hasło"
          onChange={(v) => setPasswords({ ...passwords, newPass: v })}
          value={passwords.newPass}
          name="newPassword"
        ></Input>
        <Input
          type="password"
          label="Powtórz hasło"
          onChange={(v) => setPasswords({ ...passwords, newPassRepeat: v })}
          value={passwords.newPassRepeat}
          name="newPassword"
        ></Input>
        {error && <div className={styles.warn}>{error}</div>}
      </div>
      <div className={styles.buttons}>
        <HollowButton text="Anuluj" onClick={onCanceled}></HollowButton>
        <Button text="Zapisz" onClick={save} disabled={!isValid}></Button>
      </div>
    </div>
  );
};

export default ChangePassword;
