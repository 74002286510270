import tagsService from 'api/tagsService';
import styles from './styles.module.scss';
import { FC, useEffect, useMemo, useState, useRef } from 'react';
import InputWithRightIcon from 'components/InputWithRightIcon';
import { Props } from './typings';
import { ITag } from 'dto/ITag';
import casesService from 'api/casesService';
import TagBadge from 'components/TagBadge';
import { IEntity } from 'dto/IEntity';
import { ReactComponent as AddressIcon } from 'assets/icons/address.svg';
import { useDebounce } from 'use-debounce';

const SearchEntities: FC<Props> = ({
  label,
  doNotRemove = false,
  onChoose,
  className,
  isError = '',
  removeFocus,
  handleClickCreate,
}) => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');
  const [searchValue] = useDebounce(value, 300);
  const [searchList, setSearchList] = useState<IEntity[]>([]);
  const { data: entitiesData } = casesService.useGetAddressBook(searchValue);
  const entities: IEntity[] = useMemo(
    () => entitiesData?.data ?? [],
    [entitiesData]
  );

  useEffect(() => {
    setSearchList(entities);
  }, [entities]);

  useEffect(() => {
    if (!value.length) {
      setSearchList(entities);
      return;
    }

    // const filters = entities.filter(
    //   (c) =>
    //     c?.Client?.search(value) !== -1 ||
    //     c?.Title?.search(value) !== -1 ||
    //     c?.Signature?.search(value) !== -1
    // );
    // setSearchList(entities);
  }, [value]);

  const handleClick = (cas: IEntity) => {
    if (doNotRemove) {
      setValue(cas.Label ?? '');
    } else {
      setValue('');
    }
    setActive(false);
    onChoose?.(cas);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target as Element)) {
        setActive(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    setActive(true);
  }, [ref]);

  return (
    <div ref={ref} className={`${styles.wrapper} ${className}`}>
      <div className={styles.focus}>
        <InputWithRightIcon
          focus={removeFocus ? false : true}
          onClick={() => setActive(true)}
          value={value}
          isError={isError}
          onChange={(text) => setValue(text)}
          label={label ? label : 'Podmioty'}
        />
        {handleClickCreate && (
          <div onClick={handleClickCreate} className={styles.text}>
            Utwórz podmiot
          </div>
        )}
      </div>

      {!!searchList.length && active && (
        <div className={styles.list}>
          <div className={styles.listWrapper}>
            {searchList.map((c) => (
              <div
                onClick={() => handleClick(c)}
                key={c.Id}
                className={styles.box}
              >
                <div title={c.Label} className={styles.client}>
                  {c.Label}
                </div>

                <div className={styles.title}>{c.Address}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchEntities;
