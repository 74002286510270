import deskService from 'api/deskService';
import listService from 'api/listService';
import messagesService, { IChangeHandle } from 'api/messagesService';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as AttachIcon } from 'assets/icons/case.svg';
import { ReactComponent as CompleteTask } from 'assets/icons/check.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag_border.svg';
import { ReactComponent as HashIcon } from 'assets/icons/hash2.svg';
import { ReactComponent as ReplyIcon } from 'assets/icons/reply_all.svg';
import { ReactComponent as UnCompleteIcon } from 'assets/icons/unhandled.svg';

import { ReactComponent as TagIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as RedirectIcon } from 'assets/icons/reply_all.svg';

import { ReactComponent as SharedIcon } from 'assets/icons/share.svg';
import AddTagModal from 'components/AddTagModal';
import AttachCaseModal from 'components/AttachCaseModal';
import ChatActionsList from 'components/ChatActionList';
import CreateActionModal from 'components/CreateActionModal';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import StatusModal from 'components/StatusModal';
import ModalsManagerContext from 'contexts/ModalsManager';
import PackagesContext from 'contexts/PackagesContext';
import { IPackage } from 'dto/IPackage';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { axiosInstance } from 'api/axios';
import ConfirmModal from 'components/ConfirmModal';
import { RedirectModal } from 'components/RedirectModal';
import KeysContext from 'contexts/KeysContext';
import { ITag } from 'dto/ITag';
import { IWorkspaceMember } from 'dto/IWorkspace';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { generateNewKey } from 'utils/geneareNewKey';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { ENotificationType, notification } from 'utils/notification';
import { readAsBase64 } from 'utils/readAsBase64';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import styles from './styles.module.scss';
import { ReactComponent as SignIcon } from 'assets/icons/sign.svg';

import { ReactComponent as NewIcon } from 'assets/icons/new_tab.svg';
import pluginService from 'api/pluginService';
import CertContext from 'contexts/CertContext';
import ActionModal from 'components/ActionModal';
import { reEncrypt } from 'utils/reEncrypt';
import ChooseTagNew from 'components/ChooseTagNew';
import SelectFlagNew from 'components/SelectFlagNew';
import tagsService from 'api/tagsService';
import { IStatus } from 'dto/IStatus';
import statusService from 'api/statusService';
import { Box } from '@mui/material';
import CreateTaskModal from 'components/CreateTaskModal';

const OptionsCard = ({
  refWrapper,
  pack,
  showOptions,
  toggle,
  onClickAttachIcon,
  className = '',
  refetch,
  remove,
  selectedElements,
  debug = false,
  rightSide = false,
  isCase = false,
  binds = false,
  dropdown = false,
  scrollWrapper,
  setSelectedElements,
  content = '',
}: {
  binds?: boolean;
  className?: string;
  toggle?: () => void;
  refWrapper: RefObject<HTMLDivElement>;
  scrollWrapper?: RefObject<HTMLDivElement>;
  pack: IPackage;
  showOptions: boolean;
  selectedElements: IPackage[];
  onClickAttachIcon: (state: boolean) => void;
  refetch: () => void;
  debug?: boolean;
  isCase?: boolean;
  dropdown?: boolean;
  rightSide?: boolean;
  remove?: () => void;
  setSelectedElements?: (selectedElement: IPackage[]) => void;
  content?: string;
}) => {
  const {
    filterId,
    status,
    linkStatus,
    kind,
    packageId,
    threadId,
    threadPackageId,
  } = useParams();

  const isOutcoming = status === 'outcoming';
  const isLetters = status === 'pisma';
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const navigate = useNavigate();
  const { mutate: changeHandle } = messagesService.useChangeHandle();
  const { mutate: redirect } = messagesService.useRedirect();
  const { mutate: share } = messagesService.useShare();
  const { mutate: setDesk } = deskService.useSetDesk();
  const { mutate: deletePackage } = listService.useDeleteList();
  const { mutate: restore } = listService.useRestorePackage();
  const { mutate: editTags } = tagsService.useAddTag();
  const { mutate: setStatus } = statusService.useSetStatus();

  const { mutate: openEditorPlugin } = pluginService.useOpenEditor();

  const [showActions, setShowActions] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const { packages, setPackages } = useContext(PackagesContext);
  const [showFlags, setShowFlags] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showRedirect, setShowRedirect] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showTask, setShowTask] = useState(false);

  const closeModals = (e: any) => {
    if (e.key === 'Escape') {
      setShowActions(false);
      setShowCreateAction(false);
      setShowAttach(false);
      setShowTags(false);
      setShowFlags(false);
      setShowDelete(false);
      setShowRedirect(false);
      setShowShare(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', closeModals);

    return () => {
      window.removeEventListener('keydown', closeModals);
    };
  }, []);

  useEffect(() => {
    if (!showActions) return;
    setShowAttach(false);

    setShowCreateAction(false);
    setShowTags(false);
    setShowFlags(false);
    setShowDelete(false);
    setShowRedirect(false);
    setShowShare(false);
  }, [showActions]);

  useEffect(() => {
    if (!showCreateAction) return;
    setShowActions(false);
    setShowAttach(false);
    setShowTags(false);
    setShowFlags(false);
    setShowDelete(false);
    setShowRedirect(false);
    setShowShare(false);
  }, [showCreateAction]);

  useEffect(() => {
    if (!showAttach) return;
    setShowActions(false);
    setShowCreateAction(false);
    setShowTags(false);
    setShowFlags(false);
    setShowDelete(false);
    setShowRedirect(false);
    setShowShare(false);
  }, [showAttach]);

  useEffect(() => {
    if (!showTags) return;
    setShowActions(false);
    setShowCreateAction(false);
    setShowAttach(false);
    setShowFlags(false);
    setShowDelete(false);
    setShowRedirect(false);
    setShowShare(false);
  }, [showTags]);

  useEffect(() => {
    if (!showFlags) return;
    setShowActions(false);
    setShowCreateAction(false);
    setShowAttach(false);
    setShowTags(false);
    setShowDelete(false);
    setShowRedirect(false);
    setShowShare(false);
  }, [showFlags]);

  useEffect(() => {
    if (!showDelete) return;
    setShowActions(false);
    setShowCreateAction(false);
    setShowAttach(false);
    setShowTags(false);
    setShowFlags(false);
    setShowRedirect(false);
    setShowShare(false);
  }, [showDelete]);

  useEffect(() => {
    if (!showRedirect) return;
    setShowActions(false);
    setShowCreateAction(false);
    setShowAttach(false);
    setShowTags(false);
    setShowFlags(false);
    setShowDelete(false);
    setShowShare(false);
  }, [showRedirect]);

  useEffect(() => {
    if (!showShare) return;
    setShowActions(false);
    setShowCreateAction(false);
    setShowAttach(false);
    setShowTags(false);
    setShowFlags(false);
    setShowDelete(false);
    setShowRedirect(false);
  }, [showShare]);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (showOptions) {
      toggle?.();
    }
  });

  const { modals, setModals } = useContext(ModalsManagerContext);

  const { keys } = useContext(KeysContext);

  const handleClick = (e: any) => {
    if (e.code === 'Delete' && showOptions && binds) {
      handleDeletePackage();
    }
  };

  const openEditor = () => {
    openEditorPlugin({
      Id: pack.Id,
      Kind: pack.Kind,
    });
  };

  const { setCert } = useContext(CertContext);

  const openCert = () => {
    setCert({ id: pack.Id, name: pack.S1 });

    const isIncoming = status === 'incoming';
    const link = linkStatus
      ? linkStatus
      : isIncoming === true
      ? 'incoming'
      : 'outcoming';

    const isOutcoming = link === 'outcoming' ? 'sending' : filterId;
    const msg = pack;
    navigate(
      `/packages/${filterId}/pisma/${msg.Kind}/${msg.Id}/preview-letter/${msg.Id}`
    );
  };

  useEffect(() => {
    window.addEventListener('keydown', handleClick);

    return () => {
      window.removeEventListener('keydown', handleClick);
    };
  }, [showOptions, selectedElements]);

  const handleScroll = () => {
    const position = refWrapper?.current?.getBoundingClientRect?.();
    if (!position || !showOptions) return;

    const newX = position.x + position.width / 2;
    let newY = position.y - 14;

    if (newY <= 130) {
      newY = 130;
    }

    setX(newX);
    setY(newY);
  };

  useEffect(() => {
    if (
      !scrollWrapper?.current ||
      !showOptions ||
      !(selectedElements.length >= 2)
    )
      return;
    scrollWrapper.current.addEventListener('scroll', handleScroll);
    return () =>
      scrollWrapper?.current?.removeEventListener('scroll', handleScroll);
  }, [scrollWrapper, showOptions, selectedElements]);

  useEffect(() => {
    // if (!scrollWrapper?.current) return;
    if (!showOptions) return;
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [showOptions]);

  useEffect(() => {
    setShowActions(false);
    setShowCreateAction(false);
    setShowAttach(false);
    setShowTags(false);
    setShowFlags(false);
    setShowDelete(false);
  }, [packageId]);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const position = refWrapper?.current?.getBoundingClientRect?.();

    if (!position) return;
    if (!dropdown) {
      if (rightSide) {
        const newX = position.x + position.width - 180;
        let newY = position.y + 5;
        setX(newX);
        setY(newY);
        return;
      }

      const newX = position.x + position.width / 2;
      let newY = position.y - 14;

      if (newY <= 80) {
        newY = 80;
      }

      setX(newX);
      setY(newY);
      return;
    }

    const newX = position.x + position.width - 32;
    let newY = position.y + 36;

    setX(newX);
    setY(newY);
  }, [showOptions, dropdown, refWrapper]);
  if (!refWrapper?.current) return <></>;

  const backToList = () => {
    if (selectedElements.length > 1) {
      selectedElements.forEach((p) => {
        if (
          packageId === p.Id ||
          threadId === p.Id ||
          threadPackageId === p.Id
        ) {
          navigate(`/packages/${filterId}/${status}`);

          return;
        }
      });

      return;
    }

    if (
      packageId === pack.Id ||
      threadId === pack.Id ||
      threadPackageId === pack.Id
    ) {
      const index = packages.findIndex(
        (p) =>
          packageId === pack.Id ||
          threadId === pack.Id ||
          threadPackageId === pack.Id
      );

      let nextPackage: IPackage | undefined = packages[index + 1];

      if (!nextPackage) {
        return;
      }

      const needToConfirm = nextPackage.Kind === 4 && !nextPackage.ReceiveDate;

      if (needToConfirm) {
        nextPackage = packages.find(
          (p, i) => i > index && p.Kind === 4 && !!p.ReceiveDate
        );
      }

      if (!nextPackage) {
        return;
      }

      navigate(
        `/packages/${filterId}/${status}/${nextPackage.Kind}/${nextPackage.Id}`
      );

      // navigate(`/packages/${filterId}/${status}`);
    }
  };

  const showAttachIcon = !pack.Case && pack.IsIncoming;
  // const showSomething = true;
  const getPosition = (refWrapper: RefObject<HTMLDivElement>) => {
    if (!refWrapper?.current) return { x: 0, y: 0, width: 0, height: 0 };
    const position = refWrapper?.current?.getBoundingClientRect?.();
    return {
      x: position.x ?? 0,
      y: position.y ?? 0,
      width: position.width ?? 0,
      height: position.height ?? 0,
    };
  };

  const handleClickComplete = () => {
    if (selectedElements.length > 1) {
      let data: IChangeHandle = {
        Handled: true,
        Items: selectedElements.map((s) => ({
          Id: s.Id,
          Kind: s.Kind,
        })),
      };

      let newPacks = [...packages];

      const newItem = newPacks.find((p) => p.Id === pack.Id);

      if (newItem) {
        newItem.IsHandled = !newItem.IsHandled;
      }

      if (!isCase) {
        newPacks = newPacks.filter(
          (p) => !selectedElements.find((s) => s.Id === p.Id)
        );
      }

      setPackages(newPacks);

      changeHandle(data, {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      });
      backToList();
      return;
    }

    let data: IChangeHandle = {
      Handled: !pack.IsHandled,
      Items: [{ Id: pack.Id, Kind: pack.Kind }],
    };

    let newPacks = [...packages];
    const newItem = newPacks.find((p) => p.Id === pack.Id);

    if (newItem) {
      newItem.IsHandled = !newItem.IsHandled;
    }

    if (!isCase) {
      newPacks = newPacks.filter((p) => p.Id !== pack.Id);
    }

    setPackages(newPacks);

    backToList();
    changeHandle(data, {
      onSuccess: () => refetch(),
      onError: () => {
        notification({
          type: ENotificationType.ERROR,
          title: 'Wystąpił błąd',
          text: 'Coś poszło nie tak... spróbuj ponownie później',
        });
      },
    });
  };

  const handleDeletePackage = () => {
    let newPacks = [...packages];

    let elementsToDelete = [];
    if (selectedElements.length > 1) {
      setSelectedElements?.([]);
      const lastElement = selectedElements[selectedElements.length - 1];
      const index = packages.findIndex((p) => p.Id === lastElement.Id);
      const el = packages[index + 1];

      if (!el) {
        navigate(`/packages/${filterId}/${status}`);
      } else {
        navigate(`/packages/${filterId}/${status}/${el.Kind}/${el.Id}`);
      }

      for (const p of selectedElements) {
        const o = newPacks.find((op) => op.Id === p.Id);
        if (o) {
          o.isDelete = true;
        }
      }

      setPackages(newPacks);

      setSelectedElements?.([el]);
      elementsToDelete = selectedElements.map((m) => ({
        Id: m.Id,
        Kind: m.Kind,
      }));
    } else {
      const index = packages.findIndex((p) => p.Id === pack.Id);

      const editPack = newPacks.find((f) => f.Id === pack.Id);
      if (editPack) {
        editPack.isDelete = true;
      }

      setPackages(newPacks);
      elementsToDelete = [{ Id: pack.Id, Kind: pack.Kind }];
      const el = packages[index + 1];

      if (el) {
        setSelectedElements?.([el]);
      }

      if (!el) {
        navigate(`/packages/${filterId}/${status}`);
      } else {
        navigate(`/packages/${filterId}/${status}/${el.Kind}/${el.Id}`);
      }
    }

    deletePackage(
      {
        Items: elementsToDelete,
      },
      {
        onSuccess: () => {
          // refetch();
          remove?.();
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  const handleAddToDesk = () => {
    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);

    if (el) {
      el.IsDeskActive = !el.IsDeskActive;
      setPackages(newPacks);
    }

    setDesk(
      {
        Items: [{ Id: pack.Id, Kind: pack.Kind }],
        Show: el!.IsDeskActive,
      },
      {
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  const handleClickReply = (forward: boolean) => {
    setModals({ ...modals, createPost: true });

    const isDouble = pack.Sender.search('<') !== -1;

    let email = pack.Sender;

    if (isDouble) {
      email = email.split('<')[1].replaceAll('>', '');
    }

    const data = {
      id: pack.Id,
      kind: pack.Kind,
      email: email,
      title: pack.S1,
      case: pack.Case,
    };

    if (!forward) {
      sessionStorage.setItem('reply', JSON.stringify(data));
      sessionStorage.setItem('msg', content);
      sessionStorage.setItem('sendTo', data.email);
      sessionStorage.setItem('type', 'reply');
    } else {
      sessionStorage.setItem('forward', JSON.stringify(data));
      sessionStorage.setItem('msg', content);
      sessionStorage.setItem('type', 'forward');
    }
  };

  const handleCreateAction = () => {
    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      el.TasksCount = el.TasksCount + 1;
      setPackages(newPacks);
    }
    refetch();
  };

  const handleDeleteAction = () => {
    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      el.TasksCount = el.TasksCount - 1;
      setPackages(newPacks);
    }
    refetch();
  };

  const restorePackage = () => {
    let newPacks = [...packages];
    let elementsToDelete = [];
    if (selectedElements.length > 1) {
      setPackages(
        newPacks.filter((p) => !selectedElements.find((a) => a.Id === p.Id))
      );

      elementsToDelete = selectedElements.map((m) => ({
        Id: m.Id,
        Kind: m.Kind,
      }));
    } else {
      setPackages(newPacks.filter((p) => p.Id !== pack.Id));
      elementsToDelete = [{ Id: pack.Id, Kind: pack.Kind }];
    }

    backToList();

    restore(
      {
        Items: elementsToDelete,
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  const handleUpdateTags = (tags: ITag[]) => {
    const ids = tags.map((t) => t.Id);
    editTags(
      {
        NewTags: [],
        OwnerKind: pack.Kind,
        OwnerId: pack.Id,
        TagIds: [pack.TagIds!, ids!].flat(),
      },
      {
        onSuccess: (r) => {},
      }
    );

    let newPacks = [...packages];

    let el = newPacks.find((c) => c.Id === pack.Id && c.Kind === pack.Kind);

    if (el) {
      el.TagIds = [pack.TagIds!, ids!].flat();
      console.log(el);
    }

    setPackages(newPacks);
  };

  const handleChangeFlag = (status: IStatus) => {
    setStatus({
      OwnerId: pack.Id,
      OwnerKind: pack.Kind,
      StatusId: status.Id,
    });

    let newPacks = [...packages];

    let el = newPacks.find((c) => c.Id === pack.Id && c.Kind === pack.Kind);

    if (el) {
      el.StatusId = status.Id;
    }

    setPackages(newPacks);
  };

  const handleShare = async (member: IWorkspaceMember) => {
    const myKeys = await getKeys(userId!);
    const id = pack.Id;
    const Kind = pack.Kind;
    const encryptionKeyId = pack.EncryptionKeyId;

    if (!encryptionKeyId) {
      share(
        {
          Id: id,
          Kind: Kind,
          ToUserId: member.UserId,
        },
        {
          onSuccess: () => {
            setShowShare(false);
          },
        }
      );

      return;
    }

    const privateKey = await generateNewKey(encryptionKeyId, myKeys, keys);
    const uint = await reEncrypt(userId, member.UserId, privateKey);
    const readyToSend = await readAsBase64(uint);

    share(
      {
        Id: id,
        Kind: Kind,
        ToUserId: member.UserId,
        Keys: [{ Id: encryptionKeyId!, Key: readyToSend }],
      },
      {
        onSuccess: () => {
          setShowShare(false);
          let newPacks = [...packages];
          let newPack = newPacks.find((p) => p.Id === pack.Id);
          if (!newPack) {
            return;
          }

          newPack.IsShared = true;

          setPackages(newPacks);
        },
      }
    );
  };

  const buildIcons = () => {
    const isWithCase = selectedElements.find((s) => s.Case);

    if (selectedElements.length > 1) {
      return (
        <>
          {/* {pack.IsHandled ? (
            <UnCompleteIcon
              title="Oznacz jako niegotowe"
              onClick={handleClickComplete}
            />
          ) : (
            <CompleteTask
              title="Oznacz jako gotowe"
              onClick={handleClickComplete}
            />
          )} */}

          {!isWithCase && !pack.IsRemoved && (
            <AttachIcon
              title="Powiąż z teczką"
              onClick={() => {
                setShowAttach(!showAttach);
                setShowActions(false);
                setShowTags(false);
                setShowFlags(false);
              }}
            />
          )}

          {!pack.IsRemoved && (
            <FlagIcon
              title="Ustaw status"
              onClick={() => {
                setShowFlags(true);
                toggle?.();
              }}
            />
          )}
          {pack.IsRemoved ? (
            <BinIcon
              title="Przywróć"
              onClick={() => {
                restorePackage();
                toggle?.();
              }}
            />
          ) : (
            <BinIcon
              title="Usuń"
              onClick={() => {
                const ask = localStorage.getItem('ask');
                console.log('ask1');
                if (ask === 'true') {
                  handleDeletePackage();
                  return;
                }

                setShowDelete(true);
                toggle?.();
              }}
            />
          )}
        </>
      );
    }

    return (
      <>
        {isLetters && (
          <TagIcon
            title="Edytuj pismo"
            onClick={() => {
              openEditor();
              toggle?.();
            }}
          />
        )}
        {isLetters && !pack.S4 && (
          <SignIcon
            title="Podpisz elektronicznie"
            onClick={() => {
              openCert();
              toggle?.();
            }}
          />
        )}
        {!pack.Case && !pack.IsRemoved && (
          <AttachIcon
            title="Powiąż z teczką"
            onClick={() => {
              setShowAttach(!showAttach);
              setShowActions(false);
              setShowTags(false);
              toggle?.();
              setShowFlags(false);
            }}
          />
        )}
        {!pack.IsRemoved && (
          <CalendarIcon
            title="Utwórz wydarzenie"
            onClick={() => {
              setShowCreateAction(!showCreateAction);
              setShowAttach(false);
              setShowTags(false);
              toggle?.();
              setShowFlags(false);
            }}
          />
        )}
        {!pack.IsRemoved && (
          <CalendarIcon
            title="Utwórz zadanie"
            onClick={() => {
              setShowTask(!showTask);
              setShowAttach(false);
              setShowTags(false);
              toggle?.();
              setShowFlags(false);
            }}
          />
        )}
        {/* {pack.IsHandled ? (
          <UnCompleteIcon
            title="Oznacz jako niegotowe"
            onClick={handleClickComplete}
          />
        ) : (
          <CompleteTask
            title="Oznacz jako gotowe"
            onClick={handleClickComplete}
          />
        )} */}
        {!pack.IsRemoved && (
          <DeskIcon
            title="Przypnij"
            onClick={() => {
              handleAddToDesk();
              toggle?.();
            }}
          />
        )}
        {!pack.IsRemoved && (
          <HashIcon
            title="Taguj"
            onClick={() => {
              setShowTags(!showTags);
              toggle?.();
              setShowActions(false);
              setShowAttach(false);
              setShowFlags(false);
            }}
          />
        )}
        {!isOutcoming && !pack.IsRemoved && (
          <FlagIcon
            onClick={() => {
              setShowFlags(true);
              setShowTags(false);
              setShowActions(false);
              toggle?.();
              setShowAttach(false);
            }}
            title="Ustaw status"
          />
        )}
        {pack.Kind === 128 && !pack.IsRemoved && (
          <ReplyIcon
            title="Odpowiedz"
            onClick={() => {
              handleClickReply(false);
              toggle?.();
            }}
          />
        )}
        {pack.Kind === 128 && !pack.IsRemoved && (
          <ReplyIcon
            className={styles.reverse}
            title="przekaż"
            onClick={() => {
              handleClickReply(true);
              toggle?.();
            }}
          />
        )}
        {pack.IsRemoved ? (
          <BinIcon
            title="Przywróć"
            onClick={() => {
              restorePackage();
              toggle?.();
            }}
          />
        ) : (
          <BinIcon
            title="Usuń"
            onClick={() => {
              const ask = localStorage.getItem('ask');
              console.log('ask2');
              if (ask === 'true') {
                handleDeletePackage();
                return;
              }

              setShowDelete(true);
              toggle?.();
            }}
          />
        )}
        {pack.Kind !== 128 && !pack.IsRemoved && (
          <RedirectIcon
            className={styles.reverse}
            title="Przekaż"
            onClick={() => {
              setShowRedirect(true);
              toggle?.();
            }}
          />
        )}
        {pack.Kind !== 128 && !pack.IsRemoved && (
          <SharedIcon
            title="Udostepnij"
            onClick={() => {
              setShowShare(true);
              toggle?.();
            }}
          />
        )}

        {!rightSide && (
          <NewIcon
            onClick={() => {
              toggle?.();
              window.open(
                `${
                  process.env.REACT_APP_SITE ??
                  'https://alfa.pocztaprawnicza.pl'
                }/packages/fullscreen/${pack.Id}/${pack.Kind}`
              );
            }}
          />
        )}
      </>
    );
  };

  const buildDropdown = () => {
    return (
      <>
        {!pack.Case && (
          <div
            onClick={() => {
              setShowAttach(!showAttach);
              setShowActions(false);
              setShowTags(false);
              setShowFlags(false);

              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <AttachIcon title="Powiąż z teczką" />
            Powiąż z teczką
          </div>
        )}
        <div
          onClick={() => {
            setShowActions(!showActions);
            setShowAttach(false);
            setShowTags(false);
            setShowFlags(false);

            toggle?.();
          }}
          className={styles.dropdownBox}
        >
          <CalendarIcon title="Czynności" />
          Czynności
        </div>

        {/* {pack.IsHandled ? (
          <div
            onClick={() => {
              handleClickComplete();
              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <UnCompleteIcon title="Oznacz jako niegotowe" />
            Niegotowe
          </div>
        ) : (
          <div
            onClick={() => {
              handleClickComplete();
              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <CompleteTask title="Oznacz jako gotowe" />
            Gotowe
          </div>
        )} */}
        <div className={styles.dropdownBox}>
          <DeskIcon
            title="Przypnij"
            onClick={() => {
              handleAddToDesk();
              toggle?.();
            }}
          />
          Przypnij
        </div>
        <div
          onClick={() => {
            setShowTags(!showTags);
            setShowActions(false);
            setShowAttach(false);
            setShowFlags(false);

            toggle?.();
          }}
          className={styles.dropdownBox}
        >
          <HashIcon title="Taguj" />
          Taguj
        </div>

        {!isOutcoming && (
          <div
            onClick={() => {
              setShowFlags(true);
              setShowTags(false);
              setShowActions(false);
              setShowAttach(false);

              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <FlagIcon title="Ustaw status" />
            Ustaw status
          </div>
        )}

        {pack.Kind === 128 && (
          <div
            onClick={() => {
              handleClickReply(false);
              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <ReplyIcon title="Odpowiedz" />
            Odpowiedz
          </div>
        )}
        {pack.Kind === 128 && (
          <div
            onClick={() => {
              handleClickReply(true);
              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <ReplyIcon className={styles.reverse} title="przekaż" />
            Przekaż
          </div>
        )}
        {pack.IsRemoved ? (
          <div
            onClick={() => {
              restorePackage();

              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <BinIcon title="Przywróć" />
            Przywróć
          </div>
        ) : (
          <div
            onClick={() => {
              setShowDelete(true);

              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <BinIcon title="Usuń" />
            Usuń
          </div>
        )}
        {pack.Kind !== 128 && (
          <div
            onClick={() => {
              setShowRedirect(true);
              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <RedirectIcon title="Przekaż" />
            Przekaż
          </div>
        )}
        {pack.Kind !== 128 && (
          <div
            onClick={() => {
              setShowShare(true);
              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <SharedIcon style={{ stroke: 'black' }} title="Udostepnij" />
            Udostępnij
          </div>
        )}
        <div
          onClick={() => {
            window.open(
              `${
                process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'
              }/packages/fullscreen/${pack.Id}/${pack.Kind}`
            );
          }}
          className={styles.dropdownBox}
        >
          <NewIcon />
          Otwórz w nowym oknie
        </div>
      </>
    );
  };

  return ReactDOM.createPortal(
    <div className={styles.wrap}>
      {!dropdown && (
        <div
          style={{
            left: `${x}px`,
            top: `${y - 7}px`,
            display: `${!showOptions ? 'none' : 'flex'}`,
          }}
          className={`${styles.options} ${
            !showOptions && styles.hide
          } ${className}`}
        >
          {buildIcons()}
        </div>
      )}
      {dropdown && (
        <div
          //@ts-ignore
          ref={outsideRef}
          style={{
            zIndex: 1000,
            right: 10,
            top: `${y - 3}px`,
            display: `${!showOptions ? 'none' : 'block'}`,
          }}
          className={styles.dropdown}
        >
          <div className={styles.dropdownWrapper}>{buildDropdown()}</div>
        </div>
      )}
      {showTags && (
        <ModalLayout
          dock={DockPosition.CENTER}
          refWrapper={refWrapper}
          onEnd={() => {
            setShowTags(false);
          }}
          disableMove
        >
          {/* <AddTagModal
            toggle={() => {
              setShowTags(false);
              toggle?.();
            }}
            updateTags={handleUpdateTags}
            toTag={pack}
          /> */}

          <ChooseTagNew
            onChoose={(tag) => {
              handleUpdateTags([tag!]);
            }}
          />
        </ModalLayout>
      )}
      {/* {showActions && (
        <ModalLayout refWrapper={refWrapper}>
          <div className={styles.modalee}>
            <ChatActionsList
              onDelete={handleDeleteAction}
              changeView={() => {
                setShowActions(false);
                sessionStorage.setItem(
                  'action',
                  `package:${pack.Id}:${pack.Kind}`
                );
                setModals({ ...modals, createAction: true });
                // setShowCreateAction(true);
              }}
              toggle={() => {
                toggle?.();
                setShowActions(false);
                setShowCreateAction(false);
              }}
              x={getPosition(refWrapper).x}
              y={getPosition(refWrapper).y}
              pack={pack}
            />
          </div>
        </ModalLayout>
      )} */}
      {showCreateAction && (
        <ActionModal onChange={() => {}} pack={pack} />
        // <ModalLayout refWrapper={refWrapper}>
        //   <div className={styles.modalee}>
        //     <CreateActionModal
        //       kind={pack.Kind}
        //       initialValue={{
        //         parcelId: pack.Id,
        //       }}
        //       showCase={false}
        //       refetch={() => {}}
        //       onCreate={handleCreateAction}
        //       toggle={() => {
        //         setShowCreateAction(false);
        //         toggle?.();
        //       }}
        //       x={getPosition(refWrapper).x + 150}
        //       y={getPosition(refWrapper).y}
        //     />
        //   </div>
        // </ModalLayout>
      )}
      {showTask && (
        <CreateTaskModal onClose={() => setShowTask(false)} pack={pack} />
      )}
      {showAttach && (
        <ModalLayout
          className={styles.disable}
          customX={-50}
          customY={-50}
          refWrapper={refWrapper}
          onEnd={() => {
            setShowAttach(false);
          }}
        >
          <AttachCaseModal
            Package={pack}
            Packages={selectedElements}
            // position={{
            //   x: getPosition(refWrapper).x,
            //   y: getPosition(refWrapper).y + 45,
            // }}
            refWrapper={null}
            // onLink={(c) => {
            //   let newPacks = [...packages];
            //   let newPack = newPacks.find((p) => p.Id === pack.Id);
            //   if (!newPack) {
            //     return;
            //   }

            //   newPack.Case = c;
            //   setPackages(newPacks);
            // }}
            toggle={() => {
              setShowAttach(false);
              toggle?.();
              remove?.();
            }}
          />
        </ModalLayout>
      )}
      {showFlags && (
        <>
          {selectedElements.length > 1 ? (
            <ModalLayout
              customX={0}
              customY={-30}
              refWrapper={refWrapper}
              onEnd={() => {
                setShowFlags(false);
              }}
            >
              {/* <StatusModal
                item={pack}
                toggle={() => {
                  setShowFlags(false);
                  toggle?.();
                }}
                items={selectedElements}
                selectedStatus={null}
              /> */}
              <SelectFlagNew onChoose={() => {}} selectedStatus={null} />
            </ModalLayout>
          ) : (
            <ModalLayout
              disableMove
              dock={DockPosition.CENTER_2}
              refWrapper={refWrapper}
              onEnd={() => {
                setShowAttach(false);
              }}
            >
              <SelectFlagNew
                initOpen
                disableControl
                onChoose={(flag) => {
                  handleChangeFlag(flag!);
                }}
                selectedStatus={null}
              />
            </ModalLayout>
          )}
        </>
      )}
      {showDelete && (
        <ConfirmModal
          title="Potwierdź usunięcie"
          text="Wykonanie akcji usunięcia przesyłki nieodwracanie usunie przesyłkę z listy"
          acceptLabel="Usuń"
          cancelLabel="Anuluj"
          onAccept={() => {
            setShowDelete(false);
            handleDeletePackage();
            toggle?.();
          }}
          onCancel={() => {
            setShowDelete(false);
            toggle?.();
          }}
        />
      )}
      {showRedirect && (
        <RedirectModal
          onCancel={() => setShowRedirect(false)}
          // onSelect={handleRedirect}
          pack={pack}
          toggle={() => {
            setShowRedirect(false);
            toggle?.();
          }}
        />
      )}
      {showShare && (
        <RedirectModal
          onCancel={() => {
            setShowShare(false);
            toggle?.();
          }}
          onSelect={handleShare}
        />
      )}
    </div>,
    document.body
  );
};

export default OptionsCard;
