import { faL } from '@fortawesome/free-solid-svg-icons';
import { DossierItemType } from 'dto/Dossier/DossierItemType';
import { IDossierItem } from 'dto/Dossier/IDossierItem';

export const createDossierObject = (
  id: string,
  fileName?: string,
  fileContentType?: string,
  fileSize?: number,
  parentId?: string,
  label?: string,
  type: DossierItemType = DossierItemType.Attachment
): IDossierItem => {
  return {
    Type: type,
    DossierId: crypto.randomUUID(),
    Id: id,
    ParentId: parentId,
    Kind: 32,
    CreationDate: new Date(),
    LastModified: new Date(),

    FileName: fileName,
    FileSize: fileSize,
    FileContentType: fileContentType,

    CommentsCount: 0,
    TagCount: 0,
    IsShared: false,
    DeskInfo_IsOn: false,
    progress: 0,
  };
};
