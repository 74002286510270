import { FC, Props } from './typings';
import styles from './styles.module.scss';
import classNames from 'classnames';
import settingService from 'api/settingsService';
import { useState, useEffect } from 'react';

import { ReactComponent as DraftIcon } from 'assets/icons/mode_edit.svg';

const Avatar: FC<Props> = ({ onClick, name, className, refetchTrigger }) => {
  const { mutate: getAvatar } = settingService.useGetAvatar();

  const [img, setImg] = useState<Blob | null>(null);
  const [init, setInit] = useState<boolean>(true);

  const refetch = () => {
    getAvatar(
      {},
      {
        onSuccess(data, variables, context) {
          setImg(data.data);
          setInit(false);
        },
        onError(error, variables, context) {
          setInit(false);
        },
      }
    );
  };

  useEffect(() => {
    refetch();
  }, [refetchTrigger]);

  const [showChange, setShowChange] = useState(false);

  const chars = name
    .split(' ')
    .filter((_, index) => index < 2)
    .map((word) => word.charAt(0))
    .join('');
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setShowChange(true)}
      onMouseLeave={() => setShowChange(false)}
      className={classNames(styles.avatar, className)}
    >
      {showChange && (
        <div className={styles.change}>
          <DraftIcon className={styles.ic} />
        </div>
      )}
      {img && <img src={URL.createObjectURL(img)} />}
      {!img && chars}
    </div>
  );
};

export default Avatar;
