import { Draggable, Droppable } from 'react-beautiful-dnd';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useContext } from 'react';
import ConverstationContext from 'contexts/ConversationContext';
import ChatCard from 'components/ChatCard';
import { useParams } from 'react-router-dom';
import KeysContext from 'contexts/KeysContext';

const Thread: FC<Props> = ({ thread, index }) => {
  const { caseId } = useParams();
  const { converstation } = useContext(ConverstationContext);
  const { keys, setKeys } = useContext(KeysContext);
  return (
    <div className={styles.container}>
      <Draggable
        index={index}
        draggableId={`THREAD:${thread.Id}`}
        key={`THREAD:${thread.Id}`}
      >
        {(prov, snapshot) => {
          return (
            <Droppable droppableId={`THREAD:${thread.Id}`}>
              {(provided, snapshot) => (
                <div
                  ref={prov.innerRef}
                  {...prov.dragHandleProps}
                  {...prov.draggableProps}
                >
                  <div
                    onClick={() => {
                      window.open(
                        `${
                          process.env.REACT_APP_SITE ??
                          'https://alfa.pocztaprawnicza.pl'
                        }/thread/${caseId}/${thread.Id}`
                      );
                    }}
                    ref={provided.innerRef}
                    className={styles.wrapper}
                  >
                    <div className={styles.title}>
                      <div className={styles.line}></div>
                      <div className={styles.wrap}>Wątek {thread.Label}</div>
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          );
        }}
      </Draggable>
      <Droppable droppableId={`THREAD:${thread.Id}`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={styles.draggable}>
            {converstation
              .filter((p) => p.Thread?.Id === thread.Id)
              .map((Package, index) => {
                return (
                  <ChatCard
                    index={index}
                    key={Package.Id}
                    editMode={false}
                    handleChangePackages={() => {}}
                    isSelected={false}
                    refetch={() => {}}
                    Package={Package}
                    statuses={[]}
                    tags={[]}
                    keys={keys}
                    onDownload={() => {}}
                    onPreview={() => {}}
                  />
                );
              })}
          </div>
        )}
      </Droppable>
    </div>
  );

  // return (
  //   <Droppable droppableId="threads2" type="threads2">
  //     {(provided, snapshot) => (
  //       <div ref={provided.innerRef} className={styles.wrapper}>
  //         <div className={styles.title}>
  //           <div className={styles.line}></div>
  //           <div className={styles.wrap}>Wątek {thread.Label}</div>
  //         </div>
  //       </div>
  //     )}
  //   </Droppable>
  // );
};

export default Thread;
