import { IPackage } from "dto/IPackage";
import { createContext } from "react";

type ICaseChatContext = {
    casePackages: IPackage[];
    setCasePackages: (packages: IPackage[]) => void;
}

const CaseChatContext = createContext<ICaseChatContext>({
    casePackages: [],
    setCasePackages: () => {}
})

export default CaseChatContext;