import { IAttachment } from 'dto/IAttachment';
import { IPackage } from 'dto/IPackage';

export type { FC } from 'react';

export enum ESendStatus {
  WAITING = 'waiting',
  PROCESSING = 'processing',
  ERROR = 'error',
  ERROR_NOT_RETRAYABLE = 'errorNotRetrayable',
  SENT = 'sent'

}

export interface Props {
  msg: IPackage;
  keys: any;
  customY?: number;
  showPDF?: Blob | string | null
  onPreview: (pack: IPackage, attachment: IAttachment) => void;
  onDownload: (pack: IPackage, attachment: IAttachment) => void;
  showCaseIcon?: boolean;
  withoutCase?:boolean;
  previewData?: any;
}