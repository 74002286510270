import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import ReactDOM from 'react-dom';
import Input from 'components/Input';
import Button from 'components/Button';

const EditAddressModal: FC<Props> = ({
  data,
  orginalIndex,
  setData,
  toggle,
  onAccept,
}) => {
  const add = data.Data[orginalIndex].Value;
  if (!add) return <></>;
  const json = JSON.parse(add);

  const handleChange = (type: string, text: string) => {
    let add = data.Data[orginalIndex].Value;
    const json = JSON.parse(add);

    switch (type) {
      case 'address':
        json.Address = text;
        json.address = text;
        return json;
      case 'zip':
        json.ZipCode = text;
        json.zipCode = text;
        return json;
      case 'city':
        json.City = text;
        json.city = text;
        return json;
    }

    add = JSON.stringify(json);

    const newData = JSON.parse(JSON.stringify(data));

    newData.Data[orginalIndex].Value = JSON.stringify(add);

    setData(newData);
  };

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.title}>
          Adres
          <CloseIcon onClick={toggle} />
        </div>
        <Input
          value={json.Address ?? json.address}
          onChange={(text) => {
            let t = handleChange('address', text);
            t = JSON.stringify(t);
            const copy = JSON.parse(JSON.stringify(data));
            copy.Data[orginalIndex].Value = t;

            setData(copy);
          }}
          label="Ulica"
        />
        <div className={styles.group}>
          <Input
            className={styles.input}
            value={json.ZipCode ?? json.zipCode}
            onChange={(text) => {
              let t = handleChange('zip', text);
              t = JSON.stringify(t);
              const copy = JSON.parse(JSON.stringify(data));
              copy.Data[orginalIndex].Value = t;

              setData(copy);
            }}
            label="Kod pocztowy"
          />

          <Input
            className={styles.input}
            value={json.City ?? json.city}
            onChange={(text) => {
              let t = handleChange('city', text);
              t = JSON.stringify(t);
              const copy = JSON.parse(JSON.stringify(data));
              copy.Data[orginalIndex].Value = t;

              setData(copy);
            }}
            label="Miasto"
          />
        </div>
        <div className={styles.btn}>
          <Button
            onClick={() => {
              onAccept();
            }}
            text="Zapisz"
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default EditAddressModal;
