import MembershipCard from 'components/MembershipCard';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { IUserOffice } from 'dto/IUserOffice';
import { IWorkspaceMember } from 'dto/IWorkspace';
import AddButton from 'components/AddButton';
import AddWorker from 'components/Settings/AddWorker';
import { useEffect, useState } from 'react';
import Input from 'components/Input';

const WorkspaceMembers: FC<Props> = ({
  workspace,
  showAdd,
  setShowAdd,
  refetch,
  myPrivilages,
  roles,
  members,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const [showMembers, setShowMembers] = useState(members);

  useEffect(() => {
    if (searchValue.length > 0) {
      const searchedWorkers = members.filter(
        (worker) =>
          worker.Label.toLocaleLowerCase().search(
            searchValue.toLocaleLowerCase()
          ) !== -1
      );
      setShowMembers(searchedWorkers);
      return;
    }

    setShowMembers(members);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue('');
    setShowMembers(members);
  }, [members]);

  return (
    <>
      <div className={styles.group}>
        <div className={styles.title}>Twój zespół</div>
        <div className={styles.hint}></div>
      </div>
      <div className={styles.wrap}>
        <div className={styles.search}>
          <Input
            label="Wyszukaj"
            value={searchValue}
            onChange={(t) => setSearchValue(t)}
          />
        </div>
        <div className={styles.addButton}>
          {myPrivilages.CanInviteMembersToWorkspace && (
            <AddButton
              className={styles.add}
              text="Zaproś do zespołu"
              onClick={() => setShowAdd(true)}
            />
          )}
        </div>
        <div className={styles.list}>
          {showMembers
            .sort((a, b) => {
              if (a.IsMe && !b.IsMe) return -1;
              if (!a.IsMe && b.IsMe) return 1;

              if (a.IsMe === b.IsMe) {
                if (a.Status === 0 || b.Status === 0) {
                  return a.Status - b.Status;
                }

                const statusOrder = [0, 1, 4, 2, 3];
                const statusComparison =
                  statusOrder.indexOf(a.Status) - statusOrder.indexOf(b.Status);

                if (statusComparison === 0) {
                  return a.Label.localeCompare(b.Label);
                }

                return statusComparison;
              }

              return 0;
            })
            .map((worker: IWorkspaceMember) => (
              <MembershipCard
                structure={workspace.Structure}
                myPrivilages={myPrivilages}
                refetch={refetch}
                roles={roles}
                worker={worker}
              />
            ))}
        </div>
        {showAdd && (
          <AddWorker
            roles={roles}
            toggle={() => setShowAdd(false)}
            refetch={refetch}
            workspaceId={workspace.Id}
          />
        )}
      </div>
    </>
  );
};

export default WorkspaceMembers;
