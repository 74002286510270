import { EStatus, FC, Props } from './typings';
import styles from './styles.module.scss';
import classNames from 'classnames';

const Avatar: FC<Props> = ({ avatar, name, className, status }) => {
  if (!name?.length) return <></>;
  const chars = name
    .split(' ')
    .filter((_, index) => index < 2)
    .map((word) => word.charAt(0))
    .join('');
  return (
    <div className={classNames(styles.wrapper, className)}>
      {avatar && (
        <img src={avatar} className={classNames(styles.wrapper, className)} />
      )}
      {!avatar && (
        <div className={classNames(styles.avatar, className)}>{chars}</div>
      )}
      {status === EStatus.ONLINE && <div className={styles.status}></div>}
    </div>
  );
};

export default Avatar;
