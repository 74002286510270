import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Input from 'components/Input';
import { useState } from 'react';
import Button from 'components/Button';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import editorService from 'api/editorService';

const EditLetter: FC<Props> = ({ toggle, file, refetch }) => {
  const [value, setValue] = useState(file.S1);

  const { mutate: editDocument } = editorService.useEditDocument();

  const handleSave = () => {
    editDocument(
      {
        Id: file.Id,
        Title: value,
      },
      {
        onSuccess: () => {
          refetch();
          toggle(false);
        },
      }
    );
  };

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Pismo
          <CloseIcon onClick={() => toggle(false)} />
        </div>

        <div className={styles.input}>
          <Input label="Etykieta" onChange={(e) => setValue(e)} value={value} />
        </div>

        <div className={styles.button}>
          <Button onClick={handleSave} text="Zapisz" />
        </div>
      </div>
    </div>
  );
};

export default EditLetter;
