import Attachment from 'components/Attachment';
import {
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  RefObject,
} from 'react';
import { decryptPackage } from 'utils/decryptPackage';
import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import fileService from 'api/fileService';
import EmailService from 'api/emailService';
import { IAttachment } from 'dto/IAttachment';
import { getBlob } from 'utils/getBlob';
import { decryptFile } from 'utils/decryptFile';
import downloadFile from 'utils/downloadFile';
import Avatar from 'components/Avatar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import PackageCaseModal from 'components/PackageCaseModal';
import messagesService, { IChangeHandle } from 'api/messagesService';
import listService from 'api/listService';
import ChatActionsList from 'components/ChatActionList';
import CreateActionModal from 'components/CreateActionModal';
import deskService from 'api/deskService';
import ReactDOM from 'react-dom';
import AddTagModal from 'components/AddTagModal';
import AttachCaseModal from 'components/AttachCaseModal';
import ModalsManagerContext from 'contexts/ModalsManager';
import { getTypeOfParcelFromKind } from 'utils/getTypeOfParcel';
import { IParcelTypes } from 'dto/IParcelTypes';
import IconsState from 'components/IconsState';
import PackagesContext from 'contexts/PackagesContext';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { addToUrl, hasImages, parseHTML } from 'utils/parseHTML';
import { decodeAndFetchImages } from 'utils/decodeAndFetchImages';
import { getKeys } from 'utils/getKeys';
import EmailViewer from 'components/EmailViewer';
import { ReactComponent as GroupIcon } from 'assets/icons/groups.svg';
import ModalLayout from 'components/ModalLayout';
import PackageReceiversModal from 'components/PackageReceiversModal';

const ModalWrapper = ({
  refWrapper,
  children,
}: {
  refWrapper: RefObject<HTMLDivElement>;
  children: any;
}) => {
  if (!refWrapper?.current) return <></>;
  const position = refWrapper.current!.getBoundingClientRect();
  const x = position.x;
  const y = position.y + 45;

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`,
        zIndex: 1000,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

const PackThreadRenderer: FC<Props> = ({
  keys,
  pack,
  setShowPDF,
  refetch,
  onPreview,
  onDownload,
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const { threadPackageId } = useParams();
  const navigate = useNavigate();

  const { modals, setModals } = useContext(ModalsManagerContext);
  const { data: previewData } = EmailService.useGetPreview(
    pack.Id ?? '',
    pack?.Kind === 128 ? true : false
  );
  const { mutate: getEmailFile } = EmailService.useGetFile();
  const { mutate: getFile } = fileService.useGetFiltersForCases();
  const [content, setContent] = useState<null | string>(null);
  const EncryptionKeyId = pack.EncryptionKeyId;
  const isBase64 = pack?.S2?.search?.('LS0t') !== -1;
  const [showContent, setShowContent] = useState(isBase64 ? true : false);
  const [showCase, setShowCase] = useState(false);
  const [showReceivers, setShowReceivers] = useState(false);
  const [showCcReceivers, setShowCcReceivers] = useState(false);
  const [options, setOptions] = useState(false);

  const [createAction, setCreateAction] = useState(false);
  const [showActionList, setShowActionList] = useState(false);

  const { mutate: changeHandle } = messagesService.useChangeHandle();

  const { mutate: setDesk } = deskService.useSetDesk();
  const { mutate: deletePackage } = listService.useDeleteList();

  const refWrapper = useRef(null);
  const [showActions, setShowActions] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const receiversReference = useRef(null);
  const receiversCcReference = useRef(null);
  const [position, setPosition] = useState([0, 0]);

  const packCcReceivers = pack?.Receivers?.filter((x) => x.Cc || x.Bcc);
  const packReceivers = pack?.Receivers?.filter((x) => !x.Cc && !x.Bcc);

  const handleClickComplete = () => {
    let data: IChangeHandle = {
      Handled: !pack.IsHandled,
      Items: [{ Id: pack.Id, Kind: pack.Kind }],
    };

    changeHandle(data, {
      onSuccess: () => refetch(),
    });
  };

  const handleDeletePackage = () => {
    deletePackage({
      Items: [{ Id: pack.Id, Kind: pack.Kind }],
    });
  };

  const handleAddToDesk = () => {
    setDesk({
      Items: [{ Id: pack.Id, Kind: pack.Kind }],
      Show: !pack.IsDeskActive,
    });
  };

  // const onDownload = async (attachment: IAttachment) => {
  //   const encryptionKey = keys.find((key) => key.Id === EncryptionKeyId);

  //   const types = getTypeOfParcelFromKind(pack.Kind);
  //   const isEmail = types.includes(IParcelTypes.Email);

  //   if (isEmail) {
  //     const link = document.createElement('a');
  //     link.href = `https://beta.pocztaprawnicza.pl/email/attachment?id=${attachment.Id}&mailId=${pack.Id}`;
  //     link.setAttribute('download', attachment.FileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     return;
  //   }

  //   if (!encryptionKey) {
  //     return;
  //   }

  //   getFile(
  //     {
  //       id: attachment.Uri,
  //     },
  //     {
  //       onSuccess: async (data) => {
  //         await downloadFile(
  //           user!.Id,
  //           attachment.FileName,
  //           data.data.Url,
  //           encryptionKey
  //         );
  //       },
  //     }
  //   );
  // };

  const onNewWindow = async (attach: IAttachment) =>
    window.open(
      `${process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'}/pdf/${
        pack.Kind
      }/${pack.Id}/${attach.Id}`
    );

  const [fetchContent, setFetchContent] = useState(false);
  const [decryptError, setDecryptError] = useState(false);

  const [allowImages, setAllowImages] = useState(true);
  const [canImages, setCanImages] = useState(false);

  const decodeEncryptedContent = async () => {
    const myKeys = await getKeys(userId!);
    const objectKey = previewData?.data?.ObjectKeys?.[0];

    let parentKey = null;

    if (objectKey.ParentKeyId) {
      parentKey = previewData?.data.Keys.find(
        (k: any) => k?.Id === objectKey?.ParentKeyId
      );
    }

    const content = await decryptPackage(
      userId,
      previewData?.data.Content,
      objectKey,
      parentKey
    );

    const images = await decodeAndFetchImages(
      userId,
      previewData?.data,
      objectKey,
      parentKey
    );

    setContent(await parseHTML(content, images));
  };

  function SisBase64(str: string) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  const getContent = async () => {
    setFetchContent(true);
    if (pack.Kind === 128) {
      const isEncrypted = previewData?.data?.IsEncrypted;

      if (!previewData?.data?.Content) {
        return;
      }

      if (isEncrypted) {
        setAllowImages(true);
        decodeEncryptedContent();
        return;
      }
      const isImages = await hasImages(previewData?.data?.Content);

      if (!canImages && isImages) {
        setAllowImages(false);
      }

      setContent(
        await addToUrl(
          previewData?.data?.Content,
          isImages && !canImages ? false : true
        )
      );

      return;
    }

    setAllowImages(true);

    try {
      if (!pack.S2) return;

      const r = atob(pack.S2);
      const encryptionKey = keys.find(
        (key) => key?.Id === pack?.EncryptionKeyId
      );

      let parentKey = null;
      if (encryptionKey?.ParentKeyId) {
        parentKey = keys.find((key) => key?.Id === encryptionKey.ParentKeyId);
      }

      await decryptPackage(userId, pack.S2, encryptionKey, parentKey).then(
        (s) => {
          setContent(s);
        }
      );
    } catch (e) {
      if (SisBase64(pack.S2)) {
        setDecryptError(true);
        setContent('error');
        return;
      }
      setContent(`${pack.S2}`);
      return;
    } finally {
      setFetchContent(false);
    }
  };

  useEffect(() => {
    setContent(null);
  }, [pack]);

  useEffect(() => {
    getContent();
  }, [pack, previewData]);

  useEffect(() => {
    if (!ref?.current) return;

    const rect = ref.current.getBoundingClientRect();
    setPosition([rect.x + rect.width / 2, rect.y + 40]);
  }, [ref, pack]);

  const handleChangeView = () => {
    if (showActionList) {
      setShowActionList(false);
      setCreateAction(true);
      return;
    }
    setShowActionList(true);
    setCreateAction(false);
  };

  const refe = useRef(null);

  const handleClickReply = () => setModals({ ...modals, createPost: true });

  const { mutate: manageDossier } = listService.useManageDossier();

  const { packages, setPackages } = useContext(PackagesContext);

  const handleAddOnePackage = (attachment: IAttachment) => {
    manageDossier({
      AttachmentIds: [attachment.Id],
      OwnerId: pack.Id,
      OwnerKind: pack.Kind,
      Show: !attachment.ShowInDossier,
    });

    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      let attach = el.Attachments.find((e) => e.Id === attachment.Id);

      if (attach) {
        attach.ShowInDossier = Number(!attachment.ShowInDossier);
        setPackages(newPacks);
      }
    }
  };

  const getPosition = (refWrapper: RefObject<HTMLDivElement>) => {
    if (!refWrapper?.current) return { x: 0, y: 0, width: 0, height: 0 };
    const position = refWrapper.current!.getBoundingClientRect();

    return {
      x: position.x ?? 0,
      y: position.y ?? 0,
      width: position.width,
      height: position.height,
    };
  };

  useEffect(() => {
    if (threadPackageId === pack.Id) {
      if (ref) ref.current?.scrollIntoView();
    }
  }, [threadPackageId]);

  return (
    <div
      ref={ref}
      onMouseEnter={() => setOptions(true)}
      onMouseLeave={() => setOptions(false)}
      className={`${styles.wrapper} ${
        threadPackageId === pack.Id && styles.activeWrapper
      }`}
    >
      <div className={styles.content}>
        {showActionList && (
          <ChatActionsList
            toggle={setShowActionList}
            changeView={handleChangeView}
            x={position[0]}
            y={position[1]}
            pack={pack}
          />
        )}
        {createAction && (
          <CreateActionModal
            kind={pack.Kind}
            initialValue={{
              parcelId: pack.Id,
            }}
            showCase={false}
            refetch={refetch}
            toggle={() => setCreateAction(false)}
            x={position[0]}
            y={position[1]}
          />
        )}
        {showCase && (
          <PackageCaseModal toggle={setShowCase} CaseId={pack?.Case?.Id} />
        )}

        {showTags && (
          <ModalWrapper refWrapper={refWrapper}>
            <AddTagModal
              toggle={setShowTags}
              updateTags={refetch}
              toTag={pack}
            />
          </ModalWrapper>
        )}
        {showActions && (
          <ModalWrapper refWrapper={refWrapper}>
            <div className={styles.modalee}>
              <ChatActionsList
                changeView={() => {
                  setShowActions(false);
                  setShowCreateAction(true);
                }}
                toggle={() => {}}
                x={getPosition(refWrapper).x}
                y={getPosition(refWrapper).y + 45}
                pack={pack}
              />
            </div>
          </ModalWrapper>
        )}
        {showCreateAction && (
          <ModalWrapper refWrapper={refWrapper}>
            <div className={styles.modalee}>
              <CreateActionModal
                kind={pack.Kind}
                initialValue={{
                  parcelId: pack.Id,
                }}
                showCase={false}
                refetch={() => {}}
                toggle={() => setShowCreateAction(false)}
                x={getPosition(refWrapper).x}
                y={getPosition(refWrapper).y + 45}
              />
            </div>
          </ModalWrapper>
        )}
        {showAttach && (
          <ModalWrapper refWrapper={refWrapper}>
            <AttachCaseModal
              Package={pack}
              position={{
                x: getPosition(refWrapper).x,
                y: getPosition(refWrapper).y + 45,
              }}
              refWrapper={null}
              toggle={() => setShowAttach(false)}
            />
          </ModalWrapper>
        )}
        <div className={styles.personWrapper}>
          <div className={styles.personWrapper}>
            <div className={styles.person}>
              <Avatar className={styles.avastar} name={pack.Sender ?? ''} />
              <div className={styles.senderWrapper}>
                <div className={styles.nameAndDate}>
                  <div className={styles.name}>{pack?.Sender}</div>
                  <div className={styles.date}>
                    {moment(pack.ReceiveDate ?? pack.CreationDate)
                      .format('DD.MM.YYYY')
                      .toString()}
                  </div>
                </div>
                <div className={styles.titleWrapper}>
                  <div className={styles.titlePack}>{pack.S1}</div>
                  <div ref={refe} className={styles.icons}>
                    <IconsState
                      onPreview={onPreview}
                      onDownload={onDownload}
                      customY={-30}
                      keys={keys}
                      msg={packages.find((p) => p.Id === pack.Id) ?? pack}
                    />
                  </div>
                </div>
                <div className={styles.toWrapper}>
                  <div className={styles.to}>
                    Do:
                    <div className={styles.toList}>
                      {packReceivers && packReceivers.length > 0 && (
                        <div className={styles.receiver}>
                          {packReceivers[0].Name ?? packReceivers[0].Email}
                        </div>
                      )}
                      {packReceivers && packReceivers.length > 1 && (
                        <>
                          <div
                            className={styles.moreReceivers}
                            ref={receiversReference}
                            onClick={() => setShowReceivers(!showReceivers)}
                          >
                            <GroupIcon></GroupIcon> + {packReceivers.length - 1}
                          </div>

                          {showReceivers && (
                            <ModalLayout refWrapper={receiversReference}>
                              <PackageReceiversModal
                                toggle={() => setShowReceivers(false)}
                                pack={pack}
                                cc={false}
                              />
                            </ModalLayout>
                          )}
                        </>
                      )}
                    </div>
                    {packCcReceivers && packCcReceivers.length > 0 && (
                      <div className={styles.to}>
                        Dw:
                        <div className={styles.toList}>
                          {packCcReceivers && packCcReceivers.length > 0 && (
                            <div className={styles.receiver}>
                              {packCcReceivers[0].Name ??
                                packCcReceivers[0].Email}
                            </div>
                          )}
                          {packCcReceivers && packCcReceivers.length > 1 && (
                            <>
                              <div
                                className={styles.moreReceivers}
                                ref={receiversCcReference}
                                onClick={() =>
                                  setShowCcReceivers(!showCcReceivers)
                                }
                              >
                                <GroupIcon></GroupIcon> +{' '}
                                {packCcReceivers.length - 1}
                              </div>

                              {showCcReceivers && (
                                <ModalLayout refWrapper={receiversCcReference}>
                                  <PackageReceiversModal
                                    toggle={() => setShowCcReceivers(false)}
                                    pack={pack}
                                    cc={true}
                                  />
                                </ModalLayout>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.borderLine}>
          <div className={styles.line}></div>
        </div>
        {!isBase64 && !content && (
          <div className={styles.contentLoader}>Trwa ładowanie treści..</div>
        )}

        {!isBase64 && content && (
          <EmailViewer sourceContent={content ?? ''}></EmailViewer>
        )}
        {content && showContent && (
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className={styles.body}
          ></div>
        )}
        <div className={styles.attachments}>
          {pack.Attachments?.map((attachment) => (
            <div className={styles.attach}>
              <Attachment
                onNewWindow={onNewWindow}
                onPreview={(attach) => onPreview(pack, attach)}
                onDownload={(attach) => onDownload(pack, attach)}
                attachment={attachment}
                onAddDossier={!!pack?.Case ? handleAddOnePackage : undefined}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackThreadRenderer;
