import * as openpgp from 'openpgp';
import { Base64 } from 'base64-string';
import { IDecryptedKeys } from './decryptKeys';

export const decryptMasterKey = async (myKeys: IDecryptedKeys) => {
  const enc = new Base64();

  const key = await openpgp.key.readArmored(
    enc.decode(myKeys!.recoveryPackage!.private_key)
  );
  await key.keys[0].decrypt(myKeys!.recoveryPackage!.passphrase);

  return key;
};
