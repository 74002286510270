import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { ReactComponent as FolderIcon } from 'assets/icons/folder_24px.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/logout.svg';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faEllipsisVertical,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import DossierFile from 'components/DossierFile';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';
import EditFolder from 'components/EditFolder';
import DeleteFolder from 'components/DeleteFolder';
import dossierService from 'api/dossierService';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import MenuContext from 'contexts/MenuContext';
import DossierAnimation from 'components/DossierAnimation';

import { ReactComponent as NewTabIcon } from 'assets/icons/new_tab.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import DossierMoveModal from 'components/DossierMoveModal';
import JSZip from 'jszip';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import KeysContext from 'contexts/KeysContext';
import { IKey } from 'dto/IKey';
import casesService from 'api/casesService';
import { ItemKind } from 'dto/IKindItems';
import { IDossierFakeItem } from 'dto/Dossier/IDossierFakeItem';
import { getFile } from 'utils/getFile';
import { getBlob } from 'utils/getBlob';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { decryptFile } from 'utils/decryptFile';
import Spinner from 'components/Spinner';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import PackageEditSharesModal from 'components/PackageEditSharesModal';
import { IPackage } from 'dto/IPackage';

const DossierFolder: FC<Props> = ({
  refetch,
  destination,
  index,
  folder,
  statuses,
  tags,
  canSelect,
  onSelect,
  onDoubleClick,
  selectedFiles,
  cutting,
  setSelectedFiles,
  handleCopy,
  encryptionKeys,
  parentKeys,
  selectedFolder,
  setSelectedFolder,
  setFiles,
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const { keys, setKeys } = useContext(KeysContext);
  const localKeys: IKey[] = [];

  const { caseId } = useParams();
  const navigate = useNavigate();
  const [showFiles, setShowFiles] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showMove, setShowMove] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [deleteFolder, setDeleteFolder] = useState<null | string>(null);
  const { mutate: deleteDossier } = dossierService.useDeleteDossier();
  const editRef = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  var totalToDownload = 0;
  var downloaded = 0;
  const [progress, setProgress] = useState(0);

  const [isSameCase, setIsSameCase] = useState(false);
  const [isFindedElement, setIsFindedElement] = useState(false);

  const { mutateAsync: getDossier } = dossierService.useMutationGetItems();

  const handleDelete = () => {
    deleteDossier(
      {
        Id: folder.DossierId,
      },
      {
        onSuccess: () => {
          setDeleteFolder(null);
          refetch();
        },
      }
    );
  };

  const handleDownload = async () => {
    setProgress(0);
    totalToDownload = 0;
    downloaded = 0;

    setIsDownloading(true);
    const zip = new JSZip();
    await travelFolderToMove(folder, zip);

    const content = await zip.generateAsync({ type: 'blob' });

    const URL = window.URL.createObjectURL(content);
    const link = document.createElement('a');
    link.href = URL;
    link.setAttribute('download', `${folder.Label ?? 'brak_nazwy'}.zip`);
    document.body.appendChild(link);
    link.click();

    setIsDownloading(false);
  };

  const travelFolderToMove = (folder: IDossierItem, zip: JSZip) => {
    return new Promise(async (resolve) => {
      var data = await getDossier({
        CaseId: caseId!,
        FolderId: folder.DossierId,
        Page: 0,
        SortOrder: 'Ascending',
        SortProp: 'LastModification',
      });

      if (data?.data) {
        // @ts-ignore
        setKeys((prev) => [
          ...prev,
          ...data.data.ObjectKeys,
          ...data.data.Keys,
        ]);

        localKeys.push(...data.data.ObjectKeys);
        localKeys.push(...data.data.Keys);

        totalToDownload += data.data.Items.length;

        const folders = data.data.Items?.filter(
          (dossier: IDossierItem) => dossier.Kind === ItemKind.DossierFolder
        );
        const directFiles = data.data.Items?.filter(
          (dossier: IDossierItem) => dossier.Kind !== ItemKind.DossierFolder
        );

        for (const f of directFiles) {
          if (!f.FileUri) continue;

          const file = await getFile(f.FileUri);
          const blob = await getBlob(file.data.Url);

          const key =
            keys.find((key) => key?.Id === f.EncryptionKeyId) ??
            localKeys.find((key) => key?.Id === f.EncryptionKeyId);

          if (!key) {
            continue;
          }

          let parentKey = null;
          //@ts-ignore
          if (key.ParentKeyId) {
            parentKey = keys.find(
              //@ts-ignore
              (pkey: any) => pkey?.Id === key?.ParentKeyId
            );
          }

          try {
            const decryptedFile = await decryptFile(
              userId ?? '',
              new Blob([blob.data]),
              key,
              parentKey
            );

            zip.file(f.FileName ?? 'brak_nazwy', decryptedFile);

            downloaded++;
            console.log(
              'Progress',
              (downloaded / totalToDownload) * 100,
              downloaded,
              totalToDownload
            );
            setProgress((p) => (downloaded / totalToDownload) * 100);
          } catch {}
        }

        for (const f of folders) {
          var zipFolder = zip.folder(f.Label ?? 'Brak nazwy');
          downloaded++;
          await travelFolderToMove(f, zipFolder!);
        }

        resolve(true);
      }
    });
  };

  const isSelected = !!selectedFiles?.find(
    (s) => s?.DossierId === folder?.DossierId
  );

  const { id, setId } = useContext(MenuContext);

  useEffect(() => {
    if (id !== folder.Id) {
      setShowMenu(false);
    }
  }, [id]);

  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    setShowMenu(false);
  });

  const handleKeyDown = (e: any) => {
    console.log(e);

    if (e.key === 'F2') {
      setShowEdit(true);
    } else if (e.key === 'Delete') {
      handleDelete();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    const copyElements = JSON.parse(
      sessionStorage.getItem('copy_elements') ?? '[]'
    );
    const copyCase = JSON.parse(sessionStorage.getItem('copy_case') ?? '[]');

    const cut = Boolean(sessionStorage.getItem('copy_cut'));

    setIsSameCase(copyCase?.Id === caseId);
    setIsFindedElement(
      !!copyElements.find((copy: any) => copy.Id === folder.Id)
    );
  }, [caseId, selectedFiles, cutting]);

  return (
    <>
      <Draggable
        index={index}
        key={folder.DossierId}
        draggableId={folder.DossierId}
      >
        {(provided, snapshot) => {
          //@ts-ignore
          provided.draggableProps.style = {
            ...provided.draggableProps.style,
            //@ts-ignore
            cursor: 'pointer',
          };

          return (
            <div
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              className={styles.wrapper}
              // onMouseEnter={() => setShowMenu(true)}
              // onMouseLeave={() => setShowMenu(false)}
            >
              <div className={styles.move}>
                <FontAwesomeIcon icon={faGripVertical} />
              </div>
              <div className={styles.card}>
                {showEdit && (
                  <ModalLayout
                    dock={DockPosition.DISABLED}
                    off={true}
                    customX={1}
                    isAnimation={true}
                    customY={0}
                    ignore
                    fullHeight={true}
                    refWrapper={editRef}
                    toggle={() => setShowEdit(!showEdit)}
                  >
                    <EditFolder
                      refetch={refetch}
                      toggle={setShowEdit}
                      dossierId={folder.DossierId}
                      folderName={folder.Label ?? ''}
                    />
                  </ModalLayout>
                )}
                {showMove && (
                  <ModalLayout
                    dock={DockPosition.DISABLED}
                    off={true}
                    customX={1}
                    isAnimation={true}
                    customY={0}
                    ignore
                    fullHeight={true}
                    refWrapper={editRef}
                    toggle={() => setShowMove(!showMove)}
                  >
                    <DossierMoveModal
                      refetch={refetch}
                      toggle={setShowMove}
                      item={folder}
                      sourceCaseId={caseId ?? ''}
                    />
                  </ModalLayout>
                )}

                {showShare && (
                  <ModalLayout
                    dock={DockPosition.DISABLED}
                    off={true}
                    customX={1}
                    isAnimation={true}
                    customY={0}
                    ignore
                    fullHeight={true}
                  >
                    <PackageEditSharesModal
                      toggle={() => setShowShare(false)}
                      item={folder}
                      refetch={refetch}
                    />
                  </ModalLayout>
                )}
                <Droppable droppableId={`folder:${folder.DossierId}`}>
                  {(provided, snapshot) => (
                    <>
                      <div
                        ref={provided.innerRef}
                        onClick={() => {
                          //setShowFiles(!showFiles);
                          onSelect?.(folder);
                        }}
                        onDoubleClick={() => {
                          if (onDoubleClick) {
                            onDoubleClick(folder);
                          }
                        }}
                        className={`${styles.header} ${
                          showFiles && styles.colorHeader
                        } ${isSelected && styles.active}
                        ${
                          isSameCase && isFindedElement && cutting && styles.cut
                        }`}
                      >
                        <div ref={provided.innerRef} className={styles.title}>
                          {!isDownloading && (
                            <FolderIcon className={styles.folderIcon} />
                          )}
                          {isDownloading && <Spinner />}
                          <div className={styles.label}>{folder.Label}</div>
                        </div>
                        <div className={styles.icons}>
                          {folder.IsShared && (
                            <ShareIcon
                              className={styles.shareIcon}
                              onClick={() => {
                                setShowShare(!showShare);
                              }}
                            />
                          )}
                        </div>
                        <div className={styles.wrapwrap}>
                          {/* <FontAwesomeIcon
                            className={styles.expander}
                            icon={showFiles ? faCaretUp : faCaretRight}
                          /> */}
                          <div
                            onClick={() => {
                              setShowMenu(!showMenu);
                              setId(folder.Id);
                            }}
                            className={styles.hover}
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisVertical}
                              className={styles.menuIcon}
                            />
                            {showMenu && (
                              <div
                                ref={dropdownRef}
                                className={styles.dropdown}
                              >
                                <div
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_SITE}/folder/${caseId}/${folder.Id}`,
                                      '_blank'
                                    );
                                  }}
                                  className={styles.box}
                                >
                                  <NewTabIcon
                                    title="Otwórz w nowej zakładce"
                                    ref={editRef}
                                  />
                                  W nowej zakładce
                                </div>
                                <div className={styles.box}>
                                  <LabelIcon title="Etykieta" />
                                  Etykieta
                                </div>
                                <div className={styles.line}></div>
                                <div
                                  className={styles.box}
                                  onClick={() => handleDownload()}
                                >
                                  <DownloadIcon title="Pobierz" />
                                  Pobierz
                                </div>
                                <div
                                  onClick={() => setShowEdit(true)}
                                  className={styles.box}
                                >
                                  <EditIcon title="Zmień nazwę" ref={editRef} />
                                  Zmień nazwę
                                </div>

                                <div
                                  onClick={() => {
                                    setShowMove(true);
                                    setShowMenu(false);
                                  }}
                                  className={styles.box}
                                >
                                  <MoveIcon title="Przenieś" ref={editRef} />
                                  Przenieś
                                </div>
                                <div
                                  className={styles.box}
                                  onClick={() => {
                                    setShowShare(true);
                                    setShowMenu(false);
                                  }}
                                >
                                  <ShareIcon title="Udostępnij" />
                                  Udostępnij
                                </div>

                                <div className={styles.line}></div>
                                <div
                                  onClick={() => {
                                    setDeleteFolder(folder.DossierId);
                                    setShowMenu(false);
                                  }}
                                  className={styles.box}
                                >
                                  <BinIcon title="Usuń" />
                                  Usuń
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Droppable>
                {/* {showFiles && (
                  <Droppable droppableId={`folder:${folder.Dossier!.Id}`}>
                    {(provided, snapshot) => (
                      <>
                        {!files.length &&
                          destination !== folder.Dossier!.Id && (
                            <div
                              ref={provided.innerRef}
                              className={styles.fileList}
                            >
                              <div className={styles.empty}>
                                <DossierAnimation className={styles.noFiles} />
                                Przeciągnij i upuśc plik aby dodać
                              </div>
                            </div>
                          )}
                        {!!files.length && (
                          <div
                            ref={provided.innerRef}
                            className={styles.fileList}
                          >
                            {files.map((file, index) => (
                              <DossierFile
                                setFiles={setFiles}
                                files={files}
                                inFolder
                                handleCopy={handleCopy}
                                setSelectedFiles={setSelectedFiles}
                                refetch={refetch}
                                key={file.Id}
                                cutting={cutting}
                                canSelect={canSelect}
                                onSelect={onSelect}
                                selectedFiles={selectedFiles}
                                index={index}
                                file={file}
                                statuses={statuses}
                                tags={tags}
                                encryptionKeys={encryptionKeys}
                                parentKeys={parentKeys}
                              />
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </Droppable>
                )} */}
              </div>
              {isDownloading && (
                <div className={styles.progressBar}>
                  <div
                    style={{ width: `${progress ?? 0}%` }}
                    className={styles.progress}
                  ></div>
                </div>
              )}
            </div>
          );
        }}
      </Draggable>
      {deleteFolder && (
        <DeleteFolder
          onAccept={handleDelete}
          onCancel={() => setDeleteFolder(null)}
          toggle={() => setDeleteFolder(null)}
        />
      )}
    </>
  );
};

export default DossierFolder;
