import { SignalRContext } from 'App';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { INotification } from 'dto/INotification';
import { ENotificationType, notification } from 'utils/notification';
import { axiosInstance } from 'api/axios';
import { useContext } from 'react';
import KeysContext from 'contexts/KeysContext';
import PackagesContext from 'contexts/PackagesContext';
import CasesContext from 'contexts/CasesContext';
import { IPackage } from 'dto/IPackage';

const NotificationManager: FC<Props> = ({}) => {
  const { keys, setKeys } = useContext(KeysContext);
  const { packages, setPackages } = useContext(PackagesContext);
  const { cases, setCases } = useContext(CasesContext);

  const getCase = async (id: string, kind: string) => {
    const caseData = await axiosInstance.get(`/case?id=${id}&kind=${kind}`);
    const newKeys = [caseData.data.Keys].flat();
    setKeys([...keys, newKeys].flat());
    setCases([caseData.data, ...cases]);
  };

  const getPackage = async (id: string, kind: string) => {
    const packageData = await axiosInstance.get(`/list?Id=${id}&Kind=${kind}`);

    const newKeys = [packageData.data.ObjectKeys, packageData.data.Keys].flat();
    packageData.data.Items[0].Unread = 1;
    setKeys([...keys, newKeys].flat());
    setPackages([packageData.data.Items[0], ...packages].flat());
  };

  SignalRContext.useSignalREffect(
    'notification',
    (message: INotification) => {
      notification({
        type: ENotificationType.INFO,
        title: message.label,
        text: message.message,
      });

      if (message.reason === 9) {
        return;
      }

      if (message.associatedObjectKind === 1024) {
        getCase(message.associatedObjectId, message.associatedObjectKind);
        return;
      }

      getPackage(message.associatedObjectId, message.associatedObjectKind);
    },
    []
  );

  SignalRContext.useSignalREffect(
    'newEmails',
    (message: any[]) => {
      const a: any[] = message.map((m) => {
        return {
          Id: m.id,
          InternalId: m.InternalId,
          Kind: m.kind,
          Type: m.type,
          TypeId: 0,
          CreationDate: m.creationDate,
          LastModified: m.lastModified,
          SendDate: m.sendDate,
          ReceiveDate: m.receiveDate,
          ReturnDate: m.returnDate,
          Sender: m.sender,
          ReceivedBy: m.receivedBy,
          IsIncoming: m.isIncoming,
          HasTasks: m.hasTasks,
          IsHandled: m.isHandled,
          IsDeskActive: m.isDeskActive,
          IsDraft: m.isDraft,
          IsSpam: m.isSpam,
          Unread: m.unread,
          AttachmentsCount: m.AttachmentsCount,
          TasksCount: 0,
          TagsCount: 0,
          TagIds: [],
          Tags: null,
          Case: null,
          Tasks: null,
          Shares: null,
          Attachments: null,
          Receivers: m.receivers,
          S1: m.s1,
          S2: m.s2,
          S3: m.s3,
          S4: m.s4,
          N1: m.n1,
          N2: m.n2,
          EncryptionKeyId: m.encryptionKeyId,
          IsRemoved: false,
          RemovedDate: null,
          ShowInDossier: 0,
          UserId: m.userId,
          Thread: m.threadId,
          Dossier: null,
          CommentsCount: 0,
          StatusId: null,
          IsShared: false,
          SenderUserId: null,
          SenderParticipantId: null,
        };
      });

      console.log(a);

      setPackages([a, ...packages].flat());
    },
    []
  );

  SignalRContext.useSignalREffect(
    'outgoingParcelState',
    (message: any) => {
      const newPacks = [...packages];

      const pack = newPacks.find((p) => p.Id === message.id);

      if (pack) {
        pack.state = message.status;
      }

      setPackages(newPacks);
    },
    []
  );
  return <></>;
};

export default NotificationManager;
