import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteClient.svg';
import AddButton from 'components/AddButton';
import { ETabs } from 'components/EditClientModalNew/typings';

const ContactPersonsClientModal: FC<Props> = ({ view, data, setData }) => {
  const removeAddress = (index: number) => {
    const newAddresses = data.contactPerson.filter(
      (a, aIndex: number) => index !== aIndex
    );
    setData({ ...data, contactPerson: newAddresses });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.padding}>
        <div className={styles.list}>
          {data.contactPerson.map((a, index) => {
            return (
              <div className={styles.person}>
                <div className={styles.personWrapper}>
                  <div className={styles.line}>
                    <Input
                      label={
                        view !== ETabs.COMPANY
                          ? 'Nazwa Firmy / Instytucji'
                          : 'Imię i nazwisko'
                      }
                      onChange={(t) => {
                        const newAddresses = [...data.contactPerson];

                        newAddresses[index].name = t;
                        setData({ ...data, contactPerson: newAddresses });
                      }}
                      value={a.name}
                    />
                    {data.contactPerson.length > 1 && (
                      <div className={styles.addWrapper}>
                        <div className={styles.add}>
                          <DeleteIcon
                            onClick={() => {
                              removeAddress(index);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <Input
                    label="Email"
                    onChange={(t) => {
                      const newAddresses = [...data.contactPerson];

                      newAddresses[index].email = t;
                      setData({ ...data, contactPerson: newAddresses });
                    }}
                    value={a.email}
                  />
                  <Input
                    isPhoneNumber
                    label="Telefon"
                    onChange={(t) => {
                      const newAddresses = [...data.contactPerson];

                      newAddresses[index].phone = t;
                      setData({ ...data, contactPerson: newAddresses });
                    }}
                    value={a.phone}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.btn}>
          <div className={styles.btnWrapper}>
            <AddButton
              onClick={() => {
                setData({
                  ...data,
                  contactPerson: [
                    ...data.contactPerson,
                    { email: '', name: '', phone: '' },
                  ],
                });
              }}
              text="Dodaj kolejną osobę"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPersonsClientModal;
