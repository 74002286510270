import styles from './styles.module.scss';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import InputWithRightIcon from 'components/InputWithRightIcon';
import { Props } from './typings';
import statusService from 'api/statusService';
import { IStatus } from 'dto/IStatus';
import StatusBadge from 'components/StatusBadge';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const SearchStatuses: FC<Props> = ({ selectedStatus, className, onChoose }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');
  const [searchList, setSearchList] = useState<IStatus[]>([]);

  const { data: statusesData } = statusService.useGetStatuses();
  const statuses: IStatus[] = useMemo(
    () => statusesData?.data ?? [],
    [statusesData]
  );

  useEffect(() => {
    if (!value.length) {
      setSearchList(statuses);
      return;
    }

    const list = statuses.filter(
      (status) =>
        status?.Name?.toLocaleLowerCase().search(
          value.replaceAll('#', '').toLocaleLowerCase()
        ) !== -1
    );
    setSearchList(list);
  }, [value]);

  useEffect(() => {
    setSearchList(statuses);
  }, [statuses]);

  const handleChoose = (status: IStatus) => {
    setActive(false);
    setValue(status.Name);
    setSearchList(statuses);
    onChoose(status);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Element)) {
        setActive(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref} className={`${styles.wrapper} ${className}`}>
      <div className={styles.header}>Status</div>
      <div className={styles.flags}>
        <CloseIcon onClick={() => onChoose(null)} />
        {statuses.map((status) => (
          <div
            key={status.Id}
            onClick={() => onChoose(status)}
            className={`${styles.flag} ${
              selectedStatus?.Id === status.Id && styles.active
            }`}
          >
            <StatusBadge onRemove={() => {}} status={status} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchStatuses;
