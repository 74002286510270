import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Loading from 'components/Loading';
import useGetCases from 'hooks/useGetCases';
import CaseCard from 'components/CaseCard';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';

import { config } from 'config';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ClientIcon } from 'assets/icons/client_hover.svg';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';
import statusService from 'api/statusService';
import CasesContext from 'contexts/CasesContext';
import KeysContext from 'contexts/KeysContext';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance } from 'api/axios';

const DeskCases = () => {
  const navigate = useNavigate();
  const { filterId } = useParams();
  const option: any = localStorage.getItem(config.cases_actions);
  const [actionsView, setActionsView] = useState<
    'dayGridWeek' | 'dayGridMonth' | 'dayGridDay' | 'listWeek' | 'list'
  >(option ?? 'dayGridMonth');
  const [searchText, setSearchText] = useState('');

  const { data: tagsData } = tagsService.useGetTags();
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: statusesData } = statusService.useGetStatuses();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const { cases: casesContext, setCases: setCasesContext } =
    useContext(CasesContext);

  const { keys, setKeys } = useContext(KeysContext);

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery(
      ['cases', searchText],
      async ({ pageParam = 0 }) => {
        const query = new URLSearchParams(window.location.search);

        query.append('FilterId', 'd902a02c-16fa-4f88-8c0b-8f6c2f36ec71');
        query.append('Desk', 'true');

        const res = await axiosInstance.get(`/cases?${query}`);
        return res.data;
      },
      {
        getNextPageParam: (lastPage) => {},
      }
    );

  const compileData = async () => {
    if (!data) return;
    const packs = await Promise.all(
      data?.pages
        .map((page) => {
          return page.Items;
        })
        .flat()
    );

    const keys = await Promise.all(
      data?.pages
        .map((page) => {
          return page.ObjectKeys;
        })
        .flat()
    );

    setCasesContext(packs);
    setKeys(keys);
  };

  useEffect(() => {
    compileData();
  }, [data]);

  const separatorRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: any) => {
    if (!separatorRef.current) return;
    if (isLoading) return;
    var rect = separatorRef.current.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);

    if (isVisible) {
      // setPage(page + 1);
    }
  };

  const handleChangeSearchBar = (text: string) => setSearchText(text);

  const handleClick = (c: ICaseSimple) => {
    window.open(
      `${
        process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'
      }/fullscreen/cases/${c.Id}/chat`
    );
  };

  return (
    <div className={styles.wrapper}>
      <div onScroll={handleScroll} className={styles.packageList}>
        {isLoading && !casesContext.length && <Loading />}
        {!isLoading &&
          casesContext
            .filter((c) => c.IsDeskActive)
            .map((msg: ICaseSimple) => (
              <CaseCard
                key={msg.Id}
                onClick={() => handleClick(msg)}
                className={styles.card}
                c={msg}
                tags={tags}
                statuses={statuses}
                setSelectedEdit={() => {}}
              />
            ))}
        {isFetchingNextPage && isLoading && (
          <div className={styles.loadingWrapper}>
            <Loading />
          </div>
        )}
        <div ref={separatorRef} style={{ height: '5px' }}></div>
      </div>
    </div>
  );
};

export default DeskCases;
