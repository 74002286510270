import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import editorService from 'api/editorService';
import { useEffect, useMemo, useState } from 'react';
import { ITemplate } from 'dto/ITemplate';
import fileService from 'api/fileService';
import { getBlob } from 'utils/getBlob';
import { blobToBase64 } from 'utils/blobToBase64';
import pluginService from 'api/pluginService';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import PDFViewer from 'components/PDFViever';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import { decryptFile } from 'utils/decryptFile';
import { readFile } from 'utils/readFile';
import * as openpgp from 'openpgp';
import { C } from '@fullcalendar/core/internal-common';

const Template: FC<Props> = ({}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { templateId, templateKind } = useParams();

  const { data: templateData } = editorService.useGetSingleTemplate(
    templateId!,
    templateKind!
  );

  const { mutate: getFile } = fileService.useGetFiltersForCases();

  const template: ITemplate | null = useMemo(
    () => templateData?.[0] ?? null,
    [templateData]
  );

  const { mutate: tryPreview } = pluginService.usePreview();

  const [letterBlob, setLetterBlob] = useState<Blob | null>(null);

  const previewFromPlugin = async (blob: Blob, fileName: string) => {
    const b: string = (await blobToBase64(blob)) as string;

    tryPreview(
      {
        Content: b.split(',')[1],
        ContentType: 'application/x-amz-json-1.0',
        FileName: fileName,
      },
      {
        onSuccess: async (response) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setLetterBlob(new Blob([buffer]));
        },
      }
    );
  };

  const handlePreview = async () => {
    const myKeys = await getKeys(userId!);
    if (!template) return;

    getFile(
      {
        id: template.S3!,
        ownerKind: template.Kind,
      },
      {
        onSuccess: async (data) => {
          const file = await getBlob(data.data.Url);

          const xfile = await openpgp.message.read(
            new Uint8Array(await readFile(file.data))
          );

          const privateKey = await openpgp.key.readArmored(
            atob(myKeys!.intermidatePrivateKey!.data)
          );

          const { data: decryptedFile } = await openpgp.decrypt({
            message: xfile,
            privateKeys: [privateKey.keys[0]],
            format: 'binary',
          });

          previewFromPlugin(new Blob([decryptedFile]), template.S2);
        },
      }
    );
  };

  useEffect(() => {
    if (!template) return;

    handlePreview();
  }, [template]);

  if (letterBlob) {
    return <PDFViewer file={letterBlob} />;
  }

  return <></>;
};

export default Template;
