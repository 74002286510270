import { ISingleBinaryKeys } from "./GenerateSingleBinary";
import * as openpgp from 'openpgp';
import downloadFile from "./downloadFile";

const encryptFile = async (file: string | ArrayBuffer | null | undefined, keys: ISingleBinaryKeys) => {
  
    const { message } = await openpgp.encrypt({
        message: openpgp.message.fromBinary(new Uint8Array(file as ArrayBuffer)),
        publicKeys: [keys.cleanPublicKey.keys[0]],
        armor: false
        
      });

      return await new Uint8Array(message.packets.write());
}

export default encryptFile;