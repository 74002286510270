import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import prettyBytes from 'pretty-bytes';

import AttachmentIconComponent from 'components/AttachmentIconComponent';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

const Filedossier: FC<Props> = ({
  file,
  handleRemove,
  onClick,
  index,
  actualFile,
  actualProgress,
  isSelected = false,
  useDrag = false,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  if (!useDrag) {
    return (
      <div
        onMouseEnter={() => setShowOptions(true)}
        onMouseLeave={() => setShowOptions(false)}
        className={`${styles.box} ${isSelected && styles.active}`}
        onClick={onClick}
      >
        <div className={styles.wrapper}>
          {showOptions && (
            <div className={styles.options}>
              <BinIcon onClick={() => handleRemove(index)} />
            </div>
          )}
          <AttachmentIconComponent file={file.name} />
          <div className={styles.information}>
            <div className={styles.name}>{file?.name}</div>
            <div className={styles.size}>{prettyBytes(file.size ?? 0)}</div>
          </div>
        </div>
        {actualFile === index && !!actualProgress && (
          <div className={styles.progressBar}>
            <div
              style={{ width: `${actualProgress}%` }}
              className={styles.progress}
            ></div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <Draggable index={index} key={file.name} draggableId={file.name}>
        {(provided, snapshot) => {
          //@ts-ignore
          provided.draggableProps.style = {
            ...provided.draggableProps.style,
            //@ts-ignore
            cursor: 'pointer',
          };
          return (
            <div
              onMouseEnter={() => setShowOptions(true)}
              onMouseLeave={() => setShowOptions(false)}
              className={`${styles.box} ${isSelected && styles.active}`}
              onClick={onClick}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className={styles.wrapper}>
                {showOptions && (
                  <div className={styles.options}>
                    <BinIcon onClick={() => handleRemove(index)} />
                  </div>
                )}
                <AttachmentIconComponent file={file.name} />
                <div className={styles.information}>
                  <div className={styles.name}>{file?.name}</div>
                  <div className={styles.size}>
                    {prettyBytes(file.size ?? 0)}
                  </div>
                </div>
              </div>
              {actualFile === index && !!actualProgress && (
                <div className={styles.progressBar}>
                  <div
                    style={{ width: `${actualProgress}%` }}
                    className={styles.progress}
                  ></div>
                </div>
              )}
            </div>
          );
        }}
      </Draggable>
    </>
  );
};

export default Filedossier;
