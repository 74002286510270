import { FC, Props } from './typings';
import styles from './styles.module.scss';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import ReactDOM from 'react-dom';
import threadsService from 'api/threadsService';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { IThread } from 'dto/IThread';
import AddButton from 'components/AddButton';
import Input from 'components/Input';
import Button from 'components/Button';

const CaseThreadsModal: FC<Props> = ({
  toggle,
  setCreateNewThread,
  onSelectThread,
}) => {
  const [name, setName] = useState('');
  const { caseId } = useParams();

  const { mutate: createThread } = threadsService.useCreateThread();

  const { refetch } = threadsService.useGet(caseId!);

  const handleClickCreate = () => {
    createThread(
      {
        CaseId: caseId!,
        Items: [],
        Label: name,
      },
      {
        onSuccess: () => {
          toggle(false);
          refetch();
        },
      }
    );
  };

  return (
    <div className={styles.card}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          Wątek
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => toggle(false)}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            placeholder="Nazwa wątku"
            label=""
            value={name}
            onChange={(text) => setName(text)}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button onClick={handleClickCreate} text="Utwórz" />
        </div>
      </div>
    </div>
  );
};

export default CaseThreadsModal;
