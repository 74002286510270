import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { IShare } from 'dto/IShare';

const queryKeys = {
  getMetric: (id: string, kind: number) => [
    'messagesService.getMetric',
    id,
    kind,
  ],
  getShares: (id: string, kind: number) => [
    'messagesService.getShares',
    id,
    kind,
  ],
};
interface IItem {
  Id: string;
  Kind: number;
}

export interface IChangeHandle {
  Items: IItem[];
  Handled: boolean;
}

const changeHandle = (payload: IChangeHandle) => {
  return axiosInstance.post('/list/Handled', payload);
};

const useChangeHandle = () => {
  return useMutation(changeHandle);
};

const getMetric = (id: string, kind: number) => {
  return axiosInstance.get(`/message/metric?id=${id}&kind=${kind}`);
};

const useGetMetric = (id: string, kind: number) => {
  return useQuery(queryKeys.getMetric(id, kind), () => getMetric(id, kind));
};

const downloadMetric = (payload: { id: string }) => {
  return axiosInstance.get(`/message/evidence?id=${payload.id}`);
};

const useDownloadMetric = () => {
  return useMutation(downloadMetric);
};

export interface IRedirect {
  Id: string;
  Kind: number;
  ToUserId: string;
  Title?: string;
  Comment?: string;
  Keys?: [
    {
      Id: string;
      Key: string;
    }
  ];
}

const redirect = (payload: IRedirect) => {
  return axiosInstance.post('/message/pass', payload);
};

const useRedirect = () => {
  return useMutation(redirect);
};

export interface ICreateShare {
  Id: string;
  Kind: number;
  ToUserId: string;
  Title?: string;
  Comment?: string;
  Keys?: {
    Id: string;
    Key: string;
  }[];
}

const share = (payload: ICreateShare) => {
  return axiosInstance.post('/message/share', payload);
};

const useShare = () => {
  return useMutation(share);
};

const getShares = (id: string, kind: number): Promise<{ data: IShare[] }> => {
  return axiosInstance.get(`/message/shares?id=${id}&kind=${kind}`);
};

const useGetShare = (id: string, kind: number) => {
  return useQuery(queryKeys.getShares(id, kind), () => getShares(id, kind));
};

export interface IDeleteShare {
  Id: string;
  Kind: number;
}

const removeShare = (payload: IDeleteShare) => {
  return axiosInstance.delete('/message/share', {
    data: payload,
  });
};

const useRemoveShare = () => {
  return useMutation(removeShare);
};

export default {
  useChangeHandle,
  useGetMetric,
  useDownloadMetric,
  useRedirect,
  useShare,
  useGetShare,
  useRemoveShare,
};
