import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import NewSearchCases from 'components/NewSearchCases';
import { useRef, useState } from 'react';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import NewInput from 'components/NewInput';
import { Editor } from '@tinymce/tinymce-react';
import Button from 'components/Button';
import NotesService from 'api/NotesService';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { getKeys } from 'utils/getKeys';
import encryptContent from 'utils/encryptString';
import { readAsBase64 } from 'utils/readAsBase64';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { INote } from 'components/NotesDesk';

const AddNote: FC<Props> = ({ defaultValues, toggle, refetch, addNote }) => {
  const editorRef = useRef<any>(null);
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const [c, setCase] = useState<ICaseSimple>();
  const [content, setContent] = useState(defaultValues?.content ?? '');
  const [title, setTitle] = useState(defaultValues?.title ?? '');
  const editorSize = window.innerHeight <= 800 ? window.innerHeight - 380 : 500;

  const { mutate: addNoteS } = NotesService.useCreateNote();
  const { mutate: editNote } = NotesService.useEditNote();

  const handleCreate = async () => {
    const myKeys = await getKeys(userId!);

    const encryptedTitle = await encryptContent(
      title,
      myKeys?.publicBase64Key,
      true
    );
    const encryptedContent = await encryptContent(
      content,
      myKeys?.publicBase64Key,
      true
    );

    const payload = {
      title: await readAsBase64(encryptedTitle),
      content: await readAsBase64(encryptedContent),
    };

    if (defaultValues?.id) {
      editNote(
        {
          Id: defaultValues.id,
          Title: payload.title,
          Content: payload.content,
        },
        {
          onSuccess: () => {
            toggle();
            refetch();
          },
        }
      );
      return;
    }

    addNoteS(
      {
        Content: payload.content,
        Title: payload.title,
      },
      {
        onSuccess: (response) => {
          const note = response.data as INote;
          note.Title = title;
          note.Content = content;
          note.decoded = true;
          addNote(response.data);
          toggle();
        },
      }
    );
  };

  return ReactDOM.createPortal(
    <>
      <div className={`${styles.modal}`}>
        <div className={styles.window}>
          <div className={styles.title}>
            Notatka
            <CloseIcon onClick={toggle} className={styles.icon} />
          </div>
          <div className={styles.inputs}>
            {/* {!defaultValues?.id && (
              <NewSearchCases onChoose={(ce) => setCase(ce)} />
            )} */}
            <NewInput
              onChange={(a) => setTitle(a)}
              label=""
              value={title}
              placeholder="Temat: "
            />
          </div>
          <div className={styles.editor}>
            <Editor
              apiKey="12k7ybb0o4007p3nah1s77v7gmrbalfhy4jreucu2nky2l3b"
              onInit={(evt, editor) => {
                editorRef.current = editor;
                // setIsShowed(true);
              }}
              onEditorChange={(text) => {
                setContent(text);
              }}
              value={content}
              init={{
                height: editorSize,
                menubar: false,
                statusbar: false,
                resize: false,
                browser_spellcheck: true,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'imageTools',
                  'quickbars',
                ],
                toolbar_persist: true,
                fixed_toolbar_container_target: document.body,
                contextmenu: 'table',
                contextmenu_never_use_native: true,
                images_upload_url: '',
                images_upload_handler: (): Promise<any> => {
                  return new Promise((resolve) => {
                    resolve(true);
                  });
                },
                toolbar: `bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor | indent outdent | bullist numlist | table`,
                // content_style:
                //   'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; width: 100%; }',
              }}
            />
          </div>
          <div className={styles.button}>
            <Button onClick={() => handleCreate()} text="Zapisz" />
          </div>
        </div>
      </div>
    </>,
    document.body
  );
};

export default AddNote;
