import { IParcelTypes } from "dto/IParcelTypes";

export const convertTypeOfPackageToColor = (typeOfParcel: IParcelTypes[]) => {
    if(typeOfParcel.includes(IParcelTypes.Parcel) || typeOfParcel.includes(IParcelTypes.OutgoingParcel)) {
        return '#27632D';
    }



if(typeOfParcel.includes(IParcelTypes.CourtPortalApplication) || typeOfParcel.includes(IParcelTypes.CourtPortalDeliverance) || typeOfParcel.includes(IParcelTypes.CourtPortalDocument)) {
    return '#F2B8B5'
   }


   if(typeOfParcel.includes(IParcelTypes.EDeliveryMessage)) {
    return '#5D49C7';
   }





   return '#CDCDCD'
}