import ResponsiveReactGridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styles from './styles.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactGridLayout from 'react-grid-layout';
import GridWindow from 'components/GridWindow';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as LayoutIcon } from 'assets/icons/order/layout.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/order/selected.svg';
import MarkedDeskWindow from 'components/MarkedDeskWindow';
import DatePicker, { registerLocale } from 'react-datepicker';
import { CustomHeader, ServerDay } from 'modules/actionsModule';
import { DateCalendar } from '@mui/x-date-pickers';
import FullCalendar from '@fullcalendar/react';
import plLocale from '@fullcalendar/core/locales/pl';
import useCalendar from 'hooks/useCalendar';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import interactionPlugin, {
  Draggable,
  DropArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import { useNavigate, useParams } from 'react-router-dom';
import MarkedDeskWindowSmall from 'components/MarkedDeskWindowSmall';
import LettersDeskWindow from 'components/LettersDeskWindow';
import CasesDeskWindow from 'components/CasesDeskWindow';
import NotificationDesk from 'components/NotificationDesk';
import NotesDesk from 'components/NotesDesk';
import { SizeMe } from 'react-sizeme';
import { useAuth } from 'react-oidc-context';

import Button from 'components/Button';
import taskService from 'api/taskService';

import pl from 'date-fns/locale/pl';
import { IFeed } from 'dto/IFeed';
import { RenderEventContent } from 'components/ActionsCalendar';

registerLocale('pl', pl);

export enum ItemsToDrop {
  OZNACZONE_BIG = 'OZNACZONE_BIG',
  OZNACZONE_SMALL = 'OZNACZONE_SMALL',
  NOTATKI = 'NOTATKI',
  POWIADOMIENIA = 'POWIADOMIENIA',
  PISMA = 'PISMA',
  COS_1 = 'COS_1',
  SPRAWY = 'SPRAWY',
  PRZYCHODZACE = 'PRZYCHODZĄCE',
}

const GRIDWindows = [
  { i: ItemsToDrop.OZNACZONE_SMALL, x: 0, y: 1, w: 10, h: 16, static: false },
  { i: ItemsToDrop.NOTATKI, x: 10, y: 1, w: 6, h: 8 },
  { i: ItemsToDrop.POWIADOMIENIA, x: 10, y: 1, w: 6, h: 8 },
  { i: ItemsToDrop.OZNACZONE_BIG, x: 16, y: 1, w: 8, h: 9 },
  { i: ItemsToDrop.SPRAWY, x: 16, y: 9, w: 8, h: 14 },
  { i: ItemsToDrop.PISMA, x: 0, y: 16, w: 16, h: 7 },
];

// const defaultLayout: any = [
//   { i: ItemsToDrop.OZNACZONE_SMALL, x: 0, y: 0, w: 10, h: 16, static: false },
//   { i: ItemsToDrop.NOTATKI, x: 10, y: 1, w: 6, h: 8 },
//   { i: ItemsToDrop.POWIADOMIENIA, x: 10, y: 0, w: 6, h: 8 },
//   { i: ItemsToDrop.OZNACZONE_BIG, x: 16, y: 0, w: 8, h: 9 },
//   { i: ItemsToDrop.SPRAWY, x: 16, y: 9, w: 8, h: 14 },
//   { i: ItemsToDrop.PISMA, x: 0, y: 16, w: 16, h: 7 },
// ];

const defaultLayout: any = [
  { i: ItemsToDrop.OZNACZONE_BIG, x: 0, y: 0, w: 12, h: 24 },
  { i: ItemsToDrop.POWIADOMIENIA, x: 13, y: 0, w: 12, h: 12 },
  { i: ItemsToDrop.NOTATKI, x: 13, y: 9, w: 12, h: 12 },

  { i: ItemsToDrop.PISMA, x: 0, y: 17, w: 25, h: 7 },
];

const defaultLayout2: any = [
  { i: ItemsToDrop.OZNACZONE_BIG, x: 0, y: 0, w: 12, h: 31 },
  { i: ItemsToDrop.POWIADOMIENIA, x: 13, y: 0, w: 12, h: 15.5 },
  { i: ItemsToDrop.NOTATKI, x: 13, y: 9, w: 12, h: 15.5 },
];

const defaultGuestLayout: any = [
  { i: ItemsToDrop.POWIADOMIENIA, x: 0, y: 0, w: 24, h: 31 },
];

const getWindow = (type: ItemsToDrop) => {
  switch (type) {
    case ItemsToDrop.OZNACZONE_BIG:
      return MarkedDeskWindow;
    case ItemsToDrop.OZNACZONE_SMALL:
      return MarkedDeskWindowSmall;
    case ItemsToDrop.PISMA:
      return LettersDeskWindow;
    case ItemsToDrop.SPRAWY:
      return CasesDeskWindow;
    case ItemsToDrop.POWIADOMIENIA:
      return NotificationDesk;
    case ItemsToDrop.NOTATKI:
      return NotesDesk;
    default:
      return GridWindow;
  }
};

const DeskModule = ({}: { isDesk?: boolean; isCase?: boolean }) => {
  // const isEditMode = window.location.href.search('edit') !== -1;
  const navigate = useNavigate();
  const auth = useAuth();
  const isGuest = auth?.user?.profile?.role === 'guest';
  const { filterId } = useParams();

  const [layoutVersion, setLayoutVersion] = useState('2');

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const is = window.location.href.search('edit') !== -1;
    setIsEditMode(is);
  }, [filterId]);

  const [layout, setLayout] = useState(
    isGuest ? defaultGuestLayout : defaultLayout
  );

  const [droppingItem, setDroppingItem] = useState({ i: 'b3', w: 12, h: 1 });

  const items = GRIDWindows?.filter((window) => {
    const el = layout?.find((layout: any) => layout?.i === window?.i);

    if (el) {
      return false;
    }

    return true;
  });

  const ref = useRef(null);

  const [width, setWidth] = useState<null | number>(null);

  useEffect(() => {
    if (!ref.current) return;

    setTimeout(() => {
      //@ts-ignore
      const position = ref?.current?.getBoundingClientRect?.();
      if (!position) return;
      setWidth(position.width);
    }, 100);
  }, [ref]);

  useEffect(() => {
    if (isGuest) return;
    const desk_layout = localStorage.getItem('desk_layout');
    const layout_type = localStorage.getItem('desk_type');

    if (!layout_type) {
      localStorage.setItem('desk_type', '1');
    }

    if (!desk_layout?.length) return;
    const loadedLayout = JSON.parse(desk_layout);
    if (!loadedLayout?.[0]?.w) return;
    setLayout(loadedLayout);
    setLayoutVersion(layout_type ?? '1');
  }, []);

  // useEffect(() => {
  //   if (isGuest) return;
  //   localStorage.setItem('desk_layout', JSON.stringify(layout));
  // }, [layout]);

  const handleReset = () => {
    setLayout(defaultLayout);
    localStorage.setItem('desk_layout', JSON.stringify(defaultLayout));
  };

  const handleRemove = (id: string) => {
    const newLayout = [...layout].filter((l) => l.i !== id);
    setLayout(newLayout);
  };

  const [update, setUpdate] = useState(true);

  const chooseLayout = (type: string) => {
    if (type === '1') {
      setLayout(defaultLayout);
      localStorage.setItem('desk_layout', JSON.stringify(defaultLayout));
      localStorage.setItem('desk_type', '1');
      setLayoutVersion(type);
      return;
    }

    setLayout(defaultLayout2);
    localStorage.setItem('desk_layout', JSON.stringify(defaultLayout2));
    localStorage.setItem('desk_type', '2');
    setLayoutVersion(type);
  };

  var h = window.innerHeight - 20;

  const handleClose = () => {
    navigate('/desk');
  };

  const calRef = useRef(null);
  const [date, setDate] = useState<Date>(new Date());
  const [tasks, setTasks] = useState<IFeed[]>([]);

  const { data: busyData } = taskService.useGetBusy(
    moment(date).startOf('month').subtract(7, 'd').toISOString(),
    moment(date).endOf('month').add(7, 'd').toISOString()
  );

  const { data, refetch } = taskService.useGetFeed(
    moment(date).startOf('day').toISOString(),
    moment(date).endOf('day').toISOString()
  );

  const busyDates = useMemo(
    () => busyData?.data.map((d: string) => moment(d).toDate()) ?? [],
    [busyData]
  );

  const events = useMemo(
    () =>
      data?.data?.map((event: IFeed) => ({
        IsFavourite: event.IsFavourite,
        note: event.description,
        allDay: event.allDay,
        id: event.id,
        title: event.title ?? '',
        caseId: event?.case?.Id ?? null,
        start: moment(event.start).toDate(),
        end: event.end
          ? moment(event.end).toDate()
          : moment(event.start).endOf('day').add(1, 'm').toDate(),
      })) ?? [],
    [data]
  );

  useEffect(() => {
    setTasks(events);
  }, [events]);

  useEffect(() => {
    if (!calRef.current) return;
    //@ts-ignore
    calRef?.current
      //@ts-ignore
      ?.getApi()
      //@ts-ignore
      .gotoDate(moment(date).startOf('day').toISOString());
  }, [calRef, date]);

  return (
    <div className={styles.wrapper}>
      {/* <button onClick={() => setEditable(!editable)}>a</button> */}
      <div
        ref={ref}
        className={`${styles.grid} ${
          layoutVersion === '2' && styles.viewWithoutCalendar
        }`}
      >
        {width && (
          <SizeMe>
            {({ size }) => (
              <ReactGridLayout
                className="layout"
                layout={layout}
                cols={24}
                rowHeight={size.height ?? h / 31}
                width={size.width ?? width}
                isDroppable={isEditMode}
                isDraggable={isEditMode}
                isResizable={isEditMode}
                margin={[10, 0]}
                isBounded={true}
                preventCollision={true}
                autoSize={true}
                verticalCompact={true}
                style={{
                  marginTop: '10px',
                  height: `100%`,
                  overflowY: 'hidden',
                  overflowX: 'hidden',
                }}
                droppingItem={droppingItem}
                onDrop={(e, layoutItem, event: DragEvent) => {
                  setLayout([...layout, layoutItem].flat());
                }}
                onLayoutChange={(e: any) => {
                  if (update) {
                    setLayout(e);
                  }
                }}
              >
                {layout.map((l: any) => {
                  const Component = getWindow(l.i);

                  return (
                    <div key={l.i}>
                      <Component
                        handleRemove={handleRemove}
                        id={l.i}
                        isEditMode={isEditMode}
                        title={
                          <>
                            <DeskIcon /> {l.i}
                          </>
                        }
                      />
                    </div>
                  );
                })}
              </ReactGridLayout>
            )}
          </SizeMe>
        )}
      </div>
      {layoutVersion === '1' && (
        <div className={styles.calendare}>
          <div className={styles.datePicker}>
            <DateCalendar
              slots={{
                day: ServerDay,
              }}
              className={styles.mui}
              defaultValue={moment(date)}
              onChange={(d) => {
                setDate(moment(d).startOf('day').toDate());
              }}
              slotProps={{
                day: {
                  highlightedDays: busyDates.filter(
                    (f: Date) => moment(f).month() === moment(date).month()
                  ),
                } as any,
              }}
            />
          </div>
          <div className={styles.funcionCalendar}>
            <FullCalendar
              ref={calRef}
              locale={plLocale}
              // headerToolbar={{
              //   start: 'prev,next',
              //   center: 'title',
              //   end: 'dayGridWeek,dayGridDay', // user can switch between the two
              // }}
              allDayText="Całodn."
              events={tasks}
              headerToolbar={{
                start: '',
                center: '',
                end: '',
              }}
              views={{
                timeGridFourDay: {
                  type: 'timeGrid',
                  // duration: { days: day },
                },
              }}
              // dayHeaderClassNames={}
              initialView={'timeGridFourDay'}
              // ref={ref}
              nowIndicator={true}
              firstDay={1}
              plugins={[
                dayGridPlugin,
                interactionPlugin,
                listPlugin,
                timeGridPlugin,
              ]}
              weekends={true}
              // events={tasks}
              droppable={true}
              // selectable={true}

              displayEventTime
              // dateClick={new Date()}
              selectMinDistance={0}
              editable={true}
              selectable={true}
              // eventDragStop={handleEventDrop}
              // eventDragStart={(e) =>
              // eventDragStop={(e: any) =>
              dropAccept={'true'}
              // eventResize={handleEventResize}
              allDayClassNames={styles.event}
              eventClassNames={styles.event}
              eventContent={(event: any) =>
                RenderEventContent(
                  event,
                  null,
                  () => {},
                  () => {}
                )
              }
            />
          </div>
        </div>
      )}

      <div className={`${styles.items} ${!isEditMode && styles.showEditable}`}>
        <div className={styles.menuTitle}>
          Biurko
          <CloseIcon onClick={handleClose} className={styles.closeIcon} />
        </div>

        <div
          onClick={() => {
            chooseLayout('1');
          }}
          className={`${styles.choose} ${
            layoutVersion === '1' && styles.active
          }`}
        >
          {layoutVersion === '1' ? <CheckIcon /> : <LayoutIcon />} Pełny
        </div>
        <div
          onClick={() => {
            chooseLayout('2');
          }}
          className={`${styles.choose} ${
            layoutVersion === '2' && styles.active
          }`}
        >
          {layoutVersion === '2' ? <CheckIcon /> : <LayoutIcon />} Optymalny
        </div>

        <div className={styles.btn}>
          <Button onClick={handleClose} text="Zapisz" />
        </div>
      </div>
    </div>
  );
};

export default DeskModule;
