import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import casesService from 'api/casesService';
import filterService from 'api/filterService';
import classNames from 'classnames';
import Button from 'components/Button';
import HollowButton from 'components/HollowButton';
import Input from 'components/Input';
import SearchStatuses from 'components/SearchStatuses';
import SearchTags from 'components/SearchTags';
import SecondCheckbox from 'components/SecondCheckbox';
import TagBadge from 'components/TagBadge';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { useEffect, useMemo, useState } from 'react';
import { calculateKindItems } from 'utils/calculateKindItems';
import { reverseKindItems } from 'utils/reverseKindItems';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import ChooseTagNew from 'components/ChooseTagNew';
import SelectFlagNew from 'components/SelectFlagNew';
import ChooseTagNewFilter from 'components/ChooseTagNewFilter';
import SelectFlagNewFilter from 'components/SelectFlagNewFilter';
import StatusBadge from 'components/StatusBadge';
import ChooseMethods from 'components/ChooseMethods';
import Chip from 'components/Chip';

export const getType = (id: string | number) => {
  switch (id) {
    case 1:
      return 2;
    case 2:
      return 2;
    case 3:
      return 1;
    case 4:
      return 0;
    case 6:
      return 3;
    default:
      return -1;
  }
};

export const CreateFilterIncomingModal: FC<Props> = ({
  typed,
  showStatus = true,
  id,
  listRef,
  toggle,
  getFilters,
  initialValues,
}) => {
  const { data: tagsData } = tagsService.useGetTags(false, getType(id));
  const { data: statusesData } = statusService.useGetStatuses();

  const tagd = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const statused = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const [position, setPosition] = useState([0, 0]);
  const [showed, setShowed] = useState('none');
  const [name, setName] = useState('');
  const [tags, setTags] = useState<ITag[]>([]);
  const [statuses, setStatuses] = useState<IStatus[]>([]);

  const { mutate: createFilter } = filterService.useCreateFilterForCategory();
  const { mutate: deleteFilter } = filterService.useDeleteFilterForCategory();
  const { mutate: createCaseFilter } = casesService.useCreateFilterForCases();

  const [types, setTypes] = useState(initialValues ? [] : typed);

  const handleChangeState = (name: string, state: boolean) => {
    const newTypes = [...types].map((type) => {
      if (name === type.name) {
        type.active = state;
      }
      return type;
    });

    setTypes(newTypes);
  };

  useEffect(() => {
    setName(initialValues?.Name ?? '');

    if (initialValues?.TagIds) {
      const findedTags = tagd?.filter((tag: ITag) =>
        initialValues?.TagIds?.find((iTag) => tag.Id === iTag)
      );
      const findedStatuses = statused?.filter((status: IStatus) =>
        initialValues?.StatusIds?.find((iStatus) => status.Id === iStatus)
      );

      if (initialValues.ItemKinds !== 0) {
        typed = typed.map((t) => {
          t.active = false;
          return t;
        });
      }

      const kindItems = reverseKindItems(initialValues.ItemKinds);
      const kindValues = Object.entries(kindItems);

      const newTyped = typed.map((type) => {
        const key = kindValues.find((kindKey) => kindKey[0] === type.id);

        if (key) {
          type.active = key[1];
        }
        return type;
      });

      setTags(findedTags ?? []);
      setStatuses(findedStatuses);
      setTypes(newTyped);
    }
  }, [initialValues, tagd]);

  const handleChooseTag = (tag: ITag | null) => setTags([...tags, tag!]);
  const handleRemoveTag = (removeTag: ITag) =>
    setTags(tags.filter((tag) => tag.Id !== removeTag.Id));
  const handleChooseStatus = (status: IStatus) =>
    setStatuses([...statuses, status]);
  const handleRemoveStatus = (removeStatus: IStatus) =>
    setStatuses(statuses.filter((s) => s.Id !== removeStatus.Id));

  const handleDelete = () => {
    if (initialValues) {
      deleteFilter({ Id: initialValues.Id });
    }

    setTimeout(() => {
      getFilters(id);
    }, 50);
    toggle(false);
  };

  const handleCreate = () => {
    // cases id 3

    const data = {
      Name: name,
      List: id,
      Id: initialValues?.Id ? initialValues.Id : undefined,
      TasksCompleted: 0,
      Tasks: 3,
      TagIds: tags.map((tag) => tag.Id),
      StatusIds: statuses?.map((a) => a.Id) ?? [],
      Status: 0,
      Return: 3,
      Handled: 3,
      Delivery: 3,

      ItemKinds: calculateKindItems({
        CourtPortalDeliverance:
          types?.find((type) => type.id === 'CourtPortalDeliverance')?.active ??
          false,
        CourtPortalDocument:
          types?.find((type) => type.id === 'CourtPortalDocument')?.active ??
          false,
        Parcel: types?.find((type) => type.id === 'Parcel')?.active ?? false,
        ContactMessage:
          types?.find((type) => type.id === 'ContactMessage')?.active ?? false,
        EDeliveryMessage:
          types?.find((type) => type.id === 'EDeliveryMessage')?.active ??
          false,
        CourtPortalApplication:
          types?.find((type) => type.id === 'CourtPortalApplication')?.active ??
          id === 1
            ? true
            : false,
        Email: types?.find((type) => type.id === 'Email')?.active ?? false,
        OutgoingParcel:
          types?.find((type) => type.id === 'OutgoingParcel')?.active ?? false,
      }),
    };

    if (id === 3) {
      createCaseFilter(data, {
        onSuccess: () => {
          getFilters(id);
          toggle(false);
        },
      });
      return;
    }

    createFilter(data, {
      onSuccess: () => {
        getFilters(id);
        toggle(false);
      },
    });
  };

  useEffect(() => {
    if (listRef.current) {
      const left = listRef.current.offsetLeft + listRef.current.offsetWidth;
      const top = listRef.current.offsetTop + listRef.current.offsetHeight / 2;

      setPosition([left, top]);
      setShowed('block');
    }
  }, [listRef]);

  return (
    <div
      style={{ left: position[0], top: position[1], display: showed }}
      className={styles.modal}
    >
      {/* <i className={classNames(styles.arrow, styles.left)}></i> */}
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>
            {initialValues ? 'Lista' : 'Nowa Lista'}
          </div>
          <div onClick={() => toggle(false)} className={styles.close}>
            <CloseIcon className={styles.closeButton} />
          </div>
        </div>
        <Input
          value={name}
          placeholder="Wpisz nazwe nowego filtru"
          onChange={(text) => setName(text)}
          label="Nazwa"
        />

        <div className={styles.types}>
          {/* {!!types.length && (
            <>
              <div className={styles.typesHeader}>Rodzaje przesyłek</div>
              <div className={styles.typesList}>
                {types.map((type) => (
                  <div className={styles.box}>
                    <div className={styles.typeName}>{type.name}</div>
                    <div className={styles.typeCheckbox}>
                      <SecondCheckbox
                        value={type.active}
                        onChange={(state) =>
                          handleChangeState(type.name, state)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )} */}

          <div className={styles.options}>
            <ChooseMethods
              types={types}
              onChoose={(type) => {
                setTypes(
                  types.map((t) => {
                    if (type?.id === t.id) {
                      t.active = true;
                    }
                    return t;
                  })
                );
              }}
            />
            <ChooseTagNewFilter
              className={styles.choose}
              onChoose={handleChooseTag}
            />
            <SelectFlagNewFilter
              selectedStatus={null}
              onChoose={handleChooseStatus}
            />
          </div>
          <div className={styles.tagList}>
            {types
              .filter((t) => t.active)
              .map((t) => {
                return (
                  // <div className={styles.tag}>
                  //   {<t.image className={styles.tagImage} />} {t.name}
                  //   <CloseIcon
                  //     onClick={(e) => {
                  //       e.stopPropagation();
                  //       setTypes(
                  //         types.map((a) => {
                  //           if (a.id === t.id) {
                  //             t.active = false;
                  //           }
                  //           return a;
                  //         })
                  //       );
                  //     }}
                  //     className={`${styles.remove}`}
                  //   />
                  // </div>
                  <Chip
                    element={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <t.image className={styles.tagImage} /> {t.name}
                      </div>
                    }
                    onDelete={() => {
                      setTypes(
                        types.map((a) => {
                          if (a.id === t.id) {
                            t.active = false;
                          }
                          return a;
                        })
                      );
                    }}
                  />
                );
              })}
            {tags.map((tag) => (
              <Chip
                element={<>{tag.Name}</>}
                onDelete={() => {
                  handleRemoveTag(tag);
                }}
              />
            ))}
            {statuses && (
              <div className={styles.data}>
                {statuses?.map((s) => (
                  <StatusBadge
                    onRemove={(stat) => {
                      setStatuses(statuses?.filter((a) => a.Id !== stat.Id));
                    }}
                    status={s}
                    showName
                  />
                ))}
              </div>
            )}
          </div>

          <div className={styles.buttonsWrapper}>
            <HollowButton text="Usuń" onClick={() => handleDelete()} />
            <Button
              text="Zapisz"
              onClick={() => handleCreate()}
              disabled={!name.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
