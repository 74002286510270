export type { FC } from 'react';

export interface Props {
  name: string;
  className?: string;
  status?: EStatus
  avatar?: string;
}

export enum EStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}