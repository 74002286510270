import messagesService from 'api/messagesService';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PackagesContext from 'contexts/PackagesContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';

import moment from 'moment';
import casesService from 'api/casesService';
import NewSearchEntitiesWithCase from 'components/NewSearchEntitiesWithCase';
import { IEntity } from 'dto/IEntity';
import { IWorkspace, IWorkspaceMember } from 'dto/IWorkspace';
import NewInput from 'components/NewInput';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import workspaceService from 'api/workspaceService';
import Placeholder from 'assets/placeholder.png';
import Input from 'components/Input';
import { useOnClickOutside } from 'usehooks-ts';
import { IDropdownObject } from 'components/Dropdown/typings';
import Dropdown from 'components/Dropdown';
import { generateNewKey } from 'utils/geneareNewKey';
import { reEncrypt } from 'utils/reEncrypt';
import { readAsBase64 } from 'utils/readAsBase64';
import KeysContext from 'contexts/KeysContext';
import { getKeys } from 'utils/getKeys';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';

interface IItem {
  Id?: string;
  Date: Date;
  From: string;
  To: string;
  Privileges: number;
  ToUserId?: string;
}

const PackageEditSharesModal: FC<Props> = ({ toggle, item }) => {
  const { keys } = useContext(KeysContext);
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const { data: sharesData, refetch: refetchShares } =
    messagesService.useGetShare(item.Id, item.Kind);
  const { mutate: removeShare } = messagesService.useRemoveShare();
  const { mutate: createShare } = messagesService.useShare();
  const { data: c } = casesService.useGetCase(item.Id, !!item.Id?.length);
  const [active, setActive] = useState(false);
  const { packages, setPackages } = useContext(PackagesContext);
  const [shares, setShares] = useState<IItem[]>([]);

  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');

  const { data: workspaceData, refetch: refetchWorkspace } =
    workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);

  const [searchedMembers, setSearchedMembers] = useState<IWorkspaceMember[]>(
    []
  );

  const [searchValue, setSearchValue] = useState('');

  const privileges: IDropdownObject[] = [
    {
      value: 0,
      name: 'Brak',
    },
    {
      value: 1,
      name: 'Odczyt',
    },
    {
      value: 2,
      name: 'Edycja',
    },
  ];

  const setPrivileges = (item: IItem, value: IDropdownObject) => {
    if (value.value == 0) {
      // remove
    }
  };

  const onChoose = (member: IWorkspaceMember) => {
    handleShare(member);
  };

  const handleShare = async (member: IWorkspaceMember) => {
    const myKeys = await getKeys(userId!);
    const id = item.Id;
    const Kind = item.Kind;
    const encryptionKeyId = item.EncryptionKeyId;

    if (!encryptionKeyId) {
      createShare(
        {
          Id: id,
          Kind: Kind,
          ToUserId: member.UserId,
        },
        {
          onSuccess: () => {
            refetchShares();
          },
        }
      );

      return;
    }

    const privateKey = await generateNewKey(encryptionKeyId, myKeys, keys);
    console.log(privateKey);
    const uint = await reEncrypt(userId, member.UserId, null, privateKey);
    const readyToSend = await readAsBase64(uint);

    createShare(
      {
        Id: id,
        Kind: Kind,
        ToUserId: member.UserId,
        Keys: [{ Id: encryptionKeyId!, Key: readyToSend }],
      },
      {
        onSuccess: () => {
          refetchShares();
          let newPacks = [...packages];
          let newPack = newPacks.find((p) => p.Id === item.Id);
          if (!newPack) {
            return;
          }

          newPack.IsShared = true;

          setPackages(newPacks);
        },
      }
    );
  };

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }
  });

  useEffect(() => {
    if (!searchValue.length) {
      setSearchedMembers(workspaceMembers);
      return;
    }

    // filtracja

    setSearchedMembers(
      workspaceMembers.filter(
        (m) =>
          m.Label.toLocaleLowerCase().search(
            searchValue.toLocaleLowerCase()
          ) !== -1
      )
    );
  }, [workspaceMembers, searchValue]);

  useEffect(() => {
    if (sharesData) {
      setShares(
        sharesData.data.map((s) => {
          return {
            Id: s.Id,
            Date: s.Date,
            From: s.From,
            To: s.To,
            ToUserId: s.ToUserId,
            Privileges: 2,
          };
        })
      );
    }
  }, [sharesData]);

  const handleDelete = (id: string) => {
    removeShare(
      {
        Id: id,
        Kind: item.Kind,
      },
      {
        onSuccess: () => {
          let newPacks = [...packages];
          let newPack = newPacks.find((p) => p.Id === item.Id);
          if (!newPack) {
            return;
          }

          newPack.IsShared = false;
          setPackages(newPacks);
          // toggle();
          refetchShares();
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Udostępnij
        <CloseIcon onClick={toggle} style={{ cursor: 'pointer' }} />
      </div>

      <div className={styles.content}>
        <div className={styles.dropdown}>
          <div className={styles.search}>
            <Input
              className={styles.d}
              value={searchValue}
              label="Szukaj"
              onChange={(v) => {
                setSearchValue(v);
                setActive(true);
              }}
              onFocus={() => {
                setActive(true);
              }}
            />
          </div>
          {active && (
            <div className={styles.list} ref={outsideRef}>
              {searchedMembers
                .filter((member) => member.UserId)
                .map((member) => (
                  <div
                    onClick={() => {
                      setActive(false);
                      onChoose(member);
                    }}
                    key={member.Id}
                    className={styles.box}
                  >
                    <img src={Placeholder} />
                    {member.Label}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className={styles.shares}>
          {shares?.map((m) => (
            <div className={styles.share}>
              <img className={styles.avatar} src={Placeholder} />
              <div className={styles.label}>{m.To}</div>
              <div className={styles.combo}>
                <Dropdown
                  defaultValue={m.Privileges}
                  className={styles.parcelDropdown}
                  onChange={(r) => setPrivileges(m, r)}
                  list={privileges}
                  noBackground={true}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackageEditSharesModal;
