import { Route, Routes } from 'react-router-dom';
import ClientModule from '.';

const ClientsRoute = () => {
  return (
    <Routes>
      <Route path="/:filterId/:clientId?" element={<ClientModule />} />
      <Route path="/*" element={<ClientModule />} />
    </Routes>
  );
};

export default ClientsRoute;
