export interface ICourtPortalSettings {
  Login: string;
  IsActive: boolean;
  SubaccountId?: string;
  Portals: Array<IUserCourtPortal>;
  DocumentTypes: Array<CourtPortalDocumentType>;
}

export interface IUserCourtPortal {
  Id: number;
  Name: string;
  RulesUrl: string;
  Url: string;
  IsMain: boolean;
  IsActive: boolean;
  LastSync: string;
  Status: CourtPortalSyncState;
  LastErrorMessage: string;
  isNew?: boolean;
}

export enum CourtPortalSyncState {
  NotRunning = 0,
  Running = 1,
  Error = 2,
}

export interface CourtPortalDocumentType {
  Id: number;
  Name: string;
  IsActive: boolean;
}
