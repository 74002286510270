import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import messagesService from 'api/messagesService';
import { useMemo } from 'react';
import { Evidence, IMetric } from 'dto/IMetric';
import moment from 'moment';
import { getBlob } from 'utils/getBlob';
import axios from 'axios';
import { getUser } from 'api/axios';

const MetricModal: FC<Props> = ({ toggle, pack }) => {
  const { data: metricData } = messagesService.useGetMetric(pack.Id, pack.Kind);
  const { mutate: downloadMetric } = messagesService.useDownloadMetric();
  const metric: IMetric = useMemo(() => metricData?.data ?? [], [metricData]);

  const user = getUser();
  const token = user?.access_token;

  const handleDownload = async (e: Evidence) => {
    const blob = await axios.get(
      `${process.env.REACT_APP_API_URL}/message/evidence?id=${e.Id}`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const URL = window.URL.createObjectURL(new Blob([blob.data]));
    const link = document.createElement('a');
    link.href = URL;
    link.setAttribute('download', 'Potwierdzenie.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadAll = async () => {
    const blob = await axios.get(
      `${process.env.REACT_APP_API_URL}/message/evidences?id=${pack.Id}`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const URL = window.URL.createObjectURL(new Blob([blob.data]));
    const link = document.createElement('a');
    link.href = URL;
    link.setAttribute('download', `M-${pack.Id}.zip`);
    document.body.appendChild(link);
    link.click();
  };

  if (!metric) {
    return <></>;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Dowód obsługi przesyłki</div>
          <CloseIcon
            onClick={(e) => {
              toggle(false);
              // e.stopPropagation();
            }}
            className={styles.close}
          />
        </div>
        <div className={styles.body}>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Przesyłka</div>
            <div className={styles.data}>Tytuł: {metric.Subject}</div>
            <div className={styles.data}>
              Identyfikator Przesyłki: {metric.Id}
            </div>
            <div className={styles.data}>Status:</div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Obsługa przesyłki</div>
            <div className={styles.data}>
              Data wpływu:{' '}
              {moment(metric.CreationDate).format('DD.MM.YY HH:mm:ss')}
            </div>
            <div className={styles.data}>
              Data odebrania:{' '}
              {metric.ReceiveDate
                ? moment(metric.ReceiveDate).format('DD.MM.YY HH:mm:ss')
                : 'Nie odebrana'}
            </div>
            {/* <div className={styles.data}>Status: asdf</div> */}
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Nadawca</div>
            <div className={styles.data}>
              {metric?.Sender?.Name} {metric?.Sender?.Surname}
            </div>
            <div className={styles.data}>
              Adres: {metric?.Sender?.EDeliveryAddress}
            </div>
            <div className={styles.data}>
              Nazwa operatora adresu:{' '}
              {metric?.Sender?.ServiceProvider ?? 'Nieznany'}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Odbiorca</div>
            {metric?.Receivers?.map((receiver) => {
              return (
                <div key={receiver.UserId}>
                  <div className={styles.data}>
                    {receiver?.Name} {receiver?.Surname}
                  </div>
                  <div className={styles.data}>
                    Adres: {receiver?.EDeliveryAddress}
                  </div>
                  <div className={styles.data}>
                    Nazwa operatora adresu:{' '}
                    {receiver?.ServiceProvider ?? 'Nieznany'}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>
              Dowody (
              <a onClick={() => handleDownloadAll()}>Pobierz wszystkie</a>)
            </div>
            {metric?.Evidences?.map((e) => {
              return (
                <div className={styles.group}>
                  <div className={styles.data}>{e.Label}</div>
                  <div className={styles.data}>
                    Data utworzenia dowodu:{' '}
                    {moment(e.CreationDate).format('DD.MM.YY HH:mm:ss')}
                  </div>
                  <div
                    onClick={() => handleDownload(e)}
                    className={styles.download}
                  >
                    Pobierz
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricModal;
