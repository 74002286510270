import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Button from 'components/Button';
import { EClientData } from 'dto/IClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const EmailClientModal: FC<Props> = ({ client, refWrapper }) => {
  if (!refWrapper?.current) return <></>;

  const position = refWrapper.current.getBoundingClientRect();

  const emails = client.Data.filter((d) => d.Type === EClientData.EMAIL);

  return ReactDOM.createPortal(
    <div
      style={{
        left: `${position.left}px`,
        top: `${position.top + 32}px`,
      }}
      className={styles.wrapper}
    >
      <div className={styles.title}>
        Email
        <CloseIcon className={styles.icon} />
      </div>
      {emails.map((e) => (
        <div className={styles.box}>
          {e.Value} {e.IsMain && <StarIcon className={styles.icone} />}
        </div>
      ))}
    </div>,
    document.body
  );
};

export default EmailClientModal;
