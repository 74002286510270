import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useDebounce } from 'use-debounce';
import { IEntity } from 'dto/IEntity';
import casesService from 'api/casesService';
import { ReactComponent as ClientIcon } from 'assets/icons/person.svg';
import NewInput from 'components/NewInput';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnClickOutside } from 'usehooks-ts';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const SearchEntitiesNew: FC<Props> = ({
  label,
  onChoose,
  onCreate,
  onClose,
  initOpen,
}) => {
  const [value, setValue] = useState('');
  const [searchValue] = useDebounce(value, 300);
  const [searchList, setSearchList] = useState<IEntity[]>([]);
  const { data: entitiesData, refetch } =
    casesService.useGetAddressBook(searchValue);
  const entities: IEntity[] = useMemo(
    () => entitiesData?.data ?? [],
    [entitiesData]
  );

  const [active, setActive] = useState(false);
  const [deb, setDeb] = useState(false);

  useEffect(() => {
    if (!initOpen) return;
    setActive(true);
  }, []);

  useEffect(() => {
    if (active) {
      setSearchList(entities);
    }
  }, [entities, active]);

  useEffect(() => {
    if (!value.length) {
      setSearchList(entities);
      return;
    }
  }, [value]);

  useEffect(() => {
    if (active) {
      refetch();
    }
  }, [active]);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }

    if (onClose && !label) {
      onClose();
    }
  });

  return (
    <div ref={outsideRef} className={styles.wrapper}>
      <div
        onClick={() => {
          setActive(!active);
          setSearchList([]);
          setValue('');
        }}
        className={styles.card}
      >
        <ClientIcon className={styles.icon} />
        {label ? label : 'Podmioty'}
        {label && (
          <CloseIcon
            onClick={() => {
              // onChoose(null);
              onClose?.();
            }}
            className={styles.icon}
          />
        )}
        {!onClose && (
          <FontAwesomeIcon icon={active ? faCaretUp : faCaretDown} />
        )}
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.search}>
            <NewInput
              focus
              label="Szukaj podmiotu"
              labelClassName={styles.x}
              className={styles.d}
              onClick={() => setActive(true)}
              value={value}
              onChange={(text) => setValue(text)}
            />

            <div
              onClick={() => {
                setDeb(!deb);
              }}
              className={styles.create}
            >
              Nowy podmiot
            </div>
          </div>

          {searchList.map((c) => (
            <div
              onClick={() => {
                onChoose(c);
                setActive(false);
                setSearchList([]);
                setValue('');
              }}
              key={c.Id}
              className={styles.box}
            >
              <div title={c.Label} className={styles.client}>
                {c.Label}
              </div>

              <div className={styles.title}>{c.Address}</div>
            </div>
          ))}
        </div>
      )}

      {deb && (
        <div className={styles.debDropdown}>
          <div
            onClick={() => {
              setActive(false);
              setSearchList([]);
              setValue('');
              onCreate?.(false);
              setDeb(false);
            }}
            className={styles.deb}
          >
            Osoba
          </div>
          <div
            onClick={() => {
              setActive(false);
              setSearchList([]);
              setValue('');
              onCreate?.(true);
              setDeb(false);
            }}
            className={styles.deb}
          >
            Firma
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchEntitiesNew;
