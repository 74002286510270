import { FC, Props } from './typings';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Loading from 'components/Loading';
import { Button as MUIButton } from '@mui/material';

const Button: FC<Props> = ({
  className = '',
  text,
  element,
  onClick,
  handleError = () => {},
  disabled = false,
  loading = false,
}) => {
  return (
    <MUIButton
      style={{ textTransform: 'none', boxShadow: 'none', borderRadius: '8px' }}
      className={`${className} ${styles.bn}`}
      onClick={() => {
        if (loading) return;
        if (disabled) {
          handleError();
          return;
        }
        onClick();
      }}
      variant="contained"
      disabled={disabled}
    >
      {loading ? <Loading withoutText /> : <>{element ? element : text}</>}
    </MUIButton>
    // <div
    //   onClick={() => {
    //     if (loading) return;
    //     if (disabled) {
    //       handleError();
    //       return;
    //     }
    //     onClick();
    //   }}
    //   className={classNames(
    //     styles.button,
    //     disabled && styles.disabled,
    //     loading && styles.disabled,
    //     className
    //   )}
    // >
    //   {loading ? <Loading withoutText /> : <>{element ? element : text}</>}
    // </div>
  );
};

export default Button;
