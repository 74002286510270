import { IError } from 'dto/IError';

export const getErrorMessage = (error?: IError) => {
  if (error && error.errorCodes) {
    var messages = error.errorCodes.map((x) => x.message);

    return messages.join(', ');
  }
  return '';
};
