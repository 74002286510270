import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as DraftIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/blue_delete.svg';
import workspaceService from 'api/workspaceService';

const WorkspaceRoleCard: FC<Props> = ({ refetch, onEdit, role }) => {
  const { mutate: deleteRole } = workspaceService.useDeleteRole();

  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>{role.Name}</div>
      <div className={styles.icons}>
        <div className={styles.edit}>
          {!role.Static && <DraftIcon onClick={() => onEdit(role)} />}
        </div>
        {!role.Static && (
          <DeleteIcon
            onClick={() =>
              deleteRole({ Id: role.Id }, { onSuccess: () => refetch() })
            }
          />
        )}
      </div>
    </div>
  );
};

export default WorkspaceRoleCard;
