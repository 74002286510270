import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { config } from 'config';
import DossierFilterContext, { IMenu } from 'contexts/DossierFilters';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import SecondCheckbox from 'components/SecondCheckbox';
import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';
import { EOrder } from 'components/SortOrder/typings';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const defaultMenu = [
  {
    type: 'name',
    text: 'Rodz.',
    sort: true,
    order: EOrder.ASCENDING,
    hidden: true,
  },
  { type: 'status', text: 'Status', sort: false, order: EOrder.ASCENDING },
  { type: 'tag', text: 'Etykieta', sort: false, order: EOrder.ASCENDING },
  { type: 'size', text: 'Rozmiar', sort: false, order: EOrder.ASCENDING },
  {
    type: 'date',
    text: 'Data modyfikacji',
    sort: false,
    order: EOrder.ASCENDING,
  },
];

const DossierSettings = ({
  menu,
  editFilters,
}: {
  menu: IMenu[];
  editFilters: (type: string) => void;
}) => {
  return (
    <div className={styles.options}>
      <div className={styles.option}>
        <SecondCheckbox
          value={!!menu.find((m) => m.type === 'status')}
          onChange={() => editFilters('status')}
        />
        Status
      </div>
      <div className={styles.option}>
        <SecondCheckbox
          value={!!menu.find((m) => m.type === 'tag')}
          onChange={() => editFilters('tag')}
        />
        Tagi
      </div>
      <div className={styles.option}>
        <SecondCheckbox
          value={!!menu.find((m) => m.type === 'size')}
          onChange={() => editFilters('size')}
        />
        Rozmiar
      </div>
      <div className={styles.option}>
        <SecondCheckbox
          value={!!menu.find((m) => m.type === 'date')}
          onChange={() => editFilters('date')}
        />
        Data modyfikacji
      </div>
    </div>
  );
};

const DossierFilters = () => {
  const { menu, setMenu } = useContext(DossierFilterContext);
  const [options, setOptions] = useState(false);

  // const [sortProp, setSortProp] = useState('name');
  // const [sortOrder, setSortOrder] = useState(EOrder.ASCENDING);

  const onDragEnd = (result: DropResult) => {
    const newMenu = [...menu];

    const source = result.source;
    const destination = result.destination;

    if (!source || !destination) return;

    newMenu[source.index] = menu[destination.index];
    newMenu[destination.index] = menu[source.index];

    setMenu(newMenu);

    localStorage.setItem(config.filters_name, JSON.stringify(newMenu));
  };

  const editFilters = (type: string) => {
    let newMenu = [...menu];

    const isElement = newMenu.find((m) => m.type === type);

    if (!isElement) {
      const element = defaultMenu.find((m) => m.type === type);
      if (!element) return;
      newMenu = [...newMenu, element];
      setMenu(newMenu);
      localStorage.setItem(config.filters_name, JSON.stringify(newMenu));
      return;
    }

    newMenu = newMenu.filter((m) => m.type !== type);
    setMenu(newMenu);
    localStorage.setItem(config.filters_name, JSON.stringify(newMenu));
  };

  const changeSort = (prop: string) => {
    let newMenu = [...menu];

    var currentSort = newMenu.filter((m) => m.sort === true);

    if (currentSort.length > 0 && currentSort[0].type === prop) {
      currentSort[0].order =
        currentSort[0].order === EOrder.ASCENDING
          ? EOrder.DESCENDING
          : EOrder.ASCENDING;
    } else {
      var newSort = newMenu.filter((m) => m.type === prop);

      if (newSort.length > 0) {
        currentSort[0].sort = false;
        newSort[0].sort = true;
      }
    }

    setMenu(newMenu);
    localStorage.setItem(config.filters_name, JSON.stringify(newMenu));
  };

  const t = 144 * menu.filter((x) => !x.hidden).length;
  return (
    <div className={styles.wrapper}>
      {menu
        .filter((x) => x.type === 'name')
        .map((m, index) => {
          return (
            <div className={styles.name} onClick={() => changeSort(m.type)}>
              {m.text}
              <div className={`${styles.arrow} `}>
                {m.sort && (
                  <FontAwesomeIcon
                    icon={
                      m.order === EOrder.ASCENDING ? faArrowUp : faArrowDown
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="filters" type="filters" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{ width: `${t}px` }}
              className={styles.right}
            >
              {menu
                .filter((x) => !x.hidden)
                .map((m, index) => {
                  return (
                    <Draggable index={index} key={m.type} draggableId={m.type}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          className={styles.box}
                          onClick={() => changeSort(m.type)}
                        >
                          {m.text}
                          <div className={`${styles.arrow} `}>
                            {m.sort && (
                              <FontAwesomeIcon
                                icon={
                                  m.order === EOrder.ASCENDING
                                    ? faArrowUp
                                    : faArrowDown
                                }
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className={styles.optionWrapper}>
        <EditIcon
          className={styles.editIcon}
          onClick={() => setOptions(!options)}
        />
        {options && <DossierSettings menu={menu} editFilters={editFilters} />}
      </div>
    </div>
  );
};

// {menu.map((m) => {
//     return <div className={styles.box}>{m.text}</div>;
//   })}

export { DossierFilters, defaultMenu };
