import { FC, Props } from './typings';
import styles from './styles.module.scss';
import casesService from 'api/casesService';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { useState, useContext, useMemo, useEffect, useRef } from 'react';
import EditClientModal from 'components/EditClientModal';
import AddCaseModal from 'components/AddCaseModal';
import { ENotificationType, notification } from 'utils/notification';
import { ICase } from 'dto/Cases/ICase';
import { axiosInstance } from 'api/axios';
import { decryptPrivateKey } from 'utils/decryptPrivateKey';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { getKeys } from 'utils/getKeys';
import * as openpgp from 'openpgp';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { readAsBase64 } from 'utils/readAsBase64';
import { ItemKind } from 'dto/IKindItems';
import KeysContext from 'contexts/KeysContext';
import { generateSingleBinary } from 'utils/GenerateSingleBinary';
import { IPackage } from 'dto/IPackage';
import PackagesContext from 'contexts/PackagesContext';
import messagesService from 'api/messagesService';
import statusService from 'api/statusService';
import { IStatus } from 'dto/IStatus';
import { useNavigate, useParams } from 'react-router-dom';
import SearchCasesNew from 'components/SearchCasesNew';
import { useOnClickOutside } from 'hooks/useClickOutside';
import LoadingContext from 'contexts/LoadingContext';

const AttachCaseModal: FC<Props> = ({
  position,
  toggle,
  refWrapper,
  Package,
  Packages = [],
}) => {
  const navigate = useNavigate();
  const { filterId, status } = useParams();
  const auth = useAuth();
  const { mutate: changeHandle } = messagesService.useChangeHandle();
  var userId = getUserId(auth.user!);
  const { keys } = useContext(KeysContext);
  const { packages, setPackages } = useContext(PackagesContext);
  const { setIsLoading: setModalLoading } = useContext(LoadingContext);
  const [addNewCase, setAddNewCase] = useState(false);
  const [addNewEntity, setAddNewEntity] = useState(false);
  const [settedCase, setSettedCase] = useState<ICase | null>(null);
  const refPosition = refWrapper?.current?.getBoundingClientRect();
  const x = refPosition?.x ?? position?.x ?? 0;
  const y = refPosition?.y ?? position?.y ?? 0;

  const [c, setC] = useState<null | ICaseSimple>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { mutate: attachToCase } = casesService.useAttachToCase();
  const { mutate: attachManyToCase } = casesService.useAttachManyToCase();

  const { data: statusesData, refetch } = statusService.useGetStatuses();

  const [selected, setSelected] = useState(false);

  const statuses: IStatus[] = useMemo(
    () => statusesData?.data ?? [],
    [statusesData]
  );

  const compile = (pack: IPackage) => {
    return new Promise(async (resolve) => {
      const myKeys = await getKeys(userId!);

      if (!c) return;

      setIsLoading(true);

      const p = await axiosInstance.get(
        `/list?id=${pack.Id}&Kind=${pack.Kind}`
      );

      // const privateKey = p?.data?.ObjectKeys?.[0]?.PrivateKey;
      const privateKey = p?.data?.ObjectKeys?.at(0).PrivateKey;

      console.log(privateKey);

      var newPrivateKey: string | null = null;
      var newPublicKey: string | null = null;

      if (pack.EncryptionKeyId) {
        const decryptedPrivateKey = await decryptPrivateKey(
          myKeys!,
          privateKey
        );

        const response = await axiosInstance.get(`/case/keys?id=${c?.Id}`);
        const publicKey = response.data[0].PublicKey;
        const decryptedPublicKey = await readFileFromBase64(
          publicKey,
          'application/other'
        );

        console.log(decryptedPublicKey);
        const s = await openpgp.key.read(new Uint8Array(decryptedPublicKey));

        const { message } = await openpgp.encrypt({
          message: await openpgp.message.fromBinary(decryptedPrivateKey.data),
          publicKeys: [s.keys[0]],
          armor: false,
        });

        console.log(message);

        const uint8 = await new Uint8Array(message.packets.write());
        newPrivateKey = await readAsBase64(uint8);
        newPublicKey = await readAsBase64(new Uint8Array(decryptedPublicKey));
      } else if (Package.Kind == ItemKind.Email && !pack.EncryptionKeyId) {
        const response = await axiosInstance.get(`/case/keys?id=${c?.Id}`);
        const casePublicKey = response.data[0].PublicKey;
        var newKey = await generateSingleBinary(casePublicKey);

        newPrivateKey = newKey.PrivateKey;
        newPublicKey = newKey.PublicKey;
      }

      return resolve({
        CaseId: c.Id,
        ToAttachId: pack.Id,
        ToAttachKind: pack.Kind,
        ObjectKey: newPrivateKey,
        PublicKey: newPublicKey,
        ShowInDossier: selected,
      });
    });
  };

  const handleAttach = async (pack: IPackage) => {
    return new Promise(async (resolve) => {
      try {
        const d: any = await compile(pack);

        attachToCase(
          {
            CaseId: d.CaseId,
            ToAttachId: d.ToAttachId,
            ToAttachKind: d.ToAttachKind,
            ObjectKey: d.ObjectKey,
            PublicKey: d.PublicKey,
            ShowInDossier: d.ShowInDossier,
          },
          {
            onSuccess: () => {
              resolve(true);
            },
            onError: () => {
              resolve(true);

              notification({
                type: ENotificationType.ERROR,
                title: 'Wystąpił błąd 2',
                text: 'Coś poszło nie tak... spróbuj ponownie później',
              });
            },
          }
        );
      } catch (e) {}
    });
  };

  const attachThread = async () => {
    const listResponse = await axiosInstance(
      `/list?ThreadId=${Package.Thread?.Id}`
    );
    const list = listResponse.data;
    let newPacks = [...packages];
    const index = newPacks.findIndex((p) => p.Id === Package.Id);
    const nextPreview = newPacks[index + 1];

    const objects = [];

    for (const p of list.Items) {
      const object = await compile(p);
      objects.push(object);
      let pack = newPacks.find((a) => p.Id === a.Id);
      if (!pack) continue;
      pack.isDelete = true;
    }

    attachManyToCase({
      CaseId: c!.Id,
      ThreadId: Package.Thread!.Id,
      Items: objects.map((o: any) => {
        return {
          Id: o.ToAttachId,
          Kind: o.ToAttachKind,
          ObjectKey: o.ObjectKey,
          PublicKey: o.PublicKey,
        };
      }),
      ShowInDossier: selected,
    });

    setPackages(newPacks);
    if (nextPreview) {
      navigate(
        `/packages/${filterId}/${status}/${nextPreview.Kind}/${nextPreview.Id}`
      );
    }
  };

  const handleTryAttach = async () => {
    setModalLoading(true);

    if (Packages!.length <= 1) {
      if (!!Package.Thread?.Id) {
        await attachThread();
        setModalLoading(false);
        return;
      }

      await handleAttach(Package);
      setModalLoading(false);
      setIsLoading(false);
      let newPacks = [...packages];
      let newPack = newPacks.find((p) => p.Id === Package.Id);
      const index = newPacks.findIndex((p) => p.Id === Package.Id);
      if (!newPack) {
        return;
      }
      newPack.Case = c as ICaseSimple;
      newPack.isDelete = true;
      setPackages(newPacks);
      toggle(false);
      setModalLoading(false);
      setIsLoading(false);
      if (newPacks[index + 1]) {
        navigate(
          `/packages/${filterId}/${status}/${newPacks[index + 1].Kind}/${
            newPacks[index + 1].Id
          }`
        );
      }
      return;
    }

    let newPacks = [...packages];
    const lastElement = Packages[Packages.length - 1];
    if (!lastElement) return;
    const index = newPacks.findIndex((p) => p.Id === lastElement.Id);
    const nextPreview = newPacks[index + 1];
    for (const p of Packages) {
      if (p.Case) {
        continue;
      }

      await handleAttach(p);
      let pack = newPacks.find((a) => p.Id === a.Id);
      if (!pack) continue;
      pack.isDelete = true;
    }
    setPackages(newPacks);
    if (nextPreview) {
      navigate(
        `/packages/${filterId}/${status}/${nextPreview.Kind}/${nextPreview.Id}`
      );
    }

    setModalLoading(false);
    setIsLoading(false);
    toggle(false);
  };

  if (addNewEntity) {
    return (
      <div className={styles.modal}>
        <EditClientModal
          label="Nowa teczka"
          onCancel={() => setAddNewEntity(false)}
          onClose={() => setAddNewEntity(false)}
        />
      </div>
    );
  }

  if (addNewCase) {
    return (
      <div className={styles.modal}>
        <AddCaseModal
          onSave={(ce) => {
            setSettedCase(ce);
            setC(ce);
          }}
          toggle={setAddNewCase}
        />
      </div>
    );
  }

  useEffect(() => {
    const stan = localStorage.getItem('dossier_case');

    if (stan) {
      setSelected(true);
    }
  }, []);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    toggle(false);
  });

  useEffect(() => {
    if (!c) return;
    handleTryAttach();
  }, [c]);

  return (
    <div ref={ref} className={styles.modal}>
      <div className={styles.cases}>
        <SearchCasesNew
          checked={selected}
          addDossier={(s) => setSelected(s)}
          settedCase={settedCase}
          setAddNewEntity={() => {}}
          onChoose={(c) => setC(c)}
          hideControl
          alwaysOpen
        />
      </div>
    </div>
  );
};

export default AttachCaseModal;
