import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as ClientIcon } from 'assets/icons/person.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import workspaceService from 'api/workspaceService';
import { IWorkspace, IWorkspaceMember } from 'dto/IWorkspace';
import NewInput from 'components/NewInput';
import Placeholder from 'assets/placeholder.png';
import { useOnClickOutside } from 'usehooks-ts';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const SearchMemberNew: FC<Props> = ({
  isDisabled = false,
  selected,
  onChoose,
  label,
}) => {
  console.log(isDisabled);
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');

  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);

  const [searchedMembers, setSearchedMembers] = useState<IWorkspaceMember[]>(
    []
  );

  const [searchValue, setSearchValue] = useState('');
  const [active, setActive] = useState(false);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }
  });

  useEffect(() => {
    if (!searchValue.length) {
      setSearchedMembers(workspaceMembers);
      return;
    }

    // filtracja

    setSearchedMembers(
      workspaceMembers.filter(
        (m) =>
          m.Label.toLocaleLowerCase().search(
            searchValue.toLocaleLowerCase()
          ) !== -1
      )
    );
  }, [workspaceMembers, searchValue]);

  return (
    <div ref={outsideRef} className={styles.wrapper}>
      <div
        onClick={(e: any) => {
          if (isDisabled) return;
          const ele = e.target.tagName.toLowerCase();
          const button = e.target.outerHTML.search('button') !== -1;

          if (ele !== 'div' || button) {
            return;
          }

          setActive(!active);
        }}
        className={styles.card}
      >
        {selected ? (
          <>
            {' '}
            <img src={Placeholder} /> {selected.Label}{' '}
          </>
        ) : (
          <>
            <ClientIcon className={styles.icon} />{' '}
            {label ? label : <>Wyznacz</>}
          </>
        )}
        {selected && (
          <CloseIcon
            style={{ paddingLeft: '5px', paddingRight: '5px' }}
            onClick={() => {
              onChoose(null);
            }}
          />
        )}
        {!isDisabled && (
          <FontAwesomeIcon
            onClick={() => {
              setActive(!active);
            }}
            icon={active ? faCaretUp : faCaretDown}
          />
        )}
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.search}>
            <NewInput
              labelClassName={styles.x}
              className={styles.d}
              value={searchValue}
              label="Szukaj osoby"
              onChange={(v) => setSearchValue(v)}
            />
          </div>
          <div className={styles.list}>
            {searchedMembers
              .filter((member) => member.UserId)
              .map((member) => (
                <div
                  onClick={() => {
                    setActive(false);
                    onChoose(member);
                  }}
                  key={member.Id}
                  className={styles.box}
                >
                  <img src={Placeholder} />
                  {member.Label}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchMemberNew;
