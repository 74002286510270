import { axiosInstance } from 'api/axios';
import moment from 'moment';
import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
  }

  async componentDidCatch(error: any, info: any) {
    const time = moment().toJSON();
    const url = window.location.href;
    await axiosInstance.post('/logger', {
      message: `${error.toString()} stack: ${info.componentStack.toString()}`,
      level: 'error',
      url: url,
      timestamp: time,
    });
  }

  render() {
    //@ts-ignore
    return this.props.children;
  }
}
