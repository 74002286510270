import clientsService from 'api/clientsService';
import Client from 'components/Client';
import ClientCard from 'components/ClientCard';
import PanelsLayout from 'components/PanelsLayout';
import { IClient } from 'dto/IClient';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import EditClientModal from 'components/EditClientModal';
import { useNavigate, useParams } from 'react-router-dom';
import EmptyPanel from 'components/EmptyPanel';
import ClientsContext from 'contexts/ClientsContext';
import { useDebounce } from 'use-debounce';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance } from 'api/axios';
import ClientNew from 'components/ClientNew';
import SortOrder from 'components/SortOrder';
import {
  EOrder,
  ETypeOfOrder,
  IOrderObject,
} from 'components/SortOrder/typings';
import Loading from 'components/Loading';
import { parseHTML } from 'utils/parseHTML';
import Search from 'assets/icons/search.gif';
import { ConsoleWriter } from 'istanbul-lib-report';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const ClientsModule = () => {
  const { filterId, clientId } = useParams();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');

  const [debouncedText] = useDebounce(searchText, 300);

  // const { data: clientsData, refetch } = clientsService.useGetClients(
  //   filterId !== 'all' ? filterId : undefined,
  //   debouncedText
  // );

  const [nextPage, setNextPage] = useState(0);

  const separatorRef = useRef(null);
  const scrollWrapper = useRef(null);

  const [listFilter, setListFilter] = useState<IOrderObject>({
    type: ETypeOfOrder.CREATION_DATE,
    order: EOrder.DESCENDING,
  });

  const {
    data,
    refetch,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    ['clients', filterId, listFilter, debouncedText],
    async ({ pageParam = 0 }) => {
      const query = new URLSearchParams(window.location.search);

      if (filterId !== 'all') {
        query.append('FilterId', filterId!);
      }
      if (!!searchText.length) {
        query.append('Text', debouncedText);
      }

      query.append('Page', nextPage.toString());
      query.append('SortProp', listFilter.type);
      query.append('SortOrder', listFilter.order);

      const res = await axiosInstance.get(`/clients?${query}`);
      // return res.data;
      return {
        items: res.data,
        LastPage: res.data.LastPage,
        nextPage: nextPage + 1,
      };
    },
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: false,
      getNextPageParam: (lastPage) => {
        if (!lastPage.LastPage) {
          return nextPage + 1;
        }
        return undefined;
      },
    }
  );

  useEffect(() => {
    setClients([]);
  }, [searchText]);

  useEffect(() => {
    if (!listFilter.initalized) return;

    setNextPage(0);
    setClients([]);
    setTimeout(() => {
      refetch();
    }, 100);
  }, [listFilter]);

  useEffect(() => {
    if (!listFilter.initalized) return;
    refetch();
  }, [filterId]);

  useEffect(() => {
    if (!listFilter.initalized) return;
    setNextPage(0);

    setTimeout(() => {
      refetch();
    }, 100);
  }, [debouncedText]);

  const [selectedEdit, setSelectedEdit] = useState<IClient | null>(null);

  // const clientse: IClient[] = useMemo(
  //   () => data?.data.Items ?? [],
  //   [clientsData]
  // );

  const { clients, setClients } = useContext(ClientsContext);

  const handleChangeSearchBar = (text: string) => setSearchText(text);

  const compileData = async () => {
    if (!data) return;
    setNextPage(data.pages[data.pages.length - 1].nextPage);
    const packs = await Promise.all(
      data?.pages
        .map((page) => {
          return page.items.Items;
        })
        .flat()

      // .sort((left: IPackage, right: IPackage) => {
      //   //@ts-ignore
      //   return right.Unread - left.Unread;
      // })
    );

    setClients(packs);
  };

  useEffect(() => {
    compileData();
  }, [data]);

  const handleScroll = (e: any) => {
    if (!separatorRef.current) return;
    if (isLoading || isFetchingNextPage) return;
    //@ts-ignore
    var rect = separatorRef.current.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    if (isVisible) {
      fetchNextPage();
    }
  };

  const leftPanel = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.order}>
          <SortOrder
            isLoading={false}
            refetch={() => {
              // setNextPage(0);
              // setClients([]);
              // setTimeout(() => {
              //   refetch();
              // }, 100);
            }}
            setListFilter={setListFilter}
            type={listFilter.type}
            order={listFilter.order}
          />
        </div>
        {!clients.length && !isLoading && status !== 'idle' && (
          <div className={styles.wrapper}>
            <div className={styles.empty}>
              <img src={Search} />
              <div>Nie znaleziono wyników dla wybranych kryteriów</div>
            </div>
          </div>
        )}
        {isLoading && <Loading />}
        {!isLoading && (
          <DataGridPremium
            sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
            getRowHeight={() => 'auto'}
            columnHeaderHeight={0}
            onRowsScrollEnd={() => {
              fetchNextPage();
            }}
            onRowClick={(a) => {
              const client = a.row as IClient;
              navigate(`/clients/${filterId}/${client.Id}`);
            }}
            columns={[
              {
                flex: 1,
                field: 'name',
                renderCell: (props) => {
                  const client = props.row as IClient;

                  return (
                    <ClientCard
                      key={client.Id}
                      refetch={() => {}}
                      setSelectedEdit={setSelectedEdit}
                      client={client}
                    />
                  );
                },
              },
            ]}
            hideFooter={true}
            rows={clients.map((c) => {
              //@ts-ignore
              c.id = c.Id;
              return c;
            })}
          />
        )}
      </div>
    );
  };

  const rightPanel = () => {
    if (!clientId) {
      return <EmptyPanel />;
    }

    return (
      <>
        <ClientNew clientId={clientId} />
      </>
    );
  };
  return (
    <>
      {selectedEdit && (
        <EditClientModal
          onClose={() => setSelectedEdit(null)}
          client={selectedEdit}
        />
      )}
      <PanelsLayout
        onChangeSearch={handleChangeSearchBar}
        leftPanel={leftPanel()}
        rightPanel={rightPanel()}
      />
    </>
  );
};
export default ClientsModule;
