import Spinner from 'components/Spinner';
import styles from './styles.module.scss';
import Button from 'components/Button';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { User, UserManager } from 'oidc-client-ts';
import { useEffect } from 'react';
import React from 'react';

const Unauthorized = () => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  React.useEffect(() => {}, [auth, hasTriedSignin]);

  useEffect(() => {
    auth
      .querySessionStatus()
      .then((status) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        } else if (process.env.REACT_APP_HOST_TYPE === 'alfa') {
          auth.signinRedirect();
        } else {
          if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading &&
            !hasTriedSignin
          ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
          }
        }
      })
      .catch((r) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        } else if (process.env.REACT_APP_HOST_TYPE === 'alfa') {
          auth.signinRedirect();
        } else {
          window.location.replace(process.env.REACT_APP_SITE + '/home');
        }
      });
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.text}>Nie jesteś zalogowany</div>
        <Button text="Zaloguj" onClick={() => auth.signinRedirect()}></Button>
      </div>
    </div>
  );
};

export default Unauthorized;
