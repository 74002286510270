import { Claims } from 'dto/User/claims';
import { User } from 'oidc-client-ts';

export const getUserIsActivated = (user: User) => {
  const userStatus = user?.profile[Claims.UserStatusClaim];
  if (userStatus) {
    return userStatus === '10';
  } else {
    return false;
  }
};
