import exportService from 'api/exportService';
import styles from './styles.module.scss';

import { axiosInstance } from 'api/axios';
import classNames from 'classnames';
import * as openpgp from 'openpgp';
import prettyBytes from 'pretty-bytes';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { decryptFile } from 'utils/decryptFile';
import { getBlob } from 'utils/getBlob';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { readFile } from 'utils/readFile';

import { ReactComponent as DiskIcon } from 'assets/icons/credit_card.svg';
const JSZip = require('jszip');
export interface IFileToDownload {
  Id: string;
  FileName: string;
  Path: string;
  Size: number;
  IsEncrypted: boolean;
  Type: string;
}

export interface IDownloadedFile {
  Uri: string;
  FileName: string;
  ContentType: string;
  Url: string;
  KeyId: any;
  KeyPk: any;
  ObjectKey: string;
  ParentKey: string;
  CaseKeyId: any;
  Bucket: string;
}

export const ExportModule = () => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { mutate: prepareDownload } = exportService.usePrepareDownload();

  const [filesToDownload, setFilesToDownload] = useState<IFileToDownload[]>([]);
  const [actualFile, setActualFile] = useState(0);

  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault();

      return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const tryDecodeArmored = async (message: string): Promise<any> => {
    try {
      const myKeys = await getKeys(userId!);
      const xfile = await openpgp.message.readArmored(message);

      const key = (
        await openpgp.key.readArmored(atob(myKeys!.intermidatePrivateKey!.data))
      ).keys[0];
      const decoded = await openpgp.decrypt({
        message: xfile,
        privateKeys: key,
        format: 'binary',
      });
      return new Blob([decoded.data]);
    } catch (e) {
      return null;
    }
  };

  const tryDecode = async (message: ArrayBuffer): Promise<any> => {
    try {
      const myKeys = await getKeys(userId!);
      const xfile = await openpgp.message.read(new Uint8Array(message));

      const key = (
        await openpgp.key.readArmored(atob(myKeys!.intermidatePrivateKey!.data))
      ).keys[0];

      const decoded = await openpgp.decrypt({
        message: xfile,
        privateKeys: key,
        format: 'binary',
      });
      return new Blob([decoded.data]);
    } catch (e) {
      return null;
    }
  };

  const decodeByMyKey = async (file: IDownloadedFile, i: number) => {
    const myKeys = await getKeys(userId!);

    let f: any = null;

    if (file?.Url) {
      const f = await getBlob(file.Url);
      const blob = await readFile(f.data);
      const xfile = await tryDecode(blob);

      return xfile;
    }

    try {
      //@ts-ignore
      const armored = await tryDecodeArmored(file);

      if (!armored) {
        //@ts-ignore
        throw new Error('STOP 1', i);
        // await tryDecode(file);
      }

      return armored;
    } catch (e) {
      return new Blob([f ? f.data : file]);
    }

    //

    // readFileFromBase64()
  };

  const decodeByObjectKey = async (file: IDownloadedFile) => {
    try {
      const f = await getBlob(file.Url);
      const blob = await readFile(f.data);

      const xfile = await decryptFile(
        userId!,
        new Blob([blob]),
        {
          PrivateKey: file.ObjectKey,
        },
        file?.ParentKey
          ? {
              PrivateKey: file.ParentKey,
            }
          : undefined
      );

      return xfile;
    } catch (e) {
      return null;
    }
  };

  const zip = new JSZip();

  function renameDuplicates(
    files: { blob: Blob; name: string; path: string }[]
  ) {
    const nameCount: any = {};

    // Iteruj przez tablicę
    for (const file of files) {
      const { name } = file;

      // Sprawdź, czy ta nazwa już istnieje w nameCount
      if (nameCount[name] === undefined) {
        // Jeśli nie, to dodaj ją do nameCount
        nameCount[name] = 1;
      } else {
        // Jeśli istnieje, dodaj numer indeksu przed rozszerzeniem

        if (!name) {
        } else {
          const dotIndex = name.lastIndexOf('.');
          const fileName = dotIndex !== -1 ? name.slice(0, dotIndex) : name;
          const fileExt = dotIndex !== -1 ? name.slice(dotIndex + 1) : '';
          const newName = `${fileName}(${nameCount[name]}).${fileExt}`;
          nameCount[name]++;

          // Zaktualizuj nazwę w obiekcie
          file.name = newName;
        }
      }
    }

    return files;
  }

  const downloadFiles = async () => {
    const filesToDecode = filesToDownload.length;
    if (!filesToDecode) return;
    let fileNumber = 1;
    const readyFiles: { blob: Blob; name: string; path: string }[] = [];
    const errorFiles: { id: string; name: string }[] = [];

    for (const file of filesToDownload) {
      setActualFile(fileNumber);

      try {
        const response = await axiosInstance.get(
          `/export/download?id=${file.Id}`
        );

        const f: IDownloadedFile = response.data;

        if (!f.KeyId) {
          const blob = await decodeByMyKey(f, fileNumber);
          if (!blob) {
            errorFiles.push({ id: f.Uri, name: f.FileName ?? file.FileName });
            fileNumber++;
            continue;
          }
          readyFiles.push({
            blob: blob,
            name: f.FileName ?? file.FileName,
            path: file.Path,
          });
          fileNumber++;
          continue;
        }

        if (f.KeyId && f.ObjectKey) {
          const blob = await decodeByObjectKey(f);
          if (!blob) {
            errorFiles.push({ id: f.Uri, name: f.FileName ?? file.FileName });
            fileNumber++;
            continue;
          }
          readyFiles.push({
            blob: blob,
            name: f.FileName ?? file.FileName,
            path: file.Path,
          });
          fileNumber++;
          continue;
        }
      } catch (e) {
        errorFiles.push({ id: file.Id, name: file.Path });
        fileNumber++;
        continue;
      }
    }

    const rFiles = await renameDuplicates(readyFiles);

    await Promise.all(
      await rFiles.map((r) => {
        const folder = zip.folder(r.path);
        folder.file(r.name ?? 'unknow.txt', r.blob);
      })
    );

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      const URL = window.URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = URL;
      link.setAttribute('download', 'Eksport.zip');
      document.body.appendChild(link);
      link.click();
    });

    // setFilesToDownload([]);
  };

  const handleDownload = () => {
    prepareDownload(undefined, {
      onSuccess: (data) => {
        setFilesToDownload(data.data);
      },
    });
  };

  useEffect(() => {
    downloadFiles();
  }, [filesToDownload]);

  useEffect(() => {
    handleDownload();
  }, []);

  const percent = (actualFile / filesToDownload.length) * 100;

  const downloadedFiles = filesToDownload
    .filter((f, index) => index <= actualFile)
    .reduce((suma, f) => suma + f.Size, 0);
  const allFiles = filesToDownload.reduce((suma, f) => suma + f.Size, 0);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <DiskIcon />
          <div className={styles.title}>Eksport danych</div>
          <div className={styles.body}>
            Eksport danych jest w toku i może potrwać kilka minut. Aby go
            pomyślnie zakończyć, nie zamykaj przeglądarki ani aplikacji Notaup.
          </div>

          <div className={styles.barWrapper}>
            <div className={styles.stats}>
              {isNaN(percent) ? `0%` : Math.floor(percent) + '%'}
            </div>
            <div className={classNames(styles.bar, styles.big)}>
              <div
                className={styles.used}
                style={{
                  width: isNaN(percent) ? 0 : percent + '%',
                }}
              ></div>
            </div>
            <div className={styles.mg}>
              Pobrano {prettyBytes(downloadedFiles)} / {prettyBytes(allFiles)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
