import FullCalendar from '@fullcalendar/react';
import styles from './styles.module.scss';
import plLocale from '@fullcalendar/core/locales/pl';
import useCalendar from 'hooks/useCalendar';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import CreateActionModal from 'components/CreateActionModal';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import taskService from 'api/taskService';
import moment from 'moment';
import { IFeed } from 'dto/IFeed';
import { useParams } from 'react-router-dom';
import { IninitialValueCreateActionModal } from 'components/CreateActionModal/typings';
import { Props } from './typings';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ReactComponent as CaseIcon } from 'assets/icons/case.svg';
import PackageCaseModal from 'components/PackageCaseModal';
import interactionPlugin, {
  Draggable,
  DropArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import { ENotificationType, notification } from 'utils/notification';
import ActionsContext from 'contexts/ActionsContext';
import { Tooltip } from 'react-tooltip';
import ModalsManagerContext from 'contexts/ModalsManager';
import { ITask } from 'dto/ITask';
import ActionModal from 'components/ActionModal';
import { ReactComponent as StickyIcon } from 'assets/icons/sticky-note.svg';

export function RenderEventContent(
  eventInfo: any,
  ref: any,
  handleChoose: (event: any, id: string) => void,
  handleClickOnCase: (caseId: string) => void
) {
  const props = eventInfo.event._def.extendedProps;

  const isCases = window.location.href.search('cases') !== -1;

  return (
    <div
      onClick={(e) => {
        handleChoose(e, eventInfo.event.id);
      }}
      className={styles.test}
    >
      <div className={styles.color}></div>
      <div className={styles.eventWrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            {!!eventInfo.event.title.length ? (
              eventInfo.event.title
            ) : (
              // <div className={styles.empty}>Brak tytułu</div>
              <></>
            )}
          </div>
          <div ref={ref} className={styles.icons}>
            {props.caseId && !isCases && (
              <CaseIcon
                onClick={() => handleClickOnCase(props.caseId)}
                className={styles.icon}
              />
            )}
            {!!props?.note?.length && <StickyIcon className={styles.icon} />}
          </div>
        </div>
        <div
          data-tooltip-id={`bar-${eventInfo.event.id}`}
          // data-tooltip-content={props.note}
          className={styles.description}
        >
          {/* {props.note} */}
        </div>
        {/* <div className={styles.description}>{eventInfo.event.note}</div> */}
      </div>
      <Tooltip
        place="bottom"
        style={{
          maxWidth: '400px',
          wordBreak: 'break-all',
          display: 'flex',
          // height: 'fit-content',
          whiteSpace: 'normal',
          // background: isOut ? '#BA1A1A' : '#5d49c7',
        }}
        id={`bar-${eventInfo.event.id}`}
      >
        {props.note}
      </Tooltip>
    </div>
  );
}

const ActionsCalendar: FC<Props> = ({
  date,
  type,
  showCase = true,
  initialValues,
  caseId: initialCaseId,
  handleChangeDate,
  search,
}) => {
  const { calendarView } = useParams();
  const { dates, setDates } = useCalendar();
  const [position, setPosition] = useState([0, 0]);
  const [showModal, setShowModal] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { data, refetch } = taskService.useGetFeed(
    dates.start,
    dates.end,
    initialCaseId,
    search
  );
  const { mutate: moveTask } = taskService.useMoveTask();

  const iconsWrapper = useRef(null);

  const [caseId, setCaseId] = useState<string | null>(null);

  const { modals, setModals } = useContext(ModalsManagerContext);

  const [initialValue, setInitialValue] =
    useState<IninitialValueCreateActionModal | null>(null);

  const { tasks, setTasks } = useContext(ActionsContext);

  const ref = useRef(null);
  const events = useMemo(
    () =>
      data?.data?.map((event: IFeed) => ({
        IsFavourite: event.IsFavourite,
        note: event.description,
        allDay: event.allDay,
        id: event.id,
        title: event.title ?? '',
        caseId: event?.case?.Id ?? null,
        start: moment(event.start).toDate(),
        end: event.end
          ? moment(event.end).toDate()
          : moment(event.start).endOf('day').add(1, 'm').toDate(),
      })) ?? [],
    [data]
  );

  useEffect(() => {
    setTasks(events);
  }, [events]);

  const dateClicked = (day: any) => {
    // if (showModal) return;
    setEndDate(null);
    setInitialValue(null);
    setShowModal(day.date);
    setModals({ ...modals, createAction: true });
  };

  const handleClickOnCase = (caseId: string) => {
    setCaseId(caseId);
  };

  useEffect(() => {
    const handleClick = (e: any) => {
      setPosition([e.clientX, e.clientY]);
    };

    document.addEventListener('mousemove', handleClick);

    return () => {
      document.removeEventListener('mousemove', handleClick);
    };
  }, []);

  useEffect(() => {
    if (!ref?.current) return;
    //@ts-ignore
    ref.current.getApi().gotoDate(moment(date).subtract(1, 'day').toDate());

    if (calendarView === 'day') {
      //@ts-ignore
      ref.current.getApi().gotoDate(date);
      setDates({
        start: moment(date).startOf('day').toISOString(),
        end: moment(date).endOf('day').toISOString(),
      });
      return;
    }

    if (calendarView === 'week') {
      //@ts-ignore
      ref.current.getApi().gotoDate(moment(date).startOf('week').toISOString());
      setDates({
        start: moment(date).startOf('week').toISOString(),
        end: moment(date).startOf('week').add(7, 'day').toISOString(),
      });
    }
    if (!calendarView) {
      setDates({
        start: moment(date).startOf('month').toISOString(),
        end: moment(date).add(34, 'd').toISOString(),
      });
    }
  }, [date]);

  const [view, setView] = useState<
    'dayGridWeek' | 'dayGridMonth' | 'dayGridDay' | 'listWeek'
  >('dayGridMonth');
  const [day, setDays] = useState(1);

  useEffect(() => {
    if (!ref?.current) return;
    if (calendarView === 'day' || type === 'dayGridDay') {
      //@ts-ignore
      ref.current
        //@ts-ignore
        .getApi()
        .changeView('timeGridFourDay');
      setView('dayGridDay');
      setDays(1);
      return;
    }
    if (calendarView === 'week' || type === 'dayGridWeek') {
      //@ts-ignore
      ref.current
        //@ts-ignore
        .getApi()
        .changeView('timeGridFourDay');
      setView('dayGridWeek');
      setDays(7);
      return;
    }
    if (calendarView === 'month' || type === 'dayGridMonth') {
      //@ts-ignore
      ref.current
        //@ts-ignore
        .getApi()
        .changeView('dayGridMonth');
      setView('dayGridMonth');
      return;
    }
  }, [ref, calendarView, type]);

  const [edit, setEdit] = useState<IFeed | null>(null);

  const handleChoose = (e: any, id: string) => {
    //

    const tagName = e.target.tagName;

    if (tagName === 'svg') {
      return;
    }

    const c = data?.data?.find((c: any) => c.id === id);
    if (!c) return;

    setEdit(c);
    setInitialValue({
      id: c.id,
      case: c,
      note: c.note ?? c.title,
      fullday: c.allDay,
    });
    setShowModal(moment(c.start).set('h', 12).toDate());
    setEndDate(moment(c.start).set('h', 13).toDate());
  };

  const refe = useRef(null);

  const handleEventDrop = (e: any) => {
    const startDate = moment(e.event.start).toString();
    const endDate = moment(e.event.end).toString();

    // const minutes = moment(e.date).diff()
  };

  const handleEventResize = (e: EventResizeDoneArg) => {
    moveTask(
      {
        Id: e.event.id,
        AllDay: false,
        End: moment(e.event.end).toISOString(),
        Start: moment(e.event.start).toISOString(),
      },
      {
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Coś poszło nie tak',
            text: 'Nie mogliśmy zaaktualizować twojej czynności spróbuj później',
          });
        },
      }
    );
  };

  return (
    <div ref={refe} className={styles.wrapper}>
      {/* {showModal && (
        <ModalLayout
          dock={DockPosition.DISABLED}
          customX={position[0] - 550}
          customY={-position[1] + 200}
          refWrapper={refe}
        >
          <CreateActionModal
            kind={0}
            showCase={showCase}
            refetch={refetch}
            toggle={setShowModal}
            startDate={showModal}
            x={0}
            y={0}
            endDate={endDate}
            initialValue={initialValues ? initialValues : initialValue}
          />
        </ModalLayout>
      )} */}
      {caseId && (
        <ModalLayout
          customX={position[0] - 550}
          customY={-position[1] + 200}
          dock={DockPosition.DISABLED}
          refWrapper={refe}
        >
          <PackageCaseModal CaseId={caseId} toggle={() => setCaseId(null)} />
        </ModalLayout>
      )}
      <FullCalendar
        locale={plLocale}
        // headerToolbar={{
        //   start: 'prev,next',
        //   center: 'title',
        //   end: 'dayGridWeek,dayGridDay', // user can switch between the two
        // }}
        headerToolbar={{
          start: 'today',
          center: 'prev title next',
          end: '',
        }}
        views={{
          timeGridFourDay: {
            type: 'timeGrid',
            firstDay: 1,
            duration: { days: day },
          },
        }}
        dayMaxEvents={3}
        dayMaxEventRows={true}
        // dayHeaderClassNames={}
        initialView={type ? type : view}
        ref={ref}
        nowIndicator={true}
        firstDay={1}
        plugins={[dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin]}
        weekends={true}
        events={tasks}
        allDayText="Całodn."
        droppable={true}
        weekNumberClassNames={styles.trt}
        // selectable={true}
        datesSet={(event) => {
          const startDate = moment(event.start).toISOString();
          const endDate = moment(event.end).toISOString();
          handleChangeDate?.(
            moment(event.start).add(1, 'day').toDate(),
            event.end
          );
          setDates({
            start: startDate,
            end: endDate,
          });
        }}
        displayEventTime
        dateClick={dateClicked}
        select={(e) => {
          // if (showModal) return;
          setEndDate(e.end);
          setInitialValue(null);
          sessionStorage.setItem('action-date', e.start.toString());
          setModals({ ...modals, createAction: true });
          // setShowModal(e.start);
        }}
        selectMinDistance={0}
        editable={true}
        selectable={true}
        // eventDragStop={handleEventDrop}
        // eventDragStart={(e) =>
        // eventDragStop={(e: any) =>
        dropAccept={'true'}
        eventResize={handleEventResize}
        allDayClassNames={styles.event}
        eventClassNames={styles.event}
        eventContent={(event: any) =>
          RenderEventContent(
            event,
            iconsWrapper,
            handleChoose,
            handleClickOnCase
          )
        }
      />

      {edit && (
        <ActionModal
          onChange={() => {
            refetch();
            setEdit(null);
          }}
          initialState={{
            task: null,
            id: edit.id,
            Id: edit.id,
            case: null,
            endDate: moment(edit.end).toDate(),
            startDate: moment(edit.start).toDate(),
            text: edit.description ?? '',
            title: edit.title ?? '',
            allDay: edit.allDay,
          }}
        />
      )}
    </div>
  );
};

export default ActionsCalendar;
