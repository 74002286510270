import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useState } from 'react';
import Button from 'components/Button';
import clientService, { ICreateClient } from 'api/clientService';

interface IData {
  name: string;
  email: string;
  phone: string;
  edelivery: string;
}

const PersonalForm = ({
  data,
  setData,
}: {
  data: IData;
  setData: (data: IData) => void;
}) => {
  return (
    <div className={styles.inputs}>
      <Input
        label="Imie i nazwisko"
        value={data.name}
        onChange={(t) => {
          setData({ ...data, name: t });
        }}
      />
      <Input
        label="Email"
        value={data.email}
        onChange={(t) => {
          setData({ ...data, email: t });
        }}
      />
      <Input
        label="Numer telefonu"
        value={data.phone}
        isPhoneNumber
        onChange={(t) => {
          setData({ ...data, phone: t });
        }}
      />
      <Input
        label="Adres E-doręczeń"
        value={data.edelivery}
        onChange={(t) => {
          setData({ ...data, edelivery: t });
        }}
      />
    </div>
  );
};

const CompanyForm = ({
  data,
  setData,
}: {
  data: IData;
  setData: (data: IData) => void;
}) => {
  return (
    <div className={styles.inputs}>
      <Input
        label="Nazwa"
        value={data.name}
        onChange={(t) => {
          setData({ ...data, name: t });
        }}
      />
      <Input
        label="Email"
        value={data.email}
        onChange={(t) => {
          setData({ ...data, email: t });
        }}
      />
      <Input
        label="Numer telefonu"
        value={data.phone}
        isPhoneNumber
        onChange={(t) => {
          setData({ ...data, phone: t });
        }}
      />
      <Input
        label="Adres E-doręczeń"
        value={data.edelivery}
        onChange={(t) => {
          setData({ ...data, edelivery: t });
        }}
      />
    </div>
  );
};

const AdditionalClientCreate: FC<Props> = ({ onCreateClient, isCompany }) => {
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    edelivery: '',
  });

  const { mutate: createClient } = clientService.useCreateClient();

  const createData = () => {
    const d = [];

    if (!!data.email.length) {
      d.push({
        Type: 'email',
        Value: data.email,
        IsMain: true,
      });
    }
    if (!!data.phone.length) {
      d.push({
        Type: 'phone',
        Value: data.phone,
        IsMain: true,
      });
    }
    if (!!data.edelivery.length) {
      d.push({
        Type: 'edeliveryAddress',
        Value: data.edelivery,
        IsMain: true,
      });
    }

    return d;
  };

  const handleCreate = () => {
    const payload: ICreateClient = {
      Data: createData(),
      IsInstitution: isCompany,
      Name: data.name,
      TagIds: [],
    };

    createClient(payload, {
      onSuccess: (resp) => {
        onCreateClient(resp.data);
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.padding}>
        {!isCompany && <PersonalForm data={data} setData={setData} />}
        {isCompany && <CompanyForm data={data} setData={setData} />}
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={handleCreate}
            text="+ Dodaj"
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalClientCreate;
