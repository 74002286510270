import GridWindow from 'components/GridWindow';
import styles from './styles.module.scss';
import { FC, Props, TypeOfView } from './typings';

import { ReactComponent as AttachIcon } from 'assets/icons/case.svg';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as IncomingIcon } from 'assets/icons/file_download.svg';
import { ReactComponent as DraftIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as OutgoingIcon } from 'assets/icons/file_upload.svg';

import { ReactComponent as CalendarIcon } from 'assets/icons/calenar_sidebar.svg';
import { ReactComponent as LetterIcon } from 'assets/icons/project.svg';
import DeskPackages from 'components/DeskPackages';
import { useState } from 'react';
import DeskCases from 'components/DeskCases';
import DeskLetters from 'components/DeskLetters';
import DeskActions from 'components/DeskActions';

const menu = (type: TypeOfView, setType: (type: TypeOfView) => void) => {
  return (
    <div className={styles.menu}>
      <div
        onClick={() => setType(TypeOfView.CASES)}
        className={`${styles.box} ${
          type === TypeOfView.CASES && styles.activeText
        }`}
      >
        <AttachIcon />
        Teczki
        {type === TypeOfView.CASES && <div className={styles.active}></div>}
      </div>

      <div className={styles.splitter}></div>

      <div
        onClick={() => setType(TypeOfView.LETTERS)}
        className={`${styles.box} ${
          type === TypeOfView.LETTERS && styles.activeText
        } ${styles.outer}`}
      >
        <LetterIcon />
        Pisma
        {type === TypeOfView.LETTERS && <div className={styles.active}></div>}
      </div>

      <div className={styles.splitter}></div>

      <div
        onClick={() => setType(TypeOfView.ACTIONS)}
        className={`${styles.box} ${
          type === TypeOfView.ACTIONS && styles.activeText
        } ${styles.outer}`}
      >
        <CalendarIcon />
        Czynności
        {type === TypeOfView.ACTIONS && <div className={styles.active}></div>}
      </div>
      <div className={styles.splitter}></div>

      <div
        onClick={() => setType(TypeOfView.INCOMING)}
        className={`${styles.box} ${
          type === TypeOfView.INCOMING && styles.activeText
        }`}
      >
        <IncomingIcon />
        Przychodzące
        {type === TypeOfView.INCOMING && <div className={styles.active}></div>}
      </div>

      <div className={styles.splitter}></div>

      <div
        onClick={() => setType(TypeOfView.OUTCOMING)}
        className={`${styles.box} ${
          type === TypeOfView.OUTCOMING && styles.activeText
        } ${styles.outer}`}
      >
        <OutgoingIcon />
        Wychodzące
        {type === TypeOfView.OUTCOMING && <div className={styles.active}></div>}
      </div>
    </div>
  );
};

const MarkedDeskWindow: FC<Props> = ({
  handleRemove,
  id,
  isEditMode = false,
}) => {
  const [type, setType] = useState(TypeOfView.CASES);

  return (
    <GridWindow
      handleRemove={handleRemove}
      id={id}
      isEditMode={isEditMode}
      title={
        <>
          {' '}
          <DeskIcon /> Przypięte
        </>
      }
    >
      <div className={styles.wrapper}>
        {menu(type, setType)}

        <div className={styles.content}>
          {(type === TypeOfView.INCOMING || type === TypeOfView.OUTCOMING) && (
            <DeskPackages type={type} />
          )}
          {type === TypeOfView.CASES && <DeskCases />}
          {type === TypeOfView.LETTERS && <DeskLetters />}
          {type === TypeOfView.ACTIONS && <DeskActions />}

          {/* {type !== TypeOfView.CASES ? (
          ) : (
            <DeskCases />
          )} */}
        </div>
      </div>
    </GridWindow>
  );
};

export default MarkedDeskWindow;
