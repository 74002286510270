import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_l.svg';
import settingService, { ICourtPortalLoginRequest } from 'api/settingsService';
import Input from 'components/Input';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import { getErrorMessage } from 'utils/getErrorMessage';
import Dropdown from 'components/Dropdown';
import { IDropdownObject } from 'components/Dropdown/typings';
import { ICalendarIntegrationSettings } from 'dto/Settings/ICalendarIntegrationSettings';
import { ReactComponent as RemoveIcon } from 'assets/icons/bin.svg';
import {
  CourtPortalSyncState,
  ICourtPortalSettings,
  IUserCourtPortal,
} from 'dto/Settings/ICourtPortalSettings';
import {
  ICourtPortalLoginResponse,
  ICourtPortalUser,
} from 'dto/Settings/ICourtPortalLoginResponse';
import classNames from 'classnames';

export enum CourtPortalSettingsStep {
  Login,
  Confirm,
  Settings,
}

const CourtPortalSettings: FC<Props> = ({
  integrationId,
  onCanceled,
  onSaved,
  handleDisconnect,
  refetchI,
  refetchportals,
}) => {
  const [step, setStep] = useState<CourtPortalSettingsStep | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { data: settingsData } = settingService.useGetCourtPortalSettings();
  const { mutate: loginCourtPortal } = settingService.useLoginCourtPortal();
  const { mutate: confirmCourtPortal } = settingService.useConfirmCourtPortal();
  const { mutate: updateSettings } =
    settingService.useUpdateCourtPortalSettings();

  const [loginData, setLoginData] = useState({ login: '', pass: '' });
  const [courtPortalUser, setCourtPortalUser] =
    useState<ICourtPortalUser | null>(null);

  const [mainPortal, setMainPortal] = useState<IUserCourtPortal | null>(null);
  const [additionalPortals, setAdditionalPortals] = useState<
    IUserCourtPortal[]
  >([]);

  const [portals, setPortals] = useState<Array<IDropdownObject>>([]);
  const [selectedPortal, setSelectedPortal] = useState<IDropdownObject | null>(
    null
  );

  const [prevButtonState, setPrevButtonState] = useState({
    show: true,
    label: 'Anuluj',
    disabled: false,
  });
  const [nextButtonState, setNextButtonState] = useState({
    show: true,
    label: 'Zakończ',
    disabled: false,
  });

  const save = () => {
    switch (step) {
      case CourtPortalSettingsStep.Login:
        loginCourtPortal(
          {
            Login: loginData.login,
            Password: loginData.pass,
            PortalId: selectedPortal?.value as number,
          },
          {
            onSuccess(data, variables, context) {
              var response: ICourtPortalLoginResponse = data.data;
              setError(null);
              setCourtPortalUser(response.User);
              setStep(CourtPortalSettingsStep.Confirm);
              // refetchportals();
            },
            onError(e: any) {
              setError(getErrorMessage(e.response.data));
            },
          }
        );
        break;
      case CourtPortalSettingsStep.Confirm:
        confirmCourtPortal(
          {
            Login: loginData.login,
          },
          {
            onSuccess(data, variables, context) {
              setError(null);
              onCanceled();
              refetchI();
              refetchportals();
              // setStep(CourtPortalSettingsStep.Settings);
            },
            onError(e: any) {
              setError(getErrorMessage(e.response.data));
            },
          }
        );
        break;
      case CourtPortalSettingsStep.Settings:
        updateSettings(
          {
            PortalIds: additionalPortals.map((x) => x.Id),
            DocumentIds: [],
          },
          {
            onSuccess(data, variables, context) {
              setError(null);
              onSaved();
              refetchportals();
            },
            onError(e: any) {
              setError(getErrorMessage(e.response.data));
            },
          }
        );
        break;
    }
  };

  const onChange = (v?: IDropdownObject | IDropdownObject[]) => {
    //@ts-ignore
    if (v?.[1]) {
      return;
    }
    const element = v as IDropdownObject;
    setSelectedPortal(element ?? null);
  };

  const onAdditionalPortalChange = (
    v?: IDropdownObject | IDropdownObject[]
  ) => {
    if (!v) {
      return;
    }

    //@ts-ignore
    if (v?.[1]) {
      const elements = v as IDropdownObject[];

      const map = elements
        .filter((el) => !additionalPortals.find((x) => x.Id === el?.value))
        .map((el) => {
          return {
            Id: el.value as number,
            Name: el.name,
            IsActive: true,
            IsMain: false,
            RulesUrl: '',
            Url: '',
            Status: CourtPortalSyncState.NotRunning,
            LastSync: '',
            LastErrorMessage: '',
            isNew: true,
          };
        });

      setAdditionalPortals([...additionalPortals, map].flat());

      return;
    }

    //@ts-ignore
    const element = v?.[0] as IDropdownObject;
    var c = additionalPortals.find((x) => x.Id == element?.value);
    if (!c && v) {
      setAdditionalPortals([
        ...additionalPortals,
        {
          Id: element.value as number,
          Name: element.name,
          IsActive: true,
          IsMain: false,
          RulesUrl: '',
          Url: '',
          Status: CourtPortalSyncState.NotRunning,
          LastSync: '',
          LastErrorMessage: '',
          isNew: true,
        },
      ]);
    }
  };

  const handleRemoveAdditionalPortal = (id: number) => {
    setAdditionalPortals(additionalPortals.filter((x) => x.Id != id));
  };

  useEffect(() => {
    console.log(settingsData);
    if (settingsData) {
      var resp: ICourtPortalSettings = settingsData.data;

      if (resp.IsActive) {
        setStep(CourtPortalSettingsStep.Settings);
        setLoginData({ login: resp.Login, pass: '' });

        let mainPortal = resp.Portals.filter((x) => x.IsMain);

        if (mainPortal.length > 0) {
          setMainPortal(mainPortal[0]);
        }

        setAdditionalPortals(
          resp.Portals.filter((x) => !x.IsMain && x.IsActive)
        );
      } else {
        setStep(CourtPortalSettingsStep.Login);
        setLoginData({ login: '', pass: '' });
      }

      setPortals(
        resp.Portals.map((x) => {
          return { name: x.Name, value: x.Id.toString() };
        })
      );
    }
  }, [settingsData]);

  useEffect(() => {
    switch (step) {
      case CourtPortalSettingsStep.Login:
        setNextButtonState({
          ...nextButtonState,
          label: 'Zaloguj',
          disabled: true,
        });
        break;
      case CourtPortalSettingsStep.Confirm:
        setNextButtonState({ ...nextButtonState, label: 'Potwierdź' });
        break;
      case CourtPortalSettingsStep.Settings:
        setNextButtonState({ ...nextButtonState, label: 'Zakończ' });
    }
  }, [step]);

  useEffect(() => {
    if (step !== CourtPortalSettingsStep.Login) return;
    if (!selectedPortal) return;
    if (!loginData.login.length || !loginData.pass.length) return;
    setNextButtonState({ ...nextButtonState, disabled: false });
  }, [loginData, selectedPortal]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.pathBar}>
        <div className={styles.backButton}>
          {/* <BackIcon onClick={onCanceled}></BackIcon> */}
        </div>
        {/* Integracje <ArrowLeftIcon></ArrowLeftIcon> Portal sądów powszechnych */}
      </div>

      <div className={styles.wrapper2}>
        {step === CourtPortalSettingsStep.Login && (
          <div className={styles.grow}>
            <div className={styles.hint}>
              Zaloguj się do Portalu Informacyjnego Sądów Powszechnych
            </div>
            <Input
              type="text"
              label="Login"
              onChange={(v) => setLoginData({ ...loginData, login: v })}
              value={loginData.login}
            ></Input>
            <Input
              type="password"
              label="Hasło"
              onChange={(v) => setLoginData({ ...loginData, pass: v })}
              value={loginData.pass}
            ></Input>
            <Dropdown
              allowMulti={false}
              label="Portal informacyjny"
              placeholder="Wybierz portal informacyjny"
              list={portals}
              onChange={onChange}
              allowNull={true}
            ></Dropdown>
          </div>
        )}

        {step === CourtPortalSettingsStep.Confirm && (
          <div className={styles.grow}>
            <div className={styles.hint}>
              Potwierdź dane pochodzące z Portalu Informacyjnego Sądów
              Powszechnych
            </div>
            <div className={styles.confirmDataHeader}>Dane I</div>

            <div className={styles.confirmDataRow}>
              <label>Pesel</label>
              <span>{courtPortalUser?.pesel}</span>
            </div>
            <div className={styles.confirmDataRow}>
              <label>Seria i nr dokumentu tożsamości</label>
              <span>{courtPortalUser?.idNumber}</span>
            </div>
            <div className={styles.confirmDataRow}>
              <label>Pełnomocnik zawodowy</label>
              <span>{courtPortalUser?.unitNumber}</span>
            </div>
            <div className={styles.confirmDataRow}>
              <label>Numer legitymacji</label>
              <span>{courtPortalUser?.cardNumber}</span>
            </div>

            <div className={styles.confirmDataHeader}>Dane II</div>
            <div className={styles.confirmDataRow}>
              <label>Imię</label>
              <span>{courtPortalUser?.firstName}</span>
            </div>
            {courtPortalUser?.secondName && (
              <div className={styles.confirmDataRow}>
                <label>Drugie imię</label>
                <span>{courtPortalUser?.secondName}</span>
              </div>
            )}
            <div className={styles.confirmDataRow}>
              <label>Nazwisko</label>
              <span>{courtPortalUser?.lastName}</span>
            </div>
            <div className={styles.confirmDataRow}>
              <label>Data urodzenia</label>
              <span>{courtPortalUser?.birthDate}</span>
            </div>
            <div className={styles.confirmDataRow}>
              <label>Miejsce urodzenia</label>
              <span>{courtPortalUser?.birthPlace}</span>
            </div>
          </div>
        )}

        {step === CourtPortalSettingsStep.Settings && (
          <div className={styles.settings}>
            <>
              {/* <div className={styles.hint}>
                Twoja integracja z Portalem Informacyjnym Sądów Powszechnych
                jest aktywna.
              </div> */}
              <div className={styles.loginInfo}>
                {/* Nazwa użytkownika z „PI” {loginData.login} */}
              </div>
              {/* <div className={styles.subheader}>Główna apelacja</div> */}

              {mainPortal && (
                <div className={classNames(styles.portal, styles.mainPortal)}>
                  <div className={styles.name}>{mainPortal.Name}</div>
                  <div className={styles.sync}>
                    {mainPortal.LastSync}
                    {!mainPortal.LastSync &&
                      'Oczekiwanie na pierwszą synchronizację'}
                  </div>
                </div>
              )}
              <div className={styles.subheader}>Dodaj kolejną apelację</div>
              <Dropdown
                allowMulti
                label="Portal informacyjny"
                placeholder="Wybierz i dodaj apelację"
                list={portals}
                onChange={onAdditionalPortalChange}
                allowNull={true}
              ></Dropdown>

              <div className={styles.subheader}>Wybrałeś apelacje</div>
              <div className={styles.additionalPortals}>
                {additionalPortals
                  .filter((p) => p.isNew)
                  .map((p) => (
                    <div className={styles.portal}>
                      <div className={styles.name}>{p.Name}</div>
                      <div className={styles.sync}>
                        {p.LastSync}
                        {!p.LastSync &&
                          'Oczekiwanie na pierwszą synchronizację'}
                      </div>

                      <div className={styles.menu}>
                        <RemoveIcon
                          title="Usuń"
                          onClick={() => handleRemoveAdditionalPortal(p.Id)}
                        />
                      </div>
                    </div>
                  ))}
              </div>

              <div className={styles.subheader}>Wcześniej wybrane apelacja</div>
              <div className={styles.additionalPortals}>
                {additionalPortals
                  .filter((p) => !p.isNew)
                  .map((p) => (
                    <div className={styles.portal}>
                      <div className={styles.name}>{p.Name}</div>
                      <div className={styles.sync}>
                        {p.LastSync}
                        {!p.LastSync &&
                          'Oczekiwanie na pierwszą synchronizację'}
                      </div>

                      <div className={styles.menu}>
                        <RemoveIcon
                          title="Usuń"
                          onClick={() => handleRemoveAdditionalPortal(p.Id)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </>
          </div>
        )}
      </div>

      {error && <div className={styles.warn}>{error}</div>}
      <div className={styles.buttons}>
        {step === CourtPortalSettingsStep.Settings && (
          <div className={styles.disconnect}>
            {/* <a onClick={() => handleDisconnect(integrationId)}>Odłącz konto</a> */}
          </div>
        )}
        {prevButtonState.show && (
          <HollowButton
            text={prevButtonState.label}
            onClick={onCanceled}
          ></HollowButton>
        )}
        {nextButtonState.show && (
          <Button
            disabled={nextButtonState.disabled}
            text={nextButtonState.label}
            onClick={save}
          ></Button>
        )}
      </div>
      {step === CourtPortalSettingsStep.Settings && (
        <div className={classNames(styles.hint, styles.disconnect)}>
          {/* W przypadku odłączenia konta dalsza synchronizacja przestanie być */}
          {/* możliwa. */}
        </div>
      )}
    </div>
  );
};

export default CourtPortalSettings;
