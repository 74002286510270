import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useEffect, useMemo, useState } from 'react';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import workspaceService from 'api/workspaceService';
import { IWorkspace, IWorkspaceMember } from 'dto/IWorkspace';

const SearchMemberInWorkspace: FC<Props> = ({ onChoose }) => {
  const [show, setShow] = useState(false);
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');

  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);

  const [searchedMembers, setSearchedMembers] = useState<IWorkspaceMember[]>(
    []
  );

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!searchValue.length) {
      setSearchedMembers(workspaceMembers);
      return;
    }

    // filtracja

    setSearchedMembers(
      workspaceMembers.filter(
        (m) =>
          m.Label.toLocaleLowerCase().search(
            searchValue.toLocaleLowerCase()
          ) !== -1
      )
    );
  }, [workspaceMembers, searchValue]);

  const handleClick = (member: IWorkspaceMember) => {
    setShow(false);
    setSearchValue(member.Label);
    onChoose(member);
  };

  return (
    <div className={styles.wrapper}>
      <Input
        onFocus={() => {
          setShow(true);
        }}
        label="Użytkownik"
        onChange={(t) => setSearchValue(t)}
        value={searchValue}
      />
      {show && (
        <div className={styles.list}>
          <div className={styles.listWrapper}>
            {searchedMembers?.map((c) => (
              <div
                onClick={() => handleClick(c)}
                key={c.Id}
                className={styles.box}
              >
                <div className={styles.title}>{c.Label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchMemberInWorkspace;
