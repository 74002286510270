import { decryptPrivateKey } from './decryptPrivateKey';
import { getKeys } from './getKeys';
import { readFileFromBase64 } from './readFileFromBase64';
import * as openpgp from 'openpgp';

export interface IImages {
  id: string;
  content: any;
}

export const decodeAndFetchImages = async (
  userId: string,
  data: any,
  ObjectKey: any,
  parentKey?: any
): Promise<IImages[]> => {

  let privateKey = null;
  const myKeys = await getKeys(userId);
  
  if(parentKey) {
    const decryptParentKey = await decryptPrivateKey(myKeys!, parentKey.PrivateKey);
    const text = await openpgp.key.read(decryptParentKey.data);
    const PK = await readFileFromBase64(ObjectKey.PrivateKey, 'application/other');
    const msg = await openpgp.message.read(new Uint8Array(PK));

    
    var options: openpgp.DecryptOptions = {
      message: msg,
      privateKeys: text.keys[0],
      format: 'binary',
    };
    
    privateKey = await openpgp.decrypt(options);
  } else {
    privateKey = await decryptPrivateKey(myKeys!, ObjectKey.PrivateKey);
  }


  const key = await openpgp.key.read(privateKey.data);

  if (ObjectKey.EmptyPassphrase) {
    key.keys[0].decrypt('');
  }

  const images = data.Images;

  const decodedImages = [];

  for (const image of images) {
    const id = image.Id;
    const data = image.Data;
    const decodedData = await readFileFromBase64(data, 'application/other');
    const msg = await openpgp.message.read(new Uint8Array(decodedData));

    const content = await openpgp.decrypt({
      // Próba deszyfrowania
      message: msg,
      privateKeys: key.keys[0],
      format: 'binary',
    });

    
    decodedImages.push({ id: id, content: content });
  }

  return decodedImages;
};
