import keysService from 'api/keysService';
import { ReactComponent as GoalImg } from 'assets/icons/goal.svg';
import { ReactComponent as NotaIcon } from 'assets/icons/nota.svg';
import EDelivery from 'components/Activation/EDelivery';
import Identity from 'components/Activation/Identity';
import RecoveryPackage from 'components/Activation/RecoveryPackage';
import TwoFactorAuthentication from 'components/Activation/TwoFactorAuthentication';
import HollowButton from 'components/HollowButton';
import LoadingScreen from 'components/LoadingScreen';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { getUserIdentityVerified } from 'utils/getUserIdentityVerified';
import { getUserIsActivated } from 'utils/getUserIsActivated';
import styles from './styles.module.scss';
import { getUserClaim } from 'utils/getUserClaim';
import { Claims } from 'dto/User/claims';
import AuthLayout from 'components/AuthLayout';
import { ESteps } from 'components/Stepper';

export enum ActivationSteps {
  RecoveryKey = 'recoveryKey',
  Identity = 'identity',
  EDelivery = 'edelivery',
  TwoFactorAuth = 'twoFactorAuth',
}

const NormalSteps = [
  {
    type: ESteps.INFORMATION,
    title: 'Twoje dane',
    text: 'Wpisz email i hasło bądź wybierz metodę logowania',
  },
  {
    type: ESteps.ID_VERIFICATION,
    title: 'Weryfikacja tożsamości',
    text: 'Wybierz sposób weryfikacji',
  },
  {
    type: ESteps.TWO_FACTOR,
    title: 'Weryfikacja dwuetapowa',
    text: 'Skonfiguruj sposób weryfikacji dwuetapowej.',
  },
  {
    type: ESteps.KEY_GENERATION,
    title: 'Klucz przywracania',
    text: 'Generuj Swój klucz przywracania',
  },
];

const guestSteps = [
  {
    type: ESteps.INFORMATION,
    title: 'Twoje dane',
    text: 'Wpisz email i hasło bądź wybierz metodę logowania',
  },
  {
    type: ESteps.KEY_GENERATION,
    title: 'Klucz przywracania',
    text: 'Generuj Swój klucz przywracania',
  },
];

const ActivationModule = () => {
  const auth = useAuth();
  // const isInvitation = auth?.user?.profile?.invitation ?? null;
  const navigate = useNavigate();
  const [s, setS] = useState(1);

  const {
    data: recoveryPackage,
    isLoading: isLoadingRecoveryPackage,
    isSuccess: isSuccessRecoveryPackage,
    isError: isErrorRecoveryPackage,
  } = keysService.useGetRecoveryPackage();

  const [step, setStep] = useState<string | null>(null);

  const handleNextStep = () => {
    switch (step) {
      case ActivationSteps.Identity:
        setS(2);
        setStep(ActivationSteps.TwoFactorAuth);
        break;
      case ActivationSteps.TwoFactorAuth:
        setS(3);
        setStep(ActivationSteps.RecoveryKey);
        break;
      case ActivationSteps.RecoveryKey:
        setStep(ActivationSteps.EDelivery);
        break;
      case ActivationSteps.EDelivery:
        auth.signinSilent();
        break;
    }
  };

  const handleSkipStep = () => {
    switch (step) {
      case ActivationSteps.EDelivery:
        auth.signinSilent();
        break;
    }
  };

  const logout = () => {
    auth.signoutRedirect();
  };

  useEffect(() => {
    if (isSuccessRecoveryPackage) {
      if (auth.user && getUserIdentityVerified(auth.user)) {
        setStep(ActivationSteps.RecoveryKey);
      } else {
        setStep(ActivationSteps.Identity);
      }
    } else {
      const userClaim = getUserClaim(auth.user!, Claims.invitation);
      if (!!userClaim?.length) {
        setStep(ActivationSteps.RecoveryKey);
        return;
      }

      if (auth.user && getUserIdentityVerified(auth.user)) {
        setStep(ActivationSteps.TwoFactorAuth);
      } else {
        setStep(ActivationSteps.Identity);
      }
    }
  }, [recoveryPackage]);
  const isGuest = auth?.user?.profile?.role === 'guest';
  //@ts-ignore
  const isInvitation = !!auth?.user?.profile?.invitation?.length ?? false;

  useEffect(() => {
    if (isGuest) {
      setStep(ActivationSteps.RecoveryKey);
    }

    if (auth.user && getUserIsActivated(auth.user)) {
      navigate('/');
    }
  }, [auth.user]);

  useEffect(() => {
    if (isSuccessRecoveryPackage && isGuest) {
      navigate('/packages/1B8AE8D3-3209-497D-9526-27608FFFA84D/incoming');
    }
  }, [isSuccessRecoveryPackage]);

  if (isLoadingRecoveryPackage) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <AuthLayout
      actualStep={!isGuest ? s : 1}
      steps={!isGuest && !isInvitation ? NormalSteps : guestSteps}
    >
      <>
        {step === ActivationSteps.RecoveryKey && (
          <RecoveryPackage
            nextStep={handleNextStep}
            hasRecoveryPackage={isSuccessRecoveryPackage}
          ></RecoveryPackage>
        )}
        {step === ActivationSteps.Identity && (
          <Identity nextStep={handleNextStep}></Identity>
        )}
        {step === ActivationSteps.EDelivery && (
          <EDelivery
            nextStep={handleNextStep}
            skipStep={handleSkipStep}
          ></EDelivery>
        )}
        {step === ActivationSteps.TwoFactorAuth && (
          <TwoFactorAuthentication
            nextStep={handleNextStep}
          ></TwoFactorAuthentication>
        )}
      </>
    </AuthLayout>
  );
};
export default ActivationModule;
