import { RefObject, createContext } from "react";

export interface IDecryptedMsg {
    id: string;
    text: string;
}

type IMsgContext = {
    decryptedMsgs: IDecryptedMsg[];
    setDecryptedMsgs: (msgs: IDecryptedMsg[]) => void;
}

const DecryptedMsgs = createContext<IMsgContext>({
    decryptedMsgs: [],
    setDecryptedMsgs: () => {}
})

export default DecryptedMsgs;