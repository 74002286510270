import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { IReceiver } from 'dto/IPackage';
import Avatar from 'components/Avatar';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

const copy = (receiver: IReceiver) => {
  navigator.clipboard.writeText(receiver.Email ?? receiver.Name);
};

const Receiver = ({ receiver }: { receiver: IReceiver }) => {
  return (
    <div className={styles.box}>
      <div className={styles.name}>{receiver.Name}</div>
      <div className={styles.copy} onClick={() => copy(receiver)}>
        <CopyIcon title="Kopiuj"></CopyIcon>
      </div>
    </div>
  );
};

const PackageReceiversModal: FC<Props> = ({ pack, cc, toggle }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        Lista odbiorców
        <CloseIcon onClick={toggle} className={styles.closeIcon} />
      </div>
      <div className={styles.body}>
        {pack.Receivers.filter(
          (r) => (!cc && !r.Cc && !r.Bcc) || (cc && (r.Cc || r.Bcc))
        ).map((r) => (
          <Receiver receiver={r} />
        ))}
      </div>
    </div>
  );
};

export default PackageReceiversModal;
