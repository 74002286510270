import { FC, Props } from './typings';
import styles from './styles.module.scss';
import listService from 'api/listService';
import { getListName } from 'utils/getListName';
import Avatar from 'components/Avatar';
import { IPackage } from 'dto/IPackage';
import {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Loading from 'components/Loading';
import PDFViewer from 'components/PDFViever';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import EmailService from 'api/emailService';
import classNames from 'classnames';
import { decryptPackage } from 'utils/decryptPackage';
import PackThreadRenderer from 'components/PackThreadRenderer';

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as UnCompleteIcon } from 'assets/icons/unhandled.svg';
import { ReactComponent as CompleteTask } from 'assets/icons/done.svg';
import { ReactComponent as HashIcon } from 'assets/icons/hash2.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as AttachIcon } from 'assets/icons/link.svg';
import { ReactComponent as DeskIcon } from 'assets/icons/desk_hover.svg';
import { ReactComponent as ReplyIcon } from 'assets/icons/reply.svg';

import PackagesContext from 'contexts/PackagesContext';
import deskService from 'api/deskService';
import messagesService, { IChangeHandle } from 'api/messagesService';
import ReactDOM from 'react-dom';
import AddTagModal from 'components/AddTagModal';
import ChatActionsList from 'components/ChatActionList';
import CreateActionModal from 'components/CreateActionModal';
import AttachCaseModal from 'components/AttachCaseModal';
import ModalsManagerContext from 'contexts/ModalsManager';
import OptionsCard from 'components/OptionsCard/OptionsCard';

const PackageThread: FC<Props> = ({
  className,
  onDownload,
  showPDF,
  setShowPDF,
  headerClassName,
  listName,
  listId,
  onPreview,
  listFilter,
}) => {
  const { PDFId, threadId, threadPackageId } = useParams();
  const refWrapper = useRef(null);
  const {
    data: packageData,
    isLoading,
    refetch,
  } = listService.useGetPackage(
    {
      ThreadId: threadId,
    },
    listFilter
  );

  const Packages = useMemo(() => packageData?.data.Items ?? [], [packageData]);
  const pack = Packages.find((pack: IPackage) => pack.Id === threadPackageId);
  const keys = useMemo(() => packageData?.data.ObjectKeys ?? [], [packageData]);

  if (isLoading || (!!PDFId?.length && showPDF === null)) {
    return (
      <div ref={refWrapper} className={styles.loadingWrapper}>
        <Loading />
      </div>
    );
  }

  if (showPDF && !!PDFId?.length) {
    return (
      <span ref={refWrapper}>
        <PDFViewer file={showPDF} />{' '}
      </span>
    );
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={`${styles.header} ${headerClassName}`}>
        <div ref={refWrapper} className={styles.options}>
          {pack && (
            <OptionsCard
              refWrapper={refWrapper}
              pack={
                Packages.find((p: IPackage) => p.Id === threadPackageId) ?? pack
              }
              selectedElements={[]}
              showOptions={true}
              className={styles.opt}
              onClickAttachIcon={() => {}}
              refetch={refetch}
              debug
            />
          )}
        </div>
      </div>
      <div className={styles.listek}>
        {Packages.map((pack: IPackage) => (
          <PackThreadRenderer
            onPreview={onPreview}
            onDownload={onDownload}
            refetch={refetch}
            setShowPDF={setShowPDF}
            pack={pack}
            keys={keys}
          />
        ))}
      </div>
    </div>
  );
};

export default PackageThread;
