import { FC, Props } from './typings';

import { Autocomplete, TextField } from '@mui/material';
import casesService from 'api/casesService';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

const SearchCasesNew: FC<Props> = ({
  setAddNewEntity,
  className,
  classNameCard,
  defaultValue,
  isError,
  onBlur,
  onChoose,
  settedCase,
  hideControl,
  alwaysOpen,
  addDossier,
  checked,
  variant = 'filled',
}) => {
  const tRef = useRef<HTMLInputElement>(null);
  const height = 48;
  const labelOffset = -4;
  const isCase = window.location.href.search('cases') !== -1;

  const [searchedCaseId, setSearchedCaseId] = useState<null | string>(null);
  const [stopSearchById, setStopSearchById] = useState(false);

  const ref = useRef(null);
  const [active, setActive] = useState(alwaysOpen ? true : false);
  const [value, setValue] = useState('');
  const [searchList, setSearchList] = useState<ICaseSimple[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const [debouncedValue] = useDebounce(value, 300);

  const { data: casesData, refetch } = casesService.useGetSimpleCases({
    text: debouncedValue,
    id: searchedCaseId && !debouncedValue ? searchedCaseId : undefined,
  });

  const cases: ICaseSimple[] = useMemo(
    () => casesData?.data ?? [],
    [casesData]
  );

  const outsideRef = useRef(null);

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useOnClickOutside(outsideRef, () => {
    if (active && !alwaysOpen) {
      setActive(false);
    }
  });

  useEffect(() => {
    if (searchedCaseId && !stopSearchById) {
      //@ts-ignore
      const c = cases?.Result?.Items[0];
      if (c) {
        setSearchedCaseId(null);
        setValue(c.Title);
        // onChoose?.(c);
        setStopSearchById(true);
        onChoose?.(c);
        return;
      }
    }

    console.log(settedCase);

    if (settedCase) {
      //@ts-ignore
      console.log('jestem tutaj', settedCase);
      setValue(`${settedCase.Title}`);
      forceUpdate();
      return;
    }

    if (defaultValue) {
      if (!cases) return;
      //@ts-ignore
      const c = cases?.Result?.Items?.find((Case) => Case.Id === defaultValue);
      if (c) {
        setValue(c.Title);
        onChoose?.(c);
      }
    }

    if (isCase) {
      const packs = window.location.href.split('/');
      const index = packs.findIndex((t) => t === 'cases');
      const caseId = packs.at(index + 1);
      setSearchedCaseId(caseId!);
    }

    //@ts-ignore
    setSearchList(cases?.Result?.Items);
  }, [settedCase, cases, tRef]);

  useEffect(() => {
    refetch();
  }, [debouncedValue]);

  const handleClick = (cas: ICaseSimple) => {
    setValue(`${cas.Title}`);
    setActive(false);
    onChoose?.(cas);
  };

  return (
    <Autocomplete
      open={alwaysOpen}
      disablePortal
      inputValue={value}
      id="combo-box-demo"
      onChange={(e, obj) => {
        const c = searchList.find((a) => a.Id === obj?.id);
        onChoose?.(c!);
      }}
      options={
        searchList?.map((s) => {
          return {
            label: s.Title,
            id: s.Id,
          };
        }) ?? []
      }
      // sx={{
      //   width: '100%',
      //   '& .MuiInputBase-root': { height: '10px' },
      //   '&:hover:not(.Mui-focused)': {
      //     '& .MuiOutlinedInput-notchedOutline': {
      //       borderColor: '#C9C5D0',
      //     },
      //   },
      // }}
      renderInput={(params) => (
        <TextField
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          variant={variant}
          onChange={(e) => setValue(e.target.value)}
          {...params}
          value={value}
          InputLabelProps={{
            style: {
              height,
              top: isFocused ? `-4px` : `-4px`,
            },
          }}
          sx={{
            '& .MuiInputBase-root': { height: '48px' },
          }}
          label="Teczka"
          style={{
            width: '100%',
            height: height,
          }}
          /* styles the label component */
        />
      )}
    />
  );
};

export default SearchCasesNew;
