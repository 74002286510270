import { IDropdownObject } from 'components/Dropdown/typings';

const legalForms: IDropdownObject[] = [
  {
    value: 13,
    name: 'Jednoosobowa działalność gospodarcza',
  },
  {
    value: 19,
    name: 'Niepubliczne szkoły, zespoły szkół i placówki oświatowe',
  },
  {
    value: 20,
    name: 'Oddział zagranicznego pzedsiębiorcy',
  },
  {
    value: 23,
    name: 'Organizacja pożytku publicznego',
  },
  {
    value: 24,
    name: 'Organizacja pracodawców',
  },
  {
    value: 28,
    name: 'Prosta spółka akcyjna',
  },
  {
    value: 31,
    name: 'Przedszkole niepubliczne',
  },
  {
    value: 37,
    name: 'Spółdzielnia',
  },
  {
    value: 39,
    name: 'Spółka akcyjna',
  },
  {
    value: 40,
    name: 'Spółka europejska',
  },
  {
    value: 41,
    name: 'Spółka jawna',
  },
  {
    value: 42,
    name: 'Spółka komandytowa',
  },
  {
    value: 43,
    name: 'Spółka komandytowo-akcyjna',
  },
  {
    value: 44,
    name: 'Spółka partnerska',
  },
  {
    value: 45,
    name: 'Spółka z ograniczoną odpowiedzialnością',
  },
  {
    value: 46,
    name: 'Stowarzyszenie rejestrowe',
  },
];

export const getLegalForms = () => {
  return legalForms;
};
