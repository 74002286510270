import { RefObject, createContext } from "react";

export interface IPlugin {
    actual: boolean;
    version: string;
    linkWindows: string;
    linkMac: string;
    isMac: boolean;
    isWindows: boolean;
}

type IPluginContext = {
    plugin: IPlugin;
    setPlugin: (pdf: IPlugin) => void;
}

const CPluginContext = createContext<IPluginContext>({
    plugin: {
        actual: true,
        version: '0',
        linkWindows: '',
        linkMac: '',
        isMac: false,
        isWindows: false
    },
    setPlugin: () => {}
})

export default CPluginContext;