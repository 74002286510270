import { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';
import { IWorkspace, IWorkspaceMember } from 'dto/IWorkspace';
import workspaceService from 'api/workspaceService';
import { getWorkspaceId } from 'utils/getWorkspaceId';

const Plans = () => {
  return (
    <>
      <div className={styles.group}>
        <div className={styles.left}></div>
        <div className={styles.right}>
          <div className={styles.boxe}>Plany taryfowe</div>
          <div className={styles.boxe}>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>299 PLN</div>
            <div className={styles.insideBox}>799 PLN</div>
          </div>
          <div className={styles.boxe}>Roczna opłata abonamentowa</div>
          <div className={styles.boxe}>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>299 PLN</div>
            <div className={styles.insideBox}>799 PLN</div>
          </div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.left}>Nota up. przesyłki szyfrowane</div>
      </div>

      <div className={styles.group}>
        <div className={styles.left}>Odbieranie </div>
        <div className={styles.right}>
          <div className={styles.boxe}>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.left}>Odbieranie na koszt adresata  </div>
        <div className={styles.right}>
          <div className={styles.boxe}>
            <div className={styles.insideBox}>1 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.left}>Wysyłanie</div>
        <div className={styles.right}>
          <div className={styles.boxe}>
            <div className={styles.insideBox}>1 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.left}>E -doręczenia </div>
      </div>

      <div className={styles.group}>
        <div className={styles.left}>Odbieranie</div>
        <div className={styles.right}>
          <div className={styles.boxe}>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
            <div className={styles.insideBox}>0 PLN</div>
          </div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.left}>Wysyłanie</div>
        <div className={styles.right}>
          <div className={styles.boxe}>według cennika Poczty Polskiej</div>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.left}>Moduł EZD</div>
        <div className={styles.right}>
          <div className={styles.boxe}>
            <div style={{ width: '262px' }} className={styles.insideBox}>
              usługa nieaktywna
            </div>
            <div
              style={{ width: '130px', borderRight: 'none' }}
              className={styles.insideBox}
            >
              usługa aktywna
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Choose = () => {
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');
  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);

  return (
    <table dir="auto">
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td colSpan={4}></td>
          <td colSpan={2}>Koniec abonamentu:</td>
        </tr>
        <tr>
          <td rowSpan={2}></td>
          <td rowSpan={2}>
            <p data-sourcepos="36:1-36:18">Odbiera przesyłki</p>
            <p data-sourcepos="38:1-38:23">“Na koszt adresata”</p>
          </td>
          <td colSpan={3}>Plan taryfowy</td>
          <td colSpan={2}>Opłaty</td>
        </tr>
        <tr>
          <td>Zero</td>
          <td>Średni</td>
          <td>Duży</td>
          <td>Abonament</td>
          <td>Status</td>
        </tr>
        {workspaceMembers.map((work: IWorkspaceMember) => {
          return (
            <tr>
              <td style={{ maxWidth: '160px', overflow: 'hidden' }}>
                {work.Label}
              </td>
              <td>
                0 <br />
                PLN
              </td>
              <td style={{ maxWidth: '500px' }}>0 PLN</td>
              <td>
                0 <br />
                PLN
              </td>
              <td>0 PLN</td>
              <td>
                0 <br />
                PLN
              </td>
              <td>Opłacony</td>
            </tr>
          );
        })}
        {/* <tr>
          <td>Iwona Biała</td>
          <td></td>
          <td>100 PLN</td>
          <td></td>
          <td></td>
          <td></td>
          <td>Opłacony</td>
        </tr>
        <tr>
          <td>Leszek Czerwony</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Opłacić</td>
        </tr>
        <tr>
          <td>Jan Zielony</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Wygasł&nbsp;</td>
        </tr> */}
      </tbody>
    </table>
  );
};

const Packages = () => {
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');
  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);

  return (
    <table dir="auto">
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td colSpan={2}></td>
          <td>Miesiąc</td>
          <td>Styczeń</td>
        </tr>
        <tr>
          <td></td>
          <td>E-doręczenia</td>
          <td colSpan={2}>Przesyłki szyfrowane</td>
          <td rowSpan={2}>Suma kosztów przesyłek&nbsp;</td>
          <td rowSpan={2}>Pobierz listę przesyłek</td>
        </tr>
        <tr>
          <td></td>
          <td>Wysłane</td>
          <td>Wysłane</td>
          <td>Na koszt adresata</td>
        </tr>
        <tr>
          <td>Cały zespół</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {workspaceMembers.map((m: IWorkspaceMember) => {
          return (
            <tr>
              <td>{m.Label}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const PaymentsTab: FC<Props> = ({}) => {
  const [actualView, setActualView] = useState(ETabs.PLANS);

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <div
          onClick={() => setActualView(ETabs.PLANS)}
          className={`${styles.box}  ${
            actualView === ETabs.PLANS && styles.activeText
          }`}
        >
          Plany i taryfy
        </div>
        <div
          onClick={() => setActualView(ETabs.SELECT)}
          className={`${styles.box}  ${
            actualView === ETabs.SELECT && styles.activeText
          }`}
        >
          Wybór planu
        </div>
        <div
          onClick={() => setActualView(ETabs.PACKAGES)}
          className={`${styles.box}  ${
            actualView === ETabs.PACKAGES && styles.activeText
          }`}
        >
          Zestawienie przesyłek
        </div>
        <div
          onClick={() => setActualView(ETabs.FV)}
          className={`${styles.box}  ${
            actualView === ETabs.FV && styles.activeText
          }`}
        >
          Faktury
        </div>
      </div>

      {actualView === ETabs.PLANS && <Plans />}
      {actualView === ETabs.SELECT && <Choose />}
      {actualView === ETabs.PACKAGES && <Packages />}
    </div>
  );
};

export default PaymentsTab;
