import { IPackage } from "dto/IPackage";
import { createContext } from "react";

type IPackageContext = {
    packages: IPackage[];
    setPackages: (packages: IPackage[]) => void;
}

const PackagesContext = createContext<IPackageContext>({
    packages: [],
    setPackages: () => {}
})

export default PackagesContext;