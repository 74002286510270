import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { DndProvider } from 'react-dnd';
import {
  Tree,
  MultiBackend,
  getBackendOptions,
} from '@minoru/react-dnd-treeview';
import { useEffect, useRef, useState } from 'react';
import AddButton from 'components/AddButton';
import AddNewNode from 'components/AddNewNode';
import workspaceService from 'api/workspaceService';

const WorkspaceStructure: FC<Props> = ({
  workspaceMembers,
  workspaceStructures,
  refetch,
  myPrivilages,
  onClick,
}) => {
  const { mutate: moveStructure } = workspaceService.useMoveStructure();
  const { mutate: moveUser } = workspaceService.useMoveMembershipStructure();
  const [treeData, setTreeData] = useState<any[]>([]);

  const ref = useRef(null);

  // {
  //   id: 2,
  //   parent: 1,
  //   text: 'File 1-1',
  //   data: {
  //     fileType: 'csv',
  //     fileSize: '0.5MB',
  //   },
  // },
  useEffect(() => {
    const structure = workspaceStructures.map((m) => {
      return {
        id: m.Id,
        parent: m.ParentId ?? 0,
        text: m.Name,
        droppable: true,
        isOpen: true,
      };
    });

    const members = workspaceMembers.map((m) => {
      return {
        id: m.Id,
        parent: m.StructureId ?? 0,
        text: m.Label,
        data: {
          isUser: true,
        },
      };
    });

    setTreeData([structure, members].flat());
  }, [workspaceStructures]);

  useEffect(() => {
    //@ts-ignore
    ref?.current?.openAll();
  }, [treeData]);

  const handleDrop = (newTree: any, a: any) => {
    setTreeData(newTree);

    if (a?.dragSource?.data?.isUser) {
      moveUser(
        {
          Id: a.dragSourceId,
          ParentId: a.dropTargetId !== 0 ? a.dropTargetId : null,
        },
        {
          onSuccess: () => refetch(),
        }
      );
      return;
    }

    moveStructure(
      {
        Id: a.dragSourceId,
        ParentId: a.dropTargetId !== 0 ? a.dropTargetId : null,
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        {myPrivilages.CanEditWorkspaceStructure && (
          <AddButton
            onClick={() => setShowAdd(true)}
            text="Utwórz nowy węzeł"
          />
        )}
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          {!!treeData.length && (
            <Tree
              ref={ref}
              canDrag={(e) => {
                if (
                  !myPrivilages.CanEditMembersInWorkspace &&
                  //@ts-ignore
                  e?.data?.isUser
                ) {
                  return false;
                }

                if (myPrivilages.CanEditWorkspaceStructure) {
                  return true;
                }

                return false;
              }}
              tree={treeData}
              rootId={0}
              initialOpen={true}
              render={(node, { depth, isOpen, onToggle }) => (
                <div
                  onClick={() => onClick?.(node)}
                  style={{ marginInlineStart: depth * 10 }}
                >
                  {node.text}
                </div>
              )}
              dragPreviewRender={(monitorProps) => (
                <div>{monitorProps.item.text}</div>
              )}
              onDrop={handleDrop}
            />
          )}
        </DndProvider>
      </div>
      {showAdd && (
        <AddNewNode
          workspaceStructures={workspaceStructures}
          onCreate={() => {
            refetch();
            setShowAdd(false);
          }}
          onCancel={() => {
            setShowAdd(false);
          }}
        />
      )}
    </>
  );
};

export default WorkspaceStructure;
