import ModalsManagerContext from 'contexts/ModalsManager';
import { useContext, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';

import { ReactComponent as SettingsIcon } from 'assets/icons/settings_24px.svg';
import { ReactComponent as PersonalIcon } from 'assets/icons/personal.svg';
import { ReactComponent as EDeliveryIcon } from 'assets/icons/profile.svg';
import { ReactComponent as IntegrationIcon } from 'assets/icons/integration.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/team.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/settings_24px.svg';
import { ReactComponent as DiskIcon } from 'assets/icons/credit_card.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/fingerprint.svg';
import TabButton from '../TabButton';
import { render } from 'react-dom';
import settingService from 'api/settingsService';
import { INotificationSettingsReponse } from 'dto/Settings/INotificationSettingsReponse';
import Checkbox from 'components/Checkbox';
import tagsService from 'api/tagsService';
import Input from 'components/Input';
import { ITag } from 'dto/ITag';
import TagBadge from 'components/TagBadge';
import settingsContext, { IOptionType } from 'contexts/SettingsContext';

const BasicTab: FC<Props> = ({}) => {
  const {
    data: notificationSettingsData,
    isSuccess: isNotificationSettingsLoaded,
  } = settingService.useGetNotificationSettings();
  const { mutate: updateNotificationSetting } =
    settingService.useSetNotification();

  const { mutate: updateSetting } = settingService.useUpdateSetting();
  const { data: tagsData } = tagsService.useGetTags();
  const [tags, setTags] = useState<ITag[]>([]);

  const [notificationSettings, setNotificationSettings] =
    useState<INotificationSettingsReponse>();
  const [tagValue, setTagValue] = useState<string>();

  const setNotification = (id: number, isActive: boolean) => {
    updateNotificationSetting(
      { Id: id, IsActive: isActive },
      {
        onSuccess(data, variables, context) {
          //var newNotifications = [...notificationSettings!.Notifications];
          var toUpdate = notificationSettings?.Notifications.find(
            (x) => x.Id == id
          );

          if (toUpdate) {
            toUpdate.IsActive = isActive;
          }
        },
        onError(error, variables, context) {},
      }
    );
  };

  const handleTagChange = (text: string) => {};

  const handleRemoveTag = (tag: ITag) => {};

  useEffect(() => {
    if (isNotificationSettingsLoaded) {
      setNotificationSettings(notificationSettingsData.data);
    }
  }, [notificationSettingsData]);

  useEffect(() => {
    setTags(tagsData?.data);
  }, [tagsData]);

  const { options, setOptions } = useContext(settingsContext);

  const handleUpdate = (type: IOptionType, value: boolean) => {
    const newOptions = [...options];

    const op = newOptions.find((o) => o.Type === type);
    if (op) {
      op.Value = value.toString();
    }

    setOptions(newOptions);

    updateSetting({
      Type: type,
      Value: value.toString(),
    });
  };

  const isHover = options.find((f) => f.Type === IOptionType.hoverMenu)?.Value;
  const isImages = options.find(
    (f) => f.Type === IOptionType.showImages
  )?.Value;

  const isHoverPreview = options.find(
    (f) => f.Type === IOptionType.hoverMenuPreview
  )?.Value;

  const [actualView, setActualView] = useState(ETabs.LIST);

  return (
    <>
      <div className={styles.group}>
        <div className={styles.notifications}>
          <div className={styles.header}>Dostosuj listy</div>
          <div className={styles.hint}>
            Dostosuj zawartość i wygląd list zgodnie z własnymi preferencjami
          </div>
          <div className={styles.notification}>
            <Checkbox
              value={isHover === 'true'}
              onChange={(v) => {
                handleUpdate(IOptionType.hoverMenu, v);
              }}
            ></Checkbox>
            Pokazuj hover over
          </div>
          <div className={styles.header}>Dostosuj okno podglądu</div>
          <div className={styles.notification}>
            <Checkbox
              value={isHoverPreview === 'true'}
              onChange={(v) => {
                handleUpdate(IOptionType.hoverMenuPreview, v);
              }}
            ></Checkbox>
            Pokazuj ikony funkcji w oknie podglądu
          </div>
          <div className={styles.notification}>
            <Checkbox
              value={isImages === 'true'}
              onChange={(v) => {
                handleUpdate(IOptionType.showImages, v);
              }}
            ></Checkbox>
            Pokazuj treść wiadomości przychodzących
          </div>
        </div>
      </div>

      <div className={styles.group}>
        <div className={styles.header}>Powiadomienia</div>
        <div className={styles.hint}>
          Skonfiguruj akcje i zdarzenia, które mają generować automatyczne
          powiadomienia poprzez wiadomości e-mail.
        </div>
        <div className={styles.notifications}>
          {notificationSettings &&
            notificationSettings.Notifications.map((n) => (
              <div className={styles.notification}>
                <Checkbox
                  value={n.IsActive}
                  onChange={(v) => setNotification(n.Id, v)}
                ></Checkbox>
                {n.Label}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default BasicTab;
