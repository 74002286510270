import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { options } from '@fullcalendar/core/preact';
import { IntegrationType } from 'dto/Settings/IntegrationType';
import { IntegrationProvider } from 'dto/Settings/IntegrationProvider';
import { TwoFactorMethod } from 'dto/Settings/TwoFactorMethod';

const queryKeys = {
  getTwoFactorSettings: 'settingsService.get2faSettings',
  initTotp: 'settingsService.initTotp',
  getNotificationSettings: 'settingsService.getNotificationSettings',
  getAvatar: 'settingsService.getAvatar',
  getSpaceUsage: 'settingsService.getSpaceUsage',
  getIntegrationSettings: 'settingsService.getIntegrationSettings',
  getCalendarSettings: 'settingsService.getCalendarSettings',
  getCourtPortalSettings: 'settingsService.getCourtPortalSettings',
  getMsRedirect: 'settingsService.getMsRedirect',
  getTrustedBrowsers: 'settingsService.getTrustedBrowsers',
  getEDeliveryAddresses: 'settingsService.getEDeliveryAddresses',
  getEDeliveryRequests: 'settingsService.getEDeliveryRequests',
  getPhoneNumber: 'settingsService.getPhoneNumber',
  getSettings: 'settingsService.getSettings',
  getForm: 'settingsService.getForm',
};

const get2FaSettings = () => {
  return axiosInstance.get(`/2fa`);
};

const useGet2FaSettings = (disabled: boolean = false) => {
  return useQuery(queryKeys.getTwoFactorSettings, () => get2FaSettings(), {
    enabled: !disabled,
  });
};

export interface IFidoMakeCredentialOptionsRequest {
  attType: 'none' | 'direct' | 'indirect';
  authType: '' | 'platform' | 'cross-platform';
  requireResidentKey: boolean;
  userVerification: 'preferred' | 'required' | 'discouraged';
}

const fidoMakeCredentialOptions = (
  payload: IFidoMakeCredentialOptionsRequest
) => {
  return axiosInstance.post('/2fa/fido/pwmakeCredentialOptions', payload);
};

const useFidoMakeCredentialOptions = () => {
  return useMutation(fidoMakeCredentialOptions);
};

const fidoMakeCredential = (payload: any) => {
  return axiosInstance.post('/2fa/fido/pwmakeCredential', payload);
};

const useFidoMakeCredential = () => {
  return useMutation(fidoMakeCredential);
};

const getInitTotp = ({}) => {
  return axiosInstance.get(`/2fa/totp`);
};

const useGetInitTotp = () => {
  return useMutation(getInitTotp);
};

export interface IConfigureTotpRequest {
  code: string;
}

const setTotp = (payload: IConfigureTotpRequest) => {
  return axiosInstance.post('2fa/totp', payload);
};

const useSetTotp = () => {
  return useMutation(setTotp);
};

const getNotificationSettings = () => {
  return axiosInstance.get(`/settings/notifications`);
};

const useGetNotificationSettings = (disabled: boolean = false) => {
  return useQuery(
    queryKeys.getNotificationSettings,
    () => getNotificationSettings(),
    {
      enabled: !disabled,
    }
  );
};

export interface ISetNotification {
  Id: number;
  IsActive: boolean;
}

const setNotification = (payload: ISetNotification) => {
  return axiosInstance.post('/settings/notification', payload);
};

const useSetNotification = () => {
  return useMutation(setNotification);
};

export interface IChangePassword {
  OldPassword: string;
  NewPassword: string;
  RepeatPassword: string;
}

const setChangePassword = (payload: IChangePassword) => {
  return axiosInstance.post('/settings/password', payload);
};

const useSetPassword = () => {
  return useMutation(setChangePassword);
};

const uploadAvatar = (file: File) => {
  var formData = new FormData();
  formData.append('file', file);

  return axiosInstance.post(`/settings/avatar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getAvatar = ({}) => {
  return axiosInstance.get(`/settings/avatar`, {
    responseType: 'blob',
  });
};

const useGetAvatar = () => {
  return useMutation(getAvatar, {
    retry: false,
  });
};

const useUploadAvatar = () => {
  return useMutation(uploadAvatar);
};

const getSpaceUsage = () => {
  return axiosInstance.get(`settings/space`);
};

const useGetSpaceUsage = (disabled: boolean = false) => {
  return useQuery(queryKeys.getSpaceUsage, () => getSpaceUsage(), {
    enabled: !disabled,
  });
};

const getIntegrationSettings = () => {
  return axiosInstance.get(`settings/integrations`);
};

const useGetIntegrationSettings = (disabled: boolean = false) => {
  return useQuery(
    queryKeys.getIntegrationSettings,
    () => getIntegrationSettings(),
    {
      enabled: !disabled,
    }
  );
};

export interface IServerConfiguration {
  IncomingHost: string;
  IncomingPort: number;
  IncomingSecurity: number;
  OutgoingHost: string;
  OutgoingPort: number;
  OutgoingSecurity: number;
}

export interface IConfigureIntegrationRequest {
  Type: IntegrationType;
  Provider: IntegrationProvider;
  Code?: string;
  Scope?: string;
  Token?: string;
  Email?: string;
  ImapLogin?: string;
  ImapPassword?: string;
  ServerConfiguration?: IServerConfiguration
}

const configureIntegration = (payload: IConfigureIntegrationRequest) => {
  return axiosInstance.post('/settings/integration', payload);
};

const useConfigureIntegration = () => {
  return useMutation(configureIntegration);
};

export interface IRemoveIntegrationRequest {
  Id: number;
}

const removeIntegration = (payload: IRemoveIntegrationRequest) => {
  return axiosInstance.delete('/settings/integration', { data: payload });
};

const useRemoveIntegration = () => {
  return useMutation(removeIntegration);
};

const getCalendarSettings = () => {
  return axiosInstance.get(`calendar/settings`);
};

const useGetCalendarSettings = (disabled: boolean = false) => {
  return useQuery(queryKeys.getCalendarSettings, () => getCalendarSettings(), {
    enabled: !disabled,
  });
};

export interface ISetCalendarSettings {
  defaultCalendarId: string;
}

const setCalendarSettings = (payload: ISetCalendarSettings) => {
  return axiosInstance.post('calendar/settings', payload);
};

const useSetCalendarSettings = () => {
  return useMutation(setCalendarSettings);
};

const getCourtPortalSettings = () => {
  return axiosInstance.get(`courtPortal/settings`);
};

const useGetCourtPortalSettings = (disabled: boolean = false) => {
  return useQuery(
    queryKeys.getCourtPortalSettings,
    () => getCourtPortalSettings(),
    {
      enabled: !disabled,
    }
  );
};

export interface ICourtPortalLoginRequest {
  Login: string;
  Password: string;
  PortalId: number;
}

const loginCourtPortal = (payload: ICourtPortalLoginRequest) => {
  return axiosInstance.post('courtPortal/login', payload);
};

const useLoginCourtPortal = () => {
  return useMutation(loginCourtPortal);
};

export interface ICourtPortalConfirmRequest {
  Login: string;
}

const confirmCourtPortal = (payload: ICourtPortalConfirmRequest) => {
  return axiosInstance.post('courtPortal/confirm', payload);
};

const useConfirmCourtPortal = () => {
  return useMutation(confirmCourtPortal);
};

export interface IUpdateCourtPortalSettingsRequest {
  PortalIds: Array<number>;
  DocumentIds: Array<number>;
}

const updateCourtPortalSettings = (
  payload: IUpdateCourtPortalSettingsRequest
) => {
  return axiosInstance.post('courtPortal/settings', payload);
};

const useUpdateCourtPortalSettings = () => {
  return useMutation(updateCourtPortalSettings);
};

const getMsRedirect = (payload: { scopes: string }) => {
  const query = new URLSearchParams(window.location.search);

  if (!!payload.scopes?.length) {
    query.append('scopes', payload.scopes);
  }

  return axiosInstance.get(`settings/integration/ms?${query}`);
};

const useGetMsredirect = () => {
  return useMutation(getMsRedirect, {
    retry: false,
  });
};

const getTrustedBrowsers = () => {
  return axiosInstance.get(`/trustedBrowsers`);
};

const useGetTrustedBrowsers = (disabled: boolean = false) => {
  return useQuery(queryKeys.getTrustedBrowsers, () => getTrustedBrowsers(), {
    enabled: !disabled,
  });
};

export interface IRemoveTrustedBrowser {
  Id: number;
}

const removeTrustedBrowser = (payload: IRemoveTrustedBrowser) => {
  return axiosInstance.delete('/trustedBrowser', { data: payload });
};

const useRemoveTrustedBrowser = () => {
  return useMutation(removeTrustedBrowser);
};

export interface IRemoveTwoFactorMethod {
  method: TwoFactorMethod;
}

const remove2faMethod = (payload: IRemoveTwoFactorMethod) => {
  return axiosInstance.delete('/2fa', { data: payload });
};

const useRemove2faMethod = () => {
  return useMutation(remove2faMethod);
};

const getEDeliveryAddresses = () => {
  return axiosInstance.get(`/edelivery/addresses`);
};

const useGetEDeliveryAddresses = (disabled: boolean = false) => {
  return useQuery(
    queryKeys.getEDeliveryAddresses,
    () => getEDeliveryAddresses(),
    {
      enabled: !disabled,
    }
  );
};

const getEDeliveryRequests = () => {
  return axiosInstance.get(`/edelivery/address/requests`);
};

const useGetEDeliveryRequests = (disabled: boolean = false) => {
  return useQuery(
    queryKeys.getEDeliveryRequests,
    () => getEDeliveryRequests(),
    {
      enabled: !disabled,
    }
  );
};

const getPhoneNumber = () => {
  return axiosInstance.get("/settings/personal");
}

const useGetPhoneNumber = () => {
  return useQuery(queryKeys.getPhoneNumber, () => getPhoneNumber());
}

export interface ISetPersonal {
  Phone: string
}

const setPersonal = (payload: ISetPersonal) => {
  return axiosInstance.post('/settings/personal', payload)
}

const useSetPersonal = () => {
  return useMutation(setPersonal);
}

const getSettings = () => {
  return axiosInstance.get('/settings');
}

const useGetSettings = () => {
  return useQuery(queryKeys.getSettings, () => getSettings());
}

export interface IUpdateSetting {
  Type: string;
  Value: string;
}

const updateSetting = (payload: IUpdateSetting) => {
  return axiosInstance.post('/settings/setting', payload);
}

const useUpdateSetting = () => {
  return useMutation(updateSetting);
}

const getForm = () =>{ 
  return axiosInstance.get('/settings/contactForms');
}

const useGetForm = () => {
  return useQuery(queryKeys.getForm, () => getForm());
}

const createForm = () => {
  return axiosInstance.post("/settings/contactForm", {});
}

const useCreateForm = () => {
  return useMutation(createForm);
}


export default {
  useGet2FaSettings,
  useFidoMakeCredentialOptions,
  useFidoMakeCredential,
  useGetInitTotp,
  useSetTotp,
  useGetNotificationSettings,
  useSetNotification,
  useSetPassword,
  useUploadAvatar,
  useGetAvatar,
  useGetSpaceUsage,
  useGetIntegrationSettings,
  useConfigureIntegration,
  useGetCalendarSettings,
  useSetCalendarSettings,
  useGetCourtPortalSettings,
  useLoginCourtPortal,
  useGetMsredirect,
  useConfirmCourtPortal,
  useUpdateCourtPortalSettings,
  useRemoveIntegration,
  useGetTrustedBrowsers,
  useRemoveTrustedBrowser,
  useRemove2faMethod,
  useGetEDeliveryAddresses,
  useGetEDeliveryRequests,
  useGetPhoneNumber,
  useSetPersonal,
  useGetSettings,
  useUpdateSetting,
  useGetForm,
  useCreateForm
};
