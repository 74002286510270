import { DossierItemType } from 'dto/Dossier/DossierItemType';
import { IDossierFakeItem } from 'dto/Dossier/IDossierFakeItem';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { ItemKind } from 'dto/IKindItems';

export const getDossierItemFileName = (item: IDossierItem) => {
  if (item && item.Label) {
    return item.Label;
  }

  return item.FileName ?? '';
};

export const getDossierItemRealFileName = (item: IDossierItem) => {
  return item.FileName ?? '';
};

export const getDossierItemGroupName = (item: IDossierItem): string => {
  var fname = getDossierItemRealFileName(item);
  const name = fname.split('.');
  const extenstion = name[name.length - 1];

  if (item.Kind === ItemKind.DossierLink) {
    return 'Linki';
  }

  switch (extenstion) {
    case 'jpg':
    case 'png':
      return 'Obrazy';
    case 'xlsx':
      return 'Arkusze kalkulacyjne';
    case 'pdf':
      return 'Dokumenty PDF';

    case 'doc':
    case 'docx':
    case 'txt':
      return 'Dokumenty';
    default:
      return 'Inne';
  }
};
