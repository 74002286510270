import Spinner from 'components/Spinner';
import styles from './styles.module.scss';
import Button from 'components/Button';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';

const Login = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.signinRedirect();
  }, []);

  return <div className={styles.wrapper}></div>;
};

export default Login;
