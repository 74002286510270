import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_l.svg';
import settingService from 'api/settingsService';
import Input from 'components/Input';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import { getErrorMessage } from 'utils/getErrorMessage';
import Dropdown from 'components/Dropdown';
import { IDropdownObject } from 'components/Dropdown/typings';
import { ICalendarIntegrationSettings } from 'dto/Settings/ICalendarIntegrationSettings';
import classNames from 'classnames';
import { TwoFactorMethod } from 'dto/Settings/TwoFactorMethod';
import Spinner from 'components/Spinner';
import { IInitTotpResponse } from 'dto/Settings/IInitTotpResponse';
import { ENotificationType, notification } from 'utils/notification';
import ReactDOM from 'react-dom';
import { QRCodeCanvas } from 'qrcode.react';

const OtpMethod: FC<Props> = ({
  isActive,
  onCanceled,
  onSaved,
  handleRemove,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (!isActive) {
      initTotp();
    }
  }, []);

  const initTotp = () => {
    getInitTotp(
      {},
      {
        onSuccess(data) {
          let resp = data.data as IInitTotpResponse;
          setTotpUri(resp.uri);
        },
        onError(e: any) {
          notification({
            title: 'Wystąpił błąd',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  const configure = () => {
    setTotp(
      { code: totpCode },
      {
        onSuccess(data) {
          onSaved();
        },
        onError(e: any) {
          setErrorMessage(getErrorMessage(e.response.data));
        },
      }
    );
  };

  const { mutate: getInitTotp, isLoading: isLoadingTotp } =
    settingService.useGetInitTotp();
  const { mutate: setTotp, isLoading: isSettingTotp } =
    settingService.useSetTotp();
  const [totpUri, setTotpUri] = useState<string>('');
  const [totpCode, setTotpCode] = useState<string>('');

  useEffect(() => {
    if (totpUri) {
      ReactDOM.render(
        <QRCodeCanvas value={totpUri} />,
        document.getElementById('qrcode')
      );
    }
  }, [totpUri]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.pathBar}>
        <div className={styles.backButton}>
          <BackIcon onClick={onCanceled}></BackIcon>
        </div>
        Metody podwójnego uwierzytelniania <ArrowLeftIcon></ArrowLeftIcon> Otp
      </div>

      {isActive && (
        <>
          <div className={styles.hint}>
            Autoryzacja przy użyciu OTP została skonfigurowana i jest aktywna
            <br></br>
            Aby skonfigurować nowe źródło kodów OTP konieczne jest usunięcie
            obecnego.
          </div>
        </>
      )}

      {!isActive && (
        <>
          <div className={styles.hint}>
            <b>
              Zeskanuj poniższy QR kod za pomocą aplikacji haseł jednorazowych.
            </b>
            <br></br>
            Możesz skorzystać z aplikacji takich jak Microsoft Authenticator dla{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US"
              target="_blank"
            >
              Android
            </a>{' '}
            lub{' '}
            <a
              href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
              target="_blank"
            >
              iOS
            </a>
            , Google Authenticator dla{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
            >
              Android
            </a>{' '}
            lub{' '}
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
            >
              iOS
            </a>
            , oraz każdej innej obsługującej kody TOTP.
            <br></br>
            Po zeskanowaniu wpisz jednorozawy kod w poniższe pole tekstowe.
          </div>
          <div id="qrcode" className={styles.qrcode}></div>
          {isLoadingTotp && (
            <div className={styles.loading}>
              <Spinner className={styles.spinner} />
              <p>Wczytywanie</p>
            </div>
          )}
          <div className={styles.codeInput}>
            <Input
              type="text"
              label="Kod weryfikacyjny"
              onChange={(v) => setTotpCode(v)}
            ></Input>
          </div>
          {errorMessage && <div className={styles.warn}>{errorMessage}</div>}
        </>
      )}

      <div className={styles.buttons}>
        {isActive && (
          <div className={styles.disconnect}>
            <a onClick={() => handleRemove(TwoFactorMethod.Totp)}>
              Usuń metodę
            </a>
          </div>
        )}
        <HollowButton text="Anuluj" onClick={onCanceled}></HollowButton>
        {!isActive && <Button text="Weryfikuj" onClick={configure}></Button>}
      </div>
      <div className={classNames(styles.hint, styles.disconnect)}></div>
    </div>
  );
};

export default OtpMethod;
