export type { FC } from 'react';

export interface Props {
    id: string;
    isEditMode?:boolean;
    handleRemove: (id: string) => void;
}

export enum TypeOfView {
    CASES = 'CASES',
    INCOMING = 'INCOMING',
    OUTCOMING = 'OUTCOMING',
    LETTERS = 'LETTERS',
    ACTIONS = 'ACTIONS'
}