import { useMutation } from "react-query"
import { axiosInstance } from "./axios"

const download = (payload: { id: string }) => {
    return axiosInstance.get(`/export/download?id=${payload.id}`);
}

const useDownload = () => {
    return useMutation(download);
}

const prepareDownload = () => {
    return axiosInstance.get("/export/prepare");
}

const usePrepareDownload = () => {
    return useMutation(prepareDownload)
}
export default {
    useDownload,
    usePrepareDownload

}