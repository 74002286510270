import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TagIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as SignIcon } from 'assets/icons/sign.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/signature.svg';
import { useContext, useState } from 'react';
import CreateHistory from 'components/CreateHistory';
import editorService from 'api/editorService';
import { IPackage } from 'dto/IPackage';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router';
import CertContext from 'contexts/CertContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copyd.svg';

const Box = ({
  pack,
  history,
  idx,
}: {
  pack: IPackage;
  history: any;
  idx: number;
}) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { filterId, status, kind } = useParams();
  const [showMenu, setShowMenu] = useState(false);

  const { cert, setCert } = useContext(CertContext);

  const handleDownload = () => {};
  const handlePreview = () => {};
  const handleDuplicate = () => {};

  return (
    <div
      onClick={() => {
        navigate(
          `/packages/${filterId}/${status}/${pack.Kind}/${
            pack.Id
          }/preview-letter/${pack.Id}/${history.SignedUri ?? history.Uri}/${
            history.status !== 0 ? 'signed' : 'nonsigned'
          }`
        );
      }}
      className={styles.box}
    >
      <div className={styles.boxWrapper}>
        <div className={styles.boxHeader}>
          <div className={styles.number}>{idx + 1}</div>
          <div className={styles.name}>
            {history?.Name ? history.Name : pack.S1}
          </div>
          <div className={styles.menu}>
            <div className={styles.hover}>
              <FontAwesomeIcon
                onClick={(e) => {
                  setShowMenu(!showMenu);
                  e.stopPropagation();
                }}
                icon={faEllipsisVertical}
                className={styles.menuIcon}
              />

              {showMenu && (
                <div className={styles.dropdown}>
                  <div
                    onClick={() => {
                      handleDownload();
                    }}
                    className={styles.dropdownItem}
                  >
                    <DownloadIcon title="Pobierz plik źródłowy" />
                    Pobierz plik źródłowy
                  </div>
                  <div
                    onClick={() => {
                      handlePreview();
                    }}
                    className={styles.dropdownItem}
                  >
                    <DownloadIcon title="Podgląd pliku źródłowego" />
                    Podgląd pliku źródłowego
                  </div>
                  <div
                    onClick={() => {
                      handleDuplicate();
                    }}
                    className={styles.dropdownItem}
                  >
                    <CopyIcon title="Utwórz kopię" />
                    Utwórz kopię
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.signatures}>
          {history.Signatures &&
            history.Signatures.map((s: any, idx: number) => (
              <div className={styles.signature}>
                <SignatureIcon className={styles.icon}></SignatureIcon>
                <div className={styles.name}>{s.Label}</div>
                <div className={styles.date}>
                  {moment(s.Date).format('DD.MM.yyyy | HH:mm')}
                </div>
              </div>
            ))}
        </div>
        {/* {history.Status !== 0 && <div className={styles.signed}>Podpisany</div>} */}
      </div>
      {/* 
      <div className={styles.menu}>
        <TagIcon
          title="Edytuj pismo"
          onClick={() => {
            setShowModal(true);
          }}
        />
        <SignIcon
          title="Podpisz elektronicznie"
          onClick={() => {
            setCert({
              id: pack.Id,
              name: history.Name ?? pack.S1,
              versionId: history.Uri,
            });
            navigate(
              `/packages/${filterId}/${status}/${pack.Kind}/${
                pack.Id
              }/preview-letter/${pack.Id}/${history.SignedUri ?? history.Uri}/${
                history.status !== 0 ? 'signed' : 'nonsigned'
              }`
            );
          }}
        />
      </div> */}
      {showModal && (
        <CreateHistory
          pack={pack}
          history={history}
          toggle={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

const HistoryModal: FC<Props> = ({ pack, toggle }) => {
  const { data: histories } = editorService.useGetVersions(
    pack.Id,
    pack.Kind,
    true
  );

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>Wersje</div>
          <CloseIcon
            onClick={(e) => {
              toggle();
              // e.stopPropagation();
            }}
            className={styles.close}
          />
        </div>

        <div className={styles.list}>
          {histories
            ?.sort((a: any, b: any) => {
              var c = new Date(a.CreationDate);
              var d = new Date(b.CreationDate);
              //@ts-ignore
              return c - d;
            })
            .map((history: any, idx: number) => (
              <Box key={history.Id} pack={pack} history={history} idx={idx} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default HistoryModal;
