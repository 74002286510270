import statusService from 'api/statusService';
import tagsService from 'api/tagsService';
import MessageLetterCard from 'components/MessageLetterCard';
import { IOrderObject } from 'components/SortOrder/typings';
import KeysContext from 'contexts/KeysContext';
import PackagesContext from 'contexts/PackagesContext';
import { IAttachment } from 'dto/IAttachment';
import { IPackage } from 'dto/IPackage';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { DataGridPremium, useGridApiContext } from '@mui/x-data-grid-premium';

import Search from 'assets/icons/search.gif';
import MessageCard from 'components/MessageCard';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { makeStyles } from '@mui/material';

export const PackageList = ({
  debouncedText,
  fetchNextPage,
  customFilterId,
  searchText,
  isIncoming,
  onPreview,
  onDownload,
  firstElement,
  selectedElements,
  setSelectedElements,
  canSelect,
  selectMultiple,
  showSomething,
  customSortMethod,
  handleScroll,
  separatorRef,
  isLoading = false,
  remove,
  listFilter,
  status: stat,
}: {
  debouncedText: string;
  searchText: string;
  customFilterId?: string;
  isIncoming: boolean;
  onPreview: (pack: IPackage, attachment: IAttachment) => void;
  onDownload: (pack: IPackage, attachment: IAttachment) => void;
  firstElement: IPackage | null;
  selectedElements: IPackage[];
  setSelectedElements: (packs: IPackage[]) => void;
  canSelect: boolean;
  selectMultiple: boolean;
  showSomething: boolean;
  customSortMethod?: string;
  handleScroll?: (e: any) => void;
  separatorRef?: any;
  isLoading?: boolean;
  remove?: () => void;
  listFilter?: IOrderObject;
  status?: string;
  fetchNextPage: () => void;
}) => {
  const navigate = useNavigate();
  const { filterId, status, packageId } = useParams();
  const [page, setPage] = useState(0);
  const { packages, setPackages } = useContext(PackagesContext);
  const { keys, setKeys } = useContext(KeysContext);

  const { data: tagsData } = tagsService.useGetTags();
  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const scrollWrapper = useRef(null);

  const fID = customFilterId ?? filterId;

  const isPisma = window.location.href.search('pisma') !== -1;

  useEffect(() => {
    // setPackages([]);
  }, [debouncedText]);

  const apiRef = useRef<MutableRefObject<GridApiPremium>>(null);

  useEffect(() => {
    if (!apiRef?.current) return;

    //@ts-ignore
    apiRef.current.selectRow(packageId, true);
  }, [packages, isPisma, packageId, apiRef]);

  // const handleScroll = (e: any) => {
  //   if (!separatorRef.current) return;
  //   if (isLoading || isFetchingNextPage) return;
  //   var rect = separatorRef.current.getBoundingClientRect();
  //   var viewHeight = Math.max(
  //     document.documentElement.clientHeight,
  //     window.innerHeight
  //   );
  //   const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  //   if (isVisible) {
  //     // fetchNextPage();
  //   }
  // };

  const getLists = () => {
    if (!packages.length && stat !== 'idle') {
      return (
        <div className={styles.empty}>
          <img src={Search} />
          <div>Nie znaleziono wyników dla wybranych kryteriów</div>
        </div>
      );
    }

    if (!isPisma) {
      return (
        <DataGridPremium
          sx={{
            '&, [class^=MuiDataGrid]': { border: 'none' },
            '& .MuiDataGrid-row': {},
          }}
          //@ts-ignore
          apiRef={apiRef}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          getRowHeight={() => 'auto'}
          onRowsScrollEnd={fetchNextPage}
          onRowSelectionModelChange={(props) => {
            if (props.length === 1) {
              setSelectedElements([]);
              return;
            }
            const elements = packages.filter((p) =>
              props.find((a) => p.Id === a)
            );
            console.log(elements);
            setSelectedElements(elements);
          }}
          columns={[
            {
              flex: 1,
              field: 'name',
              renderCell: (props) => {
                const msg = props.row as IPackage;
                return (
                  <MessageCard
                    listFilter={listFilter}
                    key={msg.Id}
                    remove={remove}
                    scrollWrapper={scrollWrapper}
                    onPreview={onPreview}
                    onDownload={onDownload}
                    isFirst={
                      firstElement?.Id === msg.Id && selectedElements.length > 1
                    }
                    isSelectable={canSelect}
                    selectMultiple={selectMultiple}
                    showSomething={showSomething}
                    isIncoming={isIncoming}
                    selectedElements={selectedElements}
                    setSelectedElements={setSelectedElements}
                    keys={keys}
                    msg={msg}
                    statuses={statuses}
                    tags={tags}
                    decodedContent={undefined}
                  />
                );
              },
            },
          ]}
          // columns={[]}
          columnHeaderHeight={0}
          hideFooter={true}
          onRowClick={(a, b) => {
            const msg = a.row as IPackage;

            if (b.ctrlKey || b.shiftKey) return;

            const link = status
              ? status
              : isIncoming === true
              ? 'incoming'
              : 'outcoming';

            if (msg.Thread?.Id) return;

            if (msg.Kind === 256) {
              navigate(
                `/packages/${filterId}/${link}/${msg.Kind}/${msg.Id}/preview-letter/${msg.Id}`
              );
              return;
            }

            navigate(`/packages/${filterId}/${link}/${msg.Kind}/${msg.Id}`);
          }}
          // rows={[
          //   { id: 1, name: 'React' },
          //   { id: 2, name: 'MUI' },
          // ]}
          rows={packages
            .filter((p, index) => !p?.isDelete)
            .map((p) => {
              //@ts-ignore
              p.id = p.Id;
              return p;
            })}
        />
      );
    }

    return (
      <DataGridPremium
        //@ts-ignore
        apiRef={apiRef}
        getRowHeight={() => 'auto'}
        onRowsScrollEnd={fetchNextPage}
        // sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        onRowSelectionModelChange={(props) => {
          if (props.length === 1) {
            setSelectedElements([]);
            return;
          }
          const elements = packages.filter((p) =>
            props.find((a) => p.Id === a)
          );
          console.log(elements);
          setSelectedElements(elements);
        }}
        columns={[
          {
            flex: 1,
            field: 'name',

            renderCell: (props) => {
              const msg = props.row as IPackage;
              return (
                <MessageCard
                  listFilter={listFilter}
                  key={msg.Id}
                  remove={remove}
                  scrollWrapper={scrollWrapper}
                  onPreview={onPreview}
                  onDownload={onDownload}
                  isFirst={
                    firstElement?.Id === msg.Id && selectedElements.length > 1
                  }
                  selectedElements={selectedElements}
                  setSelectedElements={setSelectedElements}
                  isSelectable={canSelect}
                  selectMultiple={selectMultiple}
                  showSomething={showSomething}
                  isIncoming={isIncoming}
                  keys={keys}
                  msg={msg}
                  statuses={statuses}
                  tags={tags}
                  decodedContent={undefined}
                />
              );
            },
          },
        ]}
        // columns={[]}
        columnHeaderHeight={0}
        hideFooter={true}
        onRowClick={(a, b) => {
          const msg = a.row as IPackage;

          if (b.ctrlKey || b.shiftKey) return;

          const link = status
            ? status
            : isIncoming === true
            ? 'incoming'
            : 'outcoming';

          if (msg.Kind === 256) {
            navigate(
              `/packages/${filterId}/${link}/${msg.Kind}/${msg.Id}/preview-letter/${msg.Id}`
            );
            return;
          }

          if (msg.Thread?.Id) {
            navigate(
              `/packages/thread/${filterId}/${link}/${msg.Kind}/${msg.Thread.Id}/${msg.Id}`
            );
            return;
          }

          navigate(`/packages/${filterId}/${link}/${msg.Kind}/${msg.Id}`);
        }}
        // rows={[
        //   { id: 1, name: 'React' },
        //   { id: 2, name: 'MUI' },
        // ]}
        rows={packages
          .filter((p, index) => !p?.isDelete)
          .map((p) => {
            //@ts-ignore
            p.id = p.Id;
            return p;
          })}
      />
    );

    // return packages
    //   .filter((p) => !p?.isDelete)
    //   .map((msg: IPackage) => (
    //     <MessageLetterCard
    //       listFilter={listFilter}
    //       key={msg.Id}
    //       remove={remove}
    //       scrollWrapper={scrollWrapper}
    //       onPreview={onPreview}
    //       onDownload={onDownload}
    //       isFirst={firstElement?.Id === msg.Id && selectedElements.length > 1}
    //       selectedElements={selectedElements}
    //       setSelectedElements={setSelectedElements}
    //       isSelectable={canSelect}
    //       selectMultiple={selectMultiple}
    //       showSomething={showSomething}
    //       isIncoming={isIncoming}
    //       keys={keys}
    //       msg={msg}
    //       statuses={statuses}
    //       tags={tags}
    //       decodedContent={undefined}
    //     />
    //   ));
  };

  return (
    <div
      ref={scrollWrapper}
      onScroll={(e) => handleScroll?.(e)}
      className={styles.packageList}
    >
      {!isLoading && getLists()}

      <div ref={separatorRef} style={{ height: '5px' }}></div>
    </div>
  );

  //   return (
  //     <>
  //       {packages.map((msg: IPackage) => (
  //         <MessageCard
  //           scrollWrapper={scrollWrapper}
  //           onPreview={onPreview}
  //           onDownload={onDownload}
  //           isFirst={firstElement?.Id === msg.Id}
  //           selectedElements={selectedElements}
  //           setSelectedElements={setSelectedElements}
  //           isSelectable={canSelect}
  //           selectMultiple={selectMultiple}
  //           showSomething={showSomething}
  //           keys={keys}
  //           msg={msg}
  //           statuses={statuses}
  //           tags={tags}
  //           decodedContent={undefined}
  //         />
  //       ))}
  //     </>
  //   );
};
