export const sortByCoordinatesOnPDF = (a: any, b: any) => {
    const pos_1 = JSON.parse(a.Json).lines[0];
    const pos_2 = JSON.parse(b.Json).lines[0];


    if (pos_1[1] !== pos_2[1]) {
        return pos_1[1] - pos_2[1];
    }

    // If Y coordinates are the same, compare X coordinates
    return pos_1[0] - pos_2[0];
    // return pos_1[0] === pos_2[0] ? pos_1[1] - pos_2[1] : pos_1[0] - pos_2[0];
}
