import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as HashIcon } from 'assets/icons/hash2.svg';
import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';
import { ITag } from 'dto/ITag';
import {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

import { ReactComponent as RedirectIcon } from 'assets/icons/redirect.svg';

import { ReactComponent as Redirect2Icon } from 'assets/icons/reply_all.svg';

import { axiosInstance } from 'api/axios';
import casesService from 'api/casesService';
import deskService from 'api/deskService';
import AddTagModal from 'components/AddTagModal';
import ConfirmModal from 'components/ConfirmModal';
import ModalLayout from 'components/ModalLayout';
import { RedirectModal } from 'components/RedirectModal';
import CasesContext from 'contexts/CasesContext';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IWorkspaceMember } from 'dto/IWorkspace';
import { useAuth } from 'react-oidc-context';
import { decryptPrivateKey } from 'utils/decryptPrivateKey';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { readAsBase64 } from 'utils/readAsBase64';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { ReactComponent as ClientIcon } from 'assets/icons/client_hover.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ThreadsIcon } from 'assets/icons/listIcon.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/new_tab.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ECaseViews } from 'components/CaseView/dto/ECaseViews';
import CasePerson from 'components/CasePerson';
import { ICase } from 'dto/Cases/ICase';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import CaseInfo from 'components/CaseInfo';

import { reEncrypt } from 'utils/reEncrypt';

const CaseOptionsCard = ({
  refWrapper,
  pack,
  showSomething,
  showOptions,
  onClickEdit,
  onClickAttachIcon,
  refetch,
  showDropdown,
  toggle,
  posX,
  posY,
  threadsRef,
  setShowThreads,
  showThreads,
  view,
  showCreateFolder,
  setShowCreateFolder,
  selectedElements,
  setSelectedElements,
}: {
  refWrapper: RefObject<HTMLDivElement>;
  pack: ICaseSimple;
  showSomething: boolean;
  showOptions: boolean;
  onClickEdit: () => void;
  onClickAttachIcon: (state: boolean) => void;
  refetch: () => void;
  showDropdown?: boolean;
  toggle?: () => void;
  posX?: number;
  posY?: number;
  threadsRef?: any;
  setShowThreads?: any;
  showThreads?: boolean;
  view?: ECaseViews;
  showCreateFolder?: boolean;
  setShowCreateFolder?: (t: boolean) => void;
  selectedElements?: ICaseSimple[];
  setSelectedElements?: (cases: ICaseSimple[]) => void;
}) => {
  const { data: tagsData } = tagsService.useGetTags();
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: statusesData } = statusService.useGetStatuses();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const navigate = useNavigate();
  const auth = useAuth();
  const isFullScreen = window.location.href.search('fullscreen') !== -1;
  var userId = getUserId(auth.user!);
  const { mutate: setDesk } = deskService.useSetDesk();
  const { mutate: deleteCase } = casesService.useDeleteCase();
  const { mutate: archiveCase } = casesService.useArchiveCase();
  const { mutate: redirect } = casesService.useRedirect();

  const [showTags, setShowTags] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showRedirect, setShowRedirect] = useState(false);
  const [showPerson, setShowPerson] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (showOptions) {
      toggle?.();
    }
  });

  useEffect(() => {
    if (!showTags) return;
    setShowDelete(false);
    setShowRedirect(false);
  }, [showTags]);

  useEffect(() => {
    if (!showDelete) return;
    setShowTags(false);
    setShowRedirect(false);
  }, [showTags]);

  useEffect(() => {
    if (!showRedirect) return;
    setShowDelete(false);
    setShowTags(false);
  }, [showTags]);

  const { cases, setCases } = useContext(CasesContext);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const refka = useRef<any>(null);

  useEffect(() => {
    const position = refWrapper?.current?.getBoundingClientRect?.();
    const size = refka?.current?.getBoundingClientRect?.();

    if (!position || !size) return;
    if (!showDropdown) {
      let newX = position.x + position.width / 2;
      let newY = position.y - 14;

      if (posX) {
        newX = position.x - position.width - 70;
      }

      if (posY) {
        newY = posY;
      } else {
        if (newY <= 80) {
          newY = 80;
        }
      }

      setX(newX);
      setY(newY);
      return;
    }

    const newX = position.x + position.width - 32;
    let newY = position.y + 36;

    setX(newX);
    setY(newY);
  }, [pack, showOptions, showDropdown, refWrapper, refka]);

  const findPosition = () => {
    const position = refWrapper?.current?.getBoundingClientRect?.();
    const size = refka?.current?.getBoundingClientRect?.();

    if (!position || !size) return;
    if (!showDropdown) {
      let newX = position.x + position.width / 2;
      let newY = position.y - 14;

      if (posX) {
        newX = position.x - position.width - 50;
      }

      if (posY) {
        newY = posY;
      } else {
        if (newY <= 80) {
          newY = 80;
        }
      }

      setX(newX);
      setY(newY);
      return;
    }

    const newX = position.x + position.width - 32;
    let newY = position.y + 36;

    setX(newX);
    setY(newY);
  };

  useEffect(() => {
    window.addEventListener('resize', findPosition);

    return () => {
      window.removeEventListener('resize', findPosition);
    };
  }, []);

  const tagRef = useRef(null);

  const handleAddToDesk = () => {
    let newCases = [...cases];
    const items = [];

    if (selectedElements && selectedElements.length > 1) {
      for (const element of selectedElements) {
        let caseItem = newCases.find((c) => c.Id === element.Id);
        if (caseItem) {
          caseItem.IsDeskActive = !caseItem.IsDeskActive;
        }

        items.push({ Id: element.Id, Kind: element.Kind });
      }
    } else {
      let newCases = [...cases];
      let caseItem = newCases.find((c) => c.Id === pack.Id);
      if (caseItem) {
        caseItem.IsDeskActive = !caseItem.IsDeskActive;
      }
      items.push({ Id: pack.Id, Kind: pack.Kind });
    }

    setCases(newCases);

    setDesk(
      {
        Items: items,
        Show: pack.IsDeskActive,
      },
      {
        onSuccess: () => {},
      }
    );
  };

  const handleClicke = (e: any) => {
    if (e.code === 'Delete' && showOptions) {
      handleDeleteCase();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleClicke);

    return () => {
      window.removeEventListener('keydown', handleClicke);
    };
  }, [showOptions]);

  const handleDeleteCase = () => {
    let newCases = [...cases];
    setCases(newCases.filter((p) => p.Id !== pack.Id));
    deleteCase(pack, {
      onError: () => {
        archiveCase(pack);
      },
    });
  };

  const handleUpdateTags = (tags: ITag[]) => {
    let newCases = [...cases];

    let caseItem = newCases.find((c) => c.Id === pack.Id);

    if (caseItem) {
      caseItem.TagIds = tags.map((t) => t.Id);
    }
  };

  const handleRedirect = async (workspaceMember: IWorkspaceMember) => {
    const myKeys = await getKeys(userId!);

    const c = await axiosInstance.get(`/case?id=${pack.Id}`);

    const id = pack.Id;
    const Kind = pack.Kind;

    const encryptionKey = c.data.Keys[0].PrivateKey;

    const uint = await reEncrypt(userId, workspaceMember.UserId, encryptionKey);
    const readyToSend = await readAsBase64(uint);

    redirect(
      {
        CaseId: pack.Id,
        ReceiverId: workspaceMember.UserId,
        Keys: [{ Id: c.data.Keys[0].Id!, Key: readyToSend }],
      },
      {
        onSuccess: () => {
          setShowRedirect(false);

          let newCases = [...cases];

          newCases = newCases.filter((cc) => cc.Id !== pack.Id);
          setCases(newCases);
          navigate('/cases');
        },
      }
    );
  };

  const handleNewTab = () => {
    window.open(
      `${
        process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'
      }/fullscreen/cases/${pack?.Id}/chat`
    );
  };

  const buildDropdown = () => {
    return (
      <>
        <div
          onClick={() => {
            onClickEdit();
            toggle?.();
          }}
          className={styles.dropdownBox}
        >
          <EditIcon title="Edytuj" />
          Edytuj
        </div>

        {!pack.IsDeskActive && (
          <div
            onClick={() => {
              handleAddToDesk();
              toggle?.();
            }}
            className={styles.dropdownBox}
          >
            <DeskIcon title="Przypnij" />
            Przypnij
          </div>
        )}
        <div
          onClick={() => {
            setShowTags(!showTags);
            toggle?.();
          }}
          className={styles.dropdownBox}
        >
          <HashIcon ref={tagRef} title="Taguj" />
          Taguj
        </div>
        <div
          onClick={() => {
            const ask = localStorage.getItem('ask');

            console.log(ask);
            setShowDelete(true);
            toggle?.();
          }}
          className={styles.dropdownBox}
        >
          <BinIcon title="Usuń" />
          Usuń
        </div>
        <div
          onClick={() => {
            setShowRedirect(true);
            toggle?.();
          }}
          className={styles.dropdownBox}
        >
          <Redirect2Icon className={styles.reverse} title="Przekaż" />
          Przekaż
        </div>
      </>
    );
  };

  const canEdit =
    auth?.user?.profile?.role === 'guest' ? pack?.Privileges === 2 : true;

  const buildHoverOver = () => {
    return (
      <>
        {!selectedElements?.length && canEdit && (
          <EditIcon title="Edytuj" onClick={() => onClickEdit()} />
        )}

        {canEdit && (
          <DeskIcon
            title={pack.IsDeskActive ? 'Odepnij' : 'Przypnij'}
            onClick={handleAddToDesk}
          />
        )}

        {!isFullScreen && !selectedElements?.length && (
          <NewTabIcon title="Nowa zakładka" onClick={handleNewTab} />
        )}
        {!selectedElements?.length && canEdit && (
          <HashIcon
            ref={tagRef}
            title="Taguj"
            onClick={() => {
              setShowTags(!showTags);
            }}
          />
        )}

        {!selectedElements?.length && canEdit && (
          <>
            {(setShowThreads || setShowCreateFolder) && (
              <>
                {view !== ECaseViews.DOSSIER ? (
                  <ThreadsIcon
                    title="Wątki"
                    onClick={() => {
                      setShowThreads(!showThreads);
                      toggle?.();
                    }}
                    ref={threadsRef}
                  />
                ) : (
                  <ThreadsIcon
                    title="Foldery"
                    onClick={() => {
                      setShowCreateFolder?.(!showCreateFolder);
                      toggle?.();
                    }}
                    ref={threadsRef}
                  />
                )}
              </>
            )}
          </>
        )}
        {!selectedElements?.length && (
          <ClientIcon
            title="Podmioty"
            onClick={() => {
              setShowPerson(!showPerson);
              toggle?.();
            }}
          />
        )}
        {!selectedElements?.length && (
          <InfoIcon
            title="Metryka teczki"
            onClick={() => {
              setShowInfo(!showInfo);
              toggle?.();
            }}
            ref={refka}
          />
        )}
        {canEdit && (
          <BinIcon
            title="Usuń"
            onClick={() => {
              const ask = localStorage.getItem('ask');

              if (ask === 'true') {
                handleDeleteCase();
                return;
              }

              setShowDelete(true);
              toggle?.();
            }}
          />
        )}
        {!selectedElements?.length && canEdit && (
          <Redirect2Icon
            className={styles.reverse}
            title="Przekaż"
            onClick={() => {
              setShowRedirect(true);
              toggle?.();
            }}
          />
        )}

        {!selectedElements?.length && (
          <CloseIcon
            title="Zamknij teczkę"
            onClick={() => {
              localStorage.removeItem('last_case');
              localStorage.removeItem('last_view_case');
              navigate('/cases');
              toggle?.();
            }}
          />
        )}
      </>
    );
  };

  if (!pack) return <></>;

  return ReactDOM.createPortal(
    <>
      {!showDropdown && (
        <div
          ref={refka}
          style={{
            opacity: x === 0 ? 0 : 1,
            left: `${x}px`,
            top: `${y - 3}px`,
          }}
          className={`${styles.options} ${!showOptions && styles.hide}`}
        >
          {buildHoverOver()}
        </div>
      )}

      {showDropdown && (
        <div
          //@ts-ignore
          ref={outsideRef}
          style={{
            left: `${x}px`,
            top: `${y - 3}px`,
            display: `${!showOptions ? 'none' : 'block'}`,
          }}
          className={styles.dropdown}
        >
          <div className={styles.dropdownWrapper}>{buildDropdown()}</div>
        </div>
      )}

      {showTags && (
        <ModalLayout
          className={styles.overflow}
          onEnd={() => {
            setShowTags(false);
          }}
        >
          <AddTagModal
            toggle={setShowTags}
            updateTags={handleUpdateTags}
            toTag={pack}
          />
        </ModalLayout>
      )}
      {showDelete && (
        <ConfirmModal
          title="Potwierdź usunięcie"
          text="Wykonanie akcji usunięcia przesyłki nieodwracanie usunie teczke z listy"
          acceptLabel="Usuń"
          cancelLabel="Anuluj"
          onAccept={() => {
            setShowDelete(false);
            handleDeleteCase();
          }}
          onCancel={() => setShowDelete(false)}
        />
      )}
      {showRedirect && (
        <RedirectModal
          onCancel={() => setShowRedirect(false)}
          onSelect={handleRedirect}
          Case={pack}
          toggle={() => {
            setShowRedirect(false);
          }}
        />
      )}

      {showInfo && (
        <ModalLayout
          refWrapper={refWrapper}
          customY={-46}
          toggle={() => setShowInfo(false)}
          onEnd={() => {
            setShowTags(false);
          }}
        >
          <CaseInfo
            Case={pack as ICase}
            toggle={() => setShowInfo(false)}
            statuses={statuses}
            tags={tags}
          />
        </ModalLayout>
      )}

      {showPerson && (
        <ModalLayout
          refWrapper={refWrapper}
          className={styles.disable}
          customY={-20}
          customX={0}
          onEnd={() => {
            setShowTags(false);
          }}
        >
          <CasePerson
            toggle={() => setShowPerson(false)}
            Case={pack as ICase}
          />
        </ModalLayout>
      )}
    </>,
    document.body
  );
};

export default CaseOptionsCard;
