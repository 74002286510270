export enum ItemKind {
  /// <summary>
  /// Doręczenie elektroniczne
  /// </summary>
  EDeliveryMessage = 0x1,

  /// <summary>
  /// Dokument z portalu sądów powszechnych
  /// </summary>
  CourtPortalDocument = 0x2,

  /// <summary>
  /// Doręczenie z portalu sądów powszechnych
  /// Doręczenie działa tak, że ma pod sobą 1-X dokumentów
  /// </summary>
  CourtPortalDeliverance = 0x4,

  /// <summary>
  /// Wniosek z portalu sądów powszechnych
  /// </summary>
  CourtPortalApplication = 0x8,

  /// <summary>
  /// Przesyłka papierowa (zarejestrowana ręcznie przez użytkownika)
  /// </summary>
  Parcel = 0x10,

  /// <summary>
  /// Plik teczki (dossier)
  /// </summary>
  CaseFile = 0x20,

  /// <summary>
  /// Dokumenty będące dziećmi doręczonych doręczeń - na potrzeby akt w teczce
  /// </summary>
  CourtPortalDeliveranceDocument = 0x40,

  /// <summary>
  /// E-mail
  /// </summary>
  Email = 0x80,

  /// <summary>
  /// Dokument z edytora dokumentów
  /// </summary>
  EditorDocument = 0x100,

  /// <summary>
  /// Wiadomość z formularza kontaktowego
  /// </summary>
  ContactMessage = 0x200,

  AllWithoutFiles = 1 + 2 + 4 + 8 + 16 + 128 + 4096,
  All = 1 + 2 + 4 + 8 + 16 + 32 + 128 + 512 + 4096,

  /// <summary>
  /// Sprawa
  /// </summary>
  Cases = 0x400,

  /// <summary>
  /// Dokument z zewnętrznego edytora (google docs)
  /// </summary>
  ExternalEditorDocument = 0x800,

  /// <summary>
  /// Przesyłka wychodząca
  /// </summary>
  OutgoingParcel = 0x1000,

  /// <summary>
  /// Folder w dossier
  /// </summary>
  DossierFolder = 0x2000,

  /// <summary>
  /// Link w dossier
  /// </summary>
  DossierLink = 0x4000,

  /// <summary>
  /// Podmiot
  /// </summary>
  Participant = 0x8000,

  /// <summary>
  /// Podmiot w kontekście sprawy
  /// </summary>
  CaseParticipant = 0x10000,

  /// <summary>
  /// Czynność
  /// </summary>
  Task = 0x20000,
}
