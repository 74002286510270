import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { useOnClickOutside } from 'usehooks-ts';
import { useRef } from 'react';

const DeskActiveModal: FC<Props> = ({ toggle, onRemove }) => {
  const navigate = useNavigate();

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    toggle(false);
  });

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => toggle(false)}
          />
        </div>
        <div onClick={() => onRemove()} className={styles.box}>
          <DeskIcon />
          Usuń z biurka
        </div>
      </div>
    </div>
  );
};

export default DeskActiveModal;
