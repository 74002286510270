import moment from 'moment';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { Tooltip as ReactTooltip, Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const PackageBar: FC<Props> = ({ id, date }) => {
  const deadline = moment(date).add(14, 'day');

  const actualDate = moment();

  const days = moment(deadline).diff(actualDate, 'day');
  const isOut = days <= 0;

  let percent = ((14 - days) / 14) * 100;

  if (percent + 8 > 100) {
    percent = 100;
  }

  if (percent < 0) {
    percent = 0;
  }

  const text = isOut ? 'Przeterminowana' : `${14 - days} dni`;

  return (
    <div
      data-tooltip-id={`bar-${id}`}
      data-tooltip-content={text}
      className={`${styles.br} ${styles.hover}`}
    >
      <div
        data-tooltip-id={`bar-${id}`}
        data-tooltip-content={text}
        className={styles.wrapper}
      >
        <div className={styles.bar}>
          <div
            style={{
              width: `${isOut ? 100 : percent}%`,
            }}
            className={`${styles.color} ${isOut && styles.old}`}
          ></div>
        </div>
        {!isOut && (
          <div
            style={{
              left: `${percent}%`,
            }}
            className={styles.dot}
          ></div>
        )}
      </div>
      <Tooltip
        place="bottom"
        style={{
          background: isOut ? '#BA1A1A' : '#5d49c7',
        }}
        id={`bar-${id}`}
      />
    </div>
  );
};

export default PackageBar;
