import { FC, Props } from './typings';
import styles from './styles.module.scss';
import ReactDOM from 'react-dom';
import Avatar from 'components/Avatar';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_24.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/delete_24.svg';
import { useContext, useEffect, useState } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import HollowButton from 'components/HollowButton';
import annotationService from 'api/annotationService';
import moment from 'moment';
const AnnotationModal: FC<Props> = ({
  annotation,
  positionX,
  setClickedAnnotation,
  positionY,
  annotationID,
  comment,
  refetch,
  setMoveWindow,
  moveWindow,
  annotationModalRef,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(comment?.Text ?? '');

  const { mutate: createComment } = annotationService.useCreateComment();
  const { mutate: updateComment } = annotationService.useUpdateComment();
  const { mutate: deleteAnnotation } = annotationService.useDeleteAnnotation();

  useEffect(() => {
    if (!comment?.Text) {
      setEditMode(true);
      setValue(comment?.Text);
      return;
    }
    setEditMode(false);
    setValue(comment?.Text);
  }, [comment]);

  const handleSave = () => {
    if (!comment?.Text) {
      createComment(
        {
          AnnotationId: annotationID,
          Text: value,
        },
        {
          onSuccess: () => {
            setEditMode(false);
            refetch();
          },
        }
      );
      return;
    }

    updateComment(
      {
        CommentId: comment.Id,
        Text: value,
      },
      {
        onSuccess: () => {
          setEditMode(false);
          refetch();
        },
      }
    );
  };

  const handleRemove = () => {
    deleteAnnotation(
      {
        AnnotationId: annotationID,
      },
      {
        onSuccess: () => {
          setEditMode(false);
          setClickedAnnotation(null);
          refetch();
        },
      }
    );
  };

  if (editMode) {
    return ReactDOM.createPortal(
      <div
        ref={annotationModalRef}
        onMouseDown={() => setMoveWindow(true)}
        onMouseUp={() => setMoveWindow(false)}
        style={{ left: `${positionX - 200}px`, top: `${positionY - 10}px` }}
        className={styles.modal}
      >
        <div className={styles.header}>
          <TrashIcon onClick={handleRemove} />
          <CloseIcon
            className={styles.closeButton}
            onClick={() => setClickedAnnotation(null)}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <Avatar className={styles.avatar} name={`${annotation?.Author}`} />
          </div>
          <div className={styles.comment}>
            <div className={styles.data}>
              <div className={styles.name}>{`${annotation?.Author}`}</div>

              <div className={styles.date}>
                {moment(annotation?.CreationDate)
                  .format('DD.MM.YYYY')
                  .toString()}
              </div>
            </div>
            <Input
              label="Wpisz treść komentarza"
              defaultValue={value}
              onChange={(text) => setValue(text)}
            />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <div onClick={handleSave} className={styles.button}>
            Zapisz
          </div>
        </div>
      </div>,
      document.body
    );
  }

  return ReactDOM.createPortal(
    <div
      ref={annotationModalRef}
      style={{ left: `${positionX - 200}px`, top: `${positionY - 10}px` }}
      className={styles.modal}
    >
      <div className={styles.header}>
        <div
          onMouseDown={() => setMoveWindow(true)}
          onMouseUp={() => setMoveWindow(false)}
          className={styles.magicPlace}
        ></div>
        <TrashIcon onClick={handleRemove} />

        <CloseIcon
          className={styles.closeButton}
          onClick={() => setClickedAnnotation(null)}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <Avatar className={styles.avatar} name={`${annotation?.Author}`} />
        </div>
        <div className={styles.comment}>
          <div className={styles.data}>
            <div className={styles.name}>{`${annotation?.Author}`}</div>

            <div className={styles.date}>
              {moment(annotation?.CreationDate).format('DD.MM.YYYY').toString()}
            </div>
          </div>
          <div className={styles.text}>{comment?.Text ?? ''}</div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div
          onClick={() => {
            setValue(comment?.Text ?? '');
            setEditMode(true);
          }}
          className={styles.button}
        >
          Edytuj
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AnnotationModal;
