import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Input from 'components/Input';
import Button from 'components/Button';
import { ENotificationType, notification } from 'utils/notification';
import settingsService from 'api/settingsService';
import Loading from 'components/Loading';
import TextArea from 'components/TextArea';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';

enum ETabs {
  INFO,
  PLACE,
  PREVIEW,
}

const PackageSettings = () => {
  const [actualView, setActualView] = useState(ETabs.INFO);

  const { data: formData, refetch, isLoading } = settingsService.useGetForm();
  const { mutate: create } = settingsService.useCreateForm();

  const forms = useMemo(() => formData?.data ?? [], [formData]);

  useEffect(() => {
    if (isLoading || !!forms.length) return;

    create(undefined, {
      onSuccess: () => {
        refetch();
      },
    });
  }, [isLoading, forms]);

  const link = `https://beta.notaup.pl/form/index.html?id=${forms?.[0]?.Key}`;

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <Loading />
      </div>
    );
  }

  // if (forms.length === 0 && !isLoading) {
  //   return (
  //     <div className={styles.wrapper}>
  //       <div className={styles.title}>Utwórz formularz kontaktowy</div>
  //       <div className={styles.text}>
  //         Potrzebujesz formularza kontaktowego na swojej stronie utwórz go
  //       </div>
  //       <div className={styles.btnWrapper2}>
  //         <Button
  //           onClick={() => {
  //             create(undefined, {
  //               onSuccess: () => {
  //                 refetch();
  //               },
  //             });
  //           }}
  //           text="Utwórz"
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  // if (actualView === ETabs.INFO) {
  //   return (
  //     <div className={styles.wrapper}>
  //       <div className={styles.title}>
  //         Przesyłki szyfrowane działają według następujących zasad:
  //       </div>
  //       <ol>
  //         <li>przesyłki są szyfrowane już na komputerze nadawcy</li>
  //         <li>przesyłki są rozszyfrowywane dopiero na komputerze adresata </li>
  //         <li>przesyłkę może odczytać jej nadawca lub adresat</li>
  //         <li>
  //           na serwerze przesyłki są przechowywane w postaci zaszyfrowanej
  //         </li>
  //         <li>operator serwera pocztowego nie może odczytać przesyłek"</li>
  //       </ol>
  //     </div>
  //   );
  // }

  if (actualView === ETabs.PREVIEW) {
    return (
      <div className={styles.wrapper}>
        <div onClick={() => setActualView(ETabs.INFO)} className={styles.back}>
          <BackIcon />
          Powrót
        </div>
        <div className={styles.title}>Podglad</div>
        <div className={styles.text}>
          Tak będzie wyglądał twój formularz po osadzeniu na stronie
        </div>
        <div className={styles.test}>
          <iframe className={styles.iframe} src={link}></iframe>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Czym są przesyłki szyfowane</div>
      <>
        <div>✓ przesyłki są szyfrowane już na komputerze nadawcy</div>
        <div>
          ✓ przesyłki są rozszyfrowywane dopiero na komputerze adresata 
        </div>
        <div>✓ przesyłkę może odczytać jej nadawca lub adresat</div>
        <div>
          ✓ na serwerze przesyłki są przechowywane w postaci zaszyfrowanej 
        </div>
        <div>✓ operator serwera pocztowego nie może odczytać przesyłek</div>
      </>

      <div className={styles.title}>
        Pobieranie formularza i wstawienia na swojej stronie www.
      </div>
      <div className={styles.text}>
        Aby umieścić formularz kontaktowy na swojej stronie www należy:
        <ol>
          <li>Skopiować kod formularza</li>
          <li>Wkleić ten kod w kodzie swojej strony www</li>
        </ol>
      </div>

      <TextArea
        className={styles.copy}
        disabled
        value={`<iframe src=${link}  width="1072px" height="416px"></iframe>`}
        label="Kod osadzenia"
        onChange={() => {}}
      />
      <div className={styles.btnWrapper}>
        <div
          onClick={() => setActualView(ETabs.PREVIEW)}
          className={styles.cta}
        >
          Zobacz podgląd formularza
        </div>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(link);
            notification({
              type: ENotificationType.INFO,
              text: 'Kod osadzenia został skopiowany do twojego schowka teraz wklej go na swojej stronie',
              title: 'Skopiowano do schowka',
            });
          }}
          text="Kopiuj"
        />
      </div>
    </div>
  );
};

export default PackageSettings;
