import Thread from 'components/Thread';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { Droppable } from 'react-beautiful-dnd';

const Threads: FC<Props> = ({ threads }) => {
  return (
    <Droppable droppableId="threads">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className={styles.wrapper}>
          {threads.map((t, index) => (
            <Thread index={index} thread={t} />
          ))}
        </div>
      )}
    </Droppable>
  );
};

export default Threads;
