import { useMutation } from 'react-query';
import { axiosInstance } from './axios';

const queryKeys = {
  getCount: 'draftService.getCount',
};

const getCount = ({}) => {
  return axiosInstance.get(`/draft/counters`);
};

const useGetCount = () => {
  return useMutation(getCount);
};

export default {
  useGetCount,
};
