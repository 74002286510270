import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as InfoIcon } from 'assets/icons/authPerson.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/authMail.svg';
import { ReactComponent as IDIcon } from 'assets/icons/authSignature.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/authKey.svg';
import { ReactComponent as FactorIcon } from 'assets/icons/2fa.svg';

export enum ESteps {
  INFORMATION,
  EMAIL_VERIFICATION,
  ID_VERIFICATION,
  TWO_FACTOR,
  KEY_GENERATION,
}

const getIcon = (type: ESteps) => {
  switch (type) {
    case ESteps.INFORMATION:
      return <InfoIcon />;
    case ESteps.EMAIL_VERIFICATION:
      return <EmailIcon />;
    case ESteps.ID_VERIFICATION:
      return <IDIcon />;
    case ESteps.KEY_GENERATION:
      return <KeyIcon />;
    case ESteps.TWO_FACTOR:
      return <FactorIcon />;
  }
};

const Stepper: FC<Props> = ({ steps, actualStep }) => {
  // const actualStep = ESteps.INFORMATION;

  // const steps = [
  //   {
  //     type: ESteps.INFORMATION,
  //     title: 'Twoje dane',
  //     text: 'Wpisz email i hasło bądź wybierz metodę logowania',
  //   },
  //   {
  //     type: ESteps.EMAIL_VERIFICATION,
  //     title: 'Weryfikacja adresu email',
  //     text: 'Wpisz kod weryfikujący',
  //   },
  //   {
  //     type: ESteps.ID_VERIFICATION,
  //     title: 'Weryfikacja tożsamości',
  //     text: 'Wybierz sposób weryfikacji',
  //   },
  //   {
  //     type: ESteps.KEY_GENERATION,
  //     title: 'Klucz przywracania',
  //     text: 'Generuj Swój klucz przywracania',
  //   },
  // ];

  return (
    <div className={styles.wrapper}>
      {steps.map((step, index) => {
        const isActive = actualStep === index;
        return (
          <div className={styles.wrap}>
            <div className={`${styles.card} ${isActive && styles.active}`}>
              <div className={`${styles.box}`}>{getIcon(step.type)}</div>
              <div className={styles.info}>
                <div className={styles.title}>{step.title}</div>
                <div className={styles.body}>{step.text}</div>
              </div>
            </div>
            {index < steps.length - 1 && <div className={styles.line}></div>}
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
