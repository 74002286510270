import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';

const queryKeys = {
  getPreview: (id: string) => ['emailService.getPreview', id],
  // getFile: (payload: IGetFile) => ['emailService.getFile', ],
};

const getPreview = (id: string) => {
  const query = new URLSearchParams(window.location.search);
  query.append('id', id);

  return axiosInstance.get(`/Email/Preview?${query}`);
};

const useGetPreview = (id: string, enabled: boolean = true) => {
  return useQuery(queryKeys.getPreview(id), () => getPreview(id), {
    enabled: enabled,
  });
};

export interface IGetFile {
  id: string;
  mailId: string;
}

const getFile = async (payload: IGetFile) => {
  return await axiosInstance.get(
    `/email/attachment?id=${payload.id}&mailId=${payload.mailId}`
  );
};

const useGetFile = () => {
  return useMutation(getFile);
};

export default {
  useGetPreview,
  useGetFile,
};
