import { axiosInstance } from 'api/axios';
import fileService from 'api/fileService';
import pluginService from 'api/pluginService';
import statusService from 'api/statusService';
import tagsService from 'api/tagsService';
import EmptyPanel from 'components/EmptyPanel';
import Loading from 'components/Loading';
import MessageCard from 'components/MessageCard';
import Package from 'components/Package';
import PackageThread from 'components/PackageThread';
import PanelsLayout from 'components/PanelsLayout';
import DecryptedMsgs from 'contexts/DecryptedMsgs';
import PackagesContext from 'contexts/PackagesContext';
import { IAttachment } from 'dto/IAttachment';
import { IPackage } from 'dto/IPackage';
import useFilterMetaData from 'hooks/useFilterMetaData';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { blobToBase64 } from 'utils/blobToBase64';
import { decryptFile } from 'utils/decryptFile';
import downloadFile from 'utils/downloadFile';
import {
  fetchEmailAttachment,
  fetchEmailAttachmentBlob,
} from 'utils/fetchEmailAttachment';
import { getBlob } from 'utils/getBlob';
import { isAllowedAttachment } from 'utils/isAllowedAttachment';
import { ENotificationType, notification } from 'utils/notification';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import styles from './styles.module.scss';
import KeysContext from 'contexts/KeysContext';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { useDebounce } from 'use-debounce';
import { PackageList } from './packageList';
import CPluginContext from 'contexts/PluginContext';
import { getKeys } from 'utils/getKeys';
import { decryptPrivateKey } from 'utils/decryptPrivateKey';
import { readFile } from 'utils/readFile';
import PDFViewer from 'components/PDFViever';
import {
  EOrder,
  ETypeOfOrder,
  IOrderObject,
} from 'components/SortOrder/typings';
import SortOrder from 'components/SortOrder';

const MessagesModule = ({ hideMenu = false }: { hideMenu?: boolean }) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const [selectedElements, setSelectedElements] = useState<IPackage[]>([]);
  const [canSelect, setCanSelect] = useState(false);
  const [selectMultiple, setSelectMultiple] = useState(false);
  const { filterId, kind, packageId, threadId, status, PDFId } = useParams();
  const [showPDF, setShowPDF] = useState<Blob | string | null>(null);
  const [searchText, setSearchText] = useState('');

  const [debouncedText] = useDebounce(searchText, 300);

  const isIncoming = status === 'incoming';

  const separatorRef = useRef<HTMLDivElement>(null);

  const [showSomething, setShowSomething] = useState(true);

  const [timer, setTimer] = useState<any>(null);
  const { mutate: isPreview } = pluginService.useCheckPreview();
  const { mutate: tryPreview } = pluginService.usePreview();

  const { packages, setPackages } = useContext(PackagesContext);
  const { keys, setKeys } = useContext(KeysContext);
  const [page, setPage] = useState(0);

  const { listId, listName } = useFilterMetaData({
    filterId: filterId!,
  });

  const [listFilter, setListFilter] = useState<IOrderObject>({
    type: ETypeOfOrder.CREATION_DATE,
    order: EOrder.DESCENDING,
    initalized: false,
  });

  const [statusId, setStatusId] = useState<string | null>(null);
  const [tagId, setTagId] = useState<string | null>(null);
  const [kinde, setKind] = useState<number | null>(null);

  const {
    data,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
    status: stat,
  } = useInfiniteQuery(
    ['packages', filterId, debouncedText, listFilter, statusId, tagId, kinde],
    async ({ pageParam = '' }) => {
      const query = new URLSearchParams(window.location.search);
      if (!!searchText.length) {
        query.append('Text', debouncedText);
      }

      if (status !== 'pisma') {
        //@ts-ignore
        if (filterId! !== 'sending') {
          query.append('IsIncoming', isIncoming.toString());
          query.append('Group', 'true');

          if (kinde !== null && kinde !== -1) {
            query.append('Kind', kinde.toString());
          } else {
            //@ts-ignore
            query.append('FilterId', filterId);
          }
          query.append('PageToken', pageParam);
          query.append('SortProp', listFilter.type);
          query.append('SortOrder', listFilter.order);
        } else {
          query.append('PageToken', pageParam);
          query.append('Group', 'true');
          query.append('IsIncoming', 'false');
          query.append('Kind', '4105');
          query.append('SortProp', listFilter.type);
          query.append('SortOrder', listFilter.order);
        }
      } else {
        query.append('Group', 'true');
        query.append('IsIncoming', 'false');
        query.append('PageToken', pageParam);
        query.append('SortProp', listFilter.type);
        query.append('SortOrder', listFilter.order);
        query.append('Kind', '256');
      }

      if (statusId) {
        query.append('StatusId', statusId);
      }

      if (tagId) {
        query.append('TagId', tagId);
      }

      setPage(page + 1);
      const res = await axiosInstance.get(`/list?${query}`);
      return res.data;
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.LastPage) {
          return lastPage.PageToken;
        }
        return undefined;
      },
    }
  );

  // useEffect(() => {
  //   setPackages([]);
  //   refetch();
  // }, [listFilter]);

  const compileData = async () => {
    if (!data) return;

    const lastPage: IPackage[] = data?.pages[data?.pages.length - 1].Items;

    const packs = await Promise.all(
      data?.pages
        .map((page) => {
          return page.Items;
        })
        .flat()

      // .sort((left: IPackage, right: IPackage) => {
      //   //@ts-ignore
      //   return right.Unread - left.Unread;
      // })
    );

    const newMap = packs.map((p: IPackage) => {
      const oldPack = packages.find((a) => a.Id === p.Id);

      if (oldPack?.isDelete) {
        p.isDelete = true;
      }

      return p;
    });

    const objectKeys = await Promise.all(
      data?.pages
        .map((page) => {
          return page.ObjectKeys;
        })
        .flat()
    );

    const keys = await Promise.all(
      data?.pages
        .map((page) => {
          return page.Keys;
        })
        .flat()
    );

    setPackages(newMap.flat());
    setKeys([keys, objectKeys].flat());
  };

  useEffect(() => {
    compileData();
  }, [data]);

  useEffect(() => {
    if (!listFilter.initalized) return;

    setPackages([]);
    setTimeout(() => {
      refetch();
    }, 100);
  }, [listFilter]);

  useEffect(() => {
    refetch();
  }, [filterId]);

  useEffect(() => {
    setPackages([]);

    setTimeout(() => {
      refetch();
    }, 500);
  }, [debouncedText, statusId, filterId, tagId, kinde]);

  const changeToSelectable = (e: any) => {
    if (e.code === 'ControlLeft') {
      setCanSelect(true);
    }

    if (e.code === 'ShiftLeft') {
      setCanSelect(true);
      setSelectMultiple(true);
    }
  };

  const changeToNotSelectable = (e: any) => {
    setCanSelect(false);
    setSelectMultiple(false);
  };
  useEffect(() => {
    window.addEventListener('keydown', changeToSelectable);
    window.addEventListener('keyup', changeToNotSelectable);

    return () => {
      window.removeEventListener('keydown', changeToSelectable);
      window.removeEventListener('keyup', changeToNotSelectable);
    };
  }, []);

  const handleScroll = (e: any) => {
    console.log('scroll');
    if (timer) {
      clearTimeout(timer);
    }

    setShowSomething(false);

    setTimer(
      setTimeout(() => {
        setShowSomething(true);
        setTimer(null);
      }, 500)
    );

    if (!separatorRef.current) return;
    if (isLoading || isFetchingNextPage) return;
    var rect = separatorRef.current.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    if (isVisible) {
      fetchNextPage();
    }
  };

  const handleChangeSearchBar = (
    text: string,
    statusId: string | null,
    tagId: string | null,
    entityId: string | null,
    kind: number | null
  ) => {
    setSearchText(text);
    setStatusId(statusId);
    setTagId(tagId);
    setKind(kind);
  };

  const [firstElement, setFirstElement] = useState<IPackage | null>(null);

  useEffect(() => {
    for (const pack of packages) {
      const p = selectedElements.find((p) => p?.Id === pack?.Id);
      if (p) {
        setFirstElement(p);
        return;
      }
    }

    setFirstElement(null);
  }, [selectedElements]);

  const { mutate: getFile } = fileService.useGetFiltersForCases();
  const navigate = useNavigate();

  // getFile(
  //   {
  //     id: attachment.Uri,
  //     ownerKind: pack.Kind,
  //   },
  //   {
  //     onSuccess: async (data) => {
  //

  //       await downloadFile(userId, attachment.FileName, data.data.Url);
  //     },
  //   }
  // );

  // Jeżeli załącznik jest zawieszony do sprawy trzeba użyć tego co wyżej i odszyfrować

  const decryptSecuredEmail = async (
    pack: IPackage,
    attachment: IAttachment,
    key: any
  ): Promise<Blob> => {
    return new Promise(async (resolve) => {
      const myKeys = await getKeys(userId!);

      let parentKey: any = null;

      if (key?.data?.ObjectKeys?.[0]) {
        parentKey = key?.data?.Keys.find(
          (pkey: any) => pkey?.Id === key?.data?.ObjectKeys[0].ParentKeyId
        );
      }

      getFile(
        {
          id: attachment.Uri,
          ownerKind: pack.Kind,
        },
        {
          onSuccess: async (data) => {
            const file = await getBlob(data.data.Url);
            const blob = new Blob([file.data]);

            try {
              const decryptedBlob = await decryptFile(
                userId,
                new Blob([file.data]),
                key?.data?.ObjectKeys?.[0],
                parentKey
              );

              resolve(decryptedBlob);
            } catch (e) {}
          },
        }
      );
    });
  };

  const onDownload = async (
    pack: IPackage,
    attachment: IAttachment,
    key?: any
  ) => {
    if (pack.Kind === 128) {
      if (!!key?.data?.ObjectKeys?.length) {
        const blob = await decryptSecuredEmail(pack, attachment, key);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', attachment.FileName);
        document.body.appendChild(link);
        link.click();
        return;
      }
      const pdfBlobUrl = await fetchEmailAttachment(attachment.Id, pack.Id);
      const link = document.createElement('a');
      link.href = pdfBlobUrl;
      link.setAttribute('download', attachment.FileName);
      document.body.appendChild(link);
      link.click();

      return;
    }

    getFile(
      {
        id: attachment.Uri,
        ownerKind: pack.Kind,
      },
      {
        onSuccess: async (data) => {
          const key = keys.find(
            (key: any) => key.Id === attachment.EncryptionKeyId
          );
          await downloadFile(userId, attachment.FileName, data.data.Url, key);
        },
      }
    );
  };

  const checkPreview = async (
    attachmentId: string,
    fileName: string,
    kind: number
  ) => {
    return new Promise((resolve) => {
      isPreview(
        {
          AttachmentId: attachmentId,
          DocumentId: attachmentId,
          FileName: fileName,
          Kind: Number(kind),
        },
        {
          onSuccess: (response) => {
            resolve(new Blob([response.data]));
          },
          onError: () => resolve(false),
        }
      );
    });
  };

  const [pdfLoading, setPDFLoading] = useState(false);

  const previewFromPlugin = async (
    pack: IPackage,
    blob: Blob,
    attachment: IAttachment
  ) => {
    const b: string = (await blobToBase64(blob)) as string;

    tryPreview(
      {
        AttachmentId: attachment.Id,
        Content: b.split(',')[1],
        ContentType: attachment.ContentType,
        DocumentId: attachment.Id,
        FileName: attachment.FileName,
        Kind: attachment.Kind,
      },
      {
        onSuccess: async (response) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setPDFLoading(false);
          setShowPDF(new Blob([buffer]));
          if (!PDFId?.length) {
            if (hideMenu) {
              navigate(
                `/packages/fullscreen/${pack.Id}/${pack.Kind}/${attachment.Id}`
              );
              return;
            }
            navigate(
              `/packages/${filterId}/${status}/${pack.Kind}/${pack.Id}/preview-pdf/${attachment.Id}`
            );
          }
        },
      }
    );
  };

  const { plugin } = useContext(CPluginContext);
  const [showVersion, setShowVersion] = useState(false);

  const onPreview = async (
    pack: IPackage,
    attachment: IAttachment,
    key?: any
  ) => {
    setPDFLoading(true);

    const extension = attachment.FileName.split('.').at(-1);
    const isAllowed = isAllowedAttachment(extension ?? 'no');
    const isPDF = extension === 'pdf';

    if (!isAllowed && !isPDF) {
      notification({
        type: ENotificationType.ERROR,
        title: 'Błąd konwersji pliku',
        text: 'Przepraszamy! aktualnie nie obsługujemy tego formatu pliku',
      });
      setPDFLoading(false);
      return;
    }

    const attachmentId = attachment.Id ?? attachment.Uri;

    if (!isPDF) {
      const preview = await checkPreview(
        attachmentId,
        attachment.FileName,
        attachment.Kind
      );
    }

    if (pack.Kind === 128) {
      if (!key) {
        const pdfBlobUrl = await fetchEmailAttachmentBlob(
          attachment.Id,
          pack.Id
        );
        setPDFLoading(false);
        setShowPDF(pdfBlobUrl);
        navigate(
          `/packages/${filterId}/${status}/${pack.Kind}/${pack.Id}/preview-pdf/${attachmentId}`
        );
        return;
      }

      if (!!key.data?.ObjectKeys?.length) {
        const blob = await decryptSecuredEmail(pack, attachment, key);
        if (!plugin.actual) {
          setShowVersion(true);
          setPDFLoading(false);
          return;
        }
        if (!isPDF) {
          previewFromPlugin(pack, new Blob([blob]), attachment);
          setPDFLoading(false);
          return;
        }
        setPDFLoading(false);
        setShowPDF(blob);
        if (hideMenu) {
          navigate(
            `/packages/fullscreen/${pack.Id}/${pack.Kind}/${attachmentId}`
          );
          return;
        }

        navigate(
          `/packages/${filterId}/${status}/${pack.Kind}/${pack.Id}/preview-pdf/${attachmentId}`
        );
        return;
      }

      const pdfBlobUrl = await fetchEmailAttachment(attachmentId, pack.Id);
      if (isAllowed && !isPDF) {
        if (!plugin.actual) {
          setPDFLoading(false);
          setShowVersion(true);
          return;
        }

        setPDFLoading(false);

        previewFromPlugin(pack, new Blob([pdfBlobUrl]), attachment);
        return;
      }
      setShowPDF(pdfBlobUrl);

      if (hideMenu) {
        navigate(
          `/packages/fullscreen/${pack.Id}/${pack.Kind}/${attachment.Id}`
        );
        return;
      }

      if (!PDFId?.length) {
        navigate(
          `/packages/${filterId}/${status}/${pack.Kind}/${pack.Id}/preview-pdf/${attachmentId}`
        );
      }
      return;
    }

    getFile(
      {
        id: attachment.Uri,
        ownerKind: attachment.Kind,
      },
      {
        onSuccess: async (data) => {
          const file = await getBlob(data.data.Url);
          const key = keys.find(
            (key: any) => key?.Id === attachment?.EncryptionKeyId
          );

          let parentKey = null;

          if (key?.ParentKeyId) {
            parentKey = keys.find((kp: any) => kp?.Id === key.ParentKeyId);
          }

          try {
            const decryptedBlob = await decryptFile(
              userId,
              new Blob([file.data]),
              key,
              parentKey
            );

            if (isAllowed && !isPDF) {
              if (!plugin.actual) {
                setShowVersion(true);
                return;
              }
              previewFromPlugin(pack, decryptedBlob, attachment);
              return;
            }
            setPDFLoading(false);
            setShowPDF(decryptedBlob);
            if (hideMenu) {
              navigate(
                `/packages/fullscreen/${pack.Id}/${pack.Kind}/${attachment.Id}`
              );
              return;
            }
            if (!PDFId?.length) {
              navigate(
                `/packages/${filterId}/${status}/${pack.Kind}/${pack.Id}/preview-pdf/${attachmentId}`
              );
            }
          } catch (e) {
            setShowPDF(new Blob([file.data]));
            setPDFLoading(false);
            if (!PDFId?.length) {
              if (hideMenu) {
                navigate(
                  `/packages/fullscreen/${pack.Id}/${pack.Kind}/${attachment.Id}`
                );
                return;
              }

              navigate(
                `/packages/${filterId}/${status}/${pack.Kind}/${pack.Id}/preview-pdf/${attachmentId}`
              );
            }
          }
        },
      }
    );
  };

  const scrollWrapper = useRef(null);

  const { decryptedMsgs, setDecryptedMsgs } = useContext(DecryptedMsgs);
  const leftPanel = () => {
    if (hideMenu) {
      return (
        <Package
          onDownload={onDownload}
          onPreview={onPreview}
          listId={listId ?? 0}
          listName={listName}
          kind={kind}
          packageId={packageId}
          showPDF={showPDF}
          setShowPDF={setShowPDF}
          showVersion={showVersion}
        />
      );
    }
    return (
      <div className={styles.wrapper}>
        <div className={styles.order}>
          <SortOrder
            isLoading={isLoading || isFetchingNextPage}
            refetch={() => {
              // setPackages([]);
              // refetch();
            }}
            setListFilter={setListFilter}
            type={listFilter.type}
            order={listFilter.order}
          />
        </div>
        {(isLoading || isFetchingNextPage) && <Loading />}

        <PackageList
          status={stat}
          listFilter={listFilter}
          separatorRef={separatorRef}
          handleScroll={handleScroll}
          canSelect={canSelect}
          debouncedText={debouncedText}
          firstElement={firstElement}
          isIncoming={isIncoming}
          onDownload={onDownload}
          onPreview={onPreview}
          searchText={searchText}
          selectMultiple={selectMultiple}
          selectedElements={selectedElements}
          setSelectedElements={setSelectedElements}
          showSomething={showSomething}
          isLoading={isLoading}
          remove={() => {}}
          fetchNextPage={fetchNextPage}
        />

        {isFetchingNextPage && (
          <div className={styles.loadingWrapper}>
            <Loading />
          </div>
        )}
      </div>
    );
  };

  const rightPanel = () => {
    if (pdfLoading) {
      <div className={styles.loadingWrapper}>
        <Loading />
      </div>;
    }
    if (hideMenu && showPDF && !pdfLoading) {
      return (
        <div className={styles.pdf}>
          <PDFViewer file={showPDF} />
        </div>
      );
    }

    if (hideMenu) {
      return <></>;
    }

    return (
      <>
        {!!kind?.length && !!packageId?.length && (
          <Package
            onDownload={onDownload}
            onPreview={onPreview}
            listId={listId ?? 0}
            listName={listName}
            kind={kind}
            packageId={packageId}
            showPDF={showPDF}
            setShowPDF={setShowPDF}
            loadingPDF={pdfLoading}
            showVersion={showVersion}
          />
        )}
        {!!threadId?.length && (
          <PackageThread
            listFilter={listFilter}
            setShowPDF={setShowPDF}
            showPDF={showPDF}
            onPreview={onPreview}
            onDownload={onDownload}
            listId={listId ?? 0}
            listName={listName}
          />
        )}
        {!packageId?.length && !threadId?.length && <EmptyPanel />}
      </>
    );
  };
  return (
    <PanelsLayout
      leftClassName={hideMenu ? styles.menu : styles.changeSize}
      onChangeSearch={handleChangeSearchBar}
      leftPanel={leftPanel()}
      rightPanel={rightPanel()}
      resize
      disableHeader={hideMenu ? true : false}
    />
  );
};
export default MessagesModule;
