import Avatar from 'components/Avatar';
import styles from './styles.module.scss';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { IUser } from 'dto/IUser';
import { ReactComponent as CheckIcon } from 'assets/icons/check_24.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/delete_24.svg';
import annotationService from 'api/annotationService';
import { useParams } from 'react-router-dom';
import { IAnnotation } from 'dto/IAnnotation';
import classNames from 'classnames';
import useGetPDFViewer from 'hooks/useGetPDFViewer';
import TextArea from 'components/TextArea';
import { sortByCoordinatesOnPDF } from 'utils/sortByCoordinatesOnPDF';

const Comment = ({
  annotation,
  refetch,
  editComment,
  setEditComment,
  modalClassName,
}: {
  annotation: IAnnotation;
  refetch: () => void;
  editComment?: string;
  setEditComment?: (s: string) => void;
  modalClassName?: string;
}) => {
  const [value, setValue] = useState(annotation?.Comments?.at(0)?.Text ?? '');
  const [editMode, setEditMode] = useState(false);

  const {
    annotation: selectedAnnotation,
    setAnnotation,
    setShowAnnotation,
    getShowAnnotation,
  } = useGetPDFViewer();

  const { mutate: updateComment } = annotationService.useUpdateComment();
  const { mutate: deleteAnnotation } = annotationService.useDeleteAnnotation();
  const { mutate: createComment } = annotationService.useCreateComment();

  const handleSave = () => {
    if (!annotation?.Comments?.at(0)?.Id) {
      createComment(
        {
          AnnotationId: annotation.Id,
          Text: value,
        },
        {
          onSuccess: () => {
            setEditMode(false);
            setEditComment?.('');
            refetch();
          },
        }
      );
      return;
    }
    updateComment(
      {
        CommentId: annotation?.Comments?.at(0)?.Id,
        Text: value,
      },
      {
        onSuccess: () => {
          refetch();
          setEditComment?.('');
          setEditMode(false);
        },
      }
    );
  };

  const handleDelete = () => {
    deleteAnnotation(
      {
        AnnotationId: annotation.Id,
      },
      {
        onSuccess: () => {
          setEditComment?.('');
          refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (annotation.Id === editComment) {
      setEditMode(true);
    }
  }, []);

  if (editMode) {
    return (
      <div
        className={`${styles.modal} ${
          (selectedAnnotation() === annotation.Id ||
            getShowAnnotation() === annotation.Id) &&
          styles.active
        } ${modalClassName}`}
      >
        <div className={styles.editWrap}>
          {/* <div className={styles.top}>
            <Avatar className={styles.avatar} name={`${annotation?.Author}`} />
          </div> */}

          {/* <div className={styles.data}> */}
          {/* <div className={styles.name}>{`${annotation?.Author}`}</div> */}

          {/* <div className={styles.date}>
                {moment(annotation?.CreationDate)
                  .format('DD.MM.YYYY')
                  .toString()}
              </div> */}
          {/* </div> */}
          <div className={styles.inputWrapper}>
            <TextArea
              className={styles.eInput}
              onChange={(t) => setValue(t)}
              label=""
              value={value}
            />
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <div onClick={handleSave} className={styles.button}>
            Zapisz
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onMouseEnter={() => setAnnotation(annotation.Id)}
      onMouseLeave={() => setAnnotation(null)}
      className={`${styles.modal} ${
        (selectedAnnotation() === annotation.Id ||
          getShowAnnotation() === annotation.Id) &&
        styles.active
      } ${modalClassName}`}
    >
      <div className={styles.header}>
        {/* <CheckIcon /> */}
        <TrashIcon onClick={handleDelete} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <Avatar className={styles.avatar} name={`${annotation?.Author}`} />
        </div>
        <div className={styles.comment}>
          <div className={styles.data}>
            <div className={styles.name}>{`${annotation?.Author}`}</div>

            <div className={styles.date}>
              {moment(annotation?.CreationDate).format('DD.MM.YYYY').toString()}
            </div>
          </div>
          <div className={styles.text}>
            {annotation.Comments?.[0]?.Text ?? ''}
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div onClick={() => setEditMode(true)} className={styles.button}>
          Edytuj
        </div>
      </div>
    </div>
  );
};

const Comments = ({
  documentID,
  editComment,
  setEditComment,
  modalClassName,
}: {
  documentID: string;
  editComment?: string;
  setEditComment?: (s: string) => void;
  modalClassName?: string;
}) => {
  const { kind, packageId, PDFId } = useParams();
  const { data: annotations, refetch } = annotationService.useGet(documentID);
  const { PDF, setPDF } = useGetPDFViewer();
  return (
    <div className={styles.wrapper}>
      {annotations
        ?.sort(sortByCoordinatesOnPDF)
        ?.map((annotation: IAnnotation) => (
          <Comment
            modalClassName={modalClassName}
            key={annotation.Id}
            editComment={PDF.editComment}
            setEditComment={() => setPDF({ ...PDF, editComment: '' })}
            refetch={refetch}
            annotation={annotation}
          />
        ))}
    </div>
  );
};

export default Comments;
