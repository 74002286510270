import './styles.module.scss';
import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';
import Input from 'components/Input';
import TagBadge from 'components/TagBadge';
import { EViews } from '../ClassificationTab/typings';
import ClassificationDefault from '../ClassificationDefault';

const ClassificationClients: FC<Props> = ({ myPrivilages, type }) => {
  return <ClassificationDefault myPrivilages={myPrivilages} type={type} />;
};

export default ClassificationClients;
