import { FC, Props } from './typings';
import styles from './styles.module.scss';
import classNames from 'classnames';

const TabButton: FC<Props> = ({ Icon, title, handleClick, isActive }) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <div
        onClick={handleClick}
        className={`${styles.titleBar} ${isActive && styles.active}`}
      >
        <div className={styles.info}>
          <Icon className={styles.headerIcon} />
          <div className={styles.title}>{title}</div>
        </div>
      </div>
    </div>
  );
};

export default TabButton;
