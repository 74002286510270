import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tagsService from 'api/tagsService';
import IconsState from 'components/IconsState';
import LetterOptionsCard from 'components/LetterOptionsCard/OptionsCard';
import StatusBadge from 'components/StatusBadge';
import TagBadge from 'components/TagBadge';
import CertContext from 'contexts/CertContext';
import MenuContext from 'contexts/MenuContext';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import settingsContext, { IOptionType } from 'contexts/SettingsContext';

const LetterCard: FC<Props> = ({
  selectedItems,
  setSelectedItems,
  isOutcoming,
  msg,
  statuses,
  tags,
  refetch,
}) => {
  const navigate = useNavigate();
  const { caseId, status: linkStatus } = useParams();
  const { mutate: removeTag } = tagsService.useRemoveTagFromPackage();
  const [status, setStatus] = useState<IStatus | null>(null);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);
  const { cert, setCert } = useContext(CertContext);
  useEffect(() => {
    const t = tags?.filter((tag) =>
      msg?.TagIds?.find((iTag) => tag.Id === iTag)
    );

    const newStatus = statuses.find((s) => s.Id === msg.StatusId);
    setFindedTags(t);

    setStatus(newStatus ?? null);
  }, [statuses, msg.TagIds]);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        OwnerId: msg.Id,
        OwnerKind: msg.Kind,
        TagId: tag.Id,
      },
      {
        onSuccess: () => {
          setFindedTags(findedTags.filter((ftag) => ftag.Id !== tag.Id));
        },
      }
    );
  };

  // if (!!msg?.S3?.length) {
  //
  // }

  const handleClick = (e: any) => {
    if (showOptions) return;
    const ele = e.target.tagName.toLowerCase();
    const button = e.target.outerHTML.search('button') !== -1;
    const classNames: string[] = Array.from(e.target.classList);
    const isFullscreen = window.location.href.search('fullscreen') !== -1;

    if (classNames && classNames[0] && classNames[0].search('date') !== -1)
      return;

    if (ele !== 'div' || button) {
      return;
    }

    if (isOutcoming) {
      setSelectedItems?.([...selectedItems!, msg]);
      return;
    }

    setCert({ id: '', name: '' });

    if (msg.S4) {
      if (isFullscreen) {
        navigate(
          `/fullscreen/cases/${caseId}/letter/${msg.Kind}/${msg.Id}/${msg.S4}/${msg.EncryptionKeyId}`
        );
        return;
      }
      navigate(
        `/cases/${caseId}/letter/${msg.Kind}/${msg.Id}/${msg.S4}/${msg.EncryptionKeyId}`
      );
      return;
    }

    if (isFullscreen) {
      navigate(
        `/fullscreen/cases/${caseId}/letter/${msg.Kind}/${msg.Id}/${msg.S3}/${msg.EncryptionKeyId}`
      );

      return;
    }

    navigate(
      `/cases/${caseId}/letter/${msg.Kind}/${msg.Id}/${msg.S3}/${msg.EncryptionKeyId}`
    );
  };

  const refWrapper = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  const { id, setId } = useContext(MenuContext);

  useEffect(() => {
    if (id !== msg.Id) {
      setShowOptions(false);
    }
  }, [id]);

  const { options: option } = useContext(settingsContext);

  const h = option.find((f) => f.Type === IOptionType.hoverMenu)?.Value;

  const useHoverOver = false;

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [showAfter, setShowAfter] = useState(false);

  const c = showAfter;

  let isOpen = showOptions && showAfter;

  if (!useHoverOver) {
    isOpen = showOptions;
  }

  const isSelected = !!selectedItems?.find((i) => i.Id === msg.Id);
  return (
    <div
      onClick={handleClick}
      onMouseEnter={() => {
        if (useHoverOver) {
          setShowOptions(true);

          const t = setTimeout(() => {
            setShowAfter(true);
          }, 500);
          setTimer(t);
        }
      }}
      onMouseLeave={() => {
        if (useHoverOver) {
          setShowOptions(false);
          setShowAfter(false);
          if (timer) {
            clearTimeout(timer);
            setTimer(null);
          }
        }
      }}
      className={`${styles.card} ${isSelected && styles.active}`}
    >
      {refWrapper.current && isOpen && (
        <LetterOptionsCard
          isCase
          binds
          selectedElements={[]}
          showOptions={showOptions}
          pack={msg}
          refetch={refetch}
          refWrapper={refWrapper}
          onClickAttachIcon={() => {}}
        />
      )}

      {/* <div className={styles.colorBar}></div> */}
      <div className={styles.header}>
        <div className={styles.sender}>{msg.S1}</div>
        <div className={styles.date}>
          {moment(msg.CreationDate).format('DD/MM/YY').toString()}
          <div
            onClick={() => {
              setShowOptions(!showOptions);
              setId(msg.Id);
            }}
            className={styles.hover}
          >
            <FontAwesomeIcon
              onClick={() => setShowOptions(!showOptions)}
              ref={refWrapper}
              icon={faEllipsisVertical}
            />
          </div>
        </div>
      </div>
      <div className={styles.titleWrapper}>
        {/* <div className={styles.title}>{msg.S1}</div> */}
        <div className={styles.wrp}>
          <div className={styles.wrpp}>
            <div className={styles.tags}>
              {findedTags.map((tag) => (
                <TagBadge onRemove={(tag) => handleRemoveTag(tag)} tag={tag} />
              ))}
            </div>
          </div>
          <div>
            <IconsState
              withoutCase
              keys={[]}
              msg={msg}
              onPreview={() => {}}
              onDownload={() => {}}
            />
          </div>

          {/* {msg.IsDeskActive && <DeskIcon className={styles.icon} />}
          <CaseIcon className={styles.icon} />
          <AttachIcon className={styles.icon} /> */}
        </div>
      </div>
      {/* <div className={styles.lead}>{msg.S2}</div> */}

      <div className={styles.badgesWrapper}>
        {/* {msg.S4 && (
          <div className={styles.badge}>
            <TagBadge
              onRemove={() => {}}
              tag={{
                Id: '',
                Flags: 2,
                Global: true,
                Name: 'Podpisany',
                Type: 3,
              }}
            />
          </div>
        )} */}
        {status && (
          <div className={styles.badge}>
            <StatusBadge onRemove={() => {}} status={status} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LetterCard;
