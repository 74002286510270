import { axiosInstance } from 'api/axios';
import casesService from 'api/casesService';
import fileService, { ICaseGetFile } from 'api/fileService';
import pluginService from 'api/pluginService';
import PDFViewer from 'components/PDFViever';
import KeysContext from 'contexts/KeysContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { blobToBase64 } from 'utils/blobToBase64';
import { getBlob } from 'utils/getBlob';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import styles from './styles.module.scss';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { decryptFile } from 'utils/decryptFile';

const letterModule = () => {
  // path="/letter-preview/:caseId/:kind/:id/:letterId/:encryptionKey"
  // element={
  //   <LetterModule />
  // }

  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const { caseId, kind, id, letterId, encryptionKey } = useParams();

  const { data: caseData, isSuccess: caseDataSuccess } =
    casesService.useGetCase(caseId!);

  const Case = useMemo(() => caseData ?? [], [caseData]);

  const { keys, setKeys } = useContext(KeysContext);

  const { mutate: getFile } = fileService.useGetFiltersForCases();

  const [blob, setBlob] = useState<Blob | null>(null);

  const { mutate: tryPreview } = pluginService.usePreview();

  const get = async (): Promise<ICaseGetFile | null> => {
    return new Promise((resolve) => {
      getFile(
        {
          id: letterId!,
          ownerKind: Number(kind!),
        },
        {
          onSuccess: (response) => resolve(response.data),
          onError: () => resolve(null),
        }
      );
    });
  };

  const initializePreview = async () => {
    //@ts-ignore
    if (!Case?.Keys?.[0]) return;
    const file: ICaseGetFile | null = await get();

    if (!file) return;

    const keyR = await axiosInstance.get(`/keys/object?id=${encryptionKey}`);
    const key = keyR.data;
    if (!key) {
      return;
    }

    const blob = await getBlob(file.Url);
    let b: string | null = null;

    try {
      const decryptedBlob = await decryptFile(
        userId,
        blob.data,
        key,
        //@ts-ignore
        Case.Keys[0]
      );

      b = (await blobToBase64(decryptedBlob)) as string;
    } catch (e) {
      setBlob(blob.data);
      return;
    }

    if (!b) {
      return;
    }

    tryPreview(
      {
        AttachmentId: letterId!,
        Content: b.split(',')[1],
        ContentType: 'application/other',
        DocumentId: letterId!,
        FileName: '',
        Kind: Number(kind!),
      },
      {
        onSuccess: async (response) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setBlob(new Blob([buffer]));
        },
      }
    );
  };

  useEffect(() => {
    if (!Case) return;
    //@ts-ignore
    setKeys([...keys, Case.ObjectKeys, Case.Keys].flat());
  }, [Case]);

  useEffect(() => {
    if (!Case) return;

    initializePreview();
  }, [caseId, letterId, Case, keys]);

  return (
    <div className={styles.wrapper}>
      {blob && <PDFViewer title="Pismo" showHeader file={blob} />}
    </div>
  );
};

export default letterModule;
