import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Loading from 'components/Loading';
import useGetCases from 'hooks/useGetCases';
import CaseCard from 'components/CaseCard';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';

import { config } from 'config';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ClientIcon } from 'assets/icons/client_hover.svg';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';
import statusService from 'api/statusService';
import CasesContext from 'contexts/CasesContext';
import KeysContext from 'contexts/KeysContext';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance } from 'api/axios';
import { IPackage } from 'dto/IPackage';
import { DivIcon } from 'leaflet';
import LettersContext from 'contexts/LettersContext';
import listService from 'api/listService';
import LetterCardDesk from 'components/LetterCardDesk';

const DeskLetters: FC<Props> = ({}) => {
  const navigate = useNavigate();
  const { filterId } = useParams();
  const option: any = localStorage.getItem(config.cases_actions);
  const [actionsView, setActionsView] = useState<
    'dayGridWeek' | 'dayGridMonth' | 'dayGridDay' | 'listWeek' | 'list'
  >(option ?? 'dayGridMonth');
  const [searchText, setSearchText] = useState('');

  const { data: tagsData } = tagsService.useGetTags();
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: statusesData } = statusService.useGetStatuses();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const { cases: casesContext, setCases: setCasesContext } =
    useContext(CasesContext);

  const { letters, setLetters } = useContext(LettersContext);
  const { keys, setKeys } = useContext(KeysContext);

  const { data: lettersData, refetch } = listService.useGetListOfMessages({
    Group: true,
    IsIncoming: false,
    Page: 0,
    SortOrder: 'Ascending',
    SortProp: 'SendDate',
    Kind: 256,
    Desk: true,
  });

  const l: IPackage[] = useMemo(() => lettersData?.Items ?? [], [lettersData]);
  const objectKeys = useMemo(
    () => lettersData?.ObjectKeys ?? [],
    [lettersData]
  );
  const Keys = useMemo(() => lettersData?.Keys ?? [], [lettersData]);

  useEffect(() => {
    setLetters(l);
    setKeys([...keys, objectKeys, Keys].flat());
  }, [l]);

  const separatorRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.wrapper}>
      <div className={styles.packageList}>
        {letters.map((letter) => (
          <LetterCardDesk
            key={letter.Id}
            selectedItems={[]}
            setSelectedItems={() => {}}
            isOutcoming={false}
            statuses={statuses}
            tags={tags}
            msg={letter}
            refetch={refetch}
          />
        ))}
        <div ref={separatorRef} style={{ height: '5px' }}></div>
      </div>
    </div>
  );
};

export default DeskLetters;
