import styles from './styles.module.scss';
import Input from 'components/Input';
import Button from 'components/Button';
import dossierService from 'api/dossierService';
import { FC, useState } from 'react';
import { Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const DossierCreateVersionModal: FC<Props> = ({ item, toggle, refetch }) => {
  const [name, setName] = useState('');

  const { mutate: createVersion } = dossierService.useCreateVersion();

  const handleClickSave = () => {
    createVersion(
      {
        Id: item.Id,
        Name: name,
      },
      {
        onSuccess: () => {
          refetch();
          toggle(false);
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Utwórz wersję
        <CloseIcon onClick={() => toggle(false)} className={styles.closeIcon} />
      </div>
      <div className={styles.content}>
        <Input
          placeholder="Nazwa wersji"
          label="Nazwa"
          value={name}
          onChange={(text) => setName(text)}
        />
      </div>
      <div className={styles.footer}>
        <Button
          disabled={!name.length}
          onClick={handleClickSave}
          text="Utwórz"
        />
      </div>
    </div>
  );
};

export default DossierCreateVersionModal;
