import { ICaseSimple } from "dto/Cases/ICaseSimple";
import { createContext } from "react";


export interface ICert {
    id: string;
    name: string;
    versionId?: string;
}

type ICertContext = {
    cert: ICert;
    setCert: (cert: ICert) => void;
}

const CertContext = createContext<ICertContext>({
    cert: {
        id: '',
        name: '',
        versionId: ''
    },
    setCert: () => {}
})

export default CertContext;