import { IDossierItem } from "dto/Dossier/IDossierItem";
import { IPackage } from "dto/IPackage";
import { createContext } from "react";

type IDossierFilesContext = {
    dossierFiles: IDossierItem[];
    setDossierFiles: (packages: IDossierItem[]) => void;
}

const DossierFilesContext = createContext<IDossierFilesContext>({
    dossierFiles: [],
    setDossierFiles: () => {}
})

export default DossierFilesContext;