import { Route, Routes } from 'react-router-dom';
import DriveModule from '.';

const DriveRoute = () => {
  return (
    <Routes>
      <Route path="/:folderId?" element={<DriveModule />} />
      <Route path="*" element={<DriveModule />} />
    </Routes>
  );
};

export default DriveRoute;
