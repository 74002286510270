import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { IAttachment } from 'dto/IAttachment';
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
import annotationService from 'api/annotationService';
import { useMemo, useState } from 'react';
import Avatar from 'components/Avatar';
import moment from 'moment';
import AttachmentIconComponent from 'components/AttachmentIconComponent';
import Comments from 'components/Comments';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

export const Comment = ({ comment }: { comment: any }) => {
  return (
    <div className={styles.comment}>
      <Avatar className={styles.avatar} name={comment.Author} />
      <div className={styles.data}>
        <div className={styles.nameDate}>
          {comment.Author}
          <div className={styles.date}>
            {moment(comment.CreationDate).format('DD.MM.YYYY')}
          </div>
        </div>
        {comment?.Comments?.[Comments.length - 1]?.Text ?? ''}
      </div>
    </div>
  );
};

const Attach = ({
  attach,
  comments,
}: {
  attach: IAttachment;
  comments: any[];
}) => {
  const ownedComments = comments?.filter(
    (comment) => comment.OwnerAttachmentId === attach.Id
  );
  const [showComments, setShowComments] = useState(false);

  if (ownedComments.length === 0) {
    return <></>;
  }

  return (
    <div
      onClick={(e) => {
        setShowComments(!showComments);
        e.stopPropagation();
      }}
      className={styles.attachment}
    >
      <AttachmentIconComponent file={attach.FileName} />
      <FontAwesomeIcon
        className={styles.icone}
        icon={showComments ? faCaretUp : faCaretDown}
      />
      <div className={styles.info}>
        <div className={styles.name}>{attach.FileName}</div>
        <div className={styles.comments}>
          {ownedComments?.length ?? 0} komentarze
        </div>
      </div>
      <div className={styles.commentsList}>
        {showComments &&
          ownedComments?.map((comment) => (
            <Comment key={comment.Id} comment={comment} />
          ))}
      </div>
    </div>
  );
};

const CommentsModal: FC<Props> = ({ pack, toggle }) => {
  const { data } = annotationService.useGet(`${pack.Id}:${pack.Kind}`);

  const comments = useMemo(() => data ?? [], [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          Komentarze
          <CloseIcon
            className={styles.closeIcon}
            onClick={(e) => {
              toggle(false);
              e.stopPropagation();
            }}
          />
        </div>

        <div className={styles.list}>
          {pack.Attachments.map((attach) => (
            <Attach key={attach.Id} attach={attach} comments={comments} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommentsModal;
