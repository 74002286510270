import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import casesService from 'api/casesService';
import { useEffect, useMemo, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import Chip from 'components/Chip';
import { Avatar } from '@mui/material';
import { convertToLetters } from 'utils/convertToLetters';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';

const PackageCaseModal: FC<Props> = ({ toggle, CaseId, Package }) => {
  const { data: tagsData } = tagsService.useGetTags();
  const allTags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);
  const { data: CaseData } = casesService.useGetCase(CaseId ?? '');
  const Case = useMemo(() => CaseData, [CaseData]);

  const [caseData, setCaseData] = useState({ title: '', signature: '' });

  useEffect(() => {
    const t = allTags?.filter((tag: ITag) =>
      Case?.TagIds?.find((iTag) => tag.Id === iTag)
    );
    setFindedTags(t);
  }, [allTags, Case]);

  useEffect(() => {
    if (Package) {
      setCaseData({
        title: Package.Case.Title,
        signature: Package.Case.Signature,
      });
    }
  }, []);

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/cases/${Case?.Id}/chat`);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          Teczka
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => toggle(false)}
          />
        </div>
        <div className={styles.information}>
          <Input value={caseData.title} label="Tytuł" onChange={() => {}} />
          <Input
            value={caseData.signature}
            label="Sygnatura"
            onChange={() => {}}
          />
        </div>

        <div className={styles.chips}>
          {CaseData?.Participants.map((p) => (
            <Chip
              avatar={
                <Avatar className={styles.avatar}>
                  {convertToLetters(p.Label ?? '')}
                </Avatar>
              }
              element={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  {p.Label}
                </div>
              }
            />
          ))}
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            onClick={handleOnClick}
            text="Przejdź do teczki"
          />
        </div>
      </div>
    </div>
  );
};

export default PackageCaseModal;
