import PanelsLayout from 'components/PanelsLayout';
import moment from 'moment';
import taskService from 'api/taskService';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import ActionsCalendar from 'components/ActionsCalendar';
import { ReactComponent as DossierIcon } from 'assets/icons/dossier.svg';
import { ReactComponent as DayIcon } from 'assets/icons/dayIcon.svg';
import { ReactComponent as WeekIcon } from 'assets/icons/WeekIcon.svg';
import { ReactComponent as MonthIcon } from 'assets/icons/monthIcon.svg';
import { ReactComponent as ListIcon } from 'assets/icons/listIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import ActionsList from 'components/ActionsList';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_ios.svg';
import './calendar.scss';
import MonthList from 'components/MonthList';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { Badge } from '@mui/material';
import SearchBar from 'components/SearchBar';
import { ex } from '@fullcalendar/core/internal-common';
import Tasks from 'components/Tasks';
registerLocale('pl', pl);

export function ServerDay(
  props: PickersDayProps<any> & { highlightedDays?: number[] }
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  const dat = highlightedDays.map((d) =>
    Number(moment(d).format('DD').toString())
  );

  const isSelected =
    !props.outsideCurrentMonth && dat.includes(props.day.date());

  const actualDay = moment(new Date()).format('DD');
  let calendarData = props.day.date();
  if (calendarData <= 9) {
    calendarData = `0${calendarData}`;
  }

  const selectedStyle = isSelected ? styles.c : '';
  const isActualDate =
    actualDay === calendarData && !props.selected ? styles.isActualDate : '';
  const selectedStyle2 = props.selected ? styles.selectedDate : '';

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      className={`${selectedStyle} ${isActualDate} ${selectedStyle2}`}
    />
  );
}

export interface ISearch {
  text: string;
  statusId: string | null;
  tagId: string | null;
  entityId: string | null;
  kind: number | null;
}

const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

export const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
}: {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  changeYear: any;
}) => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.monthWrapper}>
        <ArrowIcon onClick={decreaseMonth} className={styles.icon} />
        {months[moment(date).month()]}
        <ArrowIcon
          onClick={increaseMonth}
          className={`${styles.icon} ${styles.reverse}`}
        />
      </div>
      <div className={styles.yearWrapper}>
        {' '}
        <ArrowIcon
          onClick={() => {
            changeYear(moment(date).subtract(1, 'year').format('YYYY'));
          }}
          className={styles.icon}
        />
        {moment(date).year()}{' '}
        <ArrowIcon
          onClick={() => {
            changeYear(moment(date).add(1, 'year').format('YYYY'));
          }}
          className={`${styles.icon} ${styles.reverse}`}
        />
      </div>
    </div>
  );
};

const ActionsModule = () => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const [actualMonthView, setActualMonthView] = useState(
    moment(new Date()).month()
  );

  const [showedMonth, setShowedMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarDate, setCalendarDate] = useState(new Date());
  const { calendarView } = useParams();
  const navigate = useNavigate();

  const { data: busyData } = taskService.useGetBusy(
    moment(calendarDate).startOf('month').subtract(7, 'd').toISOString(),
    moment(calendarDate).endOf('month').add(7, 'd').toISOString()
  );

  const busyDates = useMemo(
    () => busyData?.data.map((date: string) => moment(date).toDate()) ?? [],
    [busyData]
  );

  const handleClickNavigation = (type: string) => {
    localStorage.setItem(`${userId}.calendar_view`, type);
    navigate(`/actions/${type}`);
  };

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    const view = localStorage.getItem(`${userId}.calendar_view`);
    if (view) {
      handleClickNavigation(view);
    } else {
      handleClickNavigation('day');
    }
  }, []);

  const handleChangeCalendarDate = (fromDate: Date, toDate: Date) => {
    if (calendarView !== 'month') {
      setCalendarDate(moment(fromDate).startOf('week').toDate());
      return;
    }

    setCalendarDate(moment(fromDate).add(8, 'days').toDate());
  };

  const menu = () => {
    return (
      <div className={styles.menu}>
        <div
          onClick={() => handleClickNavigation('day')}
          className={`${styles.box} ${
            calendarView === 'day' && styles.activeText
          }`}
        >
          <DayIcon />
          Dzień
          {calendarView === 'day' && <div className={styles.active}></div>}
        </div>
        <div className={styles.splitter}></div>
        <div
          onClick={() => handleClickNavigation('week')}
          className={`${styles.box} ${
            calendarView === 'week' && styles.activeText
          }`}
        >
          <WeekIcon />
          Tydzień
          {calendarView === 'week' && <div className={styles.active}></div>}
        </div>
        <div className={styles.splitter}></div>
        <div
          onClick={() => handleClickNavigation('month')}
          className={`${styles.box} ${
            calendarView === 'month' && styles.activeText
          }`}
        >
          <MonthIcon />
          Miesiąc
          {calendarView === 'month' && <div className={styles.active}></div>}
        </div>
        <div className={styles.splitter}></div>
        <div
          onClick={() => handleClickNavigation('list')}
          className={`${styles.box} ${styles.outer} ${
            calendarView === 'list' && styles.activeText
          }`}
        >
          <ListIcon />
          Lista
          {calendarView === 'list' && <div className={styles.active}></div>}
        </div>
        <div className={styles.splitter}></div>
        <div
          onClick={() => handleClickNavigation('tasks')}
          className={`${styles.box} ${styles.outer} ${
            calendarView === 'tasks' && styles.activeText
          }`}
        >
          <ListIcon />
          Zadania
          {calendarView === 'tasks' && <div className={styles.active}></div>}
        </div>
      </div>
    );
  };

  const [search, setSearch] = useState<ISearch>({
    text: '',
    statusId: null,
    tagId: null,
    entityId: null,
    kind: null,
  });

  const handleChangeSearchBar = (
    text: string,
    statusId: string | null,
    tagId: string | null,
    entityId: string | null,
    kind: number | null
  ) => {
    setSearch({
      text,
      statusId,
      tagId,
      entityId,
      kind,
    });
  };

  const leftPanel = () => {
    return (
      <div className={styles.leftWrapper}>
        {(calendarView === 'day' ||
          calendarView === 'week' ||
          calendarView === 'month') && (
          <ActionsCalendar
            search={search}
            handleChangeDate={handleChangeCalendarDate}
            date={selectedDate}
          />
        )}
        {calendarView === 'list' && (
          <ActionsList search={search} date={selectedDate} />
        )}
        {calendarView === 'tasks' && <Tasks />}
      </div>
    );
  };

  const rightPanel = () => {
    {
      calendarView === 'tasks' && <></>;
    }
    // if (calendarView === 'list') return <></>;

    if (calendarView === 'month') {
      return (
        <div className={styles.rightWrapper}>
          <MonthList date={calendarDate} handleChangeDate={handleChangeDate} />
        </div>
      );
    }

    return (
      <div className={styles.rightWrapper}>
        <DateCalendar
          defaultValue={moment(calendarDate).utc()}
          onMonthChange={(date) => {
            setActualMonthView(moment(date).month());
            setCalendarDate(moment(date).toDate() ?? new Date());
          }}
          onChange={(newValue) => {
            setCalendarDate(moment(newValue).toDate() ?? new Date());
            setSelectedDate(moment(newValue).toDate() ?? new Date());
          }}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays: busyDates.filter(
                (f: Date) => moment(f).month() === actualMonthView
              ),
            } as any,
          }}
        />
        {/* <DatePicker
          selected={calendarDate}
          className={styles.wrapperInput}
          popperClassName={styles.input}
          highlightDates={busyDates}
          wrapperClassName={styles.wrapperInput}
          showTimeSelect={false}
          customInput={<></>}
          renderCustomHeader={CustomHeader}
          calendarClassName={styles.calendar}
          open={true}
          dateFormat={'dd.MM.yyyy'}
          onMonthChange={(date) => setShowedMonth(date ?? new Date())}
          onChange={(date) => {
            setCalendarDate(date ?? new Date());
            setSelectedDate(date ?? new Date());
          }}
          locale={'pl'}
        /> */}
      </div>
    );
  };

  return (
    // <PanelsLayout
    //   collapseRight={calendarView === 'list' ? true : false}
    //   disablePadding
    //   leftPanel={leftPanel()}
    //   rightPanel={rightPanel()}
    // />

    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>Czynności</div>
          <div className={styles.subTitle}></div>
        </div>
        <div className={styles.right}>
          <SearchBar
            onChange={(t, statusId, tagId, entityId, kind) =>
              handleChangeSearchBar?.(t, statusId, tagId, entityId, kind)
            }
          />
        </div>
      </div>
      {menu()}
      <div className={styles.list}>
        <div className={`${styles.leftY}`}>{leftPanel()}</div>
        {/* {calendarView !== 'list' && ( */}
        <div className={styles.rightY}>{rightPanel()}</div>
        {/* )} */}
      </div>
    </div>
  );
};

export default ActionsModule;
