import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as DeleteIcon } from 'assets/icons/blue_delete.svg';
const GridWindow: FC<Props> = ({
  id,
  title,
  isEditMode = false,
  handleRemove,
  children,
}) => {
  return (
    <div className={styles.window}>
      {/* {isEditMode && (
        <div
          onClick={() => {
            handleRemove(id);
          }}
          className={styles.deleteIcon}
        >
          <DeleteIcon />
        </div>
      )} */}
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default GridWindow;
