import AuthLayout from 'components/AuthLayout';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import Input from 'components/Input';
import { ReactComponent as InfoIcon } from 'assets/icons/error.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowBack.svg';
import Button from 'components/Button';
import workspaceService from 'api/workspaceService';
import { ESteps } from 'components/Stepper';
import { Context, Hub } from 'react-signalr/src/signalr/types';
import OtpInput from 'react-otp-input';
import { User, UserManager } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import Checkbox from 'components/Checkbox';

const steps = [
  {
    type: ESteps.INFORMATION,
    title: 'Twoje dane',
    text: 'Wpisz email i hasło bądź wybierz metodę logowania',
  },
  {
    type: ESteps.EMAIL_VERIFICATION,
    title: 'Weryfikacja adresu email',
    text: 'Wpisz kod weryfikujący',
  },
  {
    type: ESteps.KEY_GENERATION,
    title: 'Klucz przywracania',
    text: 'Generuj Swój klucz przywracania',
  },
];

const RegisterModule = ({
  SignalRContext,
}: {
  SignalRContext: Context<Hub<string, string>>;
}) => {
  const { linkToken, linkCode } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [input, setInput] = useState(false);
  const [otp, setOtp] = useState('');
  const [token, setToken] = useState('');
  const [step, setStep] = useState(0);
  const [approveRules, setApproveRules] = useState(false);
  const { mutate: register } = workspaceService.useRegisterInWorkspace();
  const { mutate: verify } = workspaceService.useVerifyEmail();

  SignalRContext.useSignalREffect('verified', async (data) => {}, []);

  const handleCreate = () => {
    register(
      {
        AcceptNotaRules: approveRules,
        AcceptRules: approveRules,
        Email: email,
        FirstName: name,
        LastName: lastName,
        Password: password,
        RepeatPassword: rePassword,
        ConnectionId: SignalRContext.connection?.connectionId ?? '',
      },
      {
        onSuccess: (resp) => {
          if (resp.data.VerifyEmail) {
            setToken(resp.data.EmailToken);
            setStep(1);
          }
        },
      }
    );
  };
  const auth = useAuth();
  const userManager = new UserManager(auth.settings);
  const setUser = (accessToken: string, refreshToken: string) => {
    userManager.storeUser(
      new User({
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: 'Bearer',
        profile: { sub: '', iss: '', aud: '', exp: 0, iat: 0 },
      })
    );
  };

  const handleCode = () => {
    verify(
      {
        Token: linkToken ?? token,
        Code: linkCode ?? otp,
      },
      {
        onSuccess: (resp) => {
          setUser(resp.data.AccessToken, resp.data.RefreshToken);
          auth.signinSilent();
          navigate('/activation');
        },
      }
    );
  };

  useEffect(() => {
    if (!linkCode || !linkToken) return;
    handleCode();
  }, [linkCode]);

  const checkCan = () => {
    if (
      !password.length ||
      !rePassword.length ||
      !name.length ||
      !lastName.length
    ) {
      return true;
    }

    if (password !== rePassword) {
      return true;
    }

    if (!approveRules) {
      return true;
    }

    return false;
  };

  // return (
  //   <AuthLayout actualStep={step} steps={[]}>
  //     <div className={styles.text}>
  //       Przepraszamy rejestracja aktualnie jest wyłączona
  //     </div>
  //   </AuthLayout>
  // );

  return (
    <AuthLayout actualStep={step} steps={steps}>
      <>
        {step === 0 && (
          <>
            <div className={styles.title}>Utwórz konto</div>
            <div className={styles.side}>I zacznij korzystać z Notaup </div>
            <div className={styles.inputs}>
              <Input
                className={styles.input}
                label="E-Mail"
                disabledTags
                value={email}
                onChange={(v) => {
                  setEmail(v);
                }}
              />
              <div className={styles.box}>
                <Input
                  className={styles.input}
                  label="Hasło"
                  type="password"
                  disabledTags
                  value={password}
                  onChange={(v) => setPassword(v)}
                />
                {input && (
                  <InfoIcon
                    data-tooltip-id={`bar-1`}
                    data-tooltip-content={'Podane hasła nie są takie same'}
                    className={styles.error}
                  />
                )}
              </div>
              <div className={styles.box}>
                <Input
                  className={styles.input}
                  label="Powtórz hasło"
                  disabledTags
                  type="password"
                  value={rePassword}
                  onChange={(v) => setRePassword(v)}
                />
                {input && (
                  <InfoIcon
                    data-tooltip-id={`bar-1`}
                    data-tooltip-content={'Podane hasła nie są takie same'}
                    className={styles.error}
                  />
                )}
              </div>
              <Input
                className={styles.input}
                disabledTags
                label="Imię"
                value={name}
                onChange={(v) => setName(v)}
              />
              <Input
                className={styles.input}
                label="Nazwisko"
                disabledTags
                value={lastName}
                onChange={(v) => setLastName(v)}
              />

              <div className={styles.checkbox}>
                <div className={styles.checkbox1}>
                  <Checkbox
                    value={approveRules}
                    onChange={(s) => setApproveRules(s)}
                  />
                </div>
                <div>
                  Akceptuję zasady wyrażone w{' '}
                  <a
                    href="https://doreczeniaelektroniczne.pl/documents/Regulamin-swiadczenia.pdf"
                    target="_blank"
                  >
                    Regulaminie
                  </a>
                  ,{' '}
                  <a
                    href="https://doreczeniaelektroniczne.pl/documents/Polityka-prywatnossci.pdf"
                    target="_blank"
                  >
                    Polityce Prywatności{' '}
                  </a>{' '}
                  doreczeniaelektroniczne.pl oraz zawieram
                  <a
                    href="https://doreczeniaelektroniczne.pl/documents/Umowa-powierzenia.pdf"
                    target="_blank"
                  >
                    {' '}
                    Umowę powierzenia przetwarzania danych
                  </a>{' '}
                  doreczeniaelektroniczne.pl.
                </div>
              </div>
            </div>
            <div className={styles.button}>
              <Button
                className={styles.b}
                text="Dalej"
                disabled={checkCan()}
                onClick={() => handleCreate()}
              />
            </div>
            <div className={styles.hint}>
              Masz już konto? <a href="/login">Zaloguj się</a>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div className={styles.title}>Potwierdź email</div>
            <div className={styles.side}>
              Wpisz poniżej kod wysłany na adres email lub kliknij link w
              wiadomości email
            </div>

            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={styles.otp}
              containerStyle={styles.cont}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
            />

            <Button
              className={styles.button}
              onClick={handleCode}
              text="Dalej"
            />
          </>
        )}
      </>
    </AuthLayout>
  );
};

export default RegisterModule;
