import WorkspaceRoleCard from 'components/WorkspaceRoleCard';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import AddButton from 'components/AddButton';
import { useState } from 'react';
import { IWorkspaceRole } from 'dto/IWorkspace';
import { WorkspaceCreateRole } from 'components/WorkspaceCreateRole';
import { IPrivilages } from 'utils/getPrivilages';

const WorkspaceRoles: FC<Props> = ({ workspace, refetch, roles }) => {
  const [showCreate, setShowCreate] = useState(false);
  const [edit, setEdit] = useState<null | IWorkspaceRole>(null);

  const handleCreateNew = () => {
    setShowCreate(true);
    setEdit(null);
  };

  if (showCreate) {
    return (
      <WorkspaceCreateRole
        onCancel={() => {
          setEdit(null);
          setShowCreate(false);
        }}
        onCreate={() => {
          setEdit(null);
          setShowCreate(false);
          refetch();
        }}
        workspaceId={workspace.Id}
        initialValue={edit}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.addButton}>
        <AddButton
          className={styles.add}
          text="Dodaj nową rolę"
          onClick={() => handleCreateNew()}
        />
      </div>
      {roles.map((role) => (
        <WorkspaceRoleCard
          refetch={refetch}
          onEdit={() => {
            setEdit(role);
            setShowCreate(true);
          }}
          role={role}
        />
      ))}
    </div>
  );
};

export default WorkspaceRoles;
