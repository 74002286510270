import { IPackage } from "dto/IPackage";
import { createContext } from "react";

type ILettersContext = {
    letters: IPackage[];
    setLetters: (letters: IPackage[]) => void;
}

const LettersContext = createContext<ILettersContext>({
    letters: [],
    setLetters: () => {}
})

export default LettersContext;