import pluginService from 'api/pluginService';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { blobToBase64 } from 'utils/blobToBase64';
import { uploadBlob } from 'utils/uploadBlob';
import { IUploadedFile } from 'components/CreateOutgoingParcelModal/typings';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import editorService from 'api/editorService';
import { useContext, useMemo, useState } from 'react';
import CertContext from 'contexts/CertContext';
import signatureService from 'api/signatureService';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button';
import ReactDOM from 'react-dom';
import moment from 'moment';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ItemKind } from 'dto/IKindItems';
import dossierService from 'api/dossierService';
import SecondCheckbox from 'components/SecondCheckbox';

const ChooseCert = ({
  onClick,
  toggle,
  certs,
}: {
  toggle: () => void;
  onClick: (cert: any) => void;
  certs: any;
}) => {
  const [IsXades, setIsXades] = useState(false);
  return ReactDOM.createPortal(
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <div className={styles.Modaltitle}>
          Wybierz certyfikat
          <CloseIcon onClick={toggle} />
        </div>
        <div className={styles.choose}>
          <SecondCheckbox
            value={!IsXades}
            onChange={() => setIsXades(false)}
            text="Pades"
          />
          <SecondCheckbox
            value={IsXades}
            onChange={() => setIsXades(true)}
            text="Xades"
          />
        </div>
        <div className={styles.list}>
          {certs.map((cert: any) => (
            <div onClick={() => onClick(cert)} className={styles.box}>
              <div className={styles.title}>
                <div className={styles.name}>{cert.Name}</div>
                <div className={styles.date}>
                  {moment(cert.ValidTo).format('DD.MM.YYYY').toString()}
                </div>
              </div>
              <div className={styles.cert}>{cert.IssuerBy}</div>
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

const CertificateApi: FC<Props> = ({ pack, file, originalFile }) => {
  const {
    caseId,
    kind,
    messageId,
    encryptionKey,
    filterId,
    status: stat,
    packageId,
  } = useParams();

  const navigate = useNavigate();

  const { data: certsData } = pluginService.useGetCerificates();
  const { mutate: signDocument, isLoading: isSignLoading } =
    pluginService.useSignDocument();
  const { mutate: editorSign } = editorService.useSign();
  const { mutate: dossierSign } = dossierService.useSign();
  const { mutate: verify } = pluginService.useVerifySignature();
  const { mutate: uploadCert } = signatureService.useUploadSignature();

  const { cert: certContext, setCert } = useContext(CertContext);

  const certs = useMemo(() => certsData?.data ?? [], [certsData]);

  const [showModal, setShowModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const isPisma = window.location.href.search('pisma') !== -1;

  const verifyCert = (
    content: string,
    contentType: string,
    fileName: string,
    attachmentId: string,
    xades?: string
  ) => {
    verify(
      {
        FileName: fileName,
        Content: content,
        ContentType: contentType,
        Xades: xades,
      },
      {
        onError: () => {
          setIsLoading(false);
        },
        onSuccess: (data: any) => {
          uploadCert(
            {
              Json: JSON.stringify(data.data),
              OwnerAttachmentId: attachmentId,
              OwnerId: certContext.id,
              OwnerKind: Number(kind),
            },
            {
              onSuccess: () => {
                setCert({
                  id: '',
                  name: '',
                });
                setIsLoading(false);
                if (isPisma) {
                  navigate(
                    `/packages/${filterId}/${stat}/${kind}/${packageId}/preview-letter/${packageId}/${attachmentId}/signed`
                  );
                  return;
                }

                navigate(
                  `/cases/${caseId}/dossier/${kind}/${messageId}/${attachmentId}/${encryptionKey}`
                );
              },
            }
          );
        },
      }
    );
  };

  const handleSign = async (cert: any) => {
    setShowModal(false);
    setIsLoading(true);
    const b: string =
      pack.Kind === ItemKind.EditorDocument || pack.Kind === ItemKind.CaseFile
        ? ((await blobToBase64(file as Blob)) as string)
        : ((await blobToBase64(originalFile as Blob)) as string);

    signDocument(
      {
        CertificateSerialNumber: cert.SerialNumber,
        Content: b.split(',')[1],
        ContentType:
          pack.Kind === ItemKind.EditorDocument ||
          pack.Kind === ItemKind.CaseFile
            ? 'application/pdf'
            : pack.S2,
        FileName:
          pack.Kind === ItemKind.EditorDocument ||
          pack.Kind === ItemKind.CaseFile
            ? `${certContext.name}.pdf`
            : pack.S1,
        GenerateReport: true,
      },
      {
        onError: () => {
          setIsLoading(false);
        },
        onSuccess: async (data: any) => {
          const fileName = data.data.FileName;
          const fileType = data.data.ContentType;
          const fileContent = data.data.Content;
          const xades = data.data.IsXades;
          const report = data.data.Report;

          const buffer = await readFileFromBase64(
            fileContent,
            xades ? 'text/xml' : 'application/pdf'
          );
          const blob = new Blob([buffer]);
          const file: IUploadedFile = {
            file: blob,
            fileType: fileType,
            name: fileName,
            size: blob.size,
          };

          const fileUploaded = await uploadBlob(
            [file],
            '',
            '',
            false,
            false,
            true
          );

          if (!fileUploaded) {
            return;
          }
          if (pack.Kind === ItemKind.EditorDocument) {
            editorSign(
              {
                Id: certContext.id,
                Uri: fileUploaded[0].uri,
                Report: report ? JSON.stringify(report) : undefined,
                Countersign: !!pack.S4,
              },
              {
                onSuccess: () => {
                  if (!report) {
                    verifyCert(
                      fileContent,
                      'application/pdf',
                      pack.S2,
                      fileUploaded[0].uri
                    );
                  } else {
                    navigate(
                      `/packages/${filterId}/${stat}/${kind}/${packageId}/preview-letter/${packageId}/${fileUploaded[0].uri}/signed`
                    );
                  }
                },
                onError: () => {
                  setIsLoading(false);
                },
              }
            );
          } else if (pack.Kind === ItemKind.CaseFile) {
            dossierSign(
              {
                Id: certContext.id,
                Uri: xades ? null : fileUploaded[0].uri,
                XadesUri: xades ? fileUploaded[0].uri : null,
                Report: report ? JSON.stringify(report) : undefined,
                Countersign: !!pack.S4,
              },
              {
                onSuccess: () => {
                  setIsLoading(false);

                  if (!report) {
                    verifyCert(
                      b.split(',')[1],
                      pack.S2,
                      pack.S1,
                      pack.Id,
                      fileContent
                    );
                  } else {
                    navigate(
                      `/cases/${caseId}/dossier/${kind}/${messageId}/${pack.Id}/${encryptionKey}`
                    );
                  }
                },
                onError: () => {
                  setIsLoading(false);
                },
              }
            );
          }
        },
      }
    );
  };

  // return (
  //   <>
  //     {showModal && <ChooseCert onClick={handleSign} certs={certs} />}
  //     <div className={styles.wrapper}>
  //       <div className={styles.wrap}>
  //         Zweryfikuj treść pisma, a następnie{' '}
  //         <Button
  //           loading={isSignLoading}
  //           className={styles.button}
  //           text="Podpisz elektronicznie"
  //           onClick={() => setShowModal(true)}
  //         />
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        Zweryfikuj treść pisma a następnie podpisz
      </div>
      <div className={styles.right}>
        <div
          onClick={() => {
            setCert({
              id: '',
              name: '',
            });
          }}
          className={styles.cta}
        >
          Anuluj
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            onClick={() => setShowModal(true)}
            text="Podpisz elektronicznie"
            loading={isLoading}
          />
          {showModal && (
            <ChooseCert
              toggle={() => setShowModal(false)}
              onClick={handleSign}
              certs={certs}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificateApi;
