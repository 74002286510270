import listService from 'api/listService';
import { FC, Props } from './typings';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useEffect, useMemo, useState, useContext, useRef } from 'react';
import { IPackage } from 'dto/IPackage';
import ChatCard from 'components/ChatCard';
import { ReactComponent as EditIcon } from 'assets/icons/tag.svg';
import threadsService from 'api/threadsService';
import Loading from 'components/Loading';
import { IAttachment } from 'dto/IAttachment';
import CaseChatContext from 'contexts/CaseChatContext';
import PackagesContext from 'contexts/PackagesContext';
import KeysContext from 'contexts/KeysContext';
import downloadFile from 'utils/downloadFile';
import fileService from 'api/fileService';
import { fetchEmailAttachment } from 'utils/fetchEmailAttachment';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import ConverstationContext from 'contexts/ConversationContext';
import { IThread } from 'dto/IThread';
import {
  DragDropContext,
  DragUpdate,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import Threads from 'components/Threads';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance } from 'api/axios';
import { ECaseViews } from 'components/CaseView/dto/ECaseViews';

const CaseChat: FC<Props> = ({
  createNewThread,
  setSelectedThread,
  setCreateNewThread,
  thread,
  statuses,
  tags,
  hideMenu = false,
  setPack,
  sort,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { caseId, view } = useParams();
  const [name, setName] = useState('');

  const scrollWrapper = useRef<HTMLDivElement>(null);
  const separatorRef = useRef<HTMLDivElement>(null);

  const {
    data: packageData,
    fetchNextPage,
    isLoading: isL,
    isFetchingNextPage,
    refetch,
    status: stat,
  } = useInfiniteQuery(
    ['chat', caseId, sort, view],
    async ({ pageParam }) => {
      const query = new URLSearchParams(window.location.search);

      if (view === ECaseViews.PACKAGES) {
        query.append('Kind', '78');
      } else {
        query.append('Kind', '4831');
      }
      query.append('CaseId', caseId!.toString());
      query.append('GroupThreads', 'false');
      query.append('SortOrder', sort);
      if (pageParam) {
        query.append('PageToken', pageParam);
      }

      const res = await axiosInstance.get(`/list?${query}`);
      return res.data;
    },
    {
      // enabled: false,
      staleTime: 0,
      cacheTime: 0,
      getNextPageParam: (lastPage) => {
        if (!lastPage.LastPage) {
          return lastPage.PageToken;
        }
        return undefined;
      },
    }
  );

  // const {
  //   data: packageData,
  //   refetch,
  //   isFetching,
  // } = listService.useGetPackage({
  //   Kind: '4831',
  //   CaseId: caseId,
  //   GroupThreads: false,
  // });

  const { data: threadListData } = threadsService.useGet(caseId!);
  const { mutate: addToThread } = threadsService.useAddToThread();
  const { mutate: deleteFromThread } = threadsService.useDeleteFromThread();
  const { mutate: move } = threadsService.useMove();

  const threads: IThread[] = useMemo(
    () => threadListData?.data ?? [],
    [threadListData]
  );

  const [showModal, setShowModal] = useState(false);
  const { mutate: updateThreadList, isLoading } =
    threadsService.useUpdateList();
  const { mutate: createThread } = threadsService.useCreateThread();

  const { casePackages, setCasePackages } = useContext(CaseChatContext);
  const { packages, setPackages } = useContext(PackagesContext);

  // const packs: IPackage[] = useMemo(
  //   () => packageData?.data.Items ?? [],
  //   [packageData]
  // );

  const [selectedPackages, setSelectedPackages] = useState<IPackage[]>([]);
  // const [keys, setKeys] = useState<any[]>([]);

  const { keys, setKeys } = useContext(KeysContext);

  const navigate = useNavigate();

  const { converstation, setConverstation } = useContext(ConverstationContext);

  const compileData = async () => {
    if (!packageData) return;
    const packs = await Promise.all(
      packageData?.pages
        .map((page: any) => {
          return page.Items;
        })
        .flat()

      // .sort((left: IPackage, right: IPackage) => {
      //   //@ts-ignore
      //   return right.Unread - left.Unread;
      // })
    );

    setCasePackages(packs);
    setPackages(packs);
    setConverstation(packs);

    const objectKeys: any[] = await Promise.all(
      packageData?.pages
        .map((page: any) => {
          return page.ObjectKeys;
        })
        .flat()
    );

    const keys: any[] = await Promise.all(
      packageData?.pages
        .map((page: any) => {
          return page.Keys;
        })
        .flat()
    );

    //@ts-ignore
    setKeys((prev) =>
      [...prev, objectKeys, keys].filter((f) => f !== undefined).flat()
    );
  };

  const handleScroll = (e: any) => {
    if (!separatorRef.current) return;
    if (isL || isFetchingNextPage) return;
    var rect = separatorRef.current.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    if (isVisible) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    compileData();
  }, [packageData]);

  useEffect(() => {
    if (createNewThread) {
      setEditMode(true);
      setSelectedPackages([]);

      // setConverstation([]);
    }
  }, [createNewThread]);

  useEffect(() => {
    if (!editMode) {
      setSelectedPackages([]);
      return;
    }

    if (createNewThread) return;

    setSelectedPackages(
      packages.filter((pack) => pack.Thread?.Id === thread?.Id)
    );
  }, [editMode]);

  const handleChangePackages = (pack: IPackage, state: boolean) => {
    if (state) {
      setSelectedPackages([...selectedPackages, pack]);
      return;
    }
    setSelectedPackages(
      selectedPackages.filter((packe) => packe.Id !== pack.Id)
    );
  };

  const { mutate: getFile } = fileService.useGetFiltersForCases();

  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const onDownload = async (pack: IPackage, attachment: IAttachment) => {
    //
    if (pack.Kind === 128) {
      const pdfBlobUrl = await fetchEmailAttachment(attachment.Id, pack.Id);
      const link = document.createElement('a');
      link.href = pdfBlobUrl;
      link.setAttribute('download', attachment.FileName);
      document.body.appendChild(link);
      link.click();

      return;
    }

    getFile(
      {
        id: attachment.Uri,
        ownerKind: pack.Kind,
      },
      {
        onSuccess: async (data) => {
          const key = keys.find(
            (key: any) => key?.Id === attachment?.EncryptionKeyId
          );
          await downloadFile(userId, attachment.FileName, data.data.Url, key);
        },
      }
    );
  };

  const onPreview = async (pack: IPackage, attachment: IAttachment) => {
    if (!hideMenu) {
      navigate(
        `/cases/${caseId}/chat/${pack.Kind}/${pack.Id}/${attachment.Id}`
      );
    } else {
      navigate(
        `/fullscreen/cases/${caseId}/chat/${pack.Kind}/${pack.Id}/${attachment.Id}`
      );
    }
  };

  const renderContent = () => {
    // return converstation.map((Package: IPackage) => (
    //   <ChatCard
    //     key={Package.Id}
    //     refetch={refetch}
    //     Package={Package}
    //     statuses={statuses}
    //     tags={tags}
    //     keys={keys}
    //     onDownload={onDownload}
    //     onPreview={onPreview}
    //   />
    // ));
    // if (thread && !editMode) {
    //   return converstation
    //     .filter((Package: IPackage) => Package?.Thread?.Id === thread.Id)
    //     .map((Package: IPackage) => (
    //       <ChatCard
    //         key={Package.Id}
    //         refetch={refetch}
    //         Package={Package}
    //         statuses={statuses}
    //         tags={tags}
    //         keys={keys}
    //         onDownload={onDownload}
    //         onPreview={onPreview}
    //       />
    //     ));
    // }
    // return converstation.map((Package: IPackage) => (
    //   <ChatCard
    //     key={Package.Id}
    //     editMode={editMode}
    //     handleChangePackages={handleChangePackages}
    //     isSelected={!!selectedPackages.find((pack) => pack.Id === Package.Id)}
    //     refetch={refetch}
    //     Package={Package}
    //     statuses={statuses}
    //     tags={tags}
    //     keys={keys}
    //     onDownload={onDownload}
    //     onPreview={onPreview}
    //   />
    // ));
  };

  const handleOnDrag = async (result: DropResult) => {
    const source = result.source;
    const destination = result.destination;

    // Przenoszenie z plików do folderu
    if (
      source.droppableId === 'files' &&
      destination?.droppableId.split(':')[0] === 'THREAD'
    ) {
      const destinationId = destination?.droppableId.split(':')[1];
      const thread = threads.find((t) => t.Id === destinationId);

      const copy = [...converstation];

      let card = copy
        .filter((t) => !t.Thread)
        .find((c, index) => index === source.index);
      if (!card) return;

      card!.Thread = thread!;
      setConverstation(copy);

      addToThread(
        {
          threadId: thread!.Id,
          chatId: card.Id,
          chatKind: card.Kind,
        },
        {
          onSuccess: () => {},
        }
      );
      return;
    }

    if (
      (source.droppableId === 'threads' &&
        destination?.droppableId === 'files') ||
      (source.droppableId.split(':')[0] === 'THREAD' &&
        destination?.droppableId === 'files')
    ) {
      const thread = threads[source.index];
      const copy = [...converstation];

      let card = copy.find((c, index) => c.Id === result.draggableId);

      if (!card) return;

      card!.Thread = null;
      setConverstation(copy);

      deleteFromThread(
        {
          threadId: thread!.Id,
          chatId: card.Id,
          chatKind: card.Kind,
        },
        {
          onSuccess: () => {},
        }
      );

      return;
    }

    if (
      destination?.droppableId.split(':')[0] === 'THREAD' &&
      destination?.droppableId.split(':')[0] === 'THREAD'
    ) {
      const copy = [...converstation];
      let card = copy.find((c, index) => c.Id === result!.draggableId!);

      const sourceThread = threads[source.index];
      const destinationThread = threads.find(
        (d) => d.Id === destination.droppableId.split(':')[1]
      );
      if (!card) return;

      card.Thread = destinationThread!;
      setConverstation(copy);

      move({
        ItemId: card.Id,
        Kind: card.Kind,
        SourceId: sourceThread!.Id!,
        TargetId: destinationThread!.Id!,
      });
    }
  };

  const renderCardWithoutThread = () => {
    return (
      <Droppable droppableId="files">
        {(provided, snapshot) => (
          <div className={styles.other} ref={provided.innerRef}>
            {converstation
              .filter((f, index) => !f?.Thread)
              .map((Package, index) => (
                <ChatCard
                  index={index}
                  key={Package.Id}
                  editMode={editMode}
                  handleChangePackages={handleChangePackages}
                  isSelected={
                    !!selectedPackages.find((pack) => pack.Id === Package.Id)
                  }
                  setPack={setPack}
                  refetch={refetch}
                  Package={Package}
                  statuses={statuses}
                  tags={tags}
                  keys={keys}
                  onDownload={onDownload}
                  onPreview={onPreview}
                />
              ))}
            <div ref={separatorRef} style={{ height: '5px' }}></div>
          </div>
        )}
      </Droppable>
    );
  };

  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <Loading />
      </div>
    );
  }

  return (
    <div onScroll={(e) => handleScroll?.(e)} className={styles.chat}>
      <DragDropContext onDragEnd={handleOnDrag}>
        <div>
          <Threads threads={threads} />
        </div>
        {!!threads.length && (
          <div className={styles.title}>
            <div className={styles.line}></div>
            <div className={styles.wrap}>Pozostałe wiadomości</div>
          </div>
        )}
        {renderCardWithoutThread()}
      </DragDropContext>
    </div>
  );
};

export default CaseChat;
