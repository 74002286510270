import { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { EViews } from './typings';
import ClassificationDefault from '../ClassificationDefault';
import ClassificationClients from '../ClassificationClients';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import workspaceService from 'api/workspaceService';
import { IWorkspace } from 'dto/IWorkspace';
import { getPrivilages } from 'utils/getPrivilages';

const ClassificationTab = () => {
  const { data: workspaceData, refetch } = workspaceService.useGetWorkspace(
    getWorkspaceId() ?? ''
  );

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);
  const workspaceRoles = useMemo(() => workspace?.Roles ?? [], [workspace]);

  const me = workspaceMembers.find((membership) => membership.IsMe === true);
  const meRole = workspaceRoles.find((role) => role.Id === me?.RoleId);
  const myPrivilages = getPrivilages(meRole);

  const [actualView, setActualView] = useState<EViews>(EViews.CASES);

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <div
          onClick={() => setActualView(EViews.CASES)}
          className={`${styles.box}  ${
            actualView === EViews.CASES && styles.activeText
          }`}
        >
          Teczki
        </div>
        <div
          onClick={() => setActualView(EViews.ACTIONS)}
          className={`${styles.box}  ${
            actualView === EViews.ACTIONS && styles.activeText
          }`}
        >
          Czynności
        </div>
        <div
          onClick={() => setActualView(EViews.CLIENTS)}
          className={`${styles.box}  ${
            actualView === EViews.CLIENTS && styles.activeText
          }`}
        >
          Podmioty
        </div>
        <div
          onClick={() => setActualView(EViews.LETTERS)}
          className={`${styles.box}  ${
            actualView === EViews.LETTERS && styles.activeText
          }`}
        >
          Pisma
        </div>
        <div
          onClick={() => setActualView(EViews.PACKAGES_INCOMING)}
          className={`${styles.box}  ${
            actualView === EViews.PACKAGES_INCOMING && styles.activeText
          }`}
        >
          Przesyłki przychodzące
        </div>
        <div
          onClick={() => setActualView(EViews.PACKAGES_OUTCOMING)}
          className={`${styles.box}  ${
            actualView === EViews.PACKAGES_OUTCOMING && styles.activeText
          }`}
        >
          Przesyłki wychodzące
        </div>
        <div
          onClick={() => setActualView(EViews.TEMPLATES)}
          className={`${styles.box}  ${
            actualView === EViews.TEMPLATES && styles.activeText
          }`}
        >
          Szablony
        </div>
      </div>

      {actualView !== EViews.CLIENTS && (
        <ClassificationDefault myPrivilages={myPrivilages} type={actualView} />
      )}
      {actualView === EViews.CLIENTS && (
        <ClassificationClients myPrivilages={myPrivilages} type={actualView} />
      )}
    </div>
  );
};

export default ClassificationTab;
