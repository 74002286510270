import { Route, Routes } from 'react-router-dom';
import ActivationModule from './';

const ActivationRoute = () => {
  return (
    <Routes>
      <Route path="/:step" element={<ActivationModule />} />
      <Route path="/*" element={<ActivationModule />} />
    </Routes>
  );
};

export default ActivationRoute;
