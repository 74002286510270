import tagsService from 'api/tagsService';
import TagBadge from 'components/TagBadge';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { convertTypeOfPackageToColor } from 'utils/convertTypeOfPackageToColor';
import { decryptPackage } from 'utils/decryptPackage';
import { getTypeOfParcelFromKind } from 'utils/getTypeOfParcel';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as ThreadIcon } from 'assets/icons/thread.svg';
import { ReactComponent as ThreadActiveIcon } from 'assets/icons/thread_active.svg';
import IconsState from 'components/IconsState';
import OptionsCard from 'components/OptionsCard/OptionsCard';
import PackageBar from 'components/PackageBar';
import ThreadList from 'components/ThreadList';
import PackagesContext from 'contexts/PackagesContext';
import { IParcelTypes } from 'dto/IParcelTypes';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import EmailService from 'api/emailService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sanitizeHtml from 'sanitize-html';
import {
  faEllipsis,
  faEllipsisH,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import MenuContext from 'contexts/MenuContext';
import settingsContext, { IOptionType } from 'contexts/SettingsContext';
import CertContext from 'contexts/CertContext';
import { ETypeOfOrder } from 'components/SortOrder/typings';
import { ESendStatus } from 'components/IconsState/typings';
import Chip from 'components/Chip';

const MessageCard: FC<Props> = ({
  onClick,
  msg,
  statuses,
  tags,
  keys,
  isFirst = false,
  showSomething = true,
  decodedContent,
  isThread = false,
  isSelectable = false,
  selectedElements,
  onDownload,
  setSelectedElements,
  onPreview,
  selectMultiple,
  scrollWrapper,
  isIncoming,
  showS = true,
  remove,
  listFilter,
}) => {
  useEffect(() => {
    console.log('odswiezam');
  }, []);
  const navigate = useNavigate();
  let {
    filterId,
    status: linkStatus,
    packageId,
    threadId,
    threadPackageId,
  } = useParams();
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const isOutcoming = window.location.href.search('outcoming') !== -1;

  packageId = encodeURIComponent(packageId ?? '');

  const { mutate: removeTag } = tagsService.useRemoveTagFromPackage();
  const [status, setStatus] = useState<IStatus | null>(null);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);
  const refWrapper = useRef(null);
  const [showAttach, setShowAttach] = useState(false);

  const isSelected = !!selectedElements?.find((s) => s.Id === msg.Id) ?? false;

  useEffect(() => {
    const t = tags?.filter((tag) =>
      msg?.TagIds?.find((iTag) => tag.Id === iTag)
    );

    const newStatus = statuses.find((s) => s.Id === msg.StatusId);
    setFindedTags(t);

    setStatus(newStatus ?? null);
  }, [statuses, msg.StatusId, msg.TagIds]);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        OwnerId: msg.Id,
        OwnerKind: msg.Kind,
        TagId: tag.Id,
      },
      {
        onSuccess: () => {
          setFindedTags(findedTags.filter((ftag) => ftag.Id !== tag.Id));
        },
      }
    );
  };

  const { packages, setPackages } = useContext(PackagesContext);
  const [showOptions, setShowOptions] = useState(false);
  const { id, setId } = useContext(MenuContext);

  // useEffect(() => {
  //   if (id !== msg.Id) {
  //     setShowOptions(false);
  //   }
  // }, [id]);

  const { setCert } = useContext(CertContext);

  const handleClick = (e: any) => {
    const ele = e.target.tagName.toLowerCase();
    const button = e.target.outerHTML.search('button') !== -1;

    if (ele !== 'div' || button) {
      return;
    }

    if (isSelectable && selectedElements && setSelectedElements) {
      if (selectMultiple) {
        const startPosition = !!selectedElements.length
          ? packages.indexOf(selectedElements[selectedElements.length - 1])
          : -1;

        const index = packages.indexOf(msg);

        const endPosition =
          packages.indexOf(selectedElements[selectedElements.length - 1]) ??
          packages.indexOf(msg);

        if (startPosition === -1) {
          const elements = packages.filter(
            (e, i) => i <= index && i >= startPosition
          );
          setSelectedElements(elements);
          return;
        }

        if (index < startPosition) {
          const elements = packages.filter(
            (e, i) => i >= index && i <= startPosition
          );
          setSelectedElements([elements, ...selectedElements].flat());
          return;
        }

        if (index > endPosition) {
          const elements = packages.filter(
            (e, i) => i <= index && i >= startPosition
          );
          setSelectedElements([...selectedElements, elements].flat());
        } else {
          const elements = selectedElements.filter((a, b) => b <= index);
          setSelectedElements(elements);
        }

        return;
      }

      const el = selectedElements.find((p) => p.Id === msg.Id);
      if (el) {
        setSelectedElements(selectedElements.filter((p) => p.Id !== msg.Id));
        return;
      }
      setSelectedElements([...selectedElements, msg]);
      return;
    }

    setSelectedElements?.([]);
    setCert({
      id: '',
      name: '',
    });
    const newPacks = [...packages];

    let el = newPacks.find((p) => p.Id === msg.Id);
    if (el) {
      el.Unread = false;
      setPackages(newPacks);
    }

    const link = linkStatus
      ? linkStatus
      : isIncoming === true
      ? 'incoming'
      : 'outcoming';

    const isOutcoming = link === 'outcoming' ? 'sending' : filterId;
    setSelectedElements?.([msg]);
    console.log('jestem tutaj');
    // if (msg.Kind === 256) {
    //   navigate(
    //     `/packages/${filterId}/${link}/${msg.Kind}/${msg.Id}/preview-letter/${msg.Id}`
    //   );
    //   return;
    // }

    if (msg.Thread?.Id) {
      navigate(
        `/packages/thread/${filterId}/${link}/${msg.Kind}/${msg.Thread.Id}/${msg.Id}`
      );
      return;
    }

    // navigate(`/packages/${filterId}/${link}/${msg.Kind}/${msg.Id}`);
  };

  const [content, setContent] = useState('');

  const getContent = async () => {
    if (msg.Kind === 128) {
      if (msg.S2 === null) {
        setContent('');
        return;
      }
      setContent(msg.S2);
      return;
    }

    try {
      const r = atob(msg.S2);
      const encryptionKey = keys.find(
        (key) => key?.Id === msg?.EncryptionKeyId
      );
      setContent(await decryptPackage(userId, r, encryptionKey));
    } catch (e) {
      if (msg.S2 === null) {
        setContent('');
        return;
      }
      setContent(`${msg.S2}`);
      return;
    }
  };

  const types = getTypeOfParcelFromKind(msg.Kind);
  const statusColor = convertTypeOfPackageToColor(types);

  const isPI =
    types.includes(IParcelTypes.CourtPortalDeliverance) &&
    !types.includes(IParcelTypes.Email);

  const threadCounter = msg?.Thread?.Count ?? 0;

  const isPisma = window.location.href.search('pisma') !== -1;

  const [showThreads, setShowThreads] = useState(false);

  const [isShowed, setIsShowed] = useState(true);

  const checkScrollPosition = () => {
    //@ts-ignore
    const position = refWrapper?.current?.getBoundingClientRect?.();
    if (position?.y + position?.height < 20 && isShowed) {
      setIsShowed(false);
      return;
    }

    if (!isShowed) {
      setIsShowed(true);
    }
  };

  // useEffect(() => {
  //
  //   if (!scrollWrapper.current) return;
  //   scrollWrapper.current.addEventListener('scroll', checkScrollPosition);

  //   return () => {
  //     scrollWrapper.current.removeEventListener('scroll', checkScrollPosition);
  //   };
  // }, [scrollWrapper]);

  // if (!isShowed) {
  //   return <></>;
  // }

  const { options } = useContext(settingsContext);

  const h = options.find((f) => f.Type === IOptionType.hoverMenu)?.Value;

  const useHoverOver = h === 'true';

  let title = msg.Sender;

  const isCurrentDate = moment(msg.SendDate ?? msg.CreationDate).isSame(
    new Date(),
    'day'
  );

  const isTomorrow = moment(msg.SendDate ?? msg.CreationDate).isSame(
    moment(new Date()).subtract(1, 'day').startOf('day'),
    'day'
  );

  const isTomorrow_2 = moment(msg.SendDate ?? msg.CreationDate).isSame(
    moment(new Date()).subtract(2, 'day').startOf('day'),
    'day'
  );

  if (isOutcoming) {
    title = msg?.Receivers?.filter((a) => !a.Cc)
      .map((r) => (!!r.Name.length ? r.Name : r.Email))
      .join(', ');
  }

  let time = msg.SendDate ?? msg.CreationDate;

  if (listFilter?.type === ETypeOfOrder.CREATION_DATE) {
    time = msg.CreationDate;
  }

  const isSended = msg?.Receivers?.find(
    (s) =>
      s.SendStatus === ESendStatus.PROCESSING ||
      s.SendStatus === ESendStatus.WAITING ||
      s.SendStatus === ESendStatus.ERROR
  );

  let data = '';
  if (
    (listFilter?.type === ETypeOfOrder.RECEIVED_DATE && msg.Unread) ||
    (listFilter?.type === ETypeOfOrder.SEND_DATE && isSended)
  ) {
    data = '';
  } else {
    data = moment(time).format('DD.MM.YY HH:mm').toString();

    if (isCurrentDate) {
      data = `Dzisiaj ${moment(time).format('HH:mm').toString()}`;
    }

    if (isTomorrow) {
      data = `Wczoraj ${moment(time).format('HH:mm').toString()}`;
    }

    if (isTomorrow_2) {
      data = `Przedwczoraj ${moment(time).format('HH:mm').toString()}`;
    }
  }

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [showAfter, setShowAfter] = useState(false);

  const c = showAfter || (selectedElements?.length ?? 0) > 1;

  const isSigned = msg.S4;

  return (
    <div>
      <div className={styles.threadList}>
        <div
          ref={refWrapper}
          onClick={
            onClick
              ? (e: any) => {
                  const ele = e.target.tagName.toLowerCase();
                  const button = e.target.outerHTML.search('button') !== -1;

                  if (ele !== 'div' || button) {
                    return;
                  }

                  onClick?.();
                }
              : handleClick
          }
          onMouseEnter={() => {
            if (useHoverOver) {
              setShowOptions(true);

              const t = setTimeout(() => {
                setShowAfter(true);
              }, 500);
              setTimer(t);
            }
          }}
          onMouseLeave={() => {
            if (useHoverOver) {
              setShowOptions(false);
              setShowAfter(false);
              if (timer) {
                clearTimeout(timer);
                setTimer(null);
              }
            }
          }}
          className={`${styles.card} ${
            msg.Thread && showThreads && styles.thread
          } ${isThread && styles.threadCard} ${
            threadId === msg.Id && styles.active
          } ${threadPackageId === msg.Id && styles.active} ${
            isSelected && styles.active
          } ${
            !msg.ReceiveDate &&
            isPI &&
            linkStatus === 'incoming' &&
            styles.maxHeight
          }`}
        >
          {msg.Unread && <div className={styles.colorBar}></div>}
          <div
            style={{ backgroundColor: statusColor }}
            className={styles.statusBar}
          ></div>
          <div
            className={`${styles.header} ${
              (isThread || msg.Thread) && styles.padding
            }`}
          >
            <div
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(title ?? '') }}
              className={styles.sender}
            ></div>
            <div className={styles.dateWrapper}>
              <div className={styles.date}>{data}</div>
              {msg?.Thread && !isThread && (
                <div className={styles.threade}>
                  <div className={styles.threadCounter}>
                    {threadCounter > 0 ? threadCounter : ''}
                  </div>
                  <>
                    {showThreads ? (
                      <ThreadActiveIcon
                        className={`${styles.threadIcon} ${
                          showThreads ? styles.threadsActive : ''
                        }`}
                        onClick={() => setShowThreads(!showThreads)}
                      />
                    ) : (
                      <ThreadIcon
                        className={`${styles.threadIcon} ${
                          showThreads ? styles.threadsActive : ''
                        }`}
                        onClick={() => setShowThreads(!showThreads)}
                      />
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
          <div className={styles.titleWrapper}>
            <div
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(msg.S1 ?? '') }}
              className={styles.title}
            ></div>
            <div
              className={`${styles.icons} ${
                (isThread || msg.Thread) && styles.padding
              }`}
            >
              <IconsState
                onDownload={onDownload}
                onPreview={onPreview}
                keys={keys}
                msg={msg}
              />
            </div>
          </div>
          <div className={styles.lead}>
            {/* {status && (
            <div className={styles.badgesWrapper}>
            {status && (
                <div
                  onClick={() => {
                    setShowFlags(!showFlags);
                  }}
                  ref={statusRef}
                  className={styles.badge}
                  >
                  <StatusBadge onRemove={() => {}} status={status} />
                  </div>
                  )}
                  </div>
                )} */}
            {findedTags.map((tag) => (
              // <TagBadge onRemove={(tag) => handleRemoveTag(tag)} tag={tag} />
              <Chip small element={<>{tag.Name}</>} />
            ))}
            {/* <div dangerouslySetInnerHTML={{ __html: content }}></div> */}

            {refWrapper.current && (
              <OptionsCard
                toggle={() => setShowOptions(false)}
                dropdown={
                  !!selectedElements?.length || useHoverOver ? false : true
                }
                binds
                selectedElements={selectedElements ?? []}
                setSelectedElements={setSelectedElements}
                showOptions={
                  (c && showOptions && (selectedElements?.length ?? 0) <= 1) ||
                  isFirst
                }
                pack={msg}
                refetch={() => {}}
                remove={remove}
                refWrapper={refWrapper}
                onClickAttachIcon={setShowAttach}
                scrollWrapper={scrollWrapper}
              />
            )}
          </div>

          {!msg.ReceiveDate && isPI && linkStatus === 'incoming' && (
            <div className={styles.status}>
              <PackageBar id={msg.Id} date={msg.SendDate} />
            </div>
          )}
        </div>
        {msg.Thread && !isThread && showThreads && (
          <ThreadList onPreview={onPreview} pack={msg} />
        )}
      </div>

      <div
        className={`${styles.separator} ${!!findedTags.length && styles.sep}`}
      ></div>
    </div>
  );
};

export default MessageCard;
