import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { INewTag } from './casesService';
import { ISearch } from 'modules/actionsModule';

const queryKeys = {
  getFeed: (start: string, end: string, caseId?: string, search?: ISearch) => [
    'taskService.getFeed',
    start,
    end,
    caseId,
    search,
  ],
  getBusy: (start: string, end: string) => ['taskService.getBusy', start, end],
  getTasks: (page: number) => ['taskService.getTasks', page],
  getSingleTask: (taskId: string) => ['taskService.getSingleTask', taskId],
};

const getFeed = (
  start: string,
  end: string,
  caseId?: string,
  search?: ISearch
) => {
  const query = new URLSearchParams(window.location.search);
  query.append('start', start);
  query.append('end', end);

  if (caseId) {
    query.append('caseId', caseId);
  }

  if (search) {
    if (!!search.text.length) {
      query.append('Text', search.text);
    }

    if (search.statusId) {
      query.append('StatusId', search.statusId);
    }
    if (search.tagId) {
      query.append('TagId ', search.tagId);
    }
    if (search.entityId) {
      query.append('AssignedUserId ', search.entityId);
    }
  }

  return axiosInstance.get(`/event/Feed?${query}`);
};

const useGetFeed = (
  start: string,
  end: string,
  caseId?: string,
  search?: ISearch
) => {
  return useQuery(queryKeys.getFeed(start, end, caseId, search), () =>
    getFeed(start, end, caseId, search)
  );
};

export interface IUpdateTaskPayload {
  Allday: boolean;
  FromDate: string;
  Id: null | string;
  IsFavourite: boolean;
  Note: string;
  TagIds: string[];
  Title?: string;
  NewTags?: INewTag[];
  ToDate: null | string;
  OwnerId: string;
  OwnerKind: number;
  WorkspaceId?: string;
  ForUserId?: string;
  isTask?: boolean
}

const updateTask = (payload: IUpdateTaskPayload) => {
  if(payload.isTask) {

    payload.isTask = undefined;
    return axiosInstance.post('/task', payload);
  }
  payload.isTask = undefined;
  return axiosInstance.post('/event', payload);
};

const useUpdateTask = () => {
  return useMutation(updateTask);
};

const newUpdateTask = (payload: IUpdateTaskPayload) => {
  return axiosInstance.put('/event', payload);
};

const useNewUpdateTask = () => {
  return useMutation(newUpdateTask);
};

const getTasks = (page: number) => {
  return axiosInstance.get(
    `Task/List?Page=${page}&SortProp=Deadline&SortOrder=Ascending`
  );
};

const useGetTasks = (page: number) => {
  return useQuery(queryKeys.getTasks(page), () => getTasks(page));
};

const getBusy = (start: string, end: string) => {
  return axiosInstance.get(`/event/Busy?start=${start}&end=${end}`);
};

const useGetBusy = (start: string, end: string) => {
  return useQuery(queryKeys.getBusy(start, end), () => getBusy(start, end));
};

const complete = (payload: { Id?: string; ExternalEmailId?: string }) => {
  return axiosInstance.post('/event/Completed', payload);
};

const useComplete = () => {
  return useMutation(complete);
};

const unComplete = (payload: { Id?: string; ExternalEmailId?: string }) => {
  return axiosInstance.post('/event/Uncompleted', payload);
};

const useUncomplete = () => {
  return useMutation(unComplete);
};

export interface IGetTask {
  OwnerId?: string;
  OwnerKind?: number;
}

const getTask = (payload: IGetTask) => {
  const query = new URLSearchParams(window.location.search);

  query.append('OwnerId', payload.OwnerId!);
  query.append('OwnerKind', payload.OwnerKind!.toString());

  return axiosInstance.get(`/event/list?${query}`);
};

const useGetTask = (payload: IGetTask) => {
  return useQuery(['tasks', payload], () => getTask(payload));
};

const deleteTask = (payload: { Id: string; MoveToTrash: boolean }) => {
  return axiosInstance.delete(`task`, { data: payload });
};

const useDeleteTask = () => {
  return useMutation(deleteTask);
};

export interface IMoveTask {
  AllDay: boolean;
  End: string;
  Start: string;
  Id: string;
}

const moveTask = (payload: IMoveTask) => {
  return axiosInstance.post('/event/Move', payload);
};

const useMoveTask = () => {
  return useMutation(moveTask);
};

const getSingleTask = (taskId: string) => {
  return axiosInstance.get(`/task/get?id=${taskId}`)
}

const useGetSingleTask = (taskId: string) => {
  return useQuery(queryKeys.getSingleTask(taskId), () => getSingleTask(taskId))
}

const getHistory = (taskId: string) => {
  return axiosInstance.get(`/task/history?id=${taskId}`)
}

const useGetHistory = (taskId: string) => {
  return useQuery(['historytask', taskId], () => getHistory(taskId))
}

const removeTask = (payload: { Id: string, MoveToTrash: boolean }) => {
  return axiosInstance.delete('/task', {
    data: payload
  })
}

const useRemoveTask = () => { 
  return useMutation(removeTask);
}

export interface ICreateComment {
  TaskId: string;
  Note: String;
}

const createComment = (payload: ICreateComment) => {
  return axiosInstance.post('/task/comment', payload)
}

const useCreateComment = () => {
  return useMutation(createComment);
}

const updateEvent = (payload: IUpdateTaskPayload) => {
  return axiosInstance.put('/task', payload)
} 

const useUpdateEvent = () => {
  return useMutation(updateEvent);
} 

export default {
  useGetFeed,
  useUpdateTask,
  useGetTasks,
  useGetBusy,
  useComplete,
  useUncomplete,
  useGetTask,
  useDeleteTask,
  useMoveTask,
  useNewUpdateTask,
  useGetSingleTask,
  useGetHistory,
  useRemoveTask,
  useCreateComment,
  useUpdateEvent
};
