import { useMutation } from 'react-query';
import { axiosInstance } from './axios';
import { ListType } from 'dto/ListType';

const queryKeys = {
  getFiltersForCategory: (id: number) => [
    'filterService.getFiltersForCategory',
    id,
  ],
};

const getFiltersForCategory = ({ id }: { id: number | string | ListType }) => {
  return axiosInstance.get(`/list/filters?list=${id}`);
};

const useGetFiltersForCategory = () => {
  return useMutation(getFiltersForCategory);
};

const createFilterForCategory = (payload: any) => {
  return axiosInstance.post('/List/Filter', payload);
};

const useCreateFilterForCategory = () => {
  return useMutation(createFilterForCategory);
};

const deleteFilterForCategory = (payload: { Id: string }) => {
  return axiosInstance.delete('/list/filter', { data: payload });
};

const useDeleteFilterForCategory = () => {
  return useMutation(deleteFilterForCategory);
};

export default {
  useGetFiltersForCategory,
  useCreateFilterForCategory,
  useDeleteFilterForCategory,
};
