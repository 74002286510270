import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import SearchCasesNew from 'components/SearchCasesNew';
import Input from 'components/Input';
import AdditionalClientModal from 'components/AdditionalClientModal';
import ChooseTagNew from 'components/ChooseTagNew';
import Button from 'components/Button';
import clientService, {
  ICreateClient,
  INewUpdateClient,
} from 'api/clientService';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';
import TagBadge from 'components/TagBadge';
import SearchByNip from 'components/SearchByNip';
import { ReactComponent as PersonIcon } from 'assets/icons/company/person.svg';
import { ReactComponent as CompanyIcon } from 'assets/icons/company/company.svg';
import { ReactComponent as MembersIcon } from 'assets/icons/company/members.svg';
import ReactDOM from 'react-dom';
import ClientsContext from 'contexts/ClientsContext';
import { IWorkspace } from 'dto/IWorkspace';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import workspaceService from 'api/workspaceService';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import { validateEmail } from 'utils/isEmail';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import { ENotificationType, notification } from 'utils/notification';
import { axiosInstance } from 'api/axios';
import { useParams } from 'react-router';
import { IPackage, ISenderDetails } from 'dto/IPackage';

export interface IAddress {
  street: string;
  zipCode: string;
  city: string;
  address?: string;
}

export interface ICPerson {
  name: string;
  email: string;
  phone: string;
}

export interface IData {
  Case: ICaseSimple | null;
  personNip: string | null;
  personRegon: string | null;
  nip: string | null;
  regon: string | null;
  name: string;
  emails: string[];
  phones: string[];
  EAddresses: string[];
  bank: string[];

  addresses: IAddress[];

  note: string | null;

  contactPerson: ICPerson[];

  tagIds: ITag[];
}

const AddButton = ({
  data,
  selectedView,
  handleAdd,
}: {
  data: IData;
  selectedView: ETabs;
  handleAdd: (type: string) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className={styles.addWrapper}>
      {data.note === null && (
        <div
          onClick={() => {
            handleAdd('all');
            // setShowDropdown();
          }}
          className={styles.addCard}
        >
          + Dane
        </div>
      )}
      {showDropdown && (
        <div className={styles.addDropdown}>
          <div
            onClick={() => {
              handleAdd('address');
            }}
            className={styles.addBox}
          >
            Adres
          </div>

          <div
            onClick={() => {
              handleAdd('eaddress');
            }}
            className={styles.addBox}
          >
            Adres E-Doręczenie
          </div>

          <div
            onClick={() => {
              handleAdd('bank');
              // setShowDropdown(!showDropdown);
            }}
            className={styles.addBox}
          >
            Nr konta
          </div>

          <div
            onClick={() => {
              handleAdd('nip');
            }}
            className={styles.addBox}
          >
            Nr NIP
          </div>
          <div
            onClick={() => {
              handleAdd('regon');
            }}
            className={styles.addBox}
          >
            Nr REGON
          </div>

          <div
            onClick={() => {
              handleAdd('person');
            }}
            className={styles.addBox}
          >
            {selectedView === ETabs.PERSON
              ? 'Reprezentuje Firmę /Instytucję'
              : 'Osoby kontaktowe'}
          </div>
          <div
            onClick={() => {
              handleAdd('email');
            }}
            className={styles.addBox}
          >
            Email
          </div>
          <div
            onClick={() => {
              handleAdd('phone');
            }}
            className={styles.addBox}
          >
            Telefon
          </div>
          <div
            onClick={() => {
              handleAdd('note');
            }}
            className={styles.addBox}
          >
            Notatka
          </div>
        </div>
      )}
    </div>
  );
};

const TeamForm = ({
  data,
  setData,
}: {
  data: IData;
  setData: (data: IData) => void;
}) => {
  return (
    <>
      <Input
        value={data.name}
        onChange={(t) => {
          setData({ ...data, name: t });
        }}
        label="Imię i Nazwisko"
        isPhoneNumber={false}
      />
      {data.emails.map((e, index) => {
        return (
          <div className={styles.input}>
            <Input
              value={e}
              onChange={(t) => {
                const newEmails = [...data.emails];

                newEmails[index] = t;

                setData({ ...data, emails: newEmails });
              }}
              label="Email"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    emails: data.emails.filter(
                      (a: any, b: number) => b !== index
                    ),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
      {data.phones.map((e, index) => {
        return (
          <div className={styles.input}>
            <Input
              value={e}
              onChange={(t) => {
                const newPhones = [...data.phones];

                newPhones[index] = t;

                setData({ ...data, phones: newPhones });
              }}
              label="Numer telefonu"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    phones: data.phones.filter(
                      (a: any, b: number) => b !== index
                    ),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
      {data.EAddresses.map((e, index) => {
        return (
          <div className={styles.input}>
            <Input
              value={e}
              onChange={(t) => {
                const newPhones = [...data.EAddresses];

                newPhones[index] = t;

                setData({ ...data, EAddresses: newPhones });
              }}
              label="Adres E-doręczeń"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    EAddresses: data.EAddresses.filter((a, b) => b !== index),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

const PersonForm = ({
  data,
  setData,
  handleAdd,
  fetchData,
}: {
  data: IData;
  setData: (data: IData) => void;
  handleAdd: (string: string, regon?: string, address?: any) => void;
  fetchData: () => void;
}) => {
  const handleCompanyData = (company: any) => {
    handleAdd('all', company.Regon, {
      city: company.Place,
      street: company.Street,
      zipCode: company.ZipCode,
    });
  };

  const isPackages = window.location.href.search('incoming') !== -1;

  return (
    <div className={styles.form}>
      <div className={styles.top}>
        {isPackages && (
          <div onClick={fetchData} className={styles.add}>
            Pobierz dane z przesyłki
          </div>
        )}
      </div>

      <SearchCasesNew
        // onBlur={() => setFieldTouched('case', true)}
        settedCase={data.Case}
        setAddNewEntity={() => {}}
        // defaultValue={data.Case?.Id}
        onChoose={(cas) => {
          setData({ ...data, Case: cas });
          // setFieldValue('case', cas);
          // setFieldTouched('case', true);
        }}
      />

      <SearchByNip
        onDataChange={handleCompanyData}
        defaultValue={data.personNip ?? ''}
        onChange={(t) => {
          setData({ ...data, personNip: t });
        }}
      />
      <Input
        value={data.name}
        onChange={(t) => {
          setData({ ...data, name: t });
        }}
        label="Imię i nazwisko"
      />
      {data.emails.map((e, index) => {
        if (index > 0) return;
        return (
          <div className={styles.input}>
            <Input
              value={e}
              onChange={(t) => {
                const newEmails = [...data.emails];

                newEmails[index] = t;

                setData({ ...data, emails: newEmails });
              }}
              label="Email"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    emails: data.emails.filter(
                      (a: any, b: number) => b !== index
                    ),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
      {data.phones.map((e, index) => {
        if (index > 0) return;
        return (
          <div className={styles.input}>
            <Input
              isPhoneNumber
              value={e}
              onChange={(t) => {
                const newPhones = [...data.phones];

                newPhones[index] = t;

                setData({ ...data, phones: newPhones });
              }}
              label="Numer telefonu"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    phones: data.phones.filter(
                      (a: any, b: number) => b !== index
                    ),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
      {data.EAddresses.map((e, index) => {
        if (index > 0) return;
        return (
          <div className={styles.input}>
            <Input
              value={e}
              onChange={(t) => {
                const newPhones = [...data.EAddresses];

                newPhones[index] = t;

                setData({ ...data, EAddresses: newPhones });
              }}
              label="Adres E-doręczeń"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    EAddresses: data.EAddresses.filter((a, b) => b !== index),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const CompanyForm = ({
  data,
  setData,
  handleAdd,
  fetchData,
}: {
  data: IData;
  setData: (data: IData) => void;
  fetchData: () => void;

  handleAdd: (
    string: string,
    regon?: string,
    address?: any,
    name?: string
  ) => void;
}) => {
  const handleCompanyData = (company: any) => {
    handleAdd(
      'all',
      company.Regon,
      {
        city: company.Place,
        street: company.Street,
        zipCode: company.ZipCode,
      },
      company.Name
    );
  };

  const isPackages = window.location.href.search('incoming') !== -1;

  return (
    <div className={styles.form}>
      <div className={styles.top}>
        {isPackages && (
          <div onClick={fetchData} className={styles.add}>
            Pobierz dane z przesyłki
          </div>
        )}
      </div>

      <SearchCasesNew
        // onBlur={() => setFieldTouched('case', true)}
        setAddNewEntity={() => {}}
        defaultValue={data.Case?.Id}
        onChoose={(cas) => {
          setData({ ...data, Case: cas });
          // setFieldValue('case', cas);
          // setFieldTouched('case', true);
        }}
      />

      <SearchByNip
        onDataChange={handleCompanyData}
        defaultValue={data.nip ?? ''}
        onChange={(t) => {
          setData({ ...data, nip: t });
        }}
      />

      <Input
        value={data.name.replaceAll('<', '').replaceAll('>', '')}
        onChange={(t) => {
          setData({ ...data, name: t });
        }}
        label="Nazwa"
      />
      {data.emails.map((e, index) => {
        if (index > 0) return;
        return (
          <div className={styles.input}>
            <Input
              value={e}
              onChange={(t) => {
                const newEmails = [...data.emails];

                newEmails[index] = t;

                setData({ ...data, emails: newEmails });
              }}
              label="Email"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    emails: data.emails.filter(
                      (a: any, b: number) => b !== index
                    ),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
      {data.phones.map((e, index) => {
        if (index > 0) return;
        return (
          <div className={styles.input}>
            <Input
              isPhoneNumber
              value={e}
              onChange={(t) => {
                const newPhones = [...data.phones];

                newPhones[index] = t;

                setData({ ...data, phones: newPhones });
              }}
              label="Numer telefonu"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    phones: data.phones.filter(
                      (a: any, b: number) => b !== index
                    ),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
      {data.EAddresses.map((e, index) => {
        if (index > 0) return;
        return (
          <div className={styles.input}>
            <Input
              value={e}
              onChange={(t) => {
                const newPhones = [...data.EAddresses];

                newPhones[index] = t;

                setData({ ...data, EAddresses: newPhones });
              }}
              label="Adres E-doręczeń"
            />
            {index > 0 && (
              <CloseIcon
                onClick={() => {
                  setData({
                    ...data,
                    EAddresses: data.EAddresses.filter((a, b) => b !== index),
                  });
                }}
                className={styles.icone}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const EditClientModalNew: FC<Props> = ({
  initialValue,
  onClose,
  label,
  onCreate,
  isCompany = false,
}) => {
  const auth = useAuth();
  const userId = auth?.user ? getUserId(auth.user!) : '2';
  const LS = localStorage.getItem(`${userId ?? ''}.layout`);
  const sizes = JSON.parse(LS ?? '[]');

  const [actualView, setActualView] = useState(
    isCompany ? ETabs.COMPANY : ETabs.PERSON
  );
  const { mutate: createClient, isLoading: isL } =
    clientService.useCreateClient();
  const { mutate: updateClient, isLoading: isL2 } =
    clientService.useNewUpdateClient();

  const isLoading = isL || isL2;

  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');

  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);

  const { mutate: invite } = workspaceService.useInviteToWorkspace();

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);
  const workspaceRoles = useMemo(() => workspace?.Roles ?? [], [workspace]);
  const workspaceStructure = useMemo(
    () => workspace?.Structure ?? [],
    [workspace]
  );

  const { data: tagsData } = tagsService.useGetTags();
  const allTags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const { clients, setClients } = useContext(ClientsContext);

  const [data, setData] = useState<IData>({
    Case: null,
    personNip: null,
    personRegon: null,
    nip: '',
    regon: '',
    name: '',
    emails: [''],
    phones: [''],
    EAddresses: [''],
    bank: [],

    addresses: [],
    contactPerson: [],
    note: null,
    tagIds: [],
  });

  interface Error {
    index: number;
    type: string;
  }

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (!initialValue) return;

    let adr = initialValue.Data.filter((f) => f.Type === 'address').map((d) => {
      const data = JSON.parse(d.Value);

      return {
        city: data?.City ?? '',
        street: data?.Address ?? '',
        zipCode: data?.ZipCode ?? '',
      };
    });

    let ct = initialValue.Data.filter((f) => f.Type === 'contactPerson').map(
      (d) => {
        const data = JSON.parse(d.Value);
        return {
          email: data?.email ?? data?.Email,
          name: `${data?.firstName ?? data?.FirstName} ${
            data?.lastName ?? data?.LastName
          }`,
          phone: data?.phone ?? data?.Phone,
        };
      }
    );

    let note = initialValue.Data.find((f) => f.Type === 'note')?.Value;

    const isCustomData =
      !!initialValue.REGON?.length ||
      !!initialValue.Note?.length ||
      !!adr?.length ||
      !!note?.length;

    let banks: string[] = [];

    let regon = initialValue?.REGON;

    if (isCustomData) {
      banks = [''];

      // bank: [''],
      // note: '',
      // contactPerson: [{ email: '', name: '', phone: '' }],
      // addresses: [...data.addresses, { city: '', street: '', zipCode: '' }],
      // regon: '',
      // personRegon: '',

      if (!adr.length) {
        adr = [...data.addresses, { city: '', street: '', zipCode: '' }];
      }

      if (!ct.length) {
        ct = [{ email: '', name: '', phone: '' }];
      }

      if (!regon?.length) {
        regon = '';
      }

      if (!note?.length) {
        note = '';
      }
    }

    const emails = initialValue.Data.filter((f) => f.Type === 'email').map(
      (f) => f.Value
    );
    const phones = initialValue.Data.filter((f) => f.Type === 'phone').map(
      (f) => f.Value
    );
    const eaddress = initialValue.Data.filter(
      (f) => f.Type === 'edeliveryAddress'
    ).map((f) => f.Value);

    const list: IData = {
      Case: null,
      bank: banks,
      personNip: initialValue.NIP ?? null,
      personRegon: regon,
      nip: initialValue.NIP ?? '',
      regon: regon,
      addresses: adr,
      contactPerson: ct,

      emails: !!emails.length ? emails : ['', ''],
      phones: !!phones.length ? phones : ['', ''],
      EAddresses: !!eaddress.length ? eaddress : ['', ''],
      name: initialValue.Name,

      note: note ?? null,
      tagIds: [],
    };

    if (initialValue.IsInstitution) {
      setActualView(ETabs.COMPANY);
    }
    setData(list);
  }, []);

  const createData = () => {
    const addresses = data.addresses
      .filter(
        (a) => !!a.city?.length && !!a.zipCode?.length && !!a.street?.length
      )
      .map((a, i) => {
        const value = JSON.stringify({
          city: a.city,
          zipCode: a.zipCode,
          address: a.street,
          isHeadquarter: i === 0,
        });
        return {
          Type: 'address',
          Value: value,
          IsMain: i === 0,
        };
      });

    const contactPersons = data.contactPerson
      .filter((a) => !!a.email.length && !!a.name.length && !!a.phone.length)
      .map((a, i) => {
        const value = JSON.stringify({
          FirstName: a.name?.split?.(' ')?.[0] ?? '',
          LastName: a.name?.split?.(' ')?.[1] ?? '',
          JobPosition: '',
          Phone: a.phone,
          Email: a.email,
        });
        return {
          Type: 'contactPerson',
          Value: value,
          IsMain: i === 0,
        };
      });

    const emails = data.emails
      .filter((f) => !!f.length)
      .map((m, index) => {
        return {
          Type: 'email',
          Value: m.replaceAll(' ', ''),
          IsMain: index === 0,
        };
      });

    const phones = data.phones
      .filter((f) => !!f.length)
      .map((m, index) => {
        return {
          Type: 'phone',
          Value: m.replaceAll(' ', ''),
          IsMain: index === 0,
        };
      });

    const eaddress = data.EAddresses.filter((f) => !!f.length).map(
      (m, index) => {
        return {
          Type: 'edeliveryAddress',
          Value: m,
          IsMain: index === 0,
        };
      }
    );

    let d: any = [addresses, emails, phones, contactPersons, eaddress].flat();

    const note = {
      Type: 'note',
      Value: !!data?.note?.length ? data.note : null,
      IsMain: true,
    };

    if (note.Value !== null) {
      d = [...d, note].flat();
    }

    return d;
  };

  const handleCreate = () => {
    if (actualView === ETabs.TEAM) {
      invite(
        {
          Email: data.emails[0],
          WorkspaceId: workspaceId,
          Name: data.name,
          RoleId: workspaceRoles.find((f) => f.Name === 'Pracownik')?.Id ?? '',
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
      return;
    }

    if (initialValue) {
      let payload: INewUpdateClient = {
        Id: initialValue.Id,
        Name: data.name.replaceAll('<', '').replaceAll('>', ''),
        Data: createData(),
        IsInstitution: false,
        NIP:
          data.personNip !== null
            ? data.personNip.replaceAll(' ', '')
            : undefined,
        REGON:
          data.personRegon !== null
            ? data.personRegon.replaceAll(' ', '')
            : undefined,
        TagIds: data.tagIds.map((t) => t.Id),
      };

      const nip =
        actualView === ETabs.COMPANY ? data.nip ?? '' : data.personNip ?? '';
      const regon =
        actualView === ETabs.COMPANY
          ? data.regon ?? ''
          : data.personRegon ?? '';

      if (actualView === ETabs.COMPANY) {
        payload = {
          Id: initialValue.Id,
          Name: data.name.replaceAll('<', '').replaceAll('>', ''),
          Data: createData(),
          IsInstitution: true,
          TagIds: data.tagIds.map((t) => t.Id),
          NIP: nip.replaceAll(' ', ''),
          REGON: regon.replaceAll(' ', ''),
        };
      }

      updateClient(payload, {
        onSuccess: (resp) => {
          const newClients = [...clients];
          let client = newClients.find((d) => d.Id === initialValue.Id);
          if (client) {
            client.Name = data.name.replaceAll('<', '').replaceAll('>', '');
            client.Data = createData() as any;
            client.Flags = resp.data.Flags;
            client.NIP =
              actualView === ETabs.PERSON
                ? regon.replaceAll(' ', '') ?? undefined
                : nip.replaceAll(' ', '');
            setClients(newClients);
          }

          onClose();
        },
      });
      return;
    }

    let payload: ICreateClient = {
      Name: data.name,
      Data: createData(),
      IsInstitution: false,
      TagIds: data.tagIds.map((t) => t.Id),
      NIP:
        data.personNip !== null
          ? data.personNip.replaceAll(' ', '')
          : undefined,
      REGON:
        data.personRegon !== null
          ? data.personRegon.replaceAll(' ', '')
          : undefined,
    };

    if (actualView === ETabs.COMPANY) {
      const nip =
        actualView === ETabs.COMPANY ? data.nip ?? '' : data.personNip ?? '';
      const regon =
        actualView === ETabs.COMPANY
          ? data.regon ?? ''
          : data.personRegon ?? '';

      payload = {
        Name: data.name,
        Data: createData(),
        IsInstitution: true,
        TagIds: data.tagIds.map((t) => t.Id),
        NIP: nip.replaceAll(' ', ''),
        REGON: regon.replaceAll(' ', ''),
      };
    }

    createClient(payload, {
      onSuccess: (resp) => {
        setClients([resp.data, ...clients]);
        onCreate?.(resp.data);
        onClose();
      },
      onError: (resp: any) => {
        if (resp?.response?.data?.errorCodes?.[0]?.code === 'Duplicate') {
          notification({
            type: ENotificationType.ERROR,
            title: 'Podmiot',
            text: 'Podmiot o podanym nipie istnieje już w bazie danych!',
          });
          return;
        }
        notification({
          type: ENotificationType.ERROR,
          title: 'Podmiot',
          text: 'Coś poszło nie tak!',
        });
      },
    });
  };

  const isNumber = data.personNip !== null || data.personRegon !== null;

  const showAdditionalInformation =
    !!data.addresses.length ||
    !!data.contactPerson.length ||
    data.note !== null ||
    isNumber;

  const Menu = () => {
    return (
      <div className={styles.switchbox}>
        <div
          onClick={() => setActualView(ETabs.PERSON)}
          className={`${styles.switch}  ${
            actualView === ETabs.PERSON && styles.active
          }`}
        >
          <PersonIcon /> Osoba
        </div>
        <div
          onClick={() => setActualView(ETabs.COMPANY)}
          className={`${styles.switch}  ${
            actualView === ETabs.COMPANY && styles.active
          }`}
        >
          <CompanyIcon /> Firma
        </div>
        {/* <div
          onClick={() => setActualView(ETabs.TEAM)}
          className={`${styles.box}  ${
            actualView === ETabs.TEAM && styles.activeText
          }`}
        >
          <MembersIcon /> Zespół
        </div> */}
      </div>
    );
  };

  const handleAdd = (
    type: string,
    regon?: string,
    place?: any,
    name?: string
  ) => {
    switch (type) {
      case 'all':
        setData({
          ...data,
          name: name ? name : data.name ?? '',
          bank: [''],
          note: data.note ?? '',
          emails: [data?.emails?.[0], data.emails[1] ?? ''],
          phones: [data.phones[0], data.phones[1] ?? ''],
          EAddresses: [data.EAddresses[0], data.EAddresses[1] ?? ''],
          contactPerson: data.contactPerson[0]
            ? [...data.contactPerson]
            : [{ email: '', name: '', phone: '' }],

          addresses: [
            ...data.addresses,
            place ?? { city: '', street: '', zipCode: '' },
          ].filter((e, index) => index === 0 || !!e.city.length),
          regon: regon ?? '',
          personRegon: regon ?? '',
        });

        return;

      case 'email':
        setData({ ...data, emails: [...data.emails, ''] });
        return;
      case 'phone':
        setData({ ...data, phones: [...data.phones, ''] });
        return;
      case 'bank':
        setData({ ...data, bank: [...data.bank, ''] });
        return;
      case 'eaddress':
        setData({ ...data, EAddresses: [...data.EAddresses, ''] });
        return;
      case 'note':
        setData({ ...data, note: '' });
        return;
      case 'person':
        setData({
          ...data,
          contactPerson: [
            ...data.contactPerson,
            { email: '', name: '', phone: '' },
          ],
        });
        return;
      case 'address':
        setData({
          ...data,
          addresses: [...data.addresses, { city: '', street: '', zipCode: '' }],
        });
        return;
      case 'nip':
        setData({ ...data, personNip: '' });
        return;
      case 'regon':
        setData({ ...data, personRegon: '' });
        return;
    }
  };

  const checkCan = () => {
    const wrongEmails = data.emails.filter(
      (f) => !!f.length && !validateEmail(f)
    );

    if (!data.name.length) return true;

    if (!!wrongEmails.length) {
      return true;
    }

    return false;
  };

  const refWrapper = useRef(null);

  const fetchData = async () => {
    const link = window.location.href.split('/');
    const isThread = window.location.href.search('thread');
    const packageId = isThread ? link[9] : link[7];
    const packageKind = isThread ? link[7] : link[6];
    const response = await axiosInstance.get(
      `/list?Id=${packageId}&Kind=${packageKind}`
    );

    const pack: IPackage = response.data.Items[0];
    const details: ISenderDetails = pack.SenderDetails;

    const newData = { ...data };

    if (details?.IsInstitution) {
      setActualView(ETabs.COMPANY);
    }

    if (details?.Name) {
      newData.name = details.Name;
    }

    if (details?.Email) {
      newData.emails[0] = details.Email;
    }

    if (details?.Nip) {
      newData.nip = details.Nip;
      newData.personNip = details.Nip;
    }
    if (details?.REGON) {
      newData.regon = details.REGON;
      newData.personRegon = details.REGON;
    }

    if (details?.Address) {
      newData.addresses[0] = {
        city: details.Address.city,
        street: details.Address.address ?? '',
        zipCode: details.Address.zipCode,
      };
    }

    newData.bank = [''];

    setData(newData);
  };

  return ReactDOM.createPortal(
    <ModalLayout
      dock={DockPosition.DISABLED}
      off={true}
      customX={1}
      customY={0}
      isAnimation
      ignore
      refWrapper={refWrapper}
      fullHeight={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {label ? label : 'Edycja podmiotu'}
          <CloseIcon onClick={onClose} className={styles.c} />
        </div>
        <div className={styles.content}>
          <Menu />
          {actualView === ETabs.PERSON && (
            <PersonForm
              fetchData={fetchData}
              handleAdd={handleAdd}
              data={data}
              setData={setData}
            />
          )}
          {actualView === ETabs.COMPANY && (
            <CompanyForm
              fetchData={fetchData}
              handleAdd={handleAdd}
              data={data}
              setData={setData}
            />
          )}
          {actualView === ETabs.TEAM && (
            <TeamForm data={data} setData={setData} />
          )}

          <div className={styles.menus}>
            <ChooseTagNew
              type={3}
              label="Role"
              onChoose={(tag) => {
                // setTags([...tags, tag]);

                setData({ ...data, tagIds: [...data.tagIds, tag!] });
              }}
            />
            {/* <AddButton
              data={data}
              selectedView={actualView}
              handleAdd={handleAdd}
            /> */}
          </div>
          <div className={styles.tags}>
            {data.tagIds.map((t) => (
              <TagBadge
                tag={t}
                onRemove={() => {
                  setData({
                    ...data,
                    tagIds: data.tagIds.filter((te) => te.Id !== t.Id),
                  });
                }}
              />
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            loading={isLoading}
            disabled={checkCan()}
            onClick={handleCreate}
            text={initialValue ? 'Zapisz' : 'Utwórz'}
          />
        </div>
        {/* {showAdditionalInformation && (
          <AdditionalClientModal
            view={actualView}
            data={data}
            setData={setData}
          />
        )} */}
      </div>
    </ModalLayout>,
    document.body
  );
};

export default EditClientModalNew;
