import moment from 'moment';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import statusService from 'api/statusService';
import ActionsOptionsCard from 'components/ActionsOptionsCard';
import StatusBadge from 'components/StatusBadge';
import { IStatus } from 'dto/IStatus';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import tagsService from 'api/tagsService';
import { ReactComponent as CaseIcon } from 'assets/icons/case.svg';
import ModalLayout from 'components/ModalLayout';
import PackageCaseModal from 'components/PackageCaseModal';
import StatusModal from 'components/StatusModal';
import TagBadge from 'components/TagBadge';
import { ITag } from 'dto/ITag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import MenuContext from 'contexts/MenuContext';
import ActionModal from 'components/ActionModal';
import { ITask } from 'dto/ITask';
import settingsContext, { IOptionType } from 'contexts/SettingsContext';

const TaskBox: FC<Props> = ({
  task,
  handleChangeStatus,
  handleDelete,
  handleChangeFlag,
  handleUpdateTask,
  handleAddTag,
  handleRemoveTag,
  refetch,
}) => {
  const { data: statusesData } = statusService.useGetStatuses();
  const { data: tagsData } = tagsService.useGetTags();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const findedStatuses = statuses?.find(
    (status: IStatus) => status.Id === task.StatusId
  );

  const findedTags = tags?.filter((t: ITag) =>
    (task.TagIds ?? []).includes(t.Id)
  );

  const [options, setOptions] = useState(false);

  const [showStatus, setShowStatus] = useState(false);

  const endDate = task.AllDay
    ? moment(task.FromDate).endOf('day').add('1', 'm')
    : moment(task.ToDate);

  const handleClickDelete = () => {
    handleDelete(task.Id);
  };

  const ref = useRef(null);
  const iconsWrapper = useRef(null);
  const [caseId, setCaseId] = useState<string | null>(null);

  const diff = moment(endDate).diff(task.FromDate, 'd');

  let format = 'DD.MM HH:mm';

  if (task.AllDay) {
    const time = moment(task.FromDate).format('HH:mm');

    format = 'DD.MM';

    if (time !== '00:00') {
      format = 'DD.MM HH:mm';
    }
  }

  const isCases = window.location.href.search('cases') !== -1;

  const [showMenu, setShowMenu] = useState(false);

  const { id, setId } = useContext(MenuContext);

  const [edit, setEdit] = useState(false);

  const handleChangeDate = (task: ITask | null) => {
    refetch();
    setEdit(false);
  };

  useEffect(() => {
    if (id !== task.Id) {
      setOptions(false);
    }
  }, [id]);

  const { options: option } = useContext(settingsContext);

  const h = option.find((f) => f.Type === IOptionType.hoverMenu)?.Value;

  const useHoverOver = h === 'true';

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [showAfter, setShowAfter] = useState(false);

  const c = showAfter;

  let isOpen = options && showAfter;

  if (!useHoverOver) {
    isOpen = options;
  }

  return (
    <div
      onMouseEnter={() => {
        if (useHoverOver) {
          setOptions(true);

          const t = setTimeout(() => {
            setShowAfter(true);
          }, 500);
          setTimer(t);
        }
      }}
      onMouseLeave={() => {
        if (useHoverOver) {
          setOptions(false);
          setShowAfter(false);
          if (timer) {
            clearTimeout(timer);
            setTimer(null);
          }
        }
      }}
      ref={ref}
      className={styles.container}
    >
      <ActionsOptionsCard
        refWrapper={ref}
        handleAddTag={handleAddTag}
        handleRemoveTag={handleRemoveTag}
        selectedStatus={findedStatuses}
        refetch={refetch}
        handleUpdateTask={handleUpdateTask}
        showOptions={isOpen}
        handleChangeStatus={handleChangeStatus}
        handleClickDelete={handleClickDelete}
        handleChangeFlag={handleChangeFlag}
        task={task}
      />
      <div className={styles.color}></div>
      <div
        onClick={(e: any) => {
          const ele = e.target.tagName.toLowerCase();
          const button = e.target.outerHTML.search('button') !== -1;

          if (ele !== 'div' || button) {
            return;
          }

          setEdit(true);
        }}
        className={styles.box}
      >
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div className={styles.name}>
              {/* @ts-ignore */}
              {task?.Title ?? task?.title}
            </div>
            <div className={styles.description}>
              {!!findedTags.length && (
                <div
                  onClick={() => setShowStatus(true)}
                  className={styles.badges}
                >
                  {findedTags.map((t: ITag) => (
                    <TagBadge tag={t} />
                  ))}
                </div>
              )}
              <div className={styles.note}>{task.Note}</div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.icons} ref={iconsWrapper}>
              {findedStatuses && (
                <div
                  onClick={() => setShowStatus(true)}
                  className={styles.flags}
                >
                  <StatusBadge status={findedStatuses} onRemove={() => {}} />
                </div>
              )}
              {task?.Case?.Id && !isCases && (
                <CaseIcon
                  onClick={() => setCaseId(task?.Case?.Id)}
                  className={styles.icon}
                />
              )}
              {!useHoverOver && (
                <div
                  onClick={() => {
                    setOptions(!options);
                    setId(task.Id);
                  }}
                  className={styles.hover}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {caseId && (
        <ModalLayout toggle={() => setCaseId(null)} refWrapper={iconsWrapper}>
          <PackageCaseModal CaseId={caseId} toggle={() => setCaseId(null)} />
        </ModalLayout>
      )}

      {edit && (
        <ActionModal
          onChange={handleChangeDate}
          initialState={{
            task: task,
            id: task.Id,
            Id: task.Id,
            case: task.Case,
            endDate: moment(task.ToDate).toDate(),
            startDate: moment(task.FromDate).toDate(),
            text: task.Note,
            title: task.Title,
            allDay: task.AllDay,
          }}
        />
      )}

      {showStatus && (
        <ModalLayout refWrapper={ref}>
          <StatusModal
            item={{
              Id: task.Id,
              Kind: 131072,
              StatusId: task.StatusId,
            }}
            toggle={() => setShowStatus(false)}
            afterUpdate={(flag) => handleChangeFlag(task.Id, flag!.Id)}
            selectedStatus={findedStatuses}
          />
        </ModalLayout>
      )}
    </div>
  );

  // return (
  //   <div
  //     onMouseEnter={() => {
  //       if (useHoverOver) {
  //         setOptions(true);

  //         const t = setTimeout(() => {
  //           setShowAfter(true);
  //         }, 500);
  //         setTimer(t);
  //       }
  //     }}
  //     onMouseLeave={() => {
  //       if (useHoverOver) {
  //         setOptions(false);
  //         setShowAfter(false);
  //         if (timer) {
  //           clearTimeout(timer);
  //           setTimer(null);
  //         }
  //       }
  //     }}
  //     className={styles.task}
  //     ref={ref}
  //   >
  //     <ActionsOptionsCard
  //       refWrapper={ref}
  //       handleAddTag={handleAddTag}
  //       handleRemoveTag={handleRemoveTag}
  //       selectedStatus={findedStatuses}
  //       refetch={refetch}
  //       handleUpdateTask={handleUpdateTask}
  //       showOptions={isOpen}
  //       handleChangeStatus={handleChangeStatus}
  //       handleClickDelete={handleClickDelete}
  //       handleChangeFlag={handleChangeFlag}
  //       task={task}
  //     />
  //     <div className={styles.header}>
  //       <div className={styles.badges}>
  //         {/* {!task.CompleteDate && moment(new Date()).isAfter(endDate) && (
  //           <div className={styles.badge}>
  //             <div className={`${styles.dot} ${styles.old}`}></div>
  //             Przeterminowane
  //           </div>
  //         )} */}
  //       </div>
  //       <div className={styles.hd}>
  //         <div
  //           onClick={() => {
  //             setEdit(true);
  //           }}
  //           className={styles.title}
  //         >
  //           {/* @ts-ignore */}
  //           {task?.Title ?? task?.title}
  //         </div>
  //         <div className={styles.icons} ref={iconsWrapper}>
  //           {task?.Case?.Id && !isCases && (
  //             <CaseIcon
  //               onClick={() => setCaseId(task?.Case?.Id)}
  //               className={styles.icon}
  //             />
  //           )}
  //           {!useHoverOver && (
  //             <div
  //               onClick={() => {
  //                 setOptions(!options);
  //                 setId(task.Id);
  //               }}
  //               className={styles.hover}
  //             >
  //               <FontAwesomeIcon icon={faEllipsisVertical} />
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //       <div className={styles.hdate}>
  //         {task.AllDay ? (
  //           <>{moment(task.FromDate).format(format)}</>
  //         ) : (
  //           <>
  //             {moment(task.FromDate).format(format)} - {endDate.format(format)}
  //           </>
  //         )}
  //       </div>
  //     </div>
  //     <div
  //       onClick={() => {
  //         setEdit(true);
  //       }}
  //       className={styles.body}
  //     >
  //       <div className={styles.description}>
  //         {findedStatuses && (
  //           <div onClick={() => setShowStatus(true)} className={styles.flags}>
  //             <StatusBadge status={findedStatuses} onRemove={() => {}} />
  //           </div>
  //         )}
  //         {findedTags && (
  //           <div onClick={() => setShowStatus(true)} className={styles.badges}>
  //             {findedTags.map((t: ITag) => (
  //               <TagBadge tag={t} onRemove={() => {}} />
  //             ))}
  //           </div>
  //         )}
  //         {task.Note}
  //       </div>
  //       <div
  //       // className={`${styles.dot} ${!task.CompleteDate && styles.old}`}
  //       ></div>
  //     </div>

  //     {caseId && (
  //       <ModalLayout refWrapper={iconsWrapper}>
  //         <PackageCaseModal CaseId={caseId} toggle={() => setCaseId(null)} />
  //       </ModalLayout>
  //     )}

  //     {edit && (
  //       <ActionModal
  //         onChange={handleChangeDate}
  //         initialState={{
  //           task: task,
  //           id: task.Id,
  //           Id: task.Id,
  //           case: task.Case,
  //           endDate: moment(task.ToDate).toDate(),
  //           startDate: moment(task.FromDate).toDate(),
  //           text: task.Note,
  //           title: task.Title,
  //           allDay: task.AllDay,
  //         }}
  //       />
  //     )}

  //     {showStatus && (
  //       <ModalLayout refWrapper={ref}>
  //         <StatusModal
  //           item={{
  //             Id: task.Id,
  //             Kind: 131072,
  //             StatusId: task.StatusId,
  //           }}
  //           toggle={() => setShowStatus(false)}
  //           afterUpdate={(flag) => handleChangeFlag(task.Id, flag!.Id)}
  //           selectedStatus={findedStatuses}
  //         />
  //       </ModalLayout>
  //     )}
  //   </div>
  // );
};

export default TaskBox;
