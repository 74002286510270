import listService from 'api/listService';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useMemo } from 'react';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import MessageCard from 'components/MessageCard';
import { IPackage } from 'dto/IPackage';
import { useParams } from 'react-router-dom';

const ThreadList: FC<Props> = ({ pack }) => {
  const { threadPackageId } = useParams();
  const { data } = listService.useGetPackage({
    ThreadId: pack.Thread!.Id!,
  });

  const { data: tagsData } = tagsService.useGetTags();
  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const packs = useMemo(() => data?.data.Items, [data]);
  const keys = useMemo(() => data?.data.Keys, [data]);

  return (
    <div className={styles.wrapper}>
      {packs
        ?.filter((p: IPackage) => p.Id !== pack.Id)
        .map((pack: IPackage) => (
          <MessageCard
            onDownload={() => {}}
            onPreview={() => {}}
            keys={keys}
            msg={pack}
            statuses={statuses}
            tags={tags}
            showSomething={false}
            key={pack.Id}
            isThread={true}
          />
        ))}
    </div>
  );
};

export default ThreadList;
