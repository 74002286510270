import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';
import AddressesClientModal from 'components/AddressesClientModal';
import NoteClientModal from 'components/NoteClientModal';
import ContactPersonsClientModal from 'components/ContactPersonsClientModal';
import { ReactComponent as LIcon } from 'assets/icons/company/l.svg';
import { ReactComponent as CIcon } from 'assets/icons/company/c.svg';
import { ReactComponent as NIcon } from 'assets/icons/company/n.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/case.svg';
import { ETabs as Tab } from 'components/EditClientModalNew/typings';
import NumbersClientModal from 'components/NumbersClientModal';
import AdditionalCaseCreate from 'components/AdditionalCaseCreate';
import AdditionalClientCreate from 'components/AdditionalClientCreate';

const AdditionalClientModal: FC<Props> = ({
  data,
  setData,
  view,

  showClient = false,
  onCreateClient = () => {},
  isCompany = false,
  showCase = false,
  onCreateCase = () => {},
  showActionModal = false,
  component,
}) => {
  const [actualView, setActualView] = useState(ETabs.ADDRESSES);
  const [initalized, setInitialized] = useState(false);

  const showAddress = actualView === ETabs.ADDRESSES && !!data && !!setData;
  const showNote = actualView === ETabs.NOTE && data && setData;
  const showPersons =
    actualView === ETabs.PERSONS && !!data && !!setData && view !== undefined;
  const showNumbers =
    actualView === ETabs.NUMBERS && !!data && !!setData && view !== undefined;

  const isNumber = !!data?.bank.length;

  const isNote = data?.note !== null && data?.note !== undefined;

  useEffect(() => {
    if (component) {
      setActualView(ETabs.CALENDAR);
      return;
    }

    if (showClient) {
      setActualView(ETabs.CREATE_CLIENT);
      return;
    }

    setActualView(ETabs.NUMBERS);
  }, []);

  // useEffect(() => {
  //   if (initalized) return;
  //   if (!!data?.addresses.length) {
  //     setActualView(ETabs.ADDRESSES);
  //     setInitialized(true);
  //     return;
  //   }

  //   if (!!data?.contactPerson.length) {
  //     setActualView(ETabs.PERSONS);
  //     setInitialized(true);
  //     return;
  //   }

  //   if (isNumber) {
  //     setActualView(ETabs.NUMBERS);
  //     setInitialized(true);
  //     return;
  //   }

  //   if (showCase) {
  //     setActualView(ETabs.CREATE_CASE);
  //     setInitialized(true);
  //     return;
  //   }

  //   if (showClient) {
  //     setActualView(ETabs.CREATE_CLIENT);
  //     setInitialized(true);
  //     return;
  //   }

  //   if (showActionModal) {
  //     setActualView(ETabs.CALENDAR);
  //     setInitialized(true);
  //     return;
  //   }

  //   setInitialized(true);
  //   setActualView(ETabs.NOTE);
  // }, []);

  // useEffect(() => {
  //   if (!initalized) return;
  //   setActualView(ETabs.NOTE);
  // }, [data?.note]);

  // useEffect(() => {
  //   if (!initalized) return;
  //   setActualView(ETabs.CREATE_CASE);
  // }, [showCase]);

  // useEffect(() => {
  //   if (!initalized) return;
  //   setActualView(ETabs.CREATE_CLIENT);
  // }, [showClient]);

  // useEffect(() => {
  //   if (!initalized) return;
  //   setActualView(ETabs.CALENDAR);
  // }, [showActionModal]);

  // useEffect(() => {
  //   if (!initalized) return;
  //   setActualView(ETabs.ADDRESSES);
  // }, [data?.addresses]);

  // useEffect(() => {
  //   if (!initalized) return;
  //   setActualView(ETabs.PERSONS);
  // }, [data?.contactPerson]);

  // useEffect(() => {
  //   if (!initalized) return;
  //   setActualView(ETabs.NUMBERS);
  // }, [data?.personNip, data?.personRegon]);

  const Menu = () => {
    return (
      <div className={styles.menu}>
        {showCase && (
          <div
            onClick={() => setActualView(ETabs.CREATE_CASE)}
            className={`${styles.box}  ${
              actualView === ETabs.CREATE_CASE && styles.activeText
            }`}
          >
            <CaseIcon /> Teczka
          </div>
        )}
        {showClient && (
          <div
            onClick={() => setActualView(ETabs.CREATE_CLIENT)}
            className={`${styles.box}  ${
              actualView === ETabs.CREATE_CLIENT && styles.activeText
            }`}
          >
            <PersonIcon /> Podmiot
          </div>
        )}
        {showActionModal && (
          <div
            onClick={() => setActualView(ETabs.CALENDAR)}
            className={`${styles.box}  ${
              actualView === ETabs.CALENDAR && styles.activeText
            }`}
          >
            <LIcon /> Data
          </div>
        )}
        {isNumber && (
          <div
            onClick={() => setActualView(ETabs.NUMBERS)}
            className={`${styles.box}  ${
              actualView === ETabs.NUMBERS && styles.activeText
            }`}
          >
            <LIcon /> Numer
          </div>
        )}
        {!!data?.addresses.length && (
          <div
            onClick={() => setActualView(ETabs.ADDRESSES)}
            className={`${styles.box}  ${
              actualView === ETabs.ADDRESSES && styles.activeText
            }`}
          >
            <LIcon /> Adres
          </div>
        )}

        {!!data?.contactPerson.length && view === Tab.COMPANY && (
          <div
            onClick={() => setActualView(ETabs.PERSONS)}
            className={`${styles.box}  ${
              actualView === ETabs.PERSONS && styles.activeText
            }`}
          >
            <CIcon /> Kontakt
          </div>
        )}
        {isNote && (
          <div
            onClick={() => setActualView(ETabs.NOTE)}
            className={`${styles.box}  ${
              actualView === ETabs.NOTE && styles.activeText
            }`}
          >
            <NIcon /> Notatka
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.padding}>
        <Menu />
        {showAddress && <AddressesClientModal data={data} setData={setData} />}
        {showNote && <NoteClientModal data={data} setData={setData} />}
        {showPersons && (
          <ContactPersonsClientModal
            view={view}
            data={data}
            setData={setData}
          />
        )}
        {showNumbers && (
          <NumbersClientModal view={view} data={data} setData={setData} />
        )}
        {actualView === ETabs.CREATE_CASE && (
          <AdditionalCaseCreate onCreate={onCreateCase} />
        )}
        {actualView === ETabs.CREATE_CLIENT && (
          <AdditionalClientCreate
            onCreateClient={onCreateClient}
            isCompany={isCompany}
          />
        )}
        {actualView === ETabs.CALENDAR && component}
      </div>
    </div>
  );
};

export default AdditionalClientModal;
