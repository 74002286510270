import { PackageList } from 'modules/packageModule/packageList';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import ReactDOM from 'react-dom';
import { ITypeOfWindow } from 'components/MarkedDeskWindowSmall';
import CaseList from 'components/CaseList';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import { useContext, useEffect, useMemo } from 'react';
import ActionsList from 'components/ActionsList';
import { IPackage } from 'dto/IPackage';
import listService from 'api/listService';
import LettersContext from 'contexts/LettersContext';
import LetterCard from 'components/LetterCard';
import KeysContext from 'contexts/KeysContext';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const PreviewMarkedDeskWindow: FC<Props> = ({ type, toggle }) => {
  const { data: tagsData } = tagsService.useGetTags();
  const { data: statusesData } = statusService.useGetStatuses();

  const { data: lettersData, refetch } = listService.useGetListOfMessages({
    Group: true,
    IsIncoming: false,
    Page: 0,
    SortOrder: 'Ascending',
    SortProp: 'SendDate',
    Kind: 256,
    Desk: true,
  });

  const l: IPackage[] = useMemo(() => lettersData?.Items ?? [], [lettersData]);
  const objectKeys = useMemo(
    () => lettersData?.ObjectKeys ?? [],
    [lettersData]
  );
  const Keys = useMemo(() => lettersData?.Keys ?? [], [lettersData]);

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const { letters, setLetters } = useContext(LettersContext);
  const { keys, setKeys } = useContext(KeysContext);

  useEffect(() => {
    setLetters(l);
    // setKeys([...keys, objectKeys, Keys].flat());
  }, [l]);

  const translate = () => {
    switch (type) {
      case ITypeOfWindow.INCOMING:
        return 'Przesyłki przychodzące';
      case ITypeOfWindow.OUTCOMING:
        return 'Przesyłki wychodzące';
      case ITypeOfWindow.CASES:
        return 'Teczki';
      case ITypeOfWindow.ACTIONS:
        return 'Czynności';
      case ITypeOfWindow.LETTERS:
        return 'Pisma';
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.header}>
          <div className={styles.headerWrapper}>
            {translate()}
            <CloseIcon onClick={toggle} />
          </div>
        </div>
        <div className={styles.wrapper}>
          {type === ITypeOfWindow.INCOMING && (
            <PackageList
              fetchNextPage={() => {}}
              customSortMethod={'DeskGroupId'}
              customFilterId={'c555b900-860a-4628-80ab-084d01076275'}
              canSelect={false}
              debouncedText=""
              firstElement={null}
              isIncoming={true}
              onDownload={() => {}}
              onPreview={() => {}}
              searchText=""
              selectMultiple={false}
              selectedElements={[]}
              setSelectedElements={() => {}}
              showSomething={false}
            />
          )}
          {type === ITypeOfWindow.OUTCOMING && (
            <PackageList
              fetchNextPage={() => {}}
              customSortMethod={'DeskGroupId'}
              customFilterId="9718808f-dcb8-4a07-a04c-f9de36b266ca"
              canSelect={false}
              debouncedText=""
              firstElement={null}
              isIncoming={false}
              onDownload={() => {}}
              onPreview={() => {}}
              searchText=""
              selectMultiple={false}
              selectedElements={[]}
              setSelectedElements={() => {}}
              showSomething={false}
            />
          )}
          {type === ITypeOfWindow.CASES && (
            <CaseList
              selectedThread={null}
              setSelectedThread={() => {}}
              filterId="d902a02c-16fa-4f88-8c0b-8f6c2f36ec71"
              searchText=""
              tags={tags}
              statuses={statuses}
              setSelectedEdit={() => {}}
            />
          )}
          {type === ITypeOfWindow.ACTIONS && (
            <ActionsList isDesk={true} date={new Date()} />
          )}
          {type === ITypeOfWindow.LETTERS && (
            <div className={styles.letters}>
              {letters.map((letter: IPackage) => (
                <LetterCard
                  selectedItems={[]}
                  setSelectedItems={() => {}}
                  isOutcoming={false}
                  statuses={statuses}
                  tags={tags}
                  msg={letter}
                  refetch={refetch}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default PreviewMarkedDeskWindow;
