import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { AxiosResponse } from 'axios';

const queryKeys = {
  getWorkspace: (workspaceId: string) => [
    'workspaceService.getWorkspace',
    workspaceId,
  ],
  getWorkspaces: 'workspaceService.getWorkspaces',
  getCurrentWorkspace: 'workspaceService.getCurrentWorkspace',
  getInvitation: (id: string) => ['workspaceService.getInvitation', id],
  getSwitches: 'workspaceService.getSwitches',
};

const getCurrentWorkspace = () => {
  return axiosInstance.get('/workspace/current');
};

const useGetCurrentWorkspace = () => {
  return useQuery(queryKeys.getCurrentWorkspace, () => getCurrentWorkspace(), {
    cacheTime: 0,
    staleTime: 0,
    retry: 0,
  });
};

const getWorkspaces = () => {
  return axiosInstance.get('/workspaces');
};

const useGetWorkspaces = () => {
  return useQuery(queryKeys.getWorkspaces, () => getWorkspaces());
};

const getWorkspace = (workspaceId: string) => {
  if (!workspaceId.length) {
    return { data: [] } as AxiosResponse;
  }

  return axiosInstance.get(`/workspace?id=${workspaceId}`);
};

const useGetWorkspace = (workspaceId: string) => {
  return useQuery(queryKeys.getWorkspace(workspaceId), () =>
    getWorkspace(workspaceId)
  );
};

export interface ICreateWorkspace {
  Name: string;
}

const createWorkspace = (payload: ICreateWorkspace) => {
  return axiosInstance.post('/workspace', payload);
};

const useCreateWorkspace = () => {
  return useMutation(createWorkspace);
};

export interface IInviteToWorkspace {
  WorkspaceId: string;
  RoleId: string;
  Emails?: string[];
  Email?: string;
  Name?: string;
}

const inviteToWorkspace = (payload: IInviteToWorkspace) => {
  return axiosInstance.post('workspace/member/invite', payload);
};

const useInviteToWorkspace = () => {
  return useMutation(inviteToWorkspace);
};

export interface IRemoveUser {
  Id: string;
}

const removeUser = (payload: IRemoveUser) => {
  return axiosInstance.delete('/workspace/member', {
    data: payload,
  });
};

const useRemoveUser = () => {
  return useMutation(removeUser);
};

const getSwitches = (workspaceId: string) => {
  return axiosInstance.get(`/subaccount/switches?workspaceId=${workspaceId}`);
};

const useGetSwitches = (workspaceId: string) => {
  return useQuery(queryKeys.getSwitches, () => getSwitches(workspaceId));
};

export interface ISwitchUserRequest {
  Id?: string;
}

const switchUser = (payload: ISwitchUserRequest) => {
  return axiosInstance.post('subaccount/switch', payload);
};

const useSwitchUser = () => {
  return useMutation(switchUser);
};

export interface ICreateWorkspaceRole {
  WorkspaceId: string;
  Name: string;
  Privileges: string[];
}

const createWorkspaceRole = (payload: ICreateWorkspaceRole) => {
  return axiosInstance.post('/workspace/role', payload);
};

const useCreateWorkspaceRole = () => {
  return useMutation(createWorkspaceRole);
};

export interface IEditWorkspaceRole {
  Id: string;
  Name: string;
  Privileges: string[];
}

const editWorkspaceRole = (payload: IEditWorkspaceRole) => {
  return axiosInstance.put('/workspace/role', payload);
};

const useEditWorkspaceRole = () => {
  return useMutation(editWorkspaceRole);
};

export interface IDeleteRole {
  Id: string;
}

const deleteRole = (payload: IDeleteRole) => {
  return axiosInstance.delete('/workspace/role', {
    data: payload,
  });
};

const useDeleteRole = () => {
  return useMutation(deleteRole);
};

export interface ICreateStructure {
  WorkspaceId: string;
  Name: string;
  ParentId?: string;
}

const createStructure = (payload: ICreateStructure) => {
  return axiosInstance.post('/workspace/structure/item', payload);
};

const useCreateStructure = () => {
  return useMutation(createStructure);
};

export interface IMoveStructure {
  Id: string;
  ParentId: string;
}

const moveStructure = (payload: IMoveStructure) => {
  return axiosInstance.post('/workspace/structure/item/move', payload);
};

const useMoveStructure = () => {
  return useMutation(moveStructure);
};

export interface IMoveMembershipStructure {
  Id: string;
  ParentId: string;
}

const moveMembershipStructure = (payload: IMoveMembershipStructure) => {
  return axiosInstance.post('/workspace/member/move', payload);
};

const useMoveMembershipStructure = () => {
  return useMutation(moveMembershipStructure);
};

export interface IApproveInvitation {
  Id?: string;
  Key?: string;
}

const approveInvitation = (payload: IApproveInvitation) => {
  return axiosInstance.post('/workspace/invitation/approve', payload);
};

const useApproveInvitation = () => {
  return useMutation(approveInvitation);
};

export interface IRejectInvitation {
  Id?: string;
  Key?: string;
}

const rejectInvitation = (payload: IRejectInvitation) => {
  return axiosInstance.post('/workspace/invitation/reject', payload);
};

const useRejectInvitation = () => {
  return useMutation(rejectInvitation);
};

export interface IWorkspaceSwitch {
  Id: string;
}

const workspaceSwitch = (payload: IWorkspaceSwitch) => {
  return axiosInstance.post('/workspace/switch', payload);
};

const useWorkspaceSwitch = () => {
  return useMutation(workspaceSwitch);
};

export interface IWorkspaceEditMember {
  Id: string;
  RoleId: string;
}

const workspaceEditMember = (payload: IWorkspaceEditMember) => {
  return axiosInstance.put('/workspace/member', payload);
};

const useWorkspaceEditMember = () => {
  return useMutation(workspaceEditMember);
};

const getInvitation = (invitationId: string) => {
  return axiosInstance.get(`/invitation?id=${invitationId}`);
};

const useGetInvitation = (invitationId: string) => {
  return useQuery(queryKeys.getInvitation(invitationId), () =>
    getInvitation(invitationId)
  );
};

export interface IRegister {
  InvitationId?: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Password: string;
  RepeatPassword: string;
  AcceptNotaRules: boolean;
  AcceptRules: boolean;
  ConnectionId?: string;
}

const registerInWorkspace = (payload: IRegister) => {
  return axiosInstance.post('/register', payload);
};

const useRegisterInWorkspace = () => {
  return useMutation(registerInWorkspace);
};

export interface IVerify {
  Token: string;
  Code: string;
}

const verifyEmail = (payload: IVerify) => {
  return axiosInstance.post('/register/verifyEmail', payload);
};

const useVerifyEmail = () => {
  return useMutation(verifyEmail);
};

export default {
  useGetCurrentWorkspace,
  useCreateWorkspace,
  useGetWorkspaces,
  useGetWorkspace,
  useInviteToWorkspace,
  useRemoveUser,
  useCreateWorkspaceRole,
  useEditWorkspaceRole,
  useDeleteRole,
  useCreateStructure,
  useMoveStructure,
  useMoveMembershipStructure,
  useApproveInvitation,
  useRejectInvitation,
  useWorkspaceSwitch,
  useWorkspaceEditMember,
  useGetInvitation,
  useRegisterInWorkspace,
  useVerifyEmail,
  useGetSwitches,
  useSwitchUser,
};
