import { RefObject, createContext } from "react";

export interface ILayoutSizes {
    width: number;
    height: number;
    isDraging: boolean;
}

type ILayoutContext = {
    sizes: ILayoutSizes;
    setSizes: (sizes: ILayoutSizes) => void;
}

const LayoutContext = createContext<ILayoutContext>({
    sizes: {
        width: 0,
        height: 0,
        isDraging: false
    },
    setSizes: () => {}
})

export default LayoutContext;