import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { RefObject, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TextField, TextareaAutosize } from '@mui/material';

const TextArea: FC<Props> = ({
  disabled = false,
  className = '',
  label,
  onChange,
  defaultValue,
  value,
  placeholder,
  onBlur,
  isError = '',
  focus = false,
  minRows = 1,
}) => {
  const height = 40;
  const labelOffset = -4;
  const [text, setText] = useState(defaultValue ?? '');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const resizeTextArea = () => {
    if (!textAreaRef?.current) return;

    const position = textAreaRef.current.getBoundingClientRect();

    if (position.height > 200) {
      return;
    }

    textAreaRef.current.style.height = 'auto';

    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
  };

  useEffect(resizeTextArea, [text]);

  useEffect(() => {
    if (!textAreaRef?.current) return;
    textAreaRef.current.style.height = `52px`;
    textAreaRef.current.style.minHeight = `52px`;
  }, [textAreaRef]);

  const [isFocused, setIsFocused] = useState(false);
  const isText = !!value?.length || !!text.length;
  const isFocusedStyle = isFocused && !isText ? styles.active : '';
  const isFocusedStyle2 = !isFocused && isText ? styles.active : '';
  const isFocusedStyle3 = isFocused && isText ? styles.active : '';

  const handleChoose = () => {
    //@ts-ignore
    textAreaRef?.current?.focus();
  };

  useEffect(() => {
    if (!textAreaRef?.current || !focus) return;
    handleChoose();
  }, [textAreaRef]);

  return (
    <div className={styles.inputWrapper}>
      <TextField
        sx={{
          // Class for the label of the input field
          '&:hover:not(.Mui-focused)': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#5d49c7',
            },
          },
        }}
        multiline
        value={value}
        label={label}
        minRows={minRows}
        onChange={(e) => onChange(e.target.value)}
        variant="filled"
      />
    </div>
  );
};

export default TextArea;
