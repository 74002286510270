import LoadingScreen from 'components/LoadingScreen';
import { FC, Props } from './typings';
import { useContext, useEffect, useState } from 'react';
import { getKeys } from 'utils/getKeys';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { getUserIsActivated } from 'utils/getUserIsActivated';
import InvitationsToWorkspace from 'components/InvitationsToWorkspace';
import workspaceService from 'api/workspaceService';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import { setWorkspaceId } from 'utils/setWorkspaceId';
import { getUserClaim } from 'utils/getUserClaim';
import { Claims } from 'dto/User/claims';
import keysService from 'api/keysService';

const ProtectedRoute: FC<Props> = ({ SignalRContext, children }) => {
  const {
    data: currentWorkspace,
    isLoading,
    isError,
  } = workspaceService.useGetCurrentWorkspace();

  const { data: workspacesData, isLoading: loadingWorkspaces } =
    workspaceService.useGetWorkspaces();

  const {
    data: recoveryPackage,
    isSuccess: isSuccessRecoveryPackage,
    isError: isErrorRecoveryPackage,
  } = keysService.useGetRecoveryPackage();

  const { mutate: switchWorkspace } = workspaceService.useWorkspaceSwitch();

  const { workspace, setWorkspace } = useContext(WorkspaceContext);

  const auth = useAuth();
  const navigate = useNavigate();

  const [invittedToWorkspaces, setInvitedToWorkspaces] = useState([]);
  const [showList, setShowList] = useState(false);

  SignalRContext.useSignalREffect(
    'invitations',
    (data) => {
      setInvitedToWorkspaces(data);
      setShowList(true);
    },
    []
  );

  const initUser = async () => {
    if (auth.user) {
      var userId = getUserId(auth.user);
      var isActivated = getUserIsActivated(auth.user);

      const keys = await getKeys(userId!);

      if (
        isSuccessRecoveryPackage &&
        !keys &&
        window.location.pathname.search('recovery') === -1
      ) {
        navigate('/recovery');
      }

      const userClaim = getUserClaim(auth.user!, Claims.invitation);

      if (!!userClaim?.length && isErrorRecoveryPackage) {
        navigate('/activation');
        return;
      }

      if (!isActivated && !userClaim?.length) {
        navigate('/activation');
      }
    }
  };

  useEffect(() => {
    initUser();
  }, [auth.user, isErrorRecoveryPackage, isSuccessRecoveryPackage]);

  useEffect(() => {
    if (isLoading || loadingWorkspaces) {
      return;
    }

    if (currentWorkspace && !workspace) {
      setWorkspace(currentWorkspace?.data);
      setWorkspaceId(currentWorkspace?.data.Id ?? '');

      return;
    }

    if (isError) {
      const workspaces = workspacesData?.data?.Items;
      const countWorkspaces = workspaces?.length;

      localStorage.removeItem('workspaceId');
      sessionStorage.removeItem('workspaceId');

      if (countWorkspaces === 1) {
        setWorkspace(workspaces[0]);
        setWorkspaceId(workspaces[0].Id);
        // Ustawiamy pierwszy z brzegu

        switchWorkspace({
          Id: workspaces[0].Id,
        });

        return;
      }

      if (countWorkspaces === 0) {
        return;
      }

      // navigate('/choose-workspace');
      return;
    }

    if (!isError) {
      const workspaces = workspacesData?.data?.Items;
      const countWorkspaces = workspaces?.length;

      if (!countWorkspaces) {
        // Brak workspace'a
        localStorage.removeItem('workspaceId');
        sessionStorage.removeItem('workspaceId');
        return;
      }

      if (countWorkspaces === 1) {
        setWorkspace(workspaces[0]);
        setWorkspaceId(workspaces[0].Id);
        // Ustawiamy pierwszy z brzegu

        switchWorkspace({
          Id: workspaces[0].Id,
        });

        return;
      }

      // navigate('/choose-workspace');
      return;
    }
  }, [isError, isLoading, currentWorkspace, loadingWorkspaces]);

  if (isLoading || loadingWorkspaces) {
    return <LoadingScreen />;
  }

  return (
    <>
      {children}
      {showList && (
        <InvitationsToWorkspace
          toggle={() => setShowList(false)}
          workspaces={invittedToWorkspaces}
        />
      )}
    </>
  );
};

export default ProtectedRoute;
