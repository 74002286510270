import styles from './styles.module.scss';
import { FC, Props } from './typings';

import statusService, { ISetStatus } from 'api/statusService';
import { useContext, useEffect, useMemo, useState } from 'react';
import StatusBadge from 'components/StatusBadge';
import { IStatus } from 'dto/IStatus';
import { ReactComponent as EditIcon } from 'assets/icons/mode_edit_24px.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_small.svg';
import Input from 'components/Input';
import Button from 'components/Button';
import HollowButton from 'components/HollowButton';
import PackagesContext from 'contexts/PackagesContext';
import { ItemKind } from 'dto/IKindItems';
import { ListType } from 'dto/ListType';
import CasesContext from 'contexts/CasesContext';
import CaseChatContext from 'contexts/CaseChatContext';
import { ENotificationType, notification } from 'utils/notification';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import { getListType } from 'utils/getTagType';
import { IWithStatus } from 'dto/Common/IWithStatus';

const EditMode = ({
  status,
  toggle,
  handleUpdateStatus,
  handleCreateStatus,
  item,
}: {
  handleUpdateStatus: (
    status: IStatus,
    name: string,
    color: number,
    item: IWithStatus | undefined
  ) => void;
  handleCreateStatus: (
    name: string,
    color: number,
    item: IWithStatus | undefined
  ) => void;
  toggle: (state: boolean) => void;
  status?: IStatus | null;
  item: IWithStatus | undefined;
}) => {
  const colors = [
    { type: 0, color: '#E89F39' },
    { type: 1, color: '#9A48D0' },
    { type: 2, color: '#50A7DE' },
    { type: 3, color: '#F05D5E' },
    { type: 4, color: '#F0E37D' },
    { type: 5, color: '#D9017E' },
    { type: 6, color: '#49C6AB' },
    { type: 7, color: '#BAB26D' },
  ];
  const [name, setName] = useState(status?.Name ?? '');
  const [sColor, setColor] = useState(status?.Color ?? 0);

  return (
    <div className={styles.edit}>
      <Input
        label="Nazwa"
        defaultValue={name}
        onChange={(t) => setName(t)}
        value={name}
      />
      <div className={styles.colors}>
        {colors.map((color) => (
          <div
            key={color.color}
            onClick={() => setColor(color.type)}
            style={{ background: color.color }}
            className={styles.rectangle}
          >
            {sColor === color.type && <CheckIcon className={styles.check} />}
          </div>
        ))}
      </div>
      <div className={styles.buttonsWrapper}>
        <HollowButton
          className={styles.holButton}
          onClick={() => toggle(false)}
          text="Anuluj"
        />
        <Button
          className={styles.smallButton}
          onClick={() => {
            if (status) {
              handleUpdateStatus(status, name, sColor, item);
            } else {
              handleCreateStatus(name, sColor, item);
            }
            toggle(false);
          }}
          text="Zapisz"
        />
      </div>
    </div>
  );
};

const PreviewMode = ({
  onClick,
  status,
}: {
  onClick: () => void;
  status: IStatus;
}) => {
  return (
    <div
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
      className={styles.previewWrapper}
    >
      <div className={styles.previewTitle}>{status.Name}</div>
      <div className={styles.editButton}>
        <EditIcon />
      </div>
    </div>
  );
};

const StatusModal: FC<Props> = ({
  toggle,
  item,
  items,
  selectedStatus,
  afterUpdate,
}) => {
  const [value, setValue] = useState('');
  const [search, setSearch] = useState<IStatus[]>([]);

  const currentWorkspaceId = getWorkspaceId();
  const { data: statusesData, refetch } = statusService.useGetStatuses(
    currentWorkspaceId ?? undefined,
    getListType(item!.Kind)
  );
  const { mutate: updateStatus } = statusService.useUpdateStatus();
  const { mutate: createStatus } = statusService.useCreateStatus();
  const { mutate: setStatus } = statusService.useSetStatus();
  const { mutate: setManyStatus } = statusService.useSetManyStatus();

  const [editMode, setEditMode] = useState(false);
  const [createNew, setCreateNew] = useState(false);

  const [selectedFlag, setSelectedFlag] = useState<IStatus | null>(
    selectedStatus
  );

  const allStatuses: IStatus[] = useMemo(
    () => statusesData?.data ?? [],
    [statusesData]
  );

  const { packages, setPackages } = useContext(PackagesContext);
  const { cases, setCases } = useContext(CasesContext);
  const { casePackages, setCasePackages } = useContext(CaseChatContext);

  useEffect(() => {
    if (!value.length) {
      setSearch(allStatuses);
      return;
    }

    setSearch(
      allStatuses.filter(
        (s: IStatus) =>
          s.Name.toLocaleLowerCase().search(value.toLocaleLowerCase()) !== -1
      )
    );
  }, [allStatuses, value]);

  const handleChangeFlag = (flag: IStatus) => {
    setSelectedFlag(flag);
    const newPacks = [...packages];
    const newCases = [...cases];
    const newCasePacks = [...casePackages];

    if (items) {
      setPackages(
        newPacks.map((a) => {
          if (!!items.find((p) => p.Id === a.Id)) {
            a.StatusId = flag.Id;
            return a;
          }
          return a;
        })
      );

      const itemsToUpdate: ISetStatus[] = items.map((p) => ({
        OwnerId: p.Id,
        OwnerKind: p.Kind,
        StatusId: flag.Id,
      }));

      setManyStatus(
        {
          Items: itemsToUpdate,
          StatusId: flag.Id,
        },
        {
          onSuccess: () => {
            toggle(false);
            if (afterUpdate) {
              afterUpdate(flag);
            }
          },
          onError: () => {
            notification({
              type: ENotificationType.ERROR,
              title: 'Wystąpił błąd',
              text: 'Coś poszło nie tak... spróbuj ponownie później',
            });
          },
        }
      );
    } else if (item) {
      let packItem = newPacks.find(
        (p) => p.Id === item.Id && p.Kind === item.Kind
      );
      let caseItem = newCases.find(
        (c) => c.Id === item.Id && c.Kind === ItemKind.Cases
      );
      let packCaseItem = newCasePacks.find(
        (p) => p.Id === item.Id && p.Kind === item.Kind
      );

      if (packItem) {
        packItem.StatusId = flag.Id;
        setPackages(newPacks);
      }

      if (caseItem) {
        caseItem.StatusId = flag.Id;
        setCases(newCases);
      }

      if (packCaseItem) {
        packCaseItem.StatusId = flag.Id;
        setCasePackages(newCasePacks);
      }

      setStatus(
        {
          OwnerId: item.Id,
          OwnerKind: item.Kind,
          StatusId: flag.Id,
        },
        {
          onSuccess: () => {
            toggle(false);
            if (afterUpdate) {
              afterUpdate(flag);
            }
          },
          onError: () => {
            notification({
              type: ENotificationType.ERROR,
              title: 'Wystąpił błąd',
              text: 'Coś poszło nie tak... spróbuj ponownie później',
            });
          },
        }
      );
    }
  };

  const getNewStatusListType = (item: IWithStatus | undefined) => {
    if (!item) {
      return ListType.Incoming;
    }
    switch (item.Kind) {
      case ItemKind.Cases:
        return ListType.Cases;
      case ItemKind.Task:
        return ListType.Tasks;
      default:
        return ListType.Incoming;
    }
  };

  const handleCreateStatus = (
    name: string,
    color: number,
    item: IWithStatus | undefined
  ) => {
    createStatus(
      {
        Color: color,
        Name: name,
        Type: getNewStatusListType(item),
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  const handleUpdateStatus = (status: IStatus, name: string, color: number) => {
    updateStatus(
      {
        Id: status.Id,
        Name: name,
        Color: color,
        Type: status.Type,
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Input
            focus
            placeholder="Szukaj"
            onChange={(text) => setValue(text)}
            label="Szukaj"
          />
          <div className={styles.flagsWrapper}>
            {search.map((status: IStatus) => (
              <div
                key={status.Id}
                onClick={(e) => {
                  handleChangeFlag(status);
                  e.stopPropagation();
                }}
                className={`${styles.flag} ${
                  selectedFlag?.Id === status.Id ? styles.active : ''
                }`}
              >
                <StatusBadge onRemove={() => {}} status={status} />{' '}
                {status.Name}
              </div>
            ))}
          </div>
        </div>
        {/* {selectedFlag && (
          <div className={styles.editor}>
            {editMode && item ? (
              <EditMode
                toggle={setEditMode}
                handleCreateStatus={handleCreateStatus}
                handleUpdateStatus={handleUpdateStatus}
                status={!createNew ? selectedFlag : null}
                item={item}
              />
            ) : (
              <PreviewMode
                onClick={() => {
                  setCreateNew(false);
                  setEditMode(true);
                }}
                status={selectedFlag}
              />
            )}
          </div>
        )} */}
        {/* {editMode ? (
          <EditMode
            toggle={setEditMode}
            handleCreateStatus={handleCreateStatus}
            handleUpdateStatus={handleUpdateStatus}
            status={!createNew ? selectedFlag : null}
            item={item ?? undefined}
          />
        ) : (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              onClick={() => {
                setCreateNew(true);
                setEditMode(true);
              }}
              text="Dodaj status"
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default StatusModal;
