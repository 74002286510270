import DossierFilterContext from 'contexts/DossierFilters';
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import casesService from 'api/casesService';
import dossierService, { IDossierAddFile } from 'api/dossierService';
import fileService from 'api/fileService';
import tagsService from 'api/tagsService';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copyd.svg';
import { ReactComponent as CutIcon } from 'assets/icons/cut-new.svg';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';
import { ReactComponent as StatusIcon } from 'assets/icons/flag_border.svg';
import { ReactComponent as SignIcon } from 'assets/icons/sign.svg';
import { ReactComponent as SignatureIcon } from 'assets/icons/signature.svg';
import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/logout.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import AddTagModal from 'components/AddTagModal';
import ConfirmModal from 'components/ConfirmModal';
import EditDossier from 'components/EditDossier';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import TagBadge from 'components/TagBadge';
import DossierFilesContext from 'contexts/DossierFilesContext';
import KeysContext from 'contexts/KeysContext';
import { DossierItemType } from 'dto/Dossier/DossierItemType';
import { IDossierFakeItem } from 'dto/Dossier/IDossierFakeItem';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { ItemKind } from 'dto/IKindItems';
import { IPackage } from 'dto/IPackage';
import { ITag } from 'dto/ITag';
import { useAuth } from 'react-oidc-context';
import downloadFile from 'utils/downloadFile';
import { getUserId } from 'utils/getUserId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import MenuContext from 'contexts/MenuContext';
import AttachmentIconComponent from 'components/AttachmentIconComponent';
import { useOnClickOutside } from 'usehooks-ts';
import CasesContext from 'contexts/CasesContext';
import pluginService from 'api/pluginService';
import CertContext from 'contexts/CertContext';
import { IStatus } from 'dto/IStatus';
import StatusBadge from 'components/StatusBadge';
import { createDossierObject } from 'components/CaseDossier/createDossierObject';
import { getBlob } from 'utils/getBlob';
import { getFile as GetFile } from 'utils/getFile';
import { decryptFile } from 'utils/decryptFile';
import { handleCreate } from 'utils/uploadFile';
import { axiosInstance } from 'api/axios';
import HistoryModal from 'components/HistoryModal';
import ChooseTagNew from 'components/ChooseTagNew';
import StatusModal from 'components/StatusModal';
import StatusModalLarge from 'components/StatusModalLarge';
import DossierMoveModal from 'components/DossierMoveModal';
import DossierCreateVersionModal from 'components/DossierCreateVersionModal';
import {
  getDossierItemFileName,
  getDossierItemRealFileName,
} from 'utils/getDossierItemFileName';
import PackageSharesModal from 'components/PackageSharesModal';
import PackageEditSharesModal from 'components/PackageEditSharesModal';
import { addCopyToFileName } from 'utils/addCopyToFileName';

const DossierFile: FC<Props> = ({
  selectedFiles,
  canSelect,
  onSelect,
  file,
  statuses,
  tags,
  index,
  refetch: refetchAll,
  cutting,
  onClick,
  showTypeFile,
  isSelected: selectedC,
  hideBytes = false,
  inFolder = false,
  setSelectedFiles,
  handleCopy,
  encryptionKeys = [],
  parentKeys = [],
  files,
  setFiles,
  handleFileDoubleClick,
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const navigate = useNavigate();
  const { caseId, view, kind, messageId, attachmentId } = useParams();
  const [showHistory, setShowHistory] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { mutate: getFile } = fileService.useGetFiltersForCases();
  const { mutate: removeTag } = tagsService.useRemoveTagFromPackage();
  const [showCreateAction, setShowCreateAction] = useState(false);
  const { setCert } = useContext(CertContext);
  // const { data: caseData } = casesService.useGetCase(caseId!);

  const { cases } = useContext(CasesContext);

  const Case = cases.find((c) => c.Id === caseId);

  const { mutate: openEditor } = pluginService.useOpenEditor();

  const canEdit =
    auth?.user?.profile?.role === 'guest' ? Case?.Privileges === 2 : true;

  const { id, setId } = useContext(MenuContext);

  useEffect(() => {
    if (id !== file.Id) {
      setShowMenu(false);
    }
  }, [id]);

  const handleClick = () => {
    onSelect?.(file);
  };

  const handleDoubleClick = () => {
    if (canSelect) {
      onSelect?.(file);
      return;
    }

    handleFileDoubleClick(file);
  };

  const { menu } = useContext(DossierFilterContext);

  const [status, setStatus] = useState<IStatus | null>(null);
  const [showTags, setShowTags] = useState(false);
  const [showTagsSmall, setShowTagsSmall] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showStatusLarge, setShowStatusLarge] = useState(false);
  const [showMove, setShowMove] = useState(false);
  const [showCreateVersion, setShowCreateVersion] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const refWrapper = useRef(null);
  const refTag = useRef(null);
  const refStatus = useRef(null);
  const refHistory = useRef(null);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);

  const { mutate: deleteDossier } = dossierService.useDeleteDossier();

  useEffect(() => {
    const t = tags?.filter((tag) =>
      file?.TagIds?.find((iTag) => tag.Id === iTag)
    );
    const newStatus = statuses.find((s) => s.Id === file.StatusId);

    setFindedTags(t);
    setStatus(newStatus ?? null);
  }, [statuses, file?.TagIds]);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        OwnerId: file.Id,
        OwnerKind: file.Kind,
        TagId: tag.Id,
      },
      {
        onSuccess: () => {
          setFindedTags(findedTags.filter((ftag) => ftag.Id !== tag.Id));
        },
      }
    );
  };

  const { keys } = useContext(KeysContext);

  const getContent = (type: string) => {
    switch (type) {
    }
  };

  const onDownloadClick = async () => {
    const fi = file as IDossierFakeItem;
    //@ts-ignore
    const isEmail = file.S3;

    getFile(
      {
        //@ts-ignore
        id: isEmail ? file.S3 : fi.Attachment.Uri ? fi.Attachment.Uri : file.Id,
        ownerKind: fi.Kind,
      },
      {
        onSuccess: async (data) => {
          //@ts-ignore
          const fileName = isEmail ? fi.S1 : fi.Attachment.FileName;

          const key = keys.find((key: any) => key?.Id === fi.EncryptionKeyId);

          if (!key) {
            console.log('Key not found', file);
            return;
          }

          let parentKey = null;
          //@ts-ignore
          if (key.ParentKeyId) {
            parentKey = parentKeys.find(
              //@ts-ignore
              (pkey: any) => pkey.Id === key.ParentKeyId
            );
          }
          await downloadFile(userId, fileName, data.data.Url, key, parentKey);
        },
      }
    );
  };

  const onDeleteClick = () => {
    deleteDossier(
      {
        Id: file.DossierId,
      },
      {
        onSuccess: () => refetchAll(),
      }
    );
  };

  const { dossierFiles, setDossierFiles } = useContext(DossierFilesContext);

  const handleUpdateTags = (tags: ITag[]) => {
    let files = [...dossierFiles];

    let el = files.find((c) => c.Id === file.Id && c.Kind === file.Kind);

    if (el) {
      el.TagIds = tags.map((t) => t.Id);
    }
  };

  const isSelected = !!selectedFiles?.find(
    (s) => s?.DossierId === file?.DossierId
  );

  // useEffect(() => {
  //   if (isSelected && refWrapper.current) {
  //     refWrapper.current!.scrollIntoView();
  //   }
  // }, [isSelected]);

  const [isSameCase, setIsSameCase] = useState(false);
  const [isFindedElement, setIsFindedElement] = useState(false);

  useEffect(() => {
    const copyElements = JSON.parse(
      sessionStorage.getItem('copy_elements') ?? '[]'
    );
    const copyCase = JSON.parse(sessionStorage.getItem('copy_case') ?? '[]');

    const cut = Boolean(sessionStorage.getItem('copy_cut'));

    setIsSameCase(copyCase?.Id === caseId);
    setIsFindedElement(!!copyElements.find((copy: any) => copy.Id === file.Id));
  }, [caseId, selectedFiles, cutting]);

  const [editLabel, setEditLabel] = useState(false);

  const [mouseIs, setMouseIs] = useState(false);

  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    setShowMenu(false);
  });

  const [copy, setCopy] = useState(false);
  const [cut, setCut] = useState(false);

  const handleButton = (e: any) => {
    console.log(e);

    if (e.key === 'F2') {
      setEditLabel(true);
    } else if (e.key === 'Delete') {
      handleDelete(file);
    }
  };

  const { mutate: addFileToDossier } = dossierService.useAddFile();

  const onDownloadXades = async () => {
    const fileBlob = await GetFile(file?.SignedXadesUri ?? '');
    const blob = await getBlob(fileBlob.data.Url);

    const URL = window.URL.createObjectURL(new Blob([blob.data]));
    const link = document.createElement('a');

    link.href = URL;
    link.setAttribute('download', 'xades.xml');
    document.body.appendChild(link);
    link.click();
  };

  const handleEdit = async (e: IDossierItem) => {
    openEditor({
      Id: e.Id,
      Kind: e.Kind,
    });
    setShowMenu(false);
  };

  const handelEditAsCopy = async (e: IDossierItem) => {
    const newDossierElements = [...files];

    if (file.CanEditAsCopy) {
      newDossierElements.push(
        createDossierObject(
          file.Id,
          file.FileName,
          file.FileContentType,
          file.FileSize,
          file.ParentId,
          file.Label,
          file.Type
        )
      );
      setFiles(newDossierElements);

      const fileBlob = await GetFile(file?.FileUri ?? '');
      const blob = await getBlob(fileBlob.data.Url);

      const key = keys.find((key: any) => key?.Id === file?.EncryptionKeyId);

      if (!key) return;

      let parentKey = null;

      if (key.ParentKeyId) {
        parentKey = keys.find(
          //@ts-ignore
          (pkey: any) => pkey?.Id === key?.ParentKeyId
        );
      }

      const decryptedFile = await decryptFile(
        userId ?? '',
        new Blob([blob.data]),
        key,
        parentKey
      );

      const respo = await axiosInstance.get(`/case/keys?Id=${caseId}`);

      const pKey = respo.data[0].PublicKey;

      const r = await handleCreate(
        [decryptedFile],
        Case!.Id,
        pKey,
        true,
        true,
        addCopyToFileName(file.FileName ?? ''),
        file.FileContentType,
        (progress) => {}
      );

      if (!r) {
        return;
      }

      let payload: IDossierAddFile = {
        CaseId: Case!.Id!,
        SourceId: file.DossierId,
        Files: [
          {
            KeyId: r[0].keyId,
            Uri: r[0].uri,
          },
        ],
      };
      addFileToDossier(payload, {
        onSuccess: (response) => {
          refetchAll();
          openEditor({
            Id: response.data.Items[0].Id,
            Kind: response.data.Items[0].Kind,
          });
        },
      });

      return;
    }
  };

  const handleDelete = (e: IDossierItem) => {
    const ask = localStorage.getItem('ask');

    if (ask === 'true') {
      onDeleteClick();
      return;
    }

    setShowDelete(true);
  };

  const handleSign = (e: IDossierItem) => {
    setCert({ id: e.Id, name: e.FileName ?? '' });
    navigate(
      `/cases/${caseId}/letter/${e.Kind}/${e.Id}/${
        e.SignedPdfUri ?? e.FileUri
      }/${e.EncryptionKeyId}`
    );
  };

  useEffect(() => {
    if (!mouseIs) return;
    window.addEventListener('keydown', handleButton);

    return () => {
      window.removeEventListener('keydown', handleButton);
    };
  }, [mouseIs]);

  useEffect(() => {
    if (!selectedFiles?.length) return;
    if (copy) {
      handleCopy(false);
      setCopy(false);
      return;
    }
    if (cut) {
      handleCopy(true);
      setCut(false);
      return;
    }
  }, [selectedFiles, copy, cut]);

  const handleCreateVersion = (file: IDossierItem) => {
    setShowCreateVersion(true);
    setShowMenu(false);
  };

  const isSigned = !!file?.SignedPdfUri || !!file?.SignedXadesUri;

  return (
    <>
      <Draggable
        index={index}
        key={file.DossierId}
        draggableId={file?.DossierId}
      >
        {(provided, snapshot) => {
          //@ts-ignore
          provided.draggableProps.style = {
            ...provided.draggableProps.style,
            //@ts-ignore
            cursor: 'pointer',
          };
          return (
            <div
              onMouseEnter={() => setMouseIs(true)}
              onMouseLeave={() => setMouseIs(false)}
              onContextMenu={(e: any) => {
                e.preventDefault();
                setShowMenu(true);
              }}
              className={`${styles.wrapper} ${selectedC && styles.active} ${
                isSelected && !copy && styles.active
              }`}
              ref={refWrapper}
            >
              {mouseIs && (
                <div className={styles.grip}>
                  <FontAwesomeIcon icon={faGripVertical} />
                </div>
              )}

              {showTags && (
                <ModalLayout
                  dock={DockPosition.DISABLED}
                  off={true}
                  customX={1}
                  isAnimation={true}
                  customY={0}
                  ignore
                  fullHeight={true}
                  toggle={() => setShowTags(!showTags)}
                >
                  <AddTagModal
                    toggle={setShowTags}
                    updateTags={handleUpdateTags}
                    //@ts-ignore
                    toTag={file as IPackage}
                  />
                </ModalLayout>
              )}

              {showTagsSmall && (
                <ModalLayout
                  dock={DockPosition.BottomRight}
                  refWrapper={refTag}
                  onEnd={() => {
                    setShowTags(false);
                  }}
                  disableMove
                  toggle={() => setShowTagsSmall(!showTagsSmall)}
                >
                  {/* <AddTagModal
                  toggle={() => {
                    setShowTags(false);
                    toggle?.();
                  }}
                  updateTags={handleUpdateTags}
                  toTag={pack}
                /> */}
                  <div className={styles.tagModal}>
                    <ChooseTagNew
                      toggle={setShowTagsSmall}
                      updateTags={handleUpdateTags}
                      //@ts-ignore
                      toTag={file as IPackage}
                    />
                  </div>
                </ModalLayout>
              )}

              {showStatus && (
                <ModalLayout
                  dock={DockPosition.DISABLED}
                  customX={0}
                  customY={-32}
                  refWrapper={refStatus}
                  toggle={() => setShowStatus(!showStatus)}
                >
                  <StatusModal
                    item={file}
                    toggle={setShowStatus}
                    selectedStatus={status}
                  />
                </ModalLayout>
              )}

              {showStatusLarge && (
                <ModalLayout
                  dock={DockPosition.DISABLED}
                  off={true}
                  customX={1}
                  isAnimation={true}
                  customY={0}
                  ignore
                  fullHeight={true}
                >
                  <StatusModalLarge
                    toggle={setShowStatusLarge}
                    item={file}
                    selectedStatus={status}
                  />
                </ModalLayout>
              )}

              {showMove && (
                <ModalLayout
                  dock={DockPosition.DISABLED}
                  off={true}
                  customX={1}
                  isAnimation={true}
                  customY={0}
                  ignore
                  fullHeight={true}
                >
                  <DossierMoveModal
                    toggle={setShowMove}
                    sourceCaseId={caseId ?? ''}
                    item={file}
                    refetch={refetchAll}
                  />
                </ModalLayout>
              )}

              {showCreateVersion && (
                <ModalLayout
                  dock={DockPosition.DISABLED}
                  off={true}
                  customX={1}
                  isAnimation={true}
                  customY={0}
                  ignore
                  fullHeight={true}
                >
                  <DossierCreateVersionModal
                    toggle={setShowCreateVersion}
                    item={file}
                    refetch={refetchAll}
                  />
                </ModalLayout>
              )}

              {showShare && (
                <ModalLayout
                  dock={DockPosition.DISABLED}
                  off={true}
                  customX={1}
                  isAnimation={true}
                  customY={0}
                  ignore
                  fullHeight={true}
                >
                  <PackageEditSharesModal
                    toggle={() => setShowShare(false)}
                    item={file}
                    refetch={refetchAll}
                  />
                </ModalLayout>
              )}

              <div
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                className={`${styles.file} ${inFolder && styles.folder} ${
                  isSameCase && isFindedElement && cutting && styles.cut
                }`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div
                  className={`${styles.fileName} ${
                    file?.Type === DossierItemType.Link && styles.link
                  }`}
                >
                  {file?.Type !== DossierItemType.Link && (
                    <AttachmentIconComponent
                      className={styles.iconPDF}
                      file={getDossierItemRealFileName(file)}
                    />
                  )}
                  <div className={styles.name}>
                    {getDossierItemFileName(file)}
                  </div>
                </div>
                <div className={styles.information}>
                  {menu.map((m) => (
                    <div className={styles.box}>
                      {
                        <>
                          {m.type === 'size' &&
                            !hideBytes &&
                            (file.Kind != ItemKind.DossierLink
                              ? prettyBytes(file.FileSize ?? 0)
                              : '')}
                          {m.type === 'date' &&
                            file.LastModified &&
                            moment(file.LastModified)
                              .format('DD.MM.YYYY')
                              .toString()}
                          {m.type === 'status' && (
                            <div className={styles.status}>
                              {status && (
                                <div
                                  ref={refStatus}
                                  className={styles.badge}
                                  onClick={(e) => {
                                    setShowStatus(!showStatus);
                                    e.stopPropagation();
                                  }}
                                >
                                  <StatusBadge
                                    status={status}
                                    showName={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                          {m.type === 'tag' && (
                            <div className={styles.tags} ref={refTag}>
                              {findedTags.map((tag) => (
                                <div
                                  onClick={() =>
                                    setShowTagsSmall(!showTagsSmall)
                                  }
                                >
                                  <TagBadge
                                    onRemove={(tag) => handleRemoveTag(tag)}
                                    tag={tag}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      }
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.icons}>
                {(file.VersionsCount ?? 0) > 0 && !isSigned && (
                  <HistoryIcon
                    ref={refHistory}
                    onClick={() => {
                      setShowHistory(!showHistory);
                    }}
                  />
                )}
                {isSigned && (
                  <SignatureIcon
                    className={styles.signatureIcon}
                    ref={refHistory}
                    onClick={() => {
                      setShowHistory(!showHistory);
                    }}
                  />
                )}
                {file.IsShared && (
                  <ShareIcon
                    className={styles.shareIcon}
                    ref={refHistory}
                    onClick={() => {
                      setShowShare(!showShare);
                    }}
                  />
                )}
              </div>
              <div className={styles.hover}>
                <FontAwesomeIcon
                  onClick={() => {
                    setShowMenu(!showMenu);
                    setId(file.Id);
                  }}
                  icon={faEllipsisVertical}
                  className={styles.menuIcon}
                />

                {showMenu && !canSelect && (
                  <div ref={dropdownRef} className={styles.dropdown}>
                    {file.CanEdit && (selectedFiles?.length ?? 0) < 2 && (
                      <div
                        onClick={() => {
                          handleEdit(file);
                        }}
                        className={styles.dropdownItem}
                      >
                        <EditIcon title="Edytuj" />
                        Edytuj
                      </div>
                    )}

                    {file.Kind === ItemKind.CaseFile &&
                      canEdit &&
                      (selectedFiles?.length ?? 0) < 2 && (
                        <div
                          onClick={(e) => {
                            setShowStatusLarge(!showStatusLarge);
                            e.stopPropagation();
                          }}
                          className={styles.dropdownItem}
                        >
                          <StatusIcon title="Status" />
                          Status
                        </div>
                      )}

                    {(selectedFiles?.length ?? 0) < 2 && (
                      <div
                        onClick={(e) => {
                          setShowTags(!showTags);
                          e.stopPropagation();
                        }}
                        className={styles.dropdownItem}
                      >
                        <LabelIcon title="Etykieta" />
                        Etykieta
                      </div>
                    )}
                    <div className={styles.line}></div>

                    {file.Type === DossierItemType.Attachment &&
                      (selectedFiles?.length ?? 0) < 2 && (
                        <div
                          onClick={onDownloadClick}
                          className={styles.dropdownItem}
                        >
                          <DownloadIcon title="Pobierz" />
                          Pobierz
                        </div>
                      )}
                    {file.SignedXadesUri && (
                      <div
                        onClick={onDownloadXades}
                        className={styles.dropdownItem}
                      >
                        <DownloadIcon title="Pobierz Xades" />
                        Pobierz xades
                      </div>
                    )}

                    {canEdit && (selectedFiles?.length ?? 0) < 2 && (
                      <div
                        onClick={() => {
                          setEditLabel(!editLabel);
                        }}
                        className={styles.dropdownItem}
                      >
                        <EditIcon title="Zmień nazwę" />
                        Zmień nazwę
                      </div>
                    )}
                    {file.CanEditAsCopy && (selectedFiles?.length ?? 0) < 2 && (
                      <div
                        onClick={() => {
                          handleEdit(file);
                        }}
                        className={styles.dropdownItem}
                      >
                        <CopyIcon title="Utwórz kopię" />
                        Utwórz kopię
                      </div>
                    )}
                    {file.CanEditAsCopy && (selectedFiles?.length ?? 0) < 2 && (
                      <div
                        onClick={() => {
                          handleCreateVersion(file);
                        }}
                        className={styles.dropdownItem}
                      >
                        <CopyIcon title="Utwórz wersję" />
                        Utwórz wersję
                      </div>
                    )}

                    {/* <div
                      onClick={() => {
                        setSelectedFiles([file]);
                        setCopy(true);
                        setCut(false);
                      }}
                      className={styles.box}
                    >
                      <CopyIcon />
                      Kopiuj
                    </div>
                    {canEdit && (
                      <div
                        onClick={() => {
                          setSelectedFiles([file]);
                          setCut(true);
                          setCopy(false);
                        }}
                        className={styles.box}
                      >
                        <CutIcon />
                        Wytnij
                      </div>
                    )} */}

                    {/* {file.Dossier?.Type === DossierItemType.Attachment &&
                      (selectedFiles?.length ?? 0) < 1 &&
                      canEdit && (
                        <div className={styles.box}>
                          <CalendarIcon title="Czynności" />
                          Kalendarz
                        </div>
                      )} */}

                    <div className={styles.line}></div>
                    <div
                      className={styles.dropdownItem}
                      onClick={() => {
                        setShowMove(true);
                        setShowMenu(false);
                      }}
                    >
                      <MoveIcon title="Przenieś" />
                      Przenieś
                    </div>
                    <div
                      className={styles.dropdownItem}
                      onClick={() => {
                        setShowShare(true);
                        setShowMenu(false);
                      }}
                    >
                      <ShareIcon title="Udostępnij" />
                      Udostępnij
                    </div>

                    <div className={styles.line}></div>
                    {canEdit && (selectedFiles?.length ?? 0) < 1 && (
                      <div
                        onClick={() => {
                          handleSign(file);
                        }}
                        className={styles.dropdownItem}
                      >
                        <SignIcon title="Podpisz" />
                        Podpisz
                      </div>
                    )}
                    {canEdit && (selectedFiles?.length ?? 0) < 1 && (
                      <div
                        onClick={() => handleDelete(file)}
                        className={styles.dropdownItem}
                      >
                        <BinIcon title="Usuń" />
                        Usuń
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.progressBar}>
                <div
                  style={{ width: `${file?.progress ?? 0}%` }}
                  className={styles.progress}
                ></div>
              </div>
            </div>
          );
        }}
      </Draggable>
      {editLabel && (
        <ModalLayout
          dock={DockPosition.DISABLED}
          off={true}
          customX={1}
          isAnimation={true}
          customY={0}
          ignore
          fullHeight={true}
          refWrapper={refWrapper}
          toggle={() => setEditLabel(!editLabel)}
        >
          <EditDossier toggle={setEditLabel} refetch={refetchAll} file={file} />
        </ModalLayout>
      )}

      {showHistory && (
        <ModalLayout
          // toggle={() => setShowVersion(false)}
          dock={DockPosition.DISABLED}
          customX={-200}
          customY={-30}
          refWrapper={refHistory}
        >
          <HistoryModal
            pack={file as any}
            toggle={() => setShowHistory(false)}
          />
          {/* <></> */}
        </ModalLayout>
      )}

      {showDelete && (
        <ConfirmModal
          title="Usunąć?"
          text="Czy na pewno chcesz usnąć wybrany element?"
          acceptLabel="Usuń"
          cancelLabel="Anuluj"
          onAccept={() => {
            setShowDelete(false);
            onDeleteClick();
          }}
          onCancel={() => setShowDelete(false)}
        />
      )}
    </>
  );
};

export default DossierFile;
