import styles from './styles.module.scss';

import { ReactComponent as HardDriveIcon } from 'assets/icons/hard_drive.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/correspondence2.svg';
import { ReactComponent as CasesIcon } from 'assets/icons/case.svg';
import { ReactComponent as LettersIcon } from 'assets/icons/letters.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings_24px.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { ECaseViews } from 'components/CaseView/dto/ECaseViews';

import { ReactComponent as DayIcon } from 'assets/icons/calendar/day.svg';
import { ReactComponent as WeekIcon } from 'assets/icons/calendar/week.svg';
import { ReactComponent as MonthIcon } from 'assets/icons/calendar/month.svg';
import { ReactComponent as ListIcon } from 'assets/icons/calendar/list.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task.svg';

import Dropdown from 'components/Dropdown';
import ReactDOM from 'react-dom';
import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { Props } from './typings';
import classNames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

interface IList {
  name: string;
  value: string | number | null;
}

const Options = ({
  defaultValue,
  onChange,
  title,
  divRef,
  list,
  toggle,
}: {
  defaultValue?: string | number;
  onChange: (list: IList) => void;
  title: string;
  divRef: RefObject<HTMLDivElement>;
  list: IList[];
  toggle: () => void;
}) => {
  const ref = useRef(null);
  const position = divRef.current?.getBoundingClientRect();

  const x = position?.x ?? 0;
  const y = (position?.y ?? 0) + 40;

  useOnClickOutside(ref, () => {
    toggle();
  });

  return ReactDOM.createPortal(
    <div
      ref={ref}
      style={{
        left: `${x}px`,
        top: `${y}px`,
      }}
      className={styles.dropdown}
    >
      <div
        onClick={() => {
          onChange({
            name: 'Dzień',
            value: 'dayGridDay',
          });
        }}
        className={styles.boxe}
      >
        <DayIcon /> Dzień
      </div>
      <div
        onClick={() => {
          onChange({
            name: 'Tydzień',
            value: 'dayGridWeek',
          });
        }}
        className={styles.boxe}
      >
        <WeekIcon /> Tydzień
      </div>
      <div
        onClick={() => {
          onChange({
            name: 'Miesiąc',
            value: 'dayGridMonth',
          });
        }}
        className={styles.boxe}
      >
        <MonthIcon /> Miesiąc
      </div>
      <div
        onClick={() => {
          onChange({
            name: 'Lista',
            value: 'list',
          });
        }}
        className={styles.boxe}
      >
        <ListIcon /> Lista
      </div>
      <div
        onClick={() => {
          onChange({
            name: 'Zadania',
            value: 'tasks',
          });
        }}
        className={styles.boxe}
      >
        <TaskIcon /> Zadania
      </div>
    </div>,
    document.body
  );
};

const DropdownSort = ({
  divRef,
  toggle,
  sort,
  setSort,
}: {
  divRef: RefObject<HTMLDivElement>;
  toggle: () => void;
  sort: string;
  setSort: (value: string) => void;
}) => {
  const ref = useRef(null);
  const position = divRef.current?.getBoundingClientRect();

  const x = position?.x ?? 0;
  const y = (position?.y ?? 0) + 60;

  useOnClickOutside(ref, () => {
    toggle();
  });

  return ReactDOM.createPortal(
    <div
      ref={ref}
      style={{
        width: '170px',
        left: `${x}px`,
        top: `${y}px`,
      }}
      className={styles.dropdown}
    >
      <div
        onClick={() => {
          setSort('Ascending');
          localStorage.setItem('chatSort', 'Ascending');
        }}
        className={styles.boxe}
      >
        Rosnąco
      </div>
      <div
        onClick={() => {
          setSort('Descending');

          localStorage.setItem('chatSort', 'Descending');
        }}
        className={styles.boxe}
      >
        Malejąco
      </div>
    </div>,
    document.body
  );
};

const CaseMenu: FC<Props> = ({
  isPackages = false,
  onClick,
  action,
  onChangeAction,
  hideMenu = false,
  sort,
  setSort,
  Case,
}) => {
  const { caseId, view, kind, messageId, attachmentId } = useParams();
  const navigate = useNavigate();
  const handleNavigate = (type: ECaseViews) => {
    if (onClick) {
      onClick(type);
      return;
    }

    if (!!kind?.length) {
      navigate(
        `${
          hideMenu ? '/fullscreen' : ''
        }/cases/${caseId}/${type}/${kind}/${messageId}/${attachmentId}`
      );
      return;
    }

    navigate(`${hideMenu ? '/fullscreen' : ''}/cases/${caseId}/${type}`);
  };

  useEffect(() => {
    localStorage.setItem('last_view_case', view ?? 'chat');
  }, [view]);

  const ref = useRef(null);
  const opt = useRef(null);

  const [showOptionsAction, setShowOptionsAction] = useState(false);
  const [showOpt, setShowOpt] = useState(false);
  return (
    <div className={styles.menu}>
      {Case?.FromPI && (
        <>
          <div
            onClick={() => handleNavigate(ECaseViews.PACKAGES)}
            className={classNames(
              styles.box,
              view == ECaseViews.PACKAGES ? styles.selected : ''
            )}
          >
            <div className={styles.innerBox}>
              <CasesIcon />
              Akta PI
            </div>
            <div className={styles.active}></div>
          </div>
          <div className={styles.splitter}></div>
        </>
      )}

      <div
        ref={opt}
        onClick={() => handleNavigate(ECaseViews.CHAT)}
        className={classNames(
          styles.box,
          view == ECaseViews.CHAT ? styles.selected : ''
        )}
      >
        <div className={styles.innerBox}>
          <ChatIcon />
          Korespondencja
          <div className={styles.settings} onClick={() => setShowOpt(!showOpt)}>
            <SettingsIcon />
            {showOpt && (
              <DropdownSort
                toggle={() => setShowOpt(false)}
                sort={sort}
                setSort={setSort}
                divRef={opt}
              />
            )}
          </div>
        </div>
        <div className={styles.active}></div>
      </div>
      <div className={styles.splitter}></div>
      <div
        onClick={() => handleNavigate(ECaseViews.DOSSIER)}
        className={classNames(
          styles.box,
          view == ECaseViews.DOSSIER ? styles.selected : ''
        )}
      >
        <div className={styles.innerBox}>
          <HardDriveIcon />
          Dysk
        </div>
        <div className={styles.active}></div>
      </div>
      <div className={styles.splitter}></div>
      <div
        ref={ref}
        className={classNames(
          styles.box,
          view == ECaseViews.ACTIONS ? styles.selected : ''
        )}
      >
        <div
          onClick={() => handleNavigate(ECaseViews.ACTIONS)}
          className={styles.title}
        >
          <div className={styles.innerBox}>
            <CalendarIcon />
            Czynności
            <div
              className={styles.settings}
              onClick={() => setShowOptionsAction(!showOptionsAction)}
            >
              <SettingsIcon />
            </div>
          </div>
        </div>
        <div className={styles.optionsWrapper}>
          {showOptionsAction && (
            <Options
              toggle={() => {
                setShowOptionsAction(false);
              }}
              defaultValue={action}
              onChange={(list) => {
                setShowOptionsAction(false);
                onChangeAction(
                  list.value as
                    | 'dayGridWeek'
                    | 'dayGridMonth'
                    | 'dayGridDay'
                    | 'listWeek'
                    | 'list'
                );
              }}
              title="Wybór widoku"
              list={[
                { name: 'Dzień', value: 'dayGridDay' },
                { name: 'Tydzień', value: 'dayGridWeek' },
                { name: 'Miesiąc', value: 'dayGridMonth' },
                { name: 'Lista', value: 'list' },
                { name: 'Zadania', value: 'tasks' },
              ]}
              divRef={ref}
            />
          )}
        </div>
        <div className={styles.active}></div>
      </div>
    </div>
  );
};

export default CaseMenu;
