import { IData } from 'components/EditClientModalNew';
import { ETabs as TAB } from 'components/EditClientModalNew/typings';
import { ICase } from 'dto/Cases/ICase';

export type { FC } from 'react';

export enum ETabs {
    ADDRESSES,
    PERSONS,
    NOTE,
    NUMBERS,
    CREATE_CASE,
    CALENDAR,
    CREATE_CLIENT
}

export interface Props {
  data?: IData
  setData?: (data: IData) => void;
  view?: TAB;

  showActionModal?: boolean;
  showCase?: boolean;
  component?: JSX.Element;
  onCreateCase?: (c: ICase) => void;

  showClient?: boolean;
  onCreateClient?: (entity: any) => void;
  isCompany?: boolean;
}