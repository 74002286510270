import { ItemKind } from 'dto/IKindItems';
import { IParcelTypes } from 'dto/IParcelTypes';

export const translateToPolish = (parcelType: IParcelTypes) => {
  switch (parcelType) {
    case IParcelTypes.EDelivery:
      return 'e-doręczenie';
    case IParcelTypes.Email:
      return 'e-mail';
    case IParcelTypes.EncryptedEDelivery:
      return 'e-doręczenie';
    case IParcelTypes.NotaEncrypted:
      return 'przesyłka szyfrowana';
    case IParcelTypes.TraditionalLetter:
      return 'papierowa';
  }
};

export const convertParcelTypeToKind = (parcel: IParcelTypes) => {
  switch (parcel) {
    case IParcelTypes.EDelivery:
      return 1;
    case IParcelTypes.Email:
      return 8;
    case IParcelTypes.EncryptedEDelivery:
      return 2;
    case IParcelTypes.NotaEncrypted:
      return 4;
    case IParcelTypes.TraditionalLetter:
      return 256;
  }
};

export const getTypeOfParcel = (itemKind: number) => {
  const types = [];

  if (itemKind >= 256) {
    itemKind -= 256;
    types.push(IParcelTypes.TraditionalLetter);
  }
  if (itemKind >= 8) {
    itemKind -= 8;
    types.push(IParcelTypes.Email);
  }
  if (itemKind >= 4) {
    itemKind -= 4;
    types.push(IParcelTypes.NotaEncrypted);
  }
  if (itemKind >= 2) {
    itemKind -= 2;

    types.push(IParcelTypes.EncryptedEDelivery);
  }
  if (itemKind >= 1) {
    itemKind -= 1;

    types.push(IParcelTypes.EDelivery);
  }

  return types;
};

export const getTypeOfParcelFromKind = (itemKind: number) => {
  let types: IParcelTypes[] = [];
  let toRet = itemKind;

  if (toRet >= ItemKind.Email) {
    types.push(IParcelTypes.Email);
    toRet -= ItemKind.Email;
  }

  if (toRet >= ItemKind.Parcel) {
    toRet -= ItemKind.Parcel;
    types.push(IParcelTypes.Parcel);
  }

  if (toRet >= ItemKind.CourtPortalDeliverance) {
    toRet -= ItemKind.CourtPortalDeliverance;
    types.push(IParcelTypes.CourtPortalDeliverance);
  }

  if (toRet >= ItemKind.CourtPortalDocument) {
    toRet -= ItemKind.CourtPortalDocument;
    types.push(IParcelTypes.CourtPortalDocument);
  }

  if (toRet >= ItemKind.EDeliveryMessage) {
    toRet -= ItemKind.EDeliveryMessage;
    types.push(IParcelTypes.EDeliveryMessage);
  }

  if (toRet >= ItemKind.CourtPortalApplication) {
    types.push(IParcelTypes.CourtPortalApplication);
    toRet -= ItemKind.CourtPortalApplication;
  }
  if (toRet >= ItemKind.ContactMessage) {
    types.push(IParcelTypes.ContactMessage);
    toRet -= ItemKind.ContactMessage;
  }
  if (toRet >= ItemKind.OutgoingParcel) {
    types.push(IParcelTypes.OutgoingParcel);
    toRet -= ItemKind.OutgoingParcel;
  }

  return types;
};
