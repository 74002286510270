import axios from 'axios';
import moment from 'moment';
import { User } from 'oidc-client-ts';

export const axiosPluginInstance = axios.create({
  baseURL: process.env.REACT_APP_PLUGIN_API_URL ?? 'https://localhost:8883',
  headers: {
    'content-type': 'application/json',
  },
});

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? 'https://beta.pocztaprawnicza.pl',
  headers: {
    'content-type': 'application/json',
  },
  withCredentials: true,
});

export function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENTID}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const reqInterceptor = (config: any) => {
  if (!config.headers) {
    config.headers = {};
  }
  const user = getUser();
  const token = user?.access_token;

  config.headers.authorization = `Bearer ${token}`;

  return config;
};

const resInterceptor = async (error: any) => {
  
  
  const time = moment().toJSON();
  if (error.response) {
    // Błąd odpowiedzi z serwera (np. status HTTP różny niż 2xx)
    if(error.response.status === 401) {
      window.location.reload();
    }
    // await axiosInstance.post('/logger', {
    //   message: `Axios Error: ${error?.response?.status}, ${JSON.stringify(error?.response?.data)}`,
    //   level: 'error',
    //   url: window.location.href,
    //   timestamp: time,
    // });
  } else if (error.request) {
    // Brak odpowiedzi od serwera
    // await axiosInstance.post('/logger', {
    //   message: `Axios Error: ${error?.request}`,
    //   level: 'error',
    //   url: window.location.href,
    //   timestamp: time,
    // });
  } else {
    // Inny błąd
    // await axiosInstance.post('/logger', {
    //   message: `Axios Error: ${error?.message}`,
    //   level: 'error',
    //   url: window.location.href,
    //   timestamp: time,
    // });
  }

  return Promise.reject(error); // Przekazuje błąd dalej


  
} 

axiosInstance.interceptors.request.use(reqInterceptor);
axiosInstance.interceptors.response.use((response: any )=> {return response}, resInterceptor)