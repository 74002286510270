import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tagsService from 'api/tagsService';
import IconsState from 'components/IconsState';
import LetterOptionsCard from 'components/LetterOptionsCard/OptionsCard';
import StatusBadge from 'components/StatusBadge';
import TagBadge from 'components/TagBadge';
import CertContext from 'contexts/CertContext';
import MenuContext from 'contexts/MenuContext';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import prettyBytes from 'pretty-bytes';
import Chip from 'components/Chip';

const LetterCardDesk: FC<Props> = ({
  selectedItems,
  setSelectedItems,
  isOutcoming,
  msg,
  statuses,
  tags,
  refetch,
}) => {
  const navigate = useNavigate();
  const { caseId, status: linkStatus } = useParams();
  const { mutate: removeTag } = tagsService.useRemoveTagFromPackage();
  const [status, setStatus] = useState<IStatus | null>(null);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);
  const { cert, setCert } = useContext(CertContext);
  useEffect(() => {
    const t = tags?.filter((tag) =>
      msg?.TagIds?.find((iTag) => tag.Id === iTag)
    );

    const newStatus = statuses.find((s) => s.Id === msg.StatusId);
    setFindedTags(t);

    setStatus(newStatus ?? null);
  }, [statuses, msg.TagIds]);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        OwnerId: msg.Id,
        OwnerKind: msg.Kind,
        TagId: tag.Id,
      },
      {
        onSuccess: () => {
          setFindedTags(findedTags.filter((ftag) => ftag.Id !== tag.Id));
        },
      }
    );
  };

  // if (!!msg?.S3?.length) {
  //
  // }

  const handleClick = (e: any) => {
    const ele = e.target.tagName.toLowerCase();
    const button = e.target.outerHTML.search('button') !== -1;

    if (ele !== 'div' || button) {
      return;
    }

    if (isOutcoming) {
      setSelectedItems?.([...selectedItems!, msg]);
      return;
    }

    setCert({ id: '', name: '' });

    if (msg.S4) {
      navigate(
        `/letter-preview/${msg?.Case?.Id}/${msg.Kind}/${msg.Id}/${msg.S4}/${msg.EncryptionKeyId}`
      );
      // navigate(
      //   `/cases/${msg?.Case?.Id}/letter/${msg.Kind}/${msg.Id}/${msg.S4}/${msg.EncryptionKeyId}`
      // );
      return;
    }

    navigate(
      `/letter-preview/${msg?.Case?.Id}/${msg.Kind}/${msg.Id}/${msg.S3}/${msg.EncryptionKeyId}`
    );
    // navigate(
    //   `/cases/${msg?.Case?.Id}/letter/${msg.Kind}/${msg.Id}/${msg.S3}/${msg.EncryptionKeyId}`
    // );
  };

  const refWrapper = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  const { id, setId } = useContext(MenuContext);

  useEffect(() => {
    if (id !== msg.Id) {
      setShowOptions(false);
    }
  }, [id]);

  const isSelected = !!selectedItems?.find((i) => i.Id === msg.Id);
  return (
    <div
      onClick={handleClick}
      ref={refWrapper}
      // onMouseEnter={() => setShowOptions(true)}
      // onMouseLeave={() => setShowOptions(false)}
      className={`${styles.card} ${isSelected && styles.active}`}
    >
      {refWrapper.current && (
        <LetterOptionsCard
          isCase
          binds
          selectedElements={[]}
          showOptions={showOptions}
          pack={msg}
          refetch={refetch}
          refWrapper={refWrapper}
          onClickAttachIcon={() => {}}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.left}>{msg.S1}</div>
        <div className={styles.right}>
          <div className={styles.box}>
            {msg.S4 && (
              <div className={styles.badge}>
                {/* <TagBadge
                  onRemove={() => {}}
                  tag={{
                    Id: '',
                    Flags: 2,
                    Global: true,
                    Name: 'Podpisany',
                    Type: 3,
                  }}
                /> */}
                <Chip element={<>Podpisany</>} onDelete={() => {}} small />
              </div>
            )}
          </div>
          <div className={styles.box}>{prettyBytes(msg.N1 ?? 0)}</div>
          <div className={styles.box}>
            {moment(msg.CreationDate).format('DD/MM/YY').toString()}
          </div>
          <div className={styles.icons}>
            <IconsState
              withoutCase
              keys={[]}
              msg={msg}
              onPreview={() => {}}
              onDownload={() => {}}
            />
          </div>
          <FontAwesomeIcon
            onClick={() => {
              setShowOptions(!showOptions);
              setId(msg.Id);
            }}
            icon={faEllipsisVertical}
          />
        </div>
      </div>
    </div>
  );
};

export default LetterCardDesk;
