import { IFeed } from "dto/IFeed";
import { INotification } from "dto/INotification";
import { createContext } from "react";

type INotificationContext = {
    notifications: INotification[];
    setNotifications: (tasks: INotification[]) => void;
}

const NotificationContext = createContext<INotificationContext>({
    notifications: [],
    setNotifications: () => {}
})

export default NotificationContext;