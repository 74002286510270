import taskService, { IUpdateTaskPayload } from 'api/taskService';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edite.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import tagsService from 'api/tagsService';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ITag } from 'dto/ITag';
import Chip from 'components/Chip';
import moment from 'moment';
import Avatar from 'components/Avatar';
import { Editor } from '@tinymce/tinymce-react';
import CreateTaskModal from 'components/CreateTaskModal';
import CasesContext from 'contexts/CasesContext';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import StatusBadge from 'components/StatusBadge';

const Task: FC<Props> = ({ task, setTask }) => {
  const [showEdit, setShowEdit] = useState(false);
  const { data } = taskService.useGetSingleTask(task.Id);
  const { data: historyData, refetch: refetchHistory } =
    taskService.useGetHistory(task.Id);
  const { mutate: createComment } = taskService.useCreateComment();
  const { data: tagsData } = tagsService.useGetTags();
  const { mutate: removeTask } = taskService.useDeleteTask();
  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { mutate: updateTask } = taskService.useUpdateEvent();

  const [isHidden, setIsHidden] = useState(false);

  const tags: ITag[] = useMemo(
    () =>
      tagsList.filter((t: ITag) => task.TagIds.find((tt) => tt === t.Id)) ?? [],
    [task, tagsList]
  );

  // const {cases, setCases} = useContext(CasesContext);

  useEffect(() => {
    refetchHistory();
  }, [task]);

  const [comment, setComment] = useState('');

  const handleUpdate = (tag: ITag) => {
    const payload: IUpdateTaskPayload = {
      Allday: task.AllDay,
      FromDate: task.FromDate,
      Id: task.Id,
      IsFavourite: task.IsFavourite,
      Note: task.Note,
      OwnerId: task.OwnerId,
      OwnerKind: task.Kind,
      TagIds: tags.filter((t) => t.Id !== tag.Id).map((t) => t.Id),
      ToDate: task.ToDate,
      Title: task.Title,
    };

    updateTask(payload, {
      onSuccess: (response) => {
        setTask(response.data);
      },
    });
  };

  const handleSendComment = () => {
    // console.log(comment);
    createComment(
      {
        TaskId: task.Id,
        Note: comment,
      },
      {
        onSuccess: () => {
          refetchHistory();
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.headerLine}>
            <div className={styles.title}>{task.Title}</div>
            <div className={styles.icons}>
              <EditIcon
                onClick={() => setShowEdit(true)}
                className={styles.icon}
              />
              <BinIcon
                onClick={() => {
                  removeTask(
                    { Id: task.Id, MoveToTrash: true },
                    {
                      onSuccess: () => {
                        setTask(null);
                      },
                    }
                  );
                }}
                className={styles.icon}
              />
              <CloseIcon
                className={styles.icon}
                onClick={() => setTask(null)}
              />
            </div>
          </div>
          <div className={styles.headerLine}>
            <div className={styles.tags}>
              {task.StatusId && (
                <StatusBadge
                  onRemove={() => {}}
                  showName={true}
                  status={{
                    Color: 6,
                    Id: 1,
                    Name: 'Zakończone',
                    Type: 1,
                    Global: true,
                    WorkspaceId: '',
                  }}
                />
              )}
              {tags.map((t) => (
                <Chip
                  onDelete={() => {
                    handleUpdate(t);
                  }}
                  element={<>{t.Name}</>}
                />
              ))}
            </div>
            <div className={styles.time}>
              <Avatar className={styles.avatar} name={task.UserLabel ?? ''} />{' '}
              {task.UserLabel} | Utworzone - {''}
              {moment(task.FromDate).format('YYYY.MM.DD HH:mm')}
            </div>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: task.Note }}
          className={styles.content}
        ></div>
        <div className={`${styles.timeline} ${isHidden && styles.hidden}`}>
          <div className={styles.timelineHeader}>
            <div className={styles.timelineText}>Timeline</div>
            <div
              onClick={() => setIsHidden(!isHidden)}
              className={styles.expanded}
            >
              {!isHidden ? 'Zwiń' : 'Rozwiń'}{' '}
              <FontAwesomeIcon icon={isHidden ? faCaretDown : faCaretUp} />
            </div>
          </div>

          <div className={styles.list}>
            {historyData?.data.map((h: any, index: number) => {
              if (h.Comment) {
                return (
                  <div className={styles.box}>
                    <div className={`${styles.person} ${styles.removeT}`}>
                      <Avatar
                        className={styles.avatar}
                        name={h.MakingChangeUserLabel}
                      />
                      <div className={styles.comment}>
                        <div className={styles.who}>
                          {h.MakingChangeUserLabel}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{ __html: h.Comment }}
                          className={styles.con}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className={styles.box}>
                  <div className={styles.person}>
                    <div className={styles.dot}></div>
                    <div className={styles.data}>
                      {h.Text} {moment(h.Date).format('DD.MM.YYYY HH:mm')}
                    </div>
                  </div>
                  {index + 1 !== historyData.data.length && (
                    <div className={styles.line}></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.comment}>
          <Editor
            onEditorChange={(text) => {
              setComment(text);
            }}
            init={{
              height: '360px',
              forced_root_block: 'div',
              menubar: false,
              statusbar: false,
              resize: false,
              browser_spellcheck: true,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'imageTools',
                'quickbars',
              ],
              toolbar_persist: true,
              // @ts-ignore
              init_instance_callback: 'insert_contents',
              fixed_toolbar_container_target: document.body,
              contextmenu: 'table',
              contextmenu_never_use_native: true,
              paste_data_images: true,
              images_upload_handler: (blobInfo, success): any => {
                console.log('read');
                const base64str =
                  'data:' +
                  blobInfo.blob().type +
                  ';base64,' +
                  blobInfo.base64();

                console.log(success);
                //@ts-ignore
                success(base64str);
              },
              toolbar: `bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor | indent outdent | bullist numlist | table`,
            }}
            apiKey="12k7ybb0o4007p3nah1s77v7gmrbalfhy4jreucu2nky2l3b"
          />
        </div>
        <div className={styles.btnWraper}>
          <Button
            className={styles.btn}
            onClick={() => {
              handleSendComment();
            }}
            text="Dodaj"
          />
        </div>
      </div>
      {showEdit && (
        <CreateTaskModal
          initialValues={{
            taskId: task.Id,
            caseId: '',
            content: task.Note,
            tags: tags,
            title: task.Title,
          }}
          onClose={(newT) => {
            setShowEdit(false);
            setTask(newT);
            refetchHistory();
          }}
        />
      )}
    </div>
  );
};

export default Task;
