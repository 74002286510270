import casesService from 'api/casesService';
import fileService from 'api/fileService';
import pluginService from 'api/pluginService';
import PDFViewer from 'components/PDFViever';
import KeysContext from 'contexts/KeysContext';
import CPluginContext from 'contexts/PluginContext';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { IAttachment } from 'dto/IAttachment';
import { ItemKind } from 'dto/IKindItems';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { blobToBase64 } from 'utils/blobToBase64';
import { decryptFile } from 'utils/decryptFile';
import { getBlob } from 'utils/getBlob';
import { getUserId } from 'utils/getUserId';
import { isAllowedAttachment } from 'utils/isAllowedAttachment';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import styles from './styles.module.scss';
import PanelsLayout from 'components/PanelsLayout';
import DossierFile from 'components/DossierFile';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Comments from 'components/Comments';
import LoadingScreen from 'components/LoadingScreen';
import dossierService from 'api/dossierService';
import threadsService from 'api/threadsService';
import { IThread } from 'dto/IThread';
import listService from 'api/listService';
import { IPackage } from 'dto/IPackage';
import ConverstationContext from 'contexts/ConversationContext';
import ChatCard from 'components/ChatCard';
import Attachment from 'components/Attachment';

export const ThreadModule = () => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { mutate: tryPreview } = pluginService.usePreview();
  const { caseId, threadId } = useParams();
  const { plugin } = useContext(CPluginContext);
  const [showVersion, setShowVersion] = useState(false);

  const [selectedItem, setSelectedItem] = useState<null | number>(null);
  const [blob, setBlob] = useState<null | string | Blob>(null);

  const { data: CaseData } = casesService.useGetCase(caseId!);

  const {
    data: packageData,
    refetch,
    isFetching,
  } = listService.useGetPackage({
    Kind: '4831',
    CaseId: caseId,
    GroupThreads: false,
  });

  const { mutate: getFile } = fileService.useGetFiltersForCases();

  const { data: threadListData } = threadsService.useGet(caseId!);

  const threads: IThread[] = useMemo(
    () => threadListData?.data ?? [],
    [threadListData]
  );

  const packs: IPackage[] = useMemo(
    () => packageData?.data.Items ?? [],
    [packageData]
  );

  const attachments = packs
    .filter((p) => p.Thread?.Id === threadId)
    .map((p) => p.Attachments)
    .flat();

  const { converstation, setConverstation } = useContext(ConverstationContext);

  useEffect(() => {
    setConverstation(packs.filter((p) => p.Thread?.Id === threadId));

    setKeys([packageData?.data.ObjectKeys, packageData?.data.Keys].flat());
  }, [packs]);

  const thread = threads.find((f) => f.Id === threadId!);

  //
  const { keys, setKeys } = useContext(KeysContext);

  useEffect(() => {
    // if (dossierItems[0]) {
    // setSelectedItem(0);
    // }
  }, [keys]);

  useEffect(() => {
    if (selectedItem === null) return;

    onPreview();
  }, [selectedItem]);

  const previewFromPlugin = async (blob: Blob, attachment: IAttachment) => {
    if (!plugin.actual) {
      setShowVersion(true);
      return;
    }

    const b: string = (await blobToBase64(blob)) as string;

    tryPreview(
      {
        AttachmentId: attachment.Id,
        Content: b.split(',')[1],
        ContentType: attachment.ContentType,
        DocumentId: attachment.Id,
        FileName: attachment.FileName,
        Kind: attachment.Kind,
      },
      {
        onSuccess: async (response: any) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setBlob(new Blob([buffer]));
        },
      }
    );
  };

  const getFileAndDecrypt = (
    attachment: IAttachment,
    uri: string,
    kind: ItemKind,
    download?: boolean
  ) => {
    getFile(
      {
        id: uri,
        ownerKind: kind,
      },
      {
        onSuccess: async (data) => {
          //   let parentKey = null;
          //   if (previewPackageData?.data.ObjectKeys[0]) {
          //     parentKey = parentKeys.find(
          //       (pkey: any) =>
          //         pkey.Id === previewPackageData?.data.ObjectKeys[0].ParentKeyId
          //     );
          //   }
          const key = keys?.find((k) => k?.Id === attachment.EncryptionKeyId);
          let parentKey = null;
          if (!key) return;

          if (key.ParentKeyId) {
            parentKey = keys?.find((k) => k?.Id === key.ParentKeyId);
          }

          const extension = data.data.FileName.split('.').at(-1);
          const isAllowed = isAllowedAttachment(extension ?? 'no');
          const isPDF = extension === 'pdf';

          const file = await getBlob(data.data.Url);
          try {
            const decryptedBlob = await decryptFile(
              userId,
              new Blob([file.data]),
              key,
              parentKey
            );

            if (download) {
              const link = document.createElement('a');
              link.href = URL.createObjectURL(decryptedBlob);
              link.setAttribute('download', attachment.FileName);
              document.body.appendChild(link);
              link.click();
              return;
            }

            if (!isPDF) {
              previewFromPlugin(decryptedBlob, data.data as any);
            }
            setBlob(decryptedBlob);
          } catch (e) {
            if (!isPDF) {
              previewFromPlugin(new Blob([file.data]), data.data as any);
              return;
            }
            setBlob(new Blob([file.data]));
          }
        },
      }
    );
  };

  const onPreview = async () => {
    // if (selectedItem === null || !dossierItems[selectedItem]) {
    // return;
    // }
    setShowVersion(false);
    // const previewPackage: IDossierItem = dossierItems[selectedItem];

    // getFileAndDecrypt(previewPackage, previewPackage.S3, previewPackage.Kind);
  };

  // if (selectedItem === null) {
  //   return <LoadingScreen />;
  // }
  // const documentID = `${dossierItems[selectedItem!].Id}:${
  //   dossierItems[selectedItem!].Kind
  // }:${dossierItems[selectedItem!].Id}`;

  const [documentID, setDocumentID] = useState('');

  const leftSide = () => {
    return (
      <div className={styles.window}>
        <DragDropContext onDragEnd={() => {}}>
          <Droppable droppableId="files">
            {(provided, snapshot) => (
              <div className={styles.window} ref={provided.innerRef}>
                {converstation.map((c) => {
                  return (
                    <ChatCard
                      key={c.Id}
                      refetch={() => {}}
                      Package={c}
                      statuses={[]}
                      tags={[]}
                      keys={keys}
                      onDownload={(pack, attach) => {
                        getFileAndDecrypt(
                          attach,
                          attach.Uri,
                          attach.Kind,
                          true
                        );
                      }}
                      onPreview={(pack, attach) => {
                        getFileAndDecrypt(
                          attach,
                          attach.Uri,
                          attach.Kind,
                          false
                        );
                      }}
                      index={0}
                    />
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  // const leftSide = () => {
  //   return (
  //     <div className={styles.pdf}>
  //       {showVersion && <PDFViewer file={new Blob([])} showVersion={true} />}
  //       {blob && !showVersion && (
  //         <>
  //           <PDFViewer
  //             className={styles.test}
  //             docId={`${documentID}`}
  //             file={blob}
  //           />

  //           <div className={styles.commentsWrapper}>
  //             <Comments documentID={documentID} />
  //           </div>
  //         </>
  //       )}
  //     </div>
  //   );
  // };

  const rightSide = () => {
    return (
      <div className={styles.files}>
        {blob && (
          <div className={styles.pdf}>
            <PDFViewer
              className={styles.test}
              docId={`${documentID}`}
              file={blob}
              onCloseButton={() => {
                setBlob(null);
              }}
            />
            <div className={styles.commentsWrapper}>
              <Comments documentID={documentID} />
            </div>
          </div>
        )}
        {!blob &&
          attachments.map((attach) => (
            <Attachment
              attachment={attach}
              onDownload={() => {
                getFileAndDecrypt(attach, attach.Uri, attach.Kind, true);
              }}
              onPreview={() => {
                const p = packs.find(
                  (p) => !!p.Attachments.find((a) => a.Id === attach.Id)
                );
                if (!p) return;
                getFileAndDecrypt(attach, attach.Uri, attach.Kind);
                setDocumentID(`${p.Id}:16:${attach.Id}`);
              }}
              onNewWindow={() => {}}
            />
          ))}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.p}>
        <PanelsLayout
          disableListStyle
          wrapperClassName={styles.fixWrapper}
          disablePadding
          // disableHeader
          disableSearch
          leftPanel={leftSide()}
          rightPanel={rightSide()}
          layoutName="Folders-dossier"
          title={CaseData?.Title ?? ''}
          subTitle={thread?.Label ?? ''}
        />
      </div>
    </div>
  );
};
