import { defaultMenu } from 'components/DossierFilters';
import { EOrder } from 'components/SortOrder/typings';
import { RefObject, createContext } from 'react';

export interface IMenu {
  type: string;
  text: string;
  sort: boolean;
  order: EOrder;
  hidden?: boolean;
}

type IDossierFilterContext = {
  menu: IMenu[];
  setMenu: (menu: IMenu[]) => void;
};

const DossierFilterContext = createContext<IDossierFilterContext>({
  menu: defaultMenu,
  setMenu: () => {},
});

export default DossierFilterContext;
