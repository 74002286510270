import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { EOrder, ETypeOfOrder, FC, IOrderObject, Props } from './typings';
import { ReactComponent as ArrowIcon } from 'assets/icons/order/arrow.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/order/arrow-up.svg';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { useParams } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

const packageList = [
  { type: ETypeOfOrder.CREATION_DATE, text: 'Ostatnio dodane' },
  { type: ETypeOfOrder.RECEIVED_DATE, text: 'Data odebrania' },
  { type: ETypeOfOrder.TITLE, text: 'Alfabetycznie' },
];

const outList = [
  { type: ETypeOfOrder.CREATION_DATE, text: 'Ostatnio dodane' },
  { type: ETypeOfOrder.SEND_DATE, text: 'Data doręczenia' },
  { type: ETypeOfOrder.TITLE, text: 'Alfabetycznie' },
];

const draftList = [
  { type: ETypeOfOrder.CREATION_DATE, text: 'Ostatnio dodane' },
  { type: ETypeOfOrder.LAST_MODIFED, text: 'Ostatnio modyfikowane' },
  { type: ETypeOfOrder.SEND_DATE, text: 'Ostatnio otwierane' },
  { type: ETypeOfOrder.TITLE, text: 'Alfabetycznie' },
];

const letterList = [
  { type: ETypeOfOrder.CREATION_DATE, text: 'Ostatnio dodane' },
  { type: ETypeOfOrder.LAST_MODIFED, text: 'Ostatnio modyfikowane' },
  { type: ETypeOfOrder.SEND_DATE, text: 'Ostatnio otwierane' },
  { type: ETypeOfOrder.TITLE, text: 'Alfabetycznie' },
];

const caseList = [
  { type: ETypeOfOrder.CREATION_DATE, text: 'Ostatnio dodane' },
  { type: ETypeOfOrder.UPDATE_DATE, text: 'Ostatnio modyfikowane' },
  { type: ETypeOfOrder.OPEN_DATE, text: 'Ostatnie otwierane' },
  { type: ETypeOfOrder.NAME, text: 'Alfabetycznie' },
];

const entityList = [
  { type: ETypeOfOrder.CREATION_DATE, text: 'Ostatnio dodane' },
  { type: ETypeOfOrder.UPDATE_DATE, text: 'Ostatnio modyfikowane' },
  { type: ETypeOfOrder.OPEN_DATE, text: 'Ostatnie otwierane' },
  { type: ETypeOfOrder.NAME, text: 'Alfabetycznie' },
];

const nameOf = 'ORDER';

const nameOfList = (isPackage: boolean, isCase: boolean, isEntity: boolean) => {
  if (isPackage) {
    return 'Packages';
  }
  if (isCase) {
    return 'Cases';
  }
  if (isEntity) {
    return 'Entities';
  }
};

const SortOrder: FC<Props> = ({
  type,
  order,
  setListFilter,
  isLoading,
  refetch,
}) => {
  const auth = useAuth();
  var userId = auth?.user ? getUserId(auth.user!) : '1';
  const [initalized, setInitialized] = useState(false);
  const { filterId } = useParams();
  const isPackage = window.location.href.search('packages') !== -1;
  const isCase = window.location.href.search('cases') !== -1;
  const isDraft =
    window.location.href.search('A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99') !== -1;
  const isEntity = window.location.href.search('clients') !== -1;
  const isLetter = window.location.href.search('pisma') !== -1;
  const isOut = window.location.href.search('sending') !== -1;

  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);

  let list = packageList;

  if (isCase) {
    list = caseList;
  }

  if (isEntity) {
    list = entityList;
  }

  if (isLetter) {
    list = letterList;
  }

  if (isOut) {
    list = outList;
  }

  if (isDraft) {
    list = draftList;
  }

  const filter = list.find((t) => t.type === type) ?? list[0];

  useOnClickOutside(ref, () => {
    if (showMenu) {
      setShowMenu(false);
    }
  });

  useEffect(() => {
    refetch();
  }, [type, order]);

  useEffect(() => {
    if (initalized) return;
    const json = localStorage.getItem(
      `${nameOfList(
        isPackage,
        isCase,
        isEntity
      )}_${userId}_${filterId}_${nameOf}`
    );
    setInitialized(true);
    if (!json) {
      setListFilter({ order: order, type: type, initalized: true });
      return;
    }

    const item: IOrderObject = JSON.parse(json);

    setListFilter({
      type: item.type,
      order: item.order,
      initalized: true,
    });
  }, [filterId, isLoading, initalized]);

  useEffect(() => {
    setInitialized(false);
  }, [filterId, isCase, isPackage, isEntity]);

  useEffect(() => {
    const object = {
      type: type,
      order: order,
    };
    localStorage.setItem(
      `${nameOfList(
        isPackage,
        isCase,
        isEntity
      )}_${userId}_${filterId}_${nameOf}`,
      JSON.stringify(object)
    );
  }, [type, order]);

  const handleChangeOrder = () => {
    if (order === EOrder.ASCENDING) {
      setListFilter({
        type: type,
        order: EOrder.DESCENDING,
        initalized: true,
      });

      return;
    }

    setListFilter({
      type: type,
      order: EOrder.ASCENDING,
      initalized: true,
    });
  };

  const handleChangeTypeOrder = (newType: ETypeOfOrder) => {
    setShowMenu(false);
    if (newType === type) {
      setListFilter({
        type: type,
        order:
          order === EOrder.ASCENDING ? EOrder.DESCENDING : EOrder.ASCENDING,
        initalized: true,
      });
      return;
    }
    setListFilter({
      type: newType,
      order: order,
      initalized: true,
    });
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.card}>
        <div onClick={() => setShowMenu(!showMenu)} className={styles.name}>
          Sortuj: {filter?.text ?? 'undefined'}
        </div>
        <div onClick={handleChangeOrder} className={`${styles.arrow} `}>
          <FontAwesomeIcon
            icon={order === EOrder.ASCENDING ? faArrowUp : faArrowDown}
          />
        </div>
      </div>

      {showMenu && (
        <div className={styles.dropdown}>
          {list.map((b) => (
            <div
              onClick={() => handleChangeTypeOrder(b.type)}
              key={b.type}
              className={styles.box}
            >
              {b.text}
            </div>
          ))}
        </div>
      )}
      <div className={styles.separator}></div>
    </div>
  );
};

export default SortOrder;
