import styles from './styles.module.scss';
import { FC, Props } from './typings';
import UpssAnimation from 'assets/upss.json';
import Lottie from 'lottie-react';

const ErrorScreen: FC<Props> = ({ text }) => {
  return (
    <div className={styles.error}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Upss</div>!
      </div>
      <div className={styles.body}>
        {text ? text : <>Nie byliśmy w stanie załadować pliku do podglądu</>}
      </div>
      <div className={styles.cat}>
        <Lottie
          className={styles.kittyCat}
          animationData={UpssAnimation}
          loop={true}
        />
      </div>
    </div>
  );
};

export default ErrorScreen;
