import { FC, IDropdownObject, Props } from './typings';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import SecondCheckbox from 'components/SecondCheckbox';
import Button from 'components/Button';
import HollowButton from 'components/HollowButton';

const Dropdown: FC<Props> = ({
  list,
  onChange,
  defaultValue,
  className = '',
  label,
  placeholder,
  allowNull = false,
  isError = '',
  allowMulti = false,
  disableChange = false,
  noBorder = false,
  noBackground = false,
}) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState<string | null>();

  useEffect(() => {
    if (defaultValue) {
      setValue(list.find((box) => box.value === defaultValue)?.name ?? '');
      return;
    }
    if (disableChange) return;

    if (list?.[0] && !allowNull) {
      setValue(list[0].name);
      return;
    }
    setValue('');
    // setValue(allowNull ? '' : list?.[0]?.name ?? '');
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setValue(list.find((box) => box.value === defaultValue)?.name ?? '');
      return;
    }
    if (disableChange) return;
    if (list?.[0] && !allowNull) {
      setValue(list[0].name);
      return;
    }
    setValue('');
  }, [list, defaultValue]);

  const [multiSelect, setMultiSelect] = useState<IDropdownObject[]>([]);

  const handleChange = (box?: IDropdownObject) => {
    if (allowMulti) {
      setMultiSelect([...multiSelect!, box!]);
      return;
    }
    setValue(box?.name);
    onChange(box);
    setShow(false);
  };

  const clear = () => {
    setValue(null);
    onChange();
    setShow(false);
  };

  const handleMultiChange = () => {
    onChange(multiSelect);
    setValue(null);
    setMultiSelect([]);
    setShow(false);
  };

  const hasValue = !!value?.length;

  return (
    <div
      className={`${styles.inputWrapper} ${className} ${
        hasValue ? styles.hasValue : ''
      }`}
    >
      <div
        onClick={(e) => {
          setShow(!show);
          e.stopPropagation();
        }}
        className={`${styles.label} ${!!isError.length && styles.error}`}
      >
        {!hasValue ? placeholder ?? label : label}
      </div>
      <div
        onClick={() => setShow(!show)}
        className={`${styles.input} ${noBorder && styles.brd} ${
          !noBackground && styles.bg
        }`}
      >
        <div className={styles.inputContent}>{value}</div>
        {!disableChange && (
          <FontAwesomeIcon icon={show ? faCaretUp : faCaretDown} />
        )}
      </div>
      {show && !disableChange && (
        <div className={styles.listWrapper}>
          <div className={styles.list}>
            {list.map((box) => (
              <div onClick={() => handleChange(box)} className={styles.box}>
                {allowMulti && (
                  <SecondCheckbox
                    onChange={() => {
                      handleChange(box);
                    }}
                    value={!!multiSelect.find((p) => p.value === box.value)}
                  />
                )}
                {box.name}
              </div>
            ))}
          </div>
          {allowMulti && (
            <div className={styles.buttons}>
              <HollowButton text="Anuluj" onClick={() => {}} />
              <Button text="Dodaj" onClick={handleMultiChange} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
