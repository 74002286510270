import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useState, useRef, useEffect } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import tagsService from 'api/tagsService';

const InputWithRightIcon: FC<Props> = ({
  id = -1,
  onFocus,
  onBlur,
  onChange,
  defaultValue,
  value,
  placeholder,
  handleClick,
  label = '',
  isError = '',
  onClick = () => {},
  focus,
}) => {
  const ref = useRef(null);
  const { mutate: addTag } = tagsService.useCreateTag();
  const [text, setText] = useState(defaultValue ?? '');
  const [isFocused, setIsFocused] = useState(false);

  const isText = !!value?.length || !!text.length;
  const isFocusedStyle = isFocused && !isText ? styles.active : '';
  const isFocusedStyle2 = !isFocused && isText ? styles.active : '';
  const isFocusedStyle3 = isFocused && isText ? styles.active : '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const handleClickButton = (e: any) => {
    if (!handleClick) return;
    if ((e.key === 'Enter' || e.code === 'Space') && isFocused) {
      const formatedText = value ?? text;
      if (formatedText.search('#') === -1) return;
      const tag = formatedText.split('#')[1].replaceAll('_', ' ');

      setText(formatedText.split('#')[0]);
      onChange(formatedText.split('#')[0]);

      addTag(
        {
          Name: tag,
          Type: id,
        },
        {
          onSuccess: (response) => {
            handleClick(response.data);
          },
        }
      );
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleClickButton);

    return () => document.removeEventListener('keypress', handleClickButton);
  }, [value, text, isFocused]);

  const handleChoose = () => {
    //@ts-ignore
    ref?.current?.focus();
  };

  useEffect(() => {
    if (!ref?.current) return;
    if (focus) {
      handleChoose();
      setIsFocused(true);
    }
  }, [ref]);

  return (
    <div className={styles.wrap}>
      <div
        onClick={onClick}
        className={`${styles.inputWrapper} ${isFocusedStyle3} ${isFocusedStyle} ${isFocusedStyle2}`}
      >
        <div className={`${isFocused && styles.shadow}`}></div>
        <div
          onClick={handleChoose}
          className={`${styles.label} ${!!isError.length && styles.error}`}
        >
          {label}
        </div>
        <input
          autoComplete="off"
          ref={ref}
          onFocus={() => {
            onFocus?.();
            setIsFocused(true);
          }}
          onBlur={() => {
            onBlur?.();
            setIsFocused(false);
          }}
          onChange={(e) => handleChange(e)}
          className={`${styles.input} ${!!isError.length && styles.error}`}
          type="text"
          value={value ?? text}
          // placeholder={placeholder}
        />
        <SearchIcon className={styles.icon} />
      </div>
      {/* {!!isError.length && <div className={styles.errorBox}>{isError}</div>} */}
    </div>
  );
};

export default InputWithRightIcon;
