import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';

const queryKeys = {
  getTags: (tagId: number, workspaceId?: string, currentWorkspaceId?: string) => ['tagsService.getTags', tagId, workspaceId, currentWorkspaceId],
};

const getTags = (tagId: number, workspaceId?: string, currentWorkspaceId?: string) => {
  const query = new URLSearchParams(window.location.search);

  if(tagId !== -1) {
    query.append("Type", tagId.toString())
    // return axiosInstance.get(`/tags?Type=${tagId}`);
  }
  
  if(workspaceId) {
    query.append("WorkspaceId", workspaceId)

  }

  if(currentWorkspaceId) {
    query.append("CurrentWorkspaceId", currentWorkspaceId)

  }




  return axiosInstance.get(`/tags?${query}`);
};

const useGetTags = (disabled:boolean = false, tagId = -1, workspaceId?: string, currentWorkspaceId?: string) => {
  return useQuery(queryKeys.getTags(tagId, workspaceId, currentWorkspaceId), () => getTags(tagId, workspaceId, currentWorkspaceId), {
    enabled: !disabled
  });
};

interface IRemoveTagFromPackage {
  TagId: string;
  OwnerKind: number;
  OwnerId: string;
}
const removeTagFromPackage = (payload: IRemoveTagFromPackage) => {
  return axiosInstance.delete('/tagged', {
    data: payload,
  });
};

const useRemoveTagFromPackage = () => {
  return useMutation(removeTagFromPackage);
};

export interface IAddTag {
  NewTags: string[] | null;
  OwnerId: string;
  OwnerKind: number
  TagIds: string[]
}

const addTag = (payload: IAddTag) => {
  return axiosInstance.post('/tagged', payload)
} 

const useAddTag = () => {
  return useMutation(addTag);
}

export interface ICreateTag {
  Name: string;
  Type: number;
  WorkspaceId?: string
}

const createTag = (payload: ICreateTag) => {
  return axiosInstance.post('/tag', payload)
}

const useCreateTag = () => {
  return useMutation(createTag);
}

export interface IRemoveTag {
  Id: string;
}

const deleteTag = (payload: IRemoveTag) => {
  return axiosInstance.delete('/tag', {
    data: payload
  })
}

const useDeleteTag = () => {
  return useMutation(deleteTag);
}

export interface IEditTag {
  Id: string;
  Name: string;
}

const editTag = (payload: IEditTag) => {
  return axiosInstance.put('/tag', payload)
} 
const useEditTag = () => {
  return useMutation(editTag)
}

export default {
  useGetTags,
  useRemoveTagFromPackage,
  useAddTag,
  useCreateTag,
  useDeleteTag,
  useEditTag
};
