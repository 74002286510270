import DossierFile from 'components/DossierFile';
import { Droppable } from 'react-beautiful-dnd';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { EOrder } from 'components/SortOrder/typings';
import {
  getDossierItemFileName,
  getDossierItemGroupName,
} from 'utils/getDossierItemFileName';
import { useContext, useEffect, useState } from 'react';
import DossierFilterContext from 'contexts/DossierFilters';

const DossierFiles: FC<Props> = ({
  selectedFiles,
  canSelect,
  onSelect,
  files,
  statuses,
  tags,
  cutting,
  folders,
  refetch,
  setSelectedFiles,
  handleCopy,
  encryptionKeys,
  parentKeys,
  setFiles,
  handleFileDoubleClick,
}) => {
  const { menu } = useContext(DossierFilterContext);
  const [sortedFiles, setSortedFiles] = useState<IDossierItem[]>([]);
  const [group, setGroup] = useState<Partial<Record<string, IDossierItem[]>>>();
  const [showGroups, setShowGroups] = useState(false);

  const getTagName = (item: IDossierItem): string => {
    if (item.TagIds && item.TagIds.length > 0) {
      var tag = tags.find((x) => x.Id == item.TagIds![0]);

      if (tag) return tag.Name;
    }

    return '';
  };

  const getStatusName = (item: IDossierItem): string => {
    if (item.StatusId) {
      var status = statuses.find((x) => x.Id == item.StatusId);

      if (status) return status.Name;
    }

    return '';
  };

  const sortFiles = (
    itemA: IDossierItem,
    itemB: IDossierItem,
    prop: string,
    order: EOrder
  ): number => {
    switch (prop) {
      case 'size': {
        return order === EOrder.DESCENDING
          ? (itemA.FileSize ?? 0) - (itemB.FileSize ?? 0)
          : (itemB.FileSize ?? 0) - (itemA.FileSize ?? 0);
      }
      case 'date': {
        return order === EOrder.DESCENDING
          ? new Date(itemA.CreationDate).getTime() -
              new Date(itemB.CreationDate).getTime()
          : new Date(itemB.CreationDate).getTime() -
              new Date(itemA.CreationDate).getTime();
      }
      case 'tag': {
        return order === EOrder.DESCENDING
          ? getTagName(itemA).localeCompare(getTagName(itemB))
          : getTagName(itemB).localeCompare(getTagName(itemA));
      }
      case 'status': {
        return order === EOrder.DESCENDING
          ? getStatusName(itemA).localeCompare(getStatusName(itemB))
          : getStatusName(itemB).localeCompare(getStatusName(itemA));
      }
      default: {
        return order === EOrder.DESCENDING
          ? getDossierItemFileName(itemA).localeCompare(
              getDossierItemFileName(itemB)
            )
          : getDossierItemFileName(itemB).localeCompare(
              getDossierItemFileName(itemA)
            );
      }
    }
  };

  useEffect(() => {
    var currentSort = menu.filter((x) => x.sort);
    var sortProp = currentSort.length > 0 ? currentSort[0].type : 'name';
    var sortOrder =
      currentSort.length > 0 ? currentSort[0].order : EOrder.ASCENDING;

    var group = sortProp === 'name';
    setShowGroups(group);

    if (!group) {
      setSortedFiles(
        files.sort((a, b) => sortFiles(a, b, sortProp, sortOrder))
      );
    } else {
      files.sort((a, b) => {
        var groupA = getDossierItemGroupName(a);
        var groupB = getDossierItemGroupName(b);

        if (groupA === groupB) {
          return sortFiles(a, b, sortProp, sortOrder);
        }

        return sortOrder === EOrder.DESCENDING
          ? groupA.localeCompare(groupB)
          : groupB.localeCompare(groupA);
      });

      setGroup(
        // @ts-ignore
        Object.groupBy(files, (f: IDossierItem) => getDossierItemGroupName(f))
      );
    }
  }, [files, menu]);

  return (
    <div className={styles.wrapper}>
      {/* {!!folders?.length && (
        <div className={styles.header}>
          POZOSTAŁE PLIKI
          <DossierFilters />
        </div>
      )} */}
      <Droppable droppableId="files">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {!showGroups &&
              sortedFiles?.map((file, index) => (
                <DossierFile
                  files={files}
                  setFiles={setFiles}
                  handleCopy={handleCopy}
                  setSelectedFiles={setSelectedFiles}
                  refetch={refetch}
                  key={file?.Id}
                  cutting={cutting}
                  selectedFiles={selectedFiles}
                  canSelect={canSelect}
                  onSelect={onSelect}
                  file={file}
                  index={index}
                  statuses={statuses}
                  tags={tags}
                  encryptionKeys={encryptionKeys}
                  parentKeys={parentKeys}
                  handleFileDoubleClick={handleFileDoubleClick}
                />
              ))}
            {showGroups &&
              Object.entries(group!).map(([k, value]) => (
                <>
                  <div className={styles.group}>{k}</div>
                  {value!.map((file, index) => (
                    <DossierFile
                      files={files}
                      setFiles={setFiles}
                      handleCopy={handleCopy}
                      setSelectedFiles={setSelectedFiles}
                      refetch={refetch}
                      key={file?.Id}
                      cutting={cutting}
                      selectedFiles={selectedFiles}
                      canSelect={canSelect}
                      onSelect={onSelect}
                      file={file}
                      index={index}
                      statuses={statuses}
                      tags={tags}
                      encryptionKeys={encryptionKeys}
                      parentKeys={parentKeys}
                      handleFileDoubleClick={handleFileDoubleClick}
                    />
                  ))}
                </>
              ))}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default DossierFiles;
