import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useContext, useMemo, useRef, useState } from 'react';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import workspaceService from 'api/workspaceService';
import { IWorkspace, IWorkspaceMember } from 'dto/IWorkspace';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import SearchMemberNew from 'components/SearchMemberNew';
import TextArea from 'components/TextArea';
import Placeholder from 'assets/placeholder.png';
import SecondCheckbox from 'components/SecondCheckbox';
import { ReactComponent as AIcon } from 'assets/icons/company/a.svg';
import { ReactComponent as BIcon } from 'assets/icons/company/b.svg';
import Button from 'components/Button';
import { generateNewKey } from 'utils/geneareNewKey';
import { getKeys } from 'utils/getKeys';
import { readAsBase64 } from 'utils/readAsBase64';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import messagesService from 'api/messagesService';
import PackagesContext from 'contexts/PackagesContext';
import casesService from 'api/casesService';
import KeysContext from 'contexts/KeysContext';
import { useOnClickOutside } from 'usehooks-ts';
import { reEncrypt } from 'utils/reEncrypt';
import { ReactComponent as NewIcon } from 'assets/icons/new_tab.svg';
import { Editor } from '@tinymce/tinymce-react';
import Filedossier from 'components/Filedossier/Filedossier';
import moment from 'moment';
import Attachment from 'components/Attachment';
import { axiosInstance } from 'api/axios';
import SearchCasesNew from 'components/SearchCasesNew';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import Input from 'components/Input';
import { ITag } from 'dto/ITag';
import NewSearchEntitiesWithCase from 'components/NewSearchEntitiesWithCase';
import { IEntity } from 'dto/IEntity';
import './tinymce.css';
import useFiles from 'hooks/useFiles';

export const RedirectModal: FC<Props> = ({
  pack,
  Case,
  onCancel,
  onSelect,
  toggle,
}) => {
  const [c, setCase] = useState<ICaseSimple | null>(null);
  const [showCase, setShowCase] = useState(false);
  const [title, setTitle] = useState('Fwd: ' + pack?.S1);

  const isCases = window.location.href.search('cases') !== -1;

  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { mutate: redirect } = messagesService.useRedirect();
  const { mutate: caseRedirect } = casesService.useRedirect();
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');
  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);
  const [value, setValue] = useState('');
  const [entities, setEntities] = useState<IEntity[]>([]);
  const [workspaceMembers, setWorkspaceMembers] = useState<IWorkspaceMember[]>(
    []
  );
  const [cc, setCC] = useState<IEntity[]>([]);
  const [workspaceMembersCC, setWorkspaceMembersCC] = useState<
    IWorkspaceMember[]
  >([]);

  const { keys } = useContext(KeysContext);
  const [showModal, setShowModal] = useState(false);
  const outsideRef = useRef(null);

  const addNewTag = (tag: ITag) => {
    // const isTag = tags.find((etag) => etag.Name === tag.Name);
    // if (isTag) return;
    // setTags([...tags, tag]);
  };

  const { files, addNewFile, removeFile } = useFiles();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const handleClickToUpload = () => {
    if (!inputFile.current) return;
    inputFile.current.click();
  };

  const handleChooseFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = (event.target as any).files[0];
    if (!file) return;

    addNewFile(file);
  };

  useOnClickOutside(outsideRef, () => {
    if (showModal) {
      setShowModal(false);
    }
  });

  const editorSize = window.innerHeight <= 800 ? window.innerHeight - 430 : 400;
  const attachmentSize =
    window.innerHeight <= 800 ? window.innerHeight - 80 : 630;

  const settings = localStorage.getItem(`${userId}.layout`);
  const sizes = JSON.parse(settings ?? '[]');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [content, setContent] = useState('');

  const { packages, setPackages } = useContext(PackagesContext);

  const handleRedirect = async () => {
    const myKeys = await getKeys(userId!);
    let id = null;
    let Kind = null;
    let encryptionKeyId: string | null = null;
    if (pack) {
      id = pack.Id;
      Kind = pack.Kind;
      encryptionKeyId = pack.EncryptionKeyId;
    }

    if (Case) {
      id = Case.Id;
      Kind = Case.Kind;
    }

    if (!encryptionKeyId && workspaceMembers.length > 0) {
      redirect(
        {
          Id: id!,
          Kind: Kind!,
          ToUserId: workspaceMembers[0].UserId,
          Title: title,
          Comment: content,
        },
        {
          onSuccess: () => {
            toggle?.();

            if (pack) {
              let newPacks = [...packages];
              let newPack = newPacks.filter((p) => p.Id !== pack.Id);
              setPackages(newPack);
            }
          },
        }
      );
      return;
    }

    if (!encryptionKeyId && workspaceMembersCC.length > 0) {
      await axiosInstance.post('/message/share', {
        Id: id!,
        Kind: Kind!,
        ToUserId: workspaceMembersCC[0].UserId,
        Title: title,
        Comment: content,
      });
      toggle?.();
      return;
    }

    if (!encryptionKeyId) return;

    //const privateKey = await generateNewKey(encryptionKeyId, myKeys, keys);
    const encryptionKey = keys.find((k) => k?.Id === encryptionKeyId);

    const uint = await reEncrypt(
      userId,
      workspaceMembers.length > 0
        ? workspaceMembers[0].UserId
        : workspaceMembersCC[0].UserId,
      encryptionKey?.PrivateKey
    );
    const readyToSend = await readAsBase64(uint);

    if (workspaceMembers.length > 0) {
      redirect(
        {
          Id: id!,
          Kind: Kind!,
          ToUserId: workspaceMembers[0].UserId,
          Title: title,
          Comment: content,
          Keys: [{ Id: encryptionKeyId!, Key: readyToSend }],
        },
        {
          onSuccess: () => {
            if (pack) {
              let newPacks = [...packages];
              let newPack = newPacks.filter((p) => p.Id !== pack.Id);
              setPackages(newPack);
            }

            toggle?.();
          },
        }
      );
    }

    if (workspaceMembersCC.length > 0) {
      await axiosInstance.post('/message/share', {
        Id: id!,
        Kind: Kind!,
        ToUserId: workspaceMembersCC[0].UserId,
        Title: title,
        Comment: content,
        Keys: [{ Id: encryptionKeyId!, Key: readyToSend }],
      });
      toggle?.();
    }
  };

  return (
    <>
      <div
        style={
          !isFullscreen
            ? {
                background: 'transparent',
                justifyContent: 'flex-start',
                width: `${sizes.leftPanel + 9}%`,
                left: 0,
              }
            : {}
        }
        className={`${styles.modal}`}
      >
        <div
          style={
            !isFullscreen
              ? {
                  width: `100%`,
                }
              : {}
          }
          className={styles.window}
        >
          <div className={styles.title}>
            Przekaż do
            <div className={styles.icons}>
              <NewIcon
                className={styles.c}
                onClick={() => setIsFullscreen(!isFullscreen)}
              />
              <CloseIcon className={styles.c} onClick={() => onCancel()} />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.contentWrapper}>
              <div className={styles.inputs}>
                <SearchCasesNew
                  variant="filled"
                  settedCase={c}
                  setAddNewEntity={() => {
                    setShowCase(true);
                  }}
                  onChoose={(c) => setCase(c)}
                />

                <NewSearchEntitiesWithCase
                  isFullscreen={isFullscreen}
                  className={styles.t}
                  placeholder="Do: "
                  defaultValue={sessionStorage.getItem('sendTo') ?? undefined}
                  onChoose={(e) => {
                    setEntities(e);
                  }}
                  onChooseMembers={(e) => {
                    setWorkspaceMembers(e);
                  }}
                  showAlways
                  entities={entities}
                  c={c}
                  showWorkspaceMembers={true}
                />

                <NewSearchEntitiesWithCase
                  placeholder="Dw: "
                  onChoose={(e) => {
                    setCC(e);
                  }}
                  onChooseMembers={(e) => {
                    setWorkspaceMembersCC(e);
                  }}
                  entities={cc}
                  c={c}
                  showWorkspaceMembers={true}
                />

                {/* <SearchMemberNew
                  label="Wyznacz DO"
                  selected={user}
                  onChoose={handleClickNode}
                />
                <SearchMemberNew
                  label="Wyznacz DW"
                  selected={share}
                  onChoose={(s) => setShare(s)}
                /> */}

                <Input
                  variant="filled"
                  label="Temat"
                  value={title}
                  placeholder="Temat"
                  onChange={(t) => setTitle(t)}
                  onChooseTag={addNewTag}
                  tags
                />
              </div>
              <div className={styles.editor}>
                <Editor
                  apiKey="12k7ybb0o4007p3nah1s77v7gmrbalfhy4jreucu2nky2l3b"
                  onInit={(evt, editor) => {}}
                  onEditorChange={(text) => setContent(text)}
                  initialValue=""
                  value={content}
                  init={{
                    forced_root_block: 'div',
                    height: '100%',
                    menubar: false,
                    statusbar: false,
                    resize: false,
                    browser_spellcheck: true,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'help',
                      'imageTools',
                      'quickbars',
                    ],
                    toolbar_persist: true,
                    fixed_toolbar_container_target: document.body,
                    contextmenu: 'table',
                    contextmenu_never_use_native: true,
                    images_upload_url: '',
                    images_upload_handler: (): Promise<any> => {
                      return new Promise((resolve) => {
                        resolve(true);
                      });
                    },
                    toolbar: `bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor | indent outdent | bullist numlist | table`,
                    content_style:
                      '.mce-content-body { background: #F4EFF4;} .tox-editor-header { background: #F4EFF4!important;} .tox .tox-tbtn svg {fill: #ff0000!important;}',
                  }}
                />
              </div>
            </div>
            <div className={styles.attachments}>
              <div className={styles.attHeader}>
                <div className={styles.attTitle}>Załączniki</div>
                <div
                  className={styles.attAdd}
                  onClick={() => handleClickToUpload()}
                >
                  + Dodaj plik
                </div>
              </div>
              <div className={styles.attachWrap}>
                <div className={styles.attachItems}>
                  {pack?.Attachments?.map((attachment) => {
                    return (
                      <Attachment
                        onDownload={() => {}}
                        onPreview={() => {}}
                        onNewWindow={() => {}}
                        attachment={attachment}
                      />
                    );
                  })}
                  {files.map((file, i) => (
                    <Filedossier
                      index={i}
                      file={file}
                      handleRemove={removeFile}
                    />
                  ))}
                </div>
              </div>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={handleChooseFile}
              />
            </div>
            <div className={styles.footer}>
              <div className={styles.button}>
                <Button
                  loading={false}
                  className={styles.btn2}
                  disabled={false}
                  onClick={() => {
                    handleRedirect();
                  }}
                  // text="Wyślij"
                  element={
                    <>
                      <BIcon /> Wyślij
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  // return ReactDOM.createPortal(
  //   <div className={styles.modal}>
  //     <div className={styles.window}>
  //       <div className={styles.wrapper}>
  //         <div className={styles.title}>
  //           Przekaż
  //           <CloseIcon onClick={onCancel} style={{ cursor: 'pointer' }} />
  //         </div>

  //         <div className={styles.input}>
  //           <div className={styles.group}>
  //             <SearchMemberNew
  //               label="Wyznacz DO"
  //               selected={user}
  //               onChoose={handleClickNode}
  //             />
  //             <div className={styles.addWrapper}>
  //               {!isCases && (secondMsg === null || !showSecondUser) && (
  //                 <div
  //                   onClick={() => setShowModal(!showModal)}
  //                   className={styles.add}
  //                 >
  //                   + DW
  //                 </div>
  //               )}
  //               {showModal && (
  //                 <div ref={outsideRef} className={styles.dropdown}>
  //                   {!showSecondUser && (
  //                     <div
  //                       onClick={() => {
  //                         setShowSecondUser(true);
  //                         setShowModal(false);
  //                       }}
  //                       className={styles.box}
  //                     >
  //                       Wybierz osobę
  //                     </div>
  //                   )}
  //                   {secondMsg === null && (
  //                     <div
  //                       onClick={() => {
  //                         setShowSecondUser(true);
  //                         setShowModal(false);
  //                         setSecondMsg('');
  //                       }}
  //                       className={styles.box}
  //                     >
  //                       Uwagi
  //                     </div>
  //                   )}
  //                 </div>
  //               )}
  //             </div>
  //           </div>

  //           <TextArea
  //             value={msg}
  //             label="Dekret do"
  //             onChange={(t) => {
  //               setMsg(t);
  //             }}
  //           />

  //           {showSecondUser && (
  //             <div className={styles.separator}>
  //               <div className={styles.group}>
  //                 <SearchMemberNew
  //                   label="Wyznacz DW"
  //                   onChoose={handleClickSecondNode}
  //                 />
  //               </div>

  //               {secondUser && (
  //                 <div className={styles.secondUser}>
  //                   <img src={Placeholder} /> {secondUser?.Label}
  //                 </div>
  //               )}
  //             </div>
  //           )}

  //           {secondMsg !== null && (
  //             <TextArea
  //               value={secondMsg}
  //               label="Uwagi DW"
  //               onChange={(t) => {
  //                 setSecondMsg(t);
  //               }}
  //             />
  //           )}

  //           {/* <div onClick={() => setDel(!del)} className={styles.checkbox}>
  //             <SecondCheckbox
  //               onChange={() => {
  //                 // setDel(!del)
  //               }}
  //               value={del}
  //             />
  //             Po przekazaniu usuń z listy
  //           </div> */}
  //           <div className={styles.btnWrapper}>
  //             <Button
  //               disabled={!user}
  //               onClick={handleRedirect}
  //               text="Przekaż"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>,
  //   document.body
  // );
};
