import TaskBox from 'components/TaskBox';
import moment from 'moment';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ITask } from 'dto/ITask';

const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

const days = [
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
  'Niedziela',
];

const ActionBox: FC<Props> = ({
  date,
  items,
  handleChangeStatus,
  handleDelete,
  handleChangeFlag,
  handleUpdateTask,
  handleAddTag,
  handleRemoveTag,
  refetch,
}) => {
  if (!date) {
    return <></>;
  }

  const dateToCompare = moment(date);
  const today = moment(new Date());

  const isActualDay = dateToCompare.startOf('day').isSame(today.startOf('day'));

  return (
    <div className={styles.box}>
      <div className={styles.date}>
        <div className={`${isActualDay ? styles.actual : styles.day}`}>
          {moment(date).format('DD')}
        </div>
        <div className={styles.month}>
          <div className={styles.weekDay}>{days[moment(date).weekday()]}</div>
          <div className={styles.weekMonth}>
            {months[moment(date).month()]} {moment(date).year()}
          </div>
        </div>
        {/* {moment(date).format('DD')}
        <div className={styles.day}>{months[moment(date).month()]}</div>
        {moment(date).format('YYYY')} */}
      </div>
      <div className={`${styles.wrapper} ${styles.allDay}`}>
        <div className={styles.hours}>Całodn.</div>
        <div className={styles.card}>
          {items
            .filter((p: ITask) => p.AllDay)
            .map((item: any) => (
              <TaskBox
                key={item.Id}
                refetch={refetch}
                handleAddTag={handleAddTag}
                handleRemoveTag={handleRemoveTag}
                handleUpdateTask={handleUpdateTask}
                handleChangeFlag={handleChangeFlag}
                handleDelete={handleDelete}
                handleChangeStatus={handleChangeStatus}
                task={item}
              />
            ))}
        </div>
      </div>

      {items
        .filter((p: ITask) => !p.AllDay)
        .map((item: ITask) => {
          return (
            <div key={item.Id} className={styles.wrapper}>
              <div className={styles.hours}>
                {moment(item.FromDate).format('HH:mm')}
                {item.ToDate && <> - {moment(item.ToDate).format('HH:mm')}</>}
              </div>
              <div className={styles.card}>
                <TaskBox
                  refetch={refetch}
                  handleAddTag={handleAddTag}
                  handleRemoveTag={handleRemoveTag}
                  handleUpdateTask={handleUpdateTask}
                  handleChangeFlag={handleChangeFlag}
                  handleDelete={handleDelete}
                  handleChangeStatus={handleChangeStatus}
                  task={item}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ActionBox;
