import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as WindowsIcon } from 'assets/icons/windows.svg';
import { ReactComponent as MacIcon } from 'assets/icons/mac.svg';

const PluginUpdate: FC<Props> = ({
  handleClose,
  isWindows,
  isMac,
  linkWindows,
  linkMac,
}) => {
  return (
    <div className={styles.modal}>
      <CloseIcon onClick={handleClose} className={styles.closeIcon} />
      <div className={`${styles.window}`}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Zaaktualizuj wtyczkę
            <div className={styles.bar}></div>
          </div>
          <div className={styles.body}>
            Zaaktualizuj wtyczkę Noty dla swojego systemu operacyjnego.
          </div>

          <div className={styles.system}>
            {!isMac && (
              <a rel="noreferrer" target="_blank" href={linkWindows}>
                <div className={styles.box}>
                  <WindowsIcon />
                  Windows
                </div>
              </a>
            )}
            {!isMac && !isWindows && <div className={styles.line}></div>}
            {!isWindows && (
              <a rel="noreferrer" target="_blank" href={linkMac}>
                <div className={styles.box}>
                  <MacIcon />
                  MacOS
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PluginUpdate;
