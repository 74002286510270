import { FC, Props } from './typings';
import styles from './styles.module.scss';

import {
  DatePicker,
  DateTimePicker,
  DesktopDateTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_ios.svg';
import { ReactComponent as DayIcon } from 'assets/icons/dayIcon.svg';
import Input from 'components/Input';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

registerLocale('pl', pl);

const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

interface ITime {
  hour: string;
  minute: string;
}

const isTimeValid = (time: ITime) => {
  const hourInt = parseInt(time.hour, 10);
  const minuteInt = parseInt(time.minute, 10);

  if (hourInt >= 0 && hourInt <= 23 && minuteInt >= 0 && minuteInt <= 59) {
    return true;
  }
  return false;
};

const CustomTimeInput = ({
  time,
  setTime,
  handleSave,
  closeWithoutSave,
}: {
  time: ITime;
  setTime: (t: ITime) => void;
  handleSave: () => void;
  closeWithoutSave: () => void;
}) => {
  return (
    <div className={styles.downWrapper}>
      <div
        className={`${styles.timePicker} ${!isTimeValid(time) && styles.wrong}`}
      >
        <input
          type="number"
          min={0}
          max={23}
          className={styles.time}
          value={time.hour}
          onChange={(e) => {
            setTime({ ...time, hour: e.target.value });
          }}
        />
        <div className={styles.separator}>:</div>
        <input
          type="number"
          min={0}
          max={23}
          className={styles.time}
          value={time.minute}
          onChange={(e) => {
            setTime({ ...time, minute: e.target.value });
          }}
        />
      </div>
      <div className={styles.buttons}>
        <div onClick={closeWithoutSave} className={styles.button}>
          Anuluj
        </div>
        <div onClick={handleSave} className={styles.button}>
          Zapisz
        </div>
      </div>
    </div>
  );
};

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
}: {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  changeYear: any;
}) => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.monthWrapper}>
        <ArrowIcon onClick={decreaseMonth} className={styles.icon} />
        {months[moment(date).month()]}
        <ArrowIcon
          onClick={increaseMonth}
          className={`${styles.icon} ${styles.reverse}`}
        />
      </div>
      <div className={styles.yearWrapper}>
        {' '}
        <ArrowIcon
          onClick={() => {
            changeYear(moment(date).subtract(1, 'year').format('YYYY'));
          }}
          className={styles.icon}
        />
        {moment(date).year()}{' '}
        <ArrowIcon
          onClick={() => {
            changeYear(moment(date).add(1, 'year').format('YYYY'));
          }}
          className={`${styles.icon} ${styles.reverse}`}
        />
      </div>
    </div>
  );
};

const DatePickerComponent: FC<Props> = ({
  showTime = false,
  date,
  setDate,
  label,
  className = '',
  onClick,
  focus = false,
  inputRef,
  isOpen,
  setIsOpen,
}) => {
  const theme = createTheme({
    palette: {
      action: {
        hover: 'rgb(255, 0, 0)',
      },
      primary: {
        main: 'rgb(255, 0, 0)',
      },
      secondary: {
        main: 'rgb(0, 255, 0)',
      },
    },
  });

  const [time, setTime] = useState({
    hour: '12',
    minute: '00',
  });

  const [notSavedDate, setNotSavedDate] = useState(date ?? new Date());

  useEffect(() => {
    if (!date) return;
    setNotSavedDate(date);
    setTime({
      hour: moment(date).get('hour').toString(),
      minute: moment(date).get('minute').toString(),
    });
  }, [date]);

  let format = 'HH:mm | dd.MM.yyyy';

  if (!showTime) {
    format = 'dd.MM.yyyy';
  }

  const handleChangeDate = (date: Date | null) => {
    if (showTime) {
      if (!isTimeValid(time)) return;
      const comparedDate = moment(date)
        .hour(Number(time.hour))
        .minute(Number(time.minute))
        .toDate();

      setNotSavedDate(comparedDate || new Date());

      return;
    }

    setNotSavedDate(date || new Date());
  };

  useEffect(() => {
    handleChangeDate(notSavedDate);
  }, [date, time, showTime]);

  useEffect(() => {
    if (!inputRef?.current || !focus) return;
    //@ts-ignore
    inputRef.current.focus();
  }, [focus, inputRef]);

  const ref = useRef(null);

  const h = () => {
    onClick?.(ref);
  };

  if (showTime) {
    return (
      <div className={styles.wrp}>
        <DatePicker
          inputRef={inputRef}
          slotProps={{
            textField: {
              variant: 'standard',
              onClick: h,
            },
          }}
          value={date ? moment(date) : undefined}
          disableOpenPicker
          // defaultValue={date ? moment(date) : undefined}
          className={styles.picker}
          format="DD-MM-YYYY"
          label="Data"
          onChange={(e: any) => {
            if (date) {
              const hour = moment(date).get('hour').toString();
              const minute = moment(date).get('minute').toString();
              setDate(
                moment(e).hour(Number(hour)).minute(Number(minute)).toDate()
              );
              return;
            }
            setDate(moment(e).toDate());
          }}

          // defaultValue={}
        />

        <ThemeProvider theme={theme}>
          <TimePicker
            onOpen={() => {
              setIsOpen?.(true);
            }}
            onClose={() => {
              setIsOpen?.(false);
            }}
            slotProps={{ textField: { variant: 'standard' } }}
            label="Godzina"
            onChange={(e) => {
              if (date) {
                const hour = moment(e).get('hour').toString();
                const minute = moment(e).get('minute').toString();
                setDate(
                  moment(date)
                    .hour(Number(hour))
                    .minute(Number(minute))
                    .toDate()
                );
                return;
              }

              setDate(moment(date).toDate());
            }}
            ampm={false}
            value={date ? moment(date) : undefined}
          />
        </ThemeProvider>
      </div>
    );
  } else {
    return (
      <DatePicker
        format="DD-MM-YYYY"
        defaultValue={date ? moment(date) : undefined}
        className={styles.picker}
        onChange={(e: any) => {
          setDate(moment(e).toDate());
        }}
        // defaultValue={}
      />
    );
  }
};

export default DatePickerComponent;
