import { axiosInstance } from 'api/axios';
import { ICase } from 'dto/Cases/ICase';
import { useAuth } from 'react-oidc-context';
import { Context, Hub } from 'react-signalr/src/signalr/types';
import { decryptPrivateKey } from 'utils/decryptPrivateKey';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import * as openpgp from 'openpgp';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { readAsBase64 } from 'utils/readAsBase64';
import { logError } from 'utils/logger';

const getCaseKey = async (myKeys: any, participant: any) => {
  const c = await axiosInstance.get(`case?id=${participant.caseId}`);
  const keys = c.data.Keys[0];
  const privateKey = keys.PrivateKey;
  const keyId = keys.Id;
  try {
    return {
      keyId: keyId,
      privateKey: await decryptPrivateKey(myKeys!, privateKey),
    };
  } catch (e) {
    return null;
  }
};

const getInterKey = async (participant: any) => {
  const interKey = await axiosInstance.post('/account/intermediateKeys', {
    UserIds: [participant.userId],
  });
  const publicUserKey = interKey?.data?.Keys?.[0]?.PublicKey;

  if (!publicUserKey) return;
  //

  const armored = await readFileFromBase64(publicUserKey, 'application/other');
  return new TextDecoder().decode(armored);
};

const InvitationToCaseManager = ({
  SignalRContext,
}: {
  SignalRContext: Context<Hub<string, string>>;
}) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);

  SignalRContext.useSignalREffect(
    'caseSharesToApprove',
    async (data) => {
      const myKeys = await getKeys(userId!);

      for (const participant of data) {
        const caseKey = await getCaseKey(myKeys, participant);

        if (caseKey === null) {
          logError(
            `[InvitationToCaseManager] Cannot generate key for user Id: ${participant.id} userId: ${participant.userId} case ${participant.caseId} `,
            true
          );
          continue;
        }

        const keyId = caseKey.keyId;
        const decryptedPrivateKey = caseKey.privateKey;

        const interKey = await getInterKey(participant);

        if (!interKey) return;

        const { message } = await openpgp.encrypt({
          message: openpgp.message.fromBinary(decryptedPrivateKey.data),
          publicKeys: [(await openpgp.key.readArmored(interKey)).keys[0]],
          armor: false,
        });

        const uint = await new Uint8Array(message.packets.write());
        const readyToSend = await readAsBase64(uint);

        await axiosInstance.post('/case/share/approve', {
          Id: participant.id,
          Keys: [
            {
              Id: keyId,
              Key: readyToSend,
            },
          ],
        });
      }
    },
    []
  );

  return <></>;
};

export default InvitationToCaseManager;
