export enum Claims {
  Eidas_FamilyName = 'http://eidas.europa.eu/attributes/naturalperson/CurrentFamilyName',
  Eidas_FirstName = 'http://eidas.europa.eu/attributes/naturalperson/CurrentGivenName',
  Eidas_DateOfBirth = 'http://eidas.europa.eu/attributes/naturalperson/DateOfBirth',
  Eidas_PersonIdentifier = 'http://eidas.europa.eu/attributes/naturalperson/PersonIdentifier',
  Eidas_CurrentAddress = 'http://eidas.europa.eu/attributes/naturalperson/CurrentAddress',
  Eidas_IsVerified = 'http://eidas.europa.eu/attributes/naturalperson/IsVerified',

  Eidas_LegalPersonIdentifier = 'http://eidas.europa.eu/attributes/legalperson/LegalPersonIdentifier',

  Eidas_VATRegistration = 'http://eidas.europa.eu/attributes/legalperson/VATRegistrationNumber',

  Nota_HasCourtPortal = 'http://pocztaprawnicz.pl/hasCourtPortal',

  UserProfessionClaim = 'http://pocztaprawnicza.pl/atributes/user/profession',
  UserBarAssociationClaim = 'http://pocztaprawnicza.pl/atributes/user/barassociation',
  UserLicenseClaim = 'http://pocztaprawnicza.pl/atributes/user/licensenumber',

  UserStatusClaim = 'http://pocztaprawnicza.pl/atributes/user/status',
  UserTypeClaim = 'http://pocztaprawnicza.pl/atributes/user/type',

  UserIsSwitchedToAnotherUser = 'http://pocztaprawnicz.pl/atributes/subaccount/switch/active',
  UserSwitchedToUserId = 'http://pocztaprawnicz.pl/atributes/subaccount/userId',
  UserSwitchedToUserLabel = 'http://pocztaprawnicz.pl/atributes/subaccount/label',

  Email = 'email',
  invitation = 'invitation',
}
