import { FC, Props } from './typings';
import { useEffect, useRef, useState } from 'react';

type IWindowProps = {
  url: string;
  title: string;
  width: number;
  height: number;
};

const createPopup = ({ url, title, height, width }: IWindowProps) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  );
  return externalPopup;
};

const OauthPopup: FC<Props> = ({
  url,
  title,
  width,
  height,
  onClose,
  onCode,
  children,
}) => {
  const [externalWindow, setExternalWindow] = useState<Window | null>();
  const intervalRef = useRef<number>();

  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const openWindow = () => {
    setExternalWindow(
      createPopup({
        url,
        title,
        width,
        height,
      })
    );
  };

  useEffect(() => {
    if (url && url.length > 0) {
      openWindow();
    }
  }, [url]);

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const params = new URL(currentUrl).searchParams;
          const code = params.get('code');

          if (!code) {
            return;
          }
          onCode(code, params);
          clearTimer();
          externalWindow.close();
        } catch (error) {
        } finally {
          if (!externalWindow || externalWindow.closed) {
            onClose();
            clearTimer();
          }
        }
      }, 300);
    }
    return () => {
      if (externalWindow) externalWindow.close();
      if (onClose) onClose();
    };
  }, [externalWindow]);

  return <div>{children}</div>;
};

export default OauthPopup;
