import { FC, Props } from './typings';
import styles from './styles.module.scss';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import taskService, { IGetTask } from 'api/taskService';
import { useState, useEffect, useMemo } from 'react';
import { ITask } from 'dto/ITask';
import moment from 'moment';

import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as UnCompleteIcon } from 'assets/icons/unhandled.svg';
import { ReactComponent as CompleteTask } from 'assets/icons/done.svg';
import Loading from 'components/Loading';
import { IChangeHandle } from 'api/messagesService';

const ChatActionBox = ({
  task,
  refetch,
  handleDeleteAction,
}: {
  task: ITask;
  refetch: () => void;
  handleDeleteAction?: () => void;
}) => {
  const [show, setShow] = useState(false);
  const { mutate: completeTask } = taskService.useComplete();
  const { mutate: UnCompleteTask } = taskService.useUncomplete();
  const { mutate: deleteTask } = taskService.useDeleteTask();
  let format = 'DD.MM.YYYY | HH:mm';

  if (task.AllDay) {
    format = 'DD.MM.YYYY';
  }

  const handleClickComplete = () => {
    if (task.CompleteDate) {
      UnCompleteTask(
        {
          Id: task.Id,
        },
        {
          onSuccess: () => refetch(),
        }
      );
      return;
    }
    completeTask(
      {
        Id: task.Id,
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  const handleDelete = () => {
    deleteTask(
      {
        Id: task.Id,
        MoveToTrash: false,
      },
      {
        onSuccess: () => {
          refetch();
          handleDeleteAction?.();
        },
      }
    );
  };

  return (
    <div className={styles.box}>
      <div className={styles.color}></div>
      <div className={styles.info}>
        <div className={styles.title}>{task.Title} </div>
        <div className={styles.note}>{task.Note} </div>
      </div>
    </div>
  );

  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className={styles.box}
    >
      {show && (
        <div className={styles.options}>
          {task.CompleteDate ? (
            <UnCompleteIcon
              onClick={(e) => {
                handleClickComplete();
                e.stopPropagation();
              }}
            />
          ) : (
            <CompleteTask
              onClick={(e) => {
                handleClickComplete();
                e.stopPropagation();
              }}
            />
          )}
          <BinIcon
            onClick={(e) => {
              handleDelete();
              e.stopPropagation();
            }}
          />
        </div>
      )}

      <div className={styles.dates}>
        {task.FromDate ? moment(task.FromDate).format(format).toString() : ''}{' '}
        {task.FromDate && '-'}
        {task.ToDate && moment(task.ToDate).format(format).toString()}
      </div>

      <div className={styles.title}>{task.Note} </div>
    </div>
  );
};

const ChatActionsList: FC<Props> = ({
  onDelete,
  toggle,
  changeView,
  pack,
  x,
  y,
}) => {
  const [fetched, setFetched] = useState(false);
  const { data, isLoading, isFetching, refetch } = taskService.useGetTask({
    OwnerId: pack.Id,
    OwnerKind: pack.Kind,
  });

  const tasks = useMemo(() => data?.data ?? [], [data]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        Czynności
        <CloseIcon
          className={styles.closeIcon}
          onClick={(e) => {
            toggle(false);
            e.stopPropagation();
          }}
        />
      </div>
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading />
        </div>
      ) : (
        <div className={styles.list}>
          {tasks.map((task: ITask) => (
            <ChatActionBox
              handleDeleteAction={onDelete}
              refetch={refetch}
              task={task}
            />
          ))}
        </div>
      )}

      <div
        onClick={(e) => {
          changeView();
          e.stopPropagation();
        }}
        className={styles.button}
      >
        Nowa Czynność
      </div>
    </div>
  );
};

export default ChatActionsList;
