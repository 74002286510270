import { ReactComponent as ArrowIcon } from 'assets/icons/arrow_ios.svg';
import moment from 'moment';
import { useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

const months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

const MonthList: FC<Props> = ({ date, handleChangeDate }) => {
  const actualYear = moment().year().toString();

  const [actualDate, setActualDate] = useState(date);

  const handleChangeMonth = (month: number) => {
    const d = moment(actualDate)
      .set('month', month + 1)
      .startOf('month')
      .toDate();
    handleChangeDate(d);
  };

  const handleIncrementYear = () => {
    const newD = moment(actualDate).add(1, 'year');
    setActualDate(newD.toDate());
  };

  const handleDecreseYear = () => {
    const newD = moment(actualDate).subtract(1, 'year');
    setActualDate(newD.toDate());
  };

  return (
    <div className={styles.monthsWrapper}>
      <div className={styles.actDate}>
        <ArrowIcon onClick={handleDecreseYear} className={`${styles.icon} `} />
        {moment(actualDate).year().toString()}
        <ArrowIcon
          onClick={handleIncrementYear}
          className={`${styles.icon} ${styles.reverse}`}
        />
      </div>
      <div className={styles.monthList}>
        {months.map((m, index) => (
          <div
            key={m}
            onClick={() => handleChangeMonth(index)}
            className={`${styles.month} ${
              moment(date).month() === index &&
              moment(date).year() === moment(actualDate).year() &&
              styles.activeMonth
            }`}
          >
            {m}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthList;
