import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Button from 'components/Button';
import { EClientData } from 'dto/IClient';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { ReactComponent as StarIcon } from 'assets/icons/star.svg';

const PhoneClientModal: FC<Props> = ({ client, refWrapper }) => {
  if (!refWrapper?.current) return <></>;

  const position = refWrapper.current.getBoundingClientRect();

  const phones = client.Data.filter((d) => d.Type === EClientData.PHONE);

  let number =
    '+48 ' +
    client.Phone?.slice(0, 3) +
    ' ' +
    client.Phone?.slice(3, 6) +
    ' ' +
    client.Phone?.slice(6);

  return ReactDOM.createPortal(
    <div
      style={{
        left: `${position.left}px`,
        top: `${position.top + 32}px`,
      }}
      className={styles.wrapper}
    >
      <div className={styles.title}>
        Telefon
        <CloseIcon className={styles.icon} />
      </div>
      {phones.map((p) => (
        <div className={styles.box}>
          {p.Value} {p.IsMain && <StarIcon className={styles.icone} />}
        </div>
      ))}
    </div>,
    document.body
  );
};

export default PhoneClientModal;
