import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as HashIcon } from 'assets/icons/hash2.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import tagsService from 'api/tagsService';
import { ITag, TagType } from 'dto/ITag';
import NewInput from 'components/NewInput';
import { useOnClickOutside } from 'usehooks-ts';
import { getWorkspaceId } from 'utils/getWorkspaceId';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Box } from '@mui/material';
import AutoComplete from 'components/AutoComplete';
import taskService from 'api/taskService';
import { ENotificationType, notification } from 'utils/notification';
import { ITask } from 'dto/ITask';
import TagBadge from 'components/TagBadge';

const ChooseTagNew: FC<Props> = ({
  label = 'Szukaj etykiety',
  toggle,
  updateTags,
  toTag,
  isTask = false,
  afterAddTag,
  afterRemoveTag,
  onChoose,
  type,
}) => {
  const currentWorkspaceId = getWorkspaceId();
  const [active, setActive] = useState(false);
  const { mutate: createTag } = tagsService.useCreateTag();
  const { data: tagsData } = tagsService.useGetTags(
    false,
    type ?? TagType.Other,
    undefined,
    currentWorkspaceId ?? ''
  );

  const [tags, setTags] = useState<ITag[]>([]);
  const allTags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const [search, setSearch] = useState<ITag[]>([]);
  const [value, setValue] = useState('');

  const outsideRef = useRef(null);

  const { mutate: editTags } = tagsService.useAddTag();
  const { mutate: editTask } = taskService.useUpdateTask();

  const handleAddTag = (tag: ITag) => {
    if (!toTag) return;

    const newTags = [...tags, tag];

    if (isTask) {
      const task = toTag as ITask;
      editTask({
        Id: task.Id,
        Allday: task.AllDay,
        FromDate: task.FromDate,
        IsFavourite: task.IsFavourite,
        Note: task.Note,
        OwnerId: task.OwnerId,
        OwnerKind: task.Kind,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
        ToDate: task.ToDate,
      });
      afterAddTag?.(tag.Id);
      setTags(newTags);
      return;
    }

    editTags(
      {
        OwnerKind: toTag.Kind,
        NewTags: newTags
          .filter((tag) => tag.Id === '-1')
          .map((tag) => tag.Name),
        OwnerId: toTag.Id,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
      },
      {
        onSuccess: (r) => {
          if (r.data) {
            updateTags?.(r.data.map((t: ITag) => t));
          }
        },
      }
    );
    afterAddTag?.(tag.Id);
    setTags(newTags);
    setValue('');
  };

  // useOnClickOutside(outsideRef, () => {
  //   if (active) {
  //     setActive(false);
  //   }

  //   if (onClose && !label) {
  //     onClose();
  //   }
  // });

  useEffect(() => {
    if (!value.length) {
      setSearch(allTags);
      return;
    }

    setSearch(
      allTags.filter(
        (tag: ITag) =>
          tag.Name.toLocaleLowerCase().search(value.toLocaleLowerCase()) !== -1
      )
    );
  }, [allTags, value]);

  useEffect(() => {
    if (toTag) {
      setTags(allTags.filter((t: ITag) => (toTag.TagIds ?? []).includes(t.Id)));
    }
  }, [allTags, toTag]);

  const handleRemoveTag = (rtag: ITag) => {
    if (!toTag) return;

    const newTags = tags.filter((tag) => tag.Name !== rtag.Name);

    if (isTask) {
      const task = toTag as ITask;
      editTask({
        Id: task.Id,
        Allday: task.AllDay,
        FromDate: task.FromDate,
        IsFavourite: task.IsFavourite,
        Note: task.Note,
        OwnerId: task.OwnerId,
        OwnerKind: task.Kind,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
        ToDate: task.ToDate,
      });
      afterRemoveTag?.(rtag.Id);
      setTags(newTags);
      return;
    }

    editTags(
      {
        OwnerKind: toTag.Kind,
        NewTags: newTags
          .filter((tag) => tag.Id === '-1')
          .map((tag) => tag.Name),
        OwnerId: toTag.Id,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
      },
      {
        onSuccess: (r) => {
          if (r.data) {
            updateTags?.(r.data.map((t: ITag) => t));
          }
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
    afterRemoveTag?.(rtag.Id);
    setTags(newTags);
  };

  return (
    <div ref={outsideRef} className={`${styles.wrapper}`}>
      <AutoComplete
        label={'Szukaj etykiety'}
        options={search.map((s) => {
          return {
            id: s.Id,
            label: s.Name,
          };
        })}
        onChange={(text) => {
          setValue(text);
        }}
        onChoose={(id: string) => {
          const selected = search.find((tag) => tag.Id === id);
          if (toTag) handleAddTag(selected!);
          else onChoose?.(selected!);
          setActive(false);
        }}
        value={value}
      />
      <div className={styles.activeList}>
        {tags.map((tag) => (
          <TagBadge
            key={tag.Id}
            tag={tag}
            onRemove={() => handleRemoveTag(tag)}
            allwaysShowRemove={true}
          />
        ))}
      </div>
    </div>
  );
};

export default ChooseTagNew;
