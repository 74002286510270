import ModalLayout, { DockPosition } from 'components/ModalLayout';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useContext, useRef, useState } from 'react';
import SearchCasesNew from 'components/SearchCasesNew';
import Input from 'components/Input';
import { Editor } from '@tinymce/tinymce-react';
import SearchMemberNew from 'components/SearchMemberNew';
import ChooseTagNew from 'components/ChooseTagNew';
import { ITag } from 'dto/ITag';
import { IWorkspaceMember } from 'dto/IWorkspace';
import Chip from 'components/Chip';
import Button from 'components/Button';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import DatePickerNew from 'components/DatePickerNew';
import moment from 'moment';
import taskService, { IUpdateTaskPayload } from 'api/taskService';
import ModalsManagerContext from 'contexts/ModalsManager';
import { ITask } from 'dto/ITask';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import PackagesContext from 'contexts/PackagesContext';
import { Avatar } from '@mui/material';
import { convertToLetters } from 'utils/convertToLetters';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task.svg';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import { getPrivilages } from 'utils/getPrivilages';
import SearchMemberInWorkspace from 'components/SearchMemberInWorkspace';
import TextArea from 'components/TextArea';
import DatePickerComponent from 'components/DatePicker';
import SecondCheckbox from 'components/SecondCheckbox';

const CreateTaskModal: FC<Props> = ({ initialValues, onClose, pack }) => {
  const refWrapper = useRef(null);
  const { mutate: createTask, isLoading: isCreateTask } =
    taskService.useUpdateTask();
  const { mutate: updateTask, isLoading: isUpdateTask } =
    taskService.useUpdateEvent();
  const [c, setCase] = useState<ICaseSimple | null>(null);
  const [title, setTitle] = useState(initialValues?.title ?? '');
  const [text, setText] = useState(initialValues?.content ?? '');

  const [content, setContent] = useState(initialValues?.content ?? '');
  const [tags, setTags] = useState<ITag[]>(initialValues?.tags ?? []);
  const [members, setMembers] = useState<IWorkspaceMember[]>([]);

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const { modals, setModals } = useContext(ModalsManagerContext);
  const { packages, setPackages } = useContext(PackagesContext);

  const [fullDay, setFullDay] = useState(false);
  const [withoutHour, setWithoutHour] = useState(false);

  const { workspace } = useContext(WorkspaceContext);

  const myPrivilages = getPrivilages(workspace?.Roles[0]);

  const [isCalendarEvent, setIsCalendarEvent] = useState<boolean>(true);
  const [member, setMember] = useState<IWorkspaceMember | null>(null);

  const Menu = () => {
    return (
      <div className={styles.switchbox}>
        <div
          onClick={() => setIsCalendarEvent(true)}
          className={`${styles.switch}  ${
            isCalendarEvent === true && styles.active
          }`}
        >
          <CalendarIcon /> Wydarzenie
        </div>
        <div
          onClick={() => setIsCalendarEvent(false)}
          className={`${styles.switch}  ${
            isCalendarEvent === false && styles.active
          }`}
        >
          <TaskIcon /> Zadanie
        </div>
        {/* <div
          onClick={() => setActualView(ETabs.TEAM)}
          className={`${styles.box}  ${
            actualView === ETabs.TEAM && styles.activeText
          }`}
        >
          <MembersIcon /> Zespół
        </div> */}
      </div>
    );
  };

  const closeModal = (task?: ITask) => {
    if (onClose && task) {
      onClose(task);
      return;
    }
    setModals({ ...modals, hide: true });
  };

  const handleCreateTask = () => {
    let payload: IUpdateTaskPayload = {
      Id: initialValues ? initialValues.taskId : null,
      OwnerId: pack ? pack.Id : c?.Id ?? '',
      OwnerKind: pack ? pack.Kind : c?.Kind ?? 0,
      Allday: false,
      FromDate: moment(startDate).toISOString(true),
      IsFavourite: true,
      Title: title,
      Note: content,
      TagIds: tags.map((tag) => tag.Id),
      ToDate: endDate ? moment(endDate).toISOString(true) : null,
      isTask: true,
      ForUserId: members.at(0) ? members.at(0)?.UserId : undefined,
      WorkspaceId: members.at(0) ? getWorkspaceId() ?? '' : undefined,
    };

    if (initialValues) {
      updateTask(payload, {
        onSuccess: (response) => {
          closeModal(response.data);
        },
      });

      return;
    }

    createTask(payload, {
      onSuccess: () => {
        if (pack) {
          const newPacks = [...packages];
          const p = newPacks.find((a) => a.Id === pack.Id);
          if (p) {
            p.HasTasks = true;
            setPackages(newPacks);
          }
        }

        closeModal();
      },
    });
  };

  return (
    <ModalLayout
      dock={DockPosition.DISABLED}
      off={true}
      customX={1}
      customY={0}
      ignore
      refWrapper={refWrapper}
      isAnimation
      fullHeight={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Nowe zadanie
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.content}>
          <Menu />
          {isCalendarEvent && (
            <>
              <div className={styles.inputs}>
                <SearchCasesNew
                  settedCase={c}
                  setAddNewEntity={() => {
                    // setShowCase(true);
                  }}
                  onChoose={(cas) => {
                    // setFieldValue('case', cas);
                    // setFieldTouched('case', true);
                    setCase(cas);
                  }}
                />

                <Input
                  value={title}
                  onChange={(e) => {
                    setTitle(e);
                  }}
                  label="Tytuł"
                />

                <Editor
                  apiKey="12k7ybb0o4007p3nah1s77v7gmrbalfhy4jreucu2nky2l3b"
                  onInit={(evt, editor) => {}}
                  value={content}
                  onEditorChange={(text) => {
                    setContent(text);
                  }}
                  init={{
                    forced_root_block: 'div',
                    height: 370,
                    menubar: false,
                    statusbar: false,
                    resize: false,
                    browser_spellcheck: true,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'code',
                      'help',
                      'imageTools',
                      'quickbars',
                    ],
                    toolbar_persist: true,
                    fixed_toolbar_container_target: document.body,

                    contextmenu: 'table',
                    contextmenu_never_use_native: true,
                    automatic_uploads: true,
                    images_reuse_filename: true,
                    paste_data_images: true,
                    images_upload_handler: (blobInfo, success): any => {
                      const base64str =
                        'data:' +
                        blobInfo.blob().type +
                        ';base64,' +
                        blobInfo.base64();

                      //@ts-ignore
                      success(base64str);
                    },
                    toolbar: `bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor | indent outdent | bullist numlist | table`,
                    content_style:
                      '.mce-content-body { background: #F4EFF4;} .tox-editor-header { background: #F4EFF4!important;} .tox .tox-tbtn svg {fill: #ff0000!important;}',
                  }}
                />
              </div>
              <div className={styles.controls}>
                <ChooseTagNew
                  type={0}
                  onChoose={(tag) => {
                    const isExist = tags.find((t) => t.Name === tag?.Name);
                    if (isExist) return;
                    setTags([...tags, tag!]);
                  }}
                />

                <SearchMemberNew
                  onChoose={(member) => {
                    setMembers([...members, member as IWorkspaceMember]);
                  }}
                />

                <DatePickerNew
                  showTime
                  startOf={startDate}
                  endOf={endDate}
                  setDate={(type, date) => {
                    if (type === 1) {
                      setStartDate(date);

                      if (startDate === null) {
                        setEndDate(moment(date).add(30, 'minutes').toDate());
                      }

                      if (moment(date).isAfter(endDate)) {
                        setEndDate(moment(date).add(30, 'minutes').toDate());
                      }
                    }
                    if (type === 2) {
                      setEndDate(date);

                      if (moment(date).isBefore(startDate)) {
                        setStartDate(moment(date).toDate());
                      }
                    }
                  }}
                />
              </div>
              <div className={styles.list}>
                {tags.map((tag) => (
                  <>
                    <Chip
                      element={<>{tag.Name}</>}
                      onDelete={() => {
                        setTags(tags.filter((t) => t.Id !== tag.Id));
                      }}
                    />
                  </>
                ))}
                {members.map((member) => (
                  <Chip
                    avatar={
                      <Avatar className={styles.avatar}>
                        {convertToLetters(member.Label)}
                      </Avatar>
                    }
                    element={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        {member.Label}
                      </div>
                    }
                    onDelete={() => {
                      setMembers(members.filter((f) => f.Id !== member.Id));
                    }}
                  />
                ))}
              </div>
            </>
          )}
          {!isCalendarEvent && (
            <>
              {
                <SearchCasesNew
                  settedCase={c}
                  setAddNewEntity={() => {
                    // setShowCase(true);
                  }}
                  onChoose={(cas) => {
                    // setFieldValue('case', cas);
                    // setFieldTouched('case', true);
                    setCase(cas);
                  }}
                />
              }

              {myPrivilages.canTaskAssign && (
                <SearchMemberInWorkspace
                  onChoose={(member) => {
                    setMember(member);
                  }}
                />
              )}

              <TextArea
                className={styles.textArea}
                label={''}
                value={text}
                placeholder="Dodaj opis czynności. Wpisując # dodaj tag do czynności bądź @ wyznacz osobę"
                onChange={(text) => setText(text)}
                minRows={4}
              />
              <div className={styles.dateWrapper}>
                Od:
                <DatePickerComponent
                  showTime={!fullDay}
                  date={startDate}
                  setDate={setStartDate}
                />
              </div>
              {!fullDay && !withoutHour && (
                <div className={styles.dateWrapper}>
                  Do:
                  <DatePickerComponent
                    showTime={!fullDay}
                    date={endDate ?? new Date()}
                    setDate={setEndDate}
                  />
                </div>
              )}
              <div className={styles.checkbox}>
                <SecondCheckbox
                  value={fullDay}
                  onChange={(state) => setFullDay(state)}
                  text="Całodniowy"
                />
                {/* <SecondCheckbox
            value={withoutHour}
            onChange={(state) => setWithoutHour(state)}
            text="Bez godziny zakończenia"
          /> */}
              </div>
            </>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.buttonWrapper}>
            <Button
              loading={isCreateTask || isUpdateTask}
              onClick={handleCreateTask}
              text={initialValues ? 'Edytuj' : 'Utwórz'}
            />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CreateTaskModal;
