import { FC, Props } from './typings';
import styles from './styles.module.scss';
import Sidebar from 'components/Sidebar';
import PanelsLayout from 'components/PanelsLayout';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const ApplicationLayout: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [isScroll, setIsScroll] = useState(false);
  const auth = useAuth();
  const isGuest = auth?.user?.profile?.role === 'guest';

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      isGuest &&
      window.location.pathname.search('activation') === -1
    ) {
      navigate('/packages/1B8AE8D3-3209-497D-9526-27608FFFA84D/incoming');
    }
  }, [isGuest]);

  const isFullScreen = window.location.href.search('fullscreen') !== -1;
  if (isFullScreen) {
    return children;
  }

  const leftPanel = () => {
    return (
      <div className={styles.menuWrapper}>
        <Sidebar setIsScroll={setIsScroll} />
      </div>
    );
  };

  const rightPanel = () => {
    return <div className={styles.applicationWrapper}>{children}</div>;
  };

  return (
    <div className={styles.application}>
      <PanelsLayout
        leftPanel={leftPanel()}
        rightPanel={rightPanel()}
        layoutName="main"
        leftClassName={styles.left}
        defaultLeft={20}
        disableHeader={isFullScreen ? false : true}
        disableIcons
        disablePaddingScroll={isScroll}
      />
    </div>
  );
};

export default ApplicationLayout;
