import GridWindow from 'components/GridWindow';
import styles from './styles.module.scss';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { FC, Props } from './typings';
import { ReactComponent as CaseIcon } from 'assets/icons/case_sidebar.svg';
import { ReactComponent as IncomingIcon } from 'assets/icons/file_download.svg';
import { ReactComponent as OutgoingIcon } from 'assets/icons/file_upload.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calenar_sidebar.svg';

import { ReactComponent as LettersIcon } from 'assets/icons/letters.svg';
import PreviewMarkedDeskWindow from 'components/PreviewMarkedDeskWindow';
import { useState } from 'react';
import deskService from 'api/deskService';

export enum ITypeOfWindow {
  CASES = 'CASES',
  INCOMING = 'INCOMING',
  OUTCOMING = 'OUTCOMING',
  ACTIONS = 'ACTIONS',
  LETTERS = 'LETTERS',
}

const Box = ({
  Icon,
  name,
  onClick,
  counter,
}: {
  Icon: any;
  name: string;
  onClick: () => void;
  counter: number;
}) => {
  return (
    <div onClick={onClick} className={styles.box}>
      <div className={styles.boxWrapper}>
        <div className={styles.left}>
          <div className={styles.icon}>
            <Icon />
          </div>
          {name}
        </div>
        <div className={styles.right}>
          <div className={styles.counter}>{counter}</div>
        </div>
      </div>
    </div>
  );
};

const MarkedDeskWindowSmall: FC<Props> = ({ id, isEditMode, handleRemove }) => {
  const [type, setType] = useState<ITypeOfWindow | null>(null);

  const { data: counterData } = deskService.useGetCount();

  return (
    <>
      <GridWindow
        handleRemove={handleRemove}
        id={id}
        isEditMode={isEditMode}
        title={
          <>
            {' '}
            <DeskIcon /> Przypięte
          </>
        }
      >
        <div className={styles.wrapper}>
          <Box
            onClick={() => setType(ITypeOfWindow.CASES)}
            name={'Sprawy'}
            Icon={CaseIcon}
            counter={counterData?.data?.cases}
          />
          <Box
            onClick={() => setType(ITypeOfWindow.INCOMING)}
            name={'Przychodzące'}
            Icon={IncomingIcon}
            counter={counterData?.data?.incoming}
          />
          <Box
            onClick={() => setType(ITypeOfWindow.OUTCOMING)}
            name={'Wychodzące'}
            Icon={OutgoingIcon}
            counter={counterData?.data?.outgoing}
          />
          <Box
            onClick={() => setType(ITypeOfWindow.ACTIONS)}
            name={'Czynności'}
            Icon={CalendarIcon}
            counter={counterData?.data?.tasks}
          />
          <Box
            onClick={() => setType(ITypeOfWindow.LETTERS)}
            name={'Pisma'}
            Icon={LettersIcon}
            counter={counterData?.data?.editorDocuments}
          />
        </div>
      </GridWindow>
      {type && (
        <PreviewMarkedDeskWindow toggle={() => setType(null)} type={type} />
      )}
    </>
  );
};

export default MarkedDeskWindowSmall;
