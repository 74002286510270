import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

const Checkbox: FC<Props> = ({ onChange, value }) => {
  const [active, setActive] = useState<boolean>(value ?? false);

  useEffect(() => {
    if (!value) return;
    setActive(value);
  }, [value]);

  const handleClick = () => {
    onChange?.(!active);
    setActive(!active);
  };

  return (
    <div
      onClick={() => handleClick()}
      className={classNames(styles.checkboxWrapper, active && styles.active)}
    >
      <div className={styles.dot}></div>
    </div>
  );
};

export default Checkbox;
