import Avatar from 'components/Avatar';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as IncomingIcon } from 'assets/icons/file_download.svg';
import { ReactComponent as OutgoingIcon } from 'assets/icons/file_upload.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/case_sidebar.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import casesService from 'api/casesService';
import filterService from 'api/filterService';
import Button from 'components/Button';
import { ListType } from 'dto/ListType';
import Input from 'components/Input';
import { axiosInstance } from 'api/axios';

const Dropbox = ({
  id,
  Icon,
  label,
  onChoose,
  onClick,
}: {
  id: ListType;
  Icon: any;
  label: string;
  onChoose?: (
    filterId: string,
    filterName: string,
    filterIsMain: boolean
  ) => void;
  onClick?: () => void;
}) => {
  const [show, setShow] = useState(false);

  const { mutate: fetchFilters, isLoading } =
    filterService.useGetFiltersForCategory();

  const { mutate: fetchCases } = casesService.useGetFiltersForCases();

  const [filters, setFilters] = useState<any>([]);
  const [casesFilters, setCasesFilters] = useState<any>([]);

  useEffect(() => {
    console.log('odpalam');
    var allFilter = {
      Id:
        id === 1
          ? '1B8AE8D3-3209-497D-9526-27608FFFA84D'
          : id === 2
          ? '2E7191EA-F56D-4DA9-924F-9334A1C153F3'
          : id === 3
          ? '6B6DEE1A-DD11-4E6A-9B6D-61429867A17B'
          : '728739AD-37D7-4E59-8AA1-2B6DE0EEF88A',
      List: id,
      Name: 'Wszystkie',
      Static: true,
      Main: true,
      Visible: true,
    };

    fetchFilters(
      {
        id: id,
      },
      {
        onSuccess: (data) => {
          setFilters([allFilter, ...data.data]);
        },
      }
    );

    fetchCases(
      {
        id: id,
      },
      {
        onSuccess: (data) => {
          setCasesFilters([allFilter, ...data.data]);
        },
      }
    );
  }, [id]);

  return (
    <div className={styles.dropbox}>
      <div
        onClick={() => {
          if (onClick) {
            onClick();
            return;
          }
          setShow(!show);
        }}
        className={styles.card}
      >
        <div className={styles.cardWrapper}>
          <div className={styles.cardName}>
            {Icon} {label}
          </div>
          {onChoose && (
            <div className={styles.cardArrow}>
              <FontAwesomeIcon icon={show ? faCaretDown : faCaretRight} />
            </div>
          )}
        </div>
      </div>
      {show && (
        <div className={styles.cardDropdown}>
          {id === 3
            ? casesFilters.map((f: any) => {
                return (
                  <div
                    onClick={() => {
                      onChoose?.(f.Id, f.Name, f.Main);

                      setShow(false);
                    }}
                    className={styles.cardBox}
                  >
                    {f.Name}
                  </div>
                );
              })
            : filters.map((f: any) => {
                return (
                  <div
                    onClick={() => {
                      onChoose?.(f.Id, f.Name, f.Main);
                      setShow(false);
                    }}
                    className={styles.cardBox}
                  >
                    {f.Name}
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
};

const PermissionUser: FC<Props> = ({ toggle, user, handleAdd }) => {
  const [writeList, setWriteList] = useState<
    {
      filterId: string;
      filterName: string;
      isWrite: boolean;
      id: number;
      main: boolean;
    }[]
  >([]);

  useEffect(() => {
    const isExistReadOnly = user.filters.find((f) => !f.isWrite);

    if (isExistReadOnly) {
      setAddRead(true);
    }

    setWriteList(user.filters);
  }, [user]);

  const [isRead, setAddRead] = useState(false);
  const [subAccount, setSubAccount] = useState(false);
  const [pesel, setPesel] = useState('');

  const getIcon = (id: number) => {
    switch (id) {
      case 1:
        return <IncomingIcon />;
        break;

      case 2:
        return <OutgoingIcon />;
        break;
      case 3:
        return <CaseIcon />;
        break;
    }
  };

  const handleAddSubAccount = async () => {
    const response = await axiosInstance.post(
      '/subaccount/createForCourtPortal',
      {
        FirstName: user.label.split(' ')[0],
        LastName: user.label.split(' ')[1],
        Email: 'test@gmail.com',
        Pesel: pesel,
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <div className={styles.data}>
          <Avatar name={user.label} />
          <div className={styles.name}>
            <div className={styles.label}>{user.label}</div>
            <div className={styles.role}>Rola</div>
          </div>
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.title}>Upoważnienia do edycji</div>
      <div className={styles.list}>
        <Dropbox
          id={1}
          Icon={<IncomingIcon />}
          label="Przesyłki przychodzące"
          onChoose={(filterId, fName, fMain) => {
            var existingFilter = writeList.find(
              (x) => x.filterId == filterId && x.isWrite
            );

            var existingMain = writeList.find((x) => x.main && x.isWrite);

            if (!existingFilter && !existingMain) {
              setWriteList([
                ...writeList.filter((a) => !a.isWrite || !fMain),
                {
                  filterId,
                  isWrite: true,
                  id: 1,
                  filterName: fName,
                  main: fMain,
                },
              ]);
            }
          }}
        />
        <Dropbox
          onChoose={(filterId, fName, fMain) => {
            var existingFilter = writeList.find(
              (x) => x.filterId == filterId && x.isWrite
            );

            var existingMain = writeList.find((x) => x.main && x.isWrite);

            if (!existingFilter && !existingMain) {
              setWriteList([
                ...writeList.filter((a) => !a.isWrite || !fMain),
                {
                  filterId,
                  isWrite: true,
                  id: 2,
                  filterName: fName,
                  main: fMain,
                },
              ]);
            }
          }}
          id={2}
          Icon={<OutgoingIcon />}
          label="Przesyłki wychodzące"
        />
        <Dropbox
          onChoose={(filterId, fName, fMain) => {
            var existingFilter = writeList.find(
              (x) => x.filterId == filterId && x.isWrite
            );

            var existingMain = writeList.find((x) => x.main && x.isWrite);

            if (!existingFilter && !existingMain) {
              setWriteList([
                ...writeList.filter((a) => !a.isWrite || !fMain),
                {
                  filterId,
                  isWrite: true,
                  id: 3,
                  filterName: fName,
                  main: fMain,
                },
              ]);
            }
          }}
          id={2}
          Icon={<CaseIcon />}
          label="Teczki"
        />
        <Dropbox
          onClick={() => {
            setSubAccount(true);
          }}
          id={0}
          Icon={<CaseIcon />}
          label="Subkonto w PI"
        />
      </div>

      <div className={styles.tags}>
        {writeList &&
          writeList
            .filter((w) => w.isWrite)
            .map((w) => (
              <div
                onClick={() => {
                  setWriteList(
                    writeList.filter(
                      (a) => a.filterId !== w.filterId && w.isWrite
                    )
                  );
                }}
                className={styles.tag}
              >
                {getIcon(w.id)} {w.filterName} <CloseIcon />
              </div>
            ))}
      </div>
      {subAccount && (
        <div className={styles.sub}>
          <div className={styles.input}>
            <Input
              value={pesel}
              onChange={(t) => setPesel(t)}
              placeholder="Pesel"
              label=""
            />
            <Button
              className={styles.subButton}
              onClick={handleAddSubAccount}
              text="Utwórz subkonto"
            />
          </div>
          <div className={styles.subText}>
            Wpisz PESEL niezweryfikowanego użytkownika aby stworzyć subkonto w
            PI
          </div>
        </div>
      )}
      <div className={styles.line}></div>
      <div className={styles.title}>Upoważnienia do wglądu</div>
      {!isRead && (
        <div
          onClick={() => {
            setAddRead(true);
          }}
          className={styles.cta}
        >
          + Dodaj upoważnienie do wglądu
        </div>
      )}
      {isRead && (
        <>
          <div className={styles.list}>
            <Dropbox
              id={1}
              Icon={<IncomingIcon />}
              label="Przesyłki przychodzące"
              onChoose={(filterId, fName, fMain) => {
                var existingFilter = writeList.find(
                  (x) => x.filterId == filterId && !x.isWrite
                );

                var existingMain = writeList.find((x) => x.main && !x.isWrite);

                if (!existingFilter && !existingMain) {
                  setWriteList([
                    ...writeList.filter((a) => a.isWrite || !fMain),
                    {
                      filterId,
                      isWrite: false,
                      id: 1,
                      filterName: fName,
                      main: fMain,
                    },
                  ]);
                }
              }}
            />
            <Dropbox
              onChoose={(filterId, fName, fMain) => {
                var existingFilter = writeList.find(
                  (x) => x.filterId == filterId && !x.isWrite
                );

                var existingMain = writeList.find((x) => x.main && !x.isWrite);

                if (!existingFilter && !existingMain) {
                  setWriteList([
                    ...writeList.filter((a) => a.isWrite || !fMain),
                    {
                      filterId,
                      isWrite: false,
                      id: 2,
                      filterName: fName,
                      main: fMain,
                    },
                  ]);
                }
              }}
              id={2}
              Icon={<OutgoingIcon />}
              label="Przesyłki wychodzące"
            />
            <Dropbox
              onChoose={(filterId, fName, fMain) => {
                var existingFilter = writeList.find(
                  (x) => x.filterId == filterId && !x.isWrite
                );

                var existingMain = writeList.find((x) => x.main && !x.isWrite);

                if (!existingFilter && !existingMain) {
                  setWriteList([
                    ...writeList.filter((a) => a.isWrite || !fMain),
                    {
                      filterId,
                      isWrite: false,
                      id: 3,
                      filterName: fName,
                      main: fMain,
                    },
                  ]);
                }
              }}
              id={2}
              Icon={<CaseIcon />}
              label="Teczki"
            />
            <Dropbox onClick={() => {}} id={0} Icon={<CaseIcon />} label="PI" />
          </div>

          <div className={styles.tags}>
            {writeList &&
              writeList
                .filter((w) => !w.isWrite)
                .map((w) => (
                  <div
                    onClick={() => {
                      setWriteList(
                        writeList.filter(
                          (a) => a.filterId !== w.filterId && !w.isWrite
                        )
                      );
                    }}
                    className={styles.tag}
                  >
                    {getIcon(w.id)} {w.filterName} <CloseIcon />
                  </div>
                ))}
          </div>
        </>
      )}
      <div className={styles.buttons}>
        <Button
          onClick={() => {
            toggle();
          }}
          className={styles.cancelButton}
          text="Anuluj"
        />
        <Button
          onClick={() => {
            handleAdd(user.userId, writeList);
          }}
          className={styles.acceptButton}
          text="Zapisz"
        />
      </div>
    </div>
  );
};

export default PermissionUser;
