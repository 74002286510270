export type { FC } from 'react';

export interface Props {
  showTabBar: (v: boolean) => void;
}


export enum EViews {
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY'
}