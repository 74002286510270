import moment from "moment";
import { useState } from "react";

const useCalendar = () => {
    const [dates, setDates] = useState({
        start: moment().startOf('month').toISOString(),
        end: moment().endOf('month').toISOString(),
    });





    return {dates, setDates}
}

export default useCalendar;