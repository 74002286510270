import Stepper from 'components/Stepper';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as NewLogo } from 'assets/icons/new_logo.svg';

const AuthLayout: FC<Props> = ({ steps, actualStep, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftBar}>
        <NewLogo className={styles.logo} />
        <Stepper steps={steps} actualStep={actualStep} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AuthLayout;
