import classNames from 'classnames';
import moment from 'moment';

import { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import EmailService from 'api/emailService';
import listService from 'api/listService';
import messagesService from 'api/messagesService';
import tagsService from 'api/tagsService';
import { ReactComponent as DirectionIcon } from 'assets/icons/msg_incoming.svg';
import Attachment from 'components/Attachment';
import Avatar from 'components/Avatar';
import IconsState from 'components/IconsState';
import ModalLayout from 'components/ModalLayout';
import OptionsCard from 'components/OptionsCard/OptionsCard';
import SecondCheckbox from 'components/SecondCheckbox';
import StatusBadge from 'components/StatusBadge';
import StatusModal from 'components/StatusModal';
import TagBadge from 'components/TagBadge';
import { IAttachment } from 'dto/IAttachment';
import { ItemKind } from 'dto/IKindItems';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { decryptPackage } from 'utils/decryptPackage';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import PackagesContext from 'contexts/PackagesContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsis,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import MenuContext from 'contexts/MenuContext';
import { Draggable } from 'react-beautiful-dnd';
import { addToUrl, parseHTML } from 'utils/parseHTML';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { decodeAndFetchImages } from 'utils/decodeAndFetchImages';
import { decryptPrivateKey } from 'utils/decryptPrivateKey';
import { getKeys } from 'utils/getKeys';
import { useNavigate, useParams } from 'react-router';
import CasesContext from 'contexts/CasesContext';

const ChatCard: FC<Props> = ({
  handleChangePackages,
  editMode = false,
  isSelected = false,
  Package: pack,
  statuses,
  tags,
  keys,
  refetch,
  onDownload,
  onPreview,
  index,
  setPack,
}) => {
  const { caseId, view } = useParams();
  const auth = useAuth();
  const refWrapper = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [createAction, setCreateAction] = useState(false);
  const [showActionList, setShowActionList] = useState(false);
  const [status, setStatus] = useState<IStatus | null>(null);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);
  const { mutate: changeHandle } = messagesService.useChangeHandle();
  const { mutate: deleteList } = listService.useDeleteList();
  const { mutate: removeTag } = tagsService.useRemoveTagFromPackage();
  const [showFlags, setShowFlags] = useState(false);
  const statusRef = useRef(null);

  const { cases } = useContext(CasesContext);

  const Case = cases.find((c) => c.Id === caseId);

  const canEdit =
    auth?.user?.profile?.role === 'guest' ? Case?.Privileges === 2 : true;

  const [content, setContent] = useState('');

  const { data: previewData } = EmailService.useGetPreview(
    pack.Id ?? '',
    pack?.Kind === ItemKind.Email && expanded ? true : false
  );

  const userId = getUserId(auth.user!);

  const decodeEncryptedContent = async () => {
    const myKeys = await getKeys(userId!);
    const objectKey = previewData?.data?.ObjectKeys?.[0];

    let parentKey = null;

    if (objectKey?.ParentKeyId) {
      parentKey = previewData?.data?.Keys.find(
        (k: any) => k?.Id === objectKey.ParentKeyId
      );
    }

    const content = await decryptPackage(
      userId,
      previewData?.data.Content,
      objectKey,
      parentKey
    );

    const images = await decodeAndFetchImages(
      userId,
      previewData?.data,
      objectKey,
      parentKey
    );

    setContent(await parseHTML(content, images));
  };

  const getContent = async () => {
    if (pack.Kind === 128) {
      const isEncrypted = previewData?.data?.IsEncrypted;

      if (isEncrypted) {
        // setAllowImages(true);
        decodeEncryptedContent();
        return;
      }
      // const isImages = await hasImages(previewData?.data?.Content);

      // if (!canImages && isImages) {
      // setAllowImages(false);
      // }

      setContent(await addToUrl(previewData?.data?.Content, true));

      return;
    }

    if (!pack.EncryptionKeyId) {
      setContent(previewData?.data?.Content);
      return;
    }

    // typy wiadomości szyfrowanych
    // todo: isEncrypted w IPackage
    if (pack.EncryptionKeyId) {
      try {
        const r = atob(pack.S2);

        if (!keys) {
          return;
        }

        const encryptionKey = keys.find(
          (key) => key?.Id === pack?.EncryptionKeyId
        );

        let parentKey = null;
        if (encryptionKey?.ParentKeyId) {
          parentKey = keys.find(
            (key) => key?.Id === encryptionKey?.ParentKeyId
          );
        }

        setContent(
          await decryptPackage(userId, pack.S2, encryptionKey, parentKey)
        );
        return;
      } catch (e) {
        setContent(pack.S2);
        return;
      }
    } else {
      //setContent(pack.S2 ?? '');
    }
  };

  useEffect(() => {
    // if (pack.Unread) {
    //   let newPacks = [...case];
    //   let newPack = newPacks.find((p) => p.Id === pack.Id);
    //   if (!newPack) {
    //     return;
    //   }
    //   newPack.Unread = false;
    // }

    // if (!expanded) return;
    getContent();
  }, [pack, isSelected, keys, previewData, expanded]);

  useEffect(() => {
    const t = tags?.filter((tag) =>
      pack?.TagIds?.find((iTag) => tag.Id === iTag)
    );

    const newStatus = statuses.find((s) => s.Id === pack.StatusId);
    setFindedTags(t);

    setStatus(newStatus ?? null);
  }, [statuses, pack.StatusId]);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        OwnerId: pack.Id,
        OwnerKind: pack.Kind,
        TagId: tag.Id,
      },
      {
        onSuccess: () => {
          setFindedTags(findedTags.filter((ftag) => ftag.Id !== tag.Id));
        },
      }
    );
  };

  const getLead = () => {
    return pack?.S2;
  };

  const handleClickComplete = () => {
    changeHandle(
      {
        Handled: !pack.IsHandled,
        Items: [{ Id: pack.Id, Kind: pack.Kind }],
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  // useEffect(() => {
  //   if (!refWrapper?.current) return;

  //   const rect = ref.current.getBoundingClientRect();
  //   setPosition([rect.x + rect.width / 2, rect.y + 40]);
  // }, [ref, pack]);

  const handleChangeView = () => {
    if (showActionList) {
      setShowActionList(false);
      setCreateAction(true);
      return;
    }
    setShowActionList(true);
    setCreateAction(false);
  };

  const handleDelete = () => {
    deleteList(
      {
        Items: [{ Id: pack.Id, Kind: pack.Kind }],
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  const onNewWindow = async (attach: IAttachment) =>
    window.open(
      `${process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'}/pdf/${
        pack.Kind
      }/${pack.Id}/${attach.Id}`
    );

  const { mutate: manageDossier } = listService.useManageDossier();
  const handleAddOnePackage = (attachment: IAttachment) => {
    manageDossier({
      AttachmentIds: [attachment.Id],
      OwnerId: pack.Id,
      OwnerKind: pack.Kind,
      Show: !attachment.ShowInDossier,
    });

    // todo: odświeżanie
    // let newPacks = [...packages];
    // let el = newPacks.find((p) => p.Id === pack.Id);
    // if (el) {
    //   let attach = el.Attachments.find((e) => e.Id === attachment.Id);

    //   if (attach) {
    //     attach.ShowInDossier = Number(!attachment.ShowInDossier);
    //     setPackages(newPacks);
    //   }
    // }
  };

  const { packages, setPackages } = useContext(PackagesContext);

  const { id, setId } = useContext(MenuContext);

  useEffect(() => {
    if (id !== pack.Id) {
      setShowOptions(false);
    }
  }, [id]);

  const isFullScreen = window.location.href.search('fullscreen') !== -1;
  const navigate = useNavigate();

  return (
    <>
      <Draggable index={index} key={pack.Id} draggableId={pack.Id}>
        {(provided, snapshot) => {
          // provided.draggableProps.style = {
          //   ...provided.draggableProps.style,
          // };

          return (
            <div
              onClick={() => {
                if (isFullScreen) {
                  setPack?.({
                    id: pack.Id,
                    kind: pack.Kind,
                  });
                  navigate(`/fullscreen/cases/${caseId}/${view}`);
                }
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <div
                key={pack.Id}
                ref={refWrapper}
                className={classNames(
                  styles.wrapper,
                  !pack.IsIncoming && styles.outgoing
                )}
                // onMouseEnter={() => setShowOptions(true)}
                // onMouseLeave={() => setShowOptions(false)}
              >
                <div className={styles.direction}>
                  <DirectionIcon></DirectionIcon>
                </div>

                {refWrapper.current && (
                  <OptionsCard
                    selectedElements={[]}
                    binds
                    showOptions={showOptions}
                    pack={packages.find((p) => p.Id === pack.Id) ?? pack}
                    refetch={() => {}}
                    refWrapper={refWrapper}
                    onClickAttachIcon={() => {}}
                    dropdown
                    isCase
                  />
                )}
                <div className={styles.checkbox}>
                  {editMode && (
                    <SecondCheckbox
                      value={isSelected}
                      onChange={(s) => handleChangePackages?.(pack, s)}
                    />
                  )}
                </div>
                <div
                  className={classNames(
                    styles.card,
                    expanded && styles.scrollable,
                    expanded && styles.expanded
                  )}
                >
                  <div className={styles.header} {...provided.dragHandleProps}>
                    {expanded && (
                      <Avatar
                        className={styles.avatar}
                        name={pack.Sender ?? ''}
                      />
                    )}
                    <div className={styles.rows}>
                      <div
                        onClick={(e) => {
                          if (setPack) return;
                          setExpanded(!expanded);
                        }}
                        className={styles.row1}
                      >
                        <div className={styles.sender}>
                          {pack.IsIncoming
                            ? pack?.Sender
                            : pack?.Receivers?.filter((a) => !a.Cc)
                                .map((r) =>
                                  !!r.Name.length ? r.Name : r.Email
                                )
                                .join(', ')}
                        </div>
                        <div className={styles.date}>
                          {moment(pack?.SendDate)
                            .format('DD.MM.YYYY')
                            .toString()}

                          {canEdit && (
                            <div
                              onClick={() => {
                                setShowOptions(!showOptions);
                                setId(pack.Id);
                              }}
                              className={styles.hover}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.row2}>
                        <div
                          className={styles.title}
                          onClick={(e) => {
                            if (setPack) return;
                            setExpanded(!expanded);
                          }}
                        >
                          {pack?.S1}
                        </div>
                        <div className={styles.icons}>
                          <IconsState
                            onDownload={onDownload}
                            onPreview={onPreview}
                            keys={keys}
                            msg={packages.find((p) => p.Id === pack.Id) ?? pack}
                            showCaseIcon={false}
                          />
                        </div>
                      </div>
                      {pack.Receivers &&
                        !!pack.Receivers.filter((c) => c.Cc).length && (
                          <div className={styles.receivers}>
                            <span>Dw: </span>
                            {pack.Receivers.filter((r) => r.Cc)
                              .map((r) => r.Name ?? r.Email)
                              .join(', ')}{' '}
                          </div>
                        )}
                      <div className={styles.row3}>
                        {expanded && status && (
                          <div className={styles.badgesWrapperBorder}>
                            {status && (
                              <div
                                onClick={() => {
                                  setShowFlags(!showFlags);
                                }}
                                ref={statusRef}
                                className={styles.badge}
                              >
                                <StatusBadge
                                  showName={true}
                                  onRemove={() => {}}
                                  status={status}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {expanded && !!findedTags.length && (
                          <div className={styles.tags}>
                            {findedTags.map((tag) => (
                              <TagBadge
                                onRemove={(tag) => handleRemoveTag(tag)}
                                tag={tag}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {expanded && (
                    <div className={styles.borderLine}>
                      <div className={styles.line}></div>
                    </div>
                  )}
                  {!setPack && expanded && (
                    <FontAwesomeIcon
                      className={styles.expander}
                      onClick={() => {
                        if (setPack) return;
                        setExpanded(!expanded);
                      }}
                      icon={faEllipsis}
                    />
                  )}
                  <div className={styles.body}>
                    {!expanded && status && (
                      <div className={styles.badgesWrapper}>
                        {status && (
                          <div
                            onClick={(e) => {
                              setShowFlags(!showFlags);
                              e.stopPropagation();
                            }}
                            ref={statusRef}
                            className={styles.badge}
                          >
                            {/* <StatusBadge onRemove={() => {}} status={status} /> */}
                          </div>
                        )}
                      </div>
                    )}
                    {!expanded && !!findedTags.length && (
                      <div className={styles.tags}>
                        {findedTags.map((tag) => (
                          <TagBadge
                            onRemove={(tag) => handleRemoveTag(tag)}
                            tag={tag}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  {expanded && (
                    <>
                      <div
                        className={styles.lead}
                        dangerouslySetInnerHTML={{
                          __html:
                            content !==
                            '<html><head></head><body>undefined</body></html>'
                              ? content
                              : '',
                        }}
                        onClick={(e) => {
                          if (setPack) return;
                          // setExpanded(!expanded);
                        }}
                      ></div>
                      <div className={styles.attachments}>
                        {pack.Attachments?.map((attachment) => (
                          <div className={styles.attach}>
                            <Attachment
                              onAddDossier={
                                !!pack?.Case ? handleAddOnePackage : undefined
                              }
                              date={moment(
                                pack.ReceiveDate ?? pack.CreationDate
                              )
                                .format('HH:MM | DD.MM.YYYY')
                                .toString()}
                              onNewWindow={onNewWindow}
                              onPreview={(attach) => onPreview(pack, attach)}
                              onDownload={(attach) => onDownload(pack, attach)}
                              attachment={attachment}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  {!setPack && (
                    <div>
                      <FontAwesomeIcon
                        className={styles.expander}
                        onClick={() => {
                          if (setPack) return;
                          setExpanded(!expanded);
                        }}
                        icon={faEllipsis}
                      />
                    </div>
                  )}

                  {showFlags && (
                    <ModalLayout
                      customX={0}
                      customY={-30}
                      refWrapper={statusRef}
                    >
                      <StatusModal
                        toggle={setShowFlags}
                        item={pack}
                        selectedStatus={status}
                      />
                    </ModalLayout>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
    </>
  );
};

export default ChatCard;
