import { IUser } from 'dto/IUser';
import * as openpgp from 'openpgp';
import { readFileFromBase64 } from './readFileFromBase64';
import { readAsBase64 } from './readAsBase64';


export interface ISingleBinaryKeys {
    PublicKey: string; 
    PrivateKey: string;
    cleanPublicKey: openpgp.key.KeyResult
    cleanPrivateKey: openpgp.key.KeyResult
}



export const generateSingleBinary = async (masterPubKeyBase64?: string, intermidatePrivateKey?: openpgp.DecryptResult, publicKey?: any): Promise<ISingleBinaryKeys> => {

    let encryptKey = null;

    if(masterPubKeyBase64) {
        const masterPublicKey = await readFileFromBase64(masterPubKeyBase64, 'application/other');

        
        encryptKey = await openpgp.key.read(new Uint8Array(masterPublicKey));
        
    }

    if(intermidatePrivateKey) {
        encryptKey = await openpgp.key.readArmored(
            atob(intermidatePrivateKey.data)
          );
    }

    if(publicKey) {
        // 
        encryptKey = publicKey
    }

    if(!encryptKey) throw new Error("Something was wrong with creatiing new keys missing encryption key");
    
    let options: openpgp.KeyOptions = {
        userIds: [{
            name: '1'
        }],
        curve: 'secp256k1',
        numBits: 2048
    }

        const key = await openpgp.generateKey(options);
        const pubDecoded = await openpgp.key.readArmored(key.publicKeyArmored);
        const privDecoded = await openpgp.key.readArmored(key.privateKeyArmored);
        const publicKeyResultString = new Uint8Array(pubDecoded.keys[0].toPacketlist().write());
        const keyResultString = new Uint8Array(privDecoded.keys[0].toPacketlist().write());
        const { message } = await openpgp.encrypt({
            message: await openpgp.message.fromBinary(keyResultString),
            publicKeys: [encryptKey.keys[0]],
            armor: false,
        });
        
        return {
            PublicKey: await readAsBase64(publicKeyResultString),
            PrivateKey: await readAsBase64(new Uint8Array(message.packets.write())),
            cleanPublicKey: pubDecoded,
            cleanPrivateKey: privDecoded,

        }
}