import * as openpgp from 'openpgp';
import { Base64 } from 'base64-string';
import CryptoJS from 'crypto-js';
import { IRecoveryPackage } from 'dto/IRecoveryPackage';

interface IRecoveryPackageResponse {
  IntermediatePrivateKey: string;
  IntermediatePublicKey: string;
  Package: string;
}

export interface IDecryptedKeys {
  publicKeyArmored: string;
  privateKeyArmored: string;
  recoveryPackage: IRecoveryPackage;
  privateKey: openpgp.key.Key;
  publicKey: openpgp.key.KeyResult;
  publicBase64Key: string;
  privateBase64Key: string;
  masterKey?: openpgp.key.KeyResult;
  intermidatePrivateKey?: openpgp.DecryptResult;
}

export const decryptKeys = async (
  recoveryPackage: IRecoveryPackageResponse,
  recoveryKey: string
) => {
  try {
    const enc = new Base64();
    const publicKeyArmored = enc.decode(recoveryPackage.IntermediatePublicKey);
    const privateKeyArmored = await enc.decode(
      recoveryPackage.IntermediatePrivateKey
    );

    const recovery = await CryptoJS.AES.decrypt(
      recoveryPackage.Package,
      recoveryKey
    );
    const encryptedPackage: IRecoveryPackage = JSON.parse(
      recovery.toString(CryptoJS.enc.Utf8)
    );
    const publicKey = await openpgp.key.readArmored(publicKeyArmored);

    const {
      keys: [privateKey],
    } = await openpgp.key.readArmored(enc.decode(encryptedPackage.private_key));
    await privateKey.decrypt(encryptedPackage.passphrase);

    return {
      publicBase64Key: recoveryPackage.IntermediatePublicKey,
      privateBase64Key: recoveryPackage.IntermediatePrivateKey,
      publicKeyArmored: publicKeyArmored,
      privateKeyArmored: privateKeyArmored,
      publicKey: publicKey,
      recoveryPackage: encryptedPackage,
      privateKey: privateKey,
    };
  } catch (e) {
    throw e;
  }
};
