import Button from 'components/Button';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import ReactDOM from 'react-dom';
import SecondCheckbox from 'components/SecondCheckbox';
import { useEffect, useState } from 'react';

const ConfirmModal: FC<Props> = ({
  title,
  text,
  onAccept,
  onCancel,
  acceptLabel,
  cancelLabel,
}) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    localStorage.setItem('ask', String(value));
  }, [value]);

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{text}</div>

        <div className={styles.checkbox}>
          <SecondCheckbox onChange={() => setValue(!value)} value={value} /> Nie
          pytaj więcej
        </div>

        <div className={styles.buttonsWrapper}>
          <div onClick={onCancel} className={styles.button}>
            {cancelLabel}
          </div>
          <Button
            className={styles.buttonColor}
            onClick={onAccept}
            text={acceptLabel}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmModal;
