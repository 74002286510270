import { useMutation, useQuery } from "react-query";
import { axiosInstance } from "./axios";
import moment from "moment";
import { IPackage } from "dto/IPackage";

const queryKeys = {
    get: (caseId: string) => ['threadsService.get', caseId],
  };


  
  const get = (caseId: string) => {
    return axiosInstance.get(`/threads?CaseId=${caseId}`)
  }

  const useGet = (caseId: string) => {
    return useQuery(queryKeys.get(caseId), () => get(caseId))
  }

  interface IUpdateItem {
    Id: string;
    Kind: number;
  }

  export interface IUpdateList {
    Id: string;
    Items: IUpdateItem[]
  }

  const updateList = (payload: IUpdateList) => {
    return axiosInstance.put('/thread/items', payload)
  }

  const useUpdateList = () => {
    return useMutation(updateList);
  }


export interface ICreateThread {
  CaseId: string;
  Label: string;
    Items: IUpdateItem[]
}

  const createThread = (payload: ICreateThread) => {
    return axiosInstance.post("/thread", payload)
  }

  const useCreateThread = () => {
    return useMutation(createThread)
  }

  export interface IAddToThread {
    threadId: string;
    chatId: string;
    chatKind: number
  }

  const addToThread = (payload: IAddToThread) => {
    return axiosInstance.post(`/thread/${payload.threadId}`, {
      Id: payload.chatId,
      Kind: payload.chatKind
    });
  }

  const useAddToThread = () => {
    return useMutation(addToThread);
  }

  export interface IDeleteFromThread {
    threadId: string;
    chatId: string;
    chatKind: number
  }


  const deleteFromThread = (payload: IDeleteFromThread) => {
    return axiosInstance.delete(`/thread/${payload.threadId}`, {
      data: {

        Id: payload.chatId,
        Kind: payload.chatKind
      }
    });
  }

  const useDeleteFromThread = () => {
    return useMutation(deleteFromThread)
  }

  export interface IMove {
    SourceId: string;
    TargetId: string;
    ItemId: string;
    Kind: number
  }

  const move = (payload: IMove) => {
    return axiosInstance.post('/thread/move', payload)
  }

  const useMove = () => {
    return useMutation(move);
  }

  export default {
    useGet,
    useUpdateList,
    useCreateThread,
    useAddToThread,
    useDeleteFromThread,
    useMove
  } 