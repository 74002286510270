import { FC, Props } from './typings';
import styles from './styles.module.scss';
import DossierFolder from 'components/DossierFolder';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const DossierFolders: FC<Props> = ({
  refetch,
  destination,
  files,
  folders,
  statuses,
  tags,
  canSelect,
  onSelect,
  onDoubleClick,
  selectedFiles,
  cutting,
  setSelectedFiles,
  handleCopy,
  encryptionKeys,
  parentKeys,
  selectedFolder,
  setSelectedFolder,
  setFiles,
}) => {
  return (
    <Droppable droppableId="folders" type="folders">
      {(provided, snapshot) => (
        <div className={styles.wrapper} ref={provided.innerRef}>
          {folders?.map((folder, index) => (
            <DossierFolder
              setFiles={setFiles}
              handleCopy={handleCopy}
              cutting={cutting}
              setSelectedFiles={setSelectedFiles}
              refetch={refetch}
              destination={destination}
              index={index}
              key={folder.Id}
              folder={folder}
              statuses={statuses}
              tags={tags}
              selectedFiles={selectedFiles}
              canSelect={canSelect}
              onSelect={onSelect}
              onDoubleClick={onDoubleClick}
              encryptionKeys={encryptionKeys}
              parentKeys={parentKeys}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
          ))}
        </div>
      )}
    </Droppable>
  );
};

export default DossierFolders;

// folders.map((folder) => <DossierFolder folder={folder} />)
