import { axiosInstance } from 'api/axios';
import { IFileInitUploadResponse } from 'api/fileService';
import { IEncryptedFile } from 'components/CreateDossierModal/typings';
import CryptoJS from 'crypto-js';
import {
  ISingleBinaryKeys,
  generateSingleBinary,
} from './GenerateSingleBinary';
import encryptFile from './encryptFile';
import { readFileAsBlob } from './readFileAsBlob';
import { IUploadedFile } from 'components/CreateOutgoingParcelModal/typings';
import { createChunks } from './createChunksFromBlob';

const Files = async (
  files: IUploadedFile[],
  publicKey: any,
  useEncrypt: boolean
) => {
  const blobs = await Promise.all(
    files.map(async (file) => {
      const fileAndBlob = await readFileAsBlob(file.file);
      const returnedFile = fileAndBlob[0];
      const returnedBlob = fileAndBlob[1];

      return {
        encryptedFile: useEncrypt
          ? await encryptFile(returnedBlob.target?.result, publicKey)
          : returnedBlob.target?.result,
        orginalFile: returnedFile,
        blob: returnedBlob,
        name: file.name,
        type: file.fileType,
        hash: await CryptoJS.SHA3(
          (returnedBlob.target?.result as string) ?? ''
        ).toString(CryptoJS.enc.Base64),
      };
    })
  );

  return blobs;
};

export const uploadBlob = async (
  files: any[],
  caseId: string,
  publicKey: any,
  useEncrypt: boolean,
  useDecrypt: boolean,
  isSigned: boolean = false
) => {
  if (!files.length) {
    return;
  }

  // Creating keys and uploading to database also in this step we encrypt all files from hook
  const response: any = null;

  const encryptedFiles = await Files(files, publicKey, useEncrypt);
  const uris = await initUpload(
    response?.data?.Id,
    encryptedFiles,
    isSigned,
    useEncrypt
  );
  return uris;
};

// Initialize upload sending information about files
const initUpload = async (
  keyId: string,
  encryptedFiles: IEncryptedFile[],
  isSigned: boolean,
  useEncrypt: boolean
) => {
  return await Promise.all(
    encryptedFiles.map(async (file: IEncryptedFile) => {
      const response = await axiosInstance.post('/file/init', {
        //@ts-ignore
        ContentType: file.type,
        //@ts-ignore
        FileName: file.name,
        Hash: file.hash.replaceAll('"', ''),
        IsSigned: isSigned,
        KeyId: keyId ?? undefined,
        Size: file.orginalFile.size,
      });
      await getLink(response.data, keyId, file);
      return { uri: response.data.Uri, encrypted: useEncrypt };
    })
  );
};

// Getting link to S3
const getLink = async (
  uploadResponse: IFileInitUploadResponse,
  keyId: string,
  file: IEncryptedFile
) => {
  const chunks = createChunks(new Blob([file.orginalFile]));
  const links = [];
  for (const chunk in chunks) {
    const response = await axiosInstance.get(
      `/file/part?uri=${uploadResponse.Uri}&partNumber=${
        Number(chunk) + 1
      }&uploadId=${uploadResponse.UploadId}`
    );
    links.push(response.data);
  }

  await uploadFile(
    uploadResponse.Uri,
    uploadResponse.UploadId,
    links,
    keyId,
    file
  );
};

const uploadFile = async (
  uri: string,
  uploadId: string,
  links: string[],
  keyId: string,
  file: IEncryptedFile
) => {
  const chunks = createChunks(new Blob([file.orginalFile]));

  const etags: string[] = [];

  let sendedChunk = 0;

  for (const chunk of chunks) {
    const etag = await sendChunk(links[sendedChunk], chunk);

    etags.push(etag as string);
    sendedChunk++;
  }

  axiosInstance.post('/file/confirm', {
    UploadId: uploadId,
    Uri: uri,
    Parts: etags.map((etag, index) => ({ Etag: etag, PartNumber: index + 1 })),
    // Parts: [{ ETag: etag!, PartNumber: 1 }],
  });
};

const sendChunk = async (link: string, chunk: Blob) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', link, true);
    xhr.responseType = 'blob';

    // Oczekujemy, że AWS S3 może zwrócić 200 OK, 201 Created lub 204 No Content w odpowiedzi na zapytanie PUT
    xhr.onload = function () {
      if (xhr.status === 200 || xhr.status === 201 || xhr.status === 204) {
        var etag = this.getResponseHeader('ETag');

        // etags.push(etag!);
        resolve(etag?.replaceAll('"', ''));
      } else {
        console.error(
          'Wystąpił błąd podczas wysyłania pliku do AWS S3. Status:',
          xhr.status
        );
      }
    };

    xhr.send(chunk);
  });
};
