import fileService from 'api/fileService';
import listService from 'api/listService';
import pluginService from 'api/pluginService';
import statusService from 'api/statusService';
import tagsService from 'api/tagsService';
import CaseList from 'components/CaseList';
import CaseView from 'components/CaseView';
import CreateCaseModal from 'components/CreateCaseModal';
import PDFViewer from 'components/PDFViever';
import PanelsLayout from 'components/PanelsLayout';
import { config } from 'config';
import CasesContext from 'contexts/CasesContext';
import KeysContext from 'contexts/KeysContext';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IAttachment } from 'dto/IAttachment';
import { ItemKind } from 'dto/IKindItems';
import { IPackage } from 'dto/IPackage';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { blobToBase64 } from 'utils/blobToBase64';
import { decryptFile } from 'utils/decryptFile';
import { fetchEmailAttachment } from 'utils/fetchEmailAttachment';
import { getBlob } from 'utils/getBlob';
import { getUserId } from 'utils/getUserId';
import { isAllowedAttachment } from 'utils/isAllowedAttachment';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import styles from './styles.module.scss';

import { ReactComponent as NotaIcon } from 'assets/icons/nota.svg';
import CPluginContext from 'contexts/PluginContext';
import { IThread } from 'dto/IThread';
import Package from 'components/Package';
import EmptyPanel from 'components/EmptyPanel';
import ModalsManagerContext from 'contexts/ModalsManager';

const CasesModule = ({ hideMenu = false }: { hideMenu?: boolean }) => {
  const navigate = useNavigate();
  const { encryptionKey } = useParams();
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const [searchText, setSearchText] = useState('');
  const [selectedEdit, setSelectedEdit] = useState<ICaseSimple | null>(null);
  const { caseId, view, kind, messageId, attachmentId } = useParams();
  const option: any = localStorage.getItem(config.cases_actions);
  const { modals, setModals } = useContext(ModalsManagerContext);

  const { data: tagsData } = tagsService.useGetTags();
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: statusesData } = statusService.useGetStatuses();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const [createNewThread, setCreateNewThread] = useState(false);

  const [selectedThread, setSelectedThread] = useState<IThread | null>(null);

  const [showPDF, setShowPDF] = useState<Blob | string | null>(null);
  const [sourceFile, setSourceFile] = useState<Blob | string | null>(null);

  const [statusId, setStatusId] = useState<string | null>(null);
  const [tagId, setTagId] = useState<string | null>(null);
  const [entityId, setEntityId] = useState<string | null>(null);

  const { data: previewPackageData, isLoading: isPreviewPackageLoading } =
    listService.useGetPackage({
      FilterId: messageId ?? '',
      Kind: kind ?? '',
      enabled: true,
    });

  const objectKeys = useMemo(
    () => previewPackageData?.data.ObjectKeys ?? [],
    [previewPackageData]
  );
  const { cases, setCases } = useContext(CasesContext);
  const { keys, setKeys } = useContext(KeysContext);

  const [pack, setPack] = useState<{ id: string; kind: number } | null>(null);

  useEffect(() => {
    //@ts-ignore
    setKeys((prev) => [...prev, objectKeys].flat());
  }, [objectKeys]);

  useEffect(() => {
    setShowPDF(null);
    setSourceFile(null);
  }, [messageId, attachmentId]);

  const previewPackage: IPackage = useMemo(
    () => previewPackageData?.data?.Items?.[0] ?? [],
    [previewPackageData]
  );

  useEffect(() => {
    if (!isFullScreen) return;
    if (!!previewPackage) {
      if (
        previewPackage.Kind == ItemKind.CaseFile ||
        previewPackage.Kind === ItemKind.EditorDocument
      ) {
        getFileAndDecrypt(
          previewPackage.S4 ?? previewPackage.S3 ?? '',
          previewPackage.Kind
        );
        return;
      }

      const attach = previewPackage?.Attachments?.find(
        (attachment) => attachment.Id === attachmentId
      );

      if (!attach) return;
      onPreview(attach);
    }
  }, [previewPackage]);

  const { mutate: tryPreview } = pluginService.usePreview();

  const previewFromPlugin = async (blob: Blob, attachment: IAttachment) => {
    if (!plugin.actual) {
      setShowVersion(true);
      return;
    }

    const b: string = (await blobToBase64(blob)) as string;

    tryPreview(
      {
        AttachmentId: attachment.Id,
        Content: b.split(',')[1],
        ContentType: attachment.ContentType,
        DocumentId: attachment.Id,
        FileName: attachment.FileName,
        Kind: attachment.Kind,
      },
      {
        onSuccess: async (response: any) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setShowPDF(new Blob([buffer]));
        },
      }
    );
  };

  const { plugin } = useContext(CPluginContext);
  const [showVersion, setShowVersion] = useState(false);
  const isFullScreen = window.location.href.search('fullscreen') !== -1;

  const getFileAndDecrypt = (uri: string, kind: ItemKind) => {
    getFile(
      {
        id: uri,
        ownerKind: kind,
      },
      {
        onSuccess: async (data) => {
          let parentKey = null;

          if (previewPackageData?.data.ObjectKeys[0]) {
            parentKey = parentKeys.find(
              (pkey: any) =>
                pkey.Id === previewPackageData?.data.ObjectKeys[0].ParentKeyId
            );
          }

          const extension = data.data.FileName.split('.').at(-1);
          const isAllowed = isAllowedAttachment(extension ?? 'no');
          const isPDF = extension === 'pdf';

          const file = await getBlob(data.data.Url);
          try {
            const decryptedBlob = await decryptFile(
              userId,
              new Blob([file.data]),
              previewPackageData?.data.ObjectKeys[0],
              parentKey
            );

            setSourceFile(decryptedBlob);
            if (!isPDF) {
              previewFromPlugin(decryptedBlob, data.data as any);
            }
            setShowPDF(decryptedBlob);
          } catch (e) {
            if (!isPDF) {
              previewFromPlugin(new Blob([file.data]), data.data as any);
              return;
            }
            setShowPDF(new Blob([file.data]));
            setSourceFile(new Blob([file.data]));
          }
        },
      }
    );
  };

  const onPreview = async (attachment: IAttachment) => {
    if (!hideMenu || isFullScreen) return;
    if (previewPackage.Kind === ItemKind.Email) {
      const pdfBlobUrl = await fetchEmailAttachment(
        attachmentId!,
        previewPackage.Id!
      );
      setShowPDF(pdfBlobUrl);
      return;
    }
    getFileAndDecrypt(attachment.Uri, attachment.Kind);
    return;
  };

  const handleChangeSearchBar = (
    text: string,
    statusId: string | null,
    tagId: string | null,
    entityId: string | null
  ) => {
    setSearchText(text);
    setStatusId(statusId);
    setTagId(tagId);
    setEntityId(entityId);
  };
  const { mutate: getFile } = fileService.useGetFiltersForCases();
  const parentKeys = useMemo(
    () => previewPackageData?.data.Keys ?? [],
    [previewPackageData]
  );

  useEffect(() => {
    const elements = JSON.parse(
      sessionStorage.getItem('copy_elements') ?? '[]'
    ).length;

    if (!!elements) {
      navigate(`/cases/${caseId!}/dossier`);
    }
  }, [caseId]);

  const leftPanel = () => {
    return (
      <>
        {!hideMenu && (
          <CaseList
            createNewThread={createNewThread}
            setCreateNewThread={setCreateNewThread}
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
            tags={tags}
            statuses={statuses}
            setSelectedEdit={setSelectedEdit}
            searchText={searchText}
            statusId={statusId}
            tagId={tagId}
            entityId={entityId}
          ></CaseList>
        )}
        {hideMenu && (
          <CaseView
            createNewThread={createNewThread}
            setCreateNewThread={setCreateNewThread}
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
            setSelectedEdit={setSelectedEdit}
            tags={tags}
            statuses={statuses}
            hideMenu={hideMenu}
            showCase
            setPack={setPack}
          ></CaseView>
        )}
      </>
    );
  };

  const rightPanel = () => {
    return (
      <>
        {!hideMenu && (
          <CaseView
            createNewThread={createNewThread}
            setCreateNewThread={setCreateNewThread}
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
            setSelectedEdit={setSelectedEdit}
            tags={tags}
            statuses={statuses}
            hideMenu={hideMenu}
            showCase
          ></CaseView>
        )}
        {hideMenu && encryptionKey && (
          <CaseView
            createNewThread={createNewThread}
            setCreateNewThread={setCreateNewThread}
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
            setSelectedEdit={setSelectedEdit}
            tags={tags}
            statuses={statuses}
            hideMenu={hideMenu}
          ></CaseView>
        )}
        {hideMenu && !messageId && !showPDF && (
          <div className={styles.logo}>
            <NotaIcon className={styles.logoIcon} />
          </div>
        )}
        {showVersion && (
          <div className={styles.pdfWrapper}>
            <PDFViewer
              className={styles.leftFullB}
              file={new Blob([])}
              showHeader={true}
              showVersion={true}
            />
          </div>
        )}
        {hideMenu && messageId && showPDF && !showVersion && (
          <div className={styles.pdfWrapper}>
            <PDFViewer
              className={styles.leftFullB}
              file={showPDF}
              sourceFile={sourceFile}
              showHeader={true}
            />
          </div>
        )}
        {hideMenu && !pack?.id && !messageId && !encryptionKey && (
          <EmptyPanel />
        )}
        {hideMenu && pack?.id && !messageId && !encryptionKey && (
          <Package
            onDownload={() => {}}
            onPreview={(pack, attachment) => {
              navigate(
                `/fullscreen/cases/${caseId}/chat/${pack.Kind}/${pack.Id}/${attachment.Id}`
              );
            }}
            listId={1}
            listName={'TESTOWA'}
            kind={pack.kind.toString()}
            packageId={pack.id}
            showPDF={showPDF}
            setShowPDF={setShowPDF}
            showVersion={showVersion}
            cc={true}
          />
        )}
      </>
    );
  };

  return (
    <>
      {selectedEdit && (
        <CreateCaseModal
          label="Dane teczki"
          caseItem={selectedEdit}
          onClose={() => {
            setSelectedEdit(null);
            setModals({ ...modals, createCase: false });
          }}
        />
      )}
      <PanelsLayout
        onChangeSearch={handleChangeSearchBar}
        disableHeader={hideMenu}
        defaultLeft={50}
        leftPanel={leftPanel()}
        rightPanel={rightPanel()}
        // rightPanel={<>>}
        resize
        leftClassName={styles.leftFull}
      />
    </>
  );
};
export default CasesModule;
