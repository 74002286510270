import { RefObject, createContext } from "react";

export enum IOptionType {
    hoverMenu = 'list.enable.hovermenu',
    hoverMenuPreview = 'preview.enable.hovermenu',
    showLead = 'list.show.lead',
    showImages = 'email.show.images'
}

export interface IOptions {
    Type: IOptionType;
    Value: string;
}

type ISettingsContext = {
    options: IOptions[];
    setOptions: (options: IOptions[]) => void;
}

const settingsContext = createContext<ISettingsContext>({
    options: [],
    setOptions: () => {}
})

export default settingsContext;