export type { FC } from 'react';

export enum ESearchTypes {
    STATUS,
    TAG,
    SHARED,
    CHANNEL
}

export interface Props {
    onChange: (value: string, statusId: string | null, tagId: string | null, entityId: string | null, kind: number | null) => void;
}