import { useEffect, useRef, useState, useContext } from 'react';
import { CanvasPath, ReactSketchCanvas } from '../react-sketch-canvas/src';
import { FC, Props } from './typings';
import { IAnnotation } from 'dto/IAnnotation';
import annotationService from 'api/annotationService';
import { useParams } from 'react-router-dom';
import AnnotationModal from 'components/AnnotationModal';
import { threadId } from 'worker_threads';
import PackagesContext from 'contexts/PackagesContext';
import { calculatePosition } from 'utils/calculatePosition';
import { sortByCoordinatesOnPDF } from 'utils/sortByCoordinatesOnPDF';
import useGetPDFViewer from 'hooks/useGetPDFViewer';

const Annotations: FC<Props> = ({
  zoom,
  page,
  drawLine,
  documentID,
  refWrapper,
  drawPin,
  setDrawLine,
  setDrawPin,
  isRightScreen,
  setEditComment,
}) => {
  const { data: annotations, refetch } = annotationService.useGet(
    encodeURIComponent(documentID)
  );

  const { mutate: createAnnotation } = annotationService.useCreate();

  const [clickedAnnotation, setClickedAnnotation] = useState<string | null>(
    null
  );
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [tempPosition, setTempPosition] = useState({ x: 0, y: 0 });

  const ref = useRef(null);

  const annotationModalRef = useRef(null);

  const renderPaths = () => {
    //
    const convertedAnnotations = annotations
      ?.sort(sortByCoordinatesOnPDF)
      ?.map((annotation: IAnnotation) => {
        let numberOfOrder = 0;
        const json = JSON.parse(annotation.Json);

        if (json.type === 'drawing') {
          const lines = json?.lines?.map((line: any) => {
            return {
              x: parseFloat(line[0]),
              y: parseFloat(line[1]),
            };
          });

          return {
            commentId: annotation.Id,
            drawMode: true,
            paths: lines,
            strokeColor: '#5d49c7',
            strokeWidth: 4,
          };
        }

        if (json.type === 'point') {
          numberOfOrder += 1;
          return {
            isCircle: true,
            order: numberOfOrder,
            commentId: annotation.Id,
            drawMode: true,
            paths: [
              {
                x: parseFloat(json?.lines?.[0]?.[0]),
                y: parseFloat(json?.lines?.[0]?.[1]),
              },
            ],
            strokeColor: '#5d49c7',
            strokeWidth: 4,
          };
        }

        return null;
      });

    //@ts-ignore
    ref?.current?.loadPaths?.(
      convertedAnnotations?.filter(
        (annotation: IAnnotation) => annotation !== null
      ) ?? []
    );
  };

  useEffect(() => {
    renderPaths();
    return () => {
      //@ts-ignore
      ref?.current?.clearCanvas?.();
    };
  }, [annotations]);

  useEffect(() => {
    //@ts-ignore
    ref?.current?.clearCanvas?.();
    renderPaths();
  }, [zoom]);

  const { packages, setPackages } = useContext(PackagesContext);
  const { PDF, setPDF } = useGetPDFViewer();
  const handleOnStroke = (canvasPath: CanvasPath) => {
    if (canvasPath.paths.length === 1 && !drawPin) return;

    let type = canvasPath.isCircle ? 'point' : 'drawing';

    const data = {
      DocumentId: documentID,
      Json: JSON.stringify({
        type: type,
        width: 3,
        color: '#5d49c7',
        lines: canvasPath.paths.map((path) => [path.x, path.y]),
      }),
      PageNumber: page,
      Type: type,
    };

    // setDrawLine(false);
    // setDrawPin(false);

    //

    createAnnotation(data, {
      onSuccess: (data) => {
        refetch();

        if (isRightScreen) {
          setPDF({ ...PDF, editComment: data.data.Id });
          //
          // setEditComment?.(data.data.Id);
        } else {
          setClickedAnnotation(data.data.Id);
        }

        const { x, y } = calculatePosition(
          tempPosition.x,
          tempPosition.y,
          400,
          250
        );

        setPosition({ x: x, y: y });

        const document = documentID.split(':');

        const newPacks = [...packages];

        const el = newPacks.find((p) => p.Id === document[0]);
        if (el) {
          el.CommentsCount = el.CommentsCount + 1;
          setPackages(newPacks);
        }
      },
    });
  };

  const handleClickOnAnnotation = (event: any, annotationID: string) => {
    const { x, y } = calculatePosition(event.pageX, event.pageY, 400, 250);
    setPosition({ x, y });
    setClickedAnnotation(annotationID);
  };

  const handleMoveMouse = (e: any) => {
    setTempPosition({ x: e.clientX, y: e.clientY });

    if (moveWindow) {
      const sizeOfModal =
        //@ts-ignore
        annotationModalRef?.current?.getBoundingClientRect?.();
      //

      const { x, y } = calculatePosition(
        e.clientX,
        e.clientY,
        sizeOfModal.width,
        sizeOfModal.height
      );
      setPosition({ x, y });
    }
  };

  const [moveWindow, setMoveWindow] = useState(false);

  useEffect(() => {
    document.addEventListener('mousemove', handleMoveMouse);
    return () => {
      document.removeEventListener('mousemove', handleMoveMouse);
    };
  }, [moveWindow]);

  return (
    <>
      <ReactSketchCanvas
        style={{
          border: 'none',
        }}
        onClick={handleClickOnAnnotation}
        drawIcon={drawPin}
        drawLine={drawLine}
        // selectedAnnotation={}
        ref={ref}
        canvasColor={'rgba(0, 0, 0, 0)'}
        //   style={styles}
        width="100%"
        height="100%"
        strokeWidth={4}
        onStroke={(canvasPath) => handleOnStroke(canvasPath)}
        strokeColor="#5d49c7"
      />
      {clickedAnnotation && (
        <AnnotationModal
          annotationModalRef={annotationModalRef}
          setMoveWindow={setMoveWindow}
          moveWindow={moveWindow}
          refetch={refetch}
          setClickedAnnotation={setClickedAnnotation}
          positionX={position.x}
          positionY={position.y}
          annotation={annotations.find(
            (annotation: IAnnotation) => annotation.Id === clickedAnnotation
          )}
          comment={
            annotations.find(
              (annotation: IAnnotation) => annotation.Id === clickedAnnotation
            )?.Comments?.[0]
          }
          annotationID={clickedAnnotation ?? ''}
        />
      )}
    </>
  );
};

export default Annotations;
