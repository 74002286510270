import { Route, Routes } from 'react-router-dom';
import ActionsModule from '.';

const MessagesRoute = () => {
  return (
    <Routes>
      <Route path="/:calendarView" element={<ActionsModule />} />
      <Route path="*" element={<ActionsModule />} />
    </Routes>
  );
};

export default MessagesRoute;
