import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as XLSIcon } from 'assets/icons/xls.svg';
import { ReactComponent as OtherIcon } from 'assets/icons/other.svg';
import { ReactComponent as PngIcon } from 'assets/icons/png.svg';
import { ReactComponent as JpgIcon } from 'assets/icons/jpg.svg';
import { ReactComponent as DocIcon } from 'assets/icons/doc.svg';
import { ReactComponent as TXTIcon } from 'assets/icons/txt.svg';

const AttachmentIconComponent: FC<Props> = ({ file, className }) => {
  const name = file.split('.');
  const extenstion = name[name.length - 1];

  switch (extenstion) {
    case 'jpg':
      return <JpgIcon className={`${styles.icon} ${className}`} />;
    case 'xlsx':
      return <XLSIcon className={`${styles.icon} ${className}`} />;
    case 'pdf':
      return <PDFIcon className={`${styles.icon} ${className}`} />;
    case 'png':
      return <PngIcon className={`${styles.icon} ${className}`} />;
    case 'doc':
      return <DocIcon className={`${styles.icon} ${className}`} />;
    case 'docx':
      return <DocIcon className={`${styles.icon} ${className}`} />;
    case 'txt':
      return <TXTIcon className={`${styles.icon} ${className}`} />;
    default:
      return <OtherIcon className={`${styles.icon} ${className}`} />;
  }
};

export default AttachmentIconComponent;
