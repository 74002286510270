import { FC, Props } from './typings';
import styles from './styles.module.scss';
import SecondCheckbox from 'components/SecondCheckbox';
import Input from 'components/Input';
import Button from 'components/Button';
import { useState } from 'react';
import clientService from 'api/clientService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddButton from 'components/AddButton';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import SearchByNip from 'components/SearchByNip';
import { IGUSCompany } from 'dto/IGUSCompany';
import SearchCode from 'components/SearchCode';
import SearchPlace from 'components/SearchPlace';
import SearchAddress from 'components/SearchAddress';
import { useFormikContext } from 'formik';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface IContact {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  JobPosition: string;
}

const AddClientModal: FC<Props> = ({
  label,
  client,
  onClose,
  onCancel,
  newClient = false,
}) => {
  const { mutate: updateClient } = clientService.useUpdateClient();
  const { mutate: createClient } = clientService.useCreateClient();

  const { refetch } = clientService.useGetClient(client?.Id ?? '', false);

  const [values, setValues] = useState({
    Name: client?.Name ?? '',
    ZipCode: client?.ZipCode ?? '',
    City: client?.City ?? '',
    Address: client?.Address ?? '',
    Phone: client?.Phone ?? '',
    Email: client?.Email ?? '',
    Note: client?.Note ?? '',
    IsInstitution: client?.IsInstitution ?? false,
    Nip: client?.NIP ?? '',
    REGON: client?.REGON ?? '',
    cityId: null as string | null,
    addressId: null as string | null,
    ContactPersons: [] as IContact[],
  });

  const handleClick = () => {
    if (!client) {
      // createClient(
      //   {
      //     Address: values.Address ?? '',
      //     City: values.City ?? '',
      //     CityId: null,
      //     ContactPersons: values.ContactPersons,
      //     Email: values.Email ?? '',
      //     Id: null,
      //     IsInstitution: values.IsInstitution ?? false,
      //     Name: values.Name ?? '',
      //     NewTags: [],
      //     NIP: values.IsInstitution ? values.Nip ?? '' : '',
      //     Note: values.Note,
      //     Phone: values.Phone ?? '',
      //     REGON: values.IsInstitution ? values.REGON ?? '' : null,
      //     StreetId: null,
      //     TagIds: [],
      //     ZipCode: values.ZipCode ?? '',
      //   },
      //   {
      //     onSuccess: () => {
      //       onClose();
      //     },
      //   }
      // );
      return;
    }

    updateClient(
      {
        Id: client?.Id,
        Address: values.Address ?? '',
        City: values.City ?? '',
        CityId: 'none',
        ContactPersons: values.ContactPersons,
        Email: client?.Email ?? '',
        IsInstitution: values.IsInstitution ?? false,
        Name: values.Name,
        NewTags: [],
        NIP: values.IsInstitution ? values.Nip ?? '' : '',
        Note: values.Note ?? '',
        Phone: values.Phone ?? '',
        REGON: values.IsInstitution ? values.REGON ?? '' : '',
        StreetId: 'none',
        TagIds: [],
        ZipCode: values.ZipCode ?? '',
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  const addNewPerson = () => {
    setValues({
      ...values,
      ContactPersons: [
        ...values.ContactPersons,
        {
          FirstName: '',
          LastName: '',
          Phone: '',
          JobPosition: '',
          Email: '',
        },
      ],
    });
  };

  const changeContactData = (index: number, type: string, text: string) => {
    const newArray = { ...values };

    const person = newArray.ContactPersons.at(index);

    if (!person) return;

    if (type === 'name') {
      const name = text.split(' ');

      person.FirstName = name[0];
      person.LastName = name[1] ?? '';
    }
    if (type === 'phone') {
      person.Phone = text;
    }
    if (type === 'email') {
      person.Email = text;
    }

    setValues(newArray);
  };

  const deleteContact = (index: number) => {
    const newArray = { ...values };

    const persons = newArray.ContactPersons.filter((person, i) => {
      return i !== index;
    });

    newArray.ContactPersons = persons;

    setValues(newArray);
  };

  const handleGUSData = (company: IGUSCompany) => {
    setValues({ ...values, Name: company.Name, REGON: company.Regon });
  };

  return (
    <div className={styles.modal}>
      <div className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            Nowa teczka
            <CloseIcon onClick={onCancel} className={styles.closeIcon} />
          </div>
          <div className={styles.checkboxes}>
            <SecondCheckbox
              value={values.IsInstitution === false ? true : false}
              text="Osoba"
              onChange={(t) => setValues({ ...values, IsInstitution: false })}
            />
            <SecondCheckbox
              value={values.IsInstitution === true ? true : false}
              text="Firma "
              onChange={(t) => setValues({ ...values, IsInstitution: true })}
            />
          </div>
          <div className={styles.inputs}>
            {values.IsInstitution && (
              <div className={styles.group}>
                <SearchByNip
                  onDataChange={handleGUSData}
                  defaultValue={values.Nip}
                  onChange={(t) => setValues({ ...values, Nip: t })}
                />

                <Input
                  label="Regon"
                  value={values.REGON}
                  onChange={(t) => setValues({ ...values, REGON: t })}
                />
              </div>
            )}

            <Input
              label="Imię i nazwisko"
              value={values.Name}
              onChange={(t) => setValues({ ...values, Name: t })}
            />

            <div className={styles.group}>
              <SearchCode
                defaultValue={values.ZipCode}
                onChange={(t) => setValues({ ...values, ZipCode: t })}
              />
              <SearchPlace
                defaultValue={values.City}
                onChange={(t, c) =>
                  setValues({ ...values, City: t, cityId: c })
                }
              />
            </div>
            <SearchAddress
              defaultValue={values.City}
              cityId={values.cityId}
              onChange={(t, c) =>
                setValues({ ...values, Address: t, addressId: c })
              }
            />
            <div className={styles.group}>
              <Input
                label="Numer telefonu"
                defaultValue={values.Phone}
                onChange={(t) => setValues({ ...values, Phone: t })}
              />
              <Input
                label="Email"
                defaultValue={values.Email}
                onChange={(t) => setValues({ ...values, Email: t })}
              />
            </div>
            <Input
              label="Uwagi"
              defaultValue={values.Note}
              onChange={(t) => setValues({ ...values, Note: t })}
            />
          </div>

          <div className={styles.contacts}>
            {values.ContactPersons.map((contact, index) => {
              return (
                <div className={styles.contact} key={index}>
                  <div className={styles.contactInputs}>
                    <Input
                      label="Imię i nazwisko"
                      defaultValue={`${contact.FirstName} ${contact.LastName}`}
                      onChange={(t) => changeContactData(index, 'name', t)}
                    />
                    <div className={styles.group}>
                      <Input
                        label="Numer telefonu"
                        placeholder="Numer telefonu"
                        defaultValue={`${contact.Phone}`}
                        onChange={(t) => changeContactData(index, 'phone', t)}
                      />
                      <Input
                        label="Adres E-Mail"
                        placeholder="Adres E-Mail"
                        defaultValue={`${contact.Email}`}
                        onChange={(t) => changeContactData(index, 'email', t)}
                      />
                    </div>
                  </div>
                  <div className={styles.removeWrapper}>
                    <BinIcon onClick={() => deleteContact(index)} />
                  </div>
                </div>
              );
            })}
          </div>

          {values.IsInstitution && (
            <div className={styles.addButton}>
              <AddButton
                text="Dodaj osobę kontaktową"
                onClick={addNewPerson}
                className={styles.add}
              />
            </div>
          )}
          {!onCancel ? (
            <div className={styles.buttonWrapper}>
              <Button text="Zapisz" onClick={handleClick} />
            </div>
          ) : (
            <div className={styles.buttonWrapper2}>
              <Button text="Zapisz" onClick={handleClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddClientModal;
