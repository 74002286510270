import { axiosInstance } from 'api/axios';
import { IFileInitUploadResponse } from 'api/fileService';
import { IEncryptedFile } from 'components/CreateDossierModal/typings';
import CryptoJS from 'crypto-js';
import {
  ISingleBinaryKeys,
  generateSingleBinary,
} from './GenerateSingleBinary';
import encryptFile from './encryptFile';
import { readFileAsBlob } from './readFileAsBlob';
import { createChunks } from './createChunksFromBlob';
import { readAsBase64 } from './readAsBase64';

const encryptFiles = async (
  files: File[] | Blob[],
  keys: ISingleBinaryKeys
) => {
  const blobs = await Promise.all(
    files.map(async (file) => {
      const fileAndBlob = await readFileAsBlob(file);
      const returnedFile = fileAndBlob[0];
      const returnedBlob = fileAndBlob[1];
      const encryptedFile = await encryptFile(
        returnedBlob.target?.result,
        keys
      );

      return {
        orginalFile: returnedFile,
        encryptedFile: encryptedFile,
        blob: returnedBlob,
        hash: await CryptoJS.SHA3(
          (returnedBlob.target?.result as string) ?? ''
        ).toString(CryptoJS.enc.Base64),
      };
    })
  );

  return blobs;
};

const Files = async (files: File[] | Blob[]) => {
  const blobs = await Promise.all(
    files.map(async (file) => {
      const fileAndBlob = await readFileAsBlob(file);
      const returnedFile = fileAndBlob[0];
      const returnedBlob = fileAndBlob[1];

      // todo: optymalizacja użycia cryptoJS
      const base64 = await readAsBase64(
        new Uint8Array(returnedBlob.target?.result as ArrayBuffer)
      );

      return {
        orginalFile: returnedFile,
        blob: returnedBlob,
        hash: await CryptoJS.SHA3(base64).toString(CryptoJS.enc.Base64),
      };
    })
  );

  return blobs;
};

export const handleCreate = async (
  files: File[] | Blob[],
  caseId: string,
  publicKey: string,
  useEncrypt: boolean,
  useDecrypt: boolean,
  fileName?: string,
  fileType?: string,
  callback?: (progress: number) => void
) => {
  if (!files.length) {
    return;
  }

  // Creating keys and uploading to database also in this step we encrypt all files from hook
  const response: any = null;
  if (useEncrypt) {
    const keys: ISingleBinaryKeys = await generateSingleBinary(publicKey);

    const response = await axiosInstance.post('/keys/object', {
      CaseId: caseId ?? '',
      PrivateKey: keys.PrivateKey,
      PublicKey: keys.PublicKey,
    });

    const encryptedFiles = await encryptFiles(files, keys);

    const uris = await initUpload(
      response.data.Id,
      encryptedFiles,
      useDecrypt,
      fileName,
      fileType,
      callback
    );
    return uris;
  }
  const encryptedFiles = await Files(files);
  const uris = await initUpload(
    response?.data?.Id,
    encryptedFiles,
    useDecrypt,
    fileName,
    fileType,
    callback
  );
  return uris;
};

// Initialize upload sending information about files
const initUpload = async (
  keyId: string,
  encryptedFiles: IEncryptedFile[],
  useDecrypt: boolean,
  fileName?: string,
  fileType?: string,
  callback?: (progress: number) => void
) => {
  return await Promise.all(
    encryptedFiles.map(async (file: IEncryptedFile) => {
      const response = await axiosInstance.post('/file/init', {
        ContentType: fileType ? fileType : file.orginalFile.type,
        FileName: fileName ? fileName : file.orginalFile.name,
        Hash: file.hash,
        IsSigned: false,
        KeyId: keyId ?? null,
        Size: file.orginalFile.size,
      });
      await getLink(response.data, keyId, file, callback);
      return { keyId: keyId, uri: response.data.Uri, encrypted: useDecrypt };
    })
  );
};

// Getting link to S3
const getLink = async (
  uploadResponse: IFileInitUploadResponse,
  keyId: string,
  file: IEncryptedFile,
  callback?: (progress: number) => void
) => {
  const chunks = createChunks(
    new Blob([file.encryptedFile ?? file.orginalFile])
  );

  const links = [];

  for (const chunk in chunks) {
    const response = await axiosInstance.get(
      `/file/part?uri=${uploadResponse.Uri}&partNumber=${
        Number(chunk) + 1
      }&uploadId=${uploadResponse.UploadId}`
    );
    links.push(response.data);
  }
  await uploadFile(
    uploadResponse.Uri,
    uploadResponse.UploadId,
    links,
    keyId,
    file,
    callback
  );
};

const uploadFile = async (
  uri: string,
  uploadId: string,
  links: string[],
  keyId: string,
  file: IEncryptedFile,
  callback?: (progress: number) => void
) => {
  const chunks = createChunks(
    new Blob([file.encryptedFile ?? file.orginalFile])
  );
  const etags: string[] = [];

  let sendedChunk = 0;
  for (const chunk of chunks) {
    const etag = await sendChunk(
      links[sendedChunk],
      chunk,
      sendedChunk + 1,
      chunks.length,
      callback
    );
    etags.push(etag as string);
    sendedChunk++;
  }

  axiosInstance.post('/file/confirm', {
    UploadId: uploadId,
    Uri: uri,
    Parts: etags.map((etag, index) => ({ Etag: etag, PartNumber: index + 1 })),
    // Parts: [{ ETag: etag!, PartNumber: 1 }],
  });
};

const sendChunk = async (
  link: string,
  chunk: Blob,
  actualChunk: number,
  maxChunk: number,
  callback?: (progress: number) => void
) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', link, true);
    xhr.responseType = 'blob';

    // Oczekujemy, że AWS S3 może zwrócić 200 OK, 201 Created lub 204 No Content w odpowiedzi na zapytanie PUT
    xhr.onload = function () {
      if (xhr.status === 200 || xhr.status === 201 || xhr.status === 204) {
        var etag = this.getResponseHeader('ETag');
        // etags.push(etag!);
        resolve(etag?.replaceAll('"', ''));
      } else {
        console.error(
          'Wystąpił błąd podczas wysyłania pliku do AWS S3. Status:',
          xhr.status
        );
      }
    };

    xhr.upload.onprogress = (e) => {
      //
      // const filePercent = (e.loaded / e.total) * 100
      const filePercent =
        ((actualChunk - 1 + e.loaded / e.total) / maxChunk) * 100;
      callback?.(filePercent);
    };

    xhr.send(chunk);
  });
};
