import { useContext, useState } from 'react';
import styles from './styles.module.scss';
import Button from 'components/Button';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import CaseDossier from 'components/CaseDossier';
import casesService from 'api/casesService';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import KeysContext from 'contexts/KeysContext';
import { getBlob } from 'utils/getBlob';
import { getFile } from 'utils/getFile';
import { decryptFile } from 'utils/decryptFile';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import { IUploadedFile } from './typings';

export const CaseDossierWrapper = ({
  c,
  handleAddFiles,
}: {
  c: ICaseSimple;
  handleAddFiles: (files: IUploadedFile[]) => void;
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { data: Case } = casesService.useGetCase(c.Id);

  const [isLoading, setIsLoading] = useState(false);

  const { keys, setKeys } = useContext(KeysContext);

  const [selectedItems, setSelectedItems] = useState<IDossierItem[]>([]);

  const onSelecting = async (dossierItems: IDossierItem[]) => {
    setSelectedItems(dossierItems);
  };

  const handleAdd = async (selected?: IDossierItem[]) => {
    setIsLoading(true);
    const files = [];
    // todo: ?? selectedItems
    for (const element of selected!) {
      const file = await getFile(element.FileUri ?? '');
      const blob = await getBlob(file.data.Url);
      const key = keys.find((key: any) => key?.Id === element.EncryptionKeyId);

      if (!key) return;

      let parentKey = null;
      //@ts-ignore
      if (key.ParentKeyId) {
        parentKey = keys.find(
          //@ts-ignore
          (pkey: any) => pkey?.Id === key?.ParentKeyId
        );
      }
      const decryptedFile = await decryptFile(
        userId ?? '',
        new Blob([blob.data]),
        key,
        parentKey
      );

      const el = {
        file: decryptedFile,
        name: element.FileName ?? '',
        fileType: element.FileContentType ?? 'application/other',
        size: decryptedFile.size,
      };
      files.push(el);
    }

    handleAddFiles(files);
    setIsLoading(false);
  };

  return (
    <>
      <div className={styles.out}>
        <div className={styles.sizer}>
          <CaseDossier
            isOutcoming
            onSelecting={onSelecting}
            handleAdd={handleAdd}
            className={styles.remove}
            Case={Case ?? null}
          />
        </div>

        <div className={styles.btnWrapper}>
          <Button
            disabled={isLoading}
            loading={isLoading}
            text="Zatwierdź"
            onClick={handleAdd}
          />
        </div>
      </div>
    </>
  );
};
