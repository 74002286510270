import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as SendIcon } from 'assets/icons/send_purple.svg';
import moment from 'moment';
import Button from 'components/Button';
import CourtPortalService from 'api/CourtPortalService';

const ReceiveModal: FC<Props> = ({ refetch, pack }) => {
  const signature = pack.Case.Signature ?? '(Brak Sygnatury)';
  const entity = pack.Sender;
  const c = '(Brak przedmiotu sprawy)';
  const client = pack.Case.Client;

  const { mutate: receive } = CourtPortalService.useReceiveDeliverance();

  const handleClick = () => {
    receive(
      {
        id: pack.Id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.title}>Dane przesyłki</div>
          <div className={styles.date}>
            <SendIcon className={styles.icon} />{' '}
            {moment(pack.SendDate).format('DD/MM/YYYY').toString()}
          </div>
          <div className={styles.form}>
            <Input
              disabled
              value={signature}
              label="Sygnatura sprawy"
              onChange={() => {}}
            />
            <Input
              disabled
              value={entity}
              label="Organ prowadzący"
              onChange={() => {}}
            />
            <Input
              disabled
              value={c}
              label="Przedmiot Sprawy"
              onChange={() => {}}
            />
            <Input disabled value={client} label="Klient" onChange={() => {}} />
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              text="Odbierz"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiveModal;
