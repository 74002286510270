import Dropdown from 'components/Dropdown';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useEffect, useState } from 'react';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import workspaceService from 'api/workspaceService';
import ReactDOM from 'react-dom';

const MembershipEditRoleModal: FC<Props> = ({
  toggle,
  refetch,
  roles,
  member,
}) => {
  const { mutate: editUser } = workspaceService.useWorkspaceEditMember();

  const [selectedStructureId, setSelectedStructureId] = useState<string | null>(
    null
  );

  const mappedStructure = roles.map((m) => {
    return {
      name: m.Name,
      value: m.Id,
    };
  });

  useEffect(() => {
    if (member.RoleId) {
      setSelectedStructureId(member.RoleId);
      return;
    }

    setSelectedStructureId(roles?.[0]?.Id ?? '');
  }, [member]);

  const handleChange = () => {
    editUser(
      {
        Id: member.Id,
        RoleId: selectedStructureId!,
      },
      {
        onSuccess: () => {
          refetch();
          toggle();
        },
      }
    );
  };

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Edytuj rolę</div>
          <Dropdown
            list={mappedStructure}
            defaultValue={selectedStructureId}
            disableChange
            onChange={(t) => {
              setSelectedStructureId(t.value);
            }}
          />
          <div className={styles.buttons}>
            <HollowButton onClick={() => toggle()} text="Anuluj" />
            <Button onClick={handleChange} text="Zapisz" />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default MembershipEditRoleModal;
