import casesService from 'api/casesService';
import fileService from 'api/fileService';
import pluginService from 'api/pluginService';
import PDFViewer from 'components/PDFViever';
import KeysContext from 'contexts/KeysContext';
import CPluginContext from 'contexts/PluginContext';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { IAttachment } from 'dto/IAttachment';
import { ItemKind } from 'dto/IKindItems';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { blobToBase64 } from 'utils/blobToBase64';
import { decryptFile } from 'utils/decryptFile';
import { getBlob } from 'utils/getBlob';
import { getUserId } from 'utils/getUserId';
import { isAllowedAttachment } from 'utils/isAllowedAttachment';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import styles from './styles.module.scss';
import PanelsLayout from 'components/PanelsLayout';
import DossierFile from 'components/DossierFile';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Comments from 'components/Comments';
import LoadingScreen from 'components/LoadingScreen';
import dossierService from 'api/dossierService';

export const FolderModule = () => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { mutate: tryPreview } = pluginService.usePreview();
  const { caseId, folderId } = useParams();
  const { plugin } = useContext(CPluginContext);
  const [showVersion, setShowVersion] = useState(false);

  const { data: Folder } = dossierService.useGetFolder(folderId!, true);

  const { data: dossierData, refetch } = casesService.useGetDossier(
    caseId ?? ''
  );

  const [selectedItem, setSelectedItem] = useState<null | number>(null);
  const [blob, setBlob] = useState<null | string | Blob>(null);

  const { mutate: getFile } = fileService.useGetFiltersForCases();

  const dossierItems = useMemo(
    () =>
      dossierData?.data?.Items.filter(
        (dossier: IDossierItem) => dossier.ParentId === folderId
      ) ?? [],
    [dossierData]
  );

  //
  const { keys, setKeys } = useContext(KeysContext);

  useEffect(() => {
    if (!dossierData?.data) return;
    const objectKeys = dossierData?.data.ObjectKeys;
    const itemKeys = dossierData?.data.Keys;
    setKeys([...keys, objectKeys, itemKeys].flat());
  }, [dossierData]);

  useEffect(() => {
    if (dossierItems[0]) {
      setSelectedItem(0);
    }
  }, [keys]);

  useEffect(() => {
    if (selectedItem === null) return;

    onPreview();
  }, [selectedItem]);

  const previewFromPlugin = async (blob: Blob, attachment: IAttachment) => {
    if (!plugin.actual) {
      setShowVersion(true);
      return;
    }

    const b: string = (await blobToBase64(blob)) as string;

    tryPreview(
      {
        AttachmentId: attachment.Id,
        Content: b.split(',')[1],
        ContentType: attachment.ContentType,
        DocumentId: attachment.Id,
        FileName: attachment.FileName,
        Kind: attachment.Kind,
      },
      {
        onSuccess: async (response: any) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setBlob(new Blob([buffer]));
        },
      }
    );
  };

  const getFileAndDecrypt = (
    previewPackage: IDossierItem,
    uri: string,
    kind: ItemKind
  ) => {
    getFile(
      {
        id: uri,
        ownerKind: kind,
      },
      {
        onSuccess: async (data) => {
          //   let parentKey = null;
          //   if (previewPackageData?.data.ObjectKeys[0]) {
          //     parentKey = parentKeys.find(
          //       (pkey: any) =>
          //         pkey.Id === previewPackageData?.data.ObjectKeys[0].ParentKeyId
          //     );
          //   }
          const key = keys?.find(
            (k) => k?.Id === previewPackage.EncryptionKeyId
          );
          let parentKey = null;
          if (!key) return;

          if (key.ParentKeyId) {
            parentKey = keys?.find((k) => k?.Id === key.ParentKeyId);
          }

          const extension = data.data.FileName.split('.').at(-1);
          const isAllowed = isAllowedAttachment(extension ?? 'no');
          const isPDF = extension === 'pdf';

          const file = await getBlob(data.data.Url);
          try {
            const decryptedBlob = await decryptFile(
              userId,
              new Blob([file.data]),
              key,
              parentKey
            );

            if (!isPDF) {
              previewFromPlugin(decryptedBlob, data.data as any);
            }
            setBlob(decryptedBlob);
          } catch (e) {
            if (!isPDF) {
              previewFromPlugin(new Blob([file.data]), data.data as any);
              return;
            }
            setBlob(new Blob([file.data]));
          }
        },
      }
    );
  };

  const onPreview = async () => {
    if (selectedItem === null || !dossierItems[selectedItem]) {
      return;
    }
    setShowVersion(false);
    const previewPackage: IDossierItem = dossierItems[selectedItem];

    getFileAndDecrypt(
      previewPackage,
      previewPackage.FileUri ?? '',
      previewPackage.Kind
    );
  };

  if (selectedItem === null) {
    return <LoadingScreen />;
  }
  const documentID = `${dossierItems[selectedItem!].Id}:${
    dossierItems[selectedItem!].Kind
  }:${dossierItems[selectedItem!].Id}`;

  const leftSide = () => {
    return (
      <div className={styles.pdf}>
        {showVersion && <PDFViewer file={new Blob([])} showVersion={true} />}
        {blob && !showVersion && (
          <>
            <PDFViewer
              className={styles.test}
              docId={`${documentID}`}
              file={blob}
            />

            <div className={styles.commentsWrapper}>
              <Comments documentID={documentID} />
            </div>
          </>
        )}
      </div>
    );
  };

  const rightSide = () => {
    return (
      <div className={styles.files}>
        <DragDropContext onDragUpdate={() => {}} onDragEnd={() => {}}>
          <Droppable droppableId="files">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={styles.filesWrapper}>
                {dossierItems.map((d: IDossierItem, index: number) => (
                  <DossierFile
                    files={[]}
                    setFiles={() => {}}
                    setSelectedFiles={() => {}}
                    handleCopy={() => {}}
                    isSelected={index === selectedItem}
                    file={d}
                    index={index}
                    statuses={[]}
                    tags={[]}
                    refetch={() => {}}
                    onClick={() => setSelectedItem(index)}
                    showTypeFile
                    hideBytes
                    handleFileDoubleClick={() => {}}
                  />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.p}>
        <PanelsLayout
          disableListStyle
          wrapperClassName={styles.fixWrapper}
          disablePadding
          // disableHeader
          disableSearch
          leftPanel={rightSide()}
          rightPanel={leftSide()}
          layoutName="Folders-dossier"
          title={Folder?.Case?.Title ?? ''}
          subTitle={Folder?.Name ?? ''}
        />
      </div>
    </div>
  );
};
