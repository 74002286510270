import { useMutation } from "react-query"
import { axiosInstance } from "./axios"


const receiveDeliverance = (payload: { id: string}) => {
    return axiosInstance.get(`/courtPortal/receive?id=${payload.id}`)
}

const useReceiveDeliverance = () => {
    return useMutation(receiveDeliverance);
}

export default {
    useReceiveDeliverance
}