import { FC, Props } from './typings';
import styles from './styles.module.scss';
import ReactDOM from 'react-dom';
import MailsInputs from 'components/MailsInputs';
import { useEffect, useMemo, useState } from 'react';
import Button from 'components/Button';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import workspaceService from 'api/workspaceService';
import Dropdown from 'components/Dropdown';
import { IDropdownObject } from 'components/Dropdown/typings';
import Input from 'components/Input';
import { ENotificationType, notification } from 'utils/notification';

const AddWorker: FC<Props> = ({ roles, toggle, workspaceId, refetch }) => {
  const { mutate: addWorkers } = workspaceService.useInviteToWorkspace();

  const [name, setName] = useState('');
  const [list, setList] = useState<string[]>([]);

  const [selectedRole, setSelectedRole] = useState('');

  const [email, setEmail] = useState('');

  const handleChangeList = (emails: string[]) => {
    setList(emails);
  };

  const handleAdd = () => {
    addWorkers(
      {
        RoleId: selectedRole,
        WorkspaceId: workspaceId,
        Email: email.replaceAll(' ', ''),
        Name: name,
      },
      {
        onSuccess: () => {
          refetch();
          toggle();
        },
        onError: (e: any) => {
          notification({
            text: e.response.data.errorCodes[0].message,
            title: 'Coś poszło nie tak',
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  const mappedRoles = useMemo(
    () =>
      roles
        .filter((role) => role.StaticType !== 'owner')
        .map((role) => {
          return { name: role.Name, value: role.Id };
        }) ?? [],
    [roles]
  );

  useEffect(() => {
    setSelectedRole(mappedRoles[0].value);
  }, [mappedRoles]);

  const changeSelectedRole = (value: IDropdownObject) => {
    setSelectedRole(value.value as string);
  };

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Wyślij zaproszenie
            <CloseIcon onClick={toggle} className={styles.icon} />
          </div>
          <div className={styles.container}>
            <div className={styles.it}>
              <Input
                // className={styles.it}
                label="Imię i Nazwisko zapraszanej osoby"
                onChange={(t) => {
                  setName(t);
                }}
                value={name}
              />
            </div>
            <Input
              label="Email zapraszanej osoby"
              value={email}
              onChange={(t) => {
                setEmail(t);
              }}
            />
            {/* <MailsInputs onChange={handleChangeList} /> */}

            <Dropdown
              allowNull
              list={mappedRoles}
              // defaultValue={selectedRole}
              onChange={changeSelectedRole}
            />
          </div>
          <div className={styles.button}>
            <Button
              disabled={!email.length}
              onClick={handleAdd}
              text="Wyślij"
            />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AddWorker;
