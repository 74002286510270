import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import taskService, { IUpdateTaskPayload } from 'api/taskService';
import Button from 'components/Button';
import DatePickerComponent from 'components/DatePicker';
import SearchCases from 'components/SearchCases';
import SearchMemberInWorkspace from 'components/SearchMemberInWorkspace';
import SecondCheckbox from 'components/SecondCheckbox';
import TextArea from 'components/TextArea';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IWorkspaceMember } from 'dto/IWorkspace';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { getPrivilages } from 'utils/getPrivilages';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import { ENotificationType, notification } from 'utils/notification';

const CreateActionModal: FC<Props> = ({
  showCase = true,
  initialValue,
  startDate: startOf,
  endDate: endOf,
  x,
  y,
  toggle,
  refetch,
  onCreate,
  kind,
  afterUpdate,
}) => {
  const [position, setPosition] = useState([0, 0]);
  const [startDate, setStartDate] = useState(
    startOf ?? moment(new Date()).startOf('hour').toDate()
  );
  const [endDate, setEndDate] = useState(
    endOf ??
      moment(startOf ?? new Date())
        .endOf('hour')
        .add(1, 'm')
        .toDate()
  );

  const [c, setCase] = useState<null | ICaseSimple>(initialValue?.case ?? null);
  const [text, setText] = useState(initialValue?.note ?? '');

  const [fullDay, setFullDay] = useState(initialValue?.fullday ?? false);
  const [withoutHour, setWithoutHour] = useState(false);

  const [member, setMember] = useState<IWorkspaceMember | null>(null);

  const { mutate: updateTask } = taskService.useUpdateTask();

  const { workspace } = useContext(WorkspaceContext);

  const myPrivilages = getPrivilages(workspace?.Roles[0]);

  useEffect(() => {
    if (position[0] !== 0) return;
    setPosition([x, y]);
  }, [x, y]);

  useEffect(() => {
    setPosition([x, y]);
  }, [startOf, endOf]);

  const handleClick = () => {
    let payload: IUpdateTaskPayload = {
      // parcelId: initialValue?.parcelId ?? null,
      Allday: fullDay,
      FromDate: moment(startDate).toISOString(),
      Id: initialValue?.id ?? null,
      IsFavourite: true,
      Note: text,
      TagIds: [],
      ToDate: withoutHour
        ? moment(startDate).add(30, 'minute').toISOString()
        : moment(endDate).toISOString(),
      OwnerId: c ? c.Id : initialValue?.parcelId ?? '',
      OwnerKind: kind,
    };

    if (member) {
      payload.WorkspaceId = getWorkspaceId() ?? '';
      payload.ForUserId = member.UserId;
    }

    updateTask(payload, {
      onSuccess: () => {
        toggle(null);
        refetch();
        onCreate?.();
      },
      onError: () => {
        notification({
          type: ENotificationType.ERROR,
          title: 'Wystąpił błąd',
          text: 'Coś poszło nie tak... spróbuj ponownie później',
        });
      },
    });
    afterUpdate?.(payload);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        Czynności
        <CloseIcon
          className={styles.closeIcon}
          onClick={(e) => {
            toggle(null);
            e.stopPropagation();
          }}
        />
      </div>
      <div className={styles.body}>
        {showCase && (
          <SearchCases
            setAddNewEntity={() => {}}
            onChoose={(cas) => setCase(cas)}
          />
        )}

        {myPrivilages.canTaskAssign && (
          <SearchMemberInWorkspace
            onChoose={(member) => {
              setMember(member);
            }}
          />
        )}

        <TextArea
          className={styles.input}
          label={''}
          value={text}
          placeholder="Dodaj opis czynności. Wpisując # dodaj tag do czynności bądź @ wyznacz osobę"
          onChange={(text) => setText(text)}
        />
        <div className={styles.dateWrapper}>
          Od:
          <DatePickerComponent
            showTime={!fullDay}
            date={startDate}
            setDate={setStartDate}
          />
        </div>
        {!fullDay && !withoutHour && (
          <div className={styles.dateWrapper}>
            Do:
            <DatePickerComponent
              showTime={!fullDay}
              date={endDate ?? new Date()}
              setDate={setEndDate}
            />
          </div>
        )}
        <div className={styles.checkbox}>
          <SecondCheckbox
            value={fullDay}
            onChange={(state) => setFullDay(state)}
            text="Całodniowy"
          />
          {/* <SecondCheckbox
            value={withoutHour}
            onChange={(state) => setWithoutHour(state)}
            text="Bez godziny zakończenia"
          /> */}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            onClick={handleClick}
            text="Utwórz wydarzenie"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateActionModal;
