import { useMutation, useQuery } from "react-query"
import { axiosInstance } from "./axios";

const queryKeys = {
    get: (payload: IGetSignature) => ['signatureService.get', payload]
}

export interface IGetSignature {
    OwnerId: string;
    ownerKind: number;
    attachmentId: string;
}

const getSignature = (payload: IGetSignature) => {

    const query = new URLSearchParams(window.location.search);

    query.append('OwnerId', payload.OwnerId);
    query.append('ownerKind', payload.ownerKind.toString());
    // query.append('attachmentId', payload.attachmentId);

    return axiosInstance.get(`/signature/report?${query}`)
}

const useGetSignature = (payload: IGetSignature) => {
    return useQuery(queryKeys.get(payload), () => getSignature(payload))
}

export interface IUploadSignature {
    Json: string;
    OwnerAttachmentId: string;
    OwnerId: string;
    OwnerKind: number;
}

const uploadSignature = (payload: IUploadSignature) => {
    return axiosInstance.post('/signature/report', payload)
}

const useUploadSignature = () => {
    return useMutation(uploadSignature);
}

export default {
    useGetSignature,
    useUploadSignature
}