import { axiosInstance } from 'api/axios';
import ActionBox from 'components/ActionBox';
import Loading from 'components/Loading';
import { ITask } from 'dto/ITask';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import styles from './styles.module.scss';
import moment from 'moment';
import ActionsContext from 'contexts/ActionsContext';
import { Props } from './typings';
import { IFeed } from 'dto/IFeed';

const groupByDate = (tasks: ITask[]) => {
  const groupedMap = new Map();

  tasks?.forEach((item) => {
    const fromDate = moment(item?.FromDate).toISOString(true)?.split('T')[0];
    if (!fromDate) return;
    if (groupedMap.has(fromDate)) {
      groupedMap.get(fromDate).push(item);
    } else {
      groupedMap.set(fromDate, [item]);
    }
  });

  return groupedMap;
};

const createNewMap = (array: any) => {
  const valuesArray = Array.from(array.values());

  return new Map(
    valuesArray.map((group: any) => [group[0]?.FromDate?.split('T')[0], group])
  );
};

const ActionsList: FC<Props> = ({ isDesk, date, caseId, search }) => {
  const separatorRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [stopLoading, setStopLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  // const { data: tasksData, isLoading, refetch } = taskService.useGetTasks(page);

  const [st, setST] = useState(moment(date).startOf('day').toDate());

  useEffect(() => {
    if (moment(st).isSame(moment(date).startOf('day').toDate())) {
      return;
    }

    setST(moment(date).toDate());
  }, [date]);

  const {
    data: tasksData,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ['tasks', st, search, isDesk ?? false],
    async ({ pageParam = 0 }) => {
      const query = new URLSearchParams(window.location.search);

      if (!isDesk) {
        if (!caseId) {
          query.append('Start', moment(st).startOf('day').toISOString());
        }
        query.append('Page', pageParam);
        // query.append('SortProp', 'Deadline');
        // query.append('SortOrder', 'Descending');
        if (caseId) {
          query.append('CaseId', caseId);
        }

        if (search) {
          if (!!search.text.length) {
            query.append('Text', search.text);
          }

          if (search.statusId) {
            query.append('StatusId', search.statusId);
          }
          if (search.tagId) {
            query.append('TagId ', search.tagId);
          }
          if (search.entityId) {
            query.append('AssignedUserId ', search.entityId);
          }
        }
      } else {
        query.append('desk', 'true');
      }
      const res = await axiosInstance.get(`/task/listExtended?${query}`);

      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.LastPage) {
          return page + 1;
        }
        return undefined;
      },
    }
  );

  const [lists, setLists] = useState<any>([]);
  // const tasks: ITask[] = useMemo(
  //   () => tasksData?.data?.Items ?? [],
  //   [tasksData]
  // );

  const handleScroll = (e: any) => {
    if (!separatorRef.current) return;
    if (isLoading || isFetchingNextPage) return;
    var rect = separatorRef.current.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    if (isVisible) {
      setPage(page + 1);
      fetchNextPage();
    }
  };

  const { tasks, setTasks } = useContext(ActionsContext);

  const compileData = async () => {
    if (!tasksData?.pages) return;
    const list = await Promise.all(
      tasksData?.pages
        .map((page: any) => {
          return page.Items;
        })
        .flat()
    );

    setTasks(list);

    // setLists(groupByDate(list));
  };

  useEffect(() => {
    let t = tasks as any[];

    t = t.sort(function (left, right) {
      return moment.utc(left.FromDate).diff(moment.utc(right.FromDate));
    });

    setLists(groupByDate(t));
  }, [tasks]);

  useEffect(() => {
    compileData();
    // setLists([...lists, groupByDate(tasks)]);
  }, [tasksData]);

  useEffect(() => {
    setPage(0);
  }, [date]);

  useEffect(() => {
    if (stopLoading || lists.length < 2) return;
    setStopLoading(true);
    setShowList(true);
  }, [lists]);

  const handleChangeStatus = (id: string) => {
    const notMapList = Array.from([...lists]).map(([date, items]: any) => {
      return items.map((item: any) => {
        if (item.Id === id) {
          item.CompleteDate = item.CompleteDate ? null : new Date();
        }

        return item;
      });
    });

    const mapList = createNewMap(notMapList);

    setLists(mapList);
  };

  const handleDelete = (id: string) => {
    const notMapList = Array.from([...lists]).map(([date, items]: any) => {
      return items
        .map((item: any) => {
          if (item.Id === id) {
            return undefined;
          }

          return item;
        })
        .filter((item: any) => !!item);
    });

    const mapList = createNewMap(notMapList);

    setLists(mapList);
  };

  const handleChangeFlag = (id: string, statusId: number) => {
    const notMapList = Array.from([...lists]).map(([date, items]: any) => {
      return items.map((item: ITask) => {
        if (item.Id === id) {
          item.StatusId = statusId;
        }

        return item;
      });
    });

    const mapList = createNewMap(notMapList);

    setLists(mapList);
  };

  const handleUpdateTask = (c: any) => {
    const notMapList = Array.from([...lists]).map(([date, items]: any) => {
      return items.map((item: ITask) => {
        if (item.Id === c.Id) {
          item.Note = c.Note;
          item.AllDay = c.Allday;
          item.FromDate = c.FromDate;
          item.ToDate = c.ToDate;
        }

        return item;
      });
    });

    const mapList = createNewMap(notMapList);

    setLists(mapList);
  };

  const handleAddTag = (c: string, tagId: string) => {
    const notMapList = Array.from([...lists]).map(([date, items]: any) => {
      return items.map((item: ITask) => {
        if (item.Id === c) {
          item.TagIds = [...item.TagIds, tagId];
        }

        return item;
      });
    });

    const mapList = createNewMap(notMapList);

    setLists(mapList);
  };

  const handleRemoveTag = (c: string, tagId: string) => {
    const notMapList = Array.from([...lists]).map(([date, items]: any) => {
      return items.map((item: ITask) => {
        if (item.Id === c) {
          item.TagIds = [...item.TagIds].filter((t) => t !== tagId);
        }

        return item;
      });
    });

    const mapList = createNewMap(notMapList);

    setLists(mapList);
  };

  return (
    <div className={styles.wrapper}>
      <div onScroll={handleScroll} className={styles.list}>
        {isLoading && !stopLoading && <Loading />}
        {Array.from(lists).map(([date, items]: any) => (
          <ActionBox
            key={date}
            refetch={refetch}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            handleUpdateTask={handleUpdateTask}
            handleDelete={handleDelete}
            handleChangeStatus={handleChangeStatus}
            date={date}
            items={items}
            handleChangeFlag={handleChangeFlag}
          />
        ))}
        {showList && isLoading && (
          <div className={styles.loadingWrapper}>
            <Loading />
          </div>
        )}
        <div ref={separatorRef} style={{ height: '5px' }}></div>
      </div>
    </div>
  );
};

export default ActionsList;
