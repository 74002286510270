import { useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import TasksList from 'components/TasksList';
import { ITask } from 'dto/ITask';
import Task from 'components/Task';
import TaskFilter from 'components/TaskFilter';

export interface IFILTERTASK {
  statusId: null | string;
  tagId: null | string;
  memberId: null | string;
}

const Tasks: FC<Props> = ({}) => {
  const isCases = window.location.href.search('cases') !== -1;

  const [task, setTask] = useState<ITask | null>(null);

  const [filters, setFilters] = useState<IFILTERTASK>({
    statusId: null,
    tagId: null,
    memberId: null,
  });

  if (task) {
    return (
      <div className={`${styles.wrapper} ${!isCases && styles.remove}`}>
        <Task setTask={setTask} task={task} />
        <TaskFilter isOpen={!isCases} task={task} setTask={setTask} />
      </div>
    );
  }

  return (
    <div className={`${styles.listWrapper} ${!isCases && styles.remove}`}>
      <TasksList filters={filters} setTask={setTask} />
      <TaskFilter isOpen={!isCases} setFilters={setFilters} />
    </div>
  );
};

export default Tasks;
