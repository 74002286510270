import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import settingService, {
  IFidoMakeCredentialOptionsRequest,
} from 'api/settingsService';
import { ReactComponent as TotpIcon } from 'assets/icons/add_to_drive.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/encrypted.svg';
import Button from 'components/Button';
import HollowButton from 'components/HollowButton';
import Input from 'components/Input';
import Spinner from 'components/Spinner';
import { IInitTotpResponse } from 'dto/Settings/IInitTotpResponse';
import { ITwoFactorSettings } from 'dto/Settings/ITwoFactorSettings';
import { TwoFactorMethod } from 'dto/Settings/TwoFactorMethod';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAuth } from 'react-oidc-context';
import { coerceToArrayBuffer, coerceToBase64Url } from 'utils/fidoHelpers';
import { getErrorMessage } from 'utils/getErrorMessage';
import { getUserId } from 'utils/getUserId';
import { ENotificationType, notification } from 'utils/notification';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

const TwoFactorAuthentication: FC<Props> = ({ nextStep }) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);

  const { data: current2faSettings, isSuccess: isCurrent2faSettingsLoaded } =
    settingService.useGet2FaSettings();
  const { mutate: getFidoMakeCredentialOptions, isLoading: isFidoLoading } =
    settingService.useFidoMakeCredentialOptions();
  const { mutate: makeFidoCredential, isLoading: isMakingFidoCredential } =
    settingService.useFidoMakeCredential();

  const { mutate: getInitTotp, isLoading: isLoadingTotp } =
    settingService.useGetInitTotp();
  const { mutate: setTotp, isLoading: isSettingTotp } =
    settingService.useSetTotp();

  const [method, setMethod] = useState<TwoFactorMethod | null>(null);
  const [enabledMethods, setEnabledMethods] = useState({
    email: false,
    fido: false,
    totp: false,
  });

  const [skipButtonState, setSkipButtonState] = useState({
    show: true,
    label: 'Pomiń',
    disabled: false,
  });
  const [prevButtonState, setPrevButtonState] = useState({
    show: true,
    label: 'Cofnij',
    disabled: false,
  });
  const [nextButtonState, setNextButtonState] = useState({
    show: false,
    label: '',
    disabled: false,
  });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [totpUri, setTotpUri] = useState<string>('');
  const [totpCode, setTotpCode] = useState<string>('');

  const configure = (method: TwoFactorMethod) => {
    switch (method) {
      case TwoFactorMethod.Email:
        break;
      case TwoFactorMethod.WebAuthn:
        if (enabledMethods.fido) {
          notification({
            title: 'Skonfigurowano',
            text: 'Podwójna autoryzacja przy pomocy klucza sprzętowego/webauthn została już skonfigurowana.',
            type: ENotificationType.INFO,
          });
        } else {
          setMethod(method);
          registerNewKey();
        }
        break;
      case TwoFactorMethod.Totp:
        if (enabledMethods.totp) {
          notification({
            title: 'Skonfigurowano',
            text: 'Podwójna autoryzacja przy pomocy kodów OTP została już skonfigurowana.',
            type: ENotificationType.INFO,
          });
        } else {
          setMethod(method);
          initTotp();
          setNextButtonState({
            ...nextButtonState,
            label: 'Weryfikuj',
            show: true,
            disabled: true,
          });
        }
        break;
    }
  };

  const nextStepInternal = () => {
    if (method) {
      switch (method) {
        case TwoFactorMethod.Totp:
          setTotp(
            { code: totpCode },
            {
              onSuccess(data) {
                setEnabledMethods({ ...enabledMethods, totp: true });
                setMethod(null);

                setSkipButtonState({ ...skipButtonState, show: false });
                setNextButtonState({
                  show: true,
                  label: 'Dalej',
                  disabled: false,
                });
              },
              onError(e: any) {
                setErrorMessage(getErrorMessage(e.response.data));
              },
            }
          );
          break;
      }
    } else {
      nextStep?.();
    }
  };

  const prevInternal = () => {
    if (method) {
      setMethod(null);

      if (enabledMethods.fido || enabledMethods.totp) {
        setNextButtonState({
          show: true,
          label: 'Dalej',
          disabled: false,
        });
      } else {
        setNextButtonState({
          show: false,
          label: 'Dalej',
          disabled: false,
        });
      }
    }
  };

  useEffect(() => {
    if (isCurrent2faSettingsLoaded) {
      var s = current2faSettings.data as ITwoFactorSettings;

      setEnabledMethods({
        email: !!s.configurations?.find(
          (x) => x.method === TwoFactorMethod.Email
        ),
        fido: !!s.configurations?.find(
          (x) => x.method === TwoFactorMethod.WebAuthn
        ),
        totp: !!s.configurations?.find(
          (x) => x.method === TwoFactorMethod.Totp
        ),
      });
    }
  }, [current2faSettings]);

  useEffect(() => {
    if (totpUri && method == TwoFactorMethod.Totp) {
      ReactDOM.render(
        <QRCodeCanvas value={totpUri} />,
        document.getElementById('qrcode')
      );
      setNextButtonState({
        ...nextButtonState,
        label: 'Weryfikuj',
        show: true,
        disabled: false,
      });
    }
  }, [totpUri]);

  useEffect(() => {
    setSkipButtonState({ ...skipButtonState, show: !method });
    setPrevButtonState({ ...prevButtonState, show: !!method });
  }, [method]);

  const registerNewKey = () => {
    var req: IFidoMakeCredentialOptionsRequest = {
      attType: 'none',
      authType: '',
      userVerification: 'required',
      requireResidentKey: false,
    };

    getFidoMakeCredentialOptions(req, {
      onSuccess(data) {
        processFidoOptions(data.data);
      },
      onError(error) {
        notification({
          title: 'Wystąpił błąd',
          text: 'Coś poszło nie tak... spróbuj ponownie później',
          type: ENotificationType.ERROR,
        });
      },
    });
  };

  const processFidoOptions = async (makeCredentialOptions: any) => {
    // Turn the challenge back into the accepted format of padded base64
    makeCredentialOptions.challenge = coerceToArrayBuffer(
      makeCredentialOptions.challenge
    );
    // Turn ID into a UInt8Array Buffer for some reason
    makeCredentialOptions.user.id = coerceToArrayBuffer(
      makeCredentialOptions.user.id
    );

    makeCredentialOptions.excludeCredentials =
      makeCredentialOptions.excludeCredentials.map((c: any) => {
        c.id = coerceToArrayBuffer(c.id);
        return c;
      });

    if (
      makeCredentialOptions.authenticatorSelection.authenticatorAttachment ===
      null
    )
      makeCredentialOptions.authenticatorSelection.authenticatorAttachment =
        undefined;

    navigator.credentials
      .create({
        publicKey: makeCredentialOptions,
      })
      .then((s) => {
        if (s) registerNewCredential(s);
      })
      .catch((r) => {
        var msg =
          'Nie udało się utworzyć danych logowania. Prawdopodobnie dana metoda logowania została już zarejestrowana. Proszę spróbować ponownie.';

        if (r instanceof DOMException) {
          if (r.name === 'NotAllowedError') {
            msg = 'Akcja niedozwolona lub anulowana przez użytkownika';
          }
        }

        notification({
          title: 'Wystąpił błąd',
          text: msg,
          type: ENotificationType.ERROR,
        });
        setMethod(null);
      });
  };

  const registerNewCredential = (newCredential: any) => {
    // Move data into Arrays incase it is super long
    let attestationObject = new Uint8Array(
      newCredential.response.attestationObject
    );
    let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
    let rawId = new Uint8Array(newCredential.rawId);

    const req = {
      id: newCredential.id,
      rawId: coerceToBase64Url(rawId),
      type: newCredential.type,
      extensions: newCredential.getClientExtensionResults(),
      response: {
        AttestationObject: coerceToBase64Url(attestationObject),
        clientDataJson: coerceToBase64Url(clientDataJSON),
      },
    };

    makeFidoCredential(req, {
      onSuccess(data) {
        setEnabledMethods({ ...enabledMethods, fido: true });
        setMethod(null);

        setSkipButtonState({ ...skipButtonState, show: false });
        setNextButtonState({ show: true, label: 'Dalej', disabled: false });
      },
      onError(error) {
        notification({
          title: 'Wystąpił błąd',
          text: 'Coś poszło nie tak... spróbuj ponownie później',
          type: ENotificationType.ERROR,
        });
      },
    });
  };

  const initTotp = () => {
    getInitTotp(
      {},
      {
        onSuccess(data) {
          let resp = data.data as IInitTotpResponse;
          setTotpUri(resp.uri);
        },
        onError(e: any) {
          notification({
            title: 'Wystąpił błąd',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>Weryfikacja dwuetapowa</div>
      {!method && (
        <>
          <div className={styles.hint}>
            Skonfiguruj sposób weryfikacji dwuetapowej. <br></br>
            <b>Możesz skonfigurować jedną lub więcej metod.</b>
            <br></br>
            Twój adres e-mail jest zweryfikowany, więc metoda weryfikacji przy
            użyciu adresu e-mail jest domyślnie aktywna.
          </div>
          <div className={styles.methods}>
            <div className={styles.method} onClick={() => {}}>
              <div className={styles.icon}>
                <EmailIcon></EmailIcon>
              </div>
              Adres e-mail
              {enabledMethods.email && (
                <FontAwesomeIcon
                  className={styles.enabled}
                  icon={faCheckCircle}
                />
              )}
            </div>
            <div
              className={styles.method}
              onClick={() => configure(TwoFactorMethod.WebAuthn)}
            >
              <div className={styles.icon}>
                <KeyIcon></KeyIcon>
              </div>
              Klucz prywatny / Webauthn
              {enabledMethods.fido && (
                <FontAwesomeIcon
                  className={styles.enabled}
                  icon={faCheckCircle}
                />
              )}
            </div>
            <div
              className={styles.method}
              onClick={() => configure(TwoFactorMethod.Totp)}
            >
              <div className={styles.icon}>
                <TotpIcon></TotpIcon>
              </div>
              Authenticator / OTP
              {enabledMethods.totp && (
                <FontAwesomeIcon
                  className={styles.enabled}
                  icon={faCheckCircle}
                />
              )}
            </div>
          </div>
          <div className={styles.hint}>
            Logując się z nowego urządzenia bądź nowej przeglądarki
            internetowej, będzie konieczna weryfikacja dwuetapowa.
          </div>
        </>
      )}

      {method === TwoFactorMethod.WebAuthn && (
        <>
          <div className={styles.hint}>
            <b>Postępuj zgodnie z komunikatami w przeglądarce.</b>
          </div>
        </>
      )}

      {method === TwoFactorMethod.Totp && (
        <>
          <div className={styles.hint}>
            <b>
              Zeskanuj poniższy QR kod za pomocą aplikacji haseł jednorazowych.
            </b>
            <br></br>
            Możesz skorzystać z aplikacji takich jak Microsoft Authenticator dla{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US"
              target="_blank"
            >
              Android
            </a>{' '}
            lub{' '}
            <a
              href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
              target="_blank"
            >
              iOS
            </a>
            , Google Authenticator dla{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
            >
              Android
            </a>{' '}
            lub{' '}
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
            >
              iOS
            </a>
            , oraz każdej innej obsługującej kody TOTP.
            <br></br>
            Po zeskanowaniu wpisz jednorozawy kod w poniższe pole tekstowe.
          </div>
          <div id="qrcode" className={styles.qrcode}></div>
          {isLoadingTotp && (
            <div className={styles.loading}>
              <Spinner className={styles.spinner} />
              <p>Wczytywanie</p>
            </div>
          )}
          <Input
            type="text"
            label="Kod weryfikacyjny"
            onChange={(v) => setTotpCode(v)}
          ></Input>
          {errorMessage && <div className={styles.warn}>{errorMessage}</div>}
        </>
      )}

      <div className={styles.buttons}>
        {skipButtonState.show && (
          <HollowButton
            className={styles.button}
            text={skipButtonState.label}
            onClick={() => nextStep?.()}
          ></HollowButton>
        )}
        {prevButtonState.show && (
          <HollowButton
            className={styles.button}
            text={prevButtonState.label}
            onClick={() => prevInternal?.()}
          ></HollowButton>
        )}
        {nextButtonState.show && (
          <Button
            className={styles.button}
            text={nextButtonState.label}
            disabled={nextButtonState.disabled}
            onClick={() => nextStepInternal?.()}
          ></Button>
        )}
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
