export const calculatePosition = (tx: number, ty: number, width?: number, height?: number) => {
    const screenWidth = window.outerWidth;
    const screenHeight = window.outerHeight - 84;
    
    let x = tx - ((width ?? 0) / 2);
    let y = ty - 10;
    

    if(x - (width ?? 0) / 2 < 0) {
        x = (width ?? 0) / 2 + 10
    }

    if (x + (width ?? 0)  / 2 + 30 > screenWidth) {
      x = screenWidth - (width ?? 0) / 2 - 30;
    }

    if(y < 10) {
        y = 10
    }

    if (y + (height ?? 0) > screenHeight) {
      y = screenHeight - (height ?? 0);
    }



    return {
      x,
      y,
    };
  };