export const addCopyToFileName = (fileName: string): string => {
  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex === -1 || dotIndex === 0) {
    return `${fileName}_kopia`;
  }

  const name = fileName.substring(0, dotIndex);
  const extension = fileName.substring(dotIndex);

  return `${name}_kopia${extension}`;
};
