import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import { faC, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useRef, useState } from 'react';
import statusService from 'api/statusService';
import { IStatus } from 'dto/IStatus';
import StatusBadge from 'components/StatusBadge';
import { useOnClickOutside } from 'usehooks-ts';
import NewInput from 'components/NewInput';
import externalService from 'api/externalService';
import editorService from 'api/editorService';

const SelectTemplate: FC<Props> = ({
  isExternal = false,
  onChoose,
  selectedTemplate,
}) => {
  const [active, setActive] = useState(false);

  const [value, setValue] = useState('');

  const { data: externalTemplates } = externalService.useGetTemplate(value);

  const { data: templates } = editorService.useGetTemplate(value, false, true);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }
  });

  return (
    <div ref={outsideRef} className={styles.wrapper}>
      <div onClick={() => setActive(!active)} className={styles.card}>
        {selectedTemplate ? (
          <div className={styles.text}>{selectedTemplate.S1}</div>
        ) : (
          <div className={styles.text}>Szablon</div>
        )}
        <FontAwesomeIcon icon={active ? faCaretUp : faCaretDown} />
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.search}>
            <NewInput
              focus
              label="Szukaj szablonu"
              labelClassName={styles.x}
              className={styles.d}
              onClick={() => setActive(true)}
              value={value}
              onChange={(text) => setValue(text)}
              // onBlur={onBlur}
            />
          </div>
          <div className={styles.list}>
            {isExternal &&
              externalTemplates?.map((ex) => {
                return (
                  <div
                    onClick={() => {
                      setActive(false);
                      onChoose(ex);
                    }}
                    key={ex.Id}
                    className={styles.box}
                  >
                    {ex.S1}
                  </div>
                );
              })}
            {!isExternal &&
              templates?.map((ex) => {
                return (
                  <div
                    onClick={() => {
                      setActive(false);
                      onChoose(ex);
                    }}
                    key={ex.Id}
                    className={styles.box}
                  >
                    {ex.S1}
                  </div>
                );
              })}
            {/* {statuses?.map((status) => (
              <div
                onClick={() => {
                  setActive(false);
                  onChoose(status);
                }}
                key={status.Id}
                className={styles.box}
              >
                {status.Name}
              </div>
            ))} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectTemplate;
