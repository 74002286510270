import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as SearchIcon } from 'assets/icons/previewGreen.svg';
import BIRService from 'api/BIRService';
import { useEffect, useMemo, useState } from 'react';
import { IGUSCompany } from 'dto/IGUSCompany';
import { isValidNip } from 'utils/IsValidNip';
import { useDebounce } from 'use-debounce';

const SearchByNip: FC<Props> = ({
  isError = '',
  defaultValue,
  onDataChange,
  onChange,
  onBlur,
}) => {
  const [nip, setNip] = useState(defaultValue ?? '');
  const [debouncedNip] = useDebounce(nip, 300);
  const { data, refetch } = BIRService.useGetCompanyFromNIP(
    debouncedNip.replaceAll(' ', '')
  );

  const company: IGUSCompany = useMemo(() => data?.data ?? [], [data]);

  useEffect(() => {
    if (!company?.Name?.length) return;
    onDataChange(company);
  }, [company]);

  const handleSearch = () => {
    if (isValidNip(nip)) {
      refetch();
    }
  };

  const activeButton = isValidNip(nip) ? styles.activeButton : '';

  return (
    <div className={styles.wrapper}>
      <Input
        focus
        isNip
        onBlur={onBlur}
        isError={isError}
        onChange={(t) => {
          setNip(t);
          onChange(t);
        }}
        value={nip}
        label={'Nip'}
        placeholder="NIP"
      />
      <div onClick={handleSearch} className={`${styles.text} ${activeButton}`}>
        Szukaj po NIP
      </div>
    </div>
  );
};

export default SearchByNip;
