import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { AxiosResponse } from 'axios';

const queryKeys = {
  getClient: (clientId: number | string) => ['clientService.getClient', clientId],
};

const getClient = (clientId: string | number) => {
  return axiosInstance.get(`/client?id=${clientId}`);
};

const useGetClient = (clientId: string | number, enabled = true) => {
  return useQuery(queryKeys.getClient(clientId), () => getClient(clientId), {
    enabled: enabled
  })
};

export interface IUpdateClient {
    Id: number
    IsInstitution: boolean
    Name: string
    Address: string
    StreetId: string
    City: string
    CityId: string
    ZipCode: string
    Phone: string
    Email: string
    Note: string
    NIP: string
    REGON: any
    ContactPersons: any[]
    TagIds: any[]
    NewTags: any[]
}

export interface ClientData {
  Type: string;
  Value: string | null;
  IsMain: boolean;
}

export interface ICreateClient {
  IsInstitution: boolean | null;
  Name: string | null;
  NIP?: string | null;
  REGON?: string | null;
  KRS?: string | null;
  Data: ClientData[] | null;
  TagIds: string[] | null;
  // WorkspaceId: string | null;
  // WorkspaceRoleId: string | null;
}

export interface INewUpdateClient {
  Id: string | number;
  IsInstitution: boolean | null;
  Name: string | null;
  NIP?: string | null;
  REGON?: string | null;
  KRS?: string | null;
  Data: ClientData[] | null;
  TagIds: string[] | null;
  // WorkspaceId: string | null;
  // WorkspaceRoleId: string | null;
}

const createClient = (payload: ICreateClient) => {
  return axiosInstance.post("/client", payload);
}

const useCreateClient = () => {
    return useMutation(createClient);
}

const updateClient = (payload: IUpdateClient) => {
    return axiosInstance.put('/client', payload);
}

const useUpdateClient = () => {
    return useMutation(updateClient);
}

const newupdateClient = (payload: INewUpdateClient) => {
    return axiosInstance.put('/client', payload);
}

const useNewUpdateClient = () => {
    return useMutation(newupdateClient);
}

export interface IDeleteClient {
  Id: string | number;
}

const deleteClient = (payload: IDeleteClient) => {
  return axiosInstance.delete('/client', {
    data: payload
  })
} 

const useDeleteClient = () => {
  return useMutation(deleteClient);
}

export default {
    useGetClient,
    useUpdateClient,
    useDeleteClient,
    useCreateClient,
    useNewUpdateClient
};
