import tagsService from 'api/tagsService';
import taskService from 'api/taskService';
import Input from 'components/Input';
import TagBadge from 'components/TagBadge';
import { ITag } from 'dto/ITag';
import { ITask } from 'dto/ITask';
import { useEffect, useMemo, useState } from 'react';
import { getTagType } from 'utils/getTagType';
import { ENotificationType, notification } from 'utils/notification';
import styles from './styles.module.scss';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { FC, Props } from './typings';
import NewInput from 'components/NewInput';

const AddTagModal: FC<Props> = ({
  toggle,
  updateTags,
  toTag,
  isTask = false,
  afterAddTag,
  afterRemoveTag,
}) => {
  const [tags, setTags] = useState<ITag[]>([]);

  const { data: tagsData } = tagsService.useGetTags();
  const allTags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const { mutate: editTags } = tagsService.useAddTag();
  const { mutate: editTask } = taskService.useUpdateTask();

  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState<ITag[]>([]);

  useEffect(() => {
    if (!value.length) {
      setSearch(tags);
      return;
    }

    setSearch(
      allTags.filter(
        (tag: ITag) =>
          tag.Name.toLocaleLowerCase().search(value.toLocaleLowerCase()) !== -1
      )
    );
    setActive(true);
  }, [allTags, value]);

  const handleAddTag = (tag: ITag) => {
    const newTags = [...tags, tag];

    if (isTask) {
      const task = toTag as ITask;
      editTask({
        Id: task.Id,
        Allday: task.AllDay,
        FromDate: task.FromDate,
        IsFavourite: task.IsFavourite,
        Note: task.Note,
        OwnerId: task.OwnerId,
        OwnerKind: task.Kind,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
        ToDate: task.ToDate,
      });
      afterAddTag?.(tag.Id);
      setTags(newTags);
      return;
    }

    editTags(
      {
        OwnerKind: toTag.Kind,
        NewTags: newTags
          .filter((tag) => tag.Id === '-1')
          .map((tag) => tag.Name),
        OwnerId: toTag.Id,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
      },
      {
        onSuccess: (r) => {
          if (r.data) {
            updateTags(r.data.map((t: ITag) => t));
          }
        },
      }
    );
    afterAddTag?.(tag.Id);
    setTags(newTags);
    setValue('');
  };

  useEffect(() => {
    setTags(allTags.filter((t: ITag) => (toTag.TagIds ?? []).includes(t.Id)));
  }, [allTags, toTag]);

  const handleRemoveTag = (rtag: ITag) => {
    const newTags = tags.filter((tag) => tag.Name !== rtag.Name);

    if (isTask) {
      const task = toTag as ITask;
      editTask({
        Id: task.Id,
        Allday: task.AllDay,
        FromDate: task.FromDate,
        IsFavourite: task.IsFavourite,
        Note: task.Note,
        OwnerId: task.OwnerId,
        OwnerKind: task.Kind,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
        ToDate: task.ToDate,
      });
      afterRemoveTag?.(rtag.Id);
      setTags(newTags);
      return;
    }

    editTags(
      {
        OwnerKind: toTag.Kind,
        NewTags: newTags
          .filter((tag) => tag.Id === '-1')
          .map((tag) => tag.Name),
        OwnerId: toTag.Id,
        TagIds: newTags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id),
      },
      {
        onSuccess: (r) => {
          if (r.data) {
            updateTags(r.data.map((t: ITag) => t));
          }
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
    afterRemoveTag?.(rtag.Id);
    setTags(newTags);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Etykieta
        <div
          onClick={(e) => {
            toggle(false);
            e.stopPropagation();
          }}
          className={styles.close}
        >
          <CloseIcon className={styles.closeIcon} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.search}>
          <Input
            focus
            placeholder="Szukaj etykiety"
            onChange={(text) => setValue(text)}
            onChooseTag={handleAddTag}
            label="Szukaj etykiety"
          />
          {active && (
            <div className={styles.dropdown}>
              {search.map((tag: ITag) => (
                <div
                  onClick={() => {
                    handleAddTag(tag);
                    setActive(false);
                  }}
                  key={tag.Id}
                  className={styles.box}
                >
                  {tag.Name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.activeList}>
          {tags.map((tag) => (
            <TagBadge
              key={tag.Id}
              tag={tag}
              onRemove={() => handleRemoveTag(tag)}
              allwaysShowRemove={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddTagModal;
