export type { FC } from 'react';

export interface Props {
  showTabBar: (v: boolean) => void;
}

export enum ETabs {
  ACCOUNTS = 'ACCOUNTS',
  PROPOSALS = 'PROPOSALS',
  CREATE = 'CREATE'
}