import Spinner from 'components/Spinner';
import styles from './styles.module.scss';

const Loading = ({ withoutText = false }: { withoutText?: boolean }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Spinner className={styles.spinner} />
        {!withoutText && <div className={styles.text}>Trwa ładowanie</div>}
      </div>
    </div>
  );
};

export default Loading;
