import { useAuth } from 'react-oidc-context';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { getUserId } from 'utils/getUserId';
import { getKeys } from 'utils/getKeys';
import { reEncrypt } from 'utils/reEncrypt';
import { axiosInstance } from 'api/axios';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import * as openpgp from 'openpgp';
import { readAsBase64 } from 'utils/readAsBase64';
import subAccountsService from 'api/subAccountsService';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import Avatar from 'components/Avatar';
import { EStatus } from 'components/Avatar/typings';
import Button from 'components/Button';
import { useState } from 'react';
import AddPermission from '../AddPermission';
import PermissionUser from '../PermissionUser';

const PermissionTab: FC<Props> = ({}) => {
  const auth = useAuth();
  const currentUserId = getUserId(auth.user!);

  const workspaceId = getWorkspaceId();

  const { data: permissionData, refetch } =
    subAccountsService.useGetSubAccounts(workspaceId!);

  const handleAdd = async (
    userId: string,
    filters: {
      filterId: string;
      filterName: string;
      isWrite: boolean;
      id: number;
    }[]
  ) => {
    const myKeys = await getKeys(currentUserId!);

    const { data } = await axiosInstance.post('/account/intermediateKeys', {
      UserIds: [userId],
    });

    const interKey = data.Keys[0].PublicKey;

    const armored = await readFileFromBase64(interKey, 'application/other');
    const interKeyDecoded = new TextDecoder().decode(armored);
    const interKeyReady = (await openpgp.key.readArmored(interKeyDecoded))
      .keys[0];

    const myInter = myKeys!.intermidatePrivateKey!.data;

    const { message } = await openpgp.encrypt({
      message: await openpgp.message.fromText(myInter),
      publicKeys: [interKeyReady],
      armor: false,
    });

    const uint = await new Uint8Array(message.packets.write());

    const newKey = await readAsBase64(uint);

    await axiosInstance.post('/subaccount/privileges', {
      UserId: userId,
      WorkspaceId: getWorkspaceId(),
      Key: newKey,
      ReadonlyFilters: filters.filter((f) => !f.isWrite).map((f) => f.filterId),
      WriteFilters: filters.filter((f) => f.isWrite).map((f) => f.filterId),
    });

    refetch();
    setNewAdd(null);
  };

  const [addNew, setAddNew] = useState(false);

  const [setAdd, setNewAdd] = useState<{
    id: string;
    userId: string;
    label: string;
    filters: {
      id: number;
      filterId: string;
      filterName: string;
      isWrite: boolean;
      main: boolean;
    }[];
  } | null>(null);

  if (setAdd) {
    return (
      <PermissionUser
        toggle={() => setNewAdd(null)}
        handleAdd={handleAdd}
        user={setAdd}
      />
    );
  }

  if (addNew) {
    return <AddPermission setAddNew={setAddNew} setNewAdd={setNewAdd} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Uprawnienia do obierania i nadwania</div>

      <div className={styles.list}>
        {permissionData?.Subaccounts?.map((p: any) => {
          const writeList = p.WriteFilters.map((f: any) => {
            return {
              id: f.List,
              filterId: f.Id,
              filterName: f.Name,
              isWrite: true,
            };
          });

          const readList = p.ReadonlyFilters.map((f: any) => {
            return {
              id: f.List,
              filterId: f.Id,
              filterName: f.Name,
              isWrite: false,
            };
          });

          return (
            <div
              onClick={() => {
                setNewAdd({
                  id: p.Id,
                  userId: p.UserId,
                  label: p.Label,
                  filters: [writeList, readList].flat(),
                });
              }}
              className={styles.box}
            >
              <div className={styles.data}>
                <Avatar
                  name={p.Label}
                  status={p.isOnline ? EStatus.ONLINE : EStatus.OFFLINE}
                />
                <div className={styles.name}>
                  <div className={styles.label}>{p.Label}</div>
                  <div className={styles.role}>Rola</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Button
        onClick={() => {
          setAddNew(true);
        }}
        className={styles.button}
        text="+ Dodaj upoważnienia"
      />
      {/* <button onClick={handleAdd}>Dodaj</button> */}
    </div>
  );
};

export default PermissionTab;
