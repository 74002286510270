import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import casesService from 'api/casesService';
import NewInputWithRightIcon from 'components/NewInputWithRightIcon';
import CasesContext from 'contexts/CasesContext';
import { IEntity } from 'dto/IEntity';
import { IParcelTypes } from 'dto/IParcelTypes';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  convertParcelTypeToKind,
  getTypeOfParcel,
  translateToPolish,
} from 'utils/getTypeOfParcel';
import { validateEmail } from 'utils/isEmail';
import styles from './styles.module.scss';
import { Props } from './typings';
import Avatar from 'components/Avatar';
import { useOnClickOutside } from 'usehooks-ts';
import Button from 'components/Button';
import CasePerson from 'components/CasePerson';
import { validateEDeliveryAddress } from 'utils/iseDeliveryAddress';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import workspaceService from 'api/workspaceService';
import { IWorkspace, IWorkspaceMember } from 'dto/IWorkspace';

const Address = ({
  cas,
  types,
  onSelect,
  selectedType,
  setSelectedType,
}: {
  cas: any;
  types: IParcelTypes[];
  onSelect: (cas: IEntity, type: IParcelTypes) => void;
  selectedType: IParcelTypes | null;
  setSelectedType: (type: IParcelTypes | null) => void;
}) => {
  const [showed, setShowed] = useState(false);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (showed) {
      setShowed(false);
    }
  });

  return (
    <div className={styles.adressWrapper}>
      <div
        onClick={() => {
          setShowed(!showed);
        }}
        className={styles.selected}
      >
        {selectedType ? translateToPolish(selectedType) : <>Brak</>}
        {/* {!!types.length ? translateToPolish(types[0]) : <>Brak</>} */}
        <FontAwesomeIcon icon={showed ? faCaretUp : faCaretDown} />
      </div>
      {showed && (
        <div ref={outsideRef} className={styles.dropdown}>
          {types.map((type) => {
            return (
              <div
                onClick={() => {
                  // onSelect(cas, type);
                  setSelectedType(type);
                  setShowed(false);
                }}
                className={styles.dropdownBox}
              >
                {translateToPolish(type)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const AddressList = ({
  caseId,
  toggle,
  onSelect,
  addAll,
}: {
  caseId: string;
  toggle: () => void;
  addAll: (
    entities: IEntity[],
    list: { entity: IEntity; type: IParcelTypes | null }[]
  ) => void;
  onSelect: (cas: IEntity, type: IParcelTypes) => void;
}) => {
  const { data: caseData, refetch } = casesService.useGetCase(caseId ?? '');

  const c = useMemo(() => caseData ?? null, [caseData]);

  const [list, setList] = useState<
    { entity: IEntity; type: IParcelTypes | null }[]
  >([]);

  useEffect(() => {
    const m = c?.Participants.map((cas) => {
      const types: IParcelTypes[] = getTypeOfParcel(cas.ParcelTypes ?? 0);
      const defaultParcelType = cas.DefaultParcelType;

      if (!defaultParcelType) {
        return null;
      }

      const typ = getTypeOfParcel(defaultParcelType);

      const m = types.find((t) => t === typ[0]) ?? types?.[0];

      return { entity: cas, type: m };
    }).filter((c) => c !== null);

    //@ts-ignore
    setList(m);
  }, [c]);

  const [showEdit, setShowEdit] = useState(false);

  if (showEdit) {
    return (
      <div className={`${styles.b}`}>
        <CasePerson
          toggle={() => {
            refetch();
            setShowEdit(false);
          }}
          Case={c!}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.list} ${styles.b}`}>
      <div className={styles.title}>
        Lista adresowa
        <CloseIcon onClick={toggle} className={styles.listIcon} />
      </div>

      <div className={styles.entityList}>
        {c?.Participants.map((p) => {
          // const [selectedType, setSelectedType] = useState<IParcelTypes | null>(
          //   null
          // );
          const types: IParcelTypes[] = getTypeOfParcel(p.ParcelTypes ?? 0);

          return (
            <div className={styles.box}>
              <div className={styles.boxs}>
                <div
                  onClick={() => {
                    const type = list.find((a) => a.entity === p);

                    //@ts-ignore
                    onSelect(p, type.type);
                  }}
                  className={styles.data}
                >
                  <div className={styles.avatar}>
                    <Avatar
                      className={styles.avatar}
                      name={p.Label ?? p.Email ?? ''}
                    />
                  </div>
                  {p.Label ?? p.Email}
                </div>
                <div className={styles.addressList}>
                  <Address
                    selectedType={
                      list?.find((a) => a.entity.Id === p.Id)?.type ?? null
                    }
                    setSelectedType={(typwe: IParcelTypes | null) => {
                      const newList = [...list];

                      const element = newList.find((a) => a.entity.Id === p.Id);

                      if (element) {
                        element.type = typwe;
                        setList(newList);
                        return;
                      }

                      //@ts-ignore
                      newList.push({ entity: p, type: typwe });
                      setList(newList);
                    }}
                    onSelect={onSelect}
                    cas={p}
                    types={types}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.buttons}>
        <div
          onClick={() => {
            setShowEdit(true);
          }}
          className={styles.cta}
        >
          Edytuj listę adresową
        </div>
        <Button
          onClick={() => {
            //@ts-ignore
            addAll(c!.Participants!, list);
            toggle();
          }}
          className={styles.btn}
          text="Wybierz wszystkie"
        />
      </div>
    </div>
  );

  // return (
  //   <div className={styles.list}>
  //     <div className={styles.listAT}>
  //       Lista adresowa

  //     </div>
  //     <div className={styles.listWrapper}>
  //       {c?.Participants?.map((participant: ICaseParticipant) => {
  //         const types = getTypeOfParcel(participant.ParcelTypes ?? 0);

  //         return (
  //           <div key={participant.Id} className={styles.box}>
  //             <div className={styles.client}>
  //               {participant.Label}

  //               <Dropdown
  //                 className={styles.drp}
  //                 noBorder
  //                 allowNull
  //                 //@ts-ignore
  //                 defaultValue={participant.DefaultParcelType}
  //                 placeholder="Wybierz z listy"
  //                 //@ts-ignore
  //                 list={types.map((t) => ({
  //                   name:
  //                     t !== IParcelTypes.Email
  //                       ? translateToPolish(t)
  //                       : `Email: ${participant.Email}`,
  //                   value: t,
  //                 }))}
  //                 //@ts-ignore
  //                 onChange={(v) => onSelect(participant, v.value)}
  //               />
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   </div>
  // );
};

const NewSearchEntitiesWithCase: FC<Props> = ({
  placeholder = '',
  c,
  onChoose,
  className,
  showAlways = false,
  entities: listEntities,
  defaultValue,
  focus = false,
  isFullscreen,
  showWorkspaceMembers,
  onChooseMembers,
}) => {
  const [showList, setShowList] = useState(false);
  const ref = useRef(null);
  const [selected, setSelected] = useState<IEntity[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<IWorkspaceMember[]>(
    []
  );
  const [active, setActive] = useState(false);
  const [value, setValue] = useState('');
  const [eDelvierySearch, setEDeliverySearch] = useState('');
  const [debouncedText] = useDebounce(value, 500);
  const [searchList, setSearchList] = useState<IEntity[]>([]);

  const [stopSearchById, setStopSearchById] = useState(false);
  const [searchedCaseId, setSearchedCaseId] = useState<null | string>(null);

  const { data: entitiesData } = casesService.useGetAddressBook(debouncedText);
  const { mutate: searchEDeliveryAddress } =
    casesService.useSearchEDeliveryAddress();

  const { data: caseData } = casesService.useGetCase(searchedCaseId ?? '');

  const { cases, setCases } = useContext(CasesContext);

  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId() ?? '');

  const { data: workspaceData, refetch } =
    workspaceService.useGetWorkspace(workspaceId);

  const workspace: IWorkspace = useMemo(
    () => workspaceData?.data ?? [],
    [workspaceData]
  );

  const workspaceMembers = useMemo(() => workspace?.Members ?? [], [workspace]);

  const [searchedMembers, setSearchedMembers] = useState<IWorkspaceMember[]>(
    []
  );

  useEffect(() => {
    if (!focus) return;
    setActive(true);
  }, [focus]);

  useOnClickOutside(ref, () => {
    setSelectedEntity(null);
  });

  const entities: IEntity[] = useMemo(
    () => entitiesData?.data ?? [],
    [entitiesData]
  );

  const isCase = window.location.href.search('cases') !== -1;

  useEffect(() => {
    if (caseData?.Participants) {
      const e = caseData.Participants.map((p) => {
        const types = getTypeOfParcel(p.ParcelTypes ?? 0);

        //@ts-ignore
        p.parcelTypeSelected = types[0] ?? null;

        return p;
      });

      //@ts-ignore
      setSelected(e);
      //@ts-ignore
      onChoose?.(e);
    }
  }, [caseData]);

  useEffect(() => {
    if (searchedCaseId && !stopSearchById) {
      if (c) {
        setSearchedCaseId(null);

        setStopSearchById(true);
      }
    }

    if (isCase && !stopSearchById) {
      const packs = window.location.href.split('/');
      const index = packs.findIndex((t) => t === 'cases');
      const caseId = packs.at(index + 1);
      // setSearchedCaseId(caseId!);
    }
  }, [isCase, cases]);

  useEffect(() => {
    setSearchList(entities);
  }, [entities]);

  useEffect(() => {
    if (!value.length) {
      setSearchList(entities);

      if (showWorkspaceMembers) {
        setSearchedMembers(workspaceMembers);
      }
      return;
    }

    // filtracja
    if (showWorkspaceMembers) {
      setSearchedMembers(
        workspaceMembers.filter(
          (m) =>
            m.Label.toLocaleLowerCase().search(value.toLocaleLowerCase()) !== -1
        )
      );
    }
  }, [value, workspaceMembers, showWorkspaceMembers]);

  const addAll = (
    entities: IEntity[],
    list: { entity: IEntity; type: IParcelTypes | null }[]
  ) => {
    const readyEntities = entities.map((e) => {
      let parcelType = list.find((p) => p.entity.Id === e.Id)?.type;

      if (!parcelType) {
        const types: IParcelTypes[] = getTypeOfParcel(e.ParcelTypes ?? 0);

        e.parcelTypeSelected = types[0];
      }

      e.parcelTypeSelected = parcelType!;

      return e;
    });

    setSelected(readyEntities);
    onChoose?.(readyEntities);
  };

  const handleClick = (cas: IEntity, type: IParcelTypes) => {
    cas.parcelTypeSelected = type;
    const newList = [...selected, cas];
    setSelected(newList);
    // setValue(`${cas.Client ? `${cas.Client} |` : ''} ${cas.Title}`);
    setActive(false);

    onChoose?.(newList);
    setValue('');
  };

  const handleMemberClick = (cas: IWorkspaceMember) => {
    const newList = [...selectedMembers, cas];
    setSelectedMembers(newList);
    // setValue(`${cas.Client ? `${cas.Client} |` : ''} ${cas.Title}`);
    setActive(false);

    onChooseMembers?.(newList);
    setValue('');
  };

  const handleRemove = (cas: IEntity) => {
    const newList = selected.filter(
      (c) => c.ParcelTypes !== cas.ParcelTypes && c.Label !== cas.Label
    );
    setSelected(newList);
    onChoose?.(newList);
  };

  const handleMemberRemove = (cas: IWorkspaceMember) => {
    const newList = selectedMembers.filter((c) => c.UserId != cas.UserId);
    setSelectedMembers(newList);
    onChooseMembers?.(newList);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target as Element)) {
        setActive(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (!defaultValue && !defaultValue?.length) return;
    const payload: IEntity = {
      Id: -1,
      Address: '',
      BuildingNumber: '',
      City: '',
      Claims: [],
      Description: '',
      EDeliveryAddress: defaultValue,
      Email: defaultValue,
      FirstName: '',
      FlatNumber: '',
      IsGlobal: false,
      KRS: '',
      Label: defaultValue,
      Type: 8,
      ParcelTypes: 8,
      parcelTypeSelected: IParcelTypes.Email,
    };
    setSelected([payload]);
  }, []);

  const checkCanAdd = (disableCheckCharAt: boolean = false) => {
    if (
      value.charAt(value.length - 1) === ' ' &&
      validateEmail(value.replaceAll(' ', ''))
    ) {
      const email = value.replaceAll(' ', '').toLocaleLowerCase();
      handleClick(
        {
          Id: -1,
          Address: '',
          BuildingNumber: '',
          City: '',
          Claims: [],
          Description: '',
          EDeliveryAddress: email,
          Email: email,
          FirstName: '',
          FlatNumber: '',
          IsGlobal: false,
          KRS: '',
          Label: email,
          Type: 8,
          ParcelTypes: 8,
        },
        IParcelTypes.Email
      );
    } else if (
      value.charAt(value.length - 1) === ' ' &&
      validateEDeliveryAddress(value.replaceAll(' ', ''))
    ) {
      const ade = value.replaceAll(' ', '');

      searchEDeliveryAddress(ade, {
        onSuccess(data: any, variables, context) {
          if (data && data.data && data.data.length > 0) {
            handleClick(
              {
                Id: -1,
                Address: '',
                BuildingNumber: '',
                City: '',
                Claims: [],
                Description: '',
                EDeliveryAddress: ade,
                Email: '',
                FirstName: '',
                FlatNumber: '',
                IsGlobal: false,
                KRS: '',
                Label: [data.data[0].FirstName, data.data[0].LastName]
                  .filter(Boolean)
                  .join(' '),
                Type: 1,
                ParcelTypes: 8,
              },
              IParcelTypes.EDelivery
            );
          }
        },
      });
    }
  };
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    checkCanAdd();
  }, [value]);

  const hClick = (e: any) => {
    if (e.code === 'Backspace' && !value.length && isSelected) {
      const newList: IEntity[] = [...selected].slice(0, -1);

      setSelected(newList);
      onChoose?.(newList);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', hClick);
    return () => {
      document.removeEventListener('keydown', hClick);
    };
  }, [value, isSelected]);

  const [hoveredEntity, setHoveredEntity] = useState<IEntity | null>(null);
  const [selectedEntity, setSelectedEntity] = useState<IEntity | null>(null);

  return (
    <div ref={ref} className={`${styles.wrapper} ${className}`}>
      <div className={styles.wrappMe}>
        <div className={`${styles.placeholder} ${isSelected && styles.active}`}>
          {placeholder}
        </div>
        <div
          className={`${styles.listOfEntity} ${isSelected && styles.active}`}
        >
          {selectedMembers?.map((sel: IWorkspaceMember) => {
            return (
              <div className={styles.ent}>
                {sel.Label}
                <CloseIcon
                  onClick={() => handleMemberRemove(sel)}
                  className={styles.remove}
                />
              </div>
            );
          })}

          {selected?.map((sel: IEntity) => {
            const types = getTypeOfParcel(sel.ParcelTypes ?? 0);
            return (
              <div
                onMouseLeave={() => setHoveredEntity(null)}
                onMouseEnter={() => setHoveredEntity(sel)}
                className={styles.ent}
              >
                {sel.Label} ({translateToPolish(sel!.parcelTypeSelected!)})
                {types.length && (
                  <FontAwesomeIcon
                    icon={selectedEntity === sel ? faCaretUp : faCaretDown}
                    onClick={() => {
                      setSelectedEntity(selectedEntity !== null ? null : sel);
                    }}
                  />
                )}
                <CloseIcon
                  onClick={() => handleRemove(sel)}
                  style={{
                    display: `${sel === hoveredEntity ? 'block' : 'none'}`,
                  }}
                  className={styles.remove}
                />
                {selectedEntity === sel && (
                  <div
                    style={{
                      top: '32px',
                    }}
                    className={styles.list}
                  >
                    <div className={styles.listTitle}>{sel.Label}</div>
                    {types.length &&
                      types.map((t) => {
                        return (
                          <div
                            onClick={() => {
                              const newList = [...selected];
                              const s = newList.find(
                                (a) => a === selectedEntity
                              );
                              if (s) {
                                s.parcelTypeSelected = t;
                              }

                              setSelected(newList);
                              setSelectedEntity(null);
                            }}
                            className={`${styles.type} ${
                              t === selectedEntity.parcelTypeSelected &&
                              styles.act
                            }`}
                          >
                            -{' '}
                            {t !== IParcelTypes.Email
                              ? translateToPolish(t)
                              : sel.Email}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.input}>
          <NewInputWithRightIcon
            isFullscreen={isFullscreen}
            focus={focus}
            showAlways={showAlways}
            onFocus={() => setIsSelected(true)}
            onBlur={() => {
              setIsSelected(false);
              if (validateEmail(value.replaceAll(' ', ''))) {
                const email = value.replaceAll(' ', '').toLocaleLowerCase();
                handleClick(
                  {
                    Id: -1,
                    Address: '',
                    BuildingNumber: '',
                    City: '',
                    Claims: [],
                    Description: '',
                    EDeliveryAddress: email,
                    Email: email,
                    FirstName: '',
                    FlatNumber: '',
                    IsGlobal: false,
                    KRS: '',
                    Label: email,
                    Type: 8,
                    ParcelTypes: 8,
                  },
                  IParcelTypes.Email
                );
              }

              if (validateEDeliveryAddress(value.replaceAll(' ', ''))) {
                const ade = value.replaceAll(' ', '').toLocaleLowerCase();

                searchEDeliveryAddress(ade, {
                  onSuccess(data, variables, context) {
                    if (data && data.data && data.data.length > 0) {
                      handleClick(
                        {
                          Id: -1,
                          Address: '',
                          BuildingNumber: '',
                          City: '',
                          Claims: [],
                          Description: '',
                          EDeliveryAddress: ade,
                          Email: '',
                          FirstName: '',
                          FlatNumber: '',
                          IsGlobal: false,
                          KRS: '',
                          Label: [data.data[0].FirstName, data.data[0].LastName]
                            .filter(Boolean)
                            .join(' '),
                          Type: 1,
                          ParcelTypes: 8,
                        },
                        IParcelTypes.EDelivery
                      );
                    }
                  },
                });
              }
            }}
            onClick={() => setActive(true)}
            setShowList={c ? () => setShowList(true) : undefined}
            value={value}
            onChange={(text) => setValue(text)}
            isIconEnabled={!!c}
            // placeholder={placeholder}
          />
        </div>
      </div>
      {!showList ? (
        <>
          {active &&
            (!!value.length ||
              (showWorkspaceMembers && searchedMembers.length > 0)) && (
              <div className={`${styles.list} ${styles.gap}`}>
                {searchedMembers
                  .filter((member) => member.UserId)
                  .map((member) => (
                    <div
                      onClick={() => {
                        setActive(false);
                        handleMemberClick(member);
                      }}
                      key={member.Id}
                      className={styles.box}
                    >
                      <div className={styles.listBox}>
                        <div title={member.Label} className={styles.listTitle}>
                          {member.Label}
                        </div>
                      </div>
                    </div>
                  ))}
                {!!value &&
                  searchList.map((s) => {
                    const types = getTypeOfParcel(s.ParcelTypes ?? 0);
                    return (
                      <div className={styles.listBox}>
                        <div title={s.Label} className={styles.listTitle}>
                          {s.Label}
                        </div>
                        {types.length ? (
                          types.map((t) => {
                            return (
                              <div
                                onClick={() => handleClick(s, t)}
                                className={styles.type}
                              >
                                -{' '}
                                {t !== IParcelTypes.Email
                                  ? translateToPolish(t)
                                  : s.Email}
                              </div>
                            );
                          })
                        ) : (
                          <div
                            style={{ color: '#79767A' }}
                            className={styles.type}
                          >
                            - Brak dostępnych form komunikacji
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
        </>
      ) : (
        <AddressList
          addAll={(entities, list) => {
            addAll(entities, list);
          }}
          onSelect={(c, v) => {
            handleClick(c, v);
            setShowList(false);
          }}
          toggle={() => setShowList(false)}
          caseId={c!.Id}
        />
      )}
    </div>
  );
};

export default NewSearchEntitiesWithCase;

// {c?.Id && (
//   <div
//     onClick={() => setShowList(true)}
//     className={styles.button}
//   >
//     Lista adresowa
//   </div>
// )}

{
  /* {active && !!value.length && !!searchList.length && (
            <div className={styles.list}>
              <div className={styles.listWrapper}>
                {searchList.map((c) => {
                  const types = getTypeOfParcel(c.ParcelTypes ?? 0);

                  return (
                    <div key={c.Id} className={styles.box}>
                      <div className={styles.cliente}>{c.Label}</div>

                      <div className={styles.title}>
                        {!!types.length ? (
                          types.map((type) => {
                            return (
                              <div
                                className={styles.label}
                                onClick={() => handleClick(c, type)}
                              >
                                -{' '}
                                {type !== IParcelTypes.Email
                                  ? translateToPolish(type)
                                  : c.Email}
                                {(type === IParcelTypes.EDelivery ||
                                  type === IParcelTypes.EncryptedEDelivery) && (
                                  <div className={styles.address}>
                                    {c.EDeliveryAddress}
                                  </div>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <>Brak dostępnych form komunikacji</>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )} */
}
