import { createContext } from 'react';


type IModalsManagerContext = {
    isLoading: boolean;
    setIsLoading: (state: boolean) => void;
};



const LoadingContext = createContext<IModalsManagerContext>({
  isLoading: false,
  setIsLoading: () => {},
});

export default LoadingContext;
