import { FC, Props } from './typings';
import styles from './styles.module.scss';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const BigModal: FC<Props> = ({ toggle, children }) => {
  return ReactDOM.createPortal(
    <div className={styles.wrapper}>
      <CloseIcon className={styles.closeIcon} onClick={toggle} />
      <div className={styles.content}>{children}</div>
    </div>,
    document.body
  );
};

export default BigModal;
