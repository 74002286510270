import { useMutation, useQuery } from "react-query"
import { axiosInstance } from "./axios"

const queryKeys = {
    getOffices: 'officeService.getOffices',
    getOfficeMembers: (officeId: string) => ['officeService.getOfficeMembers', officeId]
}

const getOffices = () => {
    return axiosInstance.get('/workspace')
}

const useGetOffices = () => {
    return useQuery(queryKeys.getOffices, () => getOffices())
}


const getOfficeMembers = (officeId: string) => {
return axiosInstance.get(`/office/users?id=${officeId}`)
}

const useGetOfficeMembers = (disabled: boolean,officeId: string) => {
    return useQuery(queryKeys.getOfficeMembers(officeId), () => getOfficeMembers(officeId), {
        enabled: !disabled
    })
}

export interface IApproveUser {
    Id: string;
    Role: number;
}

const approvalUser = (payload: IApproveUser) => {
    return axiosInstance.post("/office/approveUser", payload)
}

const useApprovalUser = () => {
    return useMutation(approvalUser)
}

export interface IRemoveUser {
    Id: string
}

const removeUser = (payload: IRemoveUser) => {
    return axiosInstance.delete('/office/user', {
        data: payload
    })
}

const useRemoveUser = () => {
    return useMutation(removeUser)
}

export interface IInviteUser {
    Id: string;
    Role: number;
    Emails: string[]
}

const inviteUser = (payload: IInviteUser) => {
    return axiosInstance.post("/office/invite", payload)
}

const useInviteUser = () => {
    return useMutation(inviteUser);
}




export default {
    useGetOffices,
    useGetOfficeMembers,
    useApprovalUser,
    useRemoveUser,
    useInviteUser
}