import { axiosInstance } from "api/axios";

export const fetchEmailAttachment = async (attachmentId: string, packageId: string): Promise<string> => {
  return new Promise(async (resolve) => {
    const r = await axiosInstance.get(`https://beta.notaup.pl/email/attachment?id=${attachmentId}&mailId=${packageId}`, {
      responseType: 'blob',
    })
  
    const pdfBlobUrl = URL.createObjectURL(new Blob([r.data]));

    resolve(pdfBlobUrl)
  })  

}

export const fetchEmailAttachmentBlob = async (attachmentId: string, packageId: string): Promise<Blob> => {
  return new Promise(async (resolve) => {
    const r = await axiosInstance.get(`https://beta.notaup.pl/email/attachment?id=${attachmentId}&mailId=${packageId}`, {
      responseType: 'blob',
    })
  

    resolve(new Blob([r.data]))
  })  

}