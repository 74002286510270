import casesService from 'api/casesService';
import dossierService, { IDossierAddFile } from 'api/dossierService';
import fileService from 'api/fileService';
import keysService from 'api/keysService';
import listService from 'api/listService';
import tagsService from 'api/tagsService';
import { ReactComponent as NoFilesIcon } from 'assets/icons/nofiles.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/dossierNew.svg';
import { ReactComponent as DriveIcon } from 'assets/icons/hard_drive.svg';
import { ReactComponent as DocIcon } from 'assets/icons/doc.svg';
import { ReactComponent as PptIcon } from 'assets/icons/ppt.svg';
import { ReactComponent as XlsIcon } from 'assets/icons/xls.svg';
import { ReactComponent as OtherIcon } from 'assets/icons/other.svg';

import AddButton from 'components/AddButton';
import Button from 'components/Button';
import CreateFolder from 'components/CreateFolder';
import DossierFiles from 'components/DossierFiles';
import { DossierFilters, defaultMenu } from 'components/DossierFilters';
import DossierFolders from 'components/DossierFolders';
import { config } from 'config';
import DossierFilesContext from 'contexts/DossierFilesContext';
import DossierFilterContext, { IMenu } from 'contexts/DossierFilters';
import KeysContext from 'contexts/KeysContext';
import ModalsManagerContext, { defaultValue } from 'contexts/ModalsManager';
import { ICase } from 'dto/Cases/ICase';
import { IDossierFakeItem } from 'dto/Dossier/IDossierFakeItem';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { ItemKind } from 'dto/IKindItems';
import { IPackage } from 'dto/IPackage';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { FileUploader } from 'react-drag-drop-files';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptFile } from 'utils/decryptFile';
import { getBlob } from 'utils/getBlob';
import { getFile } from 'utils/getFile';
import { getUserId } from 'utils/getUserId';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-new.svg';
import { ReactComponent as CutIcon } from 'assets/icons/cut.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/bin.svg';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ENotificationType, notification } from 'utils/notification';
import { handleCreate } from 'utils/uploadFile';
import { createDossierObject } from './createDossierObject';
import statusService from 'api/statusService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faEllipsisVertical,
  faGripVertical,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { IDossierFolderPathFolder } from 'dto/Dossier/IDossierFolderPathFolder';
import { IDossierFolder } from 'dto/Dossier/IDossierFolder';
import { DossierItemFlags } from 'dto/Dossier/DossierItemFlags';
import { DossierItemType } from 'dto/Dossier/DossierItemType';
import { IKey } from 'dto/IKey';
import { EOrder } from 'components/SortOrder/typings';
import { getDossierItemFileName } from 'utils/getDossierItemFileName';
import useStateRef from 'utils/useStateRef';
import { addCopyToFileName } from 'utils/addCopyToFileName';
import Loading from 'components/Loading';
import Spinner from 'components/Spinner';
import ConfirmModal from 'components/ConfirmModal';

const CaseDossier: FC<Props> = ({
  onSelecting,
  handleAdd,
  Case,
  className = '',
  isOutcoming = false,
  setShowCreateFolder,
  setCurrentFolderToCaseView,
  allInOne = false,
  shares = false,
}) => {
  const navigate = useNavigate();
  const { caseId, view, folderId } = useParams();

  const [selectedFiles, setSelectedFiles] = useState<IDossierItem[]>([]);
  const { mutate: deleteDossier } = dossierService.useDeleteDossier();

  const [currentFolder, setCurrentFolder, currentFolderRef] =
    useStateRef<IDossierItem | null>(
      folderId
        ? {
            Id: folderId,
            DossierId: folderId,
            Label: 'Folder',
            CreationDate: new Date(),
            LastModified: new Date(),
            Kind: ItemKind.DossierFolder,
            EncryptionKeyId: '',
            Type: DossierItemType.Folder,

            IsShared: false,
            TagCount: 0,
            CommentsCount: 0,
            DeskInfo_IsOn: false,
          }
        : null
    );
  const [selectedFolder, setSelectedFolder] = useState<IDossierItem | null>(
    null
  );
  const [canSelect, setCanSelect] = useState(isOutcoming ?? false);
  const [showCreateMenu, setShowCreateMenu] = useState<boolean>(false);

  const { modals, setModals, setOptions } = useContext(ModalsManagerContext);
  const { dossierFiles, setDossierFiles } = useContext(DossierFilesContext);

  const { mutate: moveDossier } = dossierService.useMoveDossier();
  const {
    data: dossierData,
    refetch,
    isFetching: isDossierLoading,
  } = dossierService.useGetItems({
    CaseId: caseId,
    FolderId: currentFolder?.Id,
    Page: 0,
    SortOrder: !allInOne ? 'Ascending' : 'Descending',
    SortProp: !allInOne ? 'Name' : 'LastModification',

    IncludeFolders: !allInOne,
    Shared: !!shares ? true : undefined,
  });

  const { data: folderData } = dossierService.useGetFolder(
    currentFolder?.Id ?? '',
    currentFolder != null
  );

  const [menu, setMenu] = useState<IMenu[]>(defaultMenu);
  const [folder, setFolder, folderRef] = useStateRef<IDossierFolder | null>(
    null
  );

  useEffect(() => {
    setFolder(folderData);
  }, [folderData]);

  const encryptionKeys = useMemo(
    () => dossierData?.data.ObjectKeys ?? [],
    [dossierData]
  );

  const parentKeys = useMemo(() => dossierData?.data.Keys ?? [], [dossierData]);

  const { data: tagsData } = tagsService.useGetTags();
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);
  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);

  const dossiers: IDossierItem[] = useMemo(
    () => dossierData?.data.Items ?? [],
    [dossierData]
  );

  const [showDelete, setShowDelete] = useState(false);

  const { keys, setKeys } = useContext(KeysContext);

  const ObjectKeys = useMemo(
    () => dossierData?.data.ObjectKeys ?? [],
    [dossierData]
  );
  const k = useMemo(() => dossierData?.data.Keys ?? [], [dossierData]);

  useEffect(() => {
    setFiles(dossierFiles);
  }, [dossierFiles]);

  useEffect(() => {
    if (!dossierData?.data) return;
    const a = [k as IKey[], ObjectKeys as IKey[]].flat();
    // @ts-ignore
    setKeys((prev) => [...prev, ...a]);
    console.log('Keys updated, added', a);
  }, [ObjectKeys, k, dossierData]);

  const [files, setFiles] = useState<IDossierItem[]>([]);
  const [folders, setFolders] = useState<IDossierItem[]>([]);

  const sortFolder = (
    folderA: IDossierItem,
    folderB: IDossierItem,
    prop: string,
    order: EOrder
  ): number => {
    switch (prop) {
      default: {
        return order === EOrder.DESCENDING
          ? (folderA.Label ?? '').localeCompare(folderB.Label ?? '')
          : (folderB.Label ?? '').localeCompare(folderA.Label ?? '');
      }
    }
  };

  useEffect(() => {
    var currentSort = menu.filter((x) => x.sort);
    var sortProp = currentSort.length > 0 ? currentSort[0].type : 'name';
    var sortOrder =
      currentSort.length > 0 ? currentSort[0].order : EOrder.ASCENDING;

    const folders = dossiers
      ?.filter((dossier) => dossier.Kind === ItemKind.DossierFolder)
      .sort((a, b) => sortFolder(a, b, sortProp, sortOrder));

    setFolders(folders as any);

    const files = dossiers?.filter(
      (dossier) => dossier.Kind !== ItemKind.DossierFolder
    );

    setFiles(files);
    setDossierFiles(files);
  }, [dossiers, menu]);

  const [can, setCan] = useState(false);
  const [selectMultiple, setSelectMultiple] = useState(false);

  const handleKeyDown = (e: any) => {
    console.log(e);
    if (e.code === 'ControlLeft') {
      setCan(true);
      setCanSelect(true);
    }

    if (e.code === 'ShiftLeft') {
      setCan(true);
      setCanSelect(true);
      setSelectMultiple(true);
    }

    if (e.ctrlKey && e.key === 'c') {
      handleCopy(false);
    }

    if (e.ctrlKey && e.key === 'x') {
      handleCopy(true);
    }

    if (e.ctrlKey && e.key === 'v') {
      handlePaste();
    }

    if (e.code === 'ArrowDown') {
      if (selectedFiles.length > 0) {
        var selected = selectedFiles[0];

        if (selected.Kind === ItemKind.DossierFolder) {
          var index = folders.indexOf(selected);

          if (index >= 0 && index < folders.length - 1) {
            setSelectedFiles([folders[index + 1]]);
          } else if (files.length > 0) {
            setSelectedFiles([files[0]]);
          }
        } else {
          var index = files.indexOf(selected);
          if (index >= 0 && index < files.length - 1) {
            setSelectedFiles([files[index + 1]]);
          }
        }
      } else {
        if (folders.length > 0) {
          setSelectedFiles([folders[0]]);
        } else if (files.length > 0) {
          setSelectedFiles([files[0]]);
        }
      }
      e.preventDefault();
    }
    if (e.code === 'ArrowUp') {
      if (selectedFiles.length > 0) {
        var selected = selectedFiles[0];

        if (selected.Kind === ItemKind.DossierFolder) {
          var index = folders.indexOf(selected);

          if (index > 0) {
            setSelectedFiles([folders[index - 1]]);
          }
        } else {
          var index = files.indexOf(selected);
          if (index > 0) {
            setSelectedFiles([files[index - 1]]);
          } else {
            setSelectedFiles([folders[folders.length - 1]]);
          }
        }
      }
      e.preventDefault();
    }

    if (e.code === 'Enter') {
      if (selectedFiles.length > 0) {
        var selected = selectedFiles[0];

        if (selected.Kind === ItemKind.DossierFolder) {
          onFolderDoubleClick(selected);
        } else {
          handleFileDoubleClick(selected);
        }
      }
    }

    // if (e.code === 'Backspace') {
    //   console.log(folderRef.current);
    //   if (folderRef.current) {
    //     if (
    //       folderRef.current &&
    //       folderRef.current.Path &&
    //       folderRef.current.Path.length > 0
    //     ) {
    //       var p = folderRef.current.Path[folderRef.current.Path.length - 1];
    //       onFolderDoubleClick({
    //         Id: p.Id,
    //         CreationDate: new Date(),
    //         Kind: ItemKind.DossierFolder,
    //         S1: '',
    //         S2: '',
    //         S3: '',
    //         EncryptionKeyId: '',
    //         Dossier: {
    //           Id: p.Id,
    //           Label: p.Name,
    //           ParentId: undefined,
    //           Order: folderData.Order,
    //           Flags: DossierItemFlags.None,
    //           CanEdit: true,
    //           CanEditAsCopy: false,
    //           Type: DossierItemType.Folder,
    //         },
    //       });
    //     } else {
    //       navigateToRoot();
    //     }
    //   }
    // }
  };

  const handleKeyUp = (e: any) => {
    setCan(false);
    setCanSelect(false);
    setSelectMultiple(false);
  };

  const handleFileDoubleClick = (file: IDossierItem) => {
    if (file?.Type === DossierItemType.Link) {
      window.open(file.FileUri, '_blank');
      return;
    }
    const isFullscreen = window.location.href.search('fullscreen') !== -1;

    if (isFullscreen) {
      if (
        file.Kind === ItemKind.CaseFile ||
        file.Kind === ItemKind.EditorDocument
      ) {
        //@ts-ignore
        var p = file as IPackage;
        navigate(
          `/fullscreen/cases/${caseId}/dossier/${p.Kind}/${p.Id}/${p.Id}`
        );
      } else {
        var fi = file as IDossierFakeItem;
        navigate(
          `/fullscreen/cases/${caseId}/${view}/${fi.Kind}/${fi.Owner.Id}/${fi.Attachment.Id}`
        );
      }

      return;
    }

    if (
      file.Kind === ItemKind.CaseFile ||
      file.Kind === ItemKind.EditorDocument
    ) {
      //@ts-ignore
      var p = file as IPackage;
      navigate(`/cases/${caseId}/dossier/${p.Kind}/${p.Id}/${p.Id}`);
    } else {
      var fi = file as IDossierFakeItem;
      navigate(
        `/cases/${caseId}/dossier/${fi.Kind}/${fi.Owner.Id}/${fi.Attachment.Id}`
      );
    }
  };

  const moveFile = (from: string, order: number, folderId: string | null) => {
    return new Promise((resolve) => {
      moveDossier(
        {
          Id: from,
          Order: order,
          ParentId: folderId,
        },
        {
          onSuccess: () => {
            resolve(true);
          },
        }
      );
    });
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [can, selectedFiles]);

  const handleOnDrag = async (result: DropResult) => {
    const newFolders = [...folders];
    const newFiles = [...files];
    const source = result.source;
    const destination = result.destination;

    if (source?.droppableId !== destination?.droppableId) {
      const isFolder = destination?.droppableId?.search('folder') !== -1;
      const isFiles = destination?.droppableId?.search('files') !== -1;

      if (isFiles) {
        // z folderu do plików

        if (selectedFiles.length > 1) {
          let dest = destination?.index ?? 0;
          for (const file of selectedFiles) {
            const from = newFiles.find((f) => f.Id === file.Id);
            if (from) {
              from!.ParentId = undefined;
              await moveFile(from.DossierId, dest, null);
            }
            //
            //file.Dossier!.Order = dest;
            dest++;
          }
          setFiles(
            newFiles?.filter(
              (dossier) => dossier.Kind !== ItemKind.DossierFolder
            )
          );
          setSelectedFiles([]);
          return;
        }

        const from = newFiles.find(
          (file) => file!.DossierId === result?.draggableId
        );

        if (!from) return;

        from.ParentId = undefined;
        //from.Dossier.Order = destination?.index ?? 0;
        setFiles(newFiles?.filter((dossier) => dossier.Kind !== 8192));

        moveDossier({
          Id: from.DossierId,
          Order: destination?.index ?? 0,
          ParentId: null,
        });

        return;
      }

      if (isFolder) {
        // z plików do folderu
        const folderId = destination?.droppableId?.split(':')[1];

        if (!folderId) return;

        if (selectedFiles.length > 1) {
          let index = files.filter((file) => file.ParentId === folderId).length;

          for (const file of selectedFiles) {
            const from = newFiles.find((f) => f.Id === file.Id);
            if (from) {
              from!.ParentId = folderId;
              await moveFile(from.DossierId, index, folderId);
              index++;
            }
            file.ParentId = folderId;
          }
          setFiles(
            newFiles?.filter(
              (dossier) => dossier.Kind !== ItemKind.DossierFolder
            )
          );

          setSelectedFiles([]);
          return;
        }

        const from = newFiles.find(
          (file) => file!.DossierId === result?.draggableId
        );

        if (!from) return;

        from.ParentId = folderId;

        moveDossier({
          Id: from.DossierId,
          Order: destination?.index ?? 0,
          ParentId: folderId,
        });

        setFiles(
          newFiles?.filter((dossier) => dossier.Kind !== ItemKind.DossierFolder)
        );

        return;
      }
    }

    if (!destination) return;

    const isFiles = destination?.droppableId?.search('files') !== -1;
    let from: IDossierItem | undefined = newFolders[source?.index ?? 0];
    let to: IDossierItem | undefined = newFolders[destination?.index ?? 0];

    if (isFiles) {
      const aloneFiles = [...files].filter((file) => !file.ParentId);
      if (!aloneFiles) return;
      from = files.find(
        (file) => file.Id === aloneFiles[source?.index ?? 0].Id
      );
      to = files.find(
        (file) => file.Id === aloneFiles[destination?.index ?? 0].Id
      );

      if (!to || !from) return;

      //to.Dossier.Order = source?.index;
      //from.Dossier.Order = destination?.index;
      moveDossier({
        Id: from.DossierId,
        Order: destination?.index + 1,
        ParentId: null,
      });

      console.log('1');
      setFiles(newFiles);
      return;
    }

    if (!to || !from) return;

    // to.Dossier.Order = source?.index;
    // from.Dossier.Order = destination?.index;

    moveDossier({
      Id: from.DossierId,
      Order: destination?.index,
      ParentId: null,
    });

    console.log('2');
    setFolders(newFolders);
  };

  const [destination, setDestination] = useState('');

  const handleDragUpdate = (result: DragUpdate) => {
    setDestination(result.destination?.droppableId ?? '');
  };

  const handleClickAdd = () => setModals({ ...modals, createDossier: true });

  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    const table = localStorage.getItem(config.filters_name);
    if (!table) return;
    const m = JSON.parse(table);
    setMenu(m);
  }, []);

  const { mutate: createObject } = keysService.useCreateObject();
  const { mutate: initUploadFile } = fileService.useInitUpload();
  const { mutate: getS3 } = fileService.useGetS3();
  const { mutate: confirmUpload } = fileService.useConfirmUpload();
  const { mutate: addFileToDossier } = dossierService.useAddFile();

  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const onSelect = (file: IDossierItem) => {
    // if (isOutcoming && !canSelect) {
    //   handleAdd?.([file]);
    //   return;
    // }

    if (!canSelect) {
      console.log('cant select!');
      setCopyCase(null);
      setSelectedFiles([]);
    }

    setCopyCase(Case);

    if (selectMultiple && selectedFiles.length > 0) {
      var currentIndex = files.indexOf(selectedFiles[0]);
      const index = files.indexOf(file);

      if (index < currentIndex) {
        const elements = dossierFiles.filter(
          (e, i) => i >= index && i <= currentIndex
        );
        setSelectedFiles(elements);
      } else {
        const elements = dossierFiles.filter(
          (e, i) => i >= currentIndex && i <= index
        );
        setSelectedFiles(elements);
      }
      return;
    }

    const isExist = selectedFiles.find((s) => s.Id === file.Id);

    if (isExist) {
      setSelectedFiles(selectedFiles.filter((s) => s.Id !== file.Id));
      return;
    }

    const newFiles = canSelect ? [...selectedFiles, file] : [file];

    console.log('Selected files', newFiles);
    setSelectedFiles(newFiles);
  };

  const onFolderDoubleClick = (folder: IDossierItem) => {
    navigate(`/cases/${caseId}/dossier/f/${folder.DossierId}`);
    setCurrentFolder((f) => folder);
    setCurrentFolderToCaseView?.(folder);
  };

  const navigateToRoot = () => {
    navigate(`/cases/${caseId}/dossier`);
    setCurrentFolder((f) => null);
    setCurrentFolderToCaseView?.(null);
  };

  useEffect(() => {
    refetch();
  }, [currentFolder]);

  useEffect(() => {
    onSelecting?.(selectedFiles);
  }, [selectedFiles]);

  const handleUpdateProgressFile = (
    AllFiles: IDossierItem[],
    file: IDossierItem,
    progress: number
  ) => {
    const newDossier = [...AllFiles];

    const f = newDossier.find((f) => f.Id === file.Id);
    if (f) {
      f.progress = progress;
      setDossierFiles(newDossier);
    }
  };

  const handleAddNewFilesToDossier = async (
    elements: IDossierItem[],
    c: ICase
  ) => {
    const newDossierElements = [...files];
    for (const element of elements) {
      const sameFolder = element.ParentId == currentFolder?.Id;

      if (sameFolder) {
        element.Id = `${element.Id}_copy`;
      }

      newDossierElements.push(
        createDossierObject(
          element.Id,
          sameFolder
            ? addCopyToFileName(element.FileName ?? '')
            : element.FileName,
          element.FileContentType,
          element.FileSize,
          element.ParentId,
          element.Label,
          element.Type
        )
      );
    }

    setFiles(newDossierElements.flat());

    for (const element of elements) {
      if (element.Kind != ItemKind.DossierFolder) {
        if (!element.FileUri) continue;

        const sameFolder = element.ParentId == currentFolder?.Id;

        const file = await getFile(element.FileUri);
        const blob = await getBlob(file.data.Url);

        const key = keys.find(
          (key: any) => key?.Id === element.EncryptionKeyId
        );

        if (!key) return;

        let parentKey = null;
        //@ts-ignore
        if (key.ParentKeyId) {
          parentKey = keys.find(
            //@ts-ignore
            (pkey: any) => pkey?.Id === key?.ParentKeyId
          );
        }

        const decryptedFile = await decryptFile(
          userId ?? '',
          new Blob([blob.data]),
          key,
          parentKey
        );

        const r = await handleCreate(
          [decryptedFile],
          Case!.Id,
          Case!.Keys[0].PublicKey ?? '',
          true,
          true,
          sameFolder
            ? addCopyToFileName(element.FileName ?? '')
            : element.FileName,
          element.FileContentType,
          (progress) => {
            handleUpdateProgressFile(newDossierElements, element, progress);
          }
        );

        if (!r) {
          return;
        }

        let payload: IDossierAddFile = {
          CaseId: Case!.Id!,
          Files: [
            {
              KeyId: r[0].keyId,
              Uri: r[0].uri,
            },
          ],
        };

        if (currentFolder) {
          payload = { ...payload, ParentId: currentFolder.DossierId };
        }

        addFileToDossier(payload, {
          onSuccess: () => {
            if (cutting) {
              deleteDossier({
                Id: element.DossierId,
              });
            }
          },
        });
      } else {
        if (cutting && Case!.Id! === c.Id) {
          await moveFile(element.Id, 0, currentFolder?.Id ?? null);
        }
      }
    }
    sessionStorage.removeItem('copy_elements');
    sessionStorage.removeItem('copy_case');
    setCopyCase(null);
    setCopyElements([]);
    refetch();
  };

  const [cutting, setCutting] = useState(false);

  const handleCopy = (remove: boolean) => {
    sessionStorage.setItem('copy_elements', JSON.stringify(selectedFiles));
    sessionStorage.setItem('copy_case', JSON.stringify(Case));
    sessionStorage.setItem('copy_cut', remove.toString());

    setCutting(remove);

    notification({
      type: ENotificationType.INFO,
      text: 'Zaznaczone elementy skopiowano do schowka. Możesz je wkleić do folderu lub dysku tej lub innej teczki',
      title: 'Skopiowano',
    });
    setCopyElements(selectedFiles);
    setCopyCase(Case!);
    setSelectedFiles([]);
  };

  const handlePaste = () => {
    const elements = sessionStorage.getItem('copy_elements');
    const Case = sessionStorage.getItem('copy_case');

    if (!!elements?.length) {
      const converted: IDossierItem[] = JSON.parse(elements);
      const c: ICase = JSON.parse(Case!);
      handleAddNewFilesToDossier(converted!, c);
    }
  };

  const handleAttachToDesk = () => {};

  const handleDelete = () => {
    const ask = localStorage.getItem('ask');

    if (ask === 'true') {
      deleteInternal();
      return;
    }

    setShowDelete(true);
  };

  const deleteInternal = () => {
    for (const f of selectedFiles) {
      deleteDossier(
        {
          Id: f.DossierId,
        },
        {
          onSuccess: () => {
            if (f.Kind === ItemKind.DossierFolder) {
              setFolders((old) =>
                old.filter((x) => x.DossierId != f.DossierId)
              );
            } else {
              setFiles((old) => old.filter((x) => x.DossierId != f.DossierId));
            }
          },
        }
      );
    }
  };

  const handleCreateEditorDocument = (format: string) => {
    setOptions({ format: format });
    setModals({ ...defaultValue, createLetter: true });
  };

  const [copyElements, setCopyElements] = useState<IDossierItem[]>(
    JSON.parse(sessionStorage.getItem('copy_elements') ?? '[]')
  );
  const [copyCase, setCopyCase] = useState<ICase | null>(
    JSON.parse(sessionStorage.getItem('copy_case') ?? '[]')
  );

  const showCopyMenu = !!selectedFiles.length;
  const showCopyOptions = showCopyMenu && copyCase?.Id === Case?.Id;
  const showPasteOptions = !!copyElements.length && copyCase?.Id !== Case?.Id;

  // if (!files.length && !folders.length) {
  //   return (
  //     <div className={styles.empty}>
  //       <FileUploader
  //         multiple={true}
  //         handleChange={async (filesUploaded: any) => {
  //           const dossierObjects: IDossierItem[] = [];
  //           for (const f of filesUploaded) {
  //             const randomId = Math.random().toString();
  //             const fileName = f.name;
  //             const fileSize = f.size;
  //             const fileType = f.type;

  //             const dossierObject = createDossierObject(
  //               randomId,
  //               fileName + ' - Kopia',
  //               fileSize,
  //               fileType
  //             );

  //             dossierObjects.push(dossierObject);
  //           }

  //           setFiles([...files, dossierObjects].flat());

  //           let index = 0;
  //           const uploadedFiles = [];
  //           for (const f of filesUploaded) {
  //             const r = await handleCreate(
  //               [f],
  //               Case!.Id ?? '',
  //               Case!.Keys[0]!.PublicKey ?? '',
  //               true,
  //               true,
  //               undefined,
  //               undefined,
  //               (progress: number) =>
  //                 handleUpdateProgressFile(
  //                   [...files, dossierObjects].flat(),
  //                   dossierObjects[index],
  //                   progress
  //                 )
  //             );

  //             uploadedFiles.push(r);
  //             index++;
  //           }

  //           addFileToDossier(
  //             {
  //               CaseId: Case!.Id!,
  //               Files: uploadedFiles.map((ff: any) => {
  //                 return {
  //                   KeyId: ff[0].keyId,
  //                   Uri: ff[0].uri,
  //                 };
  //               }),
  //             },
  //             {
  //               onSuccess: () => {
  //                 refetch();
  //               },
  //             }
  //           );
  //         }}
  //         classes={styles.drop}
  //         name="file"
  //         children={
  //           <div
  //             onClickCapture={(e: any) => {
  //               // e.stopPropagation();
  //             }}
  //           >
  //             <NoFilesIcon className={styles.iconee} />
  //             <div className={styles.emptyTitle}>
  //               Brak <span>dokumentów</span>
  //             </div>
  //             <Button
  //               className={styles.buttonAddFile}
  //               text="Wybierz plik z dysku"
  //               // onClick={handleClickAdd}
  //               onClick={() => {}}
  //             />
  //           </div>
  //         }
  //       />
  //     </div>
  //   );
  // }

  return (
    <DossierFilterContext.Provider
      value={{
        menu,
        setMenu,
      }}
    >
      <div
        onDragEnter={() => setShowUpload(true)}
        className={`${styles.wrapper} ${className} ${
          !files.length && !folders.length ? styles.empty : ''
        }`}
      >
        {/* <FileUploader
          multiple={true}
          handleChange={async (f: any) => {
            await handleStartUpload(
              Case!,
              Array.from(f).map((f) => f) as File[],
              Case?.Keys[0],
              createObject,
              initUploadFile,
              getS3,
              confirmUpload,
              addFileToDossier
            );
            refetch();
          }}
          classes={styles.drop}
          name="file"
          children={<div className={styles.upload}></div>}
        /> */}
        <div className={styles.header}>
          <div className={styles.row}>
            <div className={styles.path}>
              <div className={styles.root} onClick={() => navigateToRoot()}>
                Mój dysk
              </div>
              {currentFolder && (
                <>
                  <FontAwesomeIcon
                    className={styles.arrow}
                    icon={faAngleRight}
                  />
                  {folderData &&
                    folderData.Path &&
                    folderData.Path.map((p: IDossierFolderPathFolder) => (
                      <>
                        <div
                          className={styles.pathItem}
                          onClick={() =>
                            onFolderDoubleClick({
                              Id: p.Id,
                              DossierId: p.Id,
                              CreationDate: new Date(),
                              LastModified: new Date(),
                              Kind: ItemKind.DossierFolder,
                              Label: p.Name,
                              Type: DossierItemType.Folder,
                              CommentsCount: 0,
                              DeskInfo_IsOn: false,
                              IsShared: false,
                              TagCount: 0,
                            })
                          }
                        >
                          {p.Name}
                        </div>
                        <FontAwesomeIcon
                          className={styles.arrow}
                          icon={faAngleRight}
                        />
                      </>
                    ))}

                  <div className={styles.current}>{folder?.Name}</div>
                </>
              )}
              {isDossierLoading && (
                <Spinner className={styles.spinner}></Spinner>
              )}
            </div>
            <div>
              <AddButton
                text="Nowy"
                onClick={() => setShowCreateMenu?.(!showCreateMenu)}
              />
              {showCreateMenu && (
                <div className={styles.createMenu}>
                  <div
                    onClick={() => {
                      setShowCreateFolder?.(true);
                      setShowCreateMenu(false);
                    }}
                    className={styles.menuItem}
                  >
                    <FolderIcon title="Folder" /> Folder
                  </div>
                  <div
                    onClick={() => {
                      setModals({ ...defaultValue, createDossier: true });
                      setShowCreateMenu(false);
                    }}
                    className={styles.menuItem}
                  >
                    <DriveIcon title="Plik lub link" /> Plik lub link
                  </div>
                  <div className={styles.line}></div>
                  <div
                    onClick={() => handleCreateEditorDocument('docx')}
                    className={styles.menuItem}
                  >
                    <DocIcon title="Word" /> Word
                  </div>
                  <div
                    onClick={() => handleCreateEditorDocument('pptx')}
                    className={styles.menuItem}
                  >
                    <PptIcon title="PowerPoint" /> PowerPoint
                  </div>
                  <div
                    onClick={() => handleCreateEditorDocument('xlsx')}
                    className={styles.menuItem}
                  >
                    <XlsIcon title="Excel" /> Excel
                  </div>
                  <div
                    onClick={() => handleCreateEditorDocument('other')}
                    className={styles.menuItem}
                  >
                    <OtherIcon title="Inny" /> Inny
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <DossierFilters />
            {(selectedFiles.length > 1 || showPasteOptions) &&
              !isDossierLoading &&
              !isOutcoming && (
                <div className={styles.options}>
                  <div className={styles.counter}>
                    Zaznaczono {selectedFiles.length}
                  </div>
                  <div className={styles.buttons}>
                    {showCopyOptions && (
                      <>
                        <CutIcon
                          title="Wytnij"
                          onClick={() => handleCopy(true)}
                        />
                        <CopyIcon
                          title="Kopiuj"
                          onClick={() => handleCopy(false)}
                        />
                      </>
                    )}
                    {showPasteOptions && (
                      <CopyIcon title="Wklej" onClick={handlePaste} />
                    )}
                    {selectedFiles.filter(
                      (x) => x.Kind != ItemKind.DossierFolder
                    ).length > 0 && (
                      <DeskIcon
                        title="Przypnij do biurka"
                        onClick={handleAttachToDesk}
                      />
                    )}
                    {selectedFiles.length > 1 && (
                      <DeleteIcon title="Usuń" onClick={handleDelete} />
                    )}
                  </div>
                  <CloseIcon onClick={() => setSelectedFiles([])} />
                </div>
              )}
          </div>
        </div>
        <div className={styles.content}>
          <DragDropContext
            onDragUpdate={handleDragUpdate}
            onDragEnd={handleOnDrag}
          >
            <DossierFolders
              setFiles={setFiles}
              cutting={cutting}
              refetch={refetch}
              destination={destination}
              files={files}
              folders={folders}
              setSelectedFiles={setSelectedFiles}
              statuses={statuses}
              tags={tags}
              selectedFiles={selectedFiles}
              canSelect={isOutcoming ? isOutcoming : canSelect}
              onSelect={onSelect}
              onDoubleClick={onFolderDoubleClick}
              handleCopy={handleCopy}
              encryptionKeys={encryptionKeys}
              parentKeys={parentKeys}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
            <DossierFiles
              setFiles={setFiles}
              handleCopy={handleCopy}
              refetch={refetch}
              cutting={cutting}
              setSelectedFiles={setSelectedFiles}
              folders={folders}
              selectedFiles={selectedFiles}
              canSelect={isOutcoming ? isOutcoming : canSelect}
              onSelect={onSelect}
              files={files}
              statuses={statuses}
              tags={tags}
              encryptionKeys={encryptionKeys}
              parentKeys={parentKeys}
              handleFileDoubleClick={handleFileDoubleClick}
            />
          </DragDropContext>
          <FileUploader
            multiple={true}
            handleChange={async (filesUploaded: any) => {
              const dossierObjects: IDossierItem[] = [];
              for (const f of filesUploaded) {
                const randomId = Math.random().toString();
                const fileName = f.name;
                const fileSize = f.size;
                const fileType = f.type;

                const dossierObject = createDossierObject(
                  randomId,
                  fileName,
                  fileSize,
                  fileType
                );

                dossierObjects.push(dossierObject);
              }

              setFiles([...files, dossierObjects].flat());

              let index = 0;
              const uploadedFiles = [];
              for (const f of filesUploaded) {
                const r = await handleCreate(
                  [f],
                  Case!.Id ?? '',
                  Case!.Keys[0]!.PublicKey ?? '',
                  true,
                  true,
                  undefined,
                  undefined,
                  (progress: number) =>
                    handleUpdateProgressFile(
                      [...files, dossierObjects].flat(),
                      dossierObjects[index],
                      progress
                    )
                );

                uploadedFiles.push(r);
                index++;
              }

              addFileToDossier(
                {
                  CaseId: Case!.Id!,
                  Files: uploadedFiles.map((ff: any) => {
                    return {
                      KeyId: ff[0].keyId,
                      Uri: ff[0].uri,
                    };
                  }),
                  ParentId: currentFolder?.Id,
                },
                {
                  onSuccess: () => {
                    refetch();
                  },
                }
              );
            }}
            classes={`${styles.drope} ${showUpload && styles.showUpload}`}
            name="file"
            children={
              !files.length &&
              !folders.length && (
                <div
                  onClickCapture={(e: any) => {
                    // e.stopPropagation();
                  }}
                >
                  <NoFilesIcon className={styles.iconee} />
                  <div className={styles.emptyTitle}>
                    Brak <span>dokumentów</span>
                  </div>
                  <Button
                    className={styles.buttonAddFile}
                    text="Wybierz plik z dysku"
                    // onClick={handleClickAdd}
                    onClick={() => {}}
                  />
                </div>
              )
            }
          />
        </div>

        {showDelete && (
          <ConfirmModal
            title="Usunąć?"
            text="Czy na pewno chcesz usnąć wybrane elementy?"
            acceptLabel="Usuń"
            cancelLabel="Anuluj"
            onAccept={() => {
              setShowDelete(false);
              deleteInternal();
            }}
            onCancel={() => setShowDelete(false)}
          />
        )}
      </div>
    </DossierFilterContext.Provider>
  );
};

export default CaseDossier;
