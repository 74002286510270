import { useRef } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import OptionsCard from 'components/OptionsCard/OptionsCard';
import { IPackage } from 'dto/IPackage';
import { ReactComponent as NotaName } from 'assets/icons/nota-name.svg';

const O: any = {
  Id: 'e56b0824-8649-4b1e-c4f1-08dc442e9b3e',
  InternalId: null,
  Kind: 512,
  Type: null,
  TypeId: 0,
  CreationDate: '2024-03-14T13:56:54Z',
  LastModified: '0001-01-01T00:00:00Z',
  SendDate: '2024-03-14T13:56:54Z',
  ReceiveDate: null,
  ReturnDate: null,
  Sender: 'asdf <sampak.dev@gmail.com>',
  SenderDetails: {
    UserId: null,
    ParticipantId: null,
    Name: 'asdf',
    NIP: null,
    REGON: null,
    KRS: null,
    IsInstitution: null,
    Email: 'sampak.dev@gmail.com',
    EDeliveryAddress: null,
  },
  ReceivedBy: null,
  IsIncoming: true,
  HasTasks: false,
  IsHandled: false,
  IsDeskActive: false,
  IsDraft: false,
  IsSpam: false,
  Unread: false,
  AttachmentsCount: 0,
  TasksCount: 0,
  TagsCount: 0,
  TagIds: [],
  Tags: null,
  Case: null,
  Tasks: null,
  Shares: null,
  Attachments: null,
  Receivers: [
    {
      Id: '00000000-0000-0000-0000-000000000000',
      Name: 'Jan Kowalski',
      ReceiveDate: null,
      SendEvidenceId: null,
      ReceiveEvidenceId: null,
      IsMe: true,
      Cc: false,
      SendDate: null,
      SendStatus: 'waiting',
      ParcelType: null,
      EDeliveryMessageId: null,
      EmailId: null,
      Email: null,
      Address: null,
      UserId: null,
      ParticipantId: null,
    },
  ],
  S1: 'test',
  S2: 'wX4DiCSiyc4CTYUSAgME+1IRoQrlwvpJD9mKzsrd3G2wplGdTtwMCvNWlxz794U0Ts12SXX39wV53KRbcQjU2a1XOnLxUHM2aq2uryfXljCNjDdExdlOx4xSbDRM3Ym5SAaFtsHZ9biGEX2rQF3PnG7b/DAHYj2PAwoPKtimx7jSPAGfEbi4EPNOgseUHgqxFj+gdty2KxhJhdcVvCgQeLU683a/d8FxeWRjGOXC9re90IpOuPvQ9GnF+2o/Bw==',
  S3: null,
  S4: null,
  N1: null,
  N2: null,
  EncryptionKeyId: 'e1adccb5-e91f-4522-27cc-08dc442d6ff5',
  IsRemoved: false,
  RemovedDate: null,
  ShowInDossier: 0,
  UserId: 'a03b4f94-4695-4338-b7c2-b58fcb9f68ad',
  Thread: null,
  Dossier: null,
  CommentsCount: 0,
  StatusId: null,
  IsShared: false,
  SenderUserId: null,
  SenderParticipantId: null,
};

const EmptyPanel = () => {
  const refWrapper = useRef(null);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.header}`}>
        <div className={styles.name}>
          <div className={styles.title}>Przykładowa przesyłka</div>
          <div className={styles.subTitle}>Sygnatura przesyłki</div>
        </div>
        <div ref={refWrapper} className={styles.options}>
          <OptionsCard
            className={styles.opt}
            // rightSide={isFullScreen ? true : false}
            rightSide={true}
            selectedElements={[]}
            showOptions={true}
            pack={O}
            refetch={() => {}}
            refWrapper={refWrapper}
            onClickAttachIcon={() => {}}
          />
        </div>
      </div>
      <div className={styles.package}>
        <div className={styles.background}></div>
        <div className={styles.logo}>
          <NotaName />
          <div className={styles.text}>
            <b>Zaznacz element</b> aby uruchomić podgląd
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className={styles.empty}>
         <LogoIcon className={styles.logo} /> 
       </div> */
}
export default EmptyPanel;
