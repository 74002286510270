import { ItemKind } from 'dto/IKindItems';

export const reverseKindItems = (kindNumber: number) => {
  const flags = {
    CourtPortalDeliverance: false,
    CourtPortalDocument: false,
    Parcel: false,
    EDeliveryMessage: false,
    CourtPortalApplication: false,
    Email: false,
    ContactMessage: false,
    OutgoingParcel: false,
  };

  let toRet = kindNumber;

  if (toRet - ItemKind.OutgoingParcel >= 0) {
    toRet -= ItemKind.OutgoingParcel;
    flags.OutgoingParcel = true;
  }
  
  if (toRet - ItemKind.CourtPortalApplication >= 0) {
    toRet -= ItemKind.CourtPortalApplication;
    flags.CourtPortalApplication = true;
  }

  if (toRet - ItemKind.ContactMessage >= 0) {
    toRet -= ItemKind.ContactMessage;
    flags.ContactMessage = true;
  }

  if (toRet - ItemKind.Email >= 0) {
    toRet -= ItemKind.Email;
    flags.Email = true;
  }


  



  if (toRet - ItemKind.Parcel >= 0) {
    toRet -= ItemKind.Parcel;
    flags.Parcel = true;
  }

  if (toRet - (ItemKind.CourtPortalDeliverance) >= 0) {
    toRet -= ItemKind.CourtPortalDeliverance;
    flags.CourtPortalDeliverance = true;
  }

  if (toRet - ItemKind.CourtPortalDocument >= 0) {
    toRet -= ItemKind.CourtPortalDocument;
    flags.CourtPortalDocument = true;
  }



  if (toRet - ItemKind.EDeliveryMessage >= 0) {
    toRet -= ItemKind.EDeliveryMessage;
    flags.EDeliveryMessage = true;
  }




  return flags;
};
