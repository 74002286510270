import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useState } from 'react';
import Button from 'components/Button';
import settingsService, {
  IConfigureIntegrationRequest,
} from 'api/settingsService';
import { ENotificationType, notification } from 'utils/notification';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import HollowButton from 'components/HollowButton';

const getPort = (type: number, security: number) => {
  if (type === 0) {
    switch (security) {
      case 0:
        return '143';
      case 1:
        return '993';
    }
  } else {
    switch (security) {
      case 0:
        return '587';
      case 1:
        return '465';
    }
  }
  return '';
};

const ConfigureIMAP: FC<Props> = ({ onSuccess, toggle }) => {
  const { mutate: integration } = settingsService.useConfigureIntegration();

  const [data, setData] = useState({
    email: '',
    password: '',
    incomingServer: {
      type: 0,
      name: '',
      port: '143',
      security: 0,
    },
    outcomingServer: {
      name: '',
      port: '587',
      security: 0,
    },
  });

  const [needMoreData, setNeedMoreData] = useState(false);

  const handleClick = () => {
    let payload: IConfigureIntegrationRequest = {
      Type: 0,
      Provider: 5,
      Email: data.email,
      ImapLogin: data.email,
      ImapPassword: data.password,
    };

    if (needMoreData) {
      payload = {
        ...payload,
        ServerConfiguration: {
          IncomingHost: data.incomingServer.name,
          IncomingPort: Number(data.incomingServer.port),
          IncomingSecurity: data.incomingServer.security,
          OutgoingHost: data.outcomingServer.name,
          OutgoingPort: Number(data.outcomingServer.port),
          OutgoingSecurity: data.outcomingServer.security,
        },
      };
    }

    integration(payload, {
      onSuccess: () => {
        onSuccess(data.email);
        toggle();
      },
      onError: (response: any) => {
        const code = response.response.data.errorCodes[0].code;
        const name = response.response.data.errorCodes[0].message;
        if (code === 'ProvideServerConfiguration') {
          notification({
            type: ENotificationType.ERROR,
            title: 'Nie udało się utworzyć integracji',
            text: 'Nie udało się nam odczytać konfiguracji twojej poczty podaj dane serwera',
          });
          setNeedMoreData(true);
          return;
        }
        notification({
          type: ENotificationType.ERROR,
          title: 'Nie udało się utworzyć integracji',
          text: name,
        });
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Skonfiguruj pocztę</div>
      <div className={styles.body}>
        Podaj swoje dane do swojej poczty E-Mail
      </div>
      <div className={styles.form}>
        <Input
          value={data.email}
          onChange={(t) => setData({ ...data, email: t })}
          label="Email"
        />
        <Input
          value={data.password}
          type="password"
          onChange={(t) => setData({ ...data, password: t })}
          label="Hasło"
        />
        {needMoreData && (
          <>
            <div className={styles.connection}>
              <div className={styles.title}>Server poczty przychodzacej</div>
              <div className={styles.group}>
                {/* <Dropdown
                  disableChange
                  onChange={(selected) => {
                    
                    const ports = selected.value.split(':');
                    if (!data.incomingServer.security) {
                      setData({
                        ...data,
                        incomingServer: {
                          ...data.incomingServer,
                          type: selected.value === '143:993' ? 0 : 1,
                          port: ports[0],
                        },
                      });
                      return;
                    }
                    setData({
                      ...data,
                      incomingServer: {
                        ...data.incomingServer,
                        type: selected.value === '143:993' ? 0 : 1,
                        port: ports[1],
                      },
                    });
                  }}
                  list={[
                    { name: 'IMAP', value: '143:993' },
                    {
                      name: 'SMTP',
                      value: '587:465',
                    },
                  ]}
                /> */}
                <Input
                  label="Adres serwera"
                  onChange={(t) => {
                    setData({
                      ...data,
                      incomingServer: { ...data.incomingServer, name: t },
                    });
                  }}
                  value={data.incomingServer.name}
                />
                <Input
                  label="Port"
                  onChange={(t) => {
                    setData({
                      ...data,
                      incomingServer: { ...data.incomingServer, port: t },
                    });
                  }}
                  value={data.incomingServer.port}
                />
                <div className={styles.checkbox}>
                  <Checkbox
                    value={!!data.incomingServer.security}
                    onChange={() => {
                      const newSecurity =
                        data.incomingServer.security === 1 ? 0 : 1;
                      setData({
                        ...data,
                        incomingServer: {
                          ...data.incomingServer,
                          port: getPort(data.incomingServer.type, newSecurity),
                          security: newSecurity,
                        },
                      });
                    }}
                  />
                  Szyfrowane
                </div>
              </div>
            </div>

            <div className={styles.connection}>
              <div className={styles.title}>Server poczty Wychodzącej</div>
              <div className={styles.group2}>
                <Input
                  label="Adres serwera"
                  onChange={(t) => {
                    setData({
                      ...data,
                      outcomingServer: { ...data.outcomingServer, name: t },
                    });
                  }}
                  value={data.outcomingServer.name}
                />
                <Input
                  label="Port"
                  onChange={(t) => {
                    setData({
                      ...data,
                      outcomingServer: { ...data.outcomingServer, port: t },
                    });
                  }}
                  value={data.outcomingServer.port}
                />
                <div className={styles.checkbox}>
                  <Checkbox
                    value={!!data.outcomingServer.security}
                    onChange={() => {
                      const newSecurity =
                        data.outcomingServer.security === 1 ? 0 : 1;

                      const port = !newSecurity ? '587' : '465';
                      setData({
                        ...data,
                        outcomingServer: {
                          ...data.outcomingServer,

                          port: port,
                          security: newSecurity,
                        },
                      });
                    }}
                  />
                  Szyfrowane
                </div>
              </div>
            </div>
          </>
        )}
        <div className={styles.buttonsWrapper}>
          <HollowButton text="Anuluj" onClick={() => toggle()} />
          <Button text="Zaloguj" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default ConfigureIMAP;
