import { FC, Props } from './typings';
import styles from './styles.module.scss';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import casesService from 'api/casesService';
import { useMemo } from 'react';
import { ReactComponent as CaseIcon } from 'assets/icons/case.svg';
import moment from 'moment';

const CaseSharesModal: FC<Props> = ({ toggle, CaseId }) => {
  const { data: CaseData } = casesService.useGetCase(CaseId ?? '');

  const Case = useMemo(() => CaseData, [CaseData]);

  const openCase = (id: string) => {
    navigate(`/cases/${id}/chat`);
  };

  const getStatus = (status: number) => {
    switch (status) {
      case 0:
        return 'Zaproszono';
      case 1:
        return 'Oczekuje na potwierdzenie';
      case 10:
        return 'Aktywny';
      case 99:
        return 'Usunięto';
    }
  };

  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          Udostępnienia
          <CloseIcon
            onClick={() => toggle(false)}
            className={styles.closeIcon}
          />
        </div>
        <div className={styles.list}>
          {CaseData?.Participants.filter((p) => (p.Privileges ?? 0) > 0).map(
            (r) => {
              return (
                <div key={r.Id} className={styles.case}>
                  <div className={styles.metaData}>
                    <div className={styles.s}>
                      <div className={styles.signature}>{r.Label}</div>
                      <div className={styles.status}>
                        {<>{getStatus(r.Status ?? 0)}</>}
                      </div>
                    </div>
                    <div className={styles.client}>
                      {moment(r.CreationDate).format('DD.MM.YYYY').toString()}
                    </div>
                  </div>

                  {/* <div className={styles.menu}>
                  <CaseIcon onClick={() => openCase(r.CaseId)} />
                </div> */}
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseSharesModal;
