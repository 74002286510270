import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';
import InputWithRightIcon from 'components/InputWithRightIcon';
import TerytService from 'api/TerytService';
import { useDebounce } from 'use-debounce';
import { useOnClickOutside } from 'hooks/useClickOutside';

const SearchPlace: FC<Props> = ({
  onBlur,
  isError = '',
  onChange,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue.toString() ?? '');
  const [search, setSearch] = useState('');

  const [debouncedSearch] = useDebounce(search, 300);
  const [searchList, setSearchList] = useState<any[]>([]);

  const { data: zipData } = TerytService.useGetCity(debouncedSearch);
  const zipCodes: any[] = useMemo(() => zipData?.data ?? [], [zipData]);

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const list = zipCodes.map((zip) => zip);

    setSearchList(list);
  }, [search, zipCodes]);

  const handleClick = (zip: any) => {
    setValue(zip.Name);
    onChange(zip.Name, zip.Id);
    // setSearch('');
    setSearchList([]);
  };

  useEffect(() => {
    setValue(defaultValue.toString());
  }, [defaultValue]);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (focused) {
      setFocused(false);
    }
  });

  return (
    <div ref={outsideRef} className={`${styles.wrapper}`}>
      <InputWithRightIcon
        value={value}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          onBlur?.();
        }}
        isError={isError}
        label="Miejscowość"
        onChange={(text) => {
          setValue(text);
          setSearch(text);
        }}
        placeholder="Miejscowość"
      />
      {!!searchList.length && !!value.length && focused && (
        <div className={styles.list}>
          <div className={styles.listWrapper}>
            {searchList.map((zip: any) => (
              <div
                onClick={() => handleClick(zip)}
                key={zip}
                className={styles.box}
              >
                {zip.Name} ({zip.District}, {zip.Voivodeship})
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchPlace;
