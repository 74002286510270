import Input from 'components/Input';
import styles from './styles.module.scss';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteClient.svg';
import { FC, Props } from './typings';
import Map from 'components/Map';
import AddButton from 'components/AddButton';

const AddressesClientModal: FC<Props> = ({ data, setData }) => {
  const removeAddress = (index: number) => {
    const newAddresses = data.addresses.filter(
      (a, aIndex: number) => index !== aIndex
    );
    setData({ ...data, addresses: newAddresses });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.padding}>
        <div className={styles.list}>
          {data.addresses.map((a, index) => {
            return (
              <div className={styles.address}>
                <div className={styles.addressWrapper}>
                  <div className={styles.inputs}>
                    <div className={styles.line}>
                      <Input
                        label="Ulica"
                        onChange={(t) => {
                          const newAddresses = [...data.addresses];

                          newAddresses[index].street = t;
                          setData({ ...data, addresses: newAddresses });
                        }}
                        value={a.street}
                      />
                      {data.addresses.length > 1 && (
                        <div className={styles.addWrapper}>
                          <div className={styles.add}>
                            <DeleteIcon
                              onClick={() => {
                                removeAddress(index);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className={styles.line2}>
                      <Input
                        isCode
                        label="Kod pocztowy"
                        onChange={(t) => {
                          const newAddresses = [...data.addresses];

                          newAddresses[index].zipCode = t;
                          setData({ ...data, addresses: newAddresses });
                        }}
                        value={a.zipCode}
                      />
                      <Input
                        label="Miasto"
                        onChange={(t) => {
                          const newAddresses = [...data.addresses];

                          newAddresses[index].city = t;
                          setData({ ...data, addresses: newAddresses });
                        }}
                        value={a.city}
                      />
                    </div>
                  </div>
                  {/* <div className={styles.map}>
                    <Map />
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.btn}>
          <div className={styles.btnWrapper}>
            <AddButton
              onClick={() => {
                setData({
                  ...data,
                  addresses: [
                    ...data.addresses,
                    {
                      city: '',
                      street: '',
                      zipCode: '',
                    },
                  ],
                });
              }}
              text="Dodaj kolejny adres"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressesClientModal;
