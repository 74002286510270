import styles from './styles.module.scss';
import { FC, Props } from './typings';

const TagWithBorder: FC<Props> = ({ text, onClick, active = false }) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.badge} ${active && styles.active}`}
    >
      {text}
    </div>
  );
};

export default TagWithBorder;
