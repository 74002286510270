import casesService, { INewTag } from 'api/casesService';
import statusService from 'api/statusService';
import tagsService from 'api/tagsService';
import Placeholder from 'assets/placeholder.png';
import AdditionalClientModal from 'components/AdditionalClientModal';
import Button from 'components/Button';
import ChooseTagNew from 'components/ChooseTagNew';
import Input from 'components/Input';
import Loading from 'components/Loading';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import SearchEntitiesNew from 'components/SearchEntitiesNew';
import SelectFlagNew from 'components/SelectFlagNew';
import TagBadge from 'components/TagBadge';
import CasesContext from 'contexts/CasesContext';
import ModalsManagerContext from 'contexts/ModalsManager';
import { ICaseParticipant } from 'dto/Cases/ICaseParticipant';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { Formik } from 'formik';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { ENotificationType, notification } from 'utils/notification';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { validationSchema } from './validation.schema';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Chip from 'components/Chip';

interface InitialValues {
  title: string;
  signature: string;
}

const CreateCaseModal: FC<Props> = ({
  isAnimation = true,
  label,
  caseItem,
  onClose,
  onUpdate,
  onCreate,
}) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);

  const LS = localStorage.getItem(`${userId ?? ''}.layout`);
  const sizes = JSON.parse(LS ?? '[]');

  const isFullScreen = window.location.href.search('fullscreen') !== -1;
  const initiailValues: InitialValues = {
    title: caseItem?.Title.replaceAll('<em>', '').replaceAll('</em>', '') ?? '',
    signature:
      caseItem?.Signature.replaceAll('<em>', '').replaceAll('</em>', '') ?? '',
  };

  const { cases, setCases } = useContext(CasesContext);
  const [createNewEntity, setCreateNewEntity] = useState(true);
  const [isCompany, setIsCompany] = useState(false);
  const { data: tagsData } = tagsService.useGetTags();
  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const { data: statusesData } = statusService.useGetStatuses();
  const statuses: IStatus[] = useMemo(
    () => statusesData?.data ?? [],
    [statusesData]
  );

  const { modals, setModals } = useContext(ModalsManagerContext);
  const { mutate: createCase, isLoading } = casesService.useCreateCase();

  const [status, setStatus] = useState<IStatus | null>(null);
  const [tags, setTags] = useState<ITag[]>([]);

  const {
    data: caseData,
    isLoading: isDataLoading,
    isSuccess: caseDataSuccess,
  } = caseItem
    ? casesService.useGetCase(caseItem?.Id ?? '')
    : { data: undefined, isSuccess: false, isLoading: false };

  const [Values, SetValues] = useState({
    Title: caseData?.Title ?? '',
    Signature: caseData?.Proceeding?.Signature ?? '',
    Entities: caseData?.Participants ?? ([] as ICaseParticipant[]),
  });

  useEffect(() => {
    SetValues({
      ...Values,
      Title: caseData?.Title ?? '',
      Signature: caseData?.Proceeding.Signature ?? '',
      Entities: caseData?.Participants ?? ([] as ICaseParticipant[]),
    });
    setTags(
      tagsList.filter((t: ITag) => (caseData?.TagIds ?? []).includes(t.Id))
    );
    if (caseData?.StatusId) {
      const status = statuses.find((s) => s.Id === caseData.StatusId);
      if (status) {
        setStatus(status);
      }
    } else {
      setStatus(null);
    }
  }, [caseData]);

  const handleClickSave = (values: InitialValues) => {
    const newTags: INewTag[] = tags
      .filter((tag) => tag.Id === '-1')
      .map((tag) => ({
        Id: null,
        Name: tag.Name,
        Type: tag.Type,
      }));

    const tagIds = tags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id);
    const participants: ICaseParticipant[] = Values.Entities.map((entity) => {
      let id: string | number = entity.Id ?? '';
      if (entity.ParticipantId) {
        id = entity.ParticipantId;
      }

      if (entity.UserId) {
        id = entity.UserId;
      }

      return {
        //@ts-ignore
        UserId: entity.Type === 1 || entity.Type === 4 ? entity.Id : undefined,
        //@ts-ignore
        ParticipantId: entity.Type === 3 ? id : undefined,
        Email: entity.Email,
        EDeliveryAddress: entity.EDeliveryAddress,
        Address: entity.Address,
        Label: entity.Label,
        RoleId: entity.RoleId ?? null,
        ParcelTypes: entity.ParcelTypes,
        DefaultParcelType: null,
        IsMain: entity.IsMain ?? false,
      };
    });

    onUpdate?.(values.title, values.signature, status);

    createCase(
      {
        Id: caseItem?.Id ?? null,
        NewTags: newTags,
        Participants: participants,
        Signature: values.signature,
        TagIds: tagIds,
        Title: values.title,
        StatusId: status?.Id,
      },
      {
        onError: () => {
          notification({
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
            type: ENotificationType.ERROR,
          });
        },
        onSuccess: (r) => {
          onCreate?.(r.data);

          const newCases = [...cases];

          if (caseItem) {
            let toUpdate = newCases.find((c) => c.Id === caseItem.Id);

            if (toUpdate) {
              const clients = participants
                .filter(
                  (f) => f.RoleId === 'b25f4943-f576-11ed-aa5c-7acc2dc006f5'
                )
                .map((p) => p.Label ?? '')
                .join(',');

              toUpdate.Title = values.title;
              toUpdate.Signature = values.signature;
              toUpdate.TagIds = r.data.TagIds;
              toUpdate.StatusId = status?.Id;
              toUpdate.Client = clients;
              //   participants.find(
              //     (f) => f.RoleId === 'b25f4943-f576-11ed-aa5c-7acc2dc006f5'
              //   )?.Label ?? '';
              // toUpdate.
              setCases(newCases);
            }
          } else {
            if (isFullScreen) {
              window.open(
                `${
                  process.env.REACT_APP_SITE ??
                  'https://alfa.pocztaprawnicza.pl'
                }/fullscreen/cases/${r.data.Id}/chat`
              );
            }

            setCases([r.data, ...cases]);
          }

          handleCloseModal();
        },
      }
    );
  };

  const addNewTag = (tag: ITag | null) => {
    const isTag = tags.find((etag) => etag.Name === tag!.Name);
    if (isTag) return;
    setTags([...tags, tag!]);
  };

  const handleRemoveTag = (tag: ITag) =>
    setTags(tags.filter((etag) => tag.Name !== etag.Name));

  const updateRole = (entityId: number, roleId: string | null) => {
    SetValues({
      ...Values,
      Entities: Values.Entities.map((entity) => {
        if (entityId === entity.Id) {
          entity.RoleId = roleId;
        }
        return entity;
      }),
    });
  };

  const updateIsMain = (entityId: string | number) => {
    SetValues({
      ...Values,
      Entities: Values.Entities.map((entity) => {
        if (entityId === entity.Id) {
          entity.IsMain = !entity.IsMain;
        }
        return entity;
      }),
    });
  };

  const removeEntity = (entityId: number) =>
    SetValues({
      ...Values,
      Entities: Values.Entities.filter((entity) => entity.Id !== entityId),
    });

  const handleCloseModal = () => {
    if (isAnimation) {
      setModals({ ...modals, hide: true });
    }
    onClose?.();
    //setCreateCase?.(false);
  };
  const [showPerson, setShowPerson] = useState(false);
  const refWrapper = useRef(null);

  if (isDataLoading) {
    return (
      <div className={styles.modal}>
        <Loading />
      </div>
    );
  }

  return (
    <ModalLayout
      dock={DockPosition.DISABLED}
      off={true}
      customX={1}
      isAnimation={isAnimation}
      customY={0}
      ignore
      refWrapper={refWrapper}
      fullHeight={true}
    >
      <div className={`${styles.wrapwrap} ${showPerson && styles.shadow}`}>
        <Formik
          initialValues={initiailValues}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            validateForm,
          }) => (
            <>
              <div
                className={`${styles.wrapper} ${
                  showPerson && styles.disableBorder
                }`}
              >
                <div className={styles.title}>
                  {label ? label : caseItem ? 'Edycja teczki' : 'Nowa teczka'}

                  <CloseIcon
                    onClick={handleCloseModal}
                    className={styles.closeIcon}
                  />
                </div>
                <div className={styles.content}>
                  <div className={styles.inputs}>
                    <div className={styles.status}>
                      <SelectFlagNew
                        selectedStatus={status}
                        onChoose={(status) => setStatus(status)}
                      />
                    </div>

                    <Input
                      focus
                      onBlur={() => setFieldTouched('title', true)}
                      name="title"
                      tags={false}
                      placeholder="Tytuł teczki, wpisując # wyszukaj tag lub dodaj nowy"
                      onChange={(t) => setFieldValue('title', t)}
                      onChooseTag={addNewTag}
                      label="Tytuł"
                      value={values.title}
                      isError={!!errors.title?.length ? errors.title : ''}
                    />
                    <Input
                      label="Sygnatura"
                      name="signature"
                      onChange={(t) => setFieldValue('signature', t)}
                      value={values.signature}
                    />

                    <div className={styles.searchEnitity}>
                      {/* <SearchEntities
                        removeFocus
                        onChoose={(entity) =>
                          SetValues({
                            ...Values,
                            Entities: [
                              ...Values.Entities,
                              entity as ICaseParticipant,
                            ],
                          })
                        }
                      /> */}
                    </div>
                  </div>

                  <div className={styles.tags}>
                    <div className={styles.cr}>
                      <ChooseTagNew type={1} onChoose={addNewTag} />
                      <SearchEntitiesNew
                        onChoose={(entity) =>
                          SetValues({
                            ...Values,
                            Entities: [
                              ...Values.Entities,
                              entity as ICaseParticipant,
                            ],
                          })
                        }
                        onCreate={(isCompany) => {
                          setShowPerson(true);
                          setIsCompany(isCompany);
                        }}
                      />
                    </div>

                    <div className={styles.full}>
                      {!!Values.Entities.length && (
                        <div className={styles.list}>
                          {Values.Entities.map((entity) => {
                            return (
                              <Chip
                                element={<>{entity.Label ?? entity.Name}</>}
                                onDelete={() => {}}
                              />
                            );
                          })}
                        </div>
                      )}

                      {!!tags.length && (
                        <div className={styles.list}>
                          {tags.map((tag) => (
                            <TagBadge
                              className={styles.tag}
                              removeClassName={styles.remove}
                              key={tag.Id}
                              tag={tag}
                              onRemove={handleRemoveTag}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.footer}>
                  <Button
                    loading={isLoading}
                    disabled={!values.title.length}
                    handleError={() => validateForm()}
                    className={styles.button}
                    onClick={() => handleClickSave(values)}
                    text={caseItem ? 'Zapisz' : 'Utwórz teczke'}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
        {showPerson && (
          <AdditionalClientModal
            showClient={createNewEntity}
            onCreateClient={(entity) => {
              SetValues({
                ...Values,
                Entities: [...Values.Entities, entity as ICaseParticipant],
              });
            }}
            isCompany={isCompany}
          />
        )}
      </div>
    </ModalLayout>
  );
};

export default CreateCaseModal;
