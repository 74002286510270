import { useMutation, useQuery } from "react-query"
import { axiosInstance } from "./axios"

const getNotes = async  () => {
    const r = await axiosInstance.get("/notes");

    return r.data ?? []
}

const useGetNotes = () => {
    return useQuery('notes', () => getNotes());
}

export interface ICreateNote {
    Title: string;
    Content: string;
    CaseId?: string
}

const createNote = (payload: ICreateNote) => {
    return axiosInstance.post('/note', payload)
}

const useCreateNote = () => {
    return useMutation(createNote);
}


export interface IEditNote {
    Id: string;
    Title: string;
    Content: string;
}

const editNote = (payload: IEditNote) => {
    return axiosInstance.put('/note', payload)
}

const useEditNote = () => {
    return useMutation(editNote);
}

export interface IDeleteNote {
    Id: string;
}

const deleteNote = (payload: IDeleteNote) => {
    return axiosInstance.delete("/note", {
        data: payload
    });
}

const useDeleteNote = () => {
    return useMutation(deleteNote);
}

export default {
    useGetNotes,
    useCreateNote,
    useEditNote,
    useDeleteNote
}