import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as DIcon } from 'assets/icons/company/d.svg';

const NewInputWithRightIcon: FC<Props> = ({
  onFocus,
  onBlur,
  onChange,
  defaultValue,
  value,
  placeholder,
  setShowList,
  onClick = () => {},
  showAlways = false,
  focus = false,
  isFullscreen,
  isIconEnabled = true,
}) => {
  const [text, setText] = useState(defaultValue ?? '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const [hover, setHover] = useState(false);

  const show = (setShowList && hover) || (setShowList && showAlways);

  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || !focus) return;

    //@ts-ignore
    ref.current.focus();
  }, [ref, focus]);

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={onClick}
      className={styles.inputWrapper}
    >
      <input
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => handleChange(e)}
        className={styles.input}
        type="text"
        value={value ?? text}
        placeholder={placeholder}
      />
      {/* <SearchIcon className={styles.icon} /> */}
      {
        <div
          onClick={setShowList}
          className={`${styles.list} ${
            isIconEnabled ? styles.enabled : styles.disabled
          }`}
        >
          <div className={styles.w}>Lista adresowa</div>
        </div>
      }
    </div>
  );
};

export default NewInputWithRightIcon;
