import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

const AddButton: FC<Props> = ({ className = '', onClick, text }) => {
  return (
    <div onClick={onClick} className={`${styles.wrapper} ${className}`}>
      <div className={styles.rounded}>
        <FontAwesomeIcon className={styles.icon} icon={faAdd} />
      </div>
      {text}
    </div>
  );
};

export default AddButton;
