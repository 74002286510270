import styles from './styles.module.scss';
import { ETypes, FC, Props } from './typings';
import { ReactComponent as LeftArrow } from 'assets/icons/left-arrow.svg';
import { ReactComponent as PIIcon } from 'assets/icons/filters/pi.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/filters/email.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as LetterIcon } from 'assets/icons/filters/editor.svg';
import { ReactComponent as AllIcon } from 'assets/icons/filters/all.svg';
import { ReactComponent as FKIcon } from 'assets/icons/filters/credit.svg';
import { ReactComponent as PolandIcon } from 'assets/icons/filters/poland.svg';
import { ReactComponent as ImapIcon } from 'assets/icons/filters/imap.svg';
import { ReactComponent as OutlookIcon } from 'assets/icons/filters/outlook.svg';
import { ReactComponent as GmailIcon } from 'assets/icons/filters/Gmail.svg';
import { ReactComponent as MicrosoftIcon } from 'assets/icons/filters/Microsoft.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/filters/Google.svg';
import { ReactComponent as WordIcon } from 'assets/icons/filters/word.svg';
import { ReactComponent as DocsIcon } from 'assets/icons/filters/docs.svg';
import { useState } from 'react';
import { IntegrationProvider } from 'dto/Settings/IntegrationProvider';
import { IntegrationType } from 'dto/Settings/IntegrationType';
import ConfigureIMAP from 'components/ConfigureIMAP';
import CourtPortalSettings from '../CourtPortalSettings';

const LIST = [
  { type: ETypes.ALL, name: 'Wszystkie aplikacje', icon: <AllIcon /> },
  { type: ETypes.PI, name: 'PI Sądów', icon: <PIIcon /> },
  { type: ETypes.EMAIL, name: 'Email', icon: <EmailIcon /> },
  { type: ETypes.CALENDAR, name: 'Kalendarze', icon: <CalendarIcon /> },
  { type: ETypes.EDITOR, name: 'Edytor', icon: <LetterIcon /> },
  { type: ETypes.FK, name: 'System FK', icon: <FKIcon /> },
];

interface IApplication {
  type: ETypes;
  image: any;
  name: string;
  description: string;
  provider?: IntegrationProvider;
}

const APPLICATIONS: IApplication[] = [
  { type: ETypes.PI, image: <PolandIcon />, name: 'PI Sądów', description: '' },
  {
    type: ETypes.EMAIL,
    image: <OutlookIcon />,
    name: 'Outlook',
    description: '',
    provider: IntegrationProvider.Microsoft,
  },
  {
    type: ETypes.EMAIL,
    image: <GmailIcon />,
    name: 'Gmail',
    description: '',
    provider: IntegrationProvider.Google,
  },
  {
    type: ETypes.EMAIL,
    image: <ImapIcon />,
    name: 'IMAP',
    description: '',
    provider: IntegrationProvider.Imap,
  },
  {
    type: ETypes.CALENDAR,
    image: <MicrosoftIcon />,
    name: 'Outlook kalendarz',
    description: '',
    provider: IntegrationProvider.Microsoft,
  },
  {
    type: ETypes.CALENDAR,
    image: <GoogleIcon />,
    name: 'Google kalendarz',
    description: '',
    provider: IntegrationProvider.Google,
  },
  {
    type: ETypes.EDITOR,
    image: <WordIcon />,
    name: 'OFFICE 365',
    description: '',
  },
  {
    type: ETypes.EDITOR,
    image: <DocsIcon />,
    name: 'Google docs',
    description: '',
    provider: IntegrationProvider.Google,
  },
  { type: ETypes.FK, image: <FKIcon />, name: 'wfirma', description: '' },
];

const IntegrationMenu: FC<Props> = ({
  handleClick,
  emailState,
  setEmailState,
  refetchI,
  courtPortalState,
  handleDisconnect,
  integrationId,
  onInnerViewCanceled,
  onInnerViewSaved,
  toggle,
  refetchportals,
}) => {
  const [actual, setActual] = useState(0);
  const actualTab = LIST.at(actual);

  const [portals, setPortals] = useState(false);
  const [confImap, setConfImap] = useState(false);

  let list = APPLICATIONS;

  if (actual !== 0) {
    list = APPLICATIONS.filter((a) => a.type === actualTab?.type);
  }

  const onClick = (application: IApplication) => {
    if (application.type === ETypes.PI) {
      setPortals(true);
      return;
    }
    if (application.type === ETypes.EMAIL) {
      if (application.provider === IntegrationProvider.Google) {
        handleClick(IntegrationType.Email, IntegrationProvider.Google, false);
        return;
      }
      if (application.provider === IntegrationProvider.Microsoft) {
        handleClick(
          IntegrationType.Email,
          IntegrationProvider.Microsoft,
          false
        );
        return;
      }
      if (application.provider === IntegrationProvider.Imap) {
        setConfImap(true);
        return;
      }
    }

    if (application.type === ETypes.CALENDAR) {
      if (application.provider === IntegrationProvider.Google) {
        handleClick(
          IntegrationType.Calendar,
          IntegrationProvider.Google,
          false
        );
        return;
      }
      if (application.provider === IntegrationProvider.Microsoft) {
        handleClick(
          IntegrationType.Calendar,
          IntegrationProvider.Microsoft,
          false
        );
        return;
      }
    }

    if (application.type === ETypes.EDITOR) {
      if (application.provider === IntegrationProvider.Google) {
        handleClick(IntegrationType.Editor, IntegrationProvider.Google, false);
        return;
      }
    }
  };

  const Build = () => {
    if (confImap) {
      return (
        <ConfigureIMAP
          onSuccess={(email) => {
            setEmailState({ ...emailState, imapActive: true });
            refetchI();
          }}
          toggle={() => setConfImap(false)}
        />
      );
    }

    if (portals) {
      return (
        <CourtPortalSettings
          integrationId={courtPortalState.id}
          //  onCanceled={onInnerViewCanceled}
          onCanceled={() => {
            onInnerViewCanceled();
            setPortals(false);
          }}
          onSaved={() => {
            onInnerViewSaved();
            setPortals(false);
          }}
          handleDisconnect={handleDisconnect}
          refetchI={refetchI}
          refetchportals={refetchportals}
        ></CourtPortalSettings>
      );
    }

    return list.map((a) => (
      <div onClick={() => onClick(a)} className={styles.application}>
        <div className={styles.appIcon}>{a.image}</div>
        <div className={styles.appTitle}>{a.name}</div>
        <div className={styles.appDescription}>{a.description}</div>
      </div>
    ));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div onClick={toggle} className={styles.back}>
          <LeftArrow /> Ustawienia
        </div>
        <div className={styles.list}>
          {LIST.map((a, index) => (
            <div
              onClick={() => setActual(index)}
              className={`${styles.box} ${index === actual && styles.active}`}
            >
              {a.icon}
              {a.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          {actualTab?.icon} {actualTab?.name}
        </div>
        <div className={styles.listApplication}>{Build()}</div>
      </div>
    </div>
  );
};

export default IntegrationMenu;
