import styles from './styles.module.scss';
import Input from 'components/Input';
import Button from 'components/Button';
import { flushSync } from 'react-dom';
import dossierService, { IDossierAddFile } from 'api/dossierService';
import {
  FC,
  useEffect,
  useState,
  forwardRef,
  useContext,
  useMemo,
} from 'react';
import { Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FolderIcon } from 'assets/icons/folder_24px.svg';
import { animated, useSpring } from '@react-spring/web';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { IDossierFolder } from 'dto/Dossier/IDossierFolder';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import LinearProgressWithLabel from '@mui/material/LinearProgress';
import clsx from 'clsx';
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from '@mui/x-tree-view/useTreeItem2';
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { styled, alpha } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import SearchCases from 'components/SearchCases';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import SearchCasesNew from 'components/SearchCasesNew';
import { DossierItemType } from 'dto/Dossier/DossierItemType';
import { getFile } from 'utils/getFile';
import { getBlob } from 'utils/getBlob';
import KeysContext from 'contexts/KeysContext';
import { decryptFile } from 'utils/decryptFile';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { handleCreate } from 'utils/uploadFile';
import casesService from 'api/casesService';
import { IKey } from 'dto/IKey';
import { ItemKind } from 'dto/IKindItems';
import { IDossierFakeItem } from 'dto/Dossier/IDossierFakeItem';

const DossierMoveModal: FC<Props> = ({
  item,
  sourceCaseId,
  toggle,
  refetch,
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const { keys, setKeys } = useContext(KeysContext);
  const localKeys: IKey[] = [];
  const { mutate: moveDossier } = dossierService.useMoveDossier();
  const { mutate: addFileToDossier } = dossierService.useAddFile();
  const { mutateAsync: createFolder } = dossierService.useCreateFolder();
  const { mutateAsync: getDossier } = dossierService.useMutationGetItems();

  const [isLoading, setIsLoading] = useState(false);
  const [c, setCase] = useState<null | ICaseSimple>(null);
  const { data: keysData } = casesService.useGetKeys({
    id: c?.Id ?? sourceCaseId,
  });

  const caseKeys: IKey = useMemo(() => keysData?.data?.[0] ?? [], [keysData]);
  const [folders, setFolders] = useState<IDossierFolder[]>([]);

  const toMove: IDossierItem[] = [];

  const [totalToMove, setTotalToMove] = useState<number>(0);
  const [currentMove, setCurrentMove] = useState<number>(0);

  const { data: foldersData } = dossierService.useGetFolders(
    c?.Id ?? sourceCaseId,
    true
  );
  const [selectedItems, setSelectedItems] = useState<string | null>(null);

  const newFolderLookup: { [Id: string]: string } = {};

  const handleSelectedItemsChange = (
    event: React.SyntheticEvent,
    id: string | null
  ) => {
    setSelectedItems(id);
  };

  const rootItem: IDossierFolder = {
    Id: c?.Id ?? sourceCaseId,
    Name: 'Mój dysk',
    CaseId: c?.Id ?? sourceCaseId,
    Order: 0,
  };

  useEffect(() => {
    if (foldersData) {
      setFolders([
        {
          ...rootItem,
          children: arrayToTree(
            (foldersData as IDossierFolder[]).filter(
              (x) => x.Id != item.DossierId
            )
          ),
        },
      ]);
    }
  }, [foldersData]);

  const moveDossierElement = (
    id: string,
    order: number,
    targetParentId: string | null
  ) => {
    return new Promise((resolve) => {
      moveDossier(
        {
          Id: id,
          Order: order,
          ParentId: targetParentId,
        },
        {
          onSuccess: () => {
            resolve(true);
          },
        }
      );
    });
  };

  const handleUpdateProgressFile = (file: IDossierItem, progress: number) => {
    // const f = newDossier.find((f) => f.Id === file.Id);
    // if (f) {
    //   f.progress = progress;
    //   setDossierFiles(newDossier);
    // }
  };

  const moveFileFromAnotherCase = (
    item: IDossierItem,
    targetParentId: string | null
  ) => {
    return new Promise(async (resolve, reject) => {
      if (!item.FileUri) reject('FileUri missing');

      const file = await getFile(item.FileUri ?? '');
      const blob = await getBlob(file.data.Url);

      const key =
        keys.find((key) => key?.Id === item.EncryptionKeyId) ??
        localKeys.find((key) => key?.Id === item.EncryptionKeyId);

      if (!key) {
        reject(`Can't find key with ID: ${item.EncryptionKeyId}`);
        return;
      }

      let parentKey = null;
      //@ts-ignore
      if (key.ParentKeyId) {
        parentKey = keys.find(
          //@ts-ignore
          (pkey: any) => pkey?.Id === key?.ParentKeyId
        );
      }

      const decryptedFile = await decryptFile(
        userId ?? '',
        new Blob([blob.data]),
        key,
        parentKey
      );

      const r = await handleCreate(
        [decryptedFile],
        c?.Id ?? sourceCaseId,
        caseKeys.PublicKey ?? '',
        true,
        true,
        item.FileName,
        item.FileUri,
        (progress) => {
          handleUpdateProgressFile(item, progress);
        }
      );

      if (!r) {
        reject(`Handle create failed`);
        return;
      }

      let payload: IDossierAddFile = {
        CaseId: c!.Id,
        Files: [
          {
            KeyId: r[0].keyId,
            Uri: r[0].uri,
          },
        ],
      };

      if (targetParentId) {
        payload = { ...payload, ParentId: targetParentId };
      }

      addFileToDossier(payload, {
        onSuccess: () => {
          resolve(true);
        },
      });
    });
  };

  const travelFolderToMove = (
    folder: IDossierItem,
    targetFolderId?: string | null
  ) => {
    return new Promise(async (resolve) => {
      var data = await getDossier({
        CaseId: sourceCaseId,
        FolderId: folder.DossierId,
        Page: -1,
        SortOrder: 'Ascending',
        SortProp: 'Name',
      });

      if (data?.data) {
        // @ts-ignore
        setKeys((prev) => [
          ...prev,
          ...data.data.ObjectKeys,
          ...data.data.Keys,
        ]);

        localKeys.push(...data.data.ObjectKeys);
        localKeys.push(...data.data.Keys);

        const folders = data.data.Items?.filter(
          (dossier: IDossierItem) => dossier.Kind === ItemKind.DossierFolder
        );
        const directFiles = data.data.Items?.filter(
          (dossier: IDossierItem) => dossier.Kind !== ItemKind.DossierFolder
        );

        for (const f of folders) {
          var result = await createFolder({
            CaseId: c!.Id,
            Name: f.Label ?? '',
            ParentId: newFolderLookup[f.ParentId ?? '']
              ? newFolderLookup[f.ParentId!]
              : targetFolderId ?? undefined,
          });

          newFolderLookup[f.DossierId] = result.data.Id;
          await travelFolderToMove(f);
        }

        toMove.push(...directFiles);
        resolve(true);
      }
    });
  };

  const handleClickSave = async () => {
    var targetId = selectedItems;
    var moveToRoot = selectedItems === sourceCaseId || selectedItems == c?.Id;

    if (sourceCaseId === c?.Id || !c) {
      setIsLoading(true);

      moveDossierElement(item.DossierId, 0, moveToRoot ? null : targetId).then(
        () => {
          refetch();
          setIsLoading(false);
          toggle(false);
        }
      );
    } else {
      setIsLoading(true);
      console.log('Moving to other case');

      if (item.Type === DossierItemType.Folder) {
        // tworzymy folder główny
        var newFolder = await createFolder({
          CaseId: c!.Id,
          Name: item.Label ?? '',
          ParentId: !moveToRoot ? targetId! : undefined,
        });

        newFolderLookup[item.DossierId!] = newFolder.data.Id;

        await travelFolderToMove(item, !moveToRoot ? targetId : null);
        setTotalToMove((p) => toMove.length);
        setCurrentMove(0);

        for (const i of toMove) {
          if (i.Type === DossierItemType.Attachment) {
            await moveFileFromAnotherCase(
              i,
              i.ParentId ? newFolderLookup[i.ParentId] : null
            ).catch((r) => {
              console.log('Moving failed', r, i);
            });

            if (currentMove < toMove.length) {
              setCurrentMove((prev) => prev + 1);
            }
          }
        }

        refetch();
        setIsLoading(false);
        toggle(false);
      } else if (item.Type === DossierItemType.Link) {
      } else {
        moveFileFromAnotherCase(item, !moveToRoot ? targetId : null).then(
          () => {
            refetch();
            setIsLoading(false);
            toggle(false);
          }
        );
      }
    }
  };

  const arrayToTree = (items: IDossierFolder[]) => {
    const rootItems: IDossierFolder[] = [];
    const lookup: { [Id: string]: IDossierFolder } = {};

    // Initialize lookup table and create a tree node for each item
    for (const item of items) {
      lookup[item.Id] = { ...item, children: [] };
    }

    // Build the tree
    for (const item of items) {
      if (!item.ParentId) {
        rootItems.push(lookup[item.Id]);
      } else {
        const parent = lookup[item.ParentId!];
        if (parent) {
          parent.children!.push(lookup[item.Id]);
        }
      }
    }

    return rootItems;
  };

  const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[800]
        : theme.palette.grey[400],
    position: 'relative',
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: theme.spacing(3.5),
    },
  })) as unknown as typeof TreeItem2Root;

  const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    flexDirection: 'row-reverse',
    borderRadius: theme.spacing(0.7),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    background: 'transparent',
    fontWeight: 500,
    [`&.Mui-expanded `]: {
      '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon':
        {
          color:
            theme.palette.mode === 'light'
              ? theme.palette.primary.main
              : theme.palette.primary.dark,
        },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '16px',
        top: '44px',
        height: 'calc(100% - 48px)',
        width: '1.5px',
        backgroundColor:
          theme.palette.mode === 'light'
            ? theme.palette.grey[300]
            : theme.palette.grey[700],
      },
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color:
        theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
      backgroundColor:
        theme.palette.mode === 'light' ? '#e6defe' : theme.palette.primary.dark,
      // color: theme.palette.primary.contrastText,
    },
  }));

  const AnimatedCollapse = animated(Collapse);

  function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
      to: {
        opacity: props.in ? 1 : 0,
        transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
      },
    });

    return <AnimatedCollapse style={style} {...props} />;
  }

  const StyledTreeItemLabelText = styled(Typography)({
    color: 'inherit',
    fontWeight: 500,
  }) as unknown as typeof Typography;

  interface CustomLabelProps {
    children: React.ReactNode;
    icon?: React.ElementType;
    expandable?: boolean;
  }

  function CustomLabel({
    icon: Icon,
    expandable,
    children,
    ...other
  }: CustomLabelProps) {
    return (
      <TreeItem2Label
        {...other}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {Icon && (
          <Box
            component={Icon}
            className="labelIcon"
            color="inherit"
            sx={{ mr: 1, fontSize: '1.2rem' }}
          />
        )}

        <StyledTreeItemLabelText variant="body2">
          {children}
        </StyledTreeItemLabelText>
        {/* {expandable && <DotIcon />} */}
      </TreeItem2Label>
    );
  }

  const isExpandable = (reactChildren: React.ReactNode) => {
    if (Array.isArray(reactChildren)) {
      return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
  };

  const getIconFromFileType = (item: IDossierItem) => {
    return FolderIcon;
    // switch (fileType) {
    //   case 'folder':
    //     return FolderIcon;
    //   default:

    // }
  };

  interface CustomTreeItemProps
    extends Omit<UseTreeItem2Parameters, 'rootRef'>,
      Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {}

  const CustomTreeItem = forwardRef(function CustomTreeItem(
    props: CustomTreeItemProps,
    ref: React.Ref<HTMLLIElement>
  ) {
    const { id, itemId, label, disabled, children, ...other } = props;

    const {
      getRootProps,
      getContentProps,
      getIconContainerProps,
      getCheckboxProps,
      getLabelProps,
      getGroupTransitionProps,
      status,
      publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    // @ts-ignore
    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);
    let icon = FolderIcon;

    if (item.fileType) {
      icon = getIconFromFileType(item);
    }

    return (
      // @ts-ignore
      <TreeItem2Provider itemId={itemId}>
        <StyledTreeItemRoot {...getRootProps(other)}>
          <CustomTreeItemContent
            {...getContentProps({
              className: clsx('content', {
                'Mui-expanded': status.expanded,
                'Mui-selected': status.selected,
                'Mui-focused': status.focused,
                'Mui-disabled': status.disabled,
              }),
            })}
          >
            <TreeItem2IconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>
            <TreeItem2Checkbox {...getCheckboxProps()} />
            <CustomLabel
              {...getLabelProps({
                icon,
                expandable: expandable && status.expanded,
              })}
            />
          </CustomTreeItemContent>
          {children && <TransitionComponent {...getGroupTransitionProps()} />}
        </StyledTreeItemRoot>
      </TreeItem2Provider>
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Przenieś
        <CloseIcon onClick={() => toggle(false)} className={styles.closeIcon} />
      </div>
      <div className={styles.content}>
        <SearchCasesNew
          isError={'Wybierz teczkę'}
          setAddNewEntity={() => {}}
          defaultValue={sourceCaseId ? sourceCaseId : undefined}
          onChoose={(simpleCase) => setCase(simpleCase)}
        />
        <div className={styles.grow}>
          {/* <div className={styles.info}>Wybierz folder docelowy</div> */}
          <RichTreeView
            items={folders}
            getItemId={(x: IDossierFolder) => x.Id}
            getItemLabel={(x: IDossierFolder) => x.Name ?? ''}
            slots={{ item: CustomTreeItem }}
            defaultExpandedItems={[c?.Id ?? sourceCaseId]}
            expansionTrigger="iconContainer"
            selectedItems={selectedItems}
            onSelectedItemsChange={handleSelectedItemsChange}
          />
        </div>
        {isLoading && (
          <div className={styles.progressBox}>
            <div className={styles.label}>Trwa przenoszenie</div>
            <LinearProgressWithLabel
              value={totalToMove > 0 ? (currentMove / totalToMove) * 100 : 0}
            />
            <div className={styles.progress}>
              Plik {currentMove + 1}/{totalToMove}
            </div>
          </div>
        )}

        {/* <Input
          placeholder="Nazwa folderu"
          label="Nazwa"
          value={name}
          onChange={(text) => setName(text)}
        /> */}
      </div>
      <div className={styles.footer}>
        <Button
          disabled={!selectedItems}
          onClick={handleClickSave}
          text="Przenieś"
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default DossierMoveModal;
