import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import {
  faC,
  faCaretDown,
  faCaretRight,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import statusService from 'api/statusService';
import { IStatus } from 'dto/IStatus';
import StatusBadge from 'components/StatusBadge';
import { useOnClickOutside } from 'usehooks-ts';
import { getWorkspaceId } from 'utils/getWorkspaceId';

const SelectFlagNew: FC<Props> = ({
  onChoose,
  onClose,
  selectedStatus,
  initOpen = false,
  type,
}) => {
  const [active, setActive] = useState(false);
  const currentWorkspaceId = getWorkspaceId() ?? '';
  const { data: statusesData } =
    statusService.useGetStatuses(currentWorkspaceId);
  let statuses: IStatus[] = useMemo(
    () => statusesData?.data ?? [],
    [statusesData]
  );

  if (type) {
    statuses = statuses.filter((f) => f.Type === type);
  }

  useEffect(() => {
    if (!initOpen) return;
    setActive(true);
  }, [initOpen]);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }

    if (onClose && selectedStatus === null) {
      onClose();
    }
  });

  return (
    <div ref={outsideRef} className={styles.wrapper}>
      <div onClick={() => setActive(!active)} className={styles.card}>
        <div className={styles.left}>
          <FlagIcon className={`${styles.icon}`} />

          {selectedStatus ? (
            <div className={`${styles.text} ${styles.selected}`}>
              {selectedStatus.Name}
            </div>
          ) : (
            <div className={styles.text}>Status</div>
          )}
        </div>
        <div className={styles.right}>
          {!onClose && (
            <FontAwesomeIcon icon={active ? faCaretDown : faCaretRight} />
          )}
        </div>
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.list}>
            {statuses?.map((status) => (
              <div
                onClick={() => {
                  setActive(false);
                  onChoose(status);
                }}
                key={status.Id}
                className={styles.box}
              >
                <StatusBadge onRemove={() => {}} status={status} />
                {status.Name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectFlagNew;
