import { FC, Props } from './typings';
import styles from './styles.module.scss';
import SecondCheckbox from 'components/SecondCheckbox';
import Input from 'components/Input';
import Button from 'components/Button';
import { useContext, useEffect, useState } from 'react';
import clientService from 'api/clientService';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import AddButton from 'components/AddButton';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import SearchByNip from 'components/SearchByNip';
import { IGUSCompany } from 'dto/IGUSCompany';
import SearchCode from 'components/SearchCode';
import SearchPlace from 'components/SearchPlace';
import SearchAddress from 'components/SearchAddress';
import TextArea from 'components/TextArea';
import { Formik, useFormikContext } from 'formik';
import {
  validationSchemaClient,
  validationSchemaEntity,
} from './validation.schema';
import { ICase } from 'dto/Cases/ICase';
import ClientsContext from 'contexts/ClientsContext';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import { IClient } from 'dto/IClient';

interface IContact {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  JobPosition: string;
}

const EditClientModal: FC<Props> = ({
  label,
  client,
  onClose,
  onCancel,
  onSave,
  newClient = false,
}) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);

  const LS = localStorage.getItem(`${userId ?? ''}.layout`);
  const sizes = JSON.parse(LS ?? '[]');
  const { mutate: updateClient } = clientService.useUpdateClient();
  const { mutate: createClient } = clientService.useCreateClient();

  const { refetch } = clientService.useGetClient(client?.Id ?? '', false);

  const { clients, setClients } = useContext(ClientsContext);

  const [metData, setMetaData] = useState<any>({
    Name: client?.Name ?? '',
    ZipCode: client?.ZipCode ?? '',
    City: client?.City ?? '',
    Address: client?.Address ?? '',
    Phone: client?.Phone ?? '',
    Email: client?.Email ?? '',
    Note: client?.Note ?? '',
    IsInstitution: client?.IsInstitution ?? false,
    Nip: client?.NIP ?? '',
    REGON: client?.REGON ?? '',
    cityId: null as string | null,
    addressId: null as string | null,
  });

  const [values, setValues] = useState({
    ContactPersons: [] as IContact[],
  });

  const [isDuplicate, setIsDuplicate] = useState<null | IClient>(null);

  const handleClick = (v: any, errors: any) => {
    setIsClickedToValidation(true);

    if (!client) {
      // createClient(
      //   {
      //     Address: v.Address ?? '',
      //     City: v.City ?? '',
      //     CityId: null,
      //     ContactPersons: values.ContactPersons,
      //     Email: v.Email ?? '',
      //     Id: null,
      //     IsInstitution: isInstitution ?? false,
      //     Name: v.Name ?? '',
      //     NewTags: [],
      //     NIP: isInstitution ? v.Nip ?? '' : '',
      //     Note: v.Note,
      //     Phone: v.Phone ?? '',
      //     REGON: v.IsInstitution ? v.REGON ?? '' : null,
      //     StreetId: null,
      //     TagIds: [],
      //     ZipCode: v.ZipCode ?? '',
      //   },
      //   {
      //     onSuccess: (response) => {
      //       setClients([response.data, ...clients].flat());
      //       onSave?.(response.data as ICase);
      //       onClose();
      //     },
      //     onError: (err: any) => {
      //       if (err?.response?.data?.duplicates) {
      //         setIsDuplicate(err?.response?.data?.duplicates?.[0]);
      //       }
      //     },
      //   }
      // );
      return;
    }

    updateClient(
      {
        Id: client?.Id,
        Address: v.Address ?? '',
        City: v.City ?? '',
        CityId: 'none',
        ContactPersons: values.ContactPersons,
        Email: client?.Email ?? '',
        IsInstitution: isInstitution ?? false,
        Name: v.Name,
        NewTags: [],
        NIP: isInstitution ? v.Nip ?? '' : '',
        Note: v.Note ?? '',
        Phone: v.Phone ?? '',
        REGON: v.IsInstitution ? v.REGON ?? '' : '',
        StreetId: 'none',
        TagIds: [],
        ZipCode: v.ZipCode ?? '',
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  // const buttonSaveIsDisabled = () => {
  //   if (values.IsInstitution) {
  //     if (
  //       !values.Nip.length ||
  //       !values.REGON.length ||
  //       !values.Name.length ||
  //       !values.ZipCode.length ||
  //       !values.City.length ||
  //       !values.Address.length
  //     ) {
  //       return true;
  //     }

  //     if (!values.Email.length && !values.Phone.length) {
  //       return true;
  //     }

  //     return false;
  //   } else {
  //     if (
  //       !values.Name.length ||
  //       !values.ZipCode.length ||
  //       !values.City.length ||
  //       !values.Address.length
  //     ) {
  //       return true;
  //     }

  //     if (!values.Email.length && !values.Phone.length) {
  //       return true;
  //     }

  //     return false;
  //   }

  //   return false;
  // };

  const addNewPerson = () => {
    setValues({
      ...values,
      ContactPersons: [
        ...values.ContactPersons,
        {
          FirstName: '',
          LastName: '',
          Phone: '',
          JobPosition: '',
          Email: '',
        },
      ],
    });
  };

  const [isClickedToValidation, setIsClickedToValidation] = useState(false);

  const changeContactData = (index: number, type: string, text: string) => {
    const newArray = { ...values };

    const person = newArray.ContactPersons.at(index);

    if (!person) return;

    if (type === 'name') {
      const name = text.split(' ');

      person.FirstName = name[0];
      person.LastName = name[1] ?? '';
    }
    if (type === 'phone') {
      person.Phone = text;
    }
    if (type === 'email') {
      person.Email = text;
    }

    setValues(newArray);
  };

  const deleteContact = (index: number) => {
    const newArray = { ...values };

    const persons = newArray.ContactPersons.filter((person, i) => {
      return i !== index;
    });

    newArray.ContactPersons = persons;

    setValues(newArray);
  };

  const [company, setCompany] = useState<IGUSCompany | null>(null);

  // const handleGUSData = (company: IGUSCompany) => {
  //   setValues({
  //     ...values,
  //     Name: company.Name,
  //     REGON: company.Regon,
  //     ZipCode: company.ZipCode,
  //     City: company.Place,
  //     Address: company.Street,
  //   });
  //
  // };

  const [isInstitution, setIsInstitution] = useState(false);

  const Observer = () => {
    const { values, setFieldValue, validateForm } = useFormikContext();
    const data: any = values as any;

    useEffect(() => {
      if (!company) return;
      setFieldValue('Name', company.Name.replaceAll('#', ''));
      setFieldValue('REGON', company.Regon);
      setFieldValue('ZipCode', company.ZipCode);
      setFieldValue('City', company.Place);
      setFieldValue('Address', company.Street);
      setTimeout(() => {
        validateForm();
      }, 100);
    }, [company]);

    return <></>;
  };

  const Duplicate = () => {
    return (
      <div className={styles.card}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Duplikat podmiotu</div>
          <div className={styles.box}>
            <div className={styles.t}>Nip: </div>
            <div className={styles.v}>{isDuplicate?.NIP}</div>
          </div>
          <div className={styles.box}>
            <div className={styles.t}>REGON: </div>
            <div className={styles.v}>
              {!!isDuplicate?.REGON?.length ? isDuplicate?.REGON : '-'}
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.t}>Nazwa: </div>
            <div className={styles.v}>{isDuplicate?.Name}</div>
          </div>
          <div className={styles.box}>
            <div className={styles.t}>Adres: </div>
            <div className={styles.v}>{isDuplicate?.Address}</div>
          </div>
          <div className={styles.box}>
            <div className={styles.t}>Kod pocztowy: </div>
            <div className={styles.v}>{isDuplicate?.ZipCode}</div>
          </div>
          <div className={styles.box}>
            <div className={styles.t}>Email: </div>
            <div className={styles.v}>{isDuplicate?.Email}</div>
          </div>
          <div className={styles.box}>
            <div className={styles.t}>Numer telefonu: </div>
            <div className={styles.v}>{isDuplicate?.Phone}</div>
          </div>
          <div className={styles.btn}>
            <Button onClick={() => setIsDuplicate(null)} text="Powrót" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: `${sizes.leftPanel + 12}%`,
      }}
      className={styles.modal}
    >
      {isDuplicate && <Duplicate />}
      <div
        style={{ display: `${isDuplicate ? 'none' : 'block'}` }}
        className={styles.card}
      >
        <div className={styles.wrapper}>
          <div className={styles.title}>
            {label ? label : 'Edycja podmiotu'}

            <CloseIcon onClick={onClose} className={styles.closeIcon} />
          </div>
          <div className={styles.checkboxes}>
            <SecondCheckbox
              value={isInstitution === false ? true : false}
              text="Osoba"
              onChange={(t) => setIsInstitution(false)}
            />
            <SecondCheckbox
              value={isInstitution === true ? true : false}
              text="Firma "
              onChange={(t) => setIsInstitution(true)}
            />
          </div>
          <Formik
            initialValues={metData}
            validationSchema={
              isInstitution ? validationSchemaEntity : validationSchemaClient
            }
            onSubmit={() => {}}
            validateOnMount
          >
            {({
              values: metaData,
              errors,
              touched,
              isValid,
              setFieldValue,
              setFieldTouched,
              validateForm,
            }) => (
              <>
                <Observer />
                <div className={styles.inputs}>
                  {isInstitution && (
                    <div className={styles.group}>
                      <SearchByNip
                        onBlur={() => setFieldTouched('Nip', true)}
                        isError={touched.Nip ? (errors.Nip as string) : ''}
                        onDataChange={(c) => setCompany(c)}
                        defaultValue={metaData.Nip}
                        onChange={(t) => {
                          setFieldValue('Nip', t);
                        }}
                      />

                      <Input
                        // isError={''}
                        isError={touched.REGON ? (errors.REGON as string) : ''}
                        label="Regon"
                        value={metaData.REGON}
                        onBlur={() => setFieldTouched('REGON', true)}
                        onChange={(t) => {
                          setFieldValue('REGON', t);
                        }}
                      />
                    </div>
                  )}
                  {isInstitution ? (
                    <TextArea
                      isError={touched.Name ? (errors.Name as string) : ''}
                      label="Nazwa podmiotu"
                      value={metaData.Name}
                      onChange={(t) => {
                        setFieldValue('Name', t);
                        setFieldTouched('Name', true);
                      }}
                    />
                  ) : (
                    <Input
                      isError={touched.Name ? (errors.Name as string) : ''}
                      label="Imię i nazwisko"
                      value={metaData.Name}
                      onBlur={() => setFieldTouched('Name', true)}
                      onChange={(t) => {
                        setFieldValue('Name', t);
                      }}
                    />
                  )}

                  <div className={styles.group}>
                    <SearchCode
                      isError={
                        isClickedToValidation ? (errors.ZipCode as string) : ''
                      }
                      defaultValue={metaData.ZipCode}
                      // onBlur={() => setFieldTouched('ZipCode', true)}
                      onChange={(t) => {
                        setFieldValue('ZipCode', t);
                        // setFieldTouched('ZipCode', true);
                      }}
                    />
                    <SearchPlace
                      isError={
                        isClickedToValidation ? (errors.City as string) : ''
                      }
                      defaultValue={metaData.City}
                      onBlur={() => setFieldTouched('City', true)}
                      onChange={(t, c) => {
                        setFieldValue('City', t);
                        setFieldValue('cityId', c);

                        setFieldTouched('City', true);
                      }}
                    />
                  </div>
                  <SearchAddress
                    isError={
                      isClickedToValidation ? (errors.Address as string) : ''
                    }
                    defaultValue={metaData.Address}
                    cityId={metaData.cityId}
                    onBlur={() => setFieldTouched('Address', true)}
                    onChange={(t, c) => {
                      setFieldValue('Address', t);
                      setFieldValue('StreetId', c);
                    }}
                  />
                  <div className={styles.group}>
                    <Input
                      onBlur={() => setFieldTouched('Phone', true)}
                      isError={touched.Phone ? (errors.Phone as string) : ''}
                      className={styles.fix}
                      label="Numer telefonu"
                      defaultValue={metaData.Phone}
                      onChange={(t) => {
                        setFieldTouched('Phone', true);
                        setFieldValue('Phone', t);
                      }}
                    />
                    <Input
                      isError={touched.Email ? (errors.Email as string) : ''}
                      className={styles.fix}
                      label="Email"
                      defaultValue={metaData.Email}
                      onBlur={() => setFieldTouched('Email', true)}
                      onChange={(t) => {
                        setFieldValue('Email', t);
                      }}
                    />
                  </div>
                  <TextArea
                    label="Uwagi"
                    defaultValue={metaData.Note}
                    onChange={(t) => setFieldValue('Note', t)}
                  />
                </div>

                <div className={styles.contacts}>
                  {values.ContactPersons.map((contact, index) => {
                    return (
                      <div className={styles.contact} key={index}>
                        <div className={styles.contactInputs}>
                          <div className={styles.inpute}>
                            <Input
                              label="Imię i nazwisko"
                              defaultValue={`${
                                !!contact.FirstName.length ? (
                                  <>contact.FirstName contact.LastName</>
                                ) : (
                                  ''
                                )
                              }`}
                              onChange={(t) =>
                                changeContactData(index, 'name', t)
                              }
                            />
                            <div className={styles.removeWrapper}>
                              <BinIcon onClick={() => deleteContact(index)} />
                            </div>
                          </div>
                          <div className={styles.contactGroup}>
                            <Input
                              label="Numer telefonu"
                              placeholder="Numer telefonu"
                              defaultValue={`${contact.Phone}`}
                              onChange={(t) =>
                                changeContactData(index, 'phone', t)
                              }
                            />
                            <Input
                              label="Adres E-Mail"
                              placeholder="Adres E-Mail"
                              defaultValue={`${contact.Email}`}
                              onChange={(t) =>
                                changeContactData(index, 'email', t)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {!onCancel ? (
                  <div className={styles.buttonWrapper}>
                    {isInstitution && (
                      <div onClick={addNewPerson} className={styles.addButton}>
                        Dodaj osobę kontaktową
                      </div>
                    )}
                    <Button
                      disabled={!isValid}
                      text="Utwórz"
                      handleError={() => validateForm()}
                      onClick={() => handleClick(metaData, errors)}
                    />
                  </div>
                ) : (
                  <div className={styles.buttonWrapper2}>
                    <Button
                      text="Zapisz"
                      onClick={() => handleClick(metaData, errors)}
                    />
                  </div>
                )}
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditClientModal;
