import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import clientService from 'api/clientService';
import { useEffect, useState } from 'react';

import Placeholder from 'assets/placeholder.png';
import TagBadge from 'components/TagBadge';
import ClientIconState from 'components/ClientIconState';
import { checkFlags } from 'utils/checkFlags';
import { ClientFlags } from 'dto/IClient';
import Avatar from 'components/Avatar';
import { getAvatar } from 'utils/getAvatar';
import { EStatus } from 'components/Avatar/typings';
import sanitizeHtml from 'sanitize-html';

const ClientCard: FC<Props> = ({ client, setSelectedEdit, refetch }) => {
  const { filterId, clientId } = useParams();
  const navigate = useNavigate();
  const { mutate: deleteClient } = clientService.useDeleteClient();
  const [showMenu, setShowMenu] = useState(false);

  const handleDelete = () => {
    deleteClient(
      {
        Id: client.Id,
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  const hasAvatar = checkFlags(client.Flags, ClientFlags.HasAvatar);

  const [av, setAv] = useState<any>(null);

  const fetchAvatar = async () => {
    setAv(await getAvatar(client!.Id!));
  };

  useEffect(() => {
    if (!hasAvatar) return;
    fetchAvatar();
  }, [hasAvatar]);

  return (
    <div className={styles.wrapper}>
      <div
        // onClick={() => navigate(`/clients/${filterId}/${client.Id}`)}
        className={`${styles.card} ${
          clientId === client?.Id?.toString() && styles.active
        }`}
      >
        <div className={styles.ID}>
          <div className={styles.av}>
            <Avatar
              avatar={av}
              status={client.IsOnline ? EStatus.ONLINE : EStatus.OFFLINE}
              className={styles.avatar}
              name={client.Name?.replaceAll('<em>', '')?.replaceAll(
                '</em>',
                ''
              )}
            />
          </div>

          <div className={styles.data}>
            <div
              className={styles.name}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(client.Name ?? ''),
              }}
            ></div>
            <div className={styles.tag}>
              {/* <TagBadge
                tag={{
                  Id: '1',
                  Flags: 1,
                  Name: 'WSPÓŁPRACOWNIK',
                  Global: true,
                  Type: 1,
                  WorkspaceId: ' ',
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className={styles.icons}>
          <ClientIconState client={client} />
        </div>
      </div>
    </div>
  );

  // return (
  //   <div
  //     onMouseLeave={() => setShowMenu(false)}
  //     onMouseEnter={() => setShowMenu(true)}
  //     className={`${styles.wrapper} `}
  //   >
  //     {showMenu && (
  //       <div className={styles.options}>
  //         <TagIcon onClick={() => setSelectedEdit(client)} />
  //         <BinIcon onClick={handleDelete} />
  //       </div>
  //     )}
  //     <div
  //       onClick={() => navigate(`/clients/${filterId}/${client.Id}`)}
  //       className={`${styles.card} ${
  //         client.Id === Number(clientId) && styles.active
  //       }`}
  //     >
  //       <div className={styles.colorBar}></div>
  //       <div className={styles.header}>
  //         <div
  //           dangerouslySetInnerHTML={{ __html: client.Name ?? '' }}
  //           className={styles.sender}
  //         ></div>
  //         <div className={styles.date}>
  //           {/* {moment(msg.SendDate).format('DD/MM/YYYY').toString()} */}
  //         </div>
  //       </div>
  //       <div className={styles.titleWrapper}>
  //         <div className={styles.title}>
  //           {!!client.Phone?.length ? client.Phone : ''}{' '}
  //           {!!client.Email?.length && '|'}{' '}
  //           {!!client.Email?.length ? client.Email : ''}
  //         </div>
  //         <div className={styles.icons}>
  //           {client.CasesCount}
  //           <CaseIcon />
  //         </div>
  //       </div>
  //       <div className={styles.lead}>{client.Address}</div>
  //     </div>
  //   </div>
  // );
};

export default ClientCard;
