import GridWindow from 'components/GridWindow';
import styles from './styles.module.scss';
import { ReactComponent as NoteIcon } from 'assets/icons/note.svg';
import { FC, Props } from './typings';
import NotesService from 'api/NotesService';
import moment from 'moment';
import { getKeys } from 'utils/getKeys';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { decryptPackage } from 'utils/decryptPackage';
import { useEffect, useRef, useState } from 'react';
import AddButton from 'components/AddButton';
import AddNote from 'components/AddNote';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import Loading from 'components/Loading';

export interface INote {
  Id: string;
  CreationDate: string;
  Title: string;
  Content: string;
  Kind: number;
  Case: any;
  LastModification: string;
  decoded?: boolean;
}

const decryptNoteByInter = async (userId: string, msg: string) => {
  return await decryptPackage(userId!, msg);
};

const NotiBox = ({
  note,
  onClick,
  handleDelete,
  listRef,
  n,
  setN,
}: {
  note: INote;
  n: INote[];
  setN: (notes: INote[]) => void;
  listRef: any;
  onClick: (note: INote, title: string, content: string) => void;
  handleDelete: (note: INote) => void;
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const [hover, setHover] = useState(false);

  const notiBoxRef = useRef(null);

  const [startedDecode, setStartedDecode] = useState(false);

  const handleDecode = async () => {
    setStartedDecode(true);
    if (!note.decoded) {
      const copy = [...n];

      const element = copy.find((a) => a.Id === note.Id);
      if (!element) return;

      element.Title = await decryptNoteByInter(userId, note.Title);
      element.Content = await decryptNoteByInter(userId, note.Content);
      element.decoded = true;

      setN(copy);
    }
  };

  useEffect(() => {
    if (!listRef.current) return;

    const handleScroll = () => {
      if (notiBoxRef.current) {
        //@ts-ignore
        const boundingBox = notiBoxRef.current.getBoundingClientRect();
        // Sprawdź, czy NotiBox jest w widoku poziomym i pionowym
        if (
          boundingBox.top >= 0 &&
          boundingBox.left >= 0 &&
          boundingBox.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          boundingBox.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
          // setIsVisible(true);
          if (startedDecode) return;
          handleDecode();
        }
      }
    };

    // Dodaj nasłuchiwanie na zdarzenie scrollowania
    listRef?.current?.addEventListener('scroll', handleScroll);

    // Sprawdź widoczność NotiBox na starcie (może być widoczny domyślnie)
    handleScroll();

    // Usuń nasłuchiwanie zdarzenia scrollowania po wyjściu z komponentu
    return () => {
      listRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [listRef, notiBoxRef]);

  return (
    <div
      ref={notiBoxRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e: any) => {
        const ele = e.target.tagName.toLowerCase();
        const button = e.target.outerHTML.search('button') !== -1;

        if (ele !== 'div' || button) {
          return;
        }

        onClick(note, note.Title, note.Content);
      }}
      className={styles.box}
    >
      {hover && (
        <BinIcon onClick={() => handleDelete(note)} className={styles.remove} />
      )}
      <div className={styles.wrapBox}>
        <div className={styles.data}>
          <div className={styles.title}>
            <div
              dangerouslySetInnerHTML={{
                __html: note.decoded ? note.Title : 'Ładowanie',
              }}
              className={styles.left}
            ></div>
            <div className={styles.right}>
              {moment(note.CreationDate).format('DD.MM.YY')}
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: note.decoded ? note.Content : 'Ładowanie',
            }}
            className={styles.description}
          ></div>
        </div>
      </div>
    </div>
  );
};

const NotesDesk: FC<Props> = ({ handleRemove, id, isEditMode }) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { data: notes, refetch } = NotesService.useGetNotes();
  const { mutate: deleteNote } = NotesService.useDeleteNote();

  const [showCreate, setShowCreate] = useState(false);

  const [editNote, setEditNote] = useState<INote | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [init, setInit] = useState(false);

  const [n, setN] = useState<INote[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (notes?.Items) {
      setN(notes?.Items);
    }
  }, [notes]);

  useEffect(() => {
    return () => {
      setN([]);
    };
  }, []);

  const handleDecryptAsync = async () => {
    setLoading(true);
    // const copy = [...n];
    // for (const note of copy) {
    //   try {
    //     const title = await decryptNoteByInter(userId, note.Title);
    //     const content = await decryptNoteByInter(userId, note.Content);
    //     note.Title = title;
    //     note.Content = content;
    //   } catch (e) {
    //     continue;
    //   }
    //   // setN(copy);
    // }

    setLoading(false);

    // setN(copy);
  };

  useEffect(() => {
    if (init || !n.length) return;

    setInit(true);
    handleDecryptAsync();
  }, [n, init]);

  const addNote = async (newN: INote) => {
    try {
      setN([newN, ...n]);
    } catch (e) {}
  };

  const handleDeleteNote = (note: INote) => {
    deleteNote(
      {
        Id: note.Id,
      },
      {
        onSuccess: () => {
          setN(n.filter((a) => a.Id !== note.Id));
        },
      }
    );
  };

  const listRef = useRef(null);

  return (
    <>
      <GridWindow
        handleRemove={handleRemove}
        id={id}
        isEditMode={isEditMode}
        title={
          <>
            {' '}
            <NoteIcon /> Notatki
          </>
        }
      >
        <>
          <div ref={listRef} className={styles.wrapper}>
            {loading && <Loading />}
            {!loading &&
              n.map((note: INote) => {
                return (
                  <NotiBox
                    listRef={listRef}
                    handleDelete={handleDeleteNote}
                    key={note.Id}
                    onClick={(note, title, content) => {
                      setEditNote(note);
                      setTitle(title);
                      setContent(content);
                      setShowCreate(true);
                    }}
                    note={note}
                    n={n}
                    setN={setN}
                  />
                );
              })}
          </div>
          <AddButton
            className={styles.add}
            onClick={() => setShowCreate(true)}
            text="Utwórz notatkę"
          />
        </>
      </GridWindow>
      {showCreate && (
        <AddNote
          defaultValues={
            editNote ? { id: editNote.Id, title, content } : undefined
          }
          refetch={refetch}
          addNote={addNote}
          toggle={() => {
            setShowCreate(false);
            setEditNote(null);
            setTitle(null);
            setContent(null);
          }}
        />
      )}
    </>
  );
};

export default NotesDesk;
