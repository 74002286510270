import { useQuery } from "react-query";
import { axiosInstance } from "./axios";

const queryKeys = {
    getCompanyFromNIP: (nip: string | number) => ['BIRService.getCompanyFromNIP', nip],
  };

const getCompanyFromNIP = async (nip: string | number) => {
    return await axiosInstance.get(`/bir/search?nip=${nip}`)


}

const useGetCompanyFromNIP = (nip: string | number) => {
    return useQuery(queryKeys.getCompanyFromNIP(nip), () => getCompanyFromNIP(nip), {
        enabled: false
    })
}

export default {
    useGetCompanyFromNIP
}