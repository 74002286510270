import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Button from 'components/Button';
import { EClientData } from 'dto/IClient';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const AddressClientModal: FC<Props> = ({ client, refWrapper }) => {
  if (!refWrapper?.current) return <></>;

  const position = refWrapper.current.getBoundingClientRect();

  const addresses = client.Data.filter((d) => d.Type === EClientData.ADDRESS);

  return ReactDOM.createPortal(
    <div
      style={{
        left: `${position.left}px`,
        top: `${position.top + 32}px`,
      }}
      className={styles.wrapper}
    >
      <div className={styles.title}>
        Adres
        <CloseIcon className={styles.icon} />
      </div>
      {addresses.map((a) => {
        const json = JSON.parse(a.Value);

        return (
          <div className={styles.box}>
            <div className={styles.data}>
              <div className={styles.address}>
                {json.Address ?? json.address}
              </div>
              <div className={styles.city}>
                {json.ZipCode ?? json.zipCOde} {json.City ?? json.city}
              </div>
            </div>{' '}
            {a.IsMain && <StarIcon className={styles.icone} />}
          </div>
        );
      })}
    </div>,
    document.body
  );
};

export default AddressClientModal;
