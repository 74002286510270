import { FC, Props } from './typings';
import styles from './styles.module.scss';
import Input from 'components/Input';
import moment from 'moment';
import CaseStatusDropdown from 'components/CaseStatusDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import SearchStatuses from 'components/SearchStatuses';
import { useState, useEffect, useContext } from 'react';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import TagBadge from 'components/TagBadge';
import casesService, { INewTag } from 'api/casesService';
import CasesContext from 'contexts/CasesContext';
import SelectFlagNew from 'components/SelectFlagNew';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const CaseInfo: FC<Props> = ({ toggle, Case: CASE, statuses, tags }) => {
  const { data: Case } = casesService.useGetCase(CASE.Id);

  const [isModified, setIsModified] = useState<boolean>(false);
  const [status, setStatus] = useState<IStatus | null>(null);
  const [activeTags, setActiveTags] = useState<ITag[]>([]);
  const { mutate: createCase } = casesService.useCreateCase();
  const { cases, setCases } = useContext(CasesContext);

  const [values, setValues] = useState({
    Title: Case?.Title ?? '',
    Signature: Case?.Proceeding?.Signature ?? '',
  });

  useEffect(() => {
    if (Case?.StatusId) {
      const status = statuses.find((s) => s.Id === Case.StatusId);
      if (status) {
        setStatus(status);
      }
    } else {
      setStatus(null);
    }

    const t = tags?.filter((tag) =>
      Case?.TagIds?.find((iTag) => tag.Id === iTag)
    );
    setActiveTags(t);

    setIsModified(false);
  }, [Case]);

  const addNewTag = (tag: ITag) => {
    const isTag = tags.find((etag) => etag.Name === tag.Name);
    if (isTag) return;
    setActiveTags([...tags, tag]);
  };

  const handleRemoveTag = (tag: ITag) => {
    setActiveTags(activeTags.filter((etag) => tag.Name !== etag.Name));
    setIsModified(true);
  };

  const save = () => {
    if (isModified) {
      const newTags: INewTag[] = tags
        .filter((tag) => tag.Id === '-1')
        .map((tag) => ({
          Id: null,
          Name: tag.Name,
          Type: tag.Type,
        }));

      const tagIds = activeTags
        .filter((tag) => tag.Id !== '-1')
        .map((tag) => tag.Id);

      createCase(
        {
          Id: Case?.Id ?? null,
          NewTags: newTags,
          Participants: Case!.Participants,
          Signature: values.Signature,
          TagIds: tagIds,
          Title: values.Title,
          StatusId: status?.Id,
        },
        {
          onSuccess: (r) => {
            Case!.Title = values.Title;
            Case!.Signature = values.Signature;
            Case!.TagIds = r.data.TagIds;
            Case!.StatusId = status?.Id;

            const newCases = [...cases];

            if (Case) {
              let toUpdate = newCases.find((c) => c.Id === Case.Id);

              if (toUpdate) {
                toUpdate.Title = values.Title;
                toUpdate.Signature = values.Signature;
                toUpdate.TagIds = r.data.TagIds;
                toUpdate.StatusId = status?.Id;

                setCases(newCases);
              }
            }

            toggle(false);
          },
          onError: () => toggle(false),
        }
      );
    } else {
      toggle(false);
    }
  };

  const shared = Case?.Shares ?? [];

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Dane teczki
        <CloseIcon onClick={save} className={styles.closeIcon} />
      </div>

      <div className={styles.inputs}>
        <div className={styles.statuses}>
          <SelectFlagNew
            selectedStatus={status}
            onChoose={(status) => setStatus(status)}
          />
        </div>
        <Input
          label="Sygnatura"
          disabled
          onChange={(t) => {
            setValues({ ...values, Signature: t });
            setIsModified(true);
          }}
          value={values.Signature}
        />
        <Input
          disabled
          tags={true}
          placeholder="Tytuł teczki, wpisując # wyszukaj tag lub dodaj nowy"
          onChange={(t) => {
            setValues({ ...values, Title: t });
            setIsModified(true);
          }}
          onChooseTag={addNewTag}
          label="Tytuł teczki"
          value={values.Title}
        />
        {!!activeTags.length && (
          <div className={styles.tagsList}>
            {activeTags.map((tag) => (
              <TagBadge key={tag.Id} tag={tag} onRemove={handleRemoveTag} />
            ))}
          </div>
        )}
        {/* <Input
          disabled
          label="Data założenia"
          onChange={() => {}}
          value={moment(Case.CreationDate).format('DD.MM.YYYY').toString()}
        /> */}
        {/* <div className={styles.statuses}>
          <SearchStatuses
            selectedStatus={status}
            onChoose={(status) => {
              setStatus(status);
              setIsModified(true);
            }}
          />
        </div> */}
        <Input
          disabled
          label="Podmiot"
          onChange={() => {}}
          value={Case?.Client ?? ''}
        />
        <Input
          disabled
          placeholder="Organ prowadzący"
          label="Organ prowadzący"
          onChange={() => {}}
          value={Case?.LeadBy ?? ''}
        />
        <Input
          disabled
          placeholder="Data założenia"
          label="data założenia"
          onChange={() => {}}
          value={
            moment(Case?.Creation.Date).format('DD.MM.YYYY HH:mm').toString() ??
            ''
          }
        />
        <div className={styles.shareTitle}>Udostępniono</div>
        {shared.map((s) => (
          <div className={styles.box}>{s.To}</div>
        ))}
        <div className={styles.shareTitle}>Przekazano</div>
        <div style={{ textAlign: 'center' }}>Brak przekazań</div>
        {/* <Input
          disabled
          placeholder="Utworzono"
          label="Utworzono"
          onChange={() => {}}
          value={moment(Case?.CreationDate ?? new Date()).format(
            'DD.MM.YYYY HH:mm'
          )}
        /> */}
      </div>
    </div>
  );
};

export default CaseInfo;
