import ActionsList from 'components/ActionsList';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

const DeskActions: FC<Props> = ({}) => {
  return (
    <div className={styles.wrapper}>
      <ActionsList isDesk={true} date={new Date()} />
    </div>
  );
};

export default DeskActions;
