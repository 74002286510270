import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import { faC, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import statusService from 'api/statusService';
import { IStatus } from 'dto/IStatus';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import StatusBadge from 'components/StatusBadge';
import { useOnClickOutside } from 'usehooks-ts';

const channels = [
  { value: 12, text: 'Doręczenie PI' },
  { value: 10, text: 'Pozostąłe dokumenty PI' },
  { value: 24, text: 'Przesyłki papierowe' },
  { value: 520, text: 'Przesyłki poufne' },
  { value: 9, text: 'Przesyłki nota' },
  { value: 136, text: 'E-mail' },
];

const SelectChannel: FC<Props> = ({
  onChoose,
  onClose,
  selectedStatus,
  initOpen = false,
  type,
}) => {
  const channel = channels.find((c) => c.value === selectedStatus);
  const [active, setActive] = useState(false);

  const { data: statusesData } = statusService.useGetStatuses();
  let statuses: IStatus[] = useMemo(
    () => statusesData?.data ?? [],
    [statusesData]
  );

  if (type) {
    statuses = statuses.filter((f) => f.Type === type);
  }

  useEffect(() => {
    if (!initOpen) return;
    setActive(true);
  }, [initOpen]);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (active) {
      setActive(false);
    }

    if (onClose && (selectedStatus === null || selectedStatus === -1)) {
      onClose();
    }
  });

  return (
    <div ref={outsideRef} className={styles.wrapper}>
      <div onClick={() => setActive(!active)} className={styles.card}>
        {channel ? (
          <div className={styles.text}>{channel.text}</div>
        ) : (
          <div className={styles.text}>Kanał komunikacji</div>
        )}
        {selectedStatus && (
          <CloseIcon
            onClick={() => {
              onChoose(null);
              onClose?.();
            }}
          />
        )}
        {!onClose && (
          <FontAwesomeIcon icon={active ? faCaretUp : faCaretDown} />
        )}
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.list}>
            {channels?.map((status) => (
              <div
                onClick={() => {
                  setActive(false);
                  onChoose(status.value);
                }}
                key={status.value}
                className={styles.box}
              >
                {status.text}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectChannel;
