import Button from 'components/Button';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import HollowButton from 'components/HollowButton';
import ReactDOM from 'react-dom';
import workspaceService from 'api/workspaceService';

const InvitationsToWorkspace: FC<Props> = ({ toggle, workspaces }) => {
  const { mutate: approve } = workspaceService.useApproveInvitation();
  const { mutate: reject } = workspaceService.useRejectInvitation();

  const handleAccept = (id: string) => {
    approve(
      {
        Id: id,
      },
      {
        onSuccess: () => toggle(),
      }
    );
  };

  const handleReject = (id: string) => {
    reject(
      {
        Id: id,
      },
      {
        onSuccess: () => toggle(),
      }
    );
  };

  //
  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Otrzymałeś zaproszenie</div>
          {workspaces.map((workspace) => {
            return (
              <div className={styles.box}>
                {/* @ts-ignore */}
                <div className={styles.boxName}>{workspace.workspace.name}</div>
                <div className={styles.options}>
                  <Button
                    onClick={() => handleAccept(workspace.id)}
                    text="Dołącz"
                  />
                  <HollowButton
                    onClick={() => handleReject(workspace.id)}
                    text="Odrzuć"
                  />
                </div>
              </div>
            );
          })}
          <div onClick={toggle} className={styles.closeButton}>
            Zamknij
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default InvitationsToWorkspace;
