import { Editor } from '@tinymce/tinymce-react';
import { axiosInstance } from 'api/axios';
import EmailService from 'api/emailService';
import parcelService from 'api/parcelService';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Button from 'components/Button';
import { CaseLetterWrapper } from 'components/CaseLetterWrapper';
import Filedossier from 'components/Filedossier/Filedossier';
import NewSearchEntitiesWithCase from 'components/NewSearchEntitiesWithCase';
import SearchCasesNew from 'components/SearchCasesNew';
import ConverstationContext from 'contexts/ConversationContext';
import KeysContext from 'contexts/KeysContext';
import ModalsManagerContext from 'contexts/ModalsManager';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IEntity } from 'dto/IEntity';
import { IPackage } from 'dto/IPackage';
import { IParcelTypes } from 'dto/IParcelTypes';
import useFiles from 'hooks/useFiles';
import * as openpgp from 'openpgp';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useAuth } from 'react-oidc-context';
import { decryptFile } from 'utils/decryptFile';
import encryptContent from 'utils/encryptString';
import { generateSingleBinaryFromMultiplePublicKeys } from 'utils/generateSingleBinaryFromMultiplePublicKeys';
import { getBlob } from 'utils/getBlob';
import { getErrorMessage } from 'utils/getErrorMessage';
import { getFile } from 'utils/getFile';
import { getKeys } from 'utils/getKeys';
import {
  convertParcelTypeToKind,
  getTypeOfParcel,
} from 'utils/getTypeOfParcel';
import { getUserId } from 'utils/getUserId';
import { ENotificationType, notification } from 'utils/notification';
import { readAsBase64 } from 'utils/readAsBase64';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { uploadBlob } from 'utils/uploadBlob';
import { handleCreate } from 'utils/uploadFile';
import { CaseDossierWrapper } from './CaseDossierWrapper';
import styles from './styles.module.scss';
import { FC, IUploadedFile, Props } from './typings';

import { ReactComponent as AIcon } from 'assets/icons/company/a.svg';
import { ReactComponent as BIcon } from 'assets/icons/company/b.svg';
import { ReactComponent as NewIcon } from 'assets/icons/new_tab.svg';
import CreateCaseModal from 'components/CreateCaseModal';
import Input from 'components/Input';
import PackagesContext from 'contexts/PackagesContext';
import { IAttachment } from 'dto/IAttachment';
import { ItemKind } from 'dto/IKindItems';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { useParams } from 'react-router-dom';
import { decodeAndFetchImages } from 'utils/decodeAndFetchImages';
import { decryptPackage } from 'utils/decryptPackage';
import { addToUrl, hasImages, parseHTML } from 'utils/parseHTML';
import ModalLayout, { DockPosition } from 'components/ModalLayout';

const CreateOutgoingParcelModal: FC<Props> = ({}) => {
  const { status, filterId } = useParams();
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const settings = localStorage.getItem(`${userId}.layout`);

  const sizes = JSON.parse(settings ?? '[]');

  const [isFullscreen, setIsFullscreen] = useState(true);

  const isOutcoming = window.location.href.search('outcoming') !== -1;
  const outSideRef = useRef(null);
  const editorRef = useRef<any>(null);
  const [c, setCase] = useState<ICaseSimple | null>(null);
  const [replyTo, setReplyTo] = useState<{ id: string; kind: ItemKind } | null>(
    null
  );
  const [showAttachments, setShowAttachments] = useState(true);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [oldMsg, setOldMsg] = useState('');
  const [from, setFrom] = useState('');
  const [entities, setEntities] = useState<IEntity[]>([]);
  const [cc, setCC] = useState<IEntity[]>([]);

  const { files, addNewFile, removeFile } = useFiles();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { modals, setModals } = useContext(ModalsManagerContext);
  const { mutate: createParcel } = parcelService.useCreateOutGoingParcel();
  const { data: parcelData } = parcelService.useGetOutcomingInit();

  const parcelInit = useMemo(() => parcelData?.data ?? [], [parcelData]);

  const [showDossier, setShowDossier] = useState<boolean>(false);
  const [showLetter, setShowLetter] = useState<boolean>(false);

  const [isReply, setIsReply] = useState(false);
  const [reply, setReply] = useState({
    id: '',
    kind: '',
  });
  const [forward, setForward] = useState({
    id: '',
    kind: '',
  });
  const [isForward, setIsForward] = useState(false);
  const [type, setType] = useState<string | null>(null);

  const { packages, setPackages } = useContext(PackagesContext);

  const editorSize = window.innerHeight <= 800 ? window.innerHeight - 430 : 300;
  const attachmentSize =
    window.innerHeight <= 800 ? window.innerHeight - 80 : 835;

  const isDisabled = () => {
    if (!title.length) {
      return true;
    }

    if (!entities.length) {
      return true;
    }

    return false;
  };

  useOnClickOutside(outSideRef, () => {
    if (setDropdown) {
      setShowDropdown(false);
    }
  });

  const { converstation, setConverstation } = useContext(ConverstationContext);

  const getKey = async () => {
    if (c) {
      const response = await axiosInstance.get(`/case/keys?id=${c?.Id}`);
      // Publiczny klucz sprawy
      return response.data?.[0].PublicKey;
    }

    const myKeys = await getKeys(userId);

    const masterPublicKey = await readFileFromBase64(
      myKeys!.publicBase64Key,
      'application/other'
    );
    const armored = new TextDecoder().decode(masterPublicKey);

    const t = await openpgp.key.readArmored(armored);

    return t;
  };

  const getEntityId = (entity: IEntity) => {
    let id = undefined;
    if (entity.Id !== -1 && entity.UserId) {
      id = String(entity.UserId);
    }

    if (!id && !entity.UserId && entity.ParticipantId) {
      id = String(entity.ParticipantId);
    }

    if (!id && entity.Id && entity.Id !== -1 && !entity.UserId) {
      id = String(entity.Id);
    }

    return id;
  };

  const handleSend = async () => {
    setIsLoading(true);

    const publicKey = await getKey();
    const myKeys = await getKeys(userId);
    const isEncryptedParcel = entities.find((entity) =>
      getTypeOfParcel(entity.ParcelTypes ?? 0).find(
        (type) =>
          type === IParcelTypes.EncryptedEDelivery ||
          type === IParcelTypes.NotaEncrypted
      )
    );

    const isNormalParcel = entities.find((entity) =>
      getTypeOfParcel(entity.ParcelTypes ?? 0).find(
        (type) =>
          type === IParcelTypes.EDelivery ||
          type === IParcelTypes.Email ||
          type === IParcelTypes.TraditionalLetter
      )
    );

    const emailParcels = entities.find((e) =>
      getTypeOfParcel(e.ParcelTypes ?? 0).find(
        (type) => type === IParcelTypes.Email
      )
    );

    if (!parcelInit.SupportsEmail && emailParcels) {
      notification({
        type: ENotificationType.ERROR,
        title: 'Błąd',
        text: 'Nie posiadasz skonfigurowanej skrzynki mailowej. Przejdź do ustawień i skonfiguruj integrację.',
      });
      setIsLoading(false);
      return;
    }

    // Generowanie kluczy
    const keys = await generateSingleBinaryFromMultiplePublicKeys([
      ...entities
        .map((entity) => entity.PublicKey ?? '')
        .filter((e) => !!e.length),
      myKeys!.publicBase64Key,
    ]);

    let s = publicKey;

    if (c) {
      const decryptedPublicKey = await readFileFromBase64(
        publicKey,
        'application/other'
      );

      s = await openpgp.key.read(new Uint8Array(decryptedPublicKey));
    }

    // Szyfrowanie klucza kluczem sprawy
    const privateCaseKey = await encryptContent(keys.PrivateKey, s);

    let sendContent = content;

    if (oldMsg) {
      sendContent = sendContent + '' + oldMsg;
    }

    // Szyfrowanie treści
    const encryptedContent = await encryptContent(
      sendContent,
      keys.cleanPublicKey
    );

    let normalUri: any = [];
    let normalBlobUri: any = [];
    let encryptedUri: any = [];
    let encryptedBlobUri: any = [];

    if (isNormalParcel || emailParcels) {
      normalUri = !!files.length
        ? await handleCreate(files, c?.Id ?? '', publicKey, false, false)
        : [];

      normalBlobUri = !!uploadedFiles.length
        ? await uploadBlob(uploadedFiles, '', keys, false, false)
        : [];
    }

    if (isEncryptedParcel) {
      encryptedUri = !!files.length
        ? await handleCreate(files, c?.Id ?? '', publicKey, false, true)
        : [];

      encryptedBlobUri = !!uploadedFiles.length
        ? await uploadBlob(uploadedFiles, '', keys, true, true)
        : [];
    }

    const uris = [
      normalUri,
      normalBlobUri,
      encryptedUri,
      encryptedBlobUri,
    ].flat();

    createParcel(
      {
        Attachments: !!uris.length
          ? uris?.map((url, index) => ({
              Encrypted: url!.encrypted,
              Order: index,
              Uri: url!.uri,
            }))
          : [],

        //@ts-ignore
        CaseId: c ? c.Id : null,
        Cc: cc.map((entity) => {
          let id = getEntityId(entity);

          return {
            Id: id,
            Type: entity.Id !== -1 ? entity.Type : 5,
            Parcel:
              entity.Id !== -1
                ? (convertParcelTypeToKind(entity.parcelTypeSelected!) as any)
                : 8,
            Email: entity.Email,
            // EDeliveryAddress: entity.EDeliveryAddress,

            EDeliveryAddress:
              entity.Id !== -1 ? entity.EDeliveryAddress : undefined,

            PublicKey: keys.PublicKey,
            PrivateKey: keys.PrivateKey,
          };
        }),
        // Cc: cc.map((entity) => ({
        //   Id:
        //     entity.Id !== -1
        //       ? entity.UserId
        //         ? entity.UserId
        //         : String(entity.Id)
        //       : undefined,
        //   Type: entity.Id !== -1 ? entity.Type : 5,
        //   Parcel:
        //     entity.Id !== -1
        //       ? (convertParcelTypeToKind(entity.parcelTypeSelected!) as any)
        //       : 8,
        //   Email: entity.Email,
        //   // EDeliveryAddress: entity.EDeliveryAddress,

        //   EDeliveryAddress:
        //     entity.Id !== -1 ? entity.EDeliveryAddress : undefined,

        //   PublicKey: keys.PublicKey,
        //   PrivateKey: keys.PrivateKey,
        // })),
        Reply: !!reply.id.length
          ? { Id: reply.id, Kind: reply.kind }
          : undefined,
        Forward: !!forward.id.length
          ? { Id: forward.id, Kind: forward.kind }
          : undefined,
        Content: !!isEncryptedParcel
          ? await readAsBase64(encryptedContent)
          : sendContent,
        RawContent: !!isNormalParcel ? sendContent : null,
        DocumentId: null,
        PrivateKey: await readAsBase64(new Uint8Array(privateCaseKey)),
        PublicKey: keys.PublicKey,
        Receivers: entities
          .filter((entity) => !entity.cc)
          .map((entity) => {
            let id = getEntityId(entity);

            return {
              Id: id,
              Type: entity.UserId ? 1 : entity.Id !== -1 ? entity.Type : 5,
              Parcel:
                entity.Id !== -1
                  ? (convertParcelTypeToKind(entity.parcelTypeSelected!) as any)
                  : 8,
              Email: entity.Email,

              EDeliveryAddress:
                entity.Id !== -1 ? entity.EDeliveryAddress : undefined,

              PublicKey: keys.PublicKey,
              PrivateKey: keys.PrivateKey,
            };
          }),
        Title: title,
      },
      {
        onSuccess: (response: any) => {
          if (isOutcoming) {
            setPackages([response.data.Items[0], ...packages]);
          }
          setConverstation([response.data.Items[0], ...converstation]);
          setKeys(
            [
              ...keysContext,
              response.data.ObjectKeys,
              response.data.Keys,
            ].flat()
          );
          setIsLoading(false);
          setModals({ ...modals, createPost: false });
        },
        onError(e: any) {
          setIsLoading(false);
          notification({
            title: 'Nie udało się przedłożyć przesyłki',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  const handleChooseFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = (event.target as any).files[0];
    if (!file) return;

    addNewFile(file);
  };

  const handleClickToUpload = () => {
    if (!inputFile.current) return;
    inputFile.current.click();
  };

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);
  const [setDropdown, setShowDropdown] = useState(false);

  const handleAddFiles = (files: IUploadedFile[]) => {
    setUploadedFiles([...uploadedFiles, files].flat());
    setShowDossier(false);
    setShowLetter(false);
  };

  const removeUploadedFile = (sindex: number) => {
    setUploadedFiles(uploadedFiles.filter((a, index) => index !== sindex));
  };
  const { keys: keysContext, setKeys } = useContext(KeysContext);
  const addFile = async (element: IPackage) => {
    const file = await getFile(
      element.S4 ?? element.S3 ?? element?.S2 ?? element.Id
    );
    const blob = await getBlob(file.data.Url);
    const key = keysContext.find(
      (key: any) => key?.Id === element.EncryptionKeyId
    );

    if (!key) return;

    let parentKey = null;
    //@ts-ignore
    if (key.ParentKeyId) {
      parentKey = keysContext.find(
        //@ts-ignore
        (pkey: any) => pkey?.Id === key?.ParentKeyId
      );
    }

    let decryptedFile: Blob | null = null;
    try {
      decryptedFile = await decryptFile(
        userId ?? '',
        new Blob([blob.data]),
        key,
        parentKey
      );
    } catch (e) {
      decryptedFile = new Blob([blob.data]);
    }

    const el = {
      file: decryptedFile,
      name: file.data.FileName,
      fileType: 'application/pdf',
      size: decryptedFile.size,
    };

    handleAddFiles([el]);
  };

  useEffect(() => {
    const elements = localStorage.getItem('send_item');

    if (!!elements?.length) {
      const fi = JSON.parse(elements);
      addFile(fi);
      localStorage.removeItem('send_item');
    }
  }, []);

  useEffect(() => {
    const email = sessionStorage.getItem('sendTo');
    if (!email || !email?.length) return;

    const payload: IEntity = {
      Id: -1,
      Address: '',
      BuildingNumber: '',
      City: '',
      Claims: [],
      Description: '',
      EDeliveryAddress: email,
      Email: email,
      FirstName: '',
      FlatNumber: '',
      IsGlobal: false,
      KRS: '',
      Label: email,
      Type: 8,
      ParcelTypes: 8,
      parcelTypeSelected: IParcelTypes.Email,
    };

    sessionStorage.removeItem('sendTo');

    setEntities([payload]);
  }, []);

  const [showCase, setShowCase] = useState(false);

  useEffect(() => {
    const email = sessionStorage.getItem('reply');

    if (!email || !email?.length) return;

    const data = JSON.parse(email);

    setTitle(data.title);
    setFrom(data.email);

    if (data.case) {
      setCase(data.case);
    }

    setIsReply(true);
    setReply({ id: data.id, kind: data.kind });
    sessionStorage.removeItem('reply');

    setTitle(data.title.replaceAll('<em>', '').replaceAll('</em>', ''));
  }, []);

  const pId = forward?.id ? forward.id : reply.id;
  const pKind = forward?.kind ? forward.kind : reply.kind;

  const { data: previewData } = EmailService.useGetPreview(
    pId ?? '',
    Number(pKind) === 128 ? true : false
  );

  const { keys } = useContext(KeysContext);

  function isBase64(str: string) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    if (forward.id || reply.id) {
      getContent();
    }
  }, [reply, forward, previewData]);

  const decodeEncryptedContent = async () => {
    const myKeys = await getKeys(userId!);
    const objectKey = previewData?.data?.ObjectKeys?.[0];

    let parentKey = null;

    if (objectKey.ParentKeyId) {
      parentKey = previewData?.data.Keys.find(
        (k: any) => k?.Id === objectKey?.ParentKeyId
      );
    }

    const content = await decryptPackage(
      userId,
      previewData?.data.Content,
      objectKey,
      parentKey
    );

    const images = await decodeAndFetchImages(
      userId,
      previewData?.data,
      objectKey,
      parentKey
    );

    setOldMsg(await parseHTML(content, images));
  };

  const getContent = async () => {
    const { data } = await axiosInstance.get(`/list?Id=${pId}&Kind=${pKind}`);

    const pack: IPackage = data.Items[0];

    if (!pack) return;
    if (pack.Kind === 128) {
      const isEncrypted = previewData?.data?.IsEncrypted;

      if (isEncrypted) {
        decodeEncryptedContent();
        return;
      }
      const isImages = await hasImages(previewData?.data?.Content);

      setContent(
        await addToUrl(previewData?.data?.Content, isImages ? true : false)
      );

      return;
    }

    try {
      const r = atob(pack.S2);
      const encryptionKey = keys.find(
        (key) => key?.Id === pack?.EncryptionKeyId
      );

      let parentKey = null;
      if (encryptionKey?.ParentKeyId) {
        parentKey = keys.find((key) => key?.Id === encryptionKey.ParentKeyId);
      }

      setContent(
        await decryptPackage(userId, pack.S2, encryptionKey, parentKey)
      );
    } catch (e) {
      if (isBase64(pack.S2)) {
        setContent('error');
        return;
      }
      setContent(`${pack.S2}`);
      return;
    } finally {
    }
  };

  const handleAdd = async () => {
    const { data } = await axiosInstance.get(`/list?Id=${pId}&Kind=${pKind}`);
    const pack: IPackage = data.Items[0];
    if (!pack) return;

    const attachments: IAttachment[] = pack.Attachments;

    const files = [];
    for (const element of attachments) {
      const file = await getFile(element.Uri);
      const blob = await getBlob(file.data.Url);
      const key = keys.find((key: any) => key?.Id === pack.EncryptionKeyId);

      if (!key) {
        return;
      }

      let parentKey = null;
      //@ts-ignore
      if (key.ParentKeyId) {
        parentKey = keys.find(
          //@ts-ignore
          (pkey: any) => pkey?.Id === key?.ParentKeyId
        );
      }

      const decryptedFile = await decryptFile(
        userId ?? '',
        new Blob([blob.data]),
        key,
        parentKey
      );

      const el = {
        file: decryptedFile,
        name: element.Label ?? element.FileName,
        fileType: element.ContentType,
        size: element.Size,
      };
      files.push(el);
    }

    handleAddFiles(files);
  };

  useEffect(() => {
    const t = sessionStorage.getItem('type');

    if (t) {
      setType(t);
      sessionStorage.removeItem('type');
    }

    if (t !== 'forward') return;

    const email = sessionStorage.getItem('forward');

    if (!email || !email?.length) return;

    const data = JSON.parse(email);

    console.log(data);

    if (data.case) {
      console.log('ustawiam poprawnie teczke: ', data.case);
      setCase(data.case);
    }

    setIsForward(true);
    setForward({ id: data.id, kind: data.kind });

    const msg = sessionStorage.getItem('msg') ?? '';

    setContent(msg);
    setFrom(data.email);
    sessionStorage.removeItem('reply');

    setTitle(data.title.replaceAll('<em>', '').replaceAll('</em>', ''));
  }, []);

  useEffect(() => {
    if (isForward && pId) {
      handleAdd();
    }
  }, [isForward, pId, pKind]);

  const refWrapper = useRef(null);

  return (
    <ModalLayout
      dock={DockPosition.DISABLED}
      off={true}
      customX={1}
      customY={0}
      ignore
      refWrapper={refWrapper}
      isAnimation
      fullHeight={true}
      widthAsLeftArea={true}
    >
      <>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            {type ? (
              <>{type === 'forward' ? 'Przekaż do' : 'Odpowiedz'}</>
            ) : (
              'Nowa przesyłka'
            )}
            <div className={styles.icons}>
              <NewIcon
                className={styles.c}
                onClick={() => setIsFullscreen(!isFullscreen)}
              />
              <CloseIcon
                className={styles.c}
                onClick={() => setModals({ ...modals, createPost: false })}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.inputs}>
              <SearchCasesNew
                variant="filled"
                settedCase={c}
                setAddNewEntity={() => {
                  setShowCase(true);
                }}
                onChoose={(c) => setCase(c)}
              />
              <NewSearchEntitiesWithCase
                isFullscreen={isFullscreen}
                className={styles.t}
                placeholder="Do: "
                focus
                defaultValue={sessionStorage.getItem('sendTo') ?? undefined}
                onChoose={(e) => {
                  setEntities(e);
                }}
                showAlways
                entities={entities}
                c={c}
              />
              <NewSearchEntitiesWithCase
                placeholder="Dw: "
                onChoose={(e) => {
                  setCC(e);
                }}
                entities={cc}
                c={c}
              />
              <Input
                label="Tytuł"
                value={title}
                variant="filled"
                onChange={(text) => setTitle(text)}
              />
              <Input
                label="Od: "
                value={from}
                variant="filled"
                onChange={(text) => setFrom(text)}
              />
            </div>
            <div className={styles.editor}>
              <Editor
                apiKey="12k7ybb0o4007p3nah1s77v7gmrbalfhy4jreucu2nky2l3b"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                onEditorChange={(text) => setContent(text)}
                initialValue=""
                value={content}
                init={{
                  forced_root_block: 'div',
                  menubar: false,
                  statusbar: false,
                  resize: false,
                  browser_spellcheck: true,
                  height: '100%',
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'code',
                    'help',
                    'imageTools',
                    'quickbars',
                  ],
                  toolbar_persist: true,
                  fixed_toolbar_container_target: document.body,
                  contextmenu: 'table',
                  contextmenu_never_use_native: true,
                  images_upload_url: '',
                  images_upload_handler: (): Promise<any> => {
                    return new Promise((resolve) => {
                      resolve(true);
                    });
                  },
                  toolbar: `bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor | indent outdent | bullist numlist | table`,
                  content_style:
                    '.mce-content-body { background: #F4EFF4;} .tox-editor-header { background: #F4EFF4!important;} .tox .tox-tbtn svg {fill: #ff0000!important;}',
                }}
              />
            </div>
            <div className={styles.attachments}>
              <div className={styles.attHeader}>
                <div className={styles.attTitle}>Załączniki</div>
                <div className={styles.button}>
                  <div
                    className={styles.attAdd}
                    onClick={() => setShowDropdown(!setDropdown)}
                  >
                    + Dodaj plik
                  </div>
                  {setDropdown && (
                    <div ref={outSideRef} className={styles.dropdown}>
                      <div
                        onClick={() => {
                          handleClickToUpload();
                          setShowDropdown(false);
                        }}
                        className={styles.box}
                      >
                        Z dysku
                      </div>
                      <div
                        onClick={() => {
                          if (!c) {
                            notification({
                              text: 'Wybierz teczkę żeby dodać pliki z dossier',
                              title: 'Teczka',
                              type: ENotificationType.ERROR,
                            });
                            return;
                          }

                          setShowDropdown(false);
                          setShowDossier(true);
                        }}
                        className={styles.box}
                      >
                        Z zakładki Dossier
                      </div>
                      <div
                        onClick={() => {
                          if (!c) {
                            notification({
                              text: 'Wybierz teczkę żeby dodać pisma',
                              title: 'Teczka',
                              type: ENotificationType.ERROR,
                            });
                            return;
                          }

                          setShowDropdown(false);
                          setShowLetter(true);
                        }}
                        className={styles.box}
                      >
                        Z zakładki pisma
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.attachWrap}>
                <div className={styles.attachItems}>
                  {files.map((file, i) => (
                    <Filedossier
                      index={i}
                      file={file}
                      handleRemove={removeFile}
                    />
                  ))}
                  {uploadedFiles.map((file, i) => (
                    <Filedossier
                      index={i}
                      file={file}
                      handleRemove={removeUploadedFile}
                    />
                  ))}
                </div>

                <FileUploader
                  multiple={true}
                  handleChange={async (f: any) => {
                    // handleChooseFile(f);
                    for (const file of f) {
                      addNewFile(file);
                    }
                  }}
                  classes={styles.drop}
                  name="file"
                />
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.cost}>Koszt przesyłki 0,00 zł</div>
              <Button
                loading={isLoading}
                className={styles.btn2}
                disabled={isDisabled()}
                onClick={handleSend}
                // text="Wyślij"
                element={
                  <>
                    <BIcon /> Wyślij
                  </>
                }
              />
            </div>
          </div>
        </div>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={handleChooseFile}
        />
        {showDossier && (
          <div className={styles.modal}>
            <CloseIcon
              className={styles.close}
              onClick={() => setShowDossier(false)}
            />
            <div className={styles.window}>
              <CaseDossierWrapper handleAddFiles={handleAddFiles} c={c!} />
            </div>
          </div>
        )}
        {showLetter && (
          <div className={styles.modal}>
            <CloseIcon
              className={styles.close}
              onClick={() => setShowLetter(false)}
            />
            <div className={styles.window}>
              <CaseLetterWrapper handleAddFiles={handleAddFiles} c={c!} />
            </div>
          </div>
        )}
        {showCase && (
          <CreateCaseModal
            isAnimation={false}
            onCreate={(c) => setCase(c)}
            onClose={() => setShowCase(false)}
          />
        )}
      </>
    </ModalLayout>
  );
};

export default CreateOutgoingParcelModal;
