import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as CalendarIcon } from 'assets/icons/calenar_sidebar.svg';
import DatePickerComponent from 'components/DatePicker';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import moment from 'moment';
import { useOnClickOutside } from 'usehooks-ts';

export const DateSidePick = ({
  startOf,
  endOf,
  selected,
  setSelected,
  setDate,
  onlyStart = false,
  isOpen = false,
  setIsOpen,
}: {
  startOf?: Date;
  endOf?: Date;
  setDate: (type: number, date: Date) => void;
  setSelected: (t: number) => void;
  selected: number;
  onlyStart?: boolean;
  isOpen?: boolean;
  setIsOpen?: (t: boolean) => void;
}) => {
  const primaryInputRef = useRef(null);
  const secondInputRef = useRef(null);

  useEffect(() => {
    setSelected(1);
  }, []);

  function ServerDay(
    props: PickersDayProps<any> & { highlightedDays?: number[] }
  ) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const dat = highlightedDays.map((d) =>
      Number(moment(d).format('DD').toString())
    );

    const isSelected =
      !props.outsideCurrentMonth && dat.includes(props.day.date());

    const actualDay = moment(new Date()).format('DD');
    let calendarData = props.day.date();
    if (calendarData <= 9) {
      calendarData = `0${calendarData}`;
    }

    const selectedStyle = isSelected ? styles.c : '';
    const isActualDate =
      actualDay === calendarData && !props.selected ? styles.isActualDate : '';
    const selectedStyle2 = props.selected ? styles.selectedDate : '';

    return (
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        className={`${selectedStyle} ${isActualDate} ${selectedStyle2}`}
      />
    );
  }

  return (
    <>
      <DatePickerComponent
        focus={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClick={(ref) => setSelected(1)}
        setDate={(r) => {
          setDate(1, moment(r).toDate());
        }}
        date={startOf}
        showTime
        inputRef={primaryInputRef}
      />
      {!onlyStart && (
        <DatePickerComponent
          inputRef={secondInputRef}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClick={(ref) => setSelected(2)}
          setDate={(r) => {
            setDate(2, moment(r).toDate());
          }}
          date={endOf}
          showTime
        />
      )}
      <DateCalendar
        slots={{
          day: ServerDay,
        }}
        className={styles.mui}
        value={undefined}
        onChange={(newValue: any) => {
          if (!selected) return;
          const newDate = moment(newValue)
            .add(moment(startOf ?? moment(new Date())).format('HH'), 'hour')
            .add(moment(startOf ?? moment(new Date())).format('mm'), 'minutes')
            .toDate();

          setDate(selected, newDate);
          if (selected === 1) {
            //@ts-ignore
            primaryInputRef?.current?.focus?.();
          }
          if (selected === 2) {
            //@ts-ignore
            secondInputRef?.current?.focus?.();
          }
        }}
      />
    </>
  );
};

const DatePickerNew: FC<Props> = ({
  startOf,
  endOf,
  onlyStart = false,
  setDate,
  showTime = false,
}) => {
  const [active, setActive] = useState(false);

  const [selected, setSelected] = useState(0);

  const outsideRef = useRef(null);

  const time = moment(startOf).format('HH:mm');

  let format = 'DD.MM.YYYY';

  if (showTime && time !== '00:00') {
    format = 'DD.MM.YYYY HH:mm';
  }

  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(outsideRef, () => {
    if (active && !isOpen) {
      setActive(false);
    }
  });

  const build = () => {
    if (!startOf && !endOf) {
      return 'Data';
    }

    const isStartOfTime = moment(startOf).format('HH:mm') !== '00:00';
    const isEndOfTime = moment(endOf).format('HH:mm') !== '00:00';

    if (startOf && !endOf) {
      if (isStartOfTime) {
        return `${moment(startOf).format('HH:mm DD.MM.YY')}`;
      }
      return `${moment(startOf).format('DD.MM.YY')}`;
    }

    if (startOf && endOf) {
      if (moment(startOf).startOf('day').isSame(moment(endOf).startOf('day'))) {
        if (isStartOfTime && !isEndOfTime) {
          return `${moment(startOf).format('HH:mm DD.MM.YY')}`;
        }
        if (isStartOfTime && isEndOfTime) {
          return `${moment(startOf).format('HH:mm')} - ${moment(endOf).format(
            'HH:mm'
          )} ${moment(startOf).format('DD.MM.YY')}`;
        }
      }

      return `${moment(startOf).format('HH:mm DD.MM.YY')} ${moment(
        endOf
      ).format('HH:mm DD.MM.YY')}`;
    }

    // if (startOf && endOf) {
    //   return `${moment(startOf).format(format)} - ${moment(endOf).format(
    //     format
    //   )}`;
    // }

    // if (startOf) {
    //   return moment(startOf).format(format);
    // }

    // if (endOf) {
    //   return `- ${moment(endOf).format(format)}`;
    // }
  };

  return (
    <div ref={outsideRef} className={styles.wrapper}>
      <div
        onClick={() => setActive(!active)}
        className={`${styles.card} ${(startOf || endOf) && styles.selected}`}
      >
        <CalendarIcon className={`${styles.icon} ${styles.calendar}`} />{' '}
        {build()}
        <FontAwesomeIcon
          style={{ color: 'black' }}
          icon={active ? faCaretUp : faCaretDown}
        />
      </div>

      {active && (
        <div className={styles.dropdown}>
          <div className={styles.padding}>
            <DateSidePick
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onlyStart={onlyStart}
              endOf={endOf}
              selected={selected}
              setDate={setDate}
              setSelected={setSelected}
              startOf={startOf}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePickerNew;
