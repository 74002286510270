import { decryptFile } from './decryptFile';
import { getBlob } from './getBlob';

export const downloadFile = async (
  userId: string,
  fileName: string,
  url: string,
  keys?: any,
  parentKeys?: any
) => {
  const file = await getBlob(url);
  // 

  const URL = window.URL.createObjectURL(
    keys ? await decryptFile(userId ?? '', new Blob([file.data]), keys, parentKeys) : new Blob([file.data])
  );
  const link = document.createElement('a');
  link.href = URL;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export default downloadFile;
