import clientsService from 'api/clientsService';
import Client from 'components/Client';
import ClientCard from 'components/ClientCard';
import PanelsLayout from 'components/PanelsLayout';
import { IClient } from 'dto/IClient';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import EditClientModal from 'components/EditClientModal';
import { useNavigate, useParams } from 'react-router-dom';
import EmptyPanel from 'components/EmptyPanel';
import ClientsContext from 'contexts/ClientsContext';
import { useDebounce } from 'use-debounce';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance } from 'api/axios';
import ClientNew from 'components/ClientNew';
import SortOrder from 'components/SortOrder';
import {
  EOrder,
  ETypeOfOrder,
  IOrderObject,
} from 'components/SortOrder/typings';
import Loading from 'components/Loading';
import { parseHTML } from 'utils/parseHTML';
import Search from 'assets/icons/search.gif';
import { ConsoleWriter } from 'istanbul-lib-report';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import CaseDossier from 'components/CaseDossier';

const SharesModule = () => {
  const { filterId, folderId } = useParams();
  const navigate = useNavigate();

  const leftPanel = () => {
    return (
      <div>
        <CaseDossier Case={null} shares={true}></CaseDossier>
      </div>
    );
  };

  const rightPanel = () => {
    return <EmptyPanel />;
  };
  return (
    <>
      <PanelsLayout
        disableHeader={true}
        leftPanel={leftPanel()}
        rightPanel={rightPanel()}
      />
    </>
  );
};
export default SharesModule;
