import * as openpgp from 'openpgp';
import { readFileFromBase64 } from './readFileFromBase64';
import { readAsBase64 } from './readAsBase64';

export interface ISingleBinaryKeys {
    PublicKey: string; 
    PrivateKey: string;
    cleanPublicKey: openpgp.key.KeyResult
    cleanPrivateKey: openpgp.key.KeyResult
    test: any;
}


export const generateSingleBinaryFromMultiplePublicKeys = async (publicKeys: string[]): Promise<ISingleBinaryKeys> => {
    let encryptionKeys = [];

    encryptionKeys = await Promise.all(
        publicKeys.map(async (publicKey) => {
            const masterPublicKey = await readFileFromBase64(publicKey, 'application/other');
            const armored = new TextDecoder().decode(masterPublicKey);
          
            return (await openpgp.key.readArmored(armored)).keys[0];
        })
    )



    
    let options: openpgp.KeyOptions = {
        userIds: [{
            name: '1testowyklucz'
        }],
        curve: 'secp256k1',
        numBits: 2048
        
    }
        const key = await openpgp.generateKey(options);
        
        const pubDecoded = await openpgp.key.readArmored(key.publicKeyArmored);
        const privDecoded = await openpgp.key.readArmored(key.privateKeyArmored);
        const publicKeyResultString = new Uint8Array(pubDecoded.keys[0].toPacketlist().write());
        const keyResultString = new Uint8Array(privDecoded.keys[0].toPacketlist().write());
        const { message } = await openpgp.encrypt({
            message: await openpgp.message.fromBinary(keyResultString),
            publicKeys: encryptionKeys,
            armor: false,
        });
        
        
        return {
            PublicKey: await readAsBase64(publicKeyResultString),
            PrivateKey: await readAsBase64(new Uint8Array(message.packets.write())),
            cleanPublicKey: pubDecoded,
            cleanPrivateKey: privDecoded,
            test: message

        }
}