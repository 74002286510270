import { IDecryptedKeys } from './decryptKeys';
import { readFileFromBase64 } from './readFileFromBase64';
import * as openpgp from 'openpgp';

export const decryptPrivateKey = async (
  myKeys: IDecryptedKeys,
  key: any,
  emptyPassphrase?: any
) => {
  const privateKeyData = await readFileFromBase64(key, 'application/other');
  const msg = await openpgp.message.read(new Uint8Array(privateKeyData));

  
  const privateKey = await openpgp.key.readArmored(
    atob(myKeys!.intermidatePrivateKey!.data)
    );

    console.log(msg);
    
    const firstKey = privateKey.keys[0];

    
    
  if (emptyPassphrase === true) {
    firstKey.decrypt('');
  }



  var options: openpgp.DecryptOptions = {
    message: msg,
    privateKeys: firstKey,
    format: 'binary',
  };

  return await openpgp.decrypt(options);
};
