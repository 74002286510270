import Input from 'components/Input';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';
import InputWithRightIcon from 'components/InputWithRightIcon';
import TerytService from 'api/TerytService';
import { useDebounce } from 'use-debounce';
import { useOnClickOutside } from 'usehooks-ts';

const SearchCode: FC<Props> = ({
  isError = '',
  onChange,
  defaultValue,
  onBlur,
}) => {
  const [value, setValue] = useState(defaultValue.toString() ?? '');
  const [searchList, setSearchList] = useState<any[]>([]);

  const [ss, setSearchValue] = useState('');
  const [searchValue] = useDebounce(ss, 300);

  const { data: zipData } = TerytService.useGetZipCode(searchValue);
  const zipCodes: any[] = useMemo(() => zipData?.data ?? [], [zipData]);
  useEffect(() => {
    const list = zipCodes.map((zip) => zip.Value);

    if (value.length === 0) return;
    setSearchList(list);
  }, [searchValue, zipCodes]);

  const handleClick = (zip: string) => {
    setValue(zip);
    onChange(zip);
    setSearchList([]);
  };

  useEffect(() => {
    setValue(defaultValue.toString());
  }, [defaultValue]);

  const [focused, setFocused] = useState(false);

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    if (focused) {
      setFocused(false);
    }
  });

  return (
    <div ref={outsideRef} className={`${styles.wrapper}`}>
      <InputWithRightIcon
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          onBlur?.();
        }}
        isError={isError}
        value={value}
        onChange={(text) => {
          setValue(text);
          setSearchValue(text);
        }}
        label="Kod pocztowy"
      />
      {!!searchList.length && !!value.length && focused && (
        <div className={styles.list}>
          <div className={styles.listWrapper}>
            {searchList.map((zip: string) => (
              <div
                onClick={() => handleClick(zip)}
                key={zip}
                className={styles.box}
              >
                {zip}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchCode;
