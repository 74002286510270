export enum EDeliveryAddressStep {
  CHOOSE_FOR = 'CHOOSE_FOR',
  CHOOSE_CONTEXT = 'CHOOSE_CONTEXT',
  CHOOSE_ADDRESS = 'CHOOSE_ADDRESS',
  ContextStep = 'context',
  IdentityStep = 'identity',
  CompanyStep = 'company',
  AddressStep = 'address',
  SummaryStep = 'summary',
  DoneStep = 'done',
}
