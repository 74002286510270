import { string, object } from 'yup';

export const validationSchemaClient = object({
  Name: string().required('Wpisz imię i nazwisko')
});

export const validationSchemaEntity = object({
  Name: string().required('Wpisz nazwę podmiotu'),
  Nip: string().required('Wpisz NIP'),
  REGON: string().required('Wpisz regon'),
});