import PDFContext from "contexts/PDFContext";
import { useContext, useState } from "react";

function useGetPDFViewer() {


    const {PDF, setPDF} = useContext(PDFContext);



    const zoom = () => { return PDF.zoom } 
    const page = () => { return PDF.page } 
    const getShowAnnotation = () => { return PDF.showAnnotation }
    const setShowAnnotation = (annotation: string | null) => setPDF({...PDF, showAnnotation: annotation}) 
    const annotation = (): string |  null => { return PDF.annotation } 
    const setAnnotation = (annotation: string | null) =>  setPDF({...PDF, annotation: annotation})  
    const getCanvas = () => { return PDF.canvas }
 
  return {
    page, 
    zoom, 
    annotation,
    setAnnotation, 
    getShowAnnotation,
    setShowAnnotation,
    getCanvas,
    PDF,
    setPDF
  };
}

export default useGetPDFViewer;
