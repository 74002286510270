import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';

const queryKeys = {
  getRecoveryPackage: 'keysService.getRecoveryPackage',
};

const getRecoveryPackage = async () => {
  const response = await axiosInstance.get(`/account/recoveryPackage`);
  return response.data ?? [];
};

const useGetRecoveryPackage = () => {
  return useQuery(queryKeys.getRecoveryPackage, () => getRecoveryPackage(), {
    staleTime: 0,
    cacheTime: 0,
    retry: false,
  });
};

export interface ICreateRecoveryPackage {
  username: string;
  publicKey: string;
  recovery: string;

  intermediatePrivateKey?: string;
  intermediatePublicKey?: string;
}

export interface IKeysCreateObject {
  CaseId: string | null;
  PrivateKey: string;
  PublicKey: string;
}

export interface IKeysCreateObjectResponse {
  Id: string;
  ParentKeyId: string;
}

const createObject = (
  payload: IKeysCreateObject
): Promise<{ data: IKeysCreateObjectResponse }> => {
  return axiosInstance.post('/keys/object', payload);
};

const useCreateObject = () => {
  return useMutation(createObject);
};

const createRecoveryPackage = (
  payload: ICreateRecoveryPackage
): Promise<void> => {
  return axiosInstance.post('/account/recoveryPackage', payload);
};

const useCreateRecoveryPackage = () => {
  return useMutation(createRecoveryPackage);
};

export default {
  useGetRecoveryPackage,
  useCreateObject,
  useCreateRecoveryPackage,
};
