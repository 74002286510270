import { useState } from 'react';
import { FC, Props } from './typings';
import React from 'react';
import parse, { domToReact, DOMNode, Element, Text } from 'html-react-parser';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

const EmailViewer: FC<Props> = ({ sourceContent }) => {
  const [content, setContent] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [hasHiddenContent, setHasHiddenContent] = useState<boolean>(true);
  const toggleIsExpanded = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const parseEmailContent = (htmlContent: string, showOriginal: boolean) => {
    console.log(htmlContent);
    let hide = false;
    const options = {
      replace: (node: DOMNode) => {
        const patterns = [
          /-----Original Message-----/i,
          /----- Forwarded Message -----/i,
          /From:/i,
          /To:/i,
          /Subject:/i,
          /^-----.+ napisał.+----$/,
          /^W dniu .+ napisał.+$/,
          /^W dniu .+ pisze:$/,
        ];

        if (node.type === 'tag' && node.name === 'body') {
          node.name = 'div';
        }

        if (node.type === 'text') {
          if (patterns.some((pattern) => pattern.test(node.data))) {
            hide = true;
          }
        }

        if (node.type === 'tag' && (node.name === 'font' || node.name == 'p')) {
          var firstChild = node.childNodes[0];
          var lastChild = node.childNodes[node.childNodes.length - 1];
          if (firstChild.type === 'text' && lastChild.type === 'text') {
            if (
              patterns.some((pattern) =>
                pattern.test(
                  (firstChild as Text).data.replace('\n', '') +
                    ' ' +
                    (lastChild as Text).data.replace('\n', '')
                )
              )
            ) {
              hide = true;
            }
          }
        }

        if (node.type === 'tag' && node.attribs.class === 'moz-cite-prefix') {
          hide = true;
        }

        if (node.type === 'tag' && node.attribs.class === 'gmail_attr') {
          hide = true;
        }

        if (node.type === 'tag' && node.attribs.id === 'divRplyFwdMsg') {
          hide = true;
        }

        if (hide && !showOriginal) {
          return <></>;
        }

        if (node instanceof Element) {
          return (
            <>
              {domToReact(
                node.children.map((c) => c as DOMNode),
                options
              )}
            </>
          );
        }

        return node;
      },
    };

    var toReturn = parse(htmlContent, options);
    //setHasHiddenContent(true);

    return toReturn;
  };

  return (
    <div className={styles.body}>
      {parseEmailContent(sourceContent, isExpanded)}

      {hasHiddenContent && (
        <div
          onClick={() => toggleIsExpanded()}
          className={styles.showMoreButton}
          title={isExpanded ? 'Ukryj treść' : 'Pokaż więcej'}
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </div>
      )}
    </div>
  );
};

export default EmailViewer;
