import listService from 'api/listService';
import styles from './styles.module.scss';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { IPackage } from 'dto/IPackage';
import MessageCard from 'components/MessageCard';
import tagsService from 'api/tagsService';
import statusService from 'api/statusService';
import LetterCard from 'components/LetterCard';
import LettersContext from 'contexts/LettersContext';
import KeysContext from 'contexts/KeysContext';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { ICase } from 'dto/Cases/ICase';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const CaseLetters = ({
  isOutcoming,
  Case,
  selectedItems,
  setSelectedItems,
}: {
  isOutcoming?: boolean;
  onSelecting?: (files: IDossierItem[]) => void;
  handleAdd?: (files: IDossierItem[]) => void;
  className?: string;
  Case?: ICase | null | undefined;
  selectedItems?: IPackage[];
  setSelectedItems?: (file: IPackage[]) => void;
}) => {
  const { caseId } = useParams();
  const { data: lettersData, refetch } = listService.useGetListOfMessages({
    FilterId: '2D4779CE-8C2D-4E89-A9BD-EFE5C6423E8A',
    Group: true,
    IsIncoming: false,
    Page: 0,
    SortOrder: 'Descending',
    SortProp: 'SendDate',
    CaseId: Case?.Id ?? caseId,
  });

  const { data: tagsData } = tagsService.useGetTags();
  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const l: IPackage[] = useMemo(() => lettersData?.Items ?? [], [lettersData]);
  const objectKeys = useMemo(
    () => lettersData?.ObjectKeys ?? [],
    [lettersData]
  );
  const Keys = useMemo(() => lettersData?.Keys ?? [], [lettersData]);

  const { letters, setLetters } = useContext(LettersContext);
  const { keys, setKeys } = useContext(KeysContext);

  useEffect(() => {
    setLetters(l);
    setKeys([...keys, objectKeys, Keys].flat());
  }, [l]);

  return (
    <div className={styles.wrapper}>
      <DataGridPremium
        getRowHeight={() => 'auto'}
        columnHeaderHeight={0}
        hideFooter={true}
        rows={letters.map((l) => {
          //@ts-ignore
          l.id = l.Id;
          return l;
        })}
        columns={[
          {
            flex: 1,
            field: 'name',
            renderCell: (props) => {
              const letter = props.row as IPackage;
              return (
                <LetterCard
                  key={letter.Id}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  isOutcoming={isOutcoming}
                  statuses={statuses}
                  tags={tags}
                  msg={letter}
                  refetch={refetch}
                />
              );
            },
          },
        ]}
      />
      {/* {letters.map((letter) => (
        <LetterCard
          key={letter.Id}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          isOutcoming={isOutcoming}
          statuses={statuses}
          tags={tags}
          msg={letter}
          refetch={refetch}
        />
      ))} */}
    </div>
  );
};

export default CaseLetters;
