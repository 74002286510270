import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';

const queryKeys = {
  getCount: 'deskService.getCount',
};

const getCount = ({}) => {
  return axiosInstance.get(`/desk/counters`);
};

const useGetCount = () => {
  return useQuery(queryKeys.getCount, getCount);
};


interface IItem {
  Id: string;
  Kind: number;
}

export interface ISetDesk {
  Items: IItem[]
  Show: boolean;
}

const setDesk = (payload: ISetDesk) => {
  return axiosInstance.post('/Desk', payload)
}

const useSetDesk = () => {
  return useMutation(setDesk)
}


export default {
  useGetCount,
  useSetDesk
};
