import { Claims } from 'dto/User/claims';
import { User } from 'oidc-client-ts';

export const getUserClaim = (user: User, claim: Claims) => {
  const v = user?.profile[claim];
  if (v) {
    if (v instanceof Array) {
      return v[0];
    }
    return <string>v;
  } else {
    return '';
  }
};
