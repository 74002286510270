export enum IParcelTypes {
    Email = 'Email',
    NotaEncrypted = 'NotaEncrypted',
    EncryptedEDelivery = 'EncryptedEDelivery',
    EDelivery = 'EDelivery',
    TraditionalLetter = 'TraditionalLetter',

    CourtPortalDeliverance = 'CourtPortalDeliverance',
    CourtPortalDocument = 'CourtPortalDocument',
    Parcel = 'Parcel',
    EDeliveryMessage = 'EDeliveryMessage',
    CourtPortalApplication = 'CourtPortalApplication',
    ContactMessage = 'ContactMessage',
    OutgoingParcel = 'OutgoingParcel'

}