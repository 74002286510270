import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import Input from 'components/Input';
import { useEffect, useMemo, useState } from 'react';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import workspaceService from 'api/workspaceService';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import Dropdown from 'components/Dropdown';

const AddNewNode: FC<Props> = ({ onCreate, onCancel, workspaceStructures }) => {
  const [name, setName] = useState('');
  const [selectedStructureId, setSelectedStructureId] = useState<string | null>(
    null
  );

  const { mutate: createNode } = workspaceService.useCreateStructure();

  const handleCreate = () => {
    createNode(
      {
        Name: name,
        WorkspaceId: getWorkspaceId() ?? '',
        ParentId: selectedStructureId ?? undefined,
      },
      {
        onSuccess: () => {
          onCreate();
        },
      }
    );
  };

  const mappedStructure = useMemo(
    () =>
      workspaceStructures.map((m) => {
        return {
          name: m.Name,
          value: m.Id,
        };
      }) ?? [],
    [workspaceStructures]
  );

  // useEffect(() => {
  //   if (!mappedStructure?.[0].value) return;

  //   setSelectedStructureId(mappedStructure[0].value);
  // }, [mappedStructure]);

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Dodaj nową strukturę</div>

          <div className={styles.inputs}>
            <Input
              label="Nazwa struktury"
              value={name}
              onChange={(t) => setName(t)}
            />
            {!!mappedStructure.length && (
              <Dropdown
                disableChange
                list={[...mappedStructure, { name: 'Główna', value: null }]}
                onChange={(t) => {
                  setSelectedStructureId(t.value);
                }}
              />
            )}
          </div>

          <div className={styles.buttons}>
            <HollowButton onClick={() => onCancel()} text="Anuluj" />
            <Button onClick={handleCreate} text="Utwórz" />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AddNewNode;
