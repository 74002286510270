import styles from './styles.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Input from 'components/Input';
import Button from 'components/Button';
import dossierService from 'api/dossierService';
import { FC, useState } from 'react';
import { Props } from './typings';

const CreateFolder: FC<Props> = ({ caseId, parentId, toggle, refetch }) => {
  const [name, setName] = useState('');

  const { mutate: createFolder } = dossierService.useCreateFolder();

  const handleClickCreate = () => {
    createFolder(
      {
        CaseId: caseId,
        ParentId: parentId,
        Name: name,
      },
      {
        onSuccess: () => {
          refetch();
          toggle(false);
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Nowy folder
        <CloseIcon onClick={() => toggle(false)} className={styles.closeIcon} />
      </div>
      <div className={styles.content}>
        <Input
          placeholder="Nazwa folderu"
          label="Nazwa folderu"
          value={name}
          onChange={(text) => setName(text)}
        />
      </div>
      <div className={styles.footer}>
        <Button onClick={handleClickCreate} text="Utwórz" />
      </div>
    </div>
  );
};

export default CreateFolder;
