import deskService from 'api/deskService';
import listService from 'api/listService';
import messagesService, { IChangeHandle } from 'api/messagesService';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as CompleteTask } from 'assets/icons/check.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag_border.svg';
import { ReactComponent as HashIcon } from 'assets/icons/hash2.svg';
import { ReactComponent as TagIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as ReplyIcon } from 'assets/icons/reply.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send_purple.svg';
import { ReactComponent as SignIcon } from 'assets/icons/sign.svg';
import { ReactComponent as UnCompleteIcon } from 'assets/icons/unhandled.svg';
import { ReactComponent as RedirectIcon } from 'assets/icons/redirect.svg';
import { ReactComponent as SharedIcon } from 'assets/icons/share.svg';

import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';
import AddTagModal from 'components/AddTagModal';
import AttachCaseModal from 'components/AttachCaseModal';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import StatusModal from 'components/StatusModal';
import ModalsManagerContext from 'contexts/ModalsManager';
import { IPackage } from 'dto/IPackage';
import { RefObject, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import pluginService from 'api/pluginService';
import ConfirmModal from 'components/ConfirmModal';
import EditLetter from 'components/EditLetter';
import CertContext from 'contexts/CertContext';
import LettersContext from 'contexts/LettersContext';
import { ITag } from 'dto/ITag';
import { useNavigate, useParams } from 'react-router-dom';
import { ENotificationType, notification } from 'utils/notification';
import styles from './styles.module.scss';
import { RedirectModal } from 'components/RedirectModal/';
import { axiosInstance } from 'api/axios';
import { generateNewKey } from 'utils/geneareNewKey';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { useAuth } from 'react-oidc-context';
import { IWorkspaceMember } from 'dto/IWorkspace';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import { readAsBase64 } from 'utils/readAsBase64';
import KeysContext from 'contexts/KeysContext';
import { reEncrypt } from 'utils/reEncrypt';

const LetterOptionsCard = ({
  refWrapper,
  pack,
  showOptions,
  onClickAttachIcon,
  className = '',
  refetch,
  selectedElements,
  debug = false,
  isCase = false,
  binds = false,
}: {
  binds?: boolean;
  className?: string;
  refWrapper: RefObject<HTMLDivElement>;
  pack: IPackage;
  showOptions: boolean;
  selectedElements: IPackage[];
  onClickAttachIcon: (state: boolean) => void;
  refetch: () => void;
  debug?: boolean;
  isCase?: boolean;
}) => {
  const {
    caseId,
    filterId,
    status,
    kind,
    packageId,
    threadId,
    threadPackageId,
  } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { keys, setKeys } = useContext(KeysContext);
  const { mutate: redirect } = messagesService.useRedirect();
  const { mutate: share } = messagesService.useShare();
  const { setCert } = useContext(CertContext);
  const { mutate: changeHandle } = messagesService.useChangeHandle();
  const { mutate: openEditorPlugin } = pluginService.useOpenEditor();
  const { mutate: setDesk } = deskService.useSetDesk();
  const { mutate: deletePackage } = listService.useDeleteList();

  const [showActions, setShowActions] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const { letters, setLetters } = useContext(LettersContext);
  const [showFlags, setShowFlags] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showRedirect, setShowRedirect] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const { modals, setModals } = useContext(ModalsManagerContext);

  const handleClick = (e: any) => {
    if (e.code === 'Delete' && showOptions && binds) {
      handleDeletePackage();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleClick);

    return () => {
      window.removeEventListener('keydown', handleClick);
    };
  }, [showOptions, selectedElements]);

  if (!refWrapper?.current) return <></>;
  const position = refWrapper?.current?.getBoundingClientRect?.();
  const x = position.x + position.width / 2;
  const y = position.y - 14;

  const backToList = () => {
    if (selectedElements.length > 1) {
      selectedElements.forEach((p) => {
        if (
          packageId === p.Id ||
          threadId === p.Id ||
          threadPackageId === p.Id
        ) {
          navigate(`/letters/${filterId}/${status}`);
          return;
        }
      });

      return;
    }

    if (
      packageId === pack.Id ||
      threadId === pack.Id ||
      threadPackageId === pack.Id
    ) {
      navigate(`/letters/${filterId}/${status}`);
    }
  };

  const showAttachIcon = !pack.Case && pack.IsIncoming;
  // const showSomething = true;
  const getPosition = (refWrapper: RefObject<HTMLDivElement>) => {
    if (!refWrapper?.current) return { x: 0, y: 0, width: 0, height: 0 };
    const position = refWrapper?.current?.getBoundingClientRect?.();
    return {
      x: position.x ?? 0,
      y: position.y ?? 0,
      width: position.width ?? 0,
      height: position.height ?? 0,
    };
  };

  const handleClickComplete = () => {
    if (selectedElements.length > 1) {
      let data: IChangeHandle = {
        Handled: true,
        Items: selectedElements.map((s) => ({
          Id: s.Id,
          Kind: s.Kind,
        })),
      };

      let newPacks = [...letters];

      const newItem = newPacks.find((p) => p.Id === pack.Id);

      if (newItem) {
        newItem.IsHandled = !newItem.IsHandled;
      }

      if (!isCase) {
        newPacks = newPacks.filter(
          (p) => !selectedElements.find((s) => s.Id === p.Id)
        );
      }

      setLetters(newPacks);

      changeHandle(data, {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      });
      backToList();
      return;
    }

    let data: IChangeHandle = {
      Handled: !pack.IsHandled,
      Items: [{ Id: pack.Id, Kind: pack.Kind }],
    };

    let newPacks = [...letters];
    const newItem = newPacks.find((p) => p.Id === pack.Id);

    if (newItem) {
      newItem.IsHandled = !newItem.IsHandled;
    }

    if (!isCase) {
      newPacks = newPacks.filter((p) => p.Id !== pack.Id);
    }

    setLetters(newPacks);

    backToList();
    changeHandle(data, {
      onSuccess: () => refetch(),
      onError: () => {
        notification({
          type: ENotificationType.ERROR,
          title: 'Wystąpił błąd',
          text: 'Coś poszło nie tak... spróbuj ponownie później',
        });
      },
    });
  };

  const handleDeletePackage = () => {
    let newPacks = [...letters];
    let elementsToDelete = [];
    if (selectedElements.length > 1) {
      setLetters(
        newPacks.filter((p) => !selectedElements.find((a) => a.Id === p.Id))
      );

      elementsToDelete = selectedElements.map((m) => ({
        Id: m.Id,
        Kind: m.Kind,
      }));
    } else {
      setLetters(newPacks.filter((p) => p.Id !== pack.Id));
      elementsToDelete = [{ Id: pack.Id, Kind: pack.Kind }];
    }

    backToList();

    deletePackage(
      {
        Items: elementsToDelete,
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  const handleAddToDesk = () => {
    let newPacks = [...letters];
    let el = newPacks.find((p) => p.Id === pack.Id);

    if (el) {
      el.IsDeskActive = !el.IsDeskActive;
      setLetters(newPacks);
    }

    setDesk(
      {
        Items: [{ Id: pack.Id, Kind: pack.Kind }],
        Show: pack.IsDeskActive,
      },
      {
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Wystąpił błąd',
            text: 'Coś poszło nie tak... spróbuj ponownie później',
          });
        },
      }
    );
  };

  const handleClickReply = () => setModals({ ...modals, createPost: true });

  const handleCreateAction = () => {
    let newPacks = [...letters];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      el.TasksCount = el.TasksCount + 1;
      setLetters(newPacks);
    }
    refetch();
  };

  const handleDeleteAction = () => {
    let newPacks = [...letters];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      el.TasksCount = el.TasksCount - 1;
      setLetters(newPacks);
    }
    refetch();
  };

  const handleUpdateTags = (tags: ITag[]) => {
    let newPacks = [...letters];

    let el = newPacks.find((c) => c.Id === pack.Id && c.Kind === pack.Kind);

    if (el) {
      el.TagIds = tags.map((t) => t.Id);
    }
  };

  const openEditor = () => {
    openEditorPlugin({
      Id: pack.Id,
      Kind: pack.Kind,
    });
  };

  const openCert = () => {
    setCert({ id: pack.Id, name: pack.S1 });
    navigate(
      `/cases/${caseId}/letter/${pack.Kind}/${pack.Id}/${pack.S3}/${pack.EncryptionKeyId}`
    );
  };

  const openOutcoming = () => {
    localStorage.setItem('send_item', JSON.stringify(pack));
    setModals({ ...modals, createPost: true });
  };

  const handleRedirect = async (workspaceMember: IWorkspaceMember) => {
    const myKeys = await getKeys(userId!);
    const id = pack.Id;
    const Kind = pack.Kind;
    const encryptionKeyId = pack.EncryptionKeyId;

    if (!encryptionKeyId) {
      redirect(
        {
          Id: id,
          Kind: Kind,
          ToUserId: workspaceMember.UserId,
        },
        {
          onSuccess: () => {
            setShowRedirect(false);

            let newPacks = [...letters];

            newPacks = newPacks.filter((p) => p.Id !== pack.Id);

            setLetters(newPacks);
            backToList();
          },
        }
      );

      return;
    }

    const privateKey = await generateNewKey(encryptionKeyId, myKeys, keys);

    const uint = await reEncrypt(userId, workspaceMember.UserId, privateKey);
    const readyToSend = await readAsBase64(uint);

    redirect(
      {
        Id: id,
        Kind: Kind,
        ToUserId: workspaceMember.UserId,
        Keys: [{ Id: encryptionKeyId!, Key: readyToSend }],
      },
      {
        onSuccess: () => {
          setShowRedirect(false);

          let newPacks = [...letters];

          newPacks = newPacks.filter((p) => p.Id !== pack.Id);

          setLetters(newPacks);
          backToList();
        },
      }
    );
  };

  const handleShare = async (member: IWorkspaceMember) => {
    const myKeys = await getKeys(userId!);
    const id = pack.Id;
    const Kind = pack.Kind;
    const encryptionKeyId = pack.EncryptionKeyId;

    if (!encryptionKeyId) {
      share(
        {
          Id: id,
          Kind: Kind,
          ToUserId: member.UserId,
        },
        {
          onSuccess: () => {
            setShowShare(false);
          },
        }
      );

      return;
    }

    const privateKey = await generateNewKey(encryptionKeyId, myKeys, keys);
    const uint = await reEncrypt(userId, member.UserId, privateKey);
    const readyToSend = await readAsBase64(uint);

    share(
      {
        Id: id,
        Kind: Kind,
        ToUserId: member.UserId,
        Keys: [{ Id: encryptionKeyId!, Key: readyToSend }],
      },
      {
        onSuccess: () => {
          setShowShare(false);
          let newPacks = [...letters];
          let newPack = newPacks.find((p) => p.Id === pack.Id);
          if (!newPack) {
            return;
          }

          newPack.IsShared = true;

          setLetters(newPacks);
        },
      }
    );
  };

  const buildIcons = () => {
    if (selectedElements.length > 1) {
      return (
        <>
          {pack.IsHandled ? (
            <div className={styles.box}>
              <UnCompleteIcon
                title="Oznacz jako niegotowe"
                onClick={handleClickComplete}
              />
              Niegotowe
            </div>
          ) : (
            <div className={styles.box}>
              <CompleteTask
                title="Oznacz jako gotowe"
                onClick={handleClickComplete}
              />
              Gotowe
            </div>
          )}
          {!pack.IsDeskActive && (
            <div onClick={handleAddToDesk} className={styles.box}>
              <DeskIcon title="Przypnij" />
              Przypnij
            </div>
          )}
          <div className={styles.box}>
            <FlagIcon title="Ustaw status" onClick={() => setShowFlags(true)} />
            Ustaw status
          </div>
          <div className={styles.box}>
            <BinIcon title="Usuń" onClick={() => setShowDelete(true)} />
            Usuń
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.box}>
          <EditIcon
            title="Edytuj"
            onClick={() => {
              setEdit(!edit);
            }}
          />
          Edytuj
        </div>
        <div className={styles.box}>
          <TagIcon onClick={openEditor} />
          Otwórz edytor
        </div>

        {pack.S4 ? (
          <div className={styles.box}>
            <SendIcon onClick={openOutcoming} />
            Wyślij
          </div>
        ) : (
          <div className={styles.box}>
            <SignIcon onClick={openCert} />
            Podpisz
          </div>
        )}

        <div className={styles.box} onClick={handleAddToDesk}>
          <DeskIcon title="Przypnij" />
          {pack.IsDeskActive ? 'Odepnij' : 'Przypnij'}
        </div>
        <div className={styles.box}>
          <HashIcon
            title="Taguj"
            onClick={() => {
              setShowTags(!showTags);
              setShowActions(false);
              setShowAttach(false);
              setShowFlags(false);
            }}
          />
          Taguj
        </div>
        <div className={styles.box}>
          <FlagIcon
            onClick={() => {
              setShowFlags(true);
              setShowTags(false);
              setShowActions(false);
              setShowAttach(false);
            }}
            title="Ustaw status"
          />
          Ustaw status
        </div>
        {pack.Kind === 128 && (
          <div className={styles.box}>
            <ReplyIcon title="Odpowiedz" onClick={handleClickReply} />
            Odpowiedz
          </div>
        )}
        {pack.Kind === 128 && (
          <div className={styles.box}>
            <ReplyIcon
              className={styles.reverse}
              title="przekaż"
              onClick={handleClickReply}
            />
            Przekaż
          </div>
        )}
        <div className={styles.box}>
          <BinIcon title="Usuń" onClick={() => setShowDelete(true)} />
          Usuń
        </div>
        <div className={styles.box}>
          <RedirectIcon title="Przekaż" onClick={() => setShowRedirect(true)} />
          Przekaż
        </div>
        <div className={styles.box}>
          <SharedIcon
            style={{ stroke: 'black' }}
            title="Udostępnij"
            onClick={() => setShowShare(true)}
          />
          Udostępnij
        </div>
      </>
    );
  };

  return ReactDOM.createPortal(
    <>
      <div
        style={{
          left: `${x}px`,
          top: `${y - 3}px`,
        }}
        className={`${styles.options} ${
          !showOptions && styles.hide
        } ${className}`}
      >
        {buildIcons()}
      </div>
      {showTags && (
        <ModalLayout refWrapper={refWrapper}>
          <AddTagModal
            toggle={setShowTags}
            updateTags={handleUpdateTags}
            toTag={pack}
          />
        </ModalLayout>
      )}

      {showAttach && (
        <ModalLayout customX={-50} customY={-50} refWrapper={refWrapper}>
          <AttachCaseModal
            Package={pack}
            // position={{
            //   x: getPosition(refWrapper).x,
            //   y: getPosition(refWrapper).y + 45,
            // }}
            refWrapper={null}
            toggle={() => setShowAttach(false)}
          />
        </ModalLayout>
      )}
      {showFlags && (
        <>
          {selectedElements.length > 1 ? (
            <ModalLayout customX={0} customY={-30} refWrapper={refWrapper}>
              <StatusModal
                item={pack}
                toggle={setShowFlags}
                items={selectedElements}
                selectedStatus={null}
              />
            </ModalLayout>
          ) : (
            <ModalLayout customX={0} customY={-30} refWrapper={refWrapper}>
              <StatusModal
                item={pack}
                toggle={setShowFlags}
                selectedStatus={null}
              />
            </ModalLayout>
          )}
        </>
      )}
      {edit && (
        <ModalLayout refWrapper={refWrapper} dock={DockPosition.BottomLeft}>
          <EditLetter toggle={setEdit} refetch={refetch} file={pack} />
        </ModalLayout>
      )}
      {showDelete && (
        <ConfirmModal
          title="Potwierdź usunięcie"
          text="Wykonanie akcji usunięcia przesyłki nieodwracanie usunie przesyłkę z listy"
          acceptLabel="Usuń"
          cancelLabel="Anuluj"
          onAccept={() => {
            setShowDelete(false);
            handleDeletePackage();
          }}
          onCancel={() => setShowDelete(false)}
        />
      )}

      {showRedirect && (
        <RedirectModal
          onCancel={() => setShowRedirect(false)}
          // onSelect={handleRedirect}
          pack={pack}
        />
      )}
      {showShare && (
        <RedirectModal
          onCancel={() => setShowShare(false)}
          onSelect={handleShare}
          toggle={() => {
            setShowRedirect(false);
          }}
        />
      )}
    </>,
    document.body
  );
};

export default LetterOptionsCard;
