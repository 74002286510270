import casesService from 'api/casesService';
import tagsService from 'api/tagsService';
import AddButton from 'components/AddButton';
import InputWithRightIcon from 'components/InputWithRightIcon';
import TagBadge from 'components/TagBadge';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { ITag } from 'dto/ITag';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import styles from './styles.module.scss';
import { Props } from './typings';

const SearchCases: FC<Props> = ({
  setAddNewEntity,
  defaultValue,
  onChoose,
  className,
  settedCase,
  onBlur,
  isError = '',
  removeFocus = false,
}) => {
  const isCase = window.location.href.search('cases') !== -1;

  const [searchedCaseId, setSearchedCaseId] = useState<null | string>(null);
  const [stopSearchById, setStopSearchById] = useState(false);

  const ref = useRef(null);
  const [active, setActive] = useState(true);
  const [value, setValue] = useState('');
  const [searchList, setSearchList] = useState<ICaseSimple[]>([]);

  const [debouncedValue] = useDebounce(value, 300);

  const { data: casesData, refetch } = casesService.useGetSimpleCases({
    text: debouncedValue,
    id: searchedCaseId ? searchedCaseId : undefined,
  });

  const cases: ICaseSimple[] = useMemo(
    () => casesData?.data ?? [],
    [casesData]
  );

  const { data: tagsData } = tagsService.useGetTags();
  const tags: ITag[] = useMemo(() => tagsData?.data ?? [], [tagsData]);

  useEffect(() => {
    if (searchedCaseId && !stopSearchById) {
      //@ts-ignore
      const c = cases?.Result?.Items[0];
      if (c) {
        setSearchedCaseId(null);
        setValue(`${c.Client ? `${c.Client} |` : ''} ${c.Title}`);
        onChoose?.(c);
        setStopSearchById(true);
      }
    }

    if (settedCase) {
      setValue(
        `${settedCase.Client ? `${settedCase.Client} |` : ''} ${
          settedCase.Title
        }`
      );
      return;
    }

    if (defaultValue) {
      if (!cases) return;
      //@ts-ignore
      const c = cases?.Result?.Items?.find((Case) => Case.Id === defaultValue);
      if (c) {
        setValue(`${c.Client ? `${c.Client} |` : ''} ${c.Title}`);
        onChoose?.(c);
      }
    }

    if (isCase) {
      const packs = window.location.href.split('/');
      const index = packs.findIndex((t) => t === 'cases');
      const caseId = packs.at(index + 1);
      setSearchedCaseId(caseId!);
    }

    //@ts-ignore
    setSearchList(cases?.Result?.Items);
  }, [cases]);

  useEffect(() => {
    if (settedCase) {
      setValue(
        `${settedCase.Client ? `${settedCase.Client} |` : ''} ${
          settedCase.Title
        }`
      );
      return;
    }
  }, [settedCase]);

  useEffect(() => {
    // if (!value.length) {
    //   setSearchList(cases);
    //   return;
    // }

    // const filters = cases.filter(
    //   (c) =>
    //     c?.Client?.search(value) !== -1 ||
    //     c?.Title?.search(value) !== -1 ||
    //     c?.Signature?.search(value) !== -1
    // );

    //

    // setSearchList(filters);

    refetch();
  }, [debouncedValue]);

  const handleClick = (cas: ICaseSimple) => {
    setValue(`${cas.Client ? `${cas.Client} |` : ''} ${cas.Title}`);
    setActive(false);
    onChoose?.(cas);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target as Element)) {
        setActive(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const buildClient = (c: ICaseSimple) => {
    if (c.Signature && c.Client) {
      return `${c.Signature} | ${c.Client}`;
    }
    if (c.Signature && !c.Client) {
      return c.Signature;
    }

    if (!c.Signature && c.Client) {
      return c.Client;
    }

    return '';
  };

  const Tags = (c: ICaseSimple) => {
    const tagsIds = c.TagIds;

    const findedTags = tags.filter((tag) =>
      tagsIds.find((caseTag) => caseTag === tag.Id)
    );

    return findedTags.map((tag) => <TagBadge tag={tag} />);
  };

  return (
    <div ref={ref} className={`${styles.wrapper} ${className}`}>
      <InputWithRightIcon
        focus={removeFocus ? false : true}
        isError={isError}
        onClick={() => setActive(true)}
        value={value}
        onChange={(text) => setValue(text)}
        label="Teczki"
        onBlur={onBlur}
      />
      {active && (
        <div className={styles.list}>
          <div className={styles.listWrapper}>
            <div className={styles.add}>
              <AddButton
                className={styles.addButton}
                onClick={() => setAddNewEntity(true)}
                text="Utwórz nową teczke"
              />
            </div>
            {searchList?.map((c) => (
              <div
                onClick={() => handleClick(c)}
                key={c.Id}
                className={styles.box}
              >
                <div className={styles.client}>{c.Title}</div>
                <div className={styles.title}>
                  {c.Signature} {c.Client}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchCases;
