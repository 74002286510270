import { ICaseSimple } from "dto/Cases/ICaseSimple";
import { createContext } from "react";

type ICasesContext = {
    cases: ICaseSimple[];
    setCases: (cases: ICaseSimple[]) => void;
}

const CasesContext = createContext<ICasesContext>({
    cases: [],
    setCases: () => {}
})

export default CasesContext;