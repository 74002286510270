export type { FC } from 'react';

export enum ETabs {
    PLANS,
    SELECT,
    PACKAGES,
    FV
}

export interface Props {
  
}