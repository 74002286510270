import { Editor } from '@tinymce/tinymce-react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

const NoteClientModal: FC<Props> = ({ data, setData }) => {
  return (
    <div className={styles.editor}>
      <Editor
        apiKey="12k7ybb0o4007p3nah1s77v7gmrbalfhy4jreucu2nky2l3b"
        onInit={(evt, editor) => {
          // editorRef.current = editor;
          // setIsShowed(true);
        }}
        onEditorChange={(text) => {
          setData({ ...data, note: text });
        }}
        value={data.note ?? ''}
        init={{
          menubar: false,
          statusbar: false,
          resize: false,
          browser_spellcheck: true,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'imageTools',
            'quickbars',
          ],
          toolbar_persist: true,
          fixed_toolbar_container_target: document.body,
          contextmenu: 'table',
          contextmenu_never_use_native: true,
          images_upload_url: '',
          images_upload_handler: (): Promise<any> => {
            return new Promise((resolve) => {
              resolve(true);
            });
          },
          toolbar: `bold italic `,
          // content_style:
          //   'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; width: 100%; }',
        }}
      />
    </div>
  );
};

export default NoteClientModal;
