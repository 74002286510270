export enum EPrivileges {
    CanEditWorkspace = 'workspace.edit',
    CanEditWorkspaceStructure = 'workspace.structure.edit',
    CanViewWorkspaceStructure = 'workspace.structure.view',
    CanInviteMembersToWorkspace = 'workspace.members.invite',
    CanEditMembersInWorkspace = 'workspace.members.edit',
    CanEditRole = 'workspace.role.edit',
    CanViewRoles = 'workspace.role.view',
    canTaskAssign = 'workspace.task.assign'


}