import ModalsManagerContext from 'contexts/ModalsManager';
import { useContext, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as SettingsIcon } from 'assets/icons/settings_24px.svg';
import { ReactComponent as PersonalIcon } from 'assets/icons/personal.svg';
import { ReactComponent as EDeliveryIcon } from 'assets/icons/profile.svg';
import { ReactComponent as IntegrationIcon } from 'assets/icons/integration.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/team.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/settings_24px.svg';
import { ReactComponent as DiskIcon } from 'assets/icons/credit_card.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/fingerprint.svg';
import TabButton from '../TabButton';
import { render } from 'react-dom';
import BasicTab from '../BasicTab';
import PersonalTab from '../PersonalTab';
import DiskTab from '../DiskTab';
import IntegrationsTab from '../IntegrationsTab';
import SecurityTab from '../SecurityTab';
import EDeliveryTab from '../EDeliveryTab';
import CourtIntegrations from '../CourtIntegrations';
import ClassificationTab from '../ClassificationTab';
import WorkersTab from '../WorkersTab';
import { useAuth } from 'react-oidc-context';
import PackageSettings from '../PackageSettings';
import PaymentsTab from 'components/PaymentsTab';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import PermissionTab from '../PermissionTab';

export enum SettingsTabs {
  PersonalData,
  Workers,

  Permission,
  Basic,
  Integrations,
  Classification,
  PACKAGE,
  EDelivery,
  HardDrive,
  Security,
  Payments,
}

const SettingsModal: FC<Props> = ({ label, onClose, onUpdate }) => {
  const auth = useAuth();
  const isGuest = auth?.user?.profile?.role === 'guest';

  const { modals, setModals } = useContext(ModalsManagerContext);
  const [tab, setTab] = useState<SettingsTabs>(
    isGuest ? SettingsTabs.EDelivery : SettingsTabs.PersonalData
  );
  const [showTabBar, setShowTabBar] = useState<boolean>(true);

  const guestTabs = [
    {
      type: SettingsTabs.EDelivery,
      header: 'E-doręczenia',
      icon: EDeliveryIcon,
    },
    {
      type: SettingsTabs.Integrations,
      header: 'Integracje',
      icon: IntegrationIcon,
    },
  ];

  const tabs = [
    {
      type: SettingsTabs.PersonalData,
      header: 'Dane podstawowe',
      icon: PersonalIcon,
    },
    { type: SettingsTabs.Workers, header: 'Zespół', icon: TeamIcon },

    { type: SettingsTabs.Permission, header: 'Uprawnienia', icon: PaymentIcon },
    { type: SettingsTabs.Basic, header: 'Personalizacje', icon: SettingsIcon },
    {
      type: SettingsTabs.Integrations,
      header: 'Integracje',
      icon: IntegrationIcon,
    },
    {
      type: SettingsTabs.Classification,
      header: 'Tagi i Statusy',
      icon: IntegrationIcon,
    },
    {
      type: SettingsTabs.PACKAGE,
      header: 'Przesyłki szyfrowane',
      icon: EDeliveryIcon,
    },
    {
      type: SettingsTabs.EDelivery,
      header: 'E-doręczenia',
      icon: EDeliveryIcon,
    },

    { type: SettingsTabs.HardDrive, header: 'Dane w Nocie', icon: DiskIcon },
    {
      type: SettingsTabs.Security,
      header: 'Zabezpieczenia',
      icon: SecurityIcon,
    },
    { type: SettingsTabs.Payments, header: 'Płatności', icon: PaymentIcon },
  ];

  const handleCloseModal = () => {
    setModals({ ...modals, settings: false });
    onClose?.();
  };

  const handleTabClick = (tab: SettingsTabs) => {
    setTab(tab);
  };

  let activeTab = tabs.find((x) => x.type == tab);
  const ActiveTabIcon = tabs.find((x) => x.type == tab)!.icon;

  return (
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.titleWrapper}>
            <div className={styles.title}>
              <div className={styles.t}>
                {label ? label : 'Ustawienia'}

                {!isGuest && (
                  <div className={styles.subTitle}>
                    {<ActiveTabIcon className={styles.icon} />}
                    {tabs[tab].header}
                  </div>
                )}
              </div>
              <CloseIcon
                onClick={handleCloseModal}
                className={styles.closeIcon}
              />
            </div>
          </div>
          <div className={styles.layout}>
            {!isGuest && (
              <div className={styles.tabs}>
                {tabs.map((t) => (
                  <TabButton
                    title={t.header}
                    Icon={t.icon}
                    isActive={tab == t.type}
                    handleClick={() => handleTabClick(t.type)}
                  ></TabButton>
                ))}
              </div>
            )}

            {isGuest && (
              <div className={styles.tabs}>
                {guestTabs.map((t) => (
                  <TabButton
                    title={t.header}
                    Icon={t.icon}
                    isActive={tab == t.type}
                    handleClick={() => handleTabClick(t.type)}
                  ></TabButton>
                ))}
              </div>
            )}

            <div className={styles.content}>
              <div className={styles.tabContent}>
                {tab === SettingsTabs.Basic && <BasicTab></BasicTab>}
                {tab === SettingsTabs.PersonalData && (
                  <PersonalTab
                    showTabBar={(v) => setShowTabBar(v)}
                  ></PersonalTab>
                )}
                {tab === SettingsTabs.Workers && <WorkersTab />}
                {tab === SettingsTabs.PACKAGE && <PackageSettings />}
                {tab === SettingsTabs.HardDrive && <DiskTab></DiskTab>}
                {tab === SettingsTabs.Integrations && (
                  <IntegrationsTab
                    showTabBar={(v) => setShowTabBar(v)}
                  ></IntegrationsTab>
                )}

                {tab === SettingsTabs.Security && (
                  <SecurityTab
                    showTabBar={(v) => setShowTabBar(v)}
                  ></SecurityTab>
                )}
                {tab === SettingsTabs.EDelivery && (
                  <EDeliveryTab
                    showTabBar={(v) => setShowTabBar(v)}
                  ></EDeliveryTab>
                )}
                {tab === SettingsTabs.Classification && (
                  <ClassificationTab
                  // showTabBar={(v) => setShowTabBar(v)}
                  ></ClassificationTab>
                )}
                {tab === SettingsTabs.Payments && <PaymentsTab />}
                {tab === SettingsTabs.Permission && <PermissionTab />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
