import styles from './styles.module.scss';
import {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Loading from 'components/Loading';
import useGetCases from 'hooks/useGetCases';
import CaseCard from 'components/CaseCard';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { FC, Props } from './typings';
import CreateCaseModal from 'components/CreateCaseModal';
import CasesContext from 'contexts/CasesContext';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance } from 'api/axios';
import KeysContext from 'contexts/KeysContext';
import { useDebounce } from 'use-debounce';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import SortOrder from 'components/SortOrder';
import {
  EOrder,
  ETypeOfOrder,
  IOrderObject,
} from 'components/SortOrder/typings';
import Search from 'assets/icons/search.gif';

import { useParams } from 'react-router-dom';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

const CaseList: FC<Props> = ({
  tags,
  statuses,
  setSelectedEdit,
  searchText,
  selectedThread,
  setSelectedThread,
  createNewThread,
  setCreateNewThread,
  entityId = null,
  statusId = null,
  tagId = null,
}) => {
  const { caseId } = useParams();
  const { cases: casesContext, setCases: setCasesContext } =
    useContext(CasesContext);

  const container = useRef<HTMLDivElement>(null);

  const { keys, setKeys } = useContext(KeysContext);

  const [prevPage, setPrevPage] = useState<number | null>(null);
  const [nextPage, setNextPage] = useState(0);

  const [debouncedText] = useDebounce(searchText, 500);

  const { filterId } = useContext(WorkspaceContext);

  const [listFilter, setListFilter] = useState<IOrderObject>({
    type: ETypeOfOrder.CREATION_DATE,
    order: EOrder.DESCENDING,
    initalized: false,
  });

  const [forId, setForId] = useState<string | null | undefined>(caseId);

  const [lastSeen, setLastSeen] = useState<string | null>(null);

  const {
    data,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
    status,
  } = useInfiniteQuery(
    ['cases', listFilter, filterId, debouncedText, entityId, statusId, tagId],
    async ({ pageParam = 0 }) => {
      const query = new URLSearchParams(window.location.search);
      if (!!debouncedText.length) {
        query.append('Text', debouncedText);
      }

      if (filterId) {
        query.append('FilterId', filterId);
      }
      query.append('Page', pageParam);

      query.append('SortProp', listFilter.type);
      query.append('SortOrder', listFilter.order);

      if (statusId) {
        query.append('StatusId', statusId);
      }

      if (tagId) {
        query.append('TagId', tagId);
      }

      if (entityId) {
        query.append('ParticipantId', entityId);
      }

      if (forId) {
        query.append('PageForId', forId);
      }

      const res = await axiosInstance.get(`/cases?${query}`);
      return {
        items: res.data,
        page: res.data.Page,
        LastPage: res.data.LastPage,
        nextPage: res.data.Page + 1,
      };
    },

    {
      staleTime: 0,
      cacheTime: 0,
      enabled: false,
      getNextPageParam: (lastPage) => {
        if (!lastPage.LastPage) {
          return lastPage.page + 1;
        }
        return undefined;
      },
    }
  );

  // useEffect(() => {
  //
  //   if (page === 0) {
  //     refetch();
  //   }
  // }, [page]);

  useEffect(() => {
    if (data?.pages?.[0] && forId) {
      setForId(null);
    }
  }, [data]);

  useEffect(() => {
    if (!listFilter.initalized) return;

    setNextPage(0);
    setCasesContext([]);
    setTimeout(() => {
      refetch();
    }, 100);
  }, [listFilter]);

  useEffect(() => {
    refetch();
  }, [filterId]);

  useEffect(() => {
    setNextPage(0);

    setTimeout(() => {
      refetch();
    }, 100);
  }, [debouncedText, tagId, statusId, entityId]);

  const compileData = async () => {
    if (!data) return;

    setNextPage(data.pages[data.pages.length - 1].nextPage);
    if (!prevPage) {
      setPrevPage(data.pages[data.pages.length - 1].page - 1);
    }

    if (prevPage && data.pages[data.pages.length - 1].page <= prevPage) {
      setPrevPage(data.pages[data.pages.length - 1].page - 1);
    }
    const packs = await Promise.all(
      data?.pages
        .sort((a, b) => {
          return a.page - b.page;
        })
        .map((page) => {
          return page.items.Items;
        })
        .flat()
    );

    const OBKeys = await Promise.all(
      data?.pages
        .map((page) => {
          return page.items.ObjectKeys;
        })
        .flat()
    );

    const NKeys = await Promise.all(
      data?.pages
        .map((page) => {
          return page.items.Keys;
        })
        .flat()
    );

    setCasesContext(packs);
    // setKeys([...keys, OBKeys, NKeys].flat());
  };

  useEffect(() => {
    compileData();
  }, [data]);

  useEffect(() => {
    setCasesContext([]);
  }, [searchText]);

  const separatorRef = useRef<HTMLDivElement>(null);
  const [selectedElements, setSelectedElements] = useState<ICaseSimple[]>([]);
  const [canSelect, setCanSelect] = useState(false);

  const changeToSelectable = (e: any) => {
    if (e.code === 'ControlLeft' || e.code === 'ShiftLeft') {
      setCanSelect(true);
    }
  };

  const changeToNotSelectable = (e: any) => {
    setCanSelect(false);
  };
  useEffect(() => {
    window.addEventListener('keydown', changeToSelectable);
    window.addEventListener('keyup', changeToNotSelectable);

    return () => {
      window.removeEventListener('keydown', changeToSelectable);
      window.removeEventListener('keyup', changeToNotSelectable);
    };
  }, []);

  const separatorRef2 = useRef<HTMLDivElement>(null);

  const handleScroll = (e: any) => {
    if (!separatorRef.current) return;
    if (isLoading || isFetchingNextPage) return;
    var rect = separatorRef.current.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);

    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    if (isVisible) {
      fetchNextPage({
        pageParam: nextPage,
      });
    }
  };

  const handleWheel = (e: any) => {
    const c: any = container.current;

    if (!c) return;
    if (isLoading || isFetchingNextPage) return;

    const isLastPage = data?.pages.find((f) => f.page === 0);

    if (isLastPage) return;

    if (c.scrollTop === 0 && e.deltaY < 0) {
      setLastSeen(casesContext.at(0)?.Id ?? '');
      fetchNextPage({
        pageParam: prevPage,
      });
    }
  };

  useEffect(() => {
    const c = container.current;
    if (!c || !lastSeen) return;

    const divs = c.querySelectorAll('div[data-id]');
    const divWithIdOne = c.querySelector(`div[data-id="${lastSeen}"]`);

    if (divWithIdOne) {
      //@ts-ignore
      const position = divWithIdOne.offsetTop;
      c.scrollTop = position;
      setLastSeen(null);
    }
  }, [lastSeen, casesContext]);

  // const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   if (scrolled) return;

  //   const c = container.current;
  //   if (!c || !casesContext.length || isLoading) return;

  //   const divs = c.querySelectorAll('div[data-id]');
  //   const divWithIdOne = c.querySelector(`div[data-id="${caseId}"]`);

  //   if (divWithIdOne) {
  //     //@ts-ignore
  //     const position = divWithIdOne.offsetTop;
  //     // c.scrollTop = position;

  //     try {
  //       c.scrollTo({ top: position, behavior: 'smooth' });
  //     } catch (e) {}
  //     setScrolled(true);
  //   }
  // }, [casesContext, container, isLoading]);

  const scrollWrapper = useRef(null);

  const gridRef = useRef(null);

  useEffect(() => {
    if (!gridRef?.current) return;
    //@ts-ignore
    gridRef.current.selectRow(caseId, true);

    const index = casesContext.findIndex((c) => c.Id === caseId);
    //@ts-ignore
    const t = gridRef?.current?.scrollToIndexes(index);
  }, [gridRef, casesContext]);

  return (
    <div>
      <div className={styles.order}>
        <SortOrder
          isLoading={false}
          refetch={() => {
            // setNextPage(0);
            // setCasesContext([]);
            // setTimeout(() => {
            //   refetch();
            // }, 100);
          }}
          setListFilter={setListFilter}
          type={listFilter.type}
          order={listFilter.order}
        />
      </div>
      <div ref={scrollWrapper} onWheel={handleWheel} className={styles.wrapper}>
        <DataGridPremium
          sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
          //@ts-ignore
          apiRef={gridRef}
          getRowHeight={() => 'auto'}
          onRowsScrollEnd={() => {
            fetchNextPage();
          }}
          onRowSelectionModelChange={(props) => {
            if (props.length === 1) {
              setSelectedElements([]);
              return;
            }
            const elements = casesContext.filter((p) =>
              props.find((a) => p.Id === a)
            );
            setSelectedElements(elements);
          }}
          columnHeaderHeight={0}
          columns={[
            {
              flex: 1,
              field: 'name',
              renderCell: (props) => {
                const caseItem = props.row as ICaseSimple;

                return (
                  <CaseCard
                    selectedThread={selectedThread}
                    setSelectedThread={setSelectedThread}
                    key={caseItem.Id}
                    className={styles.card}
                    c={caseItem}
                    tags={tags}
                    statuses={statuses}
                    setSelectedEdit={setSelectedEdit}
                    isSelectable={canSelect}
                    selectedElements={selectedElements}
                    setSelectedElements={setSelectedElements}
                    createNewThread={createNewThread}
                    setCreateNewThread={setCreateNewThread}
                  />
                );
              },
            },
          ]}
          hideFooter={true}
          rows={casesContext.map((c) => {
            //@ts-ignore
            c.id = c.Id;
            return c;
          })}
        />
      </div>
    </div>
  );
};

export default CaseList;

{
  /* <CaseCard
selectedThread={selectedThread}
setSelectedThread={setSelectedThread}
key={caseItem.Id}
className={styles.card}
c={caseItem}
tags={tags}
statuses={statuses}
setSelectedEdit={setSelectedEdit}
isSelectable={canSelect}
selectedElements={selectedElements}
setSelectedElements={setSelectedElements}
createNewThread={createNewThread}
setCreateNewThread={setCreateNewThread}
/> */
}
