import { Route, Routes } from 'react-router-dom';
import DeskModule from '.';

const DeskRoute = () => {
  return (
    <Routes>
      <Route
        path="/:filterId/package/:type/:kind/:packageId"
        element={<DeskModule isDesk={true} />}
      />
      <Route
        path="/:filterId/case/:caseId/:view"
        element={<DeskModule isDesk={false} isCase={true} />}
      />
      <Route path="/:filterId" element={<DeskModule />} />
      <Route path="/*" element={<DeskModule />} />
    </Routes>
  );
};

export default DeskRoute;
