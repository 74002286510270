import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import SidebarFilter from 'components/SidebarFilter/index';
import { useContext, useMemo, useRef, useState } from 'react';
import workspaceStyle from './workspace.module.scss';
import styles from './styles.module.scss';

import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import casesService from 'api/casesService';
import draftService from 'api/draftService';
import filterService from 'api/filterService';
import { useOnClickOutside } from 'hooks/useClickOutside';
import workspaceService from 'api/workspaceService';
import { ReactComponent as CalendarIcon } from 'assets/icons/calenar_sidebar.svg';
import { ReactComponent as LetterIcon } from 'assets/icons/project.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/case_sidebar.svg';
import { ReactComponent as DriveIcon } from 'assets/icons/hard_drive.svg';
import { ReactComponent as IncomingIcon } from 'assets/icons/file_download.svg';
import { ReactComponent as OutgoingIcon } from 'assets/icons/file_upload.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as DraftIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as NotaIcon } from 'assets/icons/nota.svg';
import { ReactComponent as ClientIcon } from 'assets/icons/person.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings_24px.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/groups.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import SidebarAddingOptions from 'components/SidebarAddingOptions';
import SidebarButton from 'components/SidebarButton';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import ModalsManagerContext from 'contexts/ModalsManager';
import { IUserSwitch, IUserSwitchedReponse, IWorkspace } from 'dto/IWorkspace';
import ReactDOM from 'react-dom';
import { useAuth } from 'react-oidc-context';
import { setWorkspaceId } from 'utils/setWorkspaceId';

import { ReactComponent as OfficeIcon } from 'assets/icons/office.svg';
import Avatar from 'components/Avatar';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import { User, UserManager } from 'oidc-client-ts';
import { getUserClaim } from 'utils/getUserClaim';
import { Claims } from 'dto/User/claims';
import Button from 'components/Button';
import dossierService from 'api/dossierService';
import { ListType } from 'dto/ListType';

const WorkspaceDropdown = ({ toggle }: { toggle: () => void }) => {
  const auth = useAuth();
  const userManager = new UserManager(auth.settings);
  const { data: workspacesData } = workspaceService.useGetWorkspaces();

  const { mutate: switchWorkspace } = workspaceService.useWorkspaceSwitch();
  const { mutate: switchUser } = workspaceService.useSwitchUser();
  const { workspace: w, setWorkspace } = useContext(WorkspaceContext);
  const { data: switchesData } = workspaceService.useGetSwitches(w?.Id!);
  const workspaces: IWorkspace[] = useMemo(
    () => workspacesData?.data.Items ?? [],
    [workspacesData]
  );

  const switches: IUserSwitch[] = useMemo(
    () => switchesData?.data ?? [],
    [switchesData]
  );

  const changeWorkspace = (workspace: IWorkspace) => {
    setWorkspaceId(workspace.Id);
    switchWorkspace({
      Id: workspace.Id,
    });
    setWorkspace(workspace);
    toggle();
  };

  const changeUser = (target?: IUserSwitch) => {
    switchUser(
      { Id: target?.Id },
      {
        onSuccess: (response) => {
          handleSwitchUserData(response.data);
        },
      }
    );
  };

  const handleSwitchUserData = (data: IUserSwitchedReponse) => {
    userManager
      .storeUser(
        new User({
          access_token: data.AccessToken,
          refresh_token: data.RefreshToken,
          token_type: 'Bearer',
          profile: { sub: '', iss: '', aud: '', exp: 0, iat: 0 },
        })
      )
      .then(() => {
        auth.signinSilent();
      });
  };

  const outsideRef = useRef(null);
  const { modals, setModals } = useContext(ModalsManagerContext);

  useOnClickOutside(outsideRef, () => {
    toggle?.();
  });

  const [dropdown, setDropdown] = useState(false);

  const logout = () => {
    auth.signoutRedirect();
    sessionStorage.removeItem('workspaceId');
    localStorage.removeItem('workspaceId');
  };

  return ReactDOM.createPortal(
    <div className={workspaceStyle.dropdown}>
      <div className={workspaceStyle.user}>
        <Avatar name={auth.user?.profile?.name ?? ''} />
        <div className={workspaceStyle.cname}>
          <div className={workspaceStyle.ctitle}>
            {auth.user?.profile?.name}
          </div>
          <div className={workspaceStyle.company}>
            {workspaces.find((w) => w.Id === getWorkspaceId())?.Name ?? ''}
          </div>
        </div>
      </div>

      <div className={workspaceStyle.workspace}>
        <div
          onClick={() => {
            setDropdown(!dropdown);
          }}
          className={workspaceStyle.button}
        >
          <GroupIcon />
          Zespół
        </div>
        <FontAwesomeIcon
          className={`${workspaceStyle.icon} ${
            dropdown && workspaceStyle.transform
          }`}
          icon={faCaretRight}
        />

        {dropdown && (
          <div
            style={{
              left: 250,
              top: 84,
            }}
            className={styles.dropdown}
          >
            {workspaces.map((workspace) => (
              <div
                onClick={() => changeWorkspace(workspace)}
                className={`${styles.box} ${
                  workspace.Id === getWorkspaceId() && styles.active
                }`}
              >
                {workspace.Name}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={workspaceStyle.cProfiles}>
        <div className={workspaceStyle.cProfileTitle}>Profile</div>
        {switches.map((s) => (
          <div
            onClick={() => changeUser(s)}
            className={workspaceStyle.cProfile}
          >
            <Avatar className={workspaceStyle.cAvatar} name={s.Label} />
            {s.Label}
          </div>
        ))}
      </div>
      <div className={workspaceStyle.buttonsWrapper}>
        <SidebarButton
          title="Ustawienia"
          Icon={SettingsIcon}
          handleClick={() => {
            toggle();
            setModals({ ...modals, settings: true });
          }}
          showCounter={false}
          isActive={false}
        ></SidebarButton>

        <Button
          className={workspaceStyle.btn}
          text="Wyloguj"
          onClick={() => {
            logout();
            toggle();
          }}
        />
      </div>
    </div>,
    document.body
  );
};

const GuestMenu: any = [
  {
    id: 1,
    fullId: '1B8AE8D3-3209-497D-9526-27608FFFA84D',
    icon: IncomingIcon,
    title: 'Przychodzące',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },
  {
    id: 2,
    fullId: 'sending',
    icon: OutgoingIcon,
    title: 'Wychodzące',
    isOpen: false,
    filters: [],
    filtersHardCoded: false,
    canAddNewFilter: true,
    showCounter: false,
    hideExpander: false,
  },
  // {
  //   id: 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99',
  //   fullId: 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99',
  //   icon: DraftIcon,
  //   title: 'Robocze',
  //   isOpen: false,
  //   filters: [
  //     {
  //       Id: '675d78ff-983d-4b0e-a334-67699448572a',
  //       uniqName: 'edelivery',
  //       Name: 'Nota',
  //       counter: 0,
  //       isIncoming: false,
  //       Static: true,
  //     },
  //     {
  //       Id: '027bdcf6-232b-4355-a396-2558c2c30f23',
  //       uniqName: 'email',
  //       Name: 'Email',
  //       counter: 0,
  //       isIncoming: false,
  //       Static: true,
  //     },
  //   ],
  //   filtersHardCoded: true,
  //   canAddNewFilter: false,
  //   showCounter: true,
  // },
  {
    id: 3,
    icon: CaseIcon,
    title: 'Teczki',
    name: 'cases',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },
];

const ActiveMenu: any = [
  {
    id: 'd76ad393-712b-4984-968a-f104579d6fa9',
    fullId: 'd76ad393-712b-4984-968a-f104579d6fa9',
    icon: HomeIcon,
    title: 'Biurko',
    isOpen: false,
    isDesk: true,
    filters: [
      {
        Id: 'c555b900-860a-4628-80ab-084d01076275',
        uniqName: 'incoming',
        Name: 'Przychodzące',
        counter: 0,
      },
      {
        Id: '9718808f-dcb8-4a07-a04c-f9de36b266ca',
        uniqName: 'outgoing',
        Name: 'Wychodzace',
        counter: 0,
        isIncoming: false,
      },
      {
        Id: 'd902a02c-16fa-4f88-8c0b-8f6c2f36ec71',
        uniqName: 'cases',
        Name: 'Teczki',
        counter: 0,
      },
    ],
    filtersHardCoded: true,
    canAddNewFilter: false,
    showCounter: true,
  },
  {
    id: 1,
    fullId: '1B8AE8D3-3209-497D-9526-27608FFFA84D',
    icon: IncomingIcon,
    title: 'Przychodzące',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },
  {
    id: 2,
    fullId: 'sending',
    icon: OutgoingIcon,
    title: 'Wychodzące',
    isOpen: false,
    filters: [],
    filtersHardCoded: false,
    canAddNewFilter: true,
    showCounter: false,
    hideExpander: false,
  },
  // {
  //   id: 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99',
  //   fullId: 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99',
  //   icon: DraftIcon,
  //   title: 'Robocze',
  //   isOpen: false,
  //   filters: [
  //     {
  //       Id: '675d78ff-983d-4b0e-a334-67699448572a',
  //       uniqName: 'edelivery',
  //       Name: 'Nota',
  //       counter: 0,
  //       isIncoming: false,
  //       Static: true,
  //     },
  //     {
  //       Id: '027bdcf6-232b-4355-a396-2558c2c30f23',
  //       uniqName: 'email',
  //       Name: 'Email',
  //       counter: 0,
  //       isIncoming: false,
  //       Static: true,
  //     },
  //   ],
  //   filtersHardCoded: true,
  //   canAddNewFilter: false,
  //   showCounter: true,
  // },
  // {
  //   id: 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99',
  //   fullId: 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99',
  //   icon: LetterIcon,
  //   title: 'Pisma',
  //   isLetter: true,
  //   isOpen: false,
  //   filters: [
  //     {
  //       Id: 'szablony',
  //       uniqName: 'szablony',
  //       Name: 'Szablony',
  //       counter: 0,
  //       isIncoming: false,
  //       Static: true,
  //       showCounter: false,
  //     },
  //   ],
  //   filtersHardCoded: true,
  //   canAddNewFilter: false,
  //   showCounter: false,
  // },

  {
    id: 3,
    icon: CaseIcon,
    title: 'Teczki',
    name: 'cases',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },
  {
    id: 7,
    icon: DriveIcon,
    title: 'Dysk',
    name: 'drive',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },

  {
    id: 6,
    fullId: 'clients',
    icon: ClientIcon,
    title: 'Podmioty',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },
  {
    id: 4,
    icon: CalendarIcon,
    title: 'Czynności',
    name: 'actions',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },

  {
    id: 8,
    icon: ShareIcon,
    title: 'Udostępnienia',
    name: 'shares',
    isOpen: false,
    filters: [],
    canAddNewFilter: true,
    showCounter: false,
  },
];

const Sidebar = ({
  setIsScroll,
}: {
  setIsScroll?: (state: boolean) => void;
}) => {
  //const { user } = useContext(UserContext);
  const auth = useAuth();
  const isGuest = auth?.user?.profile?.role === 'guest';
  const { mutate: fetchFilters, isLoading } =
    filterService.useGetFiltersForCategory();

  const { mutate: fetchCasesFilters } = casesService.useGetFiltersForCases();
  const { mutate: fetchDossierFilters } = dossierService.useGetFilters();

  // const { mutate: getDeskCounter } = deskService.useGetCount();
  const { mutate: getDraftCounter } = draftService.useGetCount();
  const { modals, setModals } = useContext(ModalsManagerContext);

  const [menus, setMenus] = useState(isGuest ? GuestMenu : ActiveMenu);

  const getFilters = (id: number | string) => {
    const menu = menus.find((menu: any) => menu.id === id);

    if (!menu) {
      return;
    }
    if (menu.showCounter) {
      if (id === 'd76ad393-712b-4984-968a-f104579d6fa9') {
        // getDeskCounter(
        //   {},
        //   {
        //     onSuccess: (data) => {
        //       const newMenu = [...menus].map((menu) => {
        //         if (menu.id === id) {
        //           let incoming = menu.filters.find(
        //             (filter) => filter.uniqName === 'incoming'
        //           );
        //           let outgoing = menu.filters.find(
        //             (filter) => filter.uniqName === 'outgoing'
        //           );
        //           let cases = menu.filters.find(
        //             (filter) => filter.uniqName === 'cases'
        //           );

        //           if (!incoming || !outgoing || !cases) {
        //             return menu;
        //           }

        //           incoming.counter = data.data.incoming;
        //           outgoing.counter = data.data.outgoing;
        //           cases.counter = data.data.cases;
        //         }

        //         return menu;
        //       });
        //       setMenus(newMenu);
        //     },
        //   }
        // );
        return;
      }

      if (id === 'A666CC3E-A2AF-49AA-AB95-54DA6CB4CC99') {
        getDraftCounter(
          {},
          {
            onSuccess: (data) => {
              const newMenu = [...menus].map((menu) => {
                if (menu.id === id) {
                  let incoming = menu.filters.find(
                    (filter: any) => filter.uniqName === 'edelivery'
                  );
                  let outgoing = menu.filters.find(
                    (filter: any) => filter.uniqName === 'email'
                  );
                  let cases = menu.filters.find(
                    (filter: any) => filter.uniqName === 'editorDocument'
                  );

                  if (!incoming || !outgoing || !cases) {
                    return menu;
                  }

                  incoming.counter = data.data.edelivery;
                  outgoing.counter = data.data.email;
                  cases.counter = data.data.editorDocument;
                }

                return menu;
              });
              setMenus(newMenu);
            },
          }
        );
      }
    }

    if (menu.filtersHardCoded) {
      return;
    }

    if (menu.hideExpander) {
      return;
    }

    if (id === 3) {
      fetchCasesFilters(
        {},
        {
          onSuccess: (data) => {
            let newMenus = [...menus].map((menu) => {
              if (menu.id === id) {
                menu.filters = data.data;
              }
              return menu;
            });

            setMenus(newMenus);
          },
        }
      );

      return;
    }

    if (id === 7) {
      fetchDossierFilters(
        { ListType: ListType.Drive },
        {
          onSuccess: (data) => {
            let newMenus = [...menus].map((menu) => {
              if (menu.id === id) {
                menu.filters = data.data;
              }
              return menu;
            });

            setMenus(newMenus);
          },
        }
      );

      return;
    }

    fetchFilters(
      {
        id: id,
      },
      {
        onSuccess: (data) => {
          let newMenus = [...menus].map((menu) => {
            if (menu.id === id) {
              menu.filters = data.data;
            }
            return menu;
          });

          setMenus(newMenus);
        },
      }
    );
  };
  const testRef = useRef(null);
  const testRef2 = useRef(null);

  const checkSize = () => {
    if (!testRef?.current) return;
    if (!testRef2?.current) return;

    //@ts-ignore
    const maxScroll = testRef?.current.scrollHeight;
    //@ts-ignore
    const actualScroll = testRef2?.current.scrollHeight + 100 + 60;

    if (actualScroll >= maxScroll) {
      setIsScroll?.(true);
      return;
    }
    setIsScroll?.(false);
  };

  const handleChangeFilterState = (title: string) => {
    let newMenu = [...menus].map((menu) => {
      if (title === menu.title) {
        menu.isOpen = !menu.isOpen;
        if (!!menu.filters && menu.isOpen) {
          getFilters(menu.id);
        }
      }
      return menu;
    });

    setMenus(newMenu);
    setTimeout(() => {
      checkSize();
    }, 100);
  };

  const handleSettingsClick = () => {
    setModals({ ...modals, settings: true });
  };

  const addButtonRef = useRef(null);

  const [isHoverSettings, setIsHoverSettings] = useState<boolean>(false);

  const [options, setOptions] = useState(false);

  const { workspace } = useContext(WorkspaceContext);

  const [showList, setShowList] = useState(false);

  return (
    <div ref={testRef} className={styles.sidebar}>
      <div className={styles.details}>
        <div
          onMouseEnter={() => setIsHoverSettings(true)}
          onMouseLeave={() => setIsHoverSettings(false)}
          className={styles.name}
        >
          <div className={styles.leftName}>
            <span className={styles.nameWrapper}>
              {auth.user?.profile.name}
            </span>
            <FontAwesomeIcon
              onClick={() => setShowList(!showList)}
              style={{ marginLeft: '4px' }}
              className={styles.expander}
              icon={showList ? faCaretUp : faCaretDown}
            />
          </div>
          {/* {isHoverSettings ? <SettingsHoverIcon /> : <SettingsIcon />}{' '} */}

          <div className={styles.add}>
            {isGuest ? (
              <div
                onClick={() => {
                  if (isGuest) {
                    setModals({ ...modals, createPost: true });
                    return;
                  }
                }}
                className={styles.we}
              >
                Utwórz
              </div>
            ) : (
              <AddIcon
                ref={addButtonRef}
                onClick={() => {
                  if (isGuest) {
                    setModals({ ...modals, createPost: true });
                    return;
                  }

                  setOptions(!options);
                }}
                className={styles.addIcon}
              />
            )}
            {options && (
              <SidebarAddingOptions
                addButtonRef={addButtonRef}
                toggle={setOptions}
              />
            )}
          </div>
        </div>
      </div>
      {
        <div className={styles.workspacesWrapper}>
          <div
            onClick={() => setShowList(!showList)}
            className={styles.workName}
          >
            <div className={styles.wname}>{workspace?.Name}</div>
          </div>
          {showList && <WorkspaceDropdown toggle={() => setShowList(false)} />}
        </div>
      }
      {auth.user &&
        getUserClaim(auth.user, Claims.UserIsSwitchedToAnotherUser) && (
          <div className={styles.workspacesWrapper}>
            jako: {getUserClaim(auth.user, Claims.UserSwitchedToUserLabel)}
          </div>
        )}
      <div className={styles.list}>
        <div ref={testRef2} className={styles.listWrapper}>
          {menus.map((menu: any) => (
            <SidebarFilter
              testRef={testRef}
              showCounter={menu.showCounter}
              getFilters={getFilters}
              id={menu.id}
              isDesk={menu.isDesk ?? false}
              key={menu.title}
              isOpen={menu.isOpen}
              title={menu.title}
              name={menu.name ?? 'no'}
              fullId={menu.fullId}
              isLetter={menu.isLetter ?? false}
              filters={menu.filters}
              filtersHardCoded={menu.filtersHardCoded ?? false}
              canAddNewFilter={menu.canAddNewFilter}
              hideExpander={menu.hideExpander ?? false}
              Icon={menu.icon}
              handleChangeFilterState={handleChangeFilterState}
            />
          ))}
        </div>
      </div>
      {/* <div className={styles.secondList}>
        <SidebarButton
          title="Ustawienia"
          Icon={SettingsIcon}
          handleClick={handleSettingsClick}
          showCounter={false}
          isActive={false}
          ></SidebarButton>
        <SidebarButton
          title="Wyloguj"
          Icon={LogoutIcon}
          handleClick={logout}
          showCounter={false}
          isActive={false}
        ></SidebarButton>
      </div> */}
      <div className={styles.logo}>
        <div className={styles.lite}></div>
        <NotaIcon />
      </div>
    </div>
  );
};

export default Sidebar;
