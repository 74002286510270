import Loading from 'components/Loading';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IPackage } from 'dto/IPackage';
import useGetPackages from 'hooks/useGetPackages';
import statusService from 'api/statusService';
import tagsService from 'api/tagsService';
import MessageCard from 'components/MessageCard';
import useGetCases from 'hooks/useGetCases';
import { useInfiniteQuery } from 'react-query';
import { axiosInstance } from 'api/axios';
import PackagesContext from 'contexts/PackagesContext';
import KeysContext from 'contexts/KeysContext';
import { TypeOfView } from 'components/MarkedDeskWindow/typings';

const DeskPackages = ({ type }: { type: TypeOfView }) => {
  const filterId =
    type === TypeOfView.INCOMING
      ? 'c555b900-860a-4628-80ab-084d01076275'
      : '9718808f-dcb8-4a07-a04c-f9de36b266ca';

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const separatorRef = useRef<HTMLDivElement>(null);

  const { packages, setPackages } = useContext(PackagesContext);
  const { keys, setKeys } = useContext(KeysContext);

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery(
      ['deskPackages', filterId],
      async ({ pageParam = '' }) => {
        const query = new URLSearchParams(window.location.search);

        query.append('Group', 'true');
        query.append(
          'FilterId',
          filterId ?? 'd76ad393-712b-4984-968a-f104579d6fa9'
        );

        query.append(
          'IsIncoming',
          filterId === '9718808f-dcb8-4a07-a04c-f9de36b266ca' ? 'false' : 'true'
        );
        query.append('SortProp', 'DeskGroupId');
        query.append('SortOrder', 'Ascending');
        query.append('Desk', 'true');

        const res = await axiosInstance.get(`/list?${query}`);
        return res.data;
      },
      {
        getNextPageParam: (lastPage) => {
          if (!lastPage.LastPage) {
            return lastPage.PageToken;
          }
          return undefined;
        },
      }
    );

  const compileData = async () => {
    if (!data) return;
    const packs = await Promise.all(
      data?.pages
        .map((page) => {
          return page.Items;
        })
        .flat()

      // .sort((left: IPackage, right: IPackage) => {
      //   //@ts-ignore
      //   return right.Unread - left.Unread;
      // })
    );

    const keys = await Promise.all(
      data?.pages
        .map((page) => {
          return page.ObjectKeys;
        })
        .flat()
    );

    setPackages(packs);
    setKeys(keys);
  };

  useEffect(() => {
    compileData();
  }, [data]);

  const { data: tagsData } = tagsService.useGetTags();
  const { data: statusesData } = statusService.useGetStatuses();

  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const tags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const handleClickPackage = (pack: IPackage) => {
    window.open(
      `${
        process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'
      }/packages/fullscreen/${pack.Id}/${pack.Kind}`
    );
  };

  const handleScroll = (e: any) => {
    if (!separatorRef.current) return;
    if (isLoading) return;
    var rect = separatorRef.current.getBoundingClientRect();
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);

    if (isVisible) {
      //   setPage(page + 1);
    }
  };

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.title}>Przychodzące</div> */}
      <div onScroll={handleScroll} className={styles.packageList}>
        {isLoading && <Loading />}
        {!isLoading &&
          packages
            .filter((p) => p.IsDeskActive)
            .map((msg: IPackage) => (
              <MessageCard
                key={msg.Id}
                onDownload={() => {}}
                onPreview={() => {}}
                keys={keys}
                onClick={() => handleClickPackage(msg)}
                msg={msg}
                statuses={statuses}
                tags={tags}
              />
            ))}
        {isFetchingNextPage && (
          <div className={styles.loadingWrapper}>
            <Loading />
          </div>
        )}
        <div ref={separatorRef} style={{ height: '5px' }}></div>
      </div>
    </div>
  );
};

export default DeskPackages;
