
export const getSizeOfImage = async (file: Blob) => {
    return new Promise((resolve) => {

        var reader = new FileReader();
        
        reader.onload = function (e) {
            //@ts-ignore
            var blobUrl = e.target.result;
            
            // Tworzenie obiektu Image
            var obraz = new Image();
            //@ts-ignore
            obraz.src = blobUrl;
            
    obraz.onload = function () {
        var szerokosc = obraz.width;
        var wysokosc = obraz.height;
        
        resolve({
            w: szerokosc,
            h: wysokosc
        })
        // 
        };
    };

    //@ts-ignore
    reader.readAsDataURL(file);
    })
}