import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from 'components/Input';
import SearchStatuses from 'components/SearchStatuses';
import SearchEntities from 'components/SearchEntities';
import { useCallback, useEffect, useRef, useState } from 'react';
import TagBadge from 'components/TagBadge';
import Button from 'components/Button';
import EntityCard from 'components/EntityCard';
import AddButton from 'components/AddButton';
import { useContext } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { IRecoveryPackage } from 'dto/IRecoveryPackage';
import keysService, { ICreateRecoveryPackage } from 'api/keysService';
import { response } from 'express';
import { async } from 'q';
import CryptoJS from 'crypto-js';
import { jsPDF } from 'jspdf';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import YouTube from 'react-youtube';
import HollowButton from 'components/HollowButton';
import activationService from 'api/activationService';
import Dropzone, { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import { tr } from 'date-fns/locale';
import { IError } from 'dto/IError';
import { getErrorMessage } from 'utils/getErrorMessage';
import { IMobywatelQRCodeResponse } from 'dto/Activation/IMobywatelQRCodeResponse';
import { ENotificationType, notification } from 'utils/notification';
import { QRCodeCanvas } from 'qrcode.react';
import ReactDOM from 'react-dom';
import { SignalRContext } from './../../../App';
import { HubCallbacksNames, IUserIdentified } from 'api/hub';
import { ReactComponent as SignatureIcon } from 'assets/icons/signature.svg';
import { ReactComponent as MobywatelIcon } from 'assets/icons/pi.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_24.svg';
import { getUserClaim } from 'utils/getUserClaim';
import { Claims } from 'dto/User/claims';

export enum IdentityMethod {
  Signature = 'signature',
  LoginGov = 'loginGov',
  Mobywatel = 'mObywatel',
}

export enum IdentityStep {
  DownloadStatement = 'downloadStatement',
  UploadStatement = 'uploadStatement',
  MObywatelQRCode = 'mobywatelQRCode',
  Success = 'success',
}

const Identity: FC<Props> = ({ nextStep }) => {
  const auth = useAuth();
  const userId = getUserId(auth.user!);

  SignalRContext.useSignalREffect(
    HubCallbacksNames.UserIdentified,
    (message: IUserIdentified) => {
      if (message.success) {
        auth.revokeTokens();
        auth.signinSilent();
        setErrorMessage('');
        setStep(IdentityStep.Success);
      } else {
        notification({
          title: 'Nie udało się zweryfikować',
          text: message.message,
          type: ENotificationType.ERROR,
        });
      }
    },
    []
  );

  const [personalData, setPersonalData] = useState<{
    firstName: string;
    lastName: Nullable<string>;
    pesel: Nullable<string>;
  }>({
    firstName: getUserClaim(auth.user!, Claims.Eidas_FirstName),
    lastName: getUserClaim(auth.user!, Claims.Eidas_FamilyName),
    pesel: getUserClaim(auth.user!, Claims.Eidas_PersonIdentifier),
  });

  const [method, setMethod] = useState<IdentityMethod | null>(null);
  const [step, setStep] = useState<IdentityStep | null>(null);
  const [prevButtonState, setPrevButtonState] = useState({
    show: true,
    label: 'Cofnij',
    disabled: false,
  });

  const [nextButtonState, setNextButtonState] = useState({
    show: false,
    label: '',
    disabled: false,
  });
  const [statement, setStatement] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [qrCodeData, setQRCodeData] = useState<string>('');

  const { mutate: getStatement, isLoading: isLoadingStatement } =
    activationService.useGetStatement();

  const { mutate: uploadStatement, isLoading: isUploadingStatement } =
    activationService.useUploadStatement();

  const { mutate: getMobywatelQRCode, isLoading: isLoadingQRCode } =
    activationService.useGetMObywatelQRcode();

  const nextStepInternal = () => {
    switch (step) {
      case IdentityStep.DownloadStatement:
        downloadStatement();
        break;
      case IdentityStep.UploadStatement:
        break;
      case IdentityStep.Success:
        nextStep?.();
        break;
    }
  };

  const prevStepInternal = () => {
    setMethod(null);
  };

  const saveFile = async (blob: Blob) => {
    const a = document.createElement('a');
    a.download = 'oswiadczenie_nota.pdf';
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const downloadStatement = () => {
    getStatement(
      {},
      {
        onSuccess(data, variables, context) {
          saveFile(data.data);
          setStep(IdentityStep.UploadStatement);
        },
      }
    );
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    setStatement(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'application/pdf': ['.pdf'] },
      onDrop: onDrop,
      multiple: false,
    });

  const initMObywatelQRCode = () => {
    getMobywatelQRCode(
      {},
      {
        onSuccess(data) {
          let resp = data.data as IMobywatelQRCodeResponse;
          setQRCodeData(resp.Raw);
        },
        onError(e: any) {
          notification({
            title: 'Wystąpił błąd ',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
        },
      }
    );
  };

  useEffect(() => {
    switch (method) {
      case IdentityMethod.Signature:
        setStep(IdentityStep.DownloadStatement);
        setNextButtonState({ ...nextButtonState, disabled: false, show: true });
        setPrevButtonState({
          ...prevButtonState,
          disabled: false,
          show: false,
        });
        break;
      case IdentityMethod.Mobywatel:
        setStep(IdentityStep.MObywatelQRCode);
        setNextButtonState({
          ...nextButtonState,
          disabled: false,
          show: false,
        });
        setPrevButtonState({ ...prevButtonState, disabled: false, show: true });
        initMObywatelQRCode();
        break;
      default:
        setNextButtonState({
          ...nextButtonState,
          disabled: false,
          show: false,
        });
        setPrevButtonState({
          ...prevButtonState,
          disabled: false,
          show: false,
        });
    }
  }, [method]);

  useEffect(() => {
    switch (step) {
      case IdentityStep.DownloadStatement:
        setNextButtonState({
          ...nextButtonState,
          disabled: false,
          show: true,
          label: 'Pobierz oświadczenie',
        });
        break;
      case IdentityStep.UploadStatement:
        setNextButtonState({
          ...nextButtonState,
          disabled: true,
          show: true,
          label: 'Weryfikuj oświadczenie',
        });
        break;
      case IdentityStep.Success:
        setNextButtonState({
          ...nextButtonState,
          disabled: false,
          show: true,
          label: 'Dalej',
        });
        setPrevButtonState({
          ...prevButtonState,
          show: false,
        });
        break;
    }
  }, [step]);

  useEffect(() => {
    if (statement) {
      uploadStatement(statement, {
        onSuccess: () => {
          auth.signinSilent();
          setErrorMessage('');
          setStep(IdentityStep.Success);
          setNextButtonState({
            ...nextButtonState,
            disabled: false,
            show: true,
            label: 'Dalej',
          });
        },
        onError: (e: any) => {
          setErrorMessage(getErrorMessage(e.response.data));
          setStatement(null);
        },
      });
    }
  }, [statement]);

  useEffect(() => {
    if (qrCodeData && document.getElementById('qrcode')) {
      ReactDOM.render(
        <QRCodeCanvas value={qrCodeData} size={256} />,
        document.getElementById('qrcode')
      );
    }
  }, [qrCodeData]);

  useEffect(() => {
    if (auth.user) {
      setPersonalData((prevState) => ({
        ...prevState,
        firstName: getUserClaim(auth.user!, Claims.Eidas_FirstName),
        lastName: getUserClaim(auth.user!, Claims.Eidas_FamilyName),
        pesel: getUserClaim(auth.user!, Claims.Eidas_PersonIdentifier),
      }));
    }
  }, [auth.user]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {step === IdentityStep.Success
          ? 'Zweryfikowano pomyślnie!'
          : 'Weryfikacja tożsamości'}
      </div>
      {!method && (
        <>
          <div className={styles.hint}>
            <b>
              Aby aktywować konto w Nocie konieczna jest weryfikacja tożsamości.
            </b>{' '}
            Wybierz sposób weryfikacji, aby kontynuować.
          </div>
          <div className={styles.methods}>
            <div
              className={styles.method}
              onClick={() => setMethod(IdentityMethod.Mobywatel)}
            >
              <div className={styles.icon}>
                <MobywatelIcon></MobywatelIcon>
              </div>
              Aplikacja mObywatel
            </div>
            <div
              className={styles.method}
              onClick={() => setMethod(IdentityMethod.Signature)}
            >
              <div className={styles.icon}>
                <SignatureIcon></SignatureIcon>
              </div>
              Podpis kwalifikowany
            </div>
          </div>
        </>
      )}

      {method === IdentityMethod.Signature && step !== IdentityStep.Success && (
        <>
          <div className={styles.hint}>
            <b>Podpisz pobrane oświadczenie podpisem kwalifikowanym</b>{' '}
            Następnie wybierz z dysku bądź przeciągnij podpisane oświadczenie
            poniżej.
          </div>
          {step === IdentityStep.DownloadStatement && (
            <div className={styles.movie}>
              <YouTube
                videoId="ekKjpzKgzvc"
                opts={{ height: 300, width: 500 }}
              ></YouTube>
            </div>
          )}
          {step === IdentityStep.UploadStatement && (
            <>
              <div
                className={classNames(
                  styles.dropContainer,
                  isDragAccept ? styles.dropAccept : '',
                  isDragReject ? styles.dropReject : '',
                  isFocused ? styles.dropFocus : ''
                )}
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {!statement && (
                    <p>
                      Przeciągnij tutaj podpisane oświadczenie lub kliknij aby
                      wybrać plik
                    </p>
                  )}
                  {statement && isUploadingStatement && (
                    <div className={styles.verificaionInProgress}>
                      <Spinner className={styles.spinner} />
                      <p>Trwa weryfikacja oświadczenia</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.warn}>
                <b>{errorMessage}</b>
              </div>
            </>
          )}
        </>
      )}

      {method === IdentityMethod.Mobywatel &&
        step === IdentityStep.MObywatelQRCode && (
          <>
            <div className={styles.hint}>
              <b>Zeskanuj poniższy kod aplikacją mObywatel</b>
              <br></br>
              Uruchom aplikację mObywatel na Twoim telefonie, w menu aplikacji
              wybierz 'Kod QR' a następnie 'Zeskanuj kod QR'. Po zeskanowaniu
              kodu zezwól na przekazanie Twoich danych KFJ Inwestycje Sp. z o.o.
              w celu weryfikacji tożsamości.
              <br></br>
              Dane jakich potrzebujemy to: Imię, Nazwisko oraz PESEL.
            </div>
            <div id="qrcode" className={styles.qrcode}></div>
            {isLoadingQRCode && (
              <div className={styles.loading}>
                <Spinner className={styles.spinner} />
                <div>Wczytywanie</div>
              </div>
            )}
          </>
        )}

      {step === IdentityStep.Success && (
        <>
          <div className={styles.successWrapper}>
            <div className={styles.success}>
              <CheckIcon></CheckIcon>
            </div>
          </div>
          <div className={styles.hint}>
            Weryfikacja Twoich danych przebiegła <b>pomyślnie</b>. Dane w Twoim
            profilu zostały uzupełnione o te uzyskane z procesu weryfikacji.
            <br></br>W przyszłości w przypadku zmiany imienia lub nazwiska
            konieczna będzie ponowna weryfikacja.
          </div>
          <div className={styles.subheader}>Uzyskane dane</div>
          <div className={styles.dataRow}>
            <label>Imię</label>
            <span>{personalData.firstName}</span>
          </div>
          <div className={styles.dataRow}>
            <label>Nazwisko</label>
            <span>{personalData.lastName}</span>
          </div>
          <div className={styles.dataRow}>
            <label>PESEL</label>
            <span>{personalData.pesel}</span>
          </div>
        </>
      )}

      <div className={styles.buttons}>
        {prevButtonState.show && (
          <HollowButton
            className={styles.button}
            text={prevButtonState.label}
            onClick={() => prevStepInternal?.()}
          ></HollowButton>
        )}
        {nextButtonState.show && (
          <Button
            className={styles.button}
            text={nextButtonState.label}
            disabled={nextButtonState.disabled}
            onClick={() => nextStepInternal?.()}
          ></Button>
        )}
      </div>
    </div>
  );
};

export default Identity;
