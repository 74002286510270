import * as React from 'react';
import { CanvasPath, Point } from '../types';
import styles from './styles.module.scss';
import classNames from 'classnames';
import useGetPDFViewer from 'hooks/useGetPDFViewer';

export type SvgPathProps = {
  isCircle?: boolean;
  order?: number;
  commentId?: string;
  // List of points to create the stroke
  paths: Point[];
  // Unique ID
  id: string;
  // Width of the stroke
  strokeWidth: number;
  // Color of the stroke
  strokeColor: string;
  // Bezier command to smoothen the line
  command?: (point: Point, i: number, a: Point[]) => string;

  onClick?: (event: any, commentId: string) => void;
};

/**
 * Generate SVG Path tag from the given points
 */
export const SvgPath = ({
  isCircle = false,
  onClick = () => {},
  order = 1,
  commentId = '',
  paths,
  id,
  strokeWidth,
  strokeColor,
  command = bezierCommand,
}: SvgPathProps): JSX.Element => {
  const {
    getCanvas,
    setAnnotation,
    annotation,
    getShowAnnotation,
    setShowAnnotation,
  } = useGetPDFViewer();
  const ref: any = React.useRef(null);

  React.useEffect(() => {
    if (!getShowAnnotation()) return;

    if (getShowAnnotation() === commentId) {
      const canvas = getCanvas();
      const position = ref.current.getBBox();
      canvas?.current.scrollTo(position.x, position.y);
    }
  }, [getShowAnnotation()]);
  if (paths.length === 1) {
    const { x, y } = paths[0];
    const radius = strokeWidth / 2;

    if (isCircle) {
      return (
        <g
          ref={ref}
          onMouseEnter={() => setAnnotation(commentId)}
          onMouseLeave={() => setAnnotation(null)}
          onClick={(e) => onClick(e, commentId)}
          className={classNames(
            styles.stroke,
            commentId === annotation() ? styles.active : ''
          )}
        >
          <circle
            cx={x}
            cy={y}
            r={12}
            stroke={strokeColor}
            fill={strokeColor}
          />
          <text
            x={x}
            y={y}
            text-anchor="middle"
            fill="white"
            font-size="14px"
            font-family="Arial"
            dy=".3em"
          >
            {order}
          </text>
        </g>
      );
    }

    return (
      <circle
        ref={ref}
        onMouseEnter={() => setAnnotation(commentId)}
        onMouseLeave={() => setAnnotation(null)}
        className={classNames(
          styles.stroke,
          commentId === annotation() ? styles.active : ''
        )}
        key={id}
        id={id}
        cx={x}
        cy={y}
        r={radius}
        stroke={strokeColor}
        fill={strokeColor}
      />
    );
  }

  const d = paths.reduce(
    (acc, point, i, a) =>
      i === 0 ? `M ${point.x},${point.y}` : `${acc} ${command(point, i, a)}`,
    ''
  );

  return (
    <path
      ref={ref}
      onMouseEnter={() => setAnnotation(commentId)}
      onMouseLeave={() => setAnnotation(null)}
      className={classNames(
        styles.stroke,
        commentId === annotation() ? styles.activeStroke : ''
      )}
      onClick={(e) => onClick(e, commentId)}
      data-commentID={commentId}
      key={id}
      id={id}
      d={d}
      fill="none"
      strokeLinecap="round"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  );
};

export const line = (pointA: Point, pointB: Point) => {
  const lengthX = pointB.x - pointA.x;
  const lengthY = pointB.y - pointA.y;

  return {
    length: Math.sqrt(lengthX ** 2 + lengthY ** 2),
    angle: Math.atan2(lengthY, lengthX),
  };
};

type ControlPoints = {
  current: Point;
  previous?: Point;
  next?: Point;
  reverse?: boolean;
};

const controlPoint = (controlPoints: ControlPoints): [number, number] => {
  const { current, next, previous, reverse } = controlPoints;

  const p = previous || current;
  const n = next || current;

  const smoothing = 0.2;

  const o = line(p, n);

  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;

  const x = current.x + Math.cos(angle) * length;
  const y = current.y + Math.sin(angle) * length;

  return [x, y];
};

export const bezierCommand = (point: Point, i: number, a: Point[]): string => {
  let cpsX = null;
  let cpsY = null;

  switch (i) {
    case 0:
      [cpsX, cpsY] = controlPoint({
        current: point,
      });
      break;
    case 1:
      [cpsX, cpsY] = controlPoint({
        current: a[i - 1],
        next: point,
      });
      break;
    default:
      [cpsX, cpsY] = controlPoint({
        current: a[i - 1],
        previous: a[i - 2],
        next: point,
      });
      break;
  }

  const [cpeX, cpeY] = controlPoint({
    current: point,
    previous: a[i - 1],
    next: a[i + 1],
    reverse: true,
  });

  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point.x}, ${point.y}`;
};

type PathProps = {
  id: string;
  paths: CanvasPath[];
  onClick?: (position: { x: number; y: number }, commentId: string) => void;
};

const Paths = ({ id, paths, onClick }: PathProps): JSX.Element => {
  let number = 1;
  return (
    <React.Fragment>
      {paths.map((path: CanvasPath, index: number) => {
        const comm = number;
        if (path.isCircle) {
          number += 1;
        }
        return (
          <SvgPath
            onClick={onClick}
            order={comm}
            isCircle={path.isCircle}
            commentId={path.commentId}
            key={`${id}__${index}`}
            paths={path.paths}
            id={`${id}__${index}`}
            strokeWidth={path.strokeWidth}
            strokeColor={path.strokeColor}
            command={bezierCommand}
          />
        );
      })}
    </React.Fragment>
  );
};

export default Paths;
