import tagsService from 'api/tagsService';
import { ReactComponent as DeskIcon } from 'assets/icons/book.svg';
import { ReactComponent as RelationsIcon } from 'assets/icons/link.svg';
import { ReactComponent as SharedIcon } from 'assets/icons/share.svg';
import StatusBadge from 'components/StatusBadge';
import TagBadge from 'components/TagBadge';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import deskService from 'api/deskService';
import classNames from 'classnames';
import CaseOptionsCard from 'components/CaseOptions';
import CaseRelationsModal from 'components/CaseRelationsModal';
import CaseSharesModal from 'components/CaseSharesModal';
import DeskActiveModal from 'components/DeskActiveModal';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import StatusModal from 'components/StatusModal';
import CasesContext from 'contexts/CasesContext';
import { ItemKind } from 'dto/IKindItems';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import MenuContext from 'contexts/MenuContext';
import settingsContext, { IOptionType } from 'contexts/SettingsContext';
import CaseThreadsModal from 'components/CaseThreadsModal';
import { IThread } from 'dto/IThread';
import sanitizeHtml from 'sanitize-html';
import Chip from 'components/Chip';

const CaseCard: FC<Props> = ({
  c,
  tags,
  statuses,
  setSelectedEdit,
  className = '',
  onClick,
  isSelectable = false,
  selectedElements,
  setSelectedElements,
  showS = true,
  selectedThread,
  setSelectedThread,
  createNewThread,
  setCreateNewThread,
}) => {
  const navigate = useNavigate();

  const { caseId } = useParams();
  const { mutate: removeTag } = tagsService.useRemoveTagFromPackage();
  const [status, setStatus] = useState<IStatus | null>(null);
  const [findedTags, setFindedTags] = useState<ITag[]>([]);
  const [showFlags, setShowFlags] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const statusRef = useRef(null);
  const deskRef = useRef(null);
  const relationsRef = useRef(null);
  const sharesRef = useRef(null);
  const { cases, setCases } = useContext(CasesContext);
  const refWrapper = useRef(null);
  const [showAttach, setShowAttach] = useState(false);
  const [showDeskModal, setShowDeskModal] = useState(false);
  const [showRelations, setShowRelations] = useState(false);
  const [showShares, setShowShares] = useState(false);
  const [showThreads, setShowThreads] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);

  const { id, setId } = useContext(MenuContext);

  useEffect(() => {
    if (id !== c.Id) {
      setShowOptions(false);
    }
  }, [id]);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        OwnerId: c.Id,
        OwnerKind: ItemKind.Cases,
        TagId: tag.Id,
      },
      {
        onSuccess: () => {
          let newCases = [...cases];

          let caseItem = newCases.find((x) => x.Id === c.Id);

          if (caseItem) {
            caseItem.TagIds = caseItem.TagIds.filter((id) => id !== tag.Id);
          }
        },
      }
    );
    setFindedTags(findedTags.filter((ftag) => ftag.Id !== tag.Id));
  };

  useEffect(() => {
    const t = tags?.filter((tag) => c?.TagIds?.find((iTag) => tag.Id === iTag));
    setFindedTags(t);

    const newStatus = statuses.find((s: IStatus) => s.Id === c.StatusId);
    setStatus(newStatus ?? null);
  }, [statuses, c.StatusId, c.TagIds]);

  const handleClick = (e: any) => {
    const ele = e.target.tagName.toLowerCase();

    if (ele !== 'div') {
      return;
    }

    if (e.target.className.search('button') !== -1) {
      return;
    }

    const newCases = [...cases];
    let el = newCases.find((p) => p.Id === c.Id);
    if (el) {
      el.Unread = false;
      setCases(newCases);
    }

    // navigate(`/packages/${filterId}/${linkStatus}/${c.Kind}/${c.Id}`);
    if (c.PartialShare) {
      navigate(`/cases/${c.Id}/partial`);
      return;
    }
    localStorage.setItem('last_case', c.Id);
    navigate(`/cases/${c.Id}/chat`);
  };

  const { mutate: setDesk } = deskService.useSetDesk();
  const handleDeskRemove = () => {
    setDesk(
      {
        Items: [{ Id: c.Id, Kind: ItemKind.Cases }],
        Show: false,
      },
      {
        onSuccess: () => {
          let newCases = [...cases];
          let caseItem = newCases.find((x) => x.Id === c.Id);

          if (caseItem) {
            caseItem.IsDeskActive = false;
            setCases(newCases);
          }
          setShowDeskModal(false);
        },
      }
    );
  };

  useEffect(() => {}, [c, c.Title]);

  const { options } = useContext(settingsContext);

  const h = options.find((f) => f.Type === IOptionType.hoverMenu)?.Value;

  const useHoverOver = h === 'true';

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [showAfter, setShowAfter] = useState(false);

  const isFirst =
    selectedElements?.at(0)?.Id === c.Id && selectedElements.length > 1;

  return (
    <div
      data-id={c.Id}
      key={c.Id}
      onClick={
        onClick
          ? (e: any) => {
              const ele = e.target.tagName.toLowerCase();
              const button = e.target.outerHTML.search('button') !== -1;

              if (ele !== 'div' || button || showDeskModal) {
                return;
              }

              onClick?.();
            }
          : handleClick
      }
      className={`${styles.card} ${className} `}
      ref={refWrapper}
      onMouseEnter={() => {
        if (useHoverOver) {
          setShowOptions(true);
          const t = setTimeout(() => {
            setShowAfter(true);
          }, 500);
          setTimer(t);
        }
      }}
      onMouseLeave={() => {
        if (useHoverOver) {
          setShowOptions(false);
          setShowAfter(false);
          if (timer) {
            clearTimeout(timer);
            setTimer(null);
          }
        }
      }}
    >
      <div className={`${styles.inner}`}>
        {refWrapper.current && showS && (
          <CaseOptionsCard
            showDropdown={useHoverOver ? false : true}
            showOptions={(showOptions && showAfter) || isFirst}
            showSomething={true}
            pack={c}
            refetch={() => {}}
            refWrapper={refWrapper}
            onClickEdit={() => setSelectedEdit(c)}
            onClickAttachIcon={setShowAttach}
            toggle={() => setShowOptions(false)}
            setShowThreads={setShowThreads}
            showThreads={showThreads}
            selectedElements={selectedElements}
            setSelectedElements={setSelectedElements}
          />
        )}

        {c.Unread && <div className={styles.colorBar}></div>}
        <div className={styles.header}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: c.Title }}
          ></div>
          <div className={styles.icons}>
            {c.IsShared && (
              <div className={styles.iconWrapper} title="Udostępniono">
                <SharedIcon
                  ref={sharesRef}
                  onClick={() => setShowShares(!showShares)}
                  className={classNames(styles.icon, styles.stroke)}
                />
              </div>
            )}
            {c.RelationsCount > 0 && (
              <div className={styles.iconWrapper} title="Relacje">
                <RelationsIcon
                  ref={relationsRef}
                  onClick={() => setShowRelations(!showRelations)}
                  className={styles.icon}
                />
              </div>
            )}
            {c.IsDeskActive && (
              <div
                className={styles.iconWrapper}
                title="Przypięto do mojego biurka"
              >
                <DeskIcon ref={deskRef} className={styles.icon} />
              </div>
            )}
          </div>
        </div>

        {/* {Case?.Participants?.find((p) => p.IsMain)?.Label}
                        {!!Case?.Participants?.filter((p) => p.IsMain)
                          ?.length && <> i inni</>} */}

        <div
          className={styles.client}
          dangerouslySetInnerHTML={{ __html: c?.Client ?? '' }}
        ></div>

        <div className={styles.signatureWrapper}>
          <div className={styles.signature}>
            {status && (
              <div className={styles.badgesWrapper}>
                {status && (
                  <div
                    onClick={() => {
                      setShowFlags(!showFlags);
                    }}
                    ref={statusRef}
                    className={styles.badge}
                  >
                    <StatusBadge onRemove={() => {}} status={status} />
                  </div>
                )}
              </div>
            )}
            {!!findedTags.length && (
              <div className={styles.tags}>
                {findedTags.map((tag) => (
                  // <TagBadge
                  //   key={tag.Id}
                  //   onRemove={(tag) => handleRemoveTag(tag)}
                  //   tag={tag}
                  // />
                  <Chip
                    element={<>{tag.Name}</>}
                    onDelete={() => handleRemoveTag(tag)}
                  />
                ))}
              </div>
            )}

            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(c?.Signature ?? ''),
              }}
            ></div>
          </div>
        </div>
      </div>

      {showFlags && (
        <ModalLayout dock={DockPosition.BottomLeft} refWrapper={statusRef}>
          <StatusModal toggle={setShowFlags} item={c} selectedStatus={status} />
        </ModalLayout>
      )}

      {showDeskModal && (
        <ModalLayout refWrapper={deskRef}>
          <DeskActiveModal
            toggle={setShowDeskModal}
            onRemove={handleDeskRemove}
          />
        </ModalLayout>
      )}

      {showRelations && (
        <ModalLayout refWrapper={relationsRef}>
          <CaseRelationsModal CaseId={c.Id} toggle={setShowRelations} />
        </ModalLayout>
      )}

      {showShares && (
        <ModalLayout
          toggle={() => {
            setShowShares(false);
          }}
          refWrapper={sharesRef}
        >
          <CaseSharesModal CaseId={c.Id} toggle={setShowShares} />
        </ModalLayout>
      )}

      {showThreads && (
        <ModalLayout
          dock={DockPosition.BottomRight}
          onEnd={() => {
            setShowThreads(false);
          }}
        >
          <CaseThreadsModal
            setCreateNewThread={() => setCreateNewThread?.(true)}
            toggle={setShowThreads}
            onSelectThread={(t) => {
              setSelectedThread?.(t);
            }}
          />
        </ModalLayout>
      )}
      <div className={styles.separator}></div>
    </div>
  );
};

export default CaseCard;
