import Input from 'components/Input';
import styles from './styles.module.scss';
import HollowButton from 'components/HollowButton';
import Button from 'components/Button';
import { useContext, useState } from 'react';
import keysService from 'api/keysService';
import { setKeys } from 'utils/setKeys';
import { decryptKeys } from 'utils/decryptKeys';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { ReactComponent as NotaIcon } from 'assets/icons/nota.svg';
import { getUserId } from 'utils/getUserId';

import BG from 'assets/icons/loginbg.png';

const Recovery = () => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const { data: recoveryPackage, isLoading } =
    keysService.useGetRecoveryPackage();

  const navigate = useNavigate();
  const [recoveryKey, setRecoveryKey] = useState('');
  const [error, setError] = useState('');

  const handleRecoveryKeys = async () => {
    setError('');
    try {
      const result = await decryptKeys(recoveryPackage, recoveryKey);
      setKeys(userId, result);
      navigate('/');
    } catch (e) {
      setError('Klucz przywracania nie jest prawidłowy');
    }
  };

  const logout = () => {
    auth.signoutRedirect();
  };

  return (
    <div>
      <img src={BG} className={styles.bg} />
      <div className={styles.top}>
        <NotaIcon className={styles.icon} />
        <div onClick={() => logout()} className={styles.logout}>
          Wróc do strony głównej
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <div className={styles.title}>Przywracanie klucza</div>
            <div className={styles.title2}>prywatnego</div>
          </div>
          <div className={styles.history}>
            Nie odnaleźliśmy na bieżącym urządzeniu kluczy do odszyfrowania
            wiadomości
          </div>
          <div className={styles.input}>
            <Input
              placeholder="Klucz przywracania"
              label=""
              onChange={(text) => setRecoveryKey(text)}
            />
            {!!error.length && <div className={styles.error}>{error}</div>}
            <div className={styles.btnWrapper}>
              <Button
                disabled={recoveryKey.length < 32 || isLoading}
                className={styles.btn}
                text="Przywróć klucze"
                onClick={handleRecoveryKeys}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
