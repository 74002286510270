import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import { TextField } from '@mui/material';

const Input: FC<Props> = ({
  type = 'input',
  name = '',
  tags = false,
  tagType = null,
  onChooseTag = () => {},
  label,
  onChange,
  defaultValue,
  value,
  placeholder,
  disabled = false,
  disabledTags = false,
  onBlur,
  onFocus,
  isError = '',
  className = '',
  hideTags = false,
  disableShadow = false,
  focus = false,
  isPhoneNumber = false,
  isNip = false,
  isRegon = false,
  isCode = false,
  onAccept,
  onEnter,
  onCancel,
  variant = 'filled',
}) => {
  const height = 48;
  const labelOffset = variant === 'outlined' || variant === 'filled' ? -4 : 6;

  const ref = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState(defaultValue ?? '');

  const link = window.location.pathname;

  const isOutcoming = link.search('outcoming') !== -1;
  const isPisma = link.search('pisma') !== -1;

  const tagS = (type: string) => {
    if (isOutcoming) {
      return 7;
    }

    if (isPisma) {
      return 8;
    }

    switch (type) {
      case 'cases':
        return 1;

      case 'actions':
        return 0;

      case 'clients':
        return 3;

      case 'letters':
        return 4;

      case 'packages':
        return 6;
      default:
        return -1;
    }
  };

  const currentWorkspaceId = getWorkspaceId();
  const { data: tagsData } = tagsService.useGetTags(
    disabledTags,
    tagS(link.split('/')[1]),
    undefined,
    currentWorkspaceId ?? ''
  );

  const [showTags, setShowTags] = useState(false);

  const tagsList = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let v = e.target.value;

    setText(v);
    onChange(v);
  };

  const handleChooseTag = (tag: ITag) => {
    onChooseTag(tag);
    const formatedText = value ?? text;

    setText(formatedText.replaceAll('#', ''));
    onChange(formatedText.replaceAll('#', ''));
  };

  useEffect(() => {
    const formatedText = value ?? text;

    const isHashtag = formatedText.search('#') !== -1;

    if (isHashtag && !showTags && !hideTags) {
      setShowTags(true);
      return;
    }

    if (!isHashtag && showTags) {
      setShowTags(false);
      return;
    }
  }, [value, text]);

  const handleClickButton = (e: any) => {
    if ((e.key === 'Enter' || e.code === 'Space') && isFocused) {
      if (onAccept) {
        onAccept?.();
        return;
      }
      const formatedText = value ?? text;
      if (formatedText.search('#') === -1) return;
      const tag = formatedText.split('#')[1].replaceAll('_', ' ');

      setShowTags(false);

      onChooseTag({
        Id: '-1',
        Name: tag,
        Type: 2,
        Flags: 0,
        Global: false,
      });

      setText(formatedText.split('#')[0]);
      onChange(formatedText.split('#')[0]);
    }
  };

  const handleChoose = () => {
    //@ts-ignore
    ref?.current?.focus();
  };

  useEffect(() => {
    if (!ref?.current || !focus) return;
    handleChoose();
  }, [ref]);

  useEffect(() => {
    document.addEventListener('keypress', handleClickButton);

    return () => document.removeEventListener('keypress', handleClickButton);
  }, [value, text, isFocused]);

  const isText = !!value?.length || !!text.length;
  const isFocusedStyle = isFocused && !isText ? styles.active : '';
  const isFocusedStyle2 = !isFocused && isText ? styles.active : '';
  const isFocusedStyle3 = isFocused && isText ? styles.active : '';

  const removeChar = (e: any) => {
    if (!isFocused) return;
    if (e.key === 'Backspace') {
      const v = value?.replaceAll(' ', '').substring(0, value.length - 1);

      setText(`${v}`);
      onChange(`${v}`);
      return;
    }
    if (e.key.length === 1) {
      if (e.key === '+') {
        setText(`${value}${e.key}`);
        onChange(`${value}${e.key}`);
        return;
      }

      if (isNaN(Number(e.key))) return;
      setText(`${value}${e.key}`);
      onChange(`${value}${e.key}`);
    }
  };

  const [keyDown, setKeyDown] = useState(false);

  const handlePaste = (e: any) => {
    if (e.key === 'c') {
    }
  };

  const changeToSelectable = (e: any) => {
    if (e.key === 'Control' && !keyDown && isFocused) {
      setKeyDown(true);
    }
  };

  const changeToNotSelectable = (e: any) => {
    if (e.key === 'Control' && keyDown && isFocused) {
      setKeyDown(false);
    }
  };

  const handleKey = async (e: any) => {
    if (e.key === 'v' && isFocused && isPhoneNumber) {
      const text = (await navigator.clipboard.readText()).replaceAll(' ', '');
      setText(text);
      onChange(text);
      setKeyDown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', changeToSelectable);
    window.addEventListener('keyup', changeToNotSelectable);

    return () => {
      window.removeEventListener('keydown', changeToSelectable);
      window.removeEventListener('keyup', changeToNotSelectable);
    };
  }, [keyDown, isFocused]);

  useEffect(() => {
    document.addEventListener('keydown', handleKey);
    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [keyDown, isFocused]);

  useEffect(() => {
    if (isPhoneNumber === false) {
      return;
    }
    document.addEventListener('keyup', removeChar);
    return () => {
      if (!isPhoneNumber) return;
      document.removeEventListener('keyup', removeChar);
    };
  }, [isFocused, value, onChange, setText, isPhoneNumber]);

  const handleEnter = (e: any) => {
    if (!isFocused) return;

    if (e.key === 'Enter') {
      setIsFocused(false);
      onBlur?.();
      onEnter?.();
    }

    if (e.key === 'Escape') {
      setIsFocused(false);
      onBlur?.();
      onCancel?.();
    }
  };

  useEffect(() => {
    if (!onEnter) return;
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [onEnter, isFocused]);

  return (
    <TextField
      value={value}
      id="outlined-basic"
      label={label}
      // placeholder={placeholder}
      onFocus={() => {
        setIsFocused(true);
        onFocus?.();
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onChange={(e) => handleChange(e)}
      style={{
        width: '100%',
        height: height,
      }}
      /* styles the label component */
      InputLabelProps={{
        style: {
          height,
          ...{ top: `${!isFocused ? labelOffset : -6}px` },
        },
      }}
      sx={{
        // Class for the label of the input field
        '&:hover:not(.Mui-focused)': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#5d49c7',
          },
        },
      }}
      /* styles the input component */
      inputProps={{
        style: {
          height,
          padding: '0 14px',
        },
      }}
      className={styles.t}
      variant={variant}
      color={'primary'}
      type={type === 'password' ? 'password' : 'text'}
    />
  );
};

export default Input;
