import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { IReceiver } from 'dto/IPackage';
import Avatar from 'components/Avatar';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const Receiver = ({ receiver }: { receiver: IReceiver }) => {
  return (
    <div className={styles.box}>
      <div className={styles.left}>
        <Avatar className={styles.avatar} name={receiver.Name} />
        <div className={styles.data}>
          <div className={styles.name}>{receiver.Name}</div>
          <div className={styles.address}>{receiver.Email}</div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.status}>W trakcie</div>
      </div>
    </div>
  );
};

const MultiPackageModal: FC<Props> = ({ pack, toggle }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        Multiprzesyłka
        <CloseIcon onClick={toggle} className={styles.closeIcon} />
      </div>
      <div className={styles.body}>
        {pack.Receivers.map((r) => (
          <Receiver receiver={r} />
        ))}
      </div>
    </div>
  );
};

export default MultiPackageModal;
