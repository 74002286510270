export interface IClientData {
    Type: EClientData
    Value: string
    IsMain: boolean
}

export enum EClientData {
    ADDRESS = 'address',
    CONTACT_PERSON = 'contactPerson',
    EMAIL = 'email',
    PHONE = 'phone',
    NOTE = 'note',
    eaddress = 'edeliveryAddress'
    
}

export interface IClient {
    Id: number
    IsInstitution: boolean
    Name: string
    Data: IClientData[]
    Flags: number
    Address?: string
    City?: string
    ZipCode?: string
    Phone?: string
    Email?: string
    Note?: string
    NIP?: string
    REGON: any
    CasesCount: number
    TargetUserId: any
    IsGlobal: boolean
    SourceId: any
    ContactPersons: any[]
    TagIds: string[]
    IsOnline: boolean;
}

export const ClientFlags = {
    None: 0,
    HasEmail: 1 << 0,
    HasPhone: 1 << 1,
    HasAddress: 1 << 2,
    HasEDeliveryAddress: 1 << 3,
    HasNote: 1 << 4,
    HasContactPerson: 1 << 5,
    HasAvatar: 1 << 6,
  };