import ReactDOM from 'react-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { ReactComponent as FilterIcon } from 'assets/icons/order/filter.svg';
import { ReactComponent as PackIcon } from 'assets/icons/order/pack.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/order/case.svg';
import { ReactComponent as FileIcon } from 'assets/icons/order/file.svg';
import { ReactComponent as LetterIcon } from 'assets/icons/order/letter.svg';
import { ReactComponent as TagsIcon } from 'assets/icons/order/tags.svg';
import { ReactComponent as ActionsIcon } from 'assets/icons/order/actions.svg';
import TagWithBorder from 'components/TagWithBorder';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { axiosInstance } from 'api/axios';

const WideSearch: FC<Props> = ({ refWrapper, toggle }) => {
  const [text, setText] = useState('');

  const [kind, setKind] = useState('1024');

  const { data, isLoading } = useQuery(
    ['wide-search', text, kind],
    async () => {
      const query = new URLSearchParams(window.location.search);

      query.append('Kind', kind);
      const res = await axiosInstance.get(`/list?${query}`);
      return res.data;
    }
  );

  const [types, setTypes] = useState({
    packages: false,
    cases: false,
    files: false,
    letters: false,
    tags: false,
    actions: false,
  });

  if (!refWrapper?.current) return <></>;

  const position = refWrapper.current.getBoundingClientRect();

  return ReactDOM.createPortal(
    <div
      style={{
        left: `${position.left}px`,
        top: `${position.top}px`,
        width: `${position.width}px`,
      }}
      className={styles.modal}
    >
      <div className={styles.inputWrapper}>
        <div className={styles.icon}>
          <FilterIcon />
        </div>
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          className={styles.input}
        />
        <div className={styles.close}>
          <CloseIcon
            onClick={() => {
              // setShowInput(true);
              //@ts-ignore
              // inputWrapper?.current?.focus?.();
              toggle();
            }}
          />
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.box}>
          <div className={styles.title}>Szukam...</div>
          <div className={styles.flex}>
            <TagWithBorder text="bieżąca lista" onClick={() => {}} active />
            <TagWithBorder text="Cała aplikacja" onClick={() => {}} />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.title}>Szukam...</div>
          <div className={styles.flex}>
            <TagWithBorder
              text={
                <div className={styles.element}>
                  <PackIcon />
                  Przesyłki
                </div>
              }
              onClick={() => {
                setTypes({ ...types, packages: !types.packages });
              }}
              active={types.packages}
            />
            <TagWithBorder
              text={
                <div className={styles.element}>
                  <CaseIcon />
                  Teczki
                </div>
              }
              onClick={() => {
                setTypes({ ...types, cases: !types.cases });
              }}
              active={types.cases}
            />
            <TagWithBorder
              text={
                <div className={styles.element}>
                  <FileIcon />
                  Pliki
                </div>
              }
              active={types.files}
              onClick={() => {
                setTypes({ ...types, files: !types.files });
              }}
            />
            <TagWithBorder
              text={
                <div className={styles.element}>
                  <LetterIcon />
                  Pisma
                </div>
              }
              onClick={() => {
                setTypes({ ...types, letters: !types.letters });
              }}
              active={types.letters}
            />
            <TagWithBorder
              text={
                <div className={styles.element}>
                  <TagsIcon />
                  #tag
                </div>
              }
              onClick={() => {
                setTypes({ ...types, tags: !types.tags });
              }}
              active={types.tags}
            />
            <TagWithBorder
              text={
                <div className={styles.element}>
                  <ActionsIcon />
                  Czynności
                </div>
              }
              onClick={() => {
                setTypes({ ...types, actions: !types.actions });
              }}
              active={types.actions}
            />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.title}>Wyniki wyszukiwania</div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default WideSearch;
