import Button from 'components/Button';
import PDFViewer from 'components/PDFViever';
import useFiles from 'hooks/useFiles';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { readFileAsBlob } from 'utils/readFileAsBlob';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import fileService, { IFileInitUploadResponse } from 'api/fileService';
import keysService from 'api/keysService';
import parcelService from 'api/parcelService';
import pluginService from 'api/pluginService';
import { IEncryptedFile } from 'components/CreateDossierModal/typings';
import SecondCheckbox from 'components/SecondCheckbox';
import KeysContext from 'contexts/KeysContext';
import ModalsManagerContext from 'contexts/ModalsManager';
import PackagesContext from 'contexts/PackagesContext';
import CryptoJS from 'crypto-js';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { IEntity } from 'dto/IEntity';
import moment from 'moment';
import { useAuth } from 'react-oidc-context';

import { ReactComponent as AIcon } from 'assets/icons/company/a.svg';
import ChooseTagNew from 'components/ChooseTagNew';
import CreateCaseModal from 'components/CreateCaseModal';
import DatePickerNew from 'components/DatePickerNew';
import DossierAnimation from 'components/DossierAnimation';
import EditClientModalNew from 'components/EditClientModalNew';
import Filedossier from 'components/Filedossier/Filedossier';
import NewInput from 'components/NewInput';
import SearchCasesNew from 'components/SearchCasesNew';
import SearchEntitiesNew from 'components/SearchEntitiesNew';
import TagBadge from 'components/TagBadge';
import CPluginContext from 'contexts/PluginContext';
import { ITag } from 'dto/ITag';
import { useOnClickOutside } from 'hooks/useClickOutside';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import {
  ISingleBinaryKeys,
  generateSingleBinary,
} from 'utils/GenerateSingleBinary';
import { blobToBase64 } from 'utils/blobToBase64';
import { decryptFile } from 'utils/decryptFile';
import encryptFile from 'utils/encryptFile';
import { getBlob } from 'utils/getBlob';
import { getFile } from 'utils/getFile';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { isAllowedAttachment } from 'utils/isAllowedAttachment';
import { ENotificationType, notification } from 'utils/notification';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import Input from 'components/Input';

const encryptFiles = async (file: File, keys: ISingleBinaryKeys) => {
  const fileAndBlob = await readFileAsBlob(file);
  const returnedFile = fileAndBlob[0];
  const returnedBlob = fileAndBlob[1];
  const encryptedFile = await encryptFile(returnedBlob.target?.result, keys);
  return {
    orginalFile: returnedFile,
    encryptedFile: encryptedFile,
    blob: returnedBlob,
    hash: JSON.stringify(
      await CryptoJS.SHA256(
        (returnedBlob.target?.result as string) ?? ''
      ).toString(CryptoJS.enc.Hex)
    ),
  };
};

const AddNewPackageModal: FC<Props> = ({}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [previewFile, setPreviewFile] = useState<any | null>(null);
  const [previewUri, setPreviewUri] = useState<string | undefined>(undefined);
  const [selectedPreviewFile, setSelectedPreviewFile] = useState(0);
  const [selectedFiles, setSelectedFile] = useState<string[]>([]);
  const { files, setFiles, addNewFile, removeFile } = useFiles();
  const { mutate: tryPreview } = pluginService.usePreview();

  const [isLoading, setIsLoading] = useState(false);

  const { data: parcelData } = parcelService.useInitParcel();

  const { mutate: createObject, isLoading: loadingObject } =
    keysService.useCreateObject();
  const { mutate: initUploadFile, isLoading: loadingInit } =
    fileService.useInitUpload();
  const { mutate: getS3, isLoading: loadingS3 } = fileService.useGetS3();
  const { mutate: confirmUpload, isLoading: loadingConfirm } =
    fileService.useConfirmUpload();

  const { mutate: createAttachment } = parcelService.useCreateParcel();
  const parcelInformation = useMemo(() => parcelData?.data ?? [], [parcelData]);

  const [title, setTitle] = useState('');
  const [Case, setCase] = useState<ICaseSimple | null>(null);
  const [entity, setEntity] = useState<IEntity | null>(null);
  const [receiver, setReceiver] = useState<any>(null);
  const [uri, setUri] = useState<string | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const [key, setKey] = useState<{ key: string; id: string } | null>(null);
  const [decodedFiles, setDecodedFiles] = useState<any>([]);
  const [showCase, setShowCase] = useState(false);

  const [showEntity, setShowEntity] = useState(false);
  const [isCompany, setIsCompany] = useState(false);

  const [tags, setTags] = useState<ITag[]>([]);

  const dropdownRef = useRef(null);

  const { packages, setPackages } = useContext(PackagesContext);
  const { keys, setKeys } = useContext(KeysContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [design, setDesign] = useState({
    date: true,
    from: true,
    redirect: true,
    case: false,
    tags: false,
  });

  useOnClickOutside(dropdownRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  useEffect(() => {
    const item = localStorage.getItem('newPackageModal');
    if (!item) return;
    const r = JSON.parse(item);
    setDesign(r);
  }, []);

  useEffect(() => {
    const json = JSON.stringify(design);
    localStorage.setItem('newPackageModal', json);
  }, [design]);

  const isMe =
    parcelInformation?.Receivers?.find((r: any) => r.Id === receiver)?.IsMe ??
    false;

  const addNewTag = (tag: ITag | null) => {
    const isTag = tags.find((etag) => etag.Name === tag!.Name);
    if (isTag) return;
    setTags([...tags, tag!]);
  };

  const handleRemoveTag = (tag: ITag) =>
    setTags(tags.filter((etag) => tag.Name !== etag.Name));

  const fetchFiles = async () => {
    const decryptedFiles = [];
    for (const file of parcelData?.data.Files) {
      const f = await getFile(file.Uri);
      const blob = await getBlob(f.data.Url);
      const decryptedFile = await decryptFile(userId, new Blob([blob.data]), {
        PrivateKey: file.KeyPk,
      });

      file.decryptedFile = decryptedFile;
      setSelectedFile([...selectedFiles, file.Uri]);
      decryptedFiles.push(file);
    }
    setDecodedFiles(decryptedFiles);
  };

  useEffect(() => {
    if (!!parcelData?.data.Files.length) {
      fetchFiles();
    }
  }, [parcelData]);

  const { plugin } = useContext(CPluginContext);
  const [showVersion, setShowVersion] = useState(false);

  const createPreview = async (
    file: File & { FileName?: string; decryptedFile?: Blob }
  ) => {
    setShowVersion(false);
    const fileName = file.name ?? file.FileName;
    //@ts-ignore
    const uri = file.Uri ?? file.metadata.Uri;
    const extension = fileName.split('.').at(-1);
    const isAllowed = isAllowedAttachment(extension ?? 'no');
    const isPDF = extension === 'pdf';

    if (!isAllowed && !isPDF) {
      notification({
        type: ENotificationType.ERROR,
        title: 'Błąd konwersji pliku',
        text: 'Przepraszamy! aktualnie nie obsługujemy tego formatu pliku',
      });
      return;
    }

    let fileToShow = null;

    if (file.decryptedFile) {
      fileToShow = file.decryptedFile;
    } else {
      const result = await readFileAsBlob(file);
      fileToShow = new Blob([result[1].target?.result!]);
    }

    if (isPDF) {
      setPreviewFile(fileToShow);
      setPreviewUri(`${uri}:uploaderFile:${uri}`);
      return;
    }

    if (!plugin.actual) {
      setShowVersion(true);
      return;
    }

    const b: string = (await blobToBase64(fileToShow)) as string;
    tryPreview(
      {
        AttachmentId: fileName,
        Content: b.split(',')[1],
        ContentType: file.type,
        DocumentId: fileName,
        FileName: fileName,
        Kind: 0,
      },
      {
        onSuccess: async (response) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setPreviewFile(new Blob([buffer]));
          setPreviewUri(`${uri}:uploaderFile:${uri}`);
        },
      }
    );
  };

  useEffect(() => {
    if (!files[0] && !decodedFiles[0]) return;
    createPreview(files?.[0] || decodedFiles?.[0] || null);
    setSelectedPreviewFile(0);
    if (files?.[0]) {
      setIsUploadedFile(false);
    } else {
      setIsUploadedFile(true);
    }
  }, [files, decodedFiles]);

  const handleChooseFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const filesToUpload = event.target.files;

    for (const file of filesToUpload) {
      await handleCreate(file);
    }
  };

  const handleChangeStateFile = (file: File) => {
    //@ts-ignore
    const uri = file.Uri ?? file.metadata.Uri;
    const isFile = !!selectedFiles.find((sfile) => sfile === uri);

    if (isFile) {
      setSelectedFile(selectedFiles.filter((sfile) => sfile !== uri));
      return;
    }

    setSelectedFile([...selectedFiles, uri]);
    return;
  };

  // Starting process of upload after click on button

  const handleCreate = async (file: File) => {
    return new Promise(async (resolve) => {
      setIsLoading(true);
      const myKeys = await getKeys(userId!);

      const keys: ISingleBinaryKeys = await generateSingleBinary(
        undefined,
        myKeys?.intermidatePrivateKey
      );

      // Creating keys and uploading to database also in this step we encrypt all files from hook
      createObject(
        {
          CaseId: null,
          PrivateKey: keys.PrivateKey,
          PublicKey: keys.PublicKey,
        },
        {
          onSuccess: async (response) => {
            setKey({
              id: response.data.Id,
              key: keys.PublicKey,
            });
            const encryptedFile = await encryptFiles(file, keys);
            //@ts-ignore
            initUpload(
              response.data.Id,
              keys.PrivateKey,
              encryptedFile,
              resolve
            );
          },
        }
      );
    });
  };

  // Initialize upload sending information about files
  const initUpload = (
    keyId: string,
    privateKey: any,
    file: IEncryptedFile,
    resolve: any
  ) => {
    initUploadFile(
      {
        ContentType: file.orginalFile.type,
        FileName: file.orginalFile.name,
        Hash: file.hash.replaceAll('"', ''),
        IsSigned: false,
        KeyId: keyId,
        Size: file.orginalFile.size,
        Queue: 2,
      },
      {
        onSuccess: (response) =>
          getLink(response.data, keyId, privateKey, file, resolve),
      }
    );
  };

  // Getting link to S3
  const getLink = (
    response: IFileInitUploadResponse,
    keyId: string,
    privateKey: any,
    file: IEncryptedFile,
    resolve: any
  ) => {
    setUri(response.Uri);
    getS3(
      {
        partNumber: 1,
        uploadId: response.UploadId,
        uri: response.Uri,
      },
      {
        onSuccess: (link) =>
          uploadFile(
            response.Uri,
            response.UploadId,
            link.data,
            keyId,
            privateKey,
            file,
            resolve
          ),
      }
    );
  };

  const uploadFile = async (
    uri: string,
    uploadId: string,
    link: string,
    keyId: string,
    privateKey: any,
    file: IEncryptedFile,
    resolve: any
  ) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', link, true);
    xhr.responseType = 'blob';

    // Oczekujemy, że AWS S3 może zwrócić 200 OK, 201 Created lub 204 No Content w odpowiedzi na zapytanie PUT
    xhr.onload = function () {
      if (xhr.status === 200 || xhr.status === 201 || xhr.status === 204) {
        var etag = this.getResponseHeader('ETag')!.replace(/[^a-zA-Z0-9]/g, '');

        confirmUpload(
          {
            UploadId: uploadId,
            Uri: uri,
            Parts: [{ ETag: etag!, PartNumber: 1 }],
          },
          {
            onSuccess: () => {
              resolve(true);
            },
          }
        );
      } else {
        console.error(
          'Wystąpił błąd podczas wysyłania pliku do AWS S3. Status:',
          xhr.status
        );
      }
    };

    // @ts-ignore
    file.orginalFile.metadata = {
      KeyId: keyId,
      KeyPk: privateKey,
      Order: 0,
      Uri: uri,
    };

    addNewFile(file.orginalFile);
    setSelectedFile([...selectedFiles, uri]);

    xhr.send(new Blob([file.encryptedFile]));
  };

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    setIsUploadedFile(false);
    // setSelectedPreviewFile(files.length - 1);
    setInitialized(true);
  }, [files]);
  const handleCancel = () => {};

  const handleAccept = async () => {
    const decodedAttachments = decodedFiles.map((f: any) => ({
      KeyId: f.KeyId,
      KeyPk: f.KeyPk,
      Order: 0,
      Uri: f.Uri,
    }));

    // @ts-ignore
    const uploadAttachments = files.map((file: File & { metadata: any }) => ({
      KeyId: file.metadata.KeyId,
      KeyPk: file.metadata.KeyPk,
      Order: 0,
      Uri: file.metadata.Uri,
    }));

    const attachments = decodedAttachments.concat(uploadAttachments);

    createAttachment(
      {
        Attachments: attachments,
        CaseId: Case ? Case.Id : null,
        ReceiveDate: moment(date).toISOString(),
        ReceiverId: receiver ? receiver : null,
        SenderId: entity ? entity.Id : userId,
        Title: title,
        TagIds: tags.map((t) => t.Id),
      },
      {
        onSuccess: (response) => {
          setIsLoading(false);
          handleClickCloseModal();
          setPackages([response.data.Items[0], ...packages]);
          setKeys([response.data.ObjectKeys, ...keys].flat());
        },
        onError: () => {
          notification({
            type: ENotificationType.ERROR,
            title: 'Błąd konwersji pliku',
            text: 'Przepraszamy! aktualnie nie jesteśmy w stanie obsłużyć twojego żądania',
          });
        },
      }
    );
  };
  const { modals, setModals } = useContext(ModalsManagerContext);

  const handleClickToUpload = () => {
    if (!inputFile.current) return;
    inputFile.current.click();
  };
  const [showCreateNew, setShowCreateNew] = useState(false);

  const handleClickCloseModal = () =>
    setModals({ ...modals, createPostage: false });

  const [isUploadedFile, setIsUploadedFile] = useState(false);
  const [errors, setErrors] = useState({ title: false, entity: false });

  // if (!files.length && !decodedFiles.length) {
  //   return <AddNewPackageEmptyState handleChooseFile={handleChooseFile} />;
  // }

  const isDisabled = !title.length || !entity;

  const handleError = () => {
    let payload = { ...errors };

    if (!title.length) {
      payload = { ...payload, title: true };
    }

    if (!entity) {
      payload = { ...payload, entity: true };
    }

    setErrors(payload);
  };

  const [startOf, setStartOf] = useState<Date | undefined>(new Date());

  useEffect(() => {
    if (!!decodedFiles.length || !!files.length) return;

    if (previewFile) {
      setPreviewFile(null);
    }
  }, [decodedFiles, files]);

  const EmptyScreen = () => {
    return (
      <div className={styles.emptyScreen}>
        <div className={styles.text}>
          <div className={styles.t}>Przeciągnij i upuść plik lub</div>
          <Button
            className={styles.button}
            text="Wybierz z dysku"
            onClick={handleClickToUpload}
          />
        </div>
        <DossierAnimation className={styles.empty} />
      </div>
    );
  };

  const handleOnDrag = async (result: DropResult) => {
    const newFiles = [...files];

    const sourceFile = files[result.source.index];

    if (!result.destination) return;

    const destinationFile = files[result.destination.index];

    newFiles[result.source.index] = destinationFile;
    newFiles[result.destination.index] = sourceFile;

    setFiles(newFiles);
  };

  const refWrapper = useRef(null);

  return (
    <ModalLayout
      dock={DockPosition.DISABLED}
      off={true}
      customX={1}
      customY={0}
      ignore
      refWrapper={refWrapper}
      isAnimation
      fullHeight={true}
    >
      <>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Przesyłka papierowa
            <CloseIcon className={styles.c} onClick={handleClickCloseModal} />
          </div>
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.inputs}>
                {design.case && (
                  <SearchCasesNew
                    variant="filled"
                    classNameCard={styles.card}
                    settedCase={Case}
                    onChoose={(c) => {
                      setCase(c);
                    }}
                    setAddNewEntity={() => {
                      setShowCase(true);
                    }}
                  />
                )}

                <Input
                  label="Tytuł"
                  value={title}
                  placeholder="Tytuł"
                  onChange={(t) => setTitle(t)}
                  onChooseTag={addNewTag}
                  tags
                />

                <div className={styles.controls}>
                  <div className={styles.leftOptions}>
                    <DatePickerNew
                      showTime
                      onlyStart
                      startOf={startOf}
                      setDate={(type, date) => {
                        setStartOf(date);
                      }}
                    />

                    <SearchEntitiesNew
                      label={entity ? entity.Label ?? entity.Name : 'Nadawca'}
                      onChoose={(entity) => {
                        setEntity(entity);
                      }}
                      onCreate={(isC) => {
                        setShowEntity(true);
                        setIsCompany(isC);
                      }}
                    />

                    {design.tags && (
                      <ChooseTagNew type={6} onChoose={addNewTag} />
                    )}
                  </div>
                  <div className={styles.rightOptions}>
                    <Button
                      className={styles.btn3}
                      text="+ Dodaj"
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                      }}
                    />
                    {showDropdown && (
                      <div ref={dropdownRef} className={styles.dropdown}>
                        <div
                          onClick={() => {
                            setDesign({ ...design, date: !design.date });
                          }}
                          className={styles.box}
                        >
                          <SecondCheckbox
                            value={design.date}
                            onChange={() => {}}
                            // onChange={(s) => setDesign({ ...design, tags: s })}
                          />
                          Data nadania
                        </div>

                        <div
                          onClick={() => {
                            setDesign({ ...design, from: !design.from });
                          }}
                          className={styles.box}
                        >
                          <SecondCheckbox
                            value={design.from}
                            onChange={() => {}}
                            // onChange={(s) => setDesign({ ...design, tags: s })}
                          />
                          Nadawca
                        </div>

                        <div
                          onClick={() => {
                            setDesign({
                              ...design,
                              redirect: !design.redirect,
                            });
                          }}
                          className={styles.box}
                        >
                          <SecondCheckbox
                            value={design.redirect}
                            onChange={() => {}}
                            // onChange={(s) => setDesign({ ...design, tags: s })}
                          />
                          Przekaż do
                        </div>

                        <div
                          onClick={() => {
                            setDesign({ ...design, case: !design.case });
                          }}
                          className={styles.box}
                        >
                          <SecondCheckbox
                            value={design.case}
                            onChange={() => {}}
                            // onChange={(s) => setDesign({ ...design, tags: s })}
                          />
                          Teczka
                        </div>

                        <div
                          onClick={() => {
                            setDesign({ ...design, tags: !design.tags });
                          }}
                          className={styles.box}
                        >
                          <SecondCheckbox
                            value={design.tags}
                            onChange={() => {}}
                            // onChange={(s) => setDesign({ ...design, tags: s })}
                          />
                          #Tag
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.list}>
                  {tags.map((tag) => (
                    <TagBadge
                      className={styles.tag}
                      removeClassName={styles.remove}
                      key={tag.Id}
                      tag={tag}
                      onRemove={handleRemoveTag}
                    />
                  ))}
                </div>
              </div>

              <div className={styles.editor}>
                {!files.length && !decodedFiles.length && <EmptyScreen />}
                {showVersion && (
                  <PDFViewer
                    headerClassName={styles.pdf}
                    onlyPDFButtons
                    withoutBorder
                    docId={previewUri}
                    showVersion={true}
                    file={new Blob([])}
                  />
                )}
                {previewFile && (
                  <PDFViewer
                    headerClassName={styles.pdf}
                    onlyPDFButtons
                    withoutBorder
                    docId={previewUri}
                    file={previewFile}
                  />
                )}
              </div>
            </div>
            <div
              style={{
                display:
                  !files.length && !decodedFiles.length ? 'none' : 'block',
              }}
              className={styles.right}
            >
              <div className={styles.rightButtons}>
                <Button
                  className={styles.btn1}
                  element={
                    <>
                      <AIcon /> Dodaj załącznik
                    </>
                  }
                  onClick={handleClickToUpload}
                />
              </div>
              <DragDropContext onDragUpdate={() => {}} onDragEnd={handleOnDrag}>
                <Droppable droppableId="files">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {decodedFiles.map((file: any, index: any) => (
                        <Filedossier
                          index={index}
                          file={file}
                          useDrag
                          isSelected={
                            index === selectedPreviewFile && isUploadedFile
                          }
                          handleRemove={removeFile}
                          onClick={() => {
                            setIsUploadedFile(true);
                            setSelectedPreviewFile(index);
                            createPreview(file);
                          }}
                        />
                      ))}
                      {files.map((file, index) => (
                        <Filedossier
                          index={index}
                          isSelected={
                            index === selectedPreviewFile && !isUploadedFile
                          }
                          file={file}
                          useDrag
                          onClick={() => {
                            setIsUploadedFile(false);
                            setSelectedPreviewFile(index);
                            createPreview(file);
                          }}
                          handleRemove={removeFile}
                        />
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>

          <div className={styles.footer}>
            <Button
              className={styles.btn2}
              text="Utwórz"
              handleError={handleError}
              disabled={isDisabled}
              onClick={handleAccept}
            />
          </div>
        </div>

        <input
          type="file"
          id="file"
          ref={inputFile}
          multiple
          style={{ display: 'none' }}
          onChange={handleChooseFile}
        />
        {showEntity && (
          <EditClientModalNew
            onClose={() => {
              setShowEntity(false);
            }}
            onCreate={(c) => {
              setEntity(c);
            }}
          />
        )}
        {showCase && (
          <CreateCaseModal
            onCreate={(c) => {
              setCase(c);
            }}
            onClose={() => setShowCase(false)}
          />
        )}
      </>
    </ModalLayout>
  );

  // return (
  //   <div className={styles.modal}>
  //     <div className={styles.header}>Przesyłka papierowa</div>
  //     <div className={styles.wrapper}>
  //       <div className={styles.previewWrapper}>

  //       </div>
  //       <div className={styles.optionsWrapper}>
  //         <div className={styles.fileListWrapper}>
  //           <div className={styles.title}>Pliki</div>
  //           <div className={styles.fileList}>
  //             {decodedFiles.map((file: any, index: any) => {
  //               return (
  //                 <div
  //                   onClick={(e: any) => {
  //                     const el = e.target.tagName;
  //                     const className = e.target.className;

  //                     if (el === 'svg' || className?.search?.('check') !== -1) {
  //                       return;
  //                     }

  //                     setIsUploadedFile(true);
  //                     setSelectedPreviewFile(index);
  //                     createPreview(file);
  //                   }}
  //                   className={`${styles.fileBox} ${
  //                     isUploadedFile &&
  //                     selectedPreviewFile === index &&
  //                     styles.active
  //                   }`}
  //                 >
  //                   <div className={styles.info}>
  //                     <AttachmentIconComponent file={file.FileName} />
  //                     <div className={styles.fileData}>
  //                       <div className={styles.fileName}>{file.FileName}</div>
  //                       <div className={styles.fileSize}>
  //                         {prettyBytes(file.decryptedFile.size)}
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <SecondCheckbox
  //                     onChange={() => handleChangeStateFile(file)}
  //                     value={
  //                       !!selectedFiles.find((sfile) => sfile === file.Uri)
  //                     }
  //                   />
  //                 </div>
  //               );
  //             })}
  //             {files.map((file, index) => {
  //               return (
  //                 <div
  //                   onClick={(e: any) => {
  //                     const el = e.target.tagName;
  //                     const className = e.target.className;

  //                     if (el === 'svg' || className?.search?.('check') !== -1) {
  //                       return;
  //                     }

  //                     setIsUploadedFile(false);
  //                     setSelectedPreviewFile(index);
  //                     createPreview(file);
  //                   }}
  //                   className={`${styles.fileBox} ${
  //                     !isUploadedFile &&
  //                     selectedPreviewFile === index &&
  //                     styles.active
  //                   }`}
  //                 >
  //                   <div className={styles.info}>
  //                     <AttachmentIconComponent file={file.name} />
  //                     <div className={styles.fileData}>
  //                       <div className={styles.fileName}>{file.name}</div>
  //                       <div className={styles.fileSize}>
  //                         {prettyBytes(file.size)}
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <SecondCheckbox
  //                     onChange={() => handleChangeStateFile(file)}
  //                     value={
  //                       !!selectedFiles.find(
  //                         //@ts-ignore
  //                         (sfile) => sfile === file.metadata.Uri
  //                       )
  //                     }
  //                   />
  //                 </div>
  //               );
  //             })}
  //           </div>
  //           {/* Dodaj załącznik */}
  //           <AddButton
  //             className={styles.addButton}
  //             text="Dodaj załącznik"
  //             onClick={handleClickToUpload}
  //           />
  //         </div>
  //         <div className={styles.form}>
  //           {parcelInformation.RegistryOfficeEmployee && (
  //             <Dropdown
  //               onChange={(r) => setReceiver(r?.value)}
  //               list={parcelInformation.Receivers.map((r: any) => ({
  //                 name: r.Label,
  //                 value: r.Id,
  //               }))}
  //             />
  //           )}
  //           {isMe && (
  //             <SearchCases
  //               setAddNewEntity={setShowCreateNew}
  //               onChoose={(c) => setCase(c)}
  //             />
  //           )}
  //           <SearchEntities
  //             doNotRemove
  //             isError={errors.entity ? 'Wprowadź nadawcę' : ''}
  //             label="Nadawca"
  //             onChoose={(c) => setEntity(c)}
  //           />
  //           <TextArea
  //             label="Tytuł"
  //             isError={errors.title ? 'Wprowadź tytuł' : ''}
  //             onChange={(t) => setTitle(t)}
  //           />
  //           <DatePickerComponent
  //             label="Data wpływu"
  //             date={date}
  //             className={styles.customWrapper}
  //             setDate={(d) => setDate(d)}
  //             showTime={false}
  //           />
  //           <div className={styles.check}>
  //             {/* <SecondCheckbox onChange={() => {}} text="Tryb opracowania" /> */}
  //           </div>

  //           <div className={styles.clickButton}>
  //             <HollowButton onClick={handleClickCloseModal} text="Anuluj" />
  //             <Button
  //               handleError={handleError}
  //               disabled={isDisabled}
  //               text="Utwórz"
  //               onClick={handleAccept}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <input
  //       type="file"
  //       id="file"
  //       ref={inputFile}
  //       style={{ display: 'none' }}
  //       onChange={handleChooseFile}
  //     />
  //   </div>
  // );
};

export default AddNewPackageModal;
