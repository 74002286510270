import { Base64 } from 'base64-string';
import { IDecryptedKeys } from './decryptKeys';
import { decryptMasterKey } from './decryptMasterKey';
import { decryptIntermidatePrivateKey } from './decryptIntermidatePrivateKey';
// import { decryptIntermidatePublicKey } from './decryptIntermidatePublicKey';

export const getKeys = async (
  userId: string
): Promise<IDecryptedKeys | null> => {
  const serializedKeys = localStorage.getItem(`${userId}.keys`);
  if (!serializedKeys?.length) return null;

  const enc = new Base64();
  let keys = JSON.parse(enc.decode(serializedKeys));

  keys.masterKey = await decryptMasterKey(keys);
  keys.intermidatePrivateKey = await decryptIntermidatePrivateKey(keys);
  // 
  // keys.IntermediatePublicKey = await decryptIntermidatePublicKey(keys);

  return keys;
};
