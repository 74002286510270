import { ESearchTypes } from 'components/SearchBar/typings';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useOnClickOutside } from 'hooks/useClickOutside';
import { useRef } from 'react';

const SearchDropdown: FC<Props> = ({ onChoose, refWrapper, toggle }) => {
  if (!refWrapper?.current) return <></>;

  const isCases = window.location.href.search('cases') !== -1;
  const isPackages = window.location.href.search('packages') !== -1;
  const isSearch = window.location.href.search('actions') !== -1;
  const position = refWrapper.current.getBoundingClientRect();

  const refClose = useRef(null);

  useOnClickOutside(refClose, () => {
    toggle();
  });

  return (
    <div
      ref={refClose}
      style={
        {
          // left: `${position.left}px`,
          // top: `${position.top}px`,
        }
      }
      className={styles.dropdown}
    >
      <div onClick={() => onChoose(ESearchTypes.STATUS)} className={styles.box}>
        Status
      </div>
      <div onClick={() => onChoose(ESearchTypes.TAG)} className={styles.box}>
        Tagi
      </div>
      {isPackages && (
        <div
          onClick={() => onChoose(ESearchTypes.CHANNEL)}
          className={styles.box}
        >
          Kanały komunikacji
        </div>
      )}
      {isCases ||
        (isSearch && (
          <div
            onClick={() => onChoose(ESearchTypes.SHARED)}
            className={styles.box}
          >
            Udostępnione
          </div>
        ))}
    </div>
  );
};

export default SearchDropdown;
