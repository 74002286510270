import workspaceService from 'api/workspaceService';
import styles from './styles.module.scss';
import { useContext, useEffect, useMemo, useState } from 'react';
import { IWorkspace } from 'dto/IWorkspace';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { setWorkspaceId } from 'utils/setWorkspaceId';
import WorkspaceContext from 'contexts/CurrentWorkspace';
import { useNavigate } from 'react-router-dom';

const ChooseWorkspace = () => {
  const navigate = useNavigate();
  const { data: workspacesData } = workspaceService.useGetWorkspaces();
  const { mutate: switchWorkspace } = workspaceService.useWorkspaceSwitch();
  const { setWorkspace } = useContext(WorkspaceContext);

  const [selected, setSelected] = useState<null | string>(null);

  const workspaces: IWorkspace[] = useMemo(
    () => workspacesData?.data.Items ?? [],
    [workspacesData]
  );

  const mappedWorkspaces = workspaces.map((w) => {
    return {
      name: w.Name,
      value: w.Id,
    };
  });

  useEffect(() => {
    if (!mappedWorkspaces?.[0]) return;
    if (selected) return;
    setSelected(mappedWorkspaces[0].value);
  }, [mappedWorkspaces]);

  const handleChoose = () => {
    if (!selected) return;
    setWorkspaceId(selected);

    const workspace = workspaces.find((w) => w.Id === selected);

    setWorkspace(workspace!);

    switchWorkspace(
      {
        Id: selected,
      },
      {
        onSuccess: () => navigate('/'),
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.title}>Wybierz workspace</div>
        <div className={styles.input}>
          <Dropdown
            defaultValue={selected}
            list={mappedWorkspaces}
            onChange={(m) => {
              setSelected(m.value);
            }}
            disableChange
          />
        </div>
        <div className={styles.button}>
          <Button text="Otwórz" disabled={!selected} onClick={handleChoose} />
        </div>
      </div>
    </div>
  );
};

export default ChooseWorkspace;
