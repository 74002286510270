import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Input from 'components/Input';
import { useContext, useState } from 'react';
import Button from 'components/Button';
import SecondCheckbox from 'components/SecondCheckbox';
import dossierService from 'api/dossierService';
import { ENotificationType, notification } from 'utils/notification';
import fileService from 'api/fileService';
import PackagesContext from 'contexts/PackagesContext';

const EditAttachment: FC<Props> = ({
  attachmentId,
  ownerId,
  ownerKind,
  label,
  toggle,
  refetch,
}) => {
  const [value, setValue] = useState(label);
  const [check, setCheck] = useState(false);

  const { mutate: updateAttachment } = fileService.useUpdateAttachment();

  const { packages, setPackages } = useContext(PackagesContext);

  const handleSave = () => {
    // toggle(false);
    updateAttachment(
      {
        AttachmentId: attachmentId,
        Label: value,
        OwnerId: decodeURIComponent(ownerId),
        OwnerKind: Number(ownerKind),
      },
      {
        onSuccess: () => {
          const newPacks = [...packages];

          const pack = newPacks.find(
            (p) => decodeURIComponent(p.Id) === decodeURIComponent(ownerId)
          );

          if (pack) {
            const attachments = pack.Attachments;

            const attachment = attachments.find((a) => a.Id === attachmentId);

            if (attachment) {
              attachment.Label = value;
            }
          }

          setPackages(newPacks);

          toggle(false);
        },
      }
    );
  };

  return (
    <div className={styles.modal}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Edytuj tytuł pliku
          <CloseIcon onClick={() => toggle(false)} />
        </div>

        <div className={styles.input}>
          <Input
            label="Tytuł pliku"
            onChange={(e) => setValue(e)}
            value={value}
          />
        </div>

        <div className={styles.button}>
          <Button onClick={handleSave} text="Zapisz" />
        </div>
      </div>
    </div>
  );
};

export default EditAttachment;
