import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as AddNew } from 'assets/icons/add_new.svg';
import { ReactComponent as MinusNew } from 'assets/icons/minus_new.svg';
import { ReactComponent as PaintIcon } from 'assets/icons/paint.svg';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as Print } from 'assets/icons/print.svg';
import { ReactComponent as TagIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as Reverse } from 'assets/icons/reverse.svg';
import Annotations from 'components/Annotations';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as AttachIcon } from 'assets/icons/case.svg';
import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';
import { ReactComponent as NewIcon } from 'assets/icons/new_tab.svg';
import Comments from 'components/Comments';
import LayoutContext from 'contexts/LayoutContext';
import PDFContext from 'contexts/PDFContext';
import html2canvas from 'html2canvas';
import listService from 'api/listService';
import AttachCaseModal from 'components/AttachCaseModal';
import ChatActionsList from 'components/ChatActionList';
import CreateActionModal from 'components/CreateActionModal';
import ModalLayout from 'components/ModalLayout';
import PackagesContext from 'contexts/PackagesContext';
import SignatureLabel from 'components/SignatureLabel';
import { getSizeOfImage } from 'utils/getSizeOfImage';
import CertificateApi from 'components/CertificateApi';
import CertContext from 'contexts/CertContext';
import { BodyPluginUpdate } from 'components/BodyPluginUpdate';
import CPluginContext from 'contexts/PluginContext';
import Spinner from 'components/Spinner';
import ErrorScreen from 'components/ErrorScreen';
import pluginService from 'api/pluginService';
import { ReactComponent as SignIcon } from 'assets/icons/sign.svg';
import EditDossier from 'components/EditDossier';
import EditAttachment from 'components/EditAttachment';
import casesService from 'api/casesService';

const PDFViewer: FC<Props> = ({
  docId,
  className = '',
  headerClassName = '',
  withoutBorder = false,
  drawer = true,
  showSignButton = false,
  file,
  sourceFile,
  showHeader = true,
  onlyPDFButtons = false,
  isRightSide = false,
  showVersion = false,
  title,
  showTitle = false,
  onCloseButton,
}) => {
  const {
    filterId,
    status,
    kind,
    packageId,
    threadId,
    PDFId,
    caseId,
    messageId,
    threadPackageId,
    attachmentId,
    templateId,
    templateKind,
  } = useParams();

  const documentID =
    docId ??
    `${threadPackageId ?? packageId ?? threadId ?? messageId ?? templateId}:${
      kind ?? templateKind
    }:${attachmentId ?? messageId ?? PDFId ?? templateId}`;

  const {
    data: packageData,
    refetch,
    isLoading,
  } = listService.useGetPackage({
    FilterId: threadPackageId ?? packageId ?? messageId ?? attachmentId,
    Kind: kind ?? '',
  });

  const isLetters = status === 'pisma';
  const isDossier = window.location.href.search('dossier') !== -1;

  const { data: dossierData, refetch: dossierRefetch } =
    casesService.useGetDossier(caseId!, null, isDossier);

  const item = dossierData?.data.Items.find((a: any) => a.Id === attachmentId);

  const { mutate: openEditorPlugin } = pluginService.useOpenEditor();

  const pack = useMemo(() => packageData?.data.Items[0] ?? [], [packageData]);
  const navigate = useNavigate();
  const { sizes } = useContext(LayoutContext);
  const [rotate, setRotate] = useState(0);
  const [canvasSize, setCanvasSize] = useState([0, 0]);
  const [pages, setPages] = useState(0);

  const { plugin } = useContext(CPluginContext);

  const [drawLine, setDrawLine] = useState(false);
  const [drawPin, setDrawPin] = useState(false);
  const [setEdit, setShowEdit] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const testRef = useRef(null);

  const { PDF: pdf, setPDF } = useContext(PDFContext);
  const { cert } = useContext(CertContext);

  useEffect(() => {
    if (!ref?.current || sizes.isDraging) return;
    const c = ref?.current.getBoundingClientRect();
    setCanvasSize([
      c.width - 10,
      // 500,
      ref?.current.offsetHeight * pdf.zoom * pages + 1,
    ]);
    setPDF({ ...pdf, canvas: ref });
  }, [ref, pdf.zoom, sizes, pages]);

  const handleRotate = (opositeSite: boolean) => {
    if (opositeSite) {
      if (rotate + 90 > 360) {
        setRotate(90);
        return;
      }
      setRotate(rotate + 90);
      return;
    }

    if (rotate - 90 < 0) {
      setRotate(270);
      return;
    }

    setRotate(rotate - 90);
  };

  const handleClose = () => {
    if (onCloseButton) {
      onCloseButton();
      return;
    }
    navigate(-1);
    return;
    // if (threadId) {
    //   //
    //   navigate(`/packages/thread/${filterId}/${status}/${kind}/${threadId}`);
    //   return;
    // }
    // navigate(`/packages/${filterId}/${status}/${kind}/${packageId}`);
  };

  const attachWrapper = useRef(null);

  const openEditor = () => {
    openEditorPlugin({
      Id: pack.Id,
      Kind: pack.Kind,
    });
  };

  const printPDF = () => {
    //@ts-ignore
    html2canvas(ref.current).then((canvas) => {
      const printWindow = window.open('', '', 'width=600,height=600');
      printWindow!.document.open();
      printWindow!.document.append(canvas);
      printWindow!.document.close();
      printWindow!.focus();
      printWindow!.print();
      // setTimeout(() => {
    });
  };

  const [showAttach, setShowAttach] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showCreateAction, setShowCreateAction] = useState(false);
  const { packages, setPackages } = useContext(PackagesContext);

  const handleCreateAction = () => {
    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      el.TasksCount = el.TasksCount + 1;
      setPackages(newPacks);
    }
    refetch();
  };

  const handleDeleteAction = () => {
    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      el.TasksCount = el.TasksCount - 1;
      setPackages(newPacks);
    }
    refetch();
  };

  const [isSignature, setIsSignature] = useState(false);

  const handleChangeZoom = (value: number) =>
    setPDF({ ...pdf, zoom: value as number });

  const [iSize, setISize] = useState({
    w: 0,
    h: 0,
  });

  const getSize = async () => {
    try {
      const sizeOf = await getSizeOfImage(file as Blob);
      //@ts-ignore
      setISize({ w: sizeOf!.w, h: sizeOf!.h });
    } catch (e) {}
  };

  const [init, setInit] = useState(true);

  const calculateScale = () => {
    if (!init) return;
    if (iSize.w === 0) return;
    if (!testRef?.current) return;
    setInit(false);
    const w = canvasSize[0];
    const h = canvasSize[1];

    var scale = w / iSize.w;

    handleChangeZoom(scale);
  };

  const { setCert } = useContext(CertContext);

  const openCert = () => {
    setCert({ id: pack.Id, name: pack.S1 });
  };

  useEffect(() => {
    getSize();
  }, [file]);

  useEffect(() => {
    calculateScale();
  }, [iSize, canvasSize]);

  const showCommentsOnRight = isRightSide || sizes.height === 100;

  const [editComment, setEditComment] = useState('');

  function blob2uint(blob: any) {
    return new Response(blob).arrayBuffer().then((buffer) => {
      //@ts-ignore
      const uint = [...new Uint8Array(buffer)];
      //@ts-ignore
      console.log('Uint8Array', uint);
      //@ts-ignore
      return uint;
    });
  }

  return (
    <div className={styles.wrapper}>
      {showHeader && (
        <div
          className={`${styles.header} ${headerClassName} ${
            withoutBorder && styles.withoutBorder
          }`}
        >
          <div className={styles.leftWrapper}>
            <div className={styles.title}>
              {showTitle && (item?.Dossier?.Label ?? pack.S1)}
              {title ?? ''}
              {/* {getListName(listId ?? 0, false)} */}

              {/* <div className={styles.subTitle}>{listName}</div> */}
            </div>
          </div>
          <div className={styles.rightWrapper}>
            {/* <Dropdown
                      onChange={handleChangeZoom}
                      defaultValue={1}
                      list={[
                        { name: '50%', value: 0.5 },
                        { name: '100%', value: 1 },
                        { name: '133%', value: 1.33 },
                        { name: '150%', value: 1.5 },
                        { name: '200%', value: 2 },
                      ]}
                    /> */}
            {!onlyPDFButtons && (
              <div
                ref={attachWrapper}
                title="Edytuj"
                onClick={() => setShowEdit(!setEdit)}
                className={styles.button}
              >
                <EditIcon title="Edytuj" />
              </div>
            )}
            {isLetters && (
              <div
                ref={attachWrapper}
                title="Edytuj pismo"
                onClick={openEditor}
                className={styles.button}
              >
                <TagIcon title="Edytuj pismo" />
              </div>
            )}
            {showSignButton && (
              <div
                ref={attachWrapper}
                title="Podpisz elektronicznie"
                onClick={openCert}
                className={styles.button}
              >
                <SignIcon
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                  title="Podpisz elektronicznie"
                />
              </div>
            )}

            {!onlyPDFButtons && (
              <div
                title="Otwórz w nowym oknie"
                onClick={async () => {
                  const pdf = file as Blob;
                  const uint = await blob2uint(pdf);
                  var serializedUint8Array = JSON.stringify(Array.from(uint));
                  sessionStorage.setItem('pdf', serializedUint8Array);
                  window.open(
                    `${
                      process.env.REACT_APP_SITE ??
                      'https://alfa.pocztaprawnicza.pl'
                    }/pdf/${kind}/${
                      threadPackageId ?? packageId ?? threadId ?? messageId
                    }/${attachmentId ?? PDFId ?? messageId}`
                  );
                }}
                className={styles.button}
              >
                <NewIcon />
              </div>
            )}

            <div
              onClick={() => handleRotate(true)}
              className={styles.button}
              title="Obróć"
            >
              <Reverse />
            </div>

            <div
              title="Powiększ"
              onClick={() => handleChangeZoom(pdf.zoom + 0.15)}
              className={styles.button}
            >
              <AddNew />
            </div>
            <div
              title="Zmniejsz"
              onClick={() => handleChangeZoom(pdf.zoom - 0.15)}
              className={styles.button}
            >
              <MinusNew />
            </div>
            <div title="Wydrukuj" onClick={printPDF} className={styles.button}>
              <Print />
            </div>

            <div
              title="Dodaj komentarz liniowy"
              onClick={() => {
                setDrawLine(!drawLine);
                setDrawPin(false);
              }}
              className={`${styles.button} ${drawLine ? styles.active : ''}`}
            >
              <PaintIcon />
            </div>

            <div
              title="Dodaj komentarz punktowy"
              onClick={() => {
                setDrawLine(false);
                setDrawPin(!drawPin);
              }}
              className={`${styles.button} ${drawPin ? styles.active : ''}`}
            >
              <PinIcon />
            </div>

            {!onlyPDFButtons && (
              <CloseIcon
                title="Zamknij"
                className={styles.closeButton}
                onClick={handleClose}
              />
            )}
          </div>
        </div>
      )}
      {isSignature && (
        <div className={styles.descr}>
          <SignatureLabel content={file} />
        </div>
      )}
      {!!cert.id.length && (
        <div className={styles.descr}>
          <CertificateApi pack={pack} file={file} originalFile={sourceFile} />
        </div>
      )}
      <div className={`${styles.pageWrapper} ${className}`}>
        <div>
          <div ref={ref} className={styles.pdfWrapper}>
            {!showVersion && (
              <Document
                ref={testRef}
                file={file}
                error={
                  <div className={styles.error}>
                    <ErrorScreen text="Nie byliśmy w stanie otworzyć pliku" />
                  </div>
                }
                loading={
                  <div className={styles.spinner}>
                    <Spinner />
                  </div>
                }
                onLoadError={(e) => {}}
                rotate={rotate}
                onLoadSuccess={async (e) => {
                  setPages(e.numPages);
                  const metadata = await e.getMetadata();
                  //@ts-ignore
                  if (!metadata?.info?.IsSignaturesPresent) {
                    setIsSignature(false);
                    return;
                  }

                  //@ts-ignore
                  setIsSignature(metadata.info.IsSignaturesPresent);
                }}
              >
                {Array.apply(null, Array(pages))
                  .map((x, i) => i + 1)
                  .map((page) => (
                    <Page
                      className={styles.Page}
                      loading={<></>}
                      scale={pdf.zoom}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      pageNumber={page}
                    />
                  ))}
                {/* <Page
                    renderAnnotationLayer={true}
                    renderTextLayer={false}
                    scale={pdf.zoom}
                    pageNumber={pdf.page}
                  />
              */}
                <div
                  style={{
                    width: `${canvasSize[0]}px`,
                    height: `${canvasSize[1]}px`,
                  }}
                  className={styles.canvas}
                >
                  {!sizes.isDraging && (
                    <Annotations
                      setEditComment={setEditComment}
                      documentID={documentID}
                      refWrapper={ref}
                      setDrawLine={setDrawLine}
                      setDrawPin={setDrawPin}
                      drawLine={drawLine}
                      isRightScreen={showCommentsOnRight}
                      drawPin={drawPin}
                      zoom={pdf.zoom}
                      page={pdf.page}
                    />
                  )}
                </div>
              </Document>
            )}
            {showVersion && (
              <BodyPluginUpdate
                className={styles.wtyczka}
                isWindows={plugin.isWindows}
                isMac={plugin.isMac}
                linkWindows={plugin.linkWindows}
                linkMac={plugin.linkMac}
              />
            )}
          </div>
        </div>
        {sizes.height === 100 && (
          <div className={styles.commentsWrapper}>
            <Comments
              setEditComment={setEditComment}
              editComment={editComment}
              documentID={documentID}
            />
          </div>
        )}
        {pack && showAttach && (
          <ModalLayout customX={-50} customY={-50} refWrapper={attachWrapper}>
            <AttachCaseModal
              Package={pack}
              // position={{
              //   x: getPosition(refWrapper).x,
              //   y: getPosition(refWrapper).y + 45,
              // }}
              refWrapper={null}
              toggle={() => setShowAttach(false)}
            />
          </ModalLayout>
        )}

        {showActions && (
          <ModalLayout refWrapper={attachWrapper}>
            <div className={styles.modalee}>
              <ChatActionsList
                onDelete={handleDeleteAction}
                changeView={() => {
                  setShowActions(false);
                  setShowCreateAction(true);
                }}
                toggle={() => {
                  setShowActions(false);
                  setShowCreateAction(false);
                }}
                x={0}
                y={0}
                pack={pack}
              />
            </div>
          </ModalLayout>
        )}
        {showCreateAction && (
          <ModalLayout refWrapper={attachWrapper}>
            <div className={styles.modalee}>
              <CreateActionModal
                kind={pack.Kind}
                initialValue={{
                  parcelId: pack.Id,
                }}
                showCase={false}
                refetch={() => {}}
                onCreate={handleCreateAction}
                toggle={() => setShowCreateAction(false)}
                x={0}
                y={0}
              />
            </div>
          </ModalLayout>
        )}
        {setEdit && (
          <>
            {isDossier ? (
              <ModalLayout refWrapper={attachWrapper}>
                <EditDossier
                  file={item}
                  refetch={dossierRefetch}
                  toggle={() => {
                    setShowEdit(false);
                  }}
                />
              </ModalLayout>
            ) : (
              <ModalLayout refWrapper={attachWrapper}>
                <EditAttachment
                  attachmentId={attachmentId ?? PDFId ?? ''}
                  label={title ?? ''}
                  ownerId={packageId!}
                  ownerKind={kind!}
                  refetch={() => {}}
                  toggle={() => {
                    setShowEdit(false);
                  }}
                />
              </ModalLayout>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PDFViewer;
