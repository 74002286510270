export enum ECaseViews {
  CHAT = 'chat',
  DOSSIER = 'dossier',
  ACTIONS = 'actions',
  LETTERS = 'letters',
  PACKAGES = 'packages',
  LETTER = 'letter',
  PARTIAL = 'partial',
  TASKS = 'tasks',
}
