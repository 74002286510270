import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { EViews, FC, Props } from './typings';

import settingService from 'api/settingsService';
import classNames from 'classnames';
import Input from 'components/Input';
import SecondCheckbox from 'components/SecondCheckbox';
import Avatar from 'components/Settings/PersonalTab/Avatar';
import { Claims } from 'dto/User/claims';
import { useAuth } from 'react-oidc-context';
import { getErrorMessage } from 'utils/getErrorMessage';
import { getUserClaim } from 'utils/getUserClaim';
import { getUserIdentityVerified } from 'utils/getUserIdentityVerified';
import { ENotificationType, notification } from 'utils/notification';
import ChangePassword from '../ChangePassword';
import PersonalTabCompany from '../PersonalTabCompany';
import userService from 'api/userService';
import Button from 'components/Button';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as EditIcon } from 'assets/icons/mode_edit.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum PersonalTabMode {
  Normal,
  ChangePassword,
  Address,
}

const PersonalTab: FC<Props> = ({ showTabBar }) => {
  const auth = useAuth();
  const { mutate: uploadAvatar } = settingService.useUploadAvatar();
  const { data: phoneNumberData } = settingService.useGetPhoneNumber();
  const { data: companyData, refetch } = userService.useGetCompany();
  const { mutate: updatePhone } = settingService.useSetPersonal();
  const inputFile = useRef<HTMLInputElement | null>(null);

  const [mode, setMode] = useState<PersonalTabMode>(PersonalTabMode.Normal);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [avatarRefetchTrigger, setAvatarRefetchTrigger] = useState<number>(0);
  const [profileData, setProfileData] = useState({
    pesel: '',
    email: '',
  });

  const changeMode = (mode: PersonalTabMode) => {
    setMode(mode);
    showTabBar(mode === PersonalTabMode.Normal);
  };

  const onInnerViewCanceled = () => {
    changeMode(PersonalTabMode.Normal);
  };

  const onInnerViewSaved = () => {
    changeMode(PersonalTabMode.Normal);
  };

  const handleBrowseAvatar = () => {
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleFileChanged = (files: FileList | null) => {
    if (files && files.length > 0) {
      var file = files![0];

      uploadAvatar(file, {
        onSuccess(data, variables, context) {
          setAvatarRefetchTrigger(avatarRefetchTrigger + 1);

          if (inputFile && inputFile.current) inputFile.current.value = '';
        },
        onError(e: any) {
          notification({
            title: 'Nie udało się dodać avatara',
            text: getErrorMessage(e.response.data),
            type: ENotificationType.ERROR,
          });
          if (inputFile && inputFile.current) inputFile.current.value = '';
        },
      });
    }
  };

  useEffect(() => {
    if (auth.user) {
      setIsVerified(getUserIdentityVerified(auth.user!));
      setProfileData({
        ...profileData,
        pesel: getUserClaim(auth.user, Claims.Eidas_PersonIdentifier),
        email: getUserClaim(auth.user, Claims.Email),
      });
    }
  }, [auth.user]);

  const [actualView, setActualView] = useState<EViews>(EViews.PERSONAL);
  const [alone, setAlone] = useState(true);
  const [editPhone, setEditPhone] = useState(false);

  const [email, setEmail] = useState(profileData?.email);
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    console.log(phoneNumberData);
    if (!phoneNumberData?.data?.Phone) return;
    console.log('phone', phoneNumberData.data.Phone);
    setPhone(phoneNumberData?.data?.Phone);
  }, [phoneNumberData]);

  const [edit, setEdit] = useState({
    email: false,
    password: false,
    phone: false,
  });

  useEffect(() => {
    setEmail(profileData?.email);
  }, [profileData]);

  const startEdit = (type: string) => {
    if (type === 'email') {
      setEdit({
        ...edit,
        email: true,
      });
    }
    if (type === 'phone') {
      setEdit({
        ...edit,
        phone: true,
      });
    }
    if (type === 'password') {
      setEdit({
        ...edit,
        password: true,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      {mode === PersonalTabMode.Normal && (
        <>
          <div style={{ width: '95%' }}>
            {/* <div className={styles.header}>Twoje dane</div> */}
            <div className={styles.primary}>
              <div className={styles.avatar}>
                <Avatar
                  onClick={handleBrowseAvatar}
                  className={styles.avatar}
                  name={auth.user?.profile.name ?? ''}
                  refetchTrigger={avatarRefetchTrigger}
                />
                <div className={styles.data}>
                  <div className={styles.bold}>
                    Witaj, {auth.user?.profile.name}{' '}
                  </div>
                  <div className={styles.hint}>
                    Aktualnie zapisane dane w bazie. Możesz samodzielnie
                    edytować dane aby jak najlepiej wykorzystać możliwości,
                    które daje Notaup. Wskaż dane, które mogą być udostępnione
                    innym użytkownikom Notaup
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.personPinfo}>
              <div className={styles.Pinfo}>
                <div className={styles.s}>
                  <div className={styles.left}>
                    <div className={styles.nazwa}>
                      {auth.user?.profile.name}
                    </div>
                    <div className={styles.pesel}>
                      Pesel: {profileData?.pesel}
                    </div>
                    {
                      <div
                        className={classNames(
                          styles.verification,
                          isVerified ? styles.verified : ''
                        )}
                      >
                        {isVerified
                          ? 'Dane zweryfikowane'
                          : 'Dane niezweryfikowane'}
                      </div>
                    }
                  </div>
                  <div className={styles.right}></div>
                </div>
                <div className={styles.e}>{/* <EditIcon /> */}</div>
              </div>

              <div className={styles.Pinfo}>
                <Input
                  className={styles.PInput}
                  value={email}
                  disabled={!edit.email}
                  label="Email"
                  focus={!!edit.email}
                  onChange={(e) => {
                    setEmail(e);
                  }}
                />

                <div style={{ marginLeft: '16px' }} className={styles.e}>
                  {/* <EditIcon onClick={() => startEdit('email')} /> */}
                </div>
              </div>
              <div className={styles.Pinfo}>
                <Input
                  className={styles.PInput}
                  value={!edit.password ? 'randomowhaseło' : password}
                  disabled={!edit.password}
                  label="Hasło"
                  type="password"
                  onChange={(e) => {
                    setPassword(e);
                  }}
                />
                <div
                  onClick={() => {
                    setMode(PersonalTabMode.ChangePassword);
                  }}
                  style={{ marginLeft: '16px' }}
                  className={styles.e}
                >
                  <EditIcon />
                </div>
              </div>
              <div className={styles.Pinfo}>
                <Input
                  className={styles.PInput}
                  value={phone ?? 'Brak'}
                  disabled={!edit.phone}
                  label="Numer telefonu"
                  onChange={(e) => {
                    setPhone(e);
                  }}
                />
                <div
                  onClick={() => {
                    if (!edit.phone) {
                      startEdit('phone');
                    } else {
                      setEdit({ ...edit, phone: false });
                      updatePhone(
                        { Phone: phone },
                        {
                          onSuccess: () => {
                            setEditPhone(false);
                          },
                        }
                      );
                    }
                  }}
                  style={{ marginLeft: '16px' }}
                  className={styles.e}
                >
                  {edit.phone ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <EditIcon />
                  )}
                </div>
              </div>
              <div className={styles.Pinfo}>
                <Input
                  className={styles.PInput}
                  value={'Brak'}
                  disabled={!edit.phone}
                  label="Adres korespodencyjny"
                  onChange={() => {}}
                />
                <div style={{ marginLeft: '16px' }} className={styles.e}>
                  {/* <EditIcon /> */}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.group}>
            <div className={styles.hint}>
              Notaup jest zintegrowana z Krajowym Systemem Doręczeń
              Elektronicznych. Możesz uzyskać Adres do Doręczeń Elektronicznych
              wysyłając wniosek bezpośrednio z Notaup.
            </div>

            <div className={styles.buttonWrapper}>
              <Button
                className={styles.ebutton}
                text="Aktywuj adres do e-doręczeń"
                onClick={() => {}}
              />
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Zamknij konto</div>
            <div className={styles.hint}>
              Zamknięcie konta spowoduję trwałe i nieodwracalne usunięcie danych
              konta. Przed zamknięciem konta pobierz dane korzystając z
              narzędzia eksportu danych lub wybierz “Zamknij i pobierz dane”
            </div>

            <div className={styles.buttonWrapper}>
              <div className={styles.btn}>Zamknij</div>
              <Button
                className={styles.errorButton}
                text="Zamknij i pobierz dane"
                onClick={() => {
                  window.open(`${process.env.REACT_APP_SITE}/export`, '_blank');
                }}
              />
            </div>
          </div>
        </>
      )}
      {mode == PersonalTabMode.ChangePassword && (
        <ChangePassword
          onCanceled={onInnerViewCanceled}
          onSaved={onInnerViewSaved}
        ></ChangePassword>
      )}
    </div>
  );

  // return (
  //   <div className={styles.wrapper}>
  //     {mode == PersonalTabMode.Normal && (
  //       <>
  //         {/* {actualView === EViews.COMPANY && (
  //           <PersonalTabCompany refetch={refetch} company={companyData?.data} />
  //         )} */}
  //         {actualView === EViews.PERSONAL && (
  //           <div style={{ width: '95%' }}>
  //             {/* <div className={styles.header}>Twoje dane</div> */}
  //             <div className={styles.primary}>
  //               <div className={styles.avatar}>
  //                 <Avatar
  //                   onClick={handleBrowseAvatar}
  //                   className={styles.avatar}
  //                   name={auth.user?.profile.name ?? ''}
  //                   refetchTrigger={avatarRefetchTrigger}
  //                 />
  //                 <div className={styles.data}>
  //                   <div className={styles.bold}>
  //                     Witaj, {auth.user?.profile.name}{' '}
  //                   </div>
  //                   <div className={styles.hint}>
  //                     Aktualnie zapisane dane w bazie. Możesz samodzielnie
  //                     edytować dane aby jak najlepiej wykorzystać możliwości,
  //                     które daje Notaup. Wskaż dane, które mogą być udostępnione
  //                     innym użytkownikom Notaup
  //                   </div>
  //                   {/* <div className={styles.bold}>
  //                     Pesel: {profileData.pesel}{' '}
  //                   </div> */}
  //                   {/* <div
  //                     className={classNames(
  //                       styles.verification,
  //                       isVerified ? styles.verified : ''
  //                     )}
  //                   >
  //                     {isVerified
  //                       ? 'Dane zweryfikowane'
  //                       : 'Dane niezweryfikowane'}
  //                   </div> */}
  //                 </div>
  //               </div>
  //             </div>
  //             <div style={{ marginTop: '12px' }} className={styles.header}>
  //               Dane kontaktowe
  //             </div>
  //             <div className={styles.information}>
  //               <div className={styles.info}>
  //                 <Input
  //                   type="text"
  //                   onChange={(v) => setPhone(v)}
  //                   label="Numer telefonu"
  //                   value={phone ?? 'Brak'}
  //                   disabled={!editPhone}
  //                 ></Input>
  //                 <div
  //                   onClick={() => {
  //                     if (!editPhone) {
  //                       setEditPhone(true);
  //                       return;
  //                     }

  //                     updatePhone(
  //                       { Phone: phone },
  //                       {
  //                         onSuccess: () => {
  //                           setEditPhone(false);
  //                         },
  //                       }
  //                     );
  //                   }}
  //                   className={styles.changePassword}
  //                 >
  //                   {editPhone ? <>Zapisz</> : <>Zmień telefon</>}
  //                 </div>
  //               </div>
  //               <div className={styles.info}>
  //                 <Input
  //                   className={styles.info}
  //                   type="text"
  //                   onChange={(v) => {}}
  //                   label="Email"
  //                   value={profileData.email}
  //                   disabled
  //                 ></Input>
  //               </div>
  //             </div>
  //             <div style={{ marginTop: '12px' }} className={styles.header}>
  //               Hasło
  //             </div>
  //             <div className={styles.info}>
  //               <Input
  //                 className={styles.info}
  //                 type="password"
  //                 onChange={(v) => {}}
  //                 label="Hasło"
  //                 value={'asdsadadsdsa'}
  //                 disabled
  //               ></Input>
  //             </div>
  //             <div
  //               onClick={() => changeMode(PersonalTabMode.ChangePassword)}
  //               className={styles.changePassword}
  //             >
  //               Zmień hasło
  //             </div>

  //             <div style={{ marginTop: '12px' }} className={styles.header}>
  //               Jak będzie używana Notup.pl
  //             </div>
  //             <div className={styles.checkboxes}>
  //               <SecondCheckbox
  //                 onChange={() => {
  //                   setAlone(true);
  //                 }}
  //                 value={alone}
  //                 text="Jednoosobowo"
  //               />
  //               <SecondCheckbox
  //                 onChange={() => {
  //                   setAlone(false);
  //                 }}
  //                 value={!alone}
  //                 text="Przeze mnie i mój zespół"
  //               />
  //             </div>
  //             <input
  //               type="file"
  //               ref={inputFile}
  //               style={{ display: 'none' }}
  //               onChange={(e) => {
  //                 handleFileChanged(e.target.files);
  //               }}
  //             ></input>
  //           </div>
  //         )}
  //       </>
  //     )}
  //     {mode == PersonalTabMode.ChangePassword && (
  //       <ChangePassword
  //         onCanceled={onInnerViewCanceled}
  //         onSaved={onInnerViewSaved}
  //       ></ChangePassword>
  //     )}
  //   </div>
  // );
};

export default PersonalTab;
