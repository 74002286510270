import Dropdown from 'components/Dropdown';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useMemo, useState } from 'react';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';
import Placeholder from 'assets/placeholder.png';
import SecondCheckbox from 'components/SecondCheckbox';

const EntityCard: FC<Props> = ({
  updateIsMain,
  entity,
  setEntityRole,
  removeEntity,
  withoutRole,
}) => {
  const { data: tagsData } = tagsService.useGetTags();
  const allTags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const Rlist: { name: string; value: string }[] = allTags
    .filter((t: ITag) => t.Type === 3)
    .map((t: ITag) => {
      return {
        name: t.Name,
        value: t.Id,
      };
    });

  const [showRoles, setShowRoles] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsDelete(true)}
      onMouseLeave={() => setIsDelete(false)}
      className={styles.card}
    >
      <div className={styles.settings}>
        <BinIcon onClick={() => removeEntity(entity.Id!)} />
      </div>

      <div className={styles.name}>
        <SecondCheckbox
          onChange={() => {
            updateIsMain?.(entity.Id!);
          }}
          value={entity.IsMain}
        />
        <img src={Placeholder} />
        {entity.Label ?? entity.Name}
      </div>
      {!withoutRole && (
        <div className={styles.options}>
          <div onClick={() => setShowRoles(!showRoles)} className={styles.role}>
            {!!Rlist.find((role) => role.value === entity.RoleId)?.value
              ? Rlist.find((role) => role.value === entity.RoleId)?.name
              : '...'}
            {showRoles && (
              <div className={styles.rolesModal}>
                {Rlist.map((role) => (
                  <div
                    onClick={() => setEntityRole(entity.Id!, role.value)}
                    key={role.value}
                    className={styles.box}
                  >
                    {role.name ?? '...'}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EntityCard;
