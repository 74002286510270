import TagBadge from 'components/TagBadge';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';

import Placeholder from 'assets/placeholder.png';
import Button from 'components/Button';
import { ReactComponent as PhoneIcon } from 'assets/icons/PhoneWhite.svg';
import { ReactComponent as EditIcon } from 'assets/icons/EditClient.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteClient.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/clientCase.svg';
import { ReactComponent as PersonalClientIcon } from 'assets/icons/PersonalClient.svg';
import { ReactComponent as PersonClientIcon } from 'assets/icons/PersonClient.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/company/note.svg';
import { useContext, useEffect, useMemo, useState } from 'react';
import PersonalDataClient from 'components/PersonalDataClient';
import clientService from 'api/clientService';
import { ClientFlags, EClientData, IClient } from 'dto/IClient';
import CasesClient from 'components/CasesClient';
import { checkFlags } from 'utils/checkFlags';
import Avatar from 'components/Avatar';
import { getAvatar } from 'utils/getAvatar';
import EditClientModalNew from 'components/EditClientModalNew';
import ClientsContext from 'contexts/ClientsContext';
import { useNavigate } from 'react-router-dom';
import { EStatus } from 'components/Avatar/typings';
import ModalsManagerContext from 'contexts/ModalsManager';
import PersonsClient from 'components/PersonsClient';
import Loading from 'components/Loading';

const Header = ({ client }: { client: IClient }) => {
  const emails =
    client?.Data?.filter(
      (d) => d.Type === EClientData.EMAIL && !!d.Value.length
    ) ?? [];

  const phones =
    client?.Data?.filter(
      (d) => d.Type === EClientData.PHONE && !!d.Value.length
    ) ?? [];

  const { mutate: deleteD } = clientService.useDeleteClient();
  const hasAvatar = checkFlags(client.Flags, ClientFlags.HasAvatar);

  const [av, setAv] = useState<any>(null);

  const fetchAvatar = async () => {
    setAv(await getAvatar(client!.Id!));
  };

  useEffect(() => {
    if (!hasAvatar) return;
    fetchAvatar();
  }, [hasAvatar]);

  const [showEdit, setShowEdit] = useState(false);

  const { clients, setClients } = useContext(ClientsContext);
  const navigate = useNavigate();

  const { modals, setModals } = useContext(ModalsManagerContext);
  return (
    <div className={styles.header}>
      <Avatar
        avatar={av}
        // status={client.IsOnline === true ? EStatus.ONLINE : EStatus.OFFLINE}
        name={client.Name?.replaceAll('<em>', '')?.replaceAll('</em>', '')}
        className={styles.avatar}
      />

      <div className={styles.data}>
        <div className={styles.name}>
          {client.Name?.replaceAll('<em>', '')?.replaceAll('</em>', '')}
        </div>
        <div className={styles.tag}>
          {/* <TagBadge
            tag={{
              Id: '1',
              Flags: 1,
              Name: 'WSPÓŁPRACOWNIK',
              Global: true,
              Type: 1,
              WorkspaceId: ' ',
            }}
          /> */}
        </div>
        <div className={styles.options}>
          <Button
            disabled={!emails.length}
            className={styles.btn}
            onClick={() => {
              sessionStorage.setItem(
                'sendTo',
                emails.find((e) => e.IsMain)?.Value ?? emails[0].Value
              );
              setModals({ ...modals, createPost: true });
            }}
            text="Wyślij wiadomość"
          />
          <div
            onClick={() => {
              const phone =
                client?.Data?.find(
                  (d) => d.Type === EClientData.PHONE && d.IsMain
                )?.Value ?? null;
              if (!phone) return;
              window.parent.location.href = `tel:${phone}`;
            }}
            className={`${styles.add} ${styles.buttonAdd} ${
              !phones.length && styles.disabled
            }`}
          >
            <PhoneIcon />
          </div>
          <div onClick={() => setShowEdit(true)} className={styles.add}>
            <EditIcon />
          </div>
          <div
            onClick={() => {
              deleteD(
                {
                  Id: client.Id,
                },
                {
                  onSuccess: () => {
                    const newClients = [...clients];
                    setClients(newClients.filter((d) => d.Id !== client.Id));
                    navigate('/clients/all');
                  },
                }
              );
            }}
            className={styles.add}
          >
            <DeleteIcon />
          </div>
        </div>
      </div>

      {showEdit && (
        <EditClientModalNew
          label="Edytuj podmiot"
          onClose={() => setShowEdit(false)}
          initialValue={client}
          // newClient={true}
        />
      )}
    </div>
  );
};

const ClientNew: FC<Props> = ({ clientId }) => {
  const { data: clientData, refetch } = clientService.useGetClient(
    clientId ?? ''
  );
  const client: IClient = useMemo(() => clientData?.data ?? [], [clientData]);
  const [actualView, setActualView] = useState(ETabs.PERSONAL);

  const { clients, setClients } = useContext(ClientsContext);

  const [contextClient, setContextClient] = useState<IClient | null>(null);

  useEffect(() => {
    const newContextClient = clients.find((c) => c.Id === client.Id);

    setContextClient(newContextClient ?? null);
  }, [clients, clientId]);

  const contactPersons =
    contextClient?.Data?.filter?.(
      (d) => d.Type === EClientData.CONTACT_PERSON
    ) ?? [];

  const note =
    client?.Data?.find?.((d) => d.Type === EClientData.NOTE)?.Value ?? '';

  useEffect(() => {
    let findedClient = clients.find((c) => c.Id === client.Id);
    if (findedClient || !client) {
      const copy = JSON.parse(JSON.stringify(clients));
      let use = copy.find((c: IClient) => c.Id === client.Id);
      use = client;
      // setClients(clients);
      return;
    }
    // setClients([...clients, client]);
  }, [client]);

  const Menu = () => {
    return (
      <div className={styles.menu}>
        <div
          onClick={() => setActualView(ETabs.PERSONAL)}
          className={`${styles.box}  ${
            actualView === ETabs.PERSONAL && styles.activeText
          }`}
        >
          <PersonalClientIcon /> Dane
        </div>
        {!!contactPersons.length && (
          <div
            onClick={() => setActualView(ETabs.PERSONS)}
            className={`${styles.box}  ${
              actualView === ETabs.PERSONS && styles.activeText
            }`}
          >
            <PersonClientIcon /> {client?.IsInstitution ? 'Osoby' : 'Firmy'}
          </div>
        )}
        {!!note.length && (
          <div
            onClick={() => setActualView(ETabs.NOTE)}
            className={`${styles.box}  ${
              actualView === ETabs.NOTE && styles.activeText
            }`}
          >
            <NoteIcon /> Notatka
          </div>
        )}
        {client.CasesCount > 0 && (
          <div
            onClick={() => setActualView(ETabs.CASES)}
            className={`${styles.box}  ${
              actualView === ETabs.CASES && styles.activeText
            }`}
          >
            <CaseIcon /> Teczki
          </div>
        )}
      </div>
    );
  };

  if (!client) {
    return <Loading />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Header client={client} />
        <Menu />
        {actualView === ETabs.PERSONAL && (
          <PersonalDataClient refetch={refetch} client={client} />
        )}
        {actualView === ETabs.PERSONS && <PersonsClient client={client} />}
        {actualView === ETabs.CASES && <CasesClient />}
        {actualView === ETabs.NOTE && (
          <div className={styles.note}>
            <div
              dangerouslySetInnerHTML={{
                __html: note.replaceAll('<p>', '').replaceAll('</p>', ''),
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientNew;
