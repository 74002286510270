import './styles.module.scss';
import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';
import Input from 'components/Input';
import TagBadge from 'components/TagBadge';
import { EViews } from '../ClassificationTab/typings';
import { useAuth } from 'react-oidc-context';
import { getWorkspaceId } from 'utils/getWorkspaceId';
import statusService, { ICreateStatus } from 'api/statusService';
import { IStatus } from 'dto/IStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const ClassificationDefault: FC<Props> = ({ myPrivilages, type }) => {
  const workspaceId = getWorkspaceId();
  const [tagType, setTagType] = useState(-1);

  const { data: tagsData, refetch } = tagsService.useGetTags(false, tagType);
  const { data: systemTagsData, refetch: refetchSystem } =
    tagsService.useGetTags(false, tagType, workspaceId ?? '');
  const { mutate: addTag } = tagsService.useCreateTag();
  const { mutate: removeTag } = tagsService.useDeleteTag();
  const { mutate: editTag } = tagsService.useEditTag();
  const [tags, setTags] = useState<ITag[]>([]);
  const [systemTags, setSystemTags] = useState<ITag[]>([]);
  const auth = useAuth();
  const isAdmin = auth.user?.profile.role === 'admin' ?? false;

  const [systemValue, setSystemValue] = useState<string>();
  const [tagValue, setTagValue] = useState<string>();
  const [statusValue, setStatusValue] = useState<number>(0);

  const { data: systemStatusesData, refetch: systemRefetchStatuses } =
    statusService.useGetStatuses(workspaceId!, statusValue);
  const { data: statusesData, refetch: refetchStatuses } =
    statusService.useGetStatuses(undefined, statusValue);
  const { mutate: deleteStatus } = statusService.useDeleteStatus();
  const { mutate: createStatus } = statusService.useCreateStatus();
  const { mutate: updateStatus } = statusService.useUpdateStatus();
  const statuses = useMemo(() => statusesData?.data ?? [], [statusesData]);
  const systemStatuses = useMemo(
    () => systemStatusesData?.data ?? [],
    [systemStatusesData]
  );

  const [systemTag, setSystemTag] = useState('');
  const [myTag, setMyTag] = useState('');

  useEffect(() => {
    setTags(tagsData?.data);
    setSystemTags(systemTagsData?.data);
  }, [tagsData, systemTagsData]);

  useEffect(() => {
    switch (type) {
      case EViews.CASES:
        setTagType(1);
        setStatusValue(3);
        return;
      case EViews.ACTIONS:
        setTagType(0);

        setStatusValue(4);
        return;
      case EViews.CLIENTS:
        setTagType(3);

        setStatusValue(6);
        return;
      case EViews.LETTERS:
        setTagType(4);

        setStatusValue(5);
        return;
      case EViews.PACKAGES_INCOMING:
        setTagType(6);

        setStatusValue(1);
        return;
      case EViews.PACKAGES_OUTCOMING:
        setTagType(7);

        setStatusValue(2);
        return;
      case EViews.TEMPLATES:
        setTagType(8);

        return;
      default:
        setTagType(-1);
        return;
    }
  }, [type]);

  const handleTagChange = (text: string) => setTagValue(text);

  const handleRemoveTag = (tag: ITag) => {
    removeTag(
      {
        Id: tag.Id,
      },
      {
        onSuccess: () => {
          refetch();
          refetchSystem();
        },
      }
    );
  };

  const createNewTag = (tag: ITag) => {
    addTag(
      {
        Name: tag.Name,
        Type: tagType,
      },
      {
        onSuccess: () => refetch(),
      }
    );
  };

  const createNewSystemTag = (tag: ITag) => {
    addTag(
      {
        Name: tag.Name,
        Type: tagType,
        WorkspaceId: workspaceId ?? '',
      },
      {
        onSuccess: () => refetchSystem(),
      }
    );
  };

  const handleEditTag = (tag: ITag) => {
    editTag(
      {
        Id: tag.Id,
        Name: tag.Name,
      },
      {
        onSuccess: () => {
          refetchSystem();
          refetch();
        },
      }
    );
  };

  const [showDropdown, setShowDropdown] = useState(-1);

  const removeStatus = (status: IStatus) => {
    setShowDropdown(-1);
    deleteStatus(
      {
        Id: status.Id,
      },
      {
        onSuccess: () => {
          refetchStatuses();
          systemRefetchStatuses();
        },
      }
    );
  };

  const addStatus = (isCompany: boolean) => {
    let payload: ICreateStatus = {
      Name: myTag,
      Color: 0,
      Type: statusValue,
    };

    if (isCompany) {
      payload = {
        Name: systemTag,
        Color: 0,
        Type: statusValue,
        WorkspaceId: workspaceId ?? '',
      };
      setSystemValue('');
    } else {
      setMyTag('');
    }

    createStatus(payload, {
      onSuccess: () => {
        refetchStatuses();
        systemRefetchStatuses();
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.grouped}>
        <div className={styles.group}>
          <div className={styles.header}>Tagi systemowe</div>
          {myPrivilages.CanEditWorkspace && (
            <Input
              type="text"
              onChange={(t: string) => setSystemValue(t)}
              // onChooseTag={(e) => createNewSystemTag(e)}
              onAccept={() => {
                createNewSystemTag({
                  Id: '',
                  Name: systemValue ?? '',
                  Type: 0,
                  Flags: 0,
                  Global: true,
                });
                setSystemValue('');
              }}
              label="#Tag"
              hideTags
              value={systemValue}
            ></Input>
          )}

          {systemTags && (
            <div className={styles.tags}>
              {systemTags?.map((tag) => (
                <TagBadge
                  key={tag.Id}
                  onEdit={
                    myPrivilages.CanEditWorkspace && !tag.Global
                      ? handleEditTag
                      : undefined
                  }
                  onRemove={
                    myPrivilages.CanEditWorkspace && !tag.Global
                      ? (tag) => handleRemoveTag(tag)
                      : undefined
                  }
                  tag={tag}
                />
              ))}
            </div>
          )}
        </div>
        {type !== EViews.TEMPLATES && (
          <div className={styles.group}>
            <div className={styles.header}>Statusy systemowe</div>
            {myPrivilages.CanEditWorkspace && (
              <Input
                type="text"
                onChange={(t: string) => setSystemTag(t)}
                label="Status"
                onAccept={() => addStatus(true)}
                hideTags
                value={systemTag}
              ></Input>
            )}
            <div className={styles.s}>
              {systemStatuses.map((status: IStatus, index: number) => (
                <div className={styles.status}>
                  {status.Name}

                  {!status.Global && (
                    <FontAwesomeIcon
                      onClick={() => setShowDropdown(index)}
                      className={styles.ic}
                      icon={faEllipsisVertical}
                    />
                  )}

                  {showDropdown === index && (
                    <div className={styles.dropdown}>
                      <div
                        onClick={() => {
                          // setEditTag(true);
                          // setShowMenu(false);
                        }}
                        className={styles.box}
                      >
                        Edytuj
                      </div>

                      <div
                        onClick={() => {
                          removeStatus(status);
                        }}
                        className={styles.box}
                      >
                        Usuń
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.line}></div>
      <div className={styles.grouped}>
        <div className={styles.group}>
          <div className={styles.header}>Twoje tagi</div>
          <Input
            type="text"
            onChange={handleTagChange}
            onAccept={() => {
              createNewTag({
                Id: '',
                Name: tagValue ?? '',
                Type: 0,
                Flags: 0,
                Global: true,
              });
              setTagValue('');
            }}
            label="#Tag"
            value={tagValue}
            hideTags
          ></Input>

          {tags && (
            <div className={styles.tags}>
              {tags
                .filter((t) => !t.WorkspaceId)
                .map((tag) => (
                  <TagBadge
                    key={tag.Id}
                    onEdit={!tag.Global ? handleEditTag : undefined}
                    onRemove={
                      !tag.Global ? (tag) => handleRemoveTag(tag) : undefined
                    }
                    tag={tag}
                  />
                ))}
            </div>
          )}
        </div>

        {type !== EViews.TEMPLATES && (
          <div className={styles.group}>
            <div className={styles.header}>Twoje statusy</div>

            <Input
              type="text"
              onChange={(t: string) => setMyTag(t)}
              label="Status"
              hideTags
              value={myTag}
              onAccept={() => addStatus(false)}
            ></Input>

            <div className={styles.s}>
              {statuses.map((status: IStatus, index: number) => (
                <div className={styles.status}>
                  {status.Name}

                  {!status.Global && (
                    <FontAwesomeIcon
                      onClick={() => setShowDropdown(index)}
                      className={styles.ic}
                      icon={faEllipsisVertical}
                    />
                  )}

                  {showDropdown === index && (
                    <div className={styles.dropdown}>
                      <div
                        onClick={() => {
                          // setEditTag(true);
                          // setShowMenu(false);
                        }}
                        className={styles.box}
                      >
                        Edytuj
                      </div>

                      <div
                        onClick={() => {
                          removeStatus(status);
                        }}
                        className={styles.box}
                      >
                        Usuń
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassificationDefault;
