import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './styles/global.scss';
import { AuthProvider } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { axiosInstance } from 'api/axios';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY!,
  client_id: process.env.REACT_APP_OIDC_CLIENTID!,
  response_type: 'code',
  redirect_uri: process.env.REACT_APP_SITE!,
  post_logout_redirect_uri: process.env.REACT_APP_SITE!,
  scope: process.env.REACT_APP_OIDC_SCOPE!,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),

  onSigninCallback: (_user: User | void): void => {
    if (_user?.state) {
      var s = _user.state as any;
      if (s.invitation) {
        axiosInstance.post('/workspace/invitation/approve', {
          Key: s.invitation,
        });
      }
    }

    if (_user) {
      const idp = _user?.profile['idp'];

      window.localStorage.setItem('nota.last.uid', _user.profile.sub);

      if (idp) {
        if (idp instanceof Array) {
          window.localStorage.setItem('nota.last.idp', idp[0] ?? 'unknown');
        }
        window.localStorage.setItem(
          'nota.last.idp',
          (idp as string) ?? 'unknown'
        );
      }
    }

    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

const theme = createTheme({
  typography: {
    fontFamily: 'Rubik',
  },
  palette: {
    primary: {
      light: '#6a57cb',
      main: '#5d49c7',
      dark: '#6a57cb',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <AuthProvider {...oidcConfig}>
      <GoogleOAuthProvider clientId="865926315578-6s2396takuu2uusi2r4p3avh29pq6142.apps.googleusercontent.com">
        <App></App>
      </GoogleOAuthProvider>
    </AuthProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
