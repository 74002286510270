import { useMutation, useQuery } from 'react-query';
import { axiosInstance } from './axios';
import { ItemKind } from 'dto/IKindItems';

const queryKeys = {
  fileService: (id: string) => ['fileService.getFile', id],
};

export interface ICaseGetFile {
  Url: string;
  Uri: string;
  FileName: string;
  ContentType: string;
  Bucket: string;
}

const getFile = ({
  id,
  ownerKind,
}: {
  id: string;
  ownerKind: ItemKind;
}): Promise<{ data: ICaseGetFile }> => {
  return axiosInstance.get(`/file?id=${id}&ownerKind=${ownerKind}`);
};

const useGetFiltersForCases = () => {
  return useMutation(getFile);
};

export interface IGetS3 {
  uri: string;
  partNumber: number;
  uploadId: string;
}

const getS3 = (payload: IGetS3): Promise<{ data: string }> => {
  return axiosInstance.get(
    `/file/part?uri=${payload.uri}&partNumber=${payload.partNumber}&uploadId=${payload.uploadId}`
  );
};

const useGetS3 = () => {
  return useMutation(getS3);
};

export interface IFilePart {
  ETag: string;
  PartNumber: number;
}
export interface IFileConfirm {
  Parts: IFilePart[];
  UploadId: string;
  Uri: string;
}

const confirmUpload = (payload: IFileConfirm) => {
  return axiosInstance.post('/file/confirm', payload);
};

const useConfirmUpload = () => {
  return useMutation(confirmUpload);
};

export interface IFileInitUpload {
  ContentType: string;
  FileName: string;
  Hash: string;
  IsSigned: boolean;
  KeyId: string;
  Size: number;
  Queue: number;
}

export interface IFileInitUploadResponse {
  UploadId: string;
  Uri: string;
}

const initUpload = (
  payload: IFileInitUpload
): Promise<{ data: IFileInitUploadResponse }> => {
  return axiosInstance.post('/file/init', payload);
};

const useInitUpload = () => {
  return useMutation(initUpload);
};


export interface IUpdateLabel {
  OwnerId: string;
  OwnerKind: number;
  AttachmentId: string;
  Label: string;
}

const updateAttachment = (payload: IUpdateLabel) => {
  return axiosInstance.post("/attachment/label", payload)
}

const useUpdateAttachment = () => {
  return useMutation(updateAttachment);
}

export default {
  useGetFiltersForCases,
  useInitUpload,
  useGetS3,
  useConfirmUpload,
  useUpdateAttachment
};
