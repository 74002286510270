import {
  NotificationManager,
  //@ts-ignore
} from 'react-notifications';

export enum ENotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
}

interface Props {
  type: ENotificationType;
  title: string;
  text: string;
}

export const notification = ({ type, title, text }: Props) => {
  switch (type) {
    case ENotificationType.ERROR:
      NotificationManager.error(text, title, 5000);
      break;
    case ENotificationType.INFO:
      NotificationManager.info(text, title, 5000);
      break;
    default:
      NotificationManager.info(text, title, 5000);
      break;
  }
};
