import TaskCard from 'components/TaskCard';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { axiosInstance } from 'api/axios';
import { useInfiniteQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const TasksList: FC<Props> = ({ filters, setTask }) => {
  const { caseId } = useParams();
  console.log(filters);
  const {
    data: tasksData,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ['tasks', filters.memberId, filters.tagId, filters.statusId],
    async ({ pageParam = 0 }) => {
      const query = new URLSearchParams(window.location.search);
      query.append('Page', pageParam);
      if (caseId) {
        query.append('CaseId', caseId!);
      }

      if (filters.memberId) {
        query.append('AssignedUserId', filters.memberId);
      }
      if (filters.tagId) {
        query.append('TagId', filters.tagId);
      }
      if (filters.statusId) {
        query.append('StatusId', filters.statusId);
      }

      const res = await axiosInstance.get(`/task/listExtended?${query}`);

      return res.data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.LastPage) {
          return lastPage.LastPage + 1;
        }
        return undefined;
      },
    }
  );

  const [unDone, setUnDone] = useState(false);
  const [done, setDone] = useState(false);

  const tasks = useMemo(
    () =>
      tasksData?.pages
        .map((p) => p.Items.filter((o: any) => o.Type === 1))
        .flat() ?? [],
    [tasksData]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrap}>
        <div className={styles.title}>
          <div className={styles.text}>Do wykonania</div>

          <div onClick={() => setUnDone(!unDone)} className={styles.expanded}>
            {!unDone ? 'Zwiń' : 'Rozwiń'}{' '}
            <FontAwesomeIcon icon={unDone ? faCaretDown : faCaretUp} />
          </div>
        </div>
        {!unDone && (
          <div className={styles.list}>
            {tasks
              .filter((s) => !s.StatusId)
              .map((task) => (
                <TaskCard
                  setTask={setTask}
                  key={task.Id}
                  onClick={async (e) => {
                    const ele = e.target.tagName.toLowerCase();
                    const classList: string[] = Array.from(e.target.classList);

                    if (ele !== 'div') return;

                    if (
                      classList?.find(
                        (c: string) => c.search('checkbox') !== -1
                      )
                    ) {
                      await axiosInstance.post('/task/done', {
                        Id: task.Id,
                        Done: true,
                      });
                      refetch();
                      return;
                    }
                    setTask(task);
                  }}
                  task={task}
                />
              ))}
          </div>
        )}
      </div>
      <div className={styles.wrap}>
        <div className={styles.title}>
          <div className={styles.text}>Wykonane</div>
          <div onClick={() => setDone(!done)} className={styles.expanded}>
            {!done ? 'Zwiń' : 'Rozwiń'}{' '}
            <FontAwesomeIcon icon={done ? faCaretDown : faCaretUp} />
          </div>
        </div>
        {!done && (
          <div className={styles.list}>
            {tasks
              .filter((s) => s.StatusId)
              .map((task) => (
                <TaskCard
                  setTask={setTask}
                  key={task.Id}
                  onClick={async (e) => {
                    const ele = e.target.tagName.toLowerCase();
                    const classList: string[] = Array.from(e.target.classList);

                    if (ele !== 'div') return;

                    if (
                      classList?.find(
                        (c: string) => c.search('checkbox') !== -1
                      )
                    ) {
                      await axiosInstance.post('/task/done', {
                        Id: task.Id,
                        Done: false,
                      });
                      refetch();
                      return;
                    }
                    setTask(task);
                  }}
                  task={task}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TasksList;
