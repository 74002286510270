import casesService, { INewTag } from 'api/casesService';
import AddButton from 'components/AddButton';
import AddClientModal from 'components/AddClientModal';
import Button from 'components/Button';
import EntityCard from 'components/EntityCard';
import Input from 'components/Input';
import SearchEntities from 'components/SearchEntities';
import SearchStatuses from 'components/SearchStatuses';
import TagBadge from 'components/TagBadge';
import ModalsManagerContext from 'contexts/ModalsManager';
import { ICaseParticipant } from 'dto/Cases/ICaseParticipant';
import { IStatus } from 'dto/IStatus';
import { ITag } from 'dto/ITag';
import { useContext, useState } from 'react';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const AddCaseModal: FC<Props> = ({ onSave, toggle }) => {
  const { modals, setModals } = useContext(ModalsManagerContext);
  const { mutate: createCase } = casesService.useCreateCase();
  const [addNewEntity, setAddNewEntity] = useState(false);
  const [title, setTitle] = useState('');
  const [signature, setSignature] = useState('');
  const [status, setStatus] = useState<IStatus | null>(null);
  const [entities, setEntities] = useState<ICaseParticipant[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);

  const handleClickCreateCase = () => {
    const newTags: INewTag[] = tags
      .filter((tag) => tag.Id === '-1')
      .map((tag) => ({
        Id: null,
        Name: tag.Name,
        Type: tag.Type,
      }));

    const tagIds = tags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id);
    //@ts-ignore
    const participants: ICaseParticipant[] = entities.map((entity) => ({
      Id: entity.Id,
      RoleId: entity.RoleId,
      UserId: entity.UserId,
      ParticipantId: entity.Id,
      DefaultParcelType: null,
    }));

    createCase(
      {
        Id: null,
        NewTags: newTags,
        Participants: participants,
        Signature: signature,
        TagIds: tagIds,
        Title: title,
      },
      {
        onSuccess: (response) => {
          toggle(false);
          onSave?.(response.data);
          handleCloseModal();
        },
      }
    );
  };

  const addNewTag = (tag: ITag) => {
    const isTag = tags.find((etag) => etag.Name === tag.Name);
    if (isTag) return;
    setTags([...tags, tag]);
  };

  const handleRemoveStatus = (tag: ITag) =>
    setTags(tags.filter((etag) => tag.Name !== etag.Name));

  const updateRole = (entityId: number, roleId: string | null) => {
    setEntities(
      entities.map((entity) => {
        if (entityId === entity.Id) {
          entity.RoleId = roleId;
        }
        return entity;
      })
    );
  };

  const removeEntity = (entityId: number) =>
    setEntities(entities.filter((entity) => entity.Id !== entityId));

  if (addNewEntity) {
    return (
      <AddClientModal
        label="Nowa teczka"
        onCancel={() => setAddNewEntity(false)}
        onClose={() => setAddNewEntity(false)}
      />
    );
  }

  const handleCloseModal = () => {
    setModals({ ...modals, createCase: false });
    toggle(false);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            Nowa teczka
            <CloseIcon
              onClick={handleCloseModal}
              className={styles.closeIcon}
            />
          </div>
          <div className={styles.inputs}>
            <Input label="Sygnatura" onChange={(t) => setSignature(t)} />
            <Input
              tags={true}
              // label=""
              label="Tytuł teczki"
              onChange={(t) => setTitle(t)}
              onChooseTag={addNewTag}
            />
            {!!tags.length && (
              <div className={styles.tagsList}>
                {tags.map((tag) => (
                  <TagBadge tag={tag} onRemove={handleRemoveStatus} />
                ))}
              </div>
            )}
            <div className={styles.statuses}>
              <SearchStatuses
                selectedStatus={status}
                onChoose={(status) => setStatus(status)}
              />
            </div>
            <div className={styles.searchEnitity}>
              <SearchEntities
              // todo
              // onChoose={(entity) => setEntities([...entities, entity])}
              />
            </div>
            {!!entities.length && (
              <div className={styles.entityList}>
                <div className={styles.entityTitle}>Rola</div>
                {entities.map((entity) => (
                  <EntityCard
                    //@ts-ignore
                    removeEntity={removeEntity}
                    //@ts-ignore
                    setEntityRole={updateRole}
                    key={entity.Id}
                    entity={entity}
                  />
                ))}
              </div>
            )}
          </div>
          <div className={styles.addWrapper}>
            <AddButton
              className={styles.add}
              onClick={() => setAddNewEntity(true)}
              text="Dodaj podmiot"
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              onClick={handleClickCreateCase}
              text="Utwórz"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCaseModal;
