import { IDropdownObject } from 'components/Dropdown/typings';

const trustedRoles: IDropdownObject[] = [
  {
    value: 1,
    name: 'Adwokat',
  },
  {
    value: 2,
    name: 'Radca prawny',
  },
  {
    value: 3,
    name: 'Doradca podatkowy',
  },
  {
    value: 4,
    name: 'Doradca restrukturyzacyjny',
  },
  {
    value: 5,
    name: 'Rzecznik patentowy',
  },
  {
    value: 6,
    name: 'Notariusz',
  },
  {
    value: 7,
    name: 'Radca Prokuratorii Generalnej RP',
  },
  {
    value: 14,
    name: 'Komornik',
  },
];

export const getTrustedRoles = () => {
  return trustedRoles;
};
