import { FC, Props } from './typings';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useNavigate } from 'react-router-dom';
import Attachment from 'components/Attachment';
import { IAttachment } from 'dto/IAttachment';
import downloadFile from 'utils/downloadFile';
import { useContext } from 'react';
import fileService from 'api/fileService';
import listService from 'api/listService';
import PackagesContext from 'contexts/PackagesContext';

const PackageAttachmentsModal: FC<Props> = ({
  toggle,
  pack,
  keys,
  onPreview,
  onDownload,
  previewData,
}) => {
  const navigate = useNavigate();
  const { mutate: getFile } = fileService.useGetFiltersForCases();
  const { mutate: manageDossier } = listService.useManageDossier();
  const { packages, setPackages } = useContext(PackagesContext);

  const onNewWindow = async (attach: IAttachment) =>
    window.open(
      `${process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'}/pdf/${
        pack.Kind
      }/${pack.Id}/${attach.Id}`
    );

  const canAddToDossier =
    pack.Attachments.find((p) => p.ShowInDossier === 1) === undefined;

  const handleClick = () => {
    manageDossier({
      AttachmentIds: pack.Attachments.map((p) => p.Id),
      OwnerId: pack.Id,
      OwnerKind: pack.Kind,
      Show: canAddToDossier,
    });

    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      el.Attachments = el.Attachments.map((e) => {
        e.ShowInDossier = Number(canAddToDossier);
        return e;
      });
      setPackages(newPacks);
    }
  };

  const handleAddOnePackage = (attachment: IAttachment) => {
    manageDossier({
      AttachmentIds: [attachment.Id],
      OwnerId: pack.Id,
      OwnerKind: pack.Kind,
      Show: !attachment.ShowInDossier,
    });

    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      let attach = el.Attachments.find((e) => e.Id === attachment.Id);

      if (attach) {
        attach.ShowInDossier = Number(!attachment.ShowInDossier);
        setPackages(newPacks);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          Załączniki
          <CloseIcon
            className={styles.closeIcon}
            onClick={(e) => {
              toggle(false);
              e.stopPropagation();
            }}
          />
        </div>
        <div className={styles.list}>
          {pack.Attachments.map((attach) => (
            <Attachment
              key={attach.Id}
              onDownload={(attach) => {
                if (previewData) {
                  onDownload(pack, attach, previewData);
                  return;
                }

                const key = keys.find((k) => k?.Id === pack.EncryptionKeyId);
                let parentKey = null;

                if (key?.ParentKeyId) {
                  parentKey = keys.find((k) => k?.Id === key?.ParentKeyId);
                }

                onDownload(pack, attach, {
                  data: {
                    Keys: [parentKey],
                    ObjectKeys: [key],
                  },
                });
              }}
              onPreview={(attach) => {
                if (previewData) {
                  onPreview(pack, attach, previewData);
                  return;
                }

                const key = keys.find((k) => k?.Id === pack.EncryptionKeyId);
                let parentKey = null;

                if (key?.ParentKeyId) {
                  parentKey = keys.find((k) => k?.Id === key?.ParentKeyId);
                }

                onPreview(pack, attach, {
                  data: {
                    Keys: [parentKey],
                    ObjectKeys: [key],
                  },
                });
              }}
              onNewWindow={onNewWindow}
              attachment={attach}
              isInDossier={Boolean(attach.ShowInDossier)}
              onAddDossier={!!pack?.Case ? handleAddOnePackage : undefined}
            />
          ))}
        </div>
        {!!pack.Case && (
          <div className={styles.buttonWrapper}>
            <div
              onClick={(e) => {
                handleClick();
                e.stopPropagation();
              }}
              className={styles.button}
            >
              {canAddToDossier
                ? '  Dodaj pliki do dossier'
                : 'Usuń pliki z dossier'}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageAttachmentsModal;
