import styles from './styles.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { FC, Props } from './typings';
import pluginService from 'api/pluginService';
import Loading from 'components/Loading';
import PluginNotExist from 'components/PluginNotExist';
import Input from 'components/Input';
import SearchCases from 'components/SearchCases';
import SecondCheckbox from 'components/SecondCheckbox';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'components/Button';
import { getKeys } from 'utils/getKeys';
import { generateSingleBinary } from 'utils/GenerateSingleBinary';
import editorService from 'api/editorService';
import casesService, { INewTag } from 'api/casesService';
import { IKey } from 'dto/IKey';
import { ITag } from 'dto/ITag';
import TagBadge from 'components/TagBadge';
import ModalsManagerContext, {
  LetterModalOptions,
} from 'contexts/ModalsManager';
import SearchTemplates from 'components/SearchTemplates';
import { ITemplate } from 'dto/ITemplate';
import externalService from 'api/externalService';
import SearchExternalTemplate from 'components/SearchExternalTemplate';
import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import LettersContext from 'contexts/LettersContext';
import ModalLayout, { DockPosition } from 'components/ModalLayout';
import AdditionalClientModal from 'components/AdditionalClientModal';
import SearchCasesNew from 'components/SearchCasesNew';
import SelectTemplate from 'components/SelectTemplate';
import { ReactComponent as GoogleIcon } from 'assets/icons/Google.svg';
import { ReactComponent as WordIcon } from 'assets/icons/Word.svg';
import Dropdown from 'components/Dropdown';
import { ItemKind } from 'dto/IKindItems';

const CreateLetterModal: FC<Props> = ({}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);

  const LS = localStorage.getItem(`${userId ?? ''}.layout`);
  const sizes = JSON.parse(LS ?? '[]');

  const { modals, setModals, options } = useContext(ModalsManagerContext);
  const {
    data: pluginInformation,
    isFetching,
    isError,
  } = pluginService.useGetVersion();

  const [c, setCase] = useState<ICaseSimple | null>(null);
  const { data: keysData } = casesService.useGetKeys({
    id: c?.Id ?? null,
  });

  const [isWord, setIsWord] = useState(true);
  const { mutate: createDocument } = editorService.useCreateDocument();
  const { mutate: createExternalDocument } =
    externalService.useGetExternalEditor();
  const { mutate: openEditor } = pluginService.useOpenEditor();
  const { mutate: addVersion } = editorService.useForceVersion();

  const refWrapper = useRef(null);

  const [title, setTitle] = useState('');
  const [template, setTemplate] = useState<ITemplate | null>(null);

  const caseKeys: IKey = useMemo(() => keysData?.data?.[0] ?? [], [keysData]);

  const [tags, setTags] = useState<ITag[]>([]);

  const { letters, setLetters } = useContext(LettersContext);

  const [showCase, setShowCase] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const link = window.location.href;
  const folderId = link.split('dossier/f/')?.[1]?.split('/')?.[0] ?? undefined;

  const [selectedFormat, setSelectedFormat] = useState<string | null>(
    (options as LetterModalOptions)?.format ?? 'docx'
  );

  const formats = [
    {
      name: 'Word',
      value: 'docx',
    },
    {
      name: 'Excel',
      value: 'xlsx',
    },
    {
      name: 'Power point',
      value: 'pptx',
    },
    {
      name: 'Inny',
      value: 'other',
    },
  ];

  const handleClick = async () => {
    setIsLoading(true);
    const newTags: INewTag[] = tags
      .filter((tag) => tag.Id === '-1')
      .map((tag) => ({
        Id: null,
        Name: tag.Name,
        Type: tag.Type,
      }));

    const tagIds = tags.filter((tag) => tag.Id !== '-1').map((tag) => tag.Id);

    if (!isWord) {
      createExternalDocument(
        {
          CaseId: c ? c.Id : null,
          NewTags: newTags,
          TagIds: tagIds,
          TemplateId: template ? template.Id : null,
          Title: title,
        },
        {
          onSuccess: (response) => {
            setIsLoading(false);
            window.open(response.Uri);
            handleClose();

            //@ts-ignore
            setLetters([...letters, response.data.Items[0]]);
          },
          onError: () => setIsLoading(false),
        }
      );
      return;
    }

    const myKeys = await getKeys(userId);

    let objectKeys = null;

    if (!c) {
      objectKeys = await generateSingleBinary(
        undefined,
        myKeys?.intermidatePrivateKey
      );
    }

    if (c && caseKeys.PublicKey) {
      objectKeys = await generateSingleBinary(caseKeys.PublicKey);
    }

    if (!objectKeys) return;

    createDocument(
      {
        CaseId: c ? c.Id : null,
        NewTags: newTags,
        PrivateKey: objectKeys!.PrivateKey,
        PublicKey: objectKeys!.PublicKey,
        TagIds: tagIds,
        TemplateId: template ? template.Id : null,
        Title: title,
        Format: selectedFormat!,
        FolderId: folderId,
      },
      {
        onSuccess(response) {
          setIsLoading(false);

          openEditor({ Id: response.data.Id, Kind: ItemKind.EditorDocument });
          // addVersion({
          //   Id: response.data.Id,
          //   Name: title,
          // });
          handleClose();
          //@ts-ignore
          setLetters([response.data.Items[0], ...letters]);
        },
        onError: () => setIsLoading(false),
      }
    );
  };

  const addNewTag = (tag: ITag) => {
    const isTag = tags.find((etag) => etag.Name === tag.Name);
    if (isTag) return;
    setTags([...tags, tag]);
  };

  const handleRemoveStatus = (tag: ITag) =>
    setTags(tags.filter((etag) => tag.Name !== etag.Name));

  const handleClose = () => setModals({ ...modals, hide: true });
  const [addNewEntity, setAddNewEntity] = useState(false);

  // if (isFetching) {
  //   return (
  //     <div className={styles.modal}>
  //       <Loading />
  //     </div>
  //   );
  // }

  if (isError)
    return (
      <PluginNotExist
        handleClose={() => setModals({ ...modals, createLetter: false })}
      />
    );

  return (
    <ModalLayout
      dock={DockPosition.DISABLED}
      off={true}
      customX={1}
      customY={0}
      ignore
      isAnimation
      refWrapper={refWrapper}
      fullHeight={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Nowe pismo
          <CloseIcon onClick={handleClose} className={styles.closeIcon} />
        </div>
        <div className={styles.content}>
          <div className={styles.types}>
            <div
              onClick={() => {
                setIsWord(false);
                setTemplate(null);
              }}
              className={`${styles.type} ${!isWord && styles.active}`}
            >
              <GoogleIcon />
              Google docs
            </div>
            <div className={styles.placeholder} />
            <div
              onClick={() => {
                setIsWord(true);
                setTemplate(null);
              }}
              className={`${styles.type} ${isWord && styles.active}`}
            >
              <WordIcon />
              Word
            </div>
            {/* <SecondCheckbox
                value={isWord}
                onChange={() => setIsWord(true)}
                text="Word"
              />
              <SecondCheckbox
                value={!isWord}
                onChange={() => setIsWord(false)}
                text="GoogleDocs"
              /> */}
          </div>
          <div className={styles.inputs}>
            <div className={styles.d}>
              <SelectTemplate
                isExternal={false}
                selectedTemplate={template}
                onChoose={(t) => {
                  setTemplate(t);
                }}
              />
            </div>
            {/* {isWord ? (
                <SearchTemplates
                  onChange={(t) => {
                    setTemplate(t);
                    if (!title.length) {
                      setTitle(t.S1);
                    }
                  }}
                />
              ) : (
                <SearchExternalTemplate
                  onChange={(t) => {
                    setTemplate(t);
                    if (!title.length) {
                      setTitle(t.S1);
                    }
                  }}
                />
              )} */}
            <SearchCasesNew
              settedCase={c}
              setAddNewEntity={() => {
                setShowCase(true);
              }}
              onChoose={(c) => setCase(c)}
            />
            <Input
              label="Tytuł"
              value={title}
              placeholder="Tytuł, wpisując # wyszukaj tag lub dodaj nowy"
              onChange={(t) => setTitle(t)}
              onChooseTag={addNewTag}
              tags
            />

            <Dropdown
              list={formats}
              defaultValue={selectedFormat}
              label="Format dokumentu"
              onChange={(f) => {
                setSelectedFormat(f.value);
              }}
            />

            {!!tags.length && (
              <div className={styles.tagsList}>
                {tags.map((tag) => (
                  <TagBadge tag={tag} onRemove={handleRemoveStatus} />
                ))}
              </div>
            )}
            {/* <SearchCases
                settedCase={c}
                removeFocus
                setAddNewEntity={() => {
                  setShowCase(true);
                }}
                onChoose={(c) => setCase(c)}
              /> */}
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            disabled={!title.length}
            onClick={handleClick}
            text="Utwórz"
          />
        </div>
      </div>
      {/* {showCase && (
          <AdditionalClientModal
            showCase={showCase}
            onCreateCase={(c) => {
              setCase(c);
              setShowCase(false);
            }}
          />
        )} */}
    </ModalLayout>
  );
};

export default CreateLetterModal;
