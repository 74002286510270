export type { FC } from 'react';


export interface IOrderObject {
    type: ETypeOfOrder,
    order: EOrder,
    initalized?: boolean;
}

export enum EOrder {
    ASCENDING = 'Ascending',
    DESCENDING = 'Descending'
}

export enum ETypeOfOrder {
    TITLE = 'Title',
    SEND_DATE = 'date',
    LAST_MODIFED = 'LastModified',
    RECEIVED_DATE = 'ReceiveDate',
    CREATION_DATE = 'CreationDate',
    REMOVED_DATE = 'RemovedDate',
    UPDATE_DATE = 'UpdateDate',
    OPEN_DATE = 'OpenDate',
    NAME = 'Name'

}


export interface Props {
  type: ETypeOfOrder;
  order: EOrder;
  setListFilter: (object: IOrderObject) => void;
  isLoading: boolean;
  refetch: () => void;
}