import { IntegrationProvider } from 'dto/Settings/IntegrationProvider';
import { IntegrationType } from 'dto/Settings/IntegrationType';

export type { FC } from 'react';

export enum ETypes {
    ALL = 'ALL',
    PI = 'PI',
    EMAIL = 'EMAIL',
    CALENDAR = 'CALENDAR',
    EDITOR = 'EDITOR',
    FK = 'FK'
}

export interface Props {
    // type: IntegrationType;
    // provider: IntegrationProvider;
    // isActive: boolean;
    toggle: () => void;
    integrationId: any;
    handleDisconnect: any;
    onInnerViewSaved: any;
    onInnerViewCanceled: any;
    courtPortalState: any;
    

    emailState: any, 
    setEmailState: any, 
    refetchI: any
    refetchportals: any

    handleClick: (
        type: IntegrationType,
        provider: IntegrationProvider,
        isActive: boolean
      ) => void;
}