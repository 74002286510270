import officeService from 'api/officeService';
import styles from './styles.module.scss';
import { IUserOffice } from 'dto/IUserOffice';
import {
  convertOfficeUserRole,
  convertOfficeUserStatus,
} from 'utils/convertOfficeUserStatus';
import { IUserOfficeStatus } from 'dto/IUserOfficeStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faEllipsis,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FC, Props } from './typings';
import { useState } from 'react';
import { IUserOfficeRole } from 'dto/IUserOfficeRole';
import { useAuth } from 'react-oidc-context';
import Dropdown from 'components/Dropdown';
import workspaceService from 'api/workspaceService';
import MembershipMoveStructureModal from 'components/MembershipMoveStructureModal';
import MembershipEditRoleModal from 'components/MembershipEditRoleModal';
import Avatar from 'components/Avatar';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { IWorkspaceRole } from 'dto/IWorkspace';
import StatusBadge from 'components/StatusBadge';

const MembershipCard: FC<Props> = ({
  myPrivilages,
  roles,
  worker,
  refetch,
  structure,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showStructure, setShowStructure] = useState(false);
  const { mutate: editUser } = workspaceService.useWorkspaceEditMember();
  const [showRole, setShowRole] = useState(false);
  const { mutate: acceptWorker } = officeService.useApprovalUser();
  const { mutate: removeWorker } = workspaceService.useRemoveUser();

  const status = convertOfficeUserStatus(worker.Status);
  // const role = convertOfficeUserRole(worker.RoleId);

  const role = roles.find((role) => role.Id === worker.RoleId);

  const user = useAuth();

  const translate = () => {
    switch (status) {
      case IUserOfficeStatus.ACTIVE:
        return 'Aktywny';
      case IUserOfficeStatus.INVITATION:
        return 'Zaproszono';
      case IUserOfficeStatus.REJECTED:
        return 'Odrzucony';
      case IUserOfficeStatus.REMOVED:
        return 'Usunięty';
      case IUserOfficeStatus.WAITING_FOR_APPROVAL:
        return 'Zaproszono';
    }
  };

  const getClassName = () => {
    switch (status) {
      case IUserOfficeStatus.ACTIVE:
        return 6;
      case IUserOfficeStatus.INVITATION:
        return 4;
      case IUserOfficeStatus.REJECTED:
        return 3;
      case IUserOfficeStatus.REMOVED:
        return 3;
      case IUserOfficeStatus.WAITING_FOR_APPROVAL:
        return 4;
    }
  };

  const handleEditUser = (selectedRole: IWorkspaceRole) => {
    editUser(
      {
        Id: worker.Id,
        RoleId: selectedRole.Id,
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleAcceptUser = () => {
    acceptWorker(
      {
        Id: worker.Id,
        Role: 0,
      },
      {
        onSuccess: () => {
          refetch();
          setShowMenu(false);
        },
      }
    );
  };

  const handleRemoveUser = () => {
    removeWorker(
      {
        Id: worker.Id,
      },
      {
        onSuccess: () => {
          refetch();
          setShowMenu(false);
        },
      }
    );
  };

  const [d, setD] = useState(false);

  return (
    <>
      <div className={styles.workerTab}>
        <div className={styles.data}>
          <div className={styles.name}>
            <Avatar className={styles.avatar} name={worker.Label} />{' '}
            {worker.Label}
          </div>
          {/* <div className={`${styles.status} ${getClassName()}`}>
            {translate()}
          </div> */}
          {/* <div className={styles.email}>{worker.Email}</div> */}
        </div>
        <div className={styles.menu}>
          {/* {isAdmin && !worker.IsMe ? (
          <Dropdown
          className={styles.d}
          defaultValue={role}
          list={roles}
          onChange={() => {}}
          />
        ) : ( */}
          <StatusBadge
            status={{
              Id: -1,
              Color: getClassName() ?? 1,
              Name: translate() ?? '',
              Type: 1,
              Global: false,
            }}
            onRemove={() => {}}
          />
          <div onClick={() => setD(!d)} className={styles.role}>
            {role?.Name}{' '}
            {!worker.IsMe &&
              !worker.IsOwner &&
              myPrivilages.CanEditMembersInWorkspace && (
                <FontAwesomeIcon icon={d ? faCaretUp : faCaretDown} />
              )}
            {d &&
              !worker.IsMe &&
              !worker.IsOwner &&
              myPrivilages.CanEditMembersInWorkspace && (
                <div className={styles.dropdown}>
                  {roles.map((r) => (
                    <div
                      onClick={() => {
                        handleEditUser(r);
                        setD(false);
                      }}
                      className={styles.option}
                    >
                      {r.Name}
                    </div>
                  ))}
                </div>
              )}
          </div>

          {/* )} */}
          <div
            onClick={() => {
              handleRemoveUser();
            }}
            className={styles.remove}
          >
            <BinIcon />
          </div>
        </div>
      </div>
      {showStructure && (
        <MembershipMoveStructureModal
          structure={structure}
          member={worker}
          refetch={refetch}
          toggle={() => setShowStructure(false)}
        />
      )}
      {showRole && (
        <MembershipEditRoleModal
          roles={roles}
          member={worker}
          refetch={refetch}
          toggle={() => setShowRole(false)}
        />
      )}
    </>
  );
};

export default MembershipCard;
