import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';

import settingService from 'api/settingsService';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import { BaeOperationStatusEnum } from 'dto/Settings/BaeOperationStatusEnum';
import { EDeliveryAddressStatus } from 'dto/Settings/EDeliveryAddressStatus';
import { IEDeliveryAddress } from 'dto/Settings/IEDeliveryAddress';
import { IEDelvieryRequest } from 'dto/Settings/IEDelvieryRequest';
import moment from 'moment';
import { getEDeliveryAddressStatus } from 'utils/getEDeliveryAddressStatus';
import { getEDeliveryOperationStatus } from 'utils/getEDeliveryOperationStatus';
import CreateRequest from './Requests/Create';
import StatusBadge from 'components/StatusBadge';
import Button from 'components/Button';

export enum EDeliveryTabMode {
  CreateRequest,
}

const EDeliveryTab: FC<Props> = ({ showTabBar }) => {
  const {
    data: edelvieryAddressesData,
    isSuccess: isEDelvieryAddressesLoaded,
  } = settingService.useGetEDeliveryAddresses();
  const {
    data: edeliveryRequestsData,
    isSuccess: isEDelvieryRequestsLoaded,
    refetch: refetchRequests,
  } = settingService.useGetEDeliveryRequests();

  const [addresses, setAddresses] = useState<IEDeliveryAddress[]>([]);
  const [requests, setRequests] = useState<IEDelvieryRequest[]>([]);
  const [mode, setMode] = useState<EDeliveryTabMode | null>(null);

  const onInnerViewCanceled = () => {
    setSelectedTab(ETabs.PROPOSALS);
  };

  const onInnerViewSaved = () => {
    setSelectedTab(ETabs.PROPOSALS);
    refetchRequests();
  };

  const handleCreateRequest = () => {
    setMode(EDeliveryTabMode.CreateRequest);
  };

  useEffect(() => {
    if (isEDelvieryAddressesLoaded) {
      var s = edelvieryAddressesData.data as IEDeliveryAddress[];
      setAddresses(s);
    }
  }, [edelvieryAddressesData]);

  useEffect(() => {
    if (isEDelvieryRequestsLoaded) {
      var s = edeliveryRequestsData.data as IEDelvieryRequest[];
      setRequests(s);
    }
  }, [edeliveryRequestsData]);

  const [selectedTab, setSelectedTab] = useState(ETabs.ACCOUNTS);

  if (!isEDelvieryAddressesLoaded) {
    return <Spinner></Spinner>;
  }

  return (
    <div className={styles.wrapper}>
      {selectedTab !== ETabs.CREATE && (
        <>
          <div className={styles.group}>
            <div className={styles.header}>Twoje adresy</div>
            <div className={styles.hint}></div>
            {addresses.map((t) => (
              <div className={styles.addressBox}>
                <div className={styles.left}>
                  <div className={styles.label}>{t.address}</div>
                </div>
                <div className={styles.right}>
                  <div className={styles.label}>
                    {t.isExposed ? 'Ujawniono' : 'Nie ujawniono'}
                  </div>
                  {t.status === EDeliveryAddressStatus.ACTIVE ? (
                    <StatusBadge
                      onRemove={() => {}}
                      status={{
                        Id: -1,
                        Color: 6,
                        Name: 'Aktywny',
                        Type: 1,
                        Global: true,
                      }}
                    />
                  ) : (
                    <StatusBadge
                      onRemove={() => {}}
                      status={{
                        Id: -1,
                        Color: 4,
                        Name: 'Oczekuje',
                        Type: 1,
                        Global: true,
                      }}
                    />
                  )}
                  <div className={styles.date}>
                    {moment(t.creationDate).format('DD.MM.YYYY').toString()}
                  </div>
                </div>
              </div>
            ))}{' '}
          </div>
        </>
      )}

      {selectedTab !== ETabs.CREATE && (
        <div className={styles.group}>
          <div className={styles.header}>Wnioski</div>
          <div className={styles.hint}></div>

          <div className={styles.table}>
            {requests.map((t) => {
              return (
                <div className={styles.addressBox}>
                  <div className={styles.left}>{t.label}</div>
                  <div className={styles.right}>
                    {t.status === BaeOperationStatusEnum.SUCCESS ? (
                      <StatusBadge
                        onRemove={() => {}}
                        status={{
                          Id: -1,
                          Color: 8,
                          Name: 'Aktywny',
                          Type: 1,
                          Global: true,
                        }}
                      />
                    ) : (
                      <StatusBadge
                        onRemove={() => {}}
                        status={{
                          Id: -1,
                          Color: 4,
                          Name: 'Oczekuje',
                          Type: 1,
                          Global: true,
                        }}
                      />
                    )}
                    <div className={styles.date}>
                      {moment(t.creationDate).format('DD.MM.YYYY').toString()}
                    </div>
                  </div>
                </div>
              );
            })}

            {!requests.length && (
              <div className={styles.empty}>Brak złożonych wniosków</div>
            )}
          </div>
          <div className={styles.right}></div>
        </div>
      )}

      {selectedTab === ETabs.CREATE && (
        <CreateRequest
          onSaved={onInnerViewSaved}
          onCanceled={onInnerViewCanceled}
        ></CreateRequest>
      )}

      {selectedTab !== ETabs.CREATE && (
        <div className={styles.btnWrapper}>
          <Button
            className={styles.btn}
            onClick={() => {
              setSelectedTab(ETabs.CREATE);
            }}
            text="Aktywuj adres do e-doręczeń"
          />
        </div>
      )}
    </div>
  );
};

export default EDeliveryTab;
