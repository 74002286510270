export interface ITag {
  Id: string;
  Name: string;
  Type: TagType;
  Flags: TagFlags;
  Global: boolean;
  WorkspaceId?: string;
}

export enum TagType {
  /// <summary>
  /// Czynności
  /// </summary>
  Tasks = 0,

  /// <summary>
  /// Sprawy
  /// </summary>
  Cases = 1,

  /// <summary>
  /// Pozostałe rodzaje obiektów z listy
  /// </summary>
  Other = 2,

  /// <summary>
  /// Podmioty
  /// </summary>
  Participants = 3,

  /// <summary>
  /// Pisma
  /// </summary>
  EditorDocuments = 4,

  /// <summary>
  /// Stanowiska w ramach workspace/zespołu
  /// </summary>
  JobPosition = 5,

  /// <summary>
  /// Przychodzące
  /// </summary>
  Incoming = 6,

  /// <summary>
  /// Wychodzące
  /// </summary>
  Outgoing = 7,

  /// <summary>
  /// Elementy dossier
  /// </summary>
  Dossier = 8,

  /// <summary>
  /// Szablony
  /// </summary>
  Templates = 9,
}

export enum TagFlags {
  None = 0,
  LeadAuthority = 1,
  Client = 2,
}
