import { ICaseSimple } from 'dto/Cases/ICaseSimple';
import styles from './styles.module.scss';
import { FC, IUploadedFile, Props } from './typings';
import CaseLetters from 'components/CaseLetters';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import { useContext, useEffect, useState } from 'react';
import KeysContext from 'contexts/KeysContext';
import { IDossierItem } from 'dto/Dossier/IDossierItem';
import { getFile } from 'utils/getFile';
import { getBlob } from 'utils/getBlob';
import { decryptFile } from 'utils/decryptFile';
import casesService from 'api/casesService';
import Button from 'components/Button';
import { IPackage } from 'dto/IPackage';

export const CaseLetterWrapper = ({
  c,
  handleAddFiles,
}: {
  c: ICaseSimple;
  handleAddFiles: (files: IUploadedFile[]) => void;
}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { data: Case } = casesService.useGetCase(c.Id);

  const { keys, setKeys } = useContext(KeysContext);

  const [selectedItems, setSelectedItems] = useState<IPackage[]>([]);

  const handleAdd = async (selected?: IDossierItem[]) => {
    const files = [];
    // todo: ?? selectedItems
    for (const element of selected!) {
      const file = await getFile(element.FileUri ?? '');
      const blob = await getBlob(file.data.Url);
      const key = keys.find((key: any) => key?.Id === element.EncryptionKeyId);

      if (!key) return;

      let parentKey = null;
      //@ts-ignore
      if (key.ParentKeyId) {
        parentKey = keys.find(
          //@ts-ignore
          (pkey: any) => pkey?.Id === key?.ParentKeyId
        );
      }

      let decryptedFile: Blob | null = null;
      try {
        decryptedFile = await decryptFile(
          userId ?? '',
          new Blob([blob.data]),
          key,
          parentKey
        );
      } catch (e) {
        decryptedFile = new Blob([blob.data]);
      }

      const el = {
        file: decryptedFile,
        name: element.FileName ?? '',
        fileType: file.data.ContentType,
        size: decryptedFile.size,
      };
      files.push(el);
    }

    handleAddFiles(files);
  };

  return (
    <div className={styles.out}>
      <div className={styles.sizer}>
        <CaseLetters
          isOutcoming
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          handleAdd={handleAdd}
          className={styles.remove}
          Case={Case ?? null}
        />
      </div>

      <div className={styles.btnWrapper}>
        <Button text="Zatwierdź" onClick={handleAdd} />
      </div>
    </div>
  );
};
