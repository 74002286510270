import { RefObject, createContext } from "react";

export interface IPDF {
    zoom: number;
    page: number;
    annotation: string | null;
    showAnnotation: string | null;
    canvas: RefObject<any> | null;
    editComment: string
}

type IPDFContext = {
    PDF: IPDF;
    setPDF: (pdf: IPDF) => void;
}

const PDFContext = createContext<IPDFContext>({
    PDF: {
        zoom: 1,
        page: 1,
        annotation: null,
        showAnnotation: null,
        canvas: null,
        editComment: ''
    },
    setPDF: () => {}
})

export default PDFContext;