import EmailService from 'api/emailService';
import fileService from 'api/fileService';
import listService from 'api/listService';
import classNames from 'classnames';
import Attachment from 'components/Attachment';
import Avatar from 'components/Avatar';
import Loading from 'components/Loading';
import PDFViewer from 'components/PDFViever';
import { IAttachment } from 'dto/IAttachment';
import { IPackage } from 'dto/IPackage';
import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptPackage } from 'utils/decryptPackage';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import PackageCaseModal from 'components/PackageCaseModal';

import IconsState from 'components/IconsState';
import OptionsCard from 'components/OptionsCard/OptionsCard';
import ModalsManagerContext from 'contexts/ModalsManager';
import PackagesContext from 'contexts/PackagesContext';
import KeysContext from 'contexts/KeysContext';
import { useAuth } from 'react-oidc-context';
import { getUserId } from 'utils/getUserId';
import ReceiveModal from 'components/ReceiveModal';
import { getBlob } from 'utils/getBlob';
import { decryptFile } from 'utils/decryptFile';
import { blobToBase64 } from 'utils/blobToBase64';
import { readFileFromBase64 } from 'utils/readFileFromBase64';
import pluginService from 'api/pluginService';
import CPluginContext from 'contexts/PluginContext';
import { getKeys } from 'utils/getKeys';
import { addToUrl, hasImages, parseHTML } from 'utils/parseHTML';
import { decodeAndFetchImages } from 'utils/decodeAndFetchImages';
import Button from 'components/Button';
import settingsContext, { IOptionType } from 'contexts/SettingsContext';
import ModalLayout from 'components/ModalLayout';
import MultiPackageModal from 'components/MultiPackageModal';
import ErrorScreen from 'components/ErrorScreen';
import { ReactComponent as GroupIcon } from 'assets/icons/groups.svg';
import { Document, Page, pdfjs } from 'react-pdf';
import { faMugSaucer } from '@fortawesome/free-solid-svg-icons';
import Chip from 'components/Chip';
import { ITag } from 'dto/ITag';
import tagsService from 'api/tagsService';
import PackageReceiversModal from 'components/PackageReceiversModal';
import { ReactComponent as FwdIcon } from 'assets/icons/fwd.svg';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const Package: FC<Props> = ({
  className = '',
  headerClassName = '',
  listId,
  listName,
  kind,
  packageId,
  showPDF,
  setShowPDF,
  onPreview,
  onDownload,
  showVersion,
  loadingPDF,
  cc,
}) => {
  const reference = useRef(null);
  const receiversReference = useRef(null);
  const receiversCcReference = useRef(null);
  const { letterId, filterId, status, versionId, signStatus } = useParams();
  const [findedTags, setFindedTags] = useState<ITag[]>([]);

  const isLetters = status === 'pisma';
  const isSending = status === 'outcoming';
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const navigate = useNavigate();
  const { PDFId } = useParams();

  const [useIframe, setUseIframe] = useState(false);

  const [showCase, setShowCase] = useState(false);

  const [showMulti, setShowMulti] = useState(false);
  const [showReceivers, setShowReceivers] = useState(false);
  const [showCcReceivers, setShowCcReceivers] = useState(false);

  const {
    data: packageData,
    refetch,
    isLoading,
  } = listService.useGetPackage({
    FilterId: packageId ?? '',
    Kind: kind ?? '',
  });

  const [allowImages, setAllowImages] = useState(true);
  const [canImages, setCanImages] = useState(false);

  const refWrapper = useRef(null);
  const [showAttach, setShowAttach] = useState(false);
  const { mutate: manageDossier } = listService.useManageDossier();

  // useGetPreview
  // const { mutate: getAnnotations } = annotationService.useGet();

  const pack: IPackage = useMemo(
    () => packageData?.data?.Items?.[0] ?? [],
    [packageData]
  );
  const packCcReceivers = pack?.Receivers?.filter((x) => x.Cc || x.Bcc);
  const packReceivers = pack?.Receivers?.filter((x) => !x.Cc && !x.Bcc);

  const { data: tagsData } = tagsService.useGetTags();
  const tags: ITag[] = useMemo(() => tagsData?.data ?? [], [tagsData]);

  useEffect(() => {
    const t = tags?.filter((tag) =>
      pack?.TagIds?.find((iTag) => tag.Id === iTag)
    );

    setFindedTags(t);
  }, [pack.TagIds]);

  const needToConfirm = pack.Kind === 4 && !pack.ReceiveDate;

  // const keys: any[] = useMemo(
  //   () => packageData?.data?.ObjectKeys ?? [],
  //   [packageData]
  // );
  const { data: previewData } = EmailService.useGetPreview(
    packageId ?? '',
    pack?.Kind === 128 ? true : false
  );

  const { mutate: getFile } = fileService.useGetFiltersForCases();

  const [content, setContent] = useState('');
  const [fetchContent, setFetchContent] = useState(false);

  const [decryptError, setDecryptError] = useState(false);

  const decodeEncryptedContent = async () => {
    const myKeys = await getKeys(userId!);
    const objectKey = previewData?.data?.ObjectKeys?.[0];

    let parentKey = null;

    if (objectKey.ParentKeyId) {
      parentKey = previewData?.data.Keys.find(
        (k: any) => k?.Id === objectKey?.ParentKeyId
      );
    }
    const content = await decryptPackage(
      userId,
      previewData?.data.Content,
      objectKey,
      parentKey
    );

    const images = await decodeAndFetchImages(
      userId,
      previewData?.data,
      objectKey,
      parentKey
    );

    setContent(await parseHTML(content, images));
  };

  function isBase64(str: string) {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }

  const getContent = async () => {
    setFetchContent(true);
    if (pack.Kind === 128) {
      const isEncrypted = previewData?.data?.IsEncrypted;

      if (isEncrypted) {
        setAllowImages(true);
        decodeEncryptedContent();
        return;
      }
      const isImages = await hasImages(previewData?.data?.Content);

      if (!canImages && isImages) {
        setAllowImages(false);
      }

      setContent(
        await addToUrl(
          previewData?.data?.Content,
          isImages && !canImages ? false : true
        )
      );

      return;
    }

    setAllowImages(true);

    try {
      const r = atob(pack.S2);
      const encryptionKey = keys.find(
        (key) => key?.Id === pack?.EncryptionKeyId
      );

      let parentKey = null;
      if (encryptionKey?.ParentKeyId) {
        parentKey = keys.find((key) => key?.Id === encryptionKey.ParentKeyId);
      }

      setContent(
        await decryptPackage(userId, pack.S2, encryptionKey, parentKey)
      );
    } catch (e) {
      if (isBase64(pack.S2)) {
        setDecryptError(true);
        setContent('error');
        return;
      }
      setContent(`${pack.S2}`);
      return;
    } finally {
      setFetchContent(false);
    }
  };

  const { packages, setPackages } = useContext(PackagesContext);
  const packContext = packages.find((p) => p.Id === pack.Id);
  const { keys, setKeys } = useContext(KeysContext);

  useEffect(() => {
    setDecryptError(false);

    if (pack.Unread) {
      let newPacks = [...packages];
      let newPack = newPacks.find((p) => p.Id === pack.Id);
      if (newPack) {
        newPack.Unread = false;
        setPackages(newPacks);
      }
    }
    if (pack.Kind === 4096) {
      getContent();
    }

    if (pack.S2) {
      if (
        !keys.filter((k) => k !== undefined).length ||
        !previewData ||
        !pack ||
        !!content.length ||
        fetchContent
      )
        getContent();
      return;
    } else {
      setContent('');
    }
  }, [previewData, pack, keys, canImages]);

  useEffect(() => {
    setUseIframe(previewData?.data?.IsHtml ?? true);
  }, [previewData]);

  const { options } = useContext(settingsContext);

  useEffect(() => {
    const s = options.find((f) => f.Type === IOptionType.showImages)?.Value;

    const can = s === 'true';

    setCanImages(can);
    setKeys(
      [packageData?.data?.Keys, packageData?.data?.ObjectKeys, ...keys].flat()
    );
  }, [pack]);

  useEffect(() => {
    if (!!PDFId?.length && !!pack && showPDF === null) {
      const attach = pack?.Attachments?.find(
        (attachment) => attachment.Id === PDFId
      );

      if (attach && !!keys.length) {
        onPreview(pack, attach, previewData);
      }
    }
  }, [pack, keys]);

  useEffect(() => {
    setUseIframe(false);
    setContent('');
    setDecryptError(false);
    setFetchContent(false);
  }, [pack, versionId]);

  const { modals, setModals } = useContext(ModalsManagerContext);

  const onNewWindow = async (attach: IAttachment) =>
    window.open(
      `${
        process.env.REACT_APP_SITE ?? 'https://alfa.pocztaprawnicza.pl'
      }/pdf/${kind}/${packageId}/${attach.Id}`
    );

  const { mutate: tryPreview } = pluginService.usePreview();

  const [letterBlob, setLetterBlob] = useState<Blob | null>(null);
  const [pdfLoading, setPDFLoading] = useState(false);
  const [height, setHeight] = useState('');
  const iframeRef = useRef(null);

  const previewFromPlugin = async (blob: Blob, fileName: string) => {
    const b: string = (await blobToBase64(blob)) as string;

    tryPreview(
      {
        Content: b.split(',')[1],
        ContentType: null,
        FileName: fileName,
      },
      {
        onSuccess: async (response) => {
          const c = response.data.Content;
          const buffer = await readFileFromBase64(c, 'application/other');
          setLetterBlob(new Blob([buffer]));
        },
      }
    );
  };

  const { plugin } = useContext(CPluginContext);

  const [update, setUpdate] = useState(false);

  const handleLetterPreview = () => {
    if (!plugin.actual) {
      setUpdate(true);
      return;
    }

    if (versionId) {
      if (signStatus === 'signed') {
        getFile(
          {
            id: versionId,
            ownerKind: pack.Kind,
          },
          {
            onSuccess: async (data) => {
              const file = await getBlob(data.data.Url);

              setLetterBlob(file.data);
            },
          }
        );
        return;
      }

      getFile(
        {
          id: versionId,
          ownerKind: pack.Kind,
        },
        {
          onSuccess: async (data) => {
            const file = await getBlob(data.data.Url);
            const key = keys.find(
              (key: any) => key?.Id === pack?.EncryptionKeyId
            );

            let parentKeyId = null;

            if (key?.ParentKeyId) {
              parentKeyId = packageData?.data?.Keys.find(
                (k: any) => k?.Id === key?.ParentKeyId
              );
            }

            try {
              const decryptedBlob = await decryptFile(
                userId,
                new Blob([file.data]),
                key,
                parentKeyId
              );

              previewFromPlugin(decryptedBlob, pack.S2);
            } catch (e) {
              setDecryptError(true);
            }
          },
          onError: (e) => {
            setDecryptError(true);
          },
        }
      );

      return;
    }

    if (pack.S4) {
      getFile(
        {
          id: pack.S4!,
          ownerKind: pack.Kind,
        },
        {
          onSuccess: async (data) => {
            const file = await getBlob(data.data.Url);

            setLetterBlob(file.data);
          },
        }
      );

      return;
    }

    getFile(
      {
        id: pack.S3!,
        ownerKind: pack.Kind,
      },
      {
        onSuccess: async (data) => {
          const file = await getBlob(data.data.Url);
          const key = keys.find(
            (key: any) => key?.Id === pack?.EncryptionKeyId
          );

          let parentKeyId = null;

          if (key?.ParentKeyId) {
            parentKeyId = packageData?.data?.Keys.find(
              (k: any) => k?.Id === key?.ParentKeyId
            );
          }

          try {
            const decryptedBlob = await decryptFile(
              userId,
              new Blob([file.data]),
              key,
              parentKeyId
            );

            previewFromPlugin(decryptedBlob, pack.S2);
          } catch (e) {
            setDecryptError(true);
          }
        },
        onError: (e) => {
          setDecryptError(true);
        },
      }
    );
  };

  useEffect(() => {
    if (pack && letterId) {
      if (!pack.S3) {
        return;
      }
      handleLetterPreview();
    }
  }, [pack, letterId, versionId]);

  const h = options.find((f) => f.Type === IOptionType.hoverMenuPreview)?.Value;

  const useMenu = h === 'true';

  const isFullScreen = window.location.href.search('fullscreen') !== -1;

  if ((showVersion || update) && !isFullScreen) {
    return (
      <PDFViewer file={new Blob([])} showVersion={showVersion || update} />
    );
  }

  if (pack && letterId && !pack.S3) {
    return (
      <div className={styles.errorWrapper}>
        <ErrorScreen text="Dokument nie jest gotowy do podglądu utwórz pierwszą wersję poprzez zapisanie pliku" />
      </div>
    );
  }

  if (decryptError) {
    return (
      <div className={styles.errorWrapper}>
        <ErrorScreen text="Nie jesteśmy w stanie rozszyfrować przesyłki" />
      </div>
    );
  }

  console.log(pdfLoading);

  if (isLoading || (!!PDFId?.length && showPDF === null) || pdfLoading) {
    console.log('wchodze tutaj');
    return (
      <div className={styles.loadingWrapper}>
        <Loading />
      </div>
    );
  }

  if (showPDF && !!PDFId?.length && !isFullScreen) {
    return (
      <PDFViewer
        title={
          !!packContext?.Attachments.find((p) => p.Id === PDFId)?.Label?.length
            ? packContext?.Attachments.find((p) => p.Id === PDFId)?.Label
            : packContext?.Attachments.find((p) => p.Id === PDFId)?.FileName
        }
        file={showPDF}
      />
    );
  }

  if (!letterBlob && letterId && !isFullScreen) {
    return <></>;
  }
  if (letterBlob) {
    return (
      <PDFViewer showSignButton={!pack.S4 && isLetters} file={letterBlob} />
    );
  }

  const handleClickReply = () => setModals({ ...modals, createPost: true });

  const handleAddOnePackage = (attachment: IAttachment) => {
    manageDossier({
      AttachmentIds: [attachment.Id],
      OwnerId: pack.Id,
      OwnerKind: pack.Kind,
      Show: !attachment.ShowInDossier,
    });

    let newPacks = [...packages];
    let el = newPacks.find((p) => p.Id === pack.Id);
    if (el) {
      let attach = el.Attachments.find((e) => e.Id === attachment.Id);

      if (attach) {
        attach.ShowInDossier = Number(!attachment.ShowInDossier);
        setPackages(newPacks);
      }
    }
  };

  if (needToConfirm) {
    return (
      <div className={classNames(styles.wrapper, className)}>
        <div className={`${styles.header} ${headerClassName}`}></div>
        <div className={styles.package}>
          <ReceiveModal refetch={refetch} pack={pack} />
        </div>
      </div>
    );
  }

  const channels = pack?.Receivers?.map((r) => r.ParcelType);
  //@ts-ignore
  const uniqChannels = [...new Set(channels)];

  const resizeIframe = (obj: any) => {
    setHeight(
      //@ts-ignore
      iframeRef.current.contentWindow.document.body.scrollHeight + 50 + 'px'
    );
  };

  if (!pack?.Id && !isLoading) {
    return (
      <div className={styles.wrap}>
        <ErrorScreen text="Nie znaleziono przesyłki" />;
      </div>
    );
  }

  const attachments = packContext?.Attachments ?? pack.Attachments;

  const packageRender = () => {
    return (
      <div className={`${styles.package} ${pack.Pass ? styles.passed : ''}`}>
        {pack.Pass && (
          <div className={styles.passedHeader}>
            <FwdIcon></FwdIcon> Przezkazana wiadomość
          </div>
        )}
        <div className={styles.personWrapper}>
          <div className={styles.person}>
            <Avatar className={styles.avastar} name={pack.Sender ?? ''} />
            <div className={styles.senderWrapper}>
              <div className={styles.nameAndDate}>
                <div className={styles.name}>{pack?.Sender}</div>
                <div className={styles.date}>
                  {moment(pack.ReceiveDate ?? pack.CreationDate)
                    .format('DD.MM.YYYY')
                    .toString()}
                </div>
              </div>
              <div className={styles.titleWrapper}>
                <div className={styles.titlePack}>{pack.S1}</div>
                <div className={styles.icons}>
                  <IconsState
                    onDownload={onDownload}
                    onPreview={onPreview}
                    previewData={previewData}
                    customY={-170}
                    keys={keys}
                    msg={packages.find((p) => p.Id === pack.Id) ?? pack}
                  />
                </div>
              </div>
              <div className={styles.toWrapper}>
                <div className={styles.to}>
                  Do:
                  <div className={styles.toList}>
                    {pack?.Receivers &&
                      pack?.Receivers?.filter((r) => !r.Cc && !r.Bcc).length >
                        0 && (
                        <div className={styles.receiver}>
                          {pack.Receivers.filter((r) => !r.Cc && !r.Bcc)[0]
                            .Name ??
                            pack.Receivers.filter((r) => !r.Cc && !r.Bcc)[0]
                              .Email}
                        </div>
                      )}
                    {pack?.Receivers &&
                      pack?.Receivers?.filter((r) => !r.Cc && !r.Bcc).length >
                        1 && (
                        <>
                          <div
                            className={styles.moreReceivers}
                            ref={receiversReference}
                            onClick={() => setShowReceivers(!showReceivers)}
                          >
                            <GroupIcon></GroupIcon> +{' '}
                            {pack.Receivers?.filter((r) => !r.Cc && !r.Bcc)
                              .length - 1}
                          </div>

                          {showReceivers && (
                            <ModalLayout refWrapper={receiversReference}>
                              <PackageReceiversModal
                                toggle={() => setShowReceivers(false)}
                                pack={pack}
                                cc={false}
                              />
                            </ModalLayout>
                          )}
                        </>
                      )}
                  </div>
                  {packCcReceivers && packCcReceivers.length > 0 && (
                    <div className={styles.ccList}>
                      <div className={styles.toList}>
                        Dw:
                        <div className={styles.receiver}>
                          {packCcReceivers[0].Name ?? packCcReceivers[0].Email}
                        </div>
                        {packCcReceivers.length > 1 && (
                          <>
                            <div
                              className={styles.moreReceivers}
                              ref={receiversCcReference}
                              onClick={() =>
                                setShowCcReceivers(!showCcReceivers)
                              }
                            >
                              <GroupIcon></GroupIcon> +{' '}
                              {packCcReceivers.length - 1}
                            </div>

                            {showCcReceivers && (
                              <ModalLayout refWrapper={receiversCcReference}>
                                <PackageReceiversModal
                                  toggle={() => setShowCcReceivers(false)}
                                  pack={pack}
                                  cc={true}
                                />
                              </ModalLayout>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {findedTags.map((tag) => (
                <Chip small element={<>{tag.Name}</>} onDelete={() => {}} />
              ))}
              {isSending && uniqChannels.length >= 2 && (
                <div className={styles.multiWrapper}>
                  {pack?.Receivers?.length >= 2 && (
                    <div
                      onClick={() => setShowMulti(!showMulti)}
                      ref={reference}
                      className={styles.multi}
                    >
                      Multiprzesyłka
                    </div>
                  )}

                  {showMulti && (
                    <ModalLayout refWrapper={reference}>
                      <MultiPackageModal
                        toggle={() => setShowMulti(false)}
                        pack={pack}
                      />
                    </ModalLayout>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.borderLine}>
          <div className={styles.line}></div>
        </div>
        {!useIframe && content.search('undefined') === -1 && (
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className={styles.body}
          ></div>
        )}

        {useIframe && (
          <>
            {content.search('undefined') === -1 && (
              <iframe
                ref={iframeRef}
                className={styles.iframe}
                srcDoc={content}
                onLoad={resizeIframe}
                height={height}
              ></iframe>
            )}
            {content.search('undefined') !== -1 && <Loading />}
          </>
        )}

        <div className={styles.attachments}>
          {attachments?.map((attachment) => (
            <div key={attachment.Id} className={styles.attach}>
              <Attachment
                onAddDossier={!!pack?.Case ? handleAddOnePackage : undefined}
                date={moment(pack.ReceiveDate ?? pack.CreationDate)
                  .format('HH:MM | DD.MM.YYYY')
                  .toString()}
                onNewWindow={onNewWindow}
                onPreview={(attach) => {
                  onPreview(pack, attach, previewData);
                }}
                onDownload={(attach) => onDownload(pack, attach, previewData)}
                attachment={attachment}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      {showCase && (
        <PackageCaseModal
          toggle={setShowCase}
          CaseId={pack?.Case?.Id}
          Package={pack}
        />
      )}
      <div className={`${styles.header} ${headerClassName}`}>
        {useMenu && (
          <div ref={refWrapper} className={styles.options}>
            <OptionsCard
              content={content}
              className={isFullScreen && !cc ? '' : styles.opt}
              rightSide={isFullScreen ? true : false}
              selectedElements={[]}
              showOptions={true}
              pack={pack}
              refetch={() => {}}
              refWrapper={refWrapper}
              onClickAttachIcon={setShowAttach}
            />
          </div>
        )}
      </div>
      {!allowImages && (
        <div className={styles.info}>
          <div className={styles.text}>
            Przesyłka zawiera pliki graficzne czy chcesz je wyświetlić?
          </div>
          <div className={styles.button}>
            <Button
              className={styles.btn}
              onClick={() => {
                setCanImages(true);
                setAllowImages(true);
              }}
              text="Wyświetl pełną treść"
            />
          </div>
        </div>
      )}
      {pack.Pass && (
        <div className={styles.passInfo}>
          <div className={styles.personWrapper}>
            <div className={styles.person}>
              <Avatar className={styles.avastar} name={pack.Pass.From ?? ''} />
              <div className={styles.senderWrapper}>
                <div className={styles.nameAndDate}>
                  <div className={styles.name}>{pack?.Pass.From}</div>
                  <div className={styles.date}>
                    {moment(pack.Pass.Date).format('DD.MM.YYYY').toString()}
                  </div>
                </div>
                <div className={styles.titleWrapper}>
                  <div className={styles.titlePack}>
                    {pack.Pass.Title ?? pack.S1}
                  </div>
                </div>
                <div className={styles.toWrapper}>
                  <div className={styles.to}>
                    Do:
                    <div className={styles.toList}>
                      <div className={styles.receiver}>{pack.Pass.To}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.passedHeader}>
            <FwdIcon></FwdIcon> Opis przekazującego
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: pack.Pass.Content ?? 'Brak opisu',
            }}
            className={styles.body}
          ></div>
          {packageRender()}
        </div>
      )}

      {!pack.Pass && packageRender()}
    </div>
  );
};

export default Package;
