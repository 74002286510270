import ModalsManagerContext, { defaultValue } from 'contexts/ModalsManager';
import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { useContext, useEffect, useRef, useState } from 'react';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as AddIcon } from 'assets/icons/upload.svg';
import { ReactComponent as CaseIcon } from 'assets/icons/case.svg';
import { ReactComponent as EntityIcon } from 'assets/icons/desk_hover.svg';
import { ReactComponent as LetterIcon } from 'assets/icons/project.svg';

import { ReactComponent as SenderIcon } from 'assets/icons/sender.svg';
import { ReactComponent as PostageIcon } from 'assets/icons/scan.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task.svg';

import ReactDOM from 'react-dom';
import { useOnClickOutside } from 'usehooks-ts';

const SidebarAddingOptions: FC<Props> = ({ addButtonRef, toggle }) => {
  const { modals, setModals } = useContext(ModalsManagerContext);

  const handleClickAction = () => {
    setModals({ ...defaultValue, createAction: true });
    toggle(false);
  };

  const handleClickDossier = () => {
    setModals({ ...defaultValue, createDossier: true });
    toggle(false);
  };

  const handleClickCase = () => {
    setModals({ ...defaultValue, createCase: true });
    toggle(false);
  };

  const handleClickEntity = () => {
    setModals({ ...defaultValue, createEntity: true });
    toggle(false);
  };
  const handleClickCompany = () => {
    setModals({ ...defaultValue, createCompany: true });
    toggle(false);
  };

  const handleClickLetter = () => {
    setModals({ ...defaultValue, createLetter: true });
    toggle(false);
  };

  const handleClickPostage = () => {
    setModals({ ...defaultValue, createPostage: true });
    toggle(false);
  };

  const handleClickTask = () => {
    setModals({ ...defaultValue, createTask: true });
    toggle(false);
  };

  const handleClickPost = () => {
    sessionStorage.removeItem('reply');
    sessionStorage.removeItem('msg');
    sessionStorage.removeItem('sendTo');
    sessionStorage.removeItem('type');
    setModals({ ...defaultValue, createPost: true });
    toggle(false);
  };

  const position = addButtonRef.current.getBoundingClientRect();

  const outsideRef = useRef(null);

  useOnClickOutside(outsideRef, () => {
    toggle(false);
  });

  const [showed, setShowed] = useState(false);

  useEffect(() => {
    setShowed(true);
  }, []);

  return ReactDOM.createPortal(
    <div
      ref={outsideRef}
      style={{
        height: showed ? `390px` : '0px',
        left: position.left,
        top: position.top + position.height + 5,
      }}
      className={`${styles.menu} ${showed && styles.open}`}
    >
      <div className={styles.wrapper}>
        {/* <div onClick={handleClickAction} className={styles.box}>
          <CalendarIcon />
          Wydarzenie
        </div> */}
        <div onClick={handleClickTask} className={styles.box}>
          <CalendarIcon />
          Czynność
        </div>
        <div onClick={handleClickDossier} className={styles.box}>
          <AddIcon />
          Element dossier
        </div>
        <div onClick={handleClickCase} className={styles.box}>
          <CaseIcon />
          Teczka
        </div>
        <div onClick={handleClickPostage} className={styles.box}>
          <PostageIcon />
          Przesyłka papierowa
        </div>
        <div onClick={handleClickEntity} className={styles.box}>
          <EntityIcon />
          Podmiot-Osoba
        </div>
        <div onClick={handleClickCompany} className={styles.box}>
          <EntityIcon />
          Podmiot-Firma
        </div>
        <div onClick={handleClickLetter} className={styles.box}>
          <LetterIcon />
          Pismo
        </div>
        <div onClick={handleClickPost} className={styles.box}>
          <SenderIcon />
          Przesyłka wychodząca
        </div>
      </div>
    </div>,
    document.body
  );
};

export default SidebarAddingOptions;
