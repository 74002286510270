import { IFeed } from "dto/IFeed";
import { createContext } from "react";

type IActionsContext = {
    tasks: IFeed[];
    setTasks: (tasks: IFeed[]) => void;
}

const ActionsContext = createContext<IActionsContext>({
    tasks: [],
    setTasks: () => {}
})

export default ActionsContext;