import { FC, Props } from './typings';
import styles from './styles.module.scss';
import ReactDOM from 'react-dom';
import Dropdown from 'components/Dropdown';
import { IDropdownObject } from 'components/Dropdown/typings';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import AddButton from 'components/AddButton';
import { useContext, useEffect, useMemo, useState } from 'react';
import SearchEntities from 'components/SearchEntities';
import { IEntity } from 'dto/IEntity';
import { ICaseParticipant } from 'dto/Cases/ICaseParticipant';
import Button from 'components/Button';
import CasesContext from 'contexts/CasesContext';
import casesService from 'api/casesService';
import { CaseParticipantPrivileges } from 'dto/Cases/CaseParticipantPrivileges';
import tagsService from 'api/tagsService';
import { ITag } from 'dto/ITag';
import SecondCheckbox from 'components/SecondCheckbox';
import { IParcelTypes } from 'dto/IParcelTypes';
import {
  convertParcelTypeToKind,
  getTypeOfParcel,
  translateToPolish,
} from 'utils/getTypeOfParcel';
import Avatar from 'components/Avatar';
import AdditionalClientModal from 'components/AdditionalClientModal';
import { useAuth } from 'react-oidc-context';

const CasePerson: FC<Props> = ({ toggle, Case }) => {
  const { data: fullCase } = casesService.useGetCase(Case.Id);

  const { mutate: createCase } = casesService.useCreateCase();
  const { cases, setCases } = useContext(CasesContext);

  const [isModified, setIsModified] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [participants, setParticipants] = useState<ICaseParticipant[]>([]);

  const roles: IDropdownObject[] = [
    { name: 'Brak', value: null },
    { name: 'Klient', value: 'b25f4943-f576-11ed-aa5c-7acc2dc006f5' },
    { name: 'Organ prow.', value: 'b25bbfde-f576-11ed-aa5c-7acc2dc006f5' },
  ];

  const shareTypes: IDropdownObject[] = [
    {
      value: 0,
      name: 'Brak',
    },
    {
      value: 1,
      name: 'Odczyt',
    },
    {
      value: 2,
      name: 'Edycja',
    },
  ];

  const handleAdd = () => {
    setAddNew(true);
  };

  const handleNewEntity = (entity: IEntity) => {
    const payload = {
      UserId: entity.Type === 1 || entity.Type === 4 ? entity.Id : undefined,
      ParticipantId: entity.Type === 3 ? entity.Id : undefined,
      Email: entity.Email,
      EDeliveryAddress: entity.EDeliveryAddress,
      Address: entity.Address,
      Label: entity.Label ?? entity.Name,
      RoleId: entity.RoleId ?? null,
      ParcelTypes: entity.ParcelTypes,
      DefaultParcelType: null,
      IsMain: false,
    };

    setParticipants([...participants, payload]);

    setAddNew(false);
    setIsModified(true);
  };

  const handleRemove = (participant: ICaseParticipant) => {
    const index = participants.indexOf(participant, 0);

    if (index > -1) {
      var arr = participants;
      arr.splice(index, 1);
      setParticipants([...arr]);

      setIsModified(true);
    }
  };

  useEffect(() => {
    setParticipants(fullCase?.Participants ?? []);
    setIsModified(false);
  }, [fullCase]);

  const save = (force = false, close = true) => {
    if (isModified || force) {
      createCase(
        {
          Id: Case?.Id ?? null,
          Participants: participants,
          Signature: Case.Signature.replaceAll('<em>', '').replaceAll(
            '</em>',
            ''
          ),
          TagIds: Case.TagIds,
          Title: Case.Title.replaceAll('<em>', '').replaceAll('</em>', ''),
          StatusId: Case.StatusId,
        },
        {
          onSuccess: (r) => {
            Case.Client = r.data.Client;
            Case.Court = r.data.Court;
            Case.Participants = r.data.Participants;

            const newCases = [...cases];

            if (Case) {
              let toUpdate = newCases.find((c) => c.Id === Case.Id);

              if (toUpdate) {
                toUpdate.Client = r.data.Client;
                toUpdate.Court = r.data.Court;

                setCases(newCases);
              }
            }

            setIsModified(false);
            if (close) {
              toggle(false);
            }
          },
        }
      );
    } else {
      toggle(false);
    }
  };

  const setRole = (participant: ICaseParticipant, role?: IDropdownObject) => {
    participant.RoleId = role ? (role.value as string) : null;
    setIsModified(true);
  };

  const setParcel = (
    participant: ICaseParticipant,
    parcelType?: IDropdownObject
  ) => {
    participant.DefaultParcelType = parcelType
      ? (parcelType.value as number)
      : null;
    setIsModified(true);
  };

  const setShare = (participant: ICaseParticipant, share?: IDropdownObject) => {
    participant.Privileges = share
      ? (share.value as number)
      : CaseParticipantPrivileges.None;
    setIsModified(true);
  };

  const { data: tagsData } = tagsService.useGetTags();
  const allTags = useMemo(() => tagsData?.data ?? [], [tagsData]);

  const Rlist =
    allTags
      ?.filter((t: ITag) => t.Type === 3)
      ?.map((t: ITag) => {
        return {
          name: t.Name,
          value: t.Id,
        };
      }) ?? [];

  const [showPerson, setShowPerson] = useState(false);

  const auth = useAuth();

  const canEdit =
    auth?.user?.profile?.role === 'guest' ? Case?.Privileges === 2 : true;

  return (
    <div className={styles.shadow}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.podmiot}>Podmioty</div>
          <div className={styles.who}>Czyja teczka</div>
          <div className={styles.role}>Rola</div>
          <div className={styles.delivery}>Doręczenie</div>
          <div className={styles.share}>Udostęp.</div>
          <div
            onClick={() => {
              save(false, true);
            }}
            className={styles.bin}
          >
            <CloseIcon />
          </div>
        </div>

        <div className={styles.list}>
          {/* <div className={styles.box}>asdf</div> */}
          {participants.map((participant) => {
            const types: IParcelTypes[] = getTypeOfParcel(
              participant.ParcelTypes ?? 0
            );

            const parcelTypes = types.map((t) => ({
              value: convertParcelTypeToKind(t)!,
              name: translateToPolish(t)!,
            }));

            return (
              <div className={styles.box}>
                <div className={styles.podmiot}>
                  <Avatar
                    className={styles.avatar}
                    name={participant.Label ?? ''}
                  />
                  {participant.Label}
                </div>
                <div className={styles.who}>
                  <SecondCheckbox
                    onChange={() => {
                      if (!canEdit) return;
                      const newParts = [...participants];

                      // for (const part of newParts) {
                      //   part.IsMain = false;
                      // }

                      const part = newParts.find(
                        (p) => p.Id === participant.Id
                      );
                      if (!part) return;

                      part.IsMain = !part.IsMain;
                      setParticipants(newParts);
                      save(true, false);
                    }}
                    value={participant.IsMain}
                  />
                </div>
                <div className={styles.role}>
                  <Dropdown
                    defaultValue={participant.RoleId}
                    className={styles.parcelDropdown}
                    onChange={(r) => setRole(participant, r)}
                    list={Rlist}
                    placeholder="..."
                    allowNull
                    disableChange={!canEdit}
                  />
                </div>
                <div className={styles.delivery}>
                  <Dropdown
                    defaultValue={participant.DefaultParcelType}
                    className={styles.parcelDropdown}
                    onChange={(r) => setParcel(participant, r)}
                    list={parcelTypes}
                    placeholder="..."
                    allowNull
                    disableChange={!canEdit}
                  />
                </div>
                <div className={styles.share}>
                  <Dropdown
                    defaultValue={participant.Privileges}
                    className={styles.parcelDropdown}
                    onChange={(r) => setShare(participant, r)}
                    list={shareTypes}
                    disableChange={!canEdit}
                  />
                </div>
                {canEdit && (
                  <div
                    onClick={() => {
                      handleRemove(participant);
                    }}
                    className={styles.bin}
                  >
                    <BinIcon />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {addNew && (
          <div className={styles.searchEnitity}>
            <SearchEntities
              handleClickCreate={() => setShowPerson(true)}
              onChoose={(entity) => handleNewEntity(entity)}
            />
          </div>
        )}
        {canEdit && (
          <div className={styles.buttons}>
            {!addNew && (
              <Button
                onClick={() => {
                  setAddNew(true);
                }}
                className={styles.button}
                text="+ Dodaj podmiot"
              />
            )}
          </div>
        )}
      </div>
      {showPerson && (
        <AdditionalClientModal
          showClient={true}
          onCreateClient={(entity) => {
            handleNewEntity(entity);
            setShowPerson(false);
          }}
          isCompany={false}
        />
      )}
    </div>
  );
};

export default CasePerson;
