import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { ETabs, FC, Props } from './typings';

import { axiosInstance } from 'api/axios';
import exportService from 'api/exportService';
import settingService from 'api/settingsService';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import { ISpaceUsageReponse } from 'dto/Settings/ISpaceUsageResponse';
import prettyBytes from 'pretty-bytes';
import { useAuth } from 'react-oidc-context';
import { decryptFile } from 'utils/decryptFile';
import { getBlob } from 'utils/getBlob';
import { getKeys } from 'utils/getKeys';
import { getUserId } from 'utils/getUserId';
import { readFile } from 'utils/readFile';
import SecondCheckbox from 'components/SecondCheckbox';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
const JSZip = require('jszip');
export interface IFileToDownload {
  Id: string;
  FileName: string;
  Path: string;
  Size: number;
  IsEncrypted: boolean;
  Type: string;
}

export interface IDownloadedFile {
  Uri: string;
  FileName: string;
  ContentType: string;
  Url: string;
  KeyId: any;
  KeyPk: any;
  ObjectKey: string;
  ParentKey: string;
  CaseKeyId: any;
  Bucket: string;
}

const DiskTab: FC<Props> = ({}) => {
  const auth = useAuth();
  var userId = getUserId(auth.user!);
  const { data: spaceUsageResponse, isSuccess: isSpaceUsageLoaded } =
    settingService.useGetSpaceUsage();

  const { mutate: prepareDownload } = exportService.usePrepareDownload();

  const [spaceUsage, setSpaceUsage] = useState<ISpaceUsageReponse | null>(null);

  useEffect(() => {
    if (isSpaceUsageLoaded) {
      setSpaceUsage(spaceUsageResponse.data);
    }
  }, [spaceUsageResponse]);

  const [filesToDownload, setFilesToDownload] = useState<IFileToDownload[]>([]);

  // useEffect(() => {
  //   return () => {
  //     setFilesToDownload([]);
  //   };
  // });

  const [c, setC] = useState(false);
  const [showCases, setShowCases] = useState(false);

  const handleDownload = () => {
    prepareDownload(undefined, {
      onSuccess: (data) => {
        setFilesToDownload(data.data);
      },
    });
  };

  const [actualView, setActualView] = useState(ETabs.DOWNLOAD);

  const Menu = () => {
    return (
      <div className={styles.menu}>
        <div
          onClick={() => setActualView(ETabs.DOWNLOAD)}
          className={`${styles.box}  ${
            actualView === ETabs.DOWNLOAD && styles.activeText
          }`}
        >
          Pobierz swoje dane
        </div>
        <div
          onClick={() => setActualView(ETabs.CLOSE)}
          className={`${styles.box}  ${
            actualView === ETabs.CLOSE && styles.activeText
          }`}
        >
          Zamknij konto
        </div>
        <div
          onClick={() => setActualView(ETabs.SPACE)}
          className={`${styles.box}  ${
            actualView === ETabs.SPACE && styles.activeText
          }`}
        >
          Wykorzystanie dysku
        </div>
      </div>
    );
  };

  const Download = () => {
    return (
      <>
        <div className={styles.group}>
          <div className={styles.title}>Pobierz swoje dane</div>
          <div className={styles.hint}>
            Wyeksportuj wszystkie swoje dane z NotaUp.pl
          </div>

          <div className={styles.checkboxes}>
            <SecondCheckbox
              onChange={() => setC(false)}
              value={!c}
              text="Dane w układzie metadanych"
            />
            <SecondCheckbox
              onChange={() => setC(true)}
              value={c}
              text="Dane w układzie spraw"
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              text="Eksportuj dane"
              onClick={() => {
                window.open(`${process.env.REACT_APP_SITE}/export`, '_blank');
              }}
            />
          </div>
        </div>
      </>
    );
  };

  if (!spaceUsage) {
    return <Spinner></Spinner>;
  }

  return (
    <>
      <Download />
      <div className={styles.wrapper}>
        <div className={styles.group}>
          <div className={styles.title}>Wykorzystanie dysku</div>
          {/* <div className={styles.hint}>
          Kontroluj zajętość swojej przestrzeni na przesyłki i plik w NotaUp
        </div> */}
          <div className={styles.total}>
            <div className={styles.row}>
              <div className={styles.percent}>
                Dostępne{' '}
                {(
                  ((spaceUsage?.Total - spaceUsage?.Used) / spaceUsage.Total) *
                  100
                ).toFixed(1)}
                %
              </div>
              <div>
                Wykorzystano {prettyBytes(spaceUsage.Used, { binary: true })} z{' '}
                {prettyBytes(spaceUsage.Total, { binary: true })}
              </div>
            </div>
            <div className={classNames(styles.bar, styles.big)}>
              <div
                className={styles.used}
                style={{
                  width: (spaceUsage.Used / spaceUsage.Total) * 100 + '%',
                }}
              ></div>
            </div>
          </div>
        </div>
        {showCases && (
          <div className={styles.cases}>
            {spaceUsage.Cases.map((c) => (
              <div className={styles.caseRow}>
                <div className={styles.col1}>
                  <div className={styles.data}>
                    <div className={styles.title}>{c.Title}</div>
                    <div className={styles.client}>{c.Client}</div>
                  </div>
                  <div className={styles.usedLabel}>{prettyBytes(c.Size)}</div>
                </div>
                <div className={styles.col}>
                  <div className={classNames(styles.bar)}>
                    <div
                      className={styles.used}
                      style={{ width: (c.Size / spaceUsage.Total) * 100 + '%' }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div onClick={() => setShowCases(!showCases)} className={styles.btn2}>
          {!showCases ? <>Sprawdz szczegóły</> : <>Ukryj szczegóły</>}{' '}
          <FontAwesomeIcon icon={!showCases ? faCaretDown : faCaretUp} />
        </div>
      </div>
    </>
  );
};

export default DiskTab;
