import { createContext } from 'react';

export interface IModalsManager {
  createDossier: boolean;
  createCase: boolean;
  createAction: boolean;
  createTask: boolean;
  createEntity: boolean;
  createCompany: boolean;
  createLetter: boolean;
  createPostage: boolean;
  createPost: boolean;
  hide: boolean;
  settings: boolean;
}

type IModalsManagerContext = {
  modals: IModalsManager;
  options: IModalOptions;
  setModals: (modals: IModalsManager) => void;
  setOptions: (options: IModalOptions) => void;
};

export const defaultValue = {
  createDossier: false,
  createCase: false,
  createAction: false,
  createTask: false,
  createEntity: false,
  createCompany: false,
  createLetter: false,
  createPostage: false,
  createPost: false,
  settings: false,
  hide: false,
};

export interface IModalOptions {}

export class LetterModalOptions implements IModalOptions {
  format?: string;
}

const ModalsManagerContext = createContext<IModalsManagerContext>({
  modals: defaultValue,
  options: {} as IModalOptions,
  setModals: () => {},
  setOptions: () => {},
});

export default ModalsManagerContext;
