export const readFileAsBlob = (file: File | any):Promise<[File, ProgressEvent<FileReader>]>  => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (result) => {
            resolve([file, result])
        };
        reader.readAsArrayBuffer(file);
    })
}

export const readFileAsUrl = (file: File): Promise<[File, ProgressEvent<FileReader>]> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (result) => {
            resolve([file, result])
        };
        reader.readAsDataURL(file);
    });
}