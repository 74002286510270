import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { FC, Props } from './typings';

import { ReactComponent as Signature } from 'assets/icons/signature.svg';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Button';
import pluginService from 'api/pluginService';
import { blobToBase64 } from 'utils/blobToBase64';
import signatureService from 'api/signatureService';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';

const SignatureLabel: FC<Props> = ({ content }) => {
  const { kind, packageId, threadId, PDFId, messageId, threadPackageId } =
    useParams();
  const ownerId = threadPackageId ?? packageId ?? threadId ?? messageId;
  const ownerKind = kind;
  const ownerAttachmentId = PDFId ?? messageId;
  const { mutate: verifyByPlugin } = pluginService.useVerifySignature();
  const { mutate: uploadSignature } = signatureService.useUploadSignature();
  const { data: signatureData } = signatureService.useGetSignature({
    OwnerId: ownerId!,
    ownerKind: Number(ownerKind)!,
    attachmentId: ownerAttachmentId!,
  });

  const [sig, setSig] = useState<any>(null);
  const [showSig, setShowSig] = useState<any>(false);
  const [more, setShowMore] = useState<any>(false);

  useEffect(() => {
    if (!signatureData?.data?.Id) return;

    setSig(JSON.parse(signatureData.data.Json));
  }, [signatureData]);

  const verify = async () => {
    const b: string = (await blobToBase64(content as Blob)) as string;
    verifyByPlugin(
      {
        Content: b.split(',')[1],
        ContentType: 'application/pdf',
        FileName: 'dokument.pdf',
      },
      {
        onSuccess: (response) => {
          setSig(response.data);
          uploadSignature({
            Json: JSON.stringify(response.data),
            OwnerId: ownerId!,
            OwnerKind: Number(ownerKind!),
            OwnerAttachmentId: ownerAttachmentId!,
          });
        },
      }
    );
  };

  const validTo = sig?.Signatures?.[0]?.SigningCertificate?.ValidTo;

  const isValid = moment(new Date()).isBefore(validTo);

  return (
    <div className={styles.descr}>
      <div onClick={() => setShowSig(!showSig)} className={styles.descrWrapper}>
        <div className={styles.ss}>
          <Signature />
          {sig ? (
            <>
              Dokument podpisany elektronicznie:{' '}
              {sig.Signatures[0].ChainItem[0].CommonName}{' '}
              {moment(sig.Signatures[0].DateTime)
                .format('DD.MM.YYYY HH:mm')
                .toString()}
            </>
          ) : (
            <>Dokument podpisany elektronicznie: Niezweryfikowany</>
          )}
        </div>
        <div>
          <FontAwesomeIcon
            className={`${styles.icon} ${showSig && styles.revert}`}
            icon={faCaretDown}
          />
        </div>
      </div>
      {showSig && (
        <div className={styles.list}>
          <div className={styles.listWrapper}>
            {!sig && (
              <div className={styles.no}>
                Podpis nie jest jeszcze zweryfikowany
                <Button
                  className={styles.button}
                  text="Weryfikuj podpis"
                  onClick={verify}
                />
              </div>
            )}
            {sig && (
              <div className={styles.signature}>
                <div className={styles.box}>
                  <div className={styles.label}>Podpisany przez: </div>
                  <div className={styles.notLabel}>
                    {sig.Signatures[0].ChainItem[0].CommonName}
                  </div>
                  <div className={styles.notLabel}>
                    w imieniu:{' '}
                    {sig.Signatures[0].TrustedProfile?.FirstName ?? ''}{' '}
                    {sig.Signatures[0].TrustedProfile?.LastName ?? ''} (PESEL:{' '}
                    {sig.Signatures[0].TrustedProfile?.PESEL ?? ''})
                  </div>
                </div>
                <div className={styles.box}>
                  <div className={styles.label}>Data podpisu: </div>
                  <div className={styles.notLabel}>
                    {moment(sig.Signatures[0].DateTime)
                      .format('DD.MM.YYYY HH:mm:ss')
                      .toString()}
                  </div>
                </div>

                <div className={styles.box}>
                  <div className={styles.label}>Rodzaj podpisu: </div>
                  <div className={styles.notLabel}>
                    {sig.Signatures[0].SigningCertificate.IsQualified
                      ? 'Profil zaufany'
                      : 'Podpis NIEKWALIFIKOWANY'}
                  </div>
                </div>

                {more && (
                  <>
                    <div className={styles.box}>
                      <div className={styles.label}>Format podpisu: </div>
                      <div className={styles.notLabel}>
                        {sig.Signatures[0].SignatureFormat}
                      </div>
                    </div>

                    <div className={styles.box}>
                      <div className={styles.label}>Algorytm podpisu: </div>
                      <div className={styles.notLabel}>
                        {sig.Signatures[0].DigestAlgorithm}
                      </div>
                    </div>
                    <div className={styles.box}>
                      <div className={styles.label}>Wydany przez: </div>
                      <div className={styles.notLabel}>
                        {sig.Signatures[0].SigningCertificate.Issuer}
                      </div>
                    </div>

                    <div className={styles.box}>
                      <div className={styles.label}>Ważność certyfikatu: </div>
                      <div className={styles.notLabel}>
                        {moment(
                          sig.Signatures[0].SigningCertificate.ValidFrom
                        ).format('DD.MM.YYYY HH:mm:ss')}{' '}
                        -{' '}
                        {moment(
                          sig.Signatures[0].SigningCertificate.ValidTo
                        ).format('DD.MM.YYYY HH:mm:ss')}
                      </div>
                    </div>

                    <div className={styles.box}>
                      <div className={styles.label}>
                        Zewnętrzny znacznik czasu:{' '}
                      </div>
                      <div className={styles.notLabel}>Nie wystepuje</div>
                    </div>
                  </>
                )}

                {!more && (
                  <div
                    onClick={() => setShowMore(true)}
                    className={styles.more}
                  >
                    Szczegóły podpisu
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SignatureLabel;
