import styles from './styles.module.scss';
import { FC, Props } from './typings';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as WindowsIcon } from 'assets/icons/windows.svg';
import { ReactComponent as MacIcon } from 'assets/icons/mac.svg';

const PluginNotExist: FC<Props> = ({ handleClose }) => {
  return (
    <div className={styles.modal}>
      <CloseIcon onClick={handleClose} className={styles.closeIcon} />
      <div className={styles.window}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Zainstaluj wtyczkę
            <div className={styles.bar}></div>
          </div>
          <div className={styles.body}>
            Pobierz wtyczkę Noty dla swojego systemu operacyjnego.
          </div>

          <div className={styles.system}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://beta.pocztaprawnicza.pl/plugin/wtyczkaNoty.msix"
            >
              <div className={styles.box}>
                <WindowsIcon />
                Windows
              </div>
            </a>
            <div className={styles.line}></div>
            <div className={styles.box}>
              <MacIcon />
              MacOS
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PluginNotExist;
